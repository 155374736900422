import { useAppSettings, useAuth } from 'lib';
import {
  Divider, Stack, Typography,
} from '@mui/material';

import { BlanketLogo, recommendationBackgroundURL } from '../../../assets';
import { useLabels } from '../../../lib/translations';
import { BoldTypography } from '../../../typography/BoldTypography';
import { LightTypography } from '../../../typography/LightTypography';

export const AnnualReportHeaderBannerPdf = () => {
  const l = useLabels();
  const { data: appSettings } = useAppSettings();
  const { user } = useAuth();

  return (
    <Stack sx={{
      backgroundImage: `url(${recommendationBackgroundURL})`,
      backgroundSize: 'cover',
      backgroundPosition: 'right',
      height: '100%',
      px: '5rem',
      py: '4rem',
    }}
    >
      <img
        src={`${appSettings?.logo ?? ''}?v=annual-report-pdf`}
        style={{
          height: '4rem',
          width: 'fit-content',
        }}
        alt="logo"
      />
      <Divider sx={{
        borderColor: '#EBEDF5', borderWidth: 0.5, opacity: 0.5, mx: '-5rem', my: '3rem',
      }}
      />
      <Stack justifyContent="space-between" flexGrow={1}>
        <Stack gap="3rem">
          <Stack>
            <BoldTypography variant="h3" color="primary">{l['annualReportPdf.header.annual']}</BoldTypography>
            <BoldTypography variant="h3" color="primary">{l['annualReportPdf.header.report']}</BoldTypography>
          </Stack>
          <Typography variant="h1" color="primary">{l['annualReportPdf.header.year']}</Typography>
          {user?.givenName && (
            <Typography variant="h5" color="primary">{`${user.givenName} ${user.familyName ?? ''}`}</Typography>
          )}
        </Stack>
        <Stack alignItems="flex-end" justifyContent="center" sx={{ mb: '-2rem', mr: '-2rem' }}>
          <LightTypography color="primary" sx={{ fontWeight: 300 }}>
            {l.poweredBy}
          </LightTypography>
          <BlanketLogo style={{ height: '2.5rem', width: 'fit-content' }} />
        </Stack>
      </Stack>
    </Stack>
  );
};
