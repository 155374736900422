import { LegislationItem as LegislationItemData, useListLegislationData } from 'lib';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { JudgeHammerCircle, PinkDotLightIcon } from '../../../icons';
import { useLabels } from '../../../lib/translations';
import { FallbackSpinner } from '../../../spinner';
import { BoldTypography } from '../../../typography/BoldTypography';

const LegislationItem = ({ legislationData }: { legislationData: LegislationItemData }) => (
  <Stack direction="row" key={legislationData.id} alignItems="flex-start" gap={2}>
    <Stack pt="0.25rem">
      <PinkDotLightIcon height="0.6rem" width="0.6rem" />
    </Stack>
    <Stack gap={1}>
      <BoldTypography
        variant="body2"
        sx={{
          textAlign: 'justify',
          cursor: 'pointer',
        }}
        onClick={() => window.open(legislationData.url, '_blank')}
      >
        {`${legislationData.billNumber} ${legislationData.title}`}
      </BoldTypography>
      <Typography
        component="span"
        variant="body2"
        sx={{
          textAlign: 'justify',
        }}
      >
        {legislationData.executiveSummary}
      </Typography>
    </Stack>
  </Stack>
);

export const LegislationOverviewPdf = ({ state }: { state: string }) => {
  const l = useLabels();
  const { data: legislationData, isLoading } = useListLegislationData(state);

  const legislationDataParsed = legislationData ?? [];
  const legislationDataSorted = legislationDataParsed.sort((a, b) => b.relevance - a.relevance);
  const legislationDataFiltered = legislationDataSorted.slice(0, 8);

  if (isLoading || !legislationDataFiltered || state === '') {
    return <FallbackSpinner />;
  }

  return (
    <Stack gap="1rem">
      <Stack direction="row" alignItems="center" gap="1rem" pt="0.5rem">
        <JudgeHammerCircle height="2rem" width="2rem" />
        <BoldTypography variant="h5">
          {l['annualReport.legislationOverview.title']}
        </BoldTypography>
      </Stack>
      <Grid container spacing={4}>
        {legislationDataFiltered.map((item) => (
          <Grid item xs={12} md={6} key={item.id}>
            <LegislationItem legislationData={item} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
