import { InsightLevel, InsightType } from 'lib';
import {
  Checkmark, CircledIcon, ExclamationMark,
} from 'ui';
import { Stack, useTheme } from '@mui/material';

export const InsightLevelComponent = ({
  level,
  insightType,
  empty = false,
}: {
  level: InsightLevel,
  insightType: InsightType,
  empty?: boolean,
}) => {
  const theme = useTheme();

  const exclamationMarkStyle = {
    color: theme.palette.primary.contrastText,
  };

  const checkMarkStyle = {
    color: theme.palette.primary.contrastText,
    height: 10,
    width: 10,
  };

  const isNegative = insightType === InsightType.NEGATIVE;
  const isPositive = insightType === InsightType.POSITIVE;
  const isNeutral = insightType === InsightType.NEUTRAL;

  if (empty) return null;

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      {isNegative && (
        <>
          <CircledIcon icon={<ExclamationMark {...exclamationMarkStyle} />} color={theme.palette.error.main} />
          <CircledIcon
            icon={<ExclamationMark {...exclamationMarkStyle} />}
            color={[InsightLevel.MEDIUM, InsightLevel.HIGH].includes(level) ? theme.palette.error.main : undefined}
          />
          <CircledIcon
            icon={<ExclamationMark {...exclamationMarkStyle} />}
            color={level === InsightLevel.HIGH ? theme.palette.error.main : undefined}
          />
        </>
      )}
      {isPositive && (
        <>
          <CircledIcon icon={<Checkmark {...checkMarkStyle} />} color={theme.palette.success.main} />
          <CircledIcon
            icon={<Checkmark {...checkMarkStyle} />}
            color={[InsightLevel.MEDIUM, InsightLevel.HIGH].includes(level) ? theme.palette.success.main : undefined}
          />
          <CircledIcon
            icon={<Checkmark {...checkMarkStyle} />}
            color={level === InsightLevel.HIGH ? theme.palette.success.main : undefined}
          />
        </>
      )}
      {isNeutral && (
        <CircledIcon />
      )}
    </Stack>
  );
};
