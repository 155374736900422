import dayjs from 'dayjs';
import { OwnerRiskQuarterInsight } from 'lib';
import { signal } from '@preact/signals-react';

import { AppRiskInsight, BasicOwnerRisk, PropertyData } from '../../../../../api/properties/types';

export type InsightDrawerState = {
  openDrawer?: () => void,
  closeDrawer?: () => void,
  ownerRisk?: BasicOwnerRisk,
};

export const insightDrawerState = signal<InsightDrawerState>({});

export const getQuarterLabels = (quarters: OwnerRiskQuarterInsight[]) => quarters.map((q) => {
  const { startDate } = q;
  const { endDate } = q;

  return `${dayjs(startDate).format('MMM')} - ${dayjs(endDate).subtract(1, 'month').format('MMM')}`;
}) ?? [];

export type CaseExpandedContentProps = {
  insight: AppRiskInsight | null,
  property: PropertyData,
};

export enum ContextKey {
  LEASE = 'monthsToEndLease',
  AVERAGE_MONTHLY_APPRECIATION = 'averageAppreciation',
  APPRECIATION = 'appreciation',
  CASH_FLOW = 'cashflow',
  MAINTENANCE = 'maintenance',
  INCOME = 'income',
  MAINTENANCE_EVENTS = 'maintenanceEvents',
  MANAGEMENT_FEE = 'managementFee',
  NOI = 'noi',
  MONTHS_RENT = 'monthsRent',
}

export enum CaseName {
  APPRECIATION = 'appreciation',
  CASH_FLOW = 'cashflow',
  NOI = 'noi',
  MANAGEMENT_FEE = 'managementFee',
  MAINTENANCE_COSTS = 'maintenance',
  MAINTENANCE_EVENTS = 'maintenanceEvents',
  VACANCY = 'vacancy',
  TENANTS_TURNOVER = 'turnoverTenants',
  LEASE = 'monthsToEndLease',
}

export const insightCaseNames: CaseName[] = [
  CaseName.APPRECIATION,
  CaseName.CASH_FLOW,
  CaseName.NOI,
  CaseName.MAINTENANCE_COSTS,
  CaseName.MAINTENANCE_EVENTS,
  CaseName.MANAGEMENT_FEE,
  CaseName.VACANCY,
  CaseName.TENANTS_TURNOVER,
  CaseName.LEASE,
];
