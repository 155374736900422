import { ApexOptions } from 'apexcharts';
import Apexcharts from 'react-apexcharts';
import { useTheme } from '@mui/material';

import { formatDecimalToPercentage, formatNumber, formatNumberToCurrency } from '../lib/formatFuncs';

export const ColumnChart = ({
  series,
  labels,
  height = undefined,
  width = '100%',
  thinColumns = false,
  usePositiveNegativeColors = false,
  asNumber = false,
  yAxisFormatter = undefined,
  yAxisStepSize = undefined,
  yAxisMax = undefined,
  hideLegend = false,
}: {
  series: ApexAxisChartSeries,
  labels: string[],
  height?: number | string,
  width?: number | string,
  thinColumns?: boolean,
  usePositiveNegativeColors?: boolean,
  asNumber?: boolean,
  yAxisFormatter?: (val: number) => string,
  yAxisStepSize?: number,
  yAxisMax?: number,
  hideLegend?: boolean,
}) => {
  const theme = useTheme();
  const formatAsDollar = (val: number) => `$${formatNumberToCurrency(val, 0, {
    style: 'decimal',
    notation: 'compact',
    compactDisplay: 'short',
  })}`;
  const formatAsNumber = (val: number) => formatNumber(val, 0, {
    style: 'decimal',
    notation: 'compact',
    compactDisplay: 'short',
  });

  const colorRange = {
    ranges: [{
      from: -Infinity,
      to: 0,
      color: theme.palette.error.main,
    }, {
      from: 0.00001,
      to: Infinity,
      color: theme.palette.success.main,
    }],
  };

  const plotOptionsBar: ApexPlotOptions['bar'] = {
    borderRadius: 2,
    borderRadiusApplication: 'end',
    columnWidth: thinColumns ? 12 : 24,
  };

  if (usePositiveNegativeColors) {
    plotOptionsBar.colors = colorRange;
  }

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: plotOptionsBar,
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      stepSize: yAxisStepSize,
      max: yAxisMax,
      labels: {
        formatter: yAxisFormatter ?? (asNumber ? formatAsNumber : formatAsDollar),
      },
    },
    xaxis: {
      type: 'category',
      categories: labels,
      labels: {
        rotate: -90,
      },
    },
    tooltip: {
      y: {
        formatter: asNumber ? formatAsNumber : formatAsDollar,
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: !hideLegend,
      position: 'bottom',
      horizontalAlign: 'center',
      showForSingleSeries: true,
      markers: {
        shape: 'circle',
      },
    },
  };

  return (
    <Apexcharts
      type="bar"
      options={options}
      height={height}
      width={width}
      series={series}
    />
  );
};

export const DoubleYAxisColumnChart = ({
  labels,
  series,
  height = undefined,
  width = '100%',
  leftAxisMax = undefined,
  rightAxisMax = undefined,
}: {
  labels: string[],
  series: ApexAxisChartSeries,
  height?: number | string;
  width?: number | string;
  leftAxisMax?: number;
  rightAxisMax?: number;
}) => {
  const seriesWithoutLast = series.slice(0, -1);
  const lastSeries = series[series.length - 1];
  const yaxis: ApexYAxis[] = [];

  seriesWithoutLast.forEach((s, i) => {
    const axis: ApexYAxis = {
      seriesName: s.name,
      show: i === 0,
      min: 0,
      max: leftAxisMax,
      labels: {
        formatter: (val) => `$${formatNumberToCurrency(val, 0, {
          style: 'decimal',
          notation: 'compact',
          compactDisplay: 'short',
        })}`,
      },
    };

    yaxis.push(axis);
  });

  yaxis.push({
    opposite: true,
    seriesName: lastSeries.name,
    labels: {
      formatter: (val) => formatDecimalToPercentage(val, 2),
    },
    min: 0,
    max: rightAxisMax,
  });

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      offsetX: 0,
      offsetY: -5,
      parentHeightOffset: 0,
    },
    grid: {
      padding: {
        left: 25,
        right: 25,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        borderRadiusApplication: 'end',
        columnWidth: 24,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis,
    xaxis: {
      type: 'category',
      categories: labels,
      labels: {
        rotate: -90,
      },
    },
    tooltip: {
      y: [
        ...seriesWithoutLast.map(() => ({
          formatter: (val: number) => formatNumberToCurrency(val, 1, {
            notation: 'compact',
            compactDisplay: 'short',
          }),
        })),
        {
          formatter: (val) => formatDecimalToPercentage(val, 2),
        },
      ],
    },
    fill: {
      opacity: 1,
    },
    stroke: {
      width: [
        ...seriesWithoutLast.map(() => 0),
        3,
      ],
    },
  };

  return (
    <Apexcharts
      type="line"
      options={options}
      height={height}
      width={width}
      series={series}
    />
  );
};
