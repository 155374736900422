import {
  ComponentType,
  MouseEvent, ReactNode, useState,
} from 'react';

import { InsightLevel, InsightType } from 'lib';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import {
  IoMdTrendingUp,
} from 'react-icons/io';
import {
  MdAttachMoney,
  MdCurrencyExchange,
  MdKeyboardArrowDown,
  MdOutlineShowChart,
  MdPeopleOutline,
  MdPersonOutline,
} from 'react-icons/md';
import {
  BoldTypography,
  BriefcaseTimeIcon, LightTooltip,
  TrendingUpDollarIcon, useLabels,
} from 'ui';
import {
  Collapse,
  IconButton,
  Stack, styled, Typography, useTheme,
} from '@mui/material';

import {
  AppreciationExpandedContent, CashFlowExpandedContent, LeaseExpandedContent,
  MaintenanceCostsExpandedContent, MaintenanceEventsExpandedContent, ManagementFeeExpandedContent,
  NOIExpandedContent, TenantsTurnoverExpandedContent, VacancyExpandedContent,
} from './InsightExpandedContent';
import { CaseExpandedContentProps, CaseName } from './utils';
import { AppRiskInsight, PropertyData } from '../../../../../api/properties/types';
import { InsightLevelComponent } from '../../InsightLevelComponent';

export const InsightRow = ({
  insight,
  caseName,
  property,
}: {
  insight: AppRiskInsight | null,
  property: PropertyData,
  caseName: CaseName,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = (e: MouseEvent) => {
    e.stopPropagation();

    setExpanded(!expanded);
  };

  type CaseConfig = {
    icon: ReactNode,
  };

  const caseNameToConfig: Record<CaseName, CaseConfig> = {
    [CaseName.TENANTS_TURNOVER]: {
      icon: <MdPeopleOutline size={24} />,
    },
    [CaseName.MANAGEMENT_FEE]: {
      icon: <HiOutlineCurrencyDollar size={24} />,
    },
    [CaseName.VACANCY]: {
      icon: <MdPersonOutline size={24} />,
    },
    [CaseName.NOI]: {
      icon: <MdAttachMoney size={24} />,
    },
    [CaseName.CASH_FLOW]: {
      icon: <MdCurrencyExchange size={24} />,
    },
    [CaseName.MAINTENANCE_COSTS]: {
      icon: <TrendingUpDollarIcon />,
    },
    [CaseName.APPRECIATION]: {
      icon: <MdOutlineShowChart size={24} />,
    },
    [CaseName.MAINTENANCE_EVENTS]: {
      icon: <IoMdTrendingUp size={24} />,
    },
    [CaseName.LEASE]: {
      icon: <BriefcaseTimeIcon />,
    },
  };

  const ExpandedContent = caseNameToExpandedContent[caseName];
  const level = insight?.level ?? InsightLevel.UNKNOWN;
  const insightType = insight?.insightType ?? InsightType.NEUTRAL;

  return (
    <DrawerInsightContainer className={expanded ? 'expanded' : ''}>
      <Stack
        onClick={toggleExpanded}
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        borderBottom={`1px solid ${theme.palette.divider}`}
        p={3}
        direction="row"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" gap={3}>
          <Stack height={24} width={24} overflow="hidden">
            {caseNameToConfig[caseName]?.icon}
          </Stack>
          <BoldTypography variant="body1">
            {l.dynamic['retention.insight'](caseName)}
          </BoldTypography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={5}>
          {insightType === InsightType.NEUTRAL ? (
            <LightTooltip
              arrow
              title={(
                <Typography
                  variant="body2"
                  sx={{
                    p: 1,
                  }}
                >
                  {l.normal}
                </Typography>
              )}
            >
              <Stack>
                <InsightLevelComponent level={level} insightType={insightType} empty={!insight} />
              </Stack>
            </LightTooltip>
          ) : (
            <InsightLevelComponent level={level} insightType={insightType} empty={!insight} />
          )}
          <IconButton size="small" onClick={toggleExpanded}>
            <MdKeyboardArrowDown
              style={{
                fill: theme.palette.primary.main,
                transform: expanded ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.3s',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
      <Collapse in={expanded}>
        <Stack p={4}>
          {ExpandedContent && <ExpandedContent insight={insight} property={property} />}
        </Stack>
      </Collapse>
    </DrawerInsightContainer>
  );
};

const DrawerInsightContainer = styled(Stack)(({ theme }) => ({
  transition: 'background-color 0.3s, margin 0.3s',
  borderRadius: '8px',
  '&.expanded': {
    backgroundColor: '#F7F7FA',
    margin: `${theme.spacing(2)} 0`,
  },
}));

const caseNameToExpandedContent: Record<CaseName, ComponentType<CaseExpandedContentProps>> = {
  [CaseName.MANAGEMENT_FEE]: ManagementFeeExpandedContent,
  [CaseName.VACANCY]: VacancyExpandedContent,
  [CaseName.NOI]: NOIExpandedContent,
  [CaseName.CASH_FLOW]: CashFlowExpandedContent,
  [CaseName.MAINTENANCE_COSTS]: MaintenanceCostsExpandedContent,
  [CaseName.APPRECIATION]: AppreciationExpandedContent,
  [CaseName.TENANTS_TURNOVER]: TenantsTurnoverExpandedContent,
  [CaseName.MAINTENANCE_EVENTS]: MaintenanceEventsExpandedContent,
  [CaseName.LEASE]: LeaseExpandedContent,
};
