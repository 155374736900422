import { CalculatedRow } from './Row';
import { formatDecimalToPercentage } from '../../../lib/formatFuncs';
import { useLabels } from '../../../lib/translations';
import { useGetPropertyIdParam } from '../state';
import { Form } from '../types';
import { useProjections } from '../useProjections';

export const CapRate = ({ form }: { form: Form }) => {
  const l = useLabels();
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form.watch(), propertyID);

  return (
    <CalculatedRow
      label={l.capRate}
      bottomBorder={false}
      values={projections.years.map((_, i) => {
        const capRate = projections.calculations.getCapRate(i);

        return {
          value: formatDecimalToPercentage(capRate || 0, 2),
          isNegative: capRate < 0,
        };
      })}
    />
  );
};
