import { MouseEvent, useState } from 'react';

import {
  APIUser as User, Role,
  useAnalytics, useImpersonate,
  useInviteUser,
} from 'lib';
import { GoGraph } from 'react-icons/go';
import {
  MdKeyboardArrowDown,
  MdListAlt,
  MdMailOutline,
  MdRemoveRedEye,
  MdSmartphone,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  useLabels, YesNoDialog,
} from 'ui';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

import { QuestionnaireDialog } from './performance-review/QuestionnaireDialog';

export const TableAction = ({
  user, smsText, isAnnualReportSummaryCreate, sendOwnerReport,
}: {
  user: User, smsText: string, isAnnualReportSummaryCreate: Boolean, sendOwnerReport: (emails: string[]) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const analytics = useAnalytics();
  const l = useLabels();
  const [openQuestionnaireDialog, setOpenQuestionnaireDialog] = useState(false);
  const { mutateAsync: impersonate } = useImpersonate();
  const [confirmationEmailOpen, setConfirmationEmailOpen] = useState(false);
  const [confirmationSMSOpen, setConfirmationSMSOpen] = useState(false);

  const { mutateAsync: invitingUser, isLoading: isInvitingUser } = useInviteUser();
  const [confirmSendReportOpen, setConfirmSendReportOpen] = useState(false);

  const handleViewClick = async () => {
    analytics.track('User Impersonated', {
      userEmail: user.email,
      actionName: l.view,
    });

    try {
      const impersonationToken = await impersonate(user.email);
      window.open(`/?impersonation-token=${impersonationToken.data.token}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleInvitationLink = async (communicationOption: 'EMAIL' | 'SMS') => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner',
      ownerEmail: user.email,
      communicationOption,
    });

    try {
      await invitingUser({ email: user.email, communicationOption });
      setConfirmationEmailOpen(false);
      setConfirmationSMSOpen(false);
      toast.success(l.dynamic['pm-dashboard.inviteUser.success'](communicationOption));
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleClickSMS = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - SMS',
      ownerEmail: user.email,
    });

    setConfirmationSMSOpen(true);
  };

  const handleClickEmail = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - Email',
      ownerEmail: user.email,
    });

    setConfirmationEmailOpen(true);
  };

  const sendOwnerReportClick = async () => {
    await sendOwnerReport([user.email]);
  };

  const handleClickOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <YesNoDialog
        open={confirmSendReportOpen}
        onClose={() => setConfirmSendReportOpen(false)}
        title={l['pm-dashboard.report.confirmationTitle']}
        description={l['pm-dashboard.report.confirmationDescription']}
        onSubmit={sendOwnerReportClick}
      />
      <Button
        size="small"
        onClick={(e) => handleClickOpenMenu(e)}
        variant="outlined"
        endIcon={<MdKeyboardArrowDown />}
      >
        {l.manage}
      </Button>
      <Menu onClose={closeMenu} anchorEl={anchorEl} open={open}>
        <MenuItem onClick={handleViewClick}>
          <ListItemIcon>
            <MdRemoveRedEye size={24} />
          </ListItemIcon>
          <ListItemText>
            {l.view}
          </ListItemText>
        </MenuItem>
        {user.roles.includes(Role.OWNER) && (
          <MenuItem
            onClick={() => { setOpenQuestionnaireDialog(true); }}
          >
            <ListItemIcon>
              <MdListAlt size={24} />
            </ListItemIcon>
            <ListItemText>{l.reviewSession}</ListItemText>
          </MenuItem>
        )}
        {(!user.isActivated) && (
          <MenuItem onClick={handleClickEmail}>
            <ListItemIcon>
              <MdMailOutline size={24} />
            </ListItemIcon>
            <ListItemText>{l.InviteByEmail}</ListItemText>
          </MenuItem>
        )}
        {(!!user.phoneNumber && !user.isActivated) && (
          <MenuItem
            onClick={handleClickSMS}
          >
            <ListItemIcon>
              <MdSmartphone size={24} />
            </ListItemIcon>
            <ListItemText>{l.InviteBySms}</ListItemText>
          </MenuItem>
        )}
        {(user.roles.includes(Role.OWNER) && isAnnualReportSummaryCreate) && (
          <MenuItem onClick={() => {
            closeMenu();
            setConfirmSendReportOpen(true);
          }}
          >
            <ListItemIcon>
              <GoGraph size={24} />
            </ListItemIcon>
            <ListItemText>{l.sendReport}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <QuestionnaireDialog open={openQuestionnaireDialog} setOpen={setOpenQuestionnaireDialog} user={user} />
      <YesNoDialog
        open={confirmationEmailOpen}
        onClose={() => setConfirmationEmailOpen(false)}
        isLoading={isInvitingUser}
        onSubmit={() => handleInvitationLink('EMAIL')}
        title={l.areYouSure}
        description={l['pm-dashboard.owner.confirmation']}
      />
      <YesNoDialog
        open={confirmationSMSOpen}
        onClose={() => setConfirmationSMSOpen(false)}
        isLoading={isInvitingUser}
        onSubmit={() => handleInvitationLink('SMS')}
        title={l.dynamic['pm-dashboard.inviteUser.smsConfirm.title'](user.email)}
        // eslint-disable-next-line react/no-danger
        description={<span dangerouslySetInnerHTML={{ __html: smsText }} />}
      />
    </>
  );
};
