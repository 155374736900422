import { useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { recommendationBackgroundURL } from '../../assets';
import { useLabels } from '../../lib/translations';
import { BoldTypography } from '../../typography/BoldTypography';

export const PinkDotBanner = () => {
  const theme = useTheme();
  const l = useLabels();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      alignItems="center"
      px={6}
      py={7}
      height={isMobile ? '120px' : '80px'}
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '6px',
        backgroundImage: `url(${recommendationBackgroundURL})`,
        backgroundSize: 'cover',
      }}
      justifyContent="center"
    >
      <BoldTypography
        sx={{
          textAlign: 'center',
        }}
        variant="body2"
        px={isMobile ? 0 : 25}
      >
        {l['annualReport.didYouKnow']}
      </BoldTypography>
    </Stack>
  );
};
