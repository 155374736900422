import { Fragment, useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import {
  objectEntries, OwnerRiskStatus, ResolvedReason, RiskLevel, sum, useAnalytics,
} from 'lib';
import {
  BoldTypography, capitalize, DonutChart, formatDecimalToPercentage, formatNumber,
  formatNumberToCurrency,
  GaugeChart, InfoTooltip, MissingDataIcon, SpinnerWithLogo, useLabels,
} from 'ui';
import {
  Box,
  Card, CardContent, Divider, List, ListItem, Stack, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useGetOwnerRisksByPM } from '../../../api/owner-risk';
import { RetainTab } from '../state';

type StatusConfig = {
  label: string,
  color: string,
  count: number,
};

export const BlanketScore = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { data, isLoading, isError } = useGetOwnerRisksByPM();

  const ownerRiskCounts: Record<RetainTab, number> = useMemo(() => {
    const allOwnerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
    const noRisk = allOwnerRisks.filter((ownerRisk) => ownerRisk.isNoRisk);
    const risks = allOwnerRisks.filter((ownerRisk) => !ownerRisk.isNoRisk);

    return {
      [RetainTab.NEW]: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.NEW).length,
      [RetainTab.IN_PROGRESS]: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.IN_PROGRESS).length,
      [RetainTab.RESOLVED]: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.RESOLVED).length,
      [RetainTab.NO_RISK]: noRisk.length,
    };
  }, [data]);

  const statusToConfig: Record<OwnerRiskStatus, StatusConfig> = {
    [OwnerRiskStatus.NEW]: {
      label: l['retention.tab.new'], color: theme.palette.error.main, count: ownerRiskCounts.new,
    },
    [OwnerRiskStatus.IN_PROGRESS]: {
      label: l['retention.tab.inProgress'], color: theme.palette.warning.main, count: ownerRiskCounts[RetainTab.IN_PROGRESS],
    },
    [OwnerRiskStatus.RESOLVED]: {
      label: l['retention.tab.resolved'], color: theme.palette.success.main, count: ownerRiskCounts[RetainTab.RESOLVED],
    },
  };

  const labels = [
    statusToConfig[OwnerRiskStatus.NEW].label,
    statusToConfig[OwnerRiskStatus.IN_PROGRESS].label,
    statusToConfig[OwnerRiskStatus.RESOLVED].label,
  ];

  const colors = [
    statusToConfig[OwnerRiskStatus.NEW].color,
    statusToConfig[OwnerRiskStatus.IN_PROGRESS].color,
    statusToConfig[OwnerRiskStatus.RESOLVED].color,
  ];

  const series: ApexOptions['series'] = [
    ownerRiskCounts[RetainTab.NEW],
    ownerRiskCounts[RetainTab.IN_PROGRESS],
    ownerRiskCounts[RetainTab.RESOLVED],
  ];

  return (
    <Card sx={{ height: '100%' }} className={isLoading ? '' : 'retain-blanket-score'}>
      <CardContent sx={{
        height: '100%', display: 'flex', flexDirection: 'column',
      }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BoldTypography variant="h6">
            {l['retention.blanketScore']}
          </BoldTypography>
          <InfoTooltip
            arrow
            isLight
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {l['retention.tooltip.blanketScore']}
              </Typography>
            )}
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Blanket Score',
              });
            }}
          />
        </Stack>
        <Stack flexGrow={1} justifyContent="center">
          {isError && (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <MissingDataIcon iconProps={{ size: 36 }} boxProps={{ sx: { borderRadius: '100%' } }} />
            </Stack>
          )}
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <SpinnerWithLogo size={48} />
            </Stack>
          ) : !isError && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <DonutChart labels={labels} colors={colors} series={series} scale={0.9}>
                <Stack gap={1}>
                  {Object.entries(statusToConfig).map(([status, config]) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={3}
                      key={`blanket-score-label-${status}`}
                      sx={{
                        textWrap: 'nowrap',
                      }}
                    >
                      <Box
                        height={10}
                        width={10}
                        borderRadius={100}
                        sx={{
                          background: config.color,
                        }}
                      />
                      {config.label}
                      {' '}
                      (
                      {statusToConfig[status as OwnerRiskStatus].count}
                      )
                    </Stack>
                  ))}
                </Stack>
              </DonutChart>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

enum PortfolioHealthMetricName {
  ATTEMPTED = 'attempted',
  RETAINED = 'retained',
  RETENTION_RATE = 'retention_rate',
}

const LOW_THRESHOLD = 50;
const MEDIUM_THRESHOLD = 70;

export const PortfolioRisk = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { data, isLoading, isError } = useGetOwnerRisksByPM();

  const getAverageRiskLevel = (score: number) => {
    if (score <= LOW_THRESHOLD) {
      return {
        level: RiskLevel.LOW,
        color: theme.palette.success.main,
      };
    }

    if (score <= MEDIUM_THRESHOLD) {
      return {
        level: RiskLevel.MEDIUM,
        color: theme.palette.warning.main,
      };
    }

    return {
      level: RiskLevel.HIGH,
      color: theme.palette.error.main,
    };
  };

  const gaugeChartData = useMemo(() => {
    const allOwnerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
    const risks = allOwnerRisks.filter((ownerRisk) => !ownerRisk.isNoRisk);
    const lowRisk = risks.filter((ownerRisk) => ownerRisk.riskLevel === RiskLevel.LOW).length;
    const mediumRisk = risks.filter((ownerRisk) => ownerRisk.riskLevel === RiskLevel.MEDIUM).length;
    const highRisk = risks.filter((ownerRisk) => ownerRisk.riskLevel === RiskLevel.HIGH).length;
    const avgRisk = risks.reduce((acc, ownerRisk) => acc + (ownerRisk.risk ?? 0), 0) / risks.length;

    return {
      avgRisk,
      lowRisk,
      mediumRisk,
      highRisk,
    };
  }, [data]);

  const chartDimensions = useChartDimensions();

  const metrics: Record<PortfolioHealthMetricName, string> = useMemo(() => {
    const allOwnerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
    const risks = allOwnerRisks.filter((ownerRisk) => !ownerRisk.isNoRisk);
    const inProgressCount = risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.IN_PROGRESS).length;
    const atRiskCount = risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.NEW).length;

    const resolved = allOwnerRisks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.RESOLVED);
    const retained = resolved.filter(
      (ownerRisk) => ownerRisk.status === OwnerRiskStatus.RESOLVED
        && ownerRisk.resolvedReason !== ResolvedReason.OWNER_WANTS_TO_LEAVE,
    );
    const retainedCount = retained.length;

    const retainedValue = formatNumberToCurrency(sum(retained.map((ownerRisk) => ownerRisk.clientValue)), 0, {
      notation: 'compact',
    });

    return {
      [PortfolioHealthMetricName.ATTEMPTED]: (
        `${formatNumber(inProgressCount, 0)} / ${formatNumber(inProgressCount + atRiskCount, 0)}`
      ),
      [PortfolioHealthMetricName.RETAINED]: `${formatNumber(retainedCount, 0)} (${retainedValue})`,
      [PortfolioHealthMetricName.RETENTION_RATE]: formatDecimalToPercentage(retainedCount / resolved.length, 2),
    };
  }, [data]);

  const metricToLabel: Record<PortfolioHealthMetricName, string> = {
    [PortfolioHealthMetricName.ATTEMPTED]: l['retention.portfolioHealth.attempted'],
    [PortfolioHealthMetricName.RETAINED]: l['retention.portfolioHealth.retained'],
    [PortfolioHealthMetricName.RETENTION_RATE]: l['retention.portfolioHealth.retentionRate'],
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4),
        paddingBottom: `${theme.spacing(0)} !important`,
      }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BoldTypography variant="subtitle1">
            {l['retention.portfolioRisk']}
          </BoldTypography>
          <InfoTooltip
            arrow
            isLight
            title={(
              <List
                dense
                sx={{
                  listStyleType: 'disc',
                  pl: 5,
                  '& .MuiListItem-root': { pl: 0 },
                  '& .MuiListItem-root::marker': { fontSize: '1.5em' },
                }}
              >
                <ListItem sx={{ display: 'list-item' }}>
                  <BoldTypography variant="body2" component="span">
                    {l['retention.portfolioHealth.attempted']}
                    {': '}
                  </BoldTypography>
                  <Typography variant="body2" component="span">
                    {l['retention.tooltip.portfolioHealth.attempted']}
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <BoldTypography variant="body2" component="span">
                    {l['retention.portfolioHealth.retained']}
                    {': '}
                  </BoldTypography>
                  <Typography variant="body2" component="span">
                    {l['retention.tooltip.portfolioHealth.retained']}
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <BoldTypography variant="body2" component="span">
                    {l['retention.portfolioHealth.retentionRate']}
                    {': '}
                  </BoldTypography>
                  <Typography variant="body2" component="span">
                    {l['retention.tooltip.portfolioHealth.retentionRate']}
                  </Typography>
                </ListItem>
              </List>
            )}
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Portfolio Health',
              });
            }}
          />
        </Stack>
        <Stack flexGrow={1} justifyContent="center">
          {isError && (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <MissingDataIcon iconProps={{ size: 36 }} boxProps={{ sx: { borderRadius: '100%' } }} />
            </Stack>
          )}
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <SpinnerWithLogo size={48} />
            </Stack>
          ) : !isError && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{
                '& .apexcharts-graphical .apexcharts-datalabels-group > text:first-of-type': {
                  fontSize: theme.typography.h6.fontSize,
                  fontWeight: theme.typography.h6.fontWeight,
                },
                '& .apexcharts-graphical .apexcharts-datalabels-group > text:last-of-type': {
                  fontSize: theme.typography.overline.fontSize,
                  fill: `${theme.palette.text.secondary} !important`,
                },
              }}
            >
              <Stack flexGrow={1} alignItems="center">
                <Stack>
                  <GaugeChart
                    colors={[getAverageRiskLevel(gaugeChartData.avgRisk).color]}
                    series={[gaugeChartData.avgRisk]}
                    totalLabel="Avg. all owners"
                    totalValue={capitalize(getAverageRiskLevel(gaugeChartData.avgRisk).level)}
                    height={chartDimensions.height}
                    width={chartDimensions.width}
                    showTotal
                  />
                </Stack>
              </Stack>
              <Stack
                gap={1}
                flexGrow={1}
                borderLeft={{ xs: 'none', md: `1px solid ${theme.palette.divider}` }}
                pl={2}
                pt={2}
                height="fit-content"
              >
                {objectEntries(metrics).map(([metric, value], i) => (
                  <Fragment key={`portfolio-health-metric-${metric}`}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} flexGrow={1}>
                      <Typography variant="body2">
                        {metricToLabel[metric]}
                      </Typography>
                      <BoldTypography variant="body2">
                        {value}
                      </BoldTypography>
                    </Stack>
                    {i < objectEntries(metrics).length - 1 && (
                      <Divider sx={{ my: 1 }} />
                    )}
                  </Fragment>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const useChartDimensions = () => {
  const theme = useTheme();
  const firstBreakpoint = useMediaQuery(theme.breakpoints.down(1480));
  const secondBreakpoint = useMediaQuery(theme.breakpoints.down(1360));
  const thirdBreakpoint = useMediaQuery(theme.breakpoints.down(1290));
  const fourthBreakpoint = useMediaQuery(theme.breakpoints.down(1200));
  const fifthBreakpoint = useMediaQuery(theme.breakpoints.down(1040));
  const sixthBreakpoint = useMediaQuery(theme.breakpoints.down(950));
  const seventhBreakpoint = useMediaQuery(theme.breakpoints.down(900));

  return useMemo(() => {
    if (seventhBreakpoint) {
      return {
        height: 200,
        width: undefined,
      };
    }

    if (sixthBreakpoint) {
      return {
        height: 170,
        width: 170,
      };
    }

    if (fifthBreakpoint) {
      return {
        height: 200,
        width: 200,
      };
    }

    if (fourthBreakpoint) {
      return {
        height: 220,
        width: 220,
      };
    }

    if (thirdBreakpoint) {
      return {
        height: 220,
        width: 220,
      };
    }

    if (secondBreakpoint) {
      return {
        height: 250,
        width: 250,
      };
    }

    if (firstBreakpoint) {
      return {
        height: 280,
        width: 280,
      };
    }

    return {
      height: 300,
      width: undefined,
    };
  }, [firstBreakpoint, secondBreakpoint, thirdBreakpoint, fourthBreakpoint, fifthBreakpoint, sixthBreakpoint, seventhBreakpoint]);
};
