import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  APIUser, Role,
  useGetOwnersToPropertyIDs,
  useHideChat,
} from 'lib';
import lodash from 'lodash';
import { IoIosArrowUp } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import {
  AnnualReport, annualReportLeadershipSummaryState, BoldTypography,
  InvertedButton, JudgeHammerCircle, RichEditor, showLegalSummaryState, useLabels,
} from 'ui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton,
  List,
  ListItem,
  Stack, Switch, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useSignal } from '@preact/signals-react';

type AutocompleteOption = {
  label: string,
  value: string,
};

const Actions = ({
  onClose,
  onSave,
  isLoading,
}: {
  onClose: () => void,
  onSave: (summary: string, showLegalSummary: boolean) => void,
  isLoading: boolean,
}) => {
  const l = useLabels();

  const summaryHtml = annualReportLeadershipSummaryState.value;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      pt={3}
      gap={2}
    >
      <Button
        size="small"
        variant="text"
        onClick={onClose}
      >
        {l.cancel}
      </Button>
      <InvertedButton
        disabled={isLoading || !summaryHtml || summaryHtml === '<p></p>'}
        size="small"
        onClick={() => onSave(summaryHtml, showLegalSummaryState.value)}
      >
        {l.save}
      </InvertedButton>
    </Stack>
  );
};

const useAutocompleteOptions = (users: APIUser[]) => {
  const foundBestOwner = useSignal(false);
  const [selectedOwner, setSelectedOwner] = useState<AutocompleteOption | null>(null);
  const { data: ownersToProperties } = useGetOwnersToPropertyIDs();

  const options = users.filter((ow) => ow.roles.includes(Role.OWNER)).map((user) => ({
    label: `${user.name} (${user.email})`,
    value: user.id,
  })).sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (foundBestOwner.value) return;

    if (options && options.length > 0) {
      const firstOwner = options[0];

      if (ownersToProperties?.[firstOwner.value]?.length) {
        setSelectedOwner(firstOwner);
        foundBestOwner.value = true;
      } else {
        options.forEach((owner) => {
          if (!foundBestOwner.value && ownersToProperties?.[owner.value]?.length) {
            setSelectedOwner(owner);
            foundBestOwner.value = true;
          }
        });

        if (!foundBestOwner.value) {
          foundBestOwner.value = true;
          setSelectedOwner(options[0]);
        }
      }
    }
  }, [options, ownersToProperties, foundBestOwner.value]);

  return {
    options,
    selectedOwner,
    setSelectedOwner,
  };
};

export const LeadershipSummaryDialog = ({
  open,
  onClose,
  onSave,
  isLoading = false,
  users,
  initialSummary,
}: {
  open: boolean,
  users: APIUser[]
  onClose: () => void,
  onSave: (summary: string, showLegalSummary: boolean) => void,
  isLoading?: boolean,
  initialSummary: string,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { options, selectedOwner, setSelectedOwner } = useAutocompleteOptions(users);

  const closeDialog = () => {
    onClose();
  };

  useHideChat(open);

  const containerClassName = 'leadership-summary-text';
  const [reportElement, setReportElement] = useState<HTMLDivElement | null>(null);

  const annualReportRef = useCallback((node: HTMLDivElement | null) => {
    setReportElement(node);
  }, []);

  const scrollToBottom = useCallback(() => {
    if (!reportElement) return;

    reportElement.scrollTo({
      top: reportElement.scrollHeight,
      behavior: 'smooth',
    });
  }, [reportElement]);

  const debouncedTriggerScroll = lodash.debounce(scrollToBottom, 500);

  useEffect(() => {
    if (!reportElement) return () => { };

    const observer = new MutationObserver((mutations) => {
      const relevantClassNames = ['leadership-summary-text'];

      const hasRelevantChanges = mutations.some((mutation) => (
        Array.from([...mutation.addedNodes, ...mutation.removedNodes]).some((node) => {
          if (!(node instanceof Element)) return false;

          if (relevantClassNames.some((className) => node.classList.contains(className))) {
            return true;
          }

          return !!node.parentElement?.classList.contains(containerClassName);
        })));

      if (hasRelevantChanges) {
        debouncedTriggerScroll?.();
      }
    });

    observer.observe(reportElement, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [reportElement, debouncedTriggerScroll]);

  useEffect(() => {
    if (!reportElement) return;

    reportElement.scrollTo({
      top: reportElement.scrollHeight,
      behavior: 'smooth',
    });
  }, [reportElement]);

  const getOwnerFullName = (ownerEmail: string) => {
    const selectedUser = users.find((ow) => ow.id === ownerEmail);

    if (selectedUser) {
      return selectedUser.name;
    }

    return '';
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullScreen
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['owner.summaryTitle']}
          </BoldTypography>
          <IconButton onClick={closeDialog}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{
        p: `${theme.spacing(4)} !important`,
        overflowY: 'hidden',
        height: '100%',
        display: 'flex',
      }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            height: '100%',
            maxHeight: '100%',
            overflowY: 'scroll',
          }}
        >
          <Grid
            item
            md={12}
            lg={5.25}
          >
            <Box sx={{
              display: 'flex', flexDirection: 'column', height: '100%',
            }}
            >
              <Stack
                sx={{ flex: 1 }}
              >
                <RichEditor
                  placeholder={l['annualReport.owner.SummaryPlaceholder']}
                  initialContent={initialSummary}
                  onContentChange={(html) => {
                    annualReportLeadershipSummaryState.value = html;
                  }}
                  menuBarContainerSx={{
                    py: 4,
                  }}
                />
              </Stack>

              <Stack>
                <Accordion
                  sx={{
                    backgroundColor: '#F7F7FA;',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '6px',
                    marginTop: '20px',
                    padding: '10px',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowUp />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography component="span" variant="body2">
                      <span style={{ fontWeight: 'bold' }}>
                        {l['annualReport.previewReport.leadership.summarizeTitle']}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List sx={{
                      listStyleType: 'disc',
                      pl: 2,
                      paddingLeft: 4,
                    }}
                    >
                      <ListItem sx={{
                        display: 'list-item',
                        paddingLeft: 0,
                        marginBottom: 2,
                      }}
                      >
                        <Typography component="span" variant="body2">
                          <span style={{ fontWeight: 'bold' }}>
                            {l['annualReport.previewReport.leadership.summarizeSuccessesTitle']}
                          </span>
                          {l['annualReport.previewReport.leadership.summarizeSuccessesDescription']}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
                        <Typography component="span" variant="body2">
                          <span style={{ fontWeight: 'bold' }}>
                            {l['annualReport.previewReport.leadership.acknowledgeHurdlesTitle']}
                          </span>
                          {l['annualReport.previewReport.leadership.acknowledgeHurdlesDescription']}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
                        <Typography component="span" variant="body2">
                          <span style={{ fontWeight: 'bold' }}>
                            {l['annualReport.previewReport.leadership.setClearVisionTitle']}
                          </span>
                          {l['annualReport.previewReport.leadership.setClearVisionDescription']}
                        </Typography>
                      </ListItem>
                    </List>
                    <Divider sx={{ m: 0 }} />
                    <Stack pt="20px">
                      <Typography component="span" variant="body2">
                        <span style={{ fontWeight: 'bold' }}>
                          {l['annualReport.previewReport.leadership.exampleTitle']}
                        </span>
                        {l['annualReport.previewReport.leadership.exampleDescription']}
                      </Typography>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>
              <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '6px',
                marginTop: '20px',
                padding: '10px',
              }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Stack direction="row" alignItems="center" gap={2} pb={4}>
                    <JudgeHammerCircle />
                    <BoldTypography variant="body1">{l['annualReport.legislationOverview.title']}</BoldTypography>
                  </Stack>
                  <Switch
                    color="primary"
                    checked={showLegalSummaryState.value}
                    onChange={() => { showLegalSummaryState.value = !showLegalSummaryState.value; }}
                  />
                </Stack>
                <Typography variant="body2">
                  {l['annualReport.legislationOverview.description']}
                </Typography>
              </Stack>

            </Box>
          </Grid>
          {!isLowerThanLg && (
            <Grid item md={12} lg={6.75} sx={{ height: '100%' }}>
              <Stack flexGrow={1} height="100%">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={5}
                  sx={{
                    padding: '12px',
                    border: '1px solid rgba(39, 38, 41, 0.12)',
                    borderTopRightRadius: '6px',
                    borderTopLeftRadius: '6px',
                  }}
                >
                  <BoldTypography variant="h6">
                    {l['annualReport.previewReport']}
                  </BoldTypography>
                  <Stack flexGrow={1} alignItems="flex-end" maxWidth="50%">
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={selectedOwner}
                      options={options}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onChange={(_, value) => setSelectedOwner(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            sx: {
                              borderRadius: '8px',
                            },
                            ...params.InputProps,
                          }}
                          placeholder="Select owner for preview"
                          error={false}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
                <Stack
                  flexGrow={1}
                  className={containerClassName}
                  sx={{
                    overflowY: 'auto',
                    border: '1px solid rgba(39, 38, 41, 0.12)',
                    boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.1)',
                    borderBottomRightRadius: '6px',
                    borderBottomLeftRadius: '6px',
                    minHeight: 0, // This allows scrolling to work properly
                  }}
                  ref={annualReportRef}
                >
                  <AnnualReport
                    ownerFullName={getOwnerFullName(selectedOwner?.value ?? '')}
                    inPreview
                    userActions={undefined}
                    showHeader={false}
                    owner={selectedOwner?.value ?? ''}
                  />
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Actions
          onClose={closeDialog}
          onSave={onSave}
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};
