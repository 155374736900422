import { useMemo } from 'react';

import {
  objectEntries, OwnerRiskSeverity, useAnalytics,
} from 'lib';
import { Fragment } from 'react/jsx-runtime';
import {
  BoldTypography, capitalize, DonutChart, formatNumberToCurrency, InfoTooltip, MissingDataIcon, SpinnerWithLogo, useLabels,
} from 'ui';
import {
  Box, Card, CardContent, Divider, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { useGetOwnerRisksByPM } from '../../../api/owner-risk';

const useChartScale = () => {
  const theme = useTheme();
  const firstBreakpoint = useMediaQuery(theme.breakpoints.down(1430));
  const secondBreakpoint = useMediaQuery(theme.breakpoints.down(1360));
  const thirdBreakpoint = useMediaQuery(theme.breakpoints.down(1270));
  const fourthBreakpoint = useMediaQuery(theme.breakpoints.down(1200));
  const fifthBreakpoint = useMediaQuery(theme.breakpoints.down(1020));
  const sixthBreakpoint = useMediaQuery(theme.breakpoints.down(900));

  return useMemo(() => {
    if (sixthBreakpoint) {
      return 1.2;
    }

    if (fifthBreakpoint) {
      return 0.6;
    }

    if (fourthBreakpoint) {
      return 0.8;
    }

    if (thirdBreakpoint) {
      return 0.6;
    }

    if (secondBreakpoint) {
      return 0.8;
    }

    if (firstBreakpoint) {
      return 1;
    }

    return 1.2;
  }, [firstBreakpoint, secondBreakpoint, thirdBreakpoint, fourthBreakpoint, fifthBreakpoint]);
};

export const PriorityChart = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { data, isLoading, isError } = useGetOwnerRisksByPM();
  const chartScale = useChartScale();

  const ownerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
  const highSeverity = ownerRisks.filter((ownerRisk) => ownerRisk.severity === OwnerRiskSeverity.HIGH);
  const mediumSeverity = ownerRisks.filter((ownerRisk) => ownerRisk.severity === OwnerRiskSeverity.MEDIUM);
  const lowSeverity = ownerRisks.filter((ownerRisk) => ownerRisk.severity === OwnerRiskSeverity.LOW);
  const highSeverityValue = highSeverity.reduce((acc, ownerRisk) => acc + ownerRisk.clientValue, 0);
  const mediumSeverityValue = mediumSeverity.reduce((acc, ownerRisk) => acc + ownerRisk.clientValue, 0);
  const lowSeverityValue = lowSeverity.reduce((acc, ownerRisk) => acc + ownerRisk.clientValue, 0);

  const metrics = {
    [OwnerRiskSeverity.HIGH]: {
      label: capitalize(OwnerRiskSeverity.HIGH),
      legendLabel: `${capitalize(OwnerRiskSeverity.HIGH)} (${highSeverity.length})`,
      color: theme.palette.error.main,
      value: highSeverityValue,
      valueLabel: `${formatNumberToCurrency(highSeverityValue, 0, { notation: 'compact' })}`,
    },
    [OwnerRiskSeverity.MEDIUM]: {
      label: capitalize(OwnerRiskSeverity.MEDIUM),
      legendLabel: `${capitalize(OwnerRiskSeverity.MEDIUM)} (${mediumSeverity.length})`,
      color: theme.palette.warning.main,
      value: mediumSeverityValue,
      valueLabel: `${formatNumberToCurrency(mediumSeverityValue, 0, { notation: 'compact' })}`,
    },
    [OwnerRiskSeverity.LOW]: {
      label: capitalize(OwnerRiskSeverity.LOW),
      legendLabel: `${capitalize(OwnerRiskSeverity.LOW)} (${lowSeverity.length})`,
      color: theme.palette.success.main,
      value: lowSeverityValue,
      valueLabel: `${formatNumberToCurrency(lowSeverityValue, 0, { notation: 'compact' })}`,
    },
  };

  const series = Object.values(metrics).map((metric) => metric.value);
  const labels = Object.values(metrics).map((metric) => metric.label);
  const colors = Object.values(metrics).map((metric) => metric.color);

  return (
    <Card sx={{ height: '100%' }} className={isLoading ? '' : 'retain-blanket-score'}>
      <CardContent sx={{
        height: '100%', display: 'flex', flexDirection: 'column', p: 3, pb: '0 !important',
      }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BoldTypography variant="subtitle1">
            {l['retention.table.priority']}
          </BoldTypography>
          <InfoTooltip
            arrow
            isLight
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {l['retention.tooltip.priority']}
              </Typography>
            )}
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Priority',
              });
            }}
          />
        </Stack>
        <Stack flexGrow={1} justifyContent="center">
          {isError && (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <MissingDataIcon iconProps={{ size: 36 }} boxProps={{ sx: { borderRadius: '100%' } }} />
            </Stack>
          )}
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <SpinnerWithLogo size={48} />
            </Stack>
          ) : !isError && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              pt={2}
            >
              <Stack flexGrow={1} ml={-4} alignItems="center">
                <DonutChart tooltipAsDollars series={series} labels={labels} colors={colors} scale={chartScale} />
              </Stack>
              <Stack
                gap={1}
                flexGrow={1}
                borderLeft={{ xs: 'none', md: `1px solid ${theme.palette.divider}` }}
                pl={2}
                height="fit-content"
              >
                {objectEntries(metrics).map(([status, metric], i) => (
                  <Fragment key={`risk-breakdown-metric-${status}`}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={3}
                      sx={{ textWrap: 'nowrap' }}
                    >
                      <Box
                        height={10}
                        width={10}
                        borderRadius={100}
                        sx={{
                          background: metric.color,
                        }}
                      />
                      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} flexGrow={1}>
                        <Typography variant="body2">
                          {metric.legendLabel}
                        </Typography>
                        <BoldTypography variant="body2">
                          {metric.valueLabel}
                        </BoldTypography>
                      </Stack>
                    </Stack>
                    {i < objectEntries(metrics).length - 1 && (
                      <Divider sx={{ my: 1 }} />
                    )}
                  </Fragment>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
