import { useEffect } from 'react';

import { signal } from '@preact/signals-react';

import { Permissions, useAuth } from './useAuth';
import {
  useIsAnnualReportEnabledFF,
  useIsB2CReferralProgramEnabledFF,
  useIsCraneAlternativeTextEnabledFF,
  useIsCraneCommunityNavItemEnabledFF,
  useIsFreemiumVersionEnabledFF,
  useIsGrowProductEnabledFF,
  useIsGrowthPmPartnersEnabledFF,
  useIsInviteProspectsEnabledFF,
  useIsLegacyPMFF, useIsMarketplaceListViewEnabledFF, useIsOBPEnabledFF,
  useIsOwnerLeadsZapierIntegrationEnabled,
  useIsPmPropertiesDashboardEnabledFF,
  useIsRetainProductEnabledFF,
  useIsRiskV2EnabledFF,
  useIsUserGuidingEnabledFF,
  useListingCardGrossYieldAsDollarFF,
} from './useFeatureFlag';
import { useGetAnnualInvitationReport } from '../api/annual-report-invitation';

type Feature = {
  isEnabled: boolean,
  isLoading: boolean,
};

export type Features = {
  isCreateAdminEnabled: boolean,
  isLoading: boolean,
  isOwnerDashboardEnabled: boolean,
  isMarketplaceEnabled: boolean,
  isGrowProductEnabled: boolean,
  isRetainProductEnabled: boolean,
  isRetainNavItemLocked: boolean,
  isPmLeadsViewEnabled: boolean,
  isPmReferralLeadsEnabled: boolean,
  isPmBuyerLeadsEnabled: boolean,
  isPmSellerLeadsEnabled: boolean,
  isPmBuyBoxLeadsEnabled: boolean,
  isPmProspectLeadsEnabled: boolean,
  isMarketplaceLeadsViewEnabled: boolean,
  isObpEnabled: boolean,
  isPmAppEnabled: boolean,
  isPmPropertiesDashboardEnabled: boolean,
  isReferralPartnersAppEnabled: boolean,
  isBillingEnabled: boolean,
  isB2CReferralProgramEnabled: boolean,
  isSettingsViewEnabled: boolean,
  isGrowthPmPartnersEnabled: boolean,
  isGrowthAgentPartnersEnabled: boolean,
  isPmHomePageEnabled: boolean,
  isContactsPageEnabled: boolean,
  isFreemium: boolean,
  isFreemiumPM: boolean,
  isReferOwnerNavWidgetEnabled: boolean,
  isUpgradeGrowProductNavWidgetEnabled: boolean,
  isShareListingEnabled: boolean,
  isLeadsThirdPartyIntegrationEnabled: boolean,
  isCraneAlternativeTextEnabled: boolean,
  isCraneCommunityNavItemEnabled: boolean,
  isInviteProspectsEnabled: boolean,
  isUserGuidingEnabled: boolean,
  isAnnualReportEnabled: boolean,
  isAnnualReportPmEnabled: boolean,
  isRiskV2Enabled: boolean,
  isMarketplaceListViewEnabled: boolean,
  isMarketplaceGrossYieldAsDollar: boolean,
};

export const featuresSignal = signal<Features>({
  isLoading: true,
} as Features);

export const useFeatures = (): Features => featuresSignal.value;

export const useLoadAndCacheFeatures = () => {
  const { user } = useAuth();

  const permissions = user?.permissions;

  const { isEnabled: isMarketplaceEnabled, isLoading: isLoadingMarketplaceEnabled } = useIsMarketplaceEnabled(permissions);
  const { isEnabled: isGrowProductEnabled, isLoading: isLoadingGrowProductEnabled } = useIsGrowProductEnabled(permissions);
  const { isEnabled: isRetainProductEnabled, isLoading: isLoadingRetainProductEnabled } = useIsRetainProductEnabled(permissions);
  const { isEnabled: isRetainNavItemLocked, isLoading: isLoadingRetainNavItemLocked } = useIsRetainNavItemLocked(permissions);
  const { isEnabled: isPmLeadsViewEnabled, isLoading: isLoadingPmLeadsViewEnabled } = useIsPmLeadsViewEnabled(permissions);
  const { isEnabled: isBillingEnabled, isLoading: isLoadingBillingEnabled } = useIsBillingEnabled(permissions);
  const { isEnabled: isObpEnabled, isLoading: isLoadingObpEnabled } = useIsObpEnabled(permissions);
  const { isEnabled: isPmAppEnabled, isLoading: isLoadingPmAppEnabled } = useIsPmAppEnabled(permissions);
  const { isEnabled: isShareListingEnabled, isLoading: isLoadingShareListingLocked } = useIsShareListingEnabled();
  const { isEnabled: isAnnualReportEnabled, isLoading: isAnnualReportLoading } = useIsAnnualReportEnabled(permissions);
  const { isEnabled: isCreateAdminEnabled, isLoading: isLoadingCreateAdminEnabled } = useIsCreateAdminEnabled(permissions);

  const { isEnabled: isAnnualReportPmEnabled, isLoading: isAnnualReportPmLoading } = useIsAnnualReportPmEnabled(permissions);

  const {
    isEnabled: isPmProspectLeadsEnabled,
    isLoading: isLoadingPmProspectLeadsEnabled,
  } = useIsPmProspectLeadsEnabled(permissions);
  const {
    isEnabled: isPmReferralLeadsEnabled,
    isLoading: isLoadingPmReferralLeadsEnabled,
  } = useIsPmReferralLeadsEnabled(permissions);
  const {
    isEnabled: isPmBuyerLeadsEnabled,
    isLoading: isLoadingPmBuyerLeadsEnabled,
  } = useIsPmBuyerLeadsEnabled(permissions);
  const {
    isEnabled: isPmSellerLeadsEnabled,
    isLoading: isLoadingPmSellerLeadsEnabled,
  } = useIsPmSellerLeadsEnabled(permissions);
  const {
    isEnabled: isPmBuyBoxLeadsEnabled,
    isLoading: isLoadingPmBuyBoxLeadsEnabled,
  } = useIsPmBuyBoxLeadsEnabled(permissions);
  const {
    isEnabled: isOwnerDashboardEnabled,
    isLoading: isLoadingOwnerDashboardEnabled,
  } = useIsOwnerDashboardEnabled(permissions);
  const {
    isEnabled: isMarketplaceLeadsViewEnabled,
    isLoading: isLoadingMarketplaceLeadsViewEnabled,
  } = useIsMarketplaceLeadsViewEnabled(permissions);
  const {
    isEnabled: isPmPropertiesDashboardEnabled,
    isLoading: isLoadingPmPropertiesDashboardEnabled,
  } = useIsPmPropertiesDashboardEnabled(permissions);
  const {
    isEnabled: isReferralPartnersAppEnabled,
    isLoading: isLoadingReferralPartnersAppEnabled,
  } = useIsReferralPartnersAppEnabled(permissions);
  const {
    isEnabled: isB2CReferralProgramEnabled,
    isLoading: isLoadingB2CReferralProgramEnabled,
  } = useIsB2CReferralProgramEnabled(permissions);
  const {
    isEnabled: isGrowthPmPartnersEnabled,
    isLoading: isLoadingGrowthPmPartnersEnabled,
  } = useIsGrowthPmPartnersEnabled(permissions);
  const {
    isEnabled: isPmHomePageEnabled,
    isLoading: isLoadingPmHomePageEnabled,
  } = useIsPmHomePageEnabled(permissions);
  const {
    isEnabled: isGrowthAgentPartnersEnabled,
    isLoading: isLoadingGrowthAgentPartnersEnabled,
  } = useIsGrowthAgentPartnersEnabled(permissions);
  const {
    isEnabled: isContactsPageEnabled,
    isLoading: isLoadingContactsPageEnabled,
  } = useIsContactsPageEnabled(permissions);
  const {
    isEnabled: isFreemiumPM,
    isLoading: isLoadingFreemiumPm,
  } = useIsFreemiumPM(permissions);
  const { isEnabled: isFreemium, isLoading: isLoadingFreemium } = useIsFreemium();
  const {
    isEnabled: isReferOwnerNavWidgetEnabled,
    isLoading: isLoadingReferOwnerNavWidgetEnabled,
  } = useIsReferOwnerNavWidgetEnabled(permissions);
  const {
    isEnabled: isUpgradeGrowProductNavWidgetEnabled,
    isLoading: isLoadingUpgradeGrowProductNavWidgetEnabled,
  } = useIsUpgradeGrowProductNavWidgetEnabled(permissions);
  const {
    isEnabled: isLeadsThirdPartyIntegrationEnabled,
    isLoading: isLoadingLeadsThirdPartyIntegrationEnabled,
  } = useIsLeadsThirdPartyIntegrationEnabled();
  const {
    isEnabled: isCraneAlternativeTextEnabled,
    isLoading: isLoadingCraneAlternativeTextEnabled,
  } = useIsCraneAlternativeTextEnabled();
  const {
    isEnabled: isCraneCommunityNavItemEnabled,
    isLoading: isLoadingCraneCommunityNavItemEnabled,
  } = useIsCraneCommunityNavItemEnabled(permissions);
  const {
    isEnabled: isInviteProspectsEnabled,
    isLoading: isLoadingInviteProspectsEnabled,
  } = useIsInviteProspectsEnabled(permissions);
  const {
    isEnabled: isUserGuidingEnabled,
    isLoading: isLoadingUserGuidingEnabled,
  } = useIsUserGuidingEnabled();
  const {
    isEnabled: isRiskV2Enabled,
    isLoading: isLoadingRiskV2Enabled,
  } = useIsRiskV2Enabled();
  const {
    isEnabled: isMarketplaceListViewEnabled,
    isLoading: isLoadingMarketplaceListViewEnabled,
  } = useIsMarketplaceListViewEnabled();
  const {
    isEnabled: isMarketplaceGrossYieldAsDollar,
    isLoading: isLoadingMarketplaceGrossYieldAsDollar,
  } = useIsMarketplaceGrossYieldAsDollar();

  const isLoading = [
    isLoadingOwnerDashboardEnabled, isLoadingMarketplaceEnabled, isLoadingGrowProductEnabled,
    isLoadingRetainProductEnabled, isLoadingPmLeadsViewEnabled, isLoadingMarketplaceLeadsViewEnabled,
    isLoadingObpEnabled, isLoadingPmAppEnabled, isLoadingPmPropertiesDashboardEnabled,
    isLoadingReferralPartnersAppEnabled, isLoadingBillingEnabled, isLoadingPmProspectLeadsEnabled,
    isLoadingB2CReferralProgramEnabled, isLoadingPmReferralLeadsEnabled, isLoadingGrowthPmPartnersEnabled,
    isLoadingPmHomePageEnabled, isLoadingGrowthAgentPartnersEnabled, isLoadingContactsPageEnabled,
    isLoadingPmBuyerLeadsEnabled, isLoadingPmSellerLeadsEnabled, isLoadingPmBuyBoxLeadsEnabled,
    isLoadingFreemiumPm, isLoadingReferOwnerNavWidgetEnabled, isLoadingUpgradeGrowProductNavWidgetEnabled,
    isLoadingRetainNavItemLocked, isLoadingShareListingLocked, isLoadingCreateAdminEnabled, isLoadingFreemium,
    isLoadingLeadsThirdPartyIntegrationEnabled, isLoadingCraneAlternativeTextEnabled, isLoadingCraneCommunityNavItemEnabled,
    isLoadingInviteProspectsEnabled, isLoadingUserGuidingEnabled, isAnnualReportLoading, isAnnualReportPmLoading,
    isLoadingRiskV2Enabled, isLoadingMarketplaceListViewEnabled, isLoadingMarketplaceGrossYieldAsDollar,
  ].some((f) => !!f);

  const features = {
    isLoading,
    isCreateAdminEnabled,
    isOwnerDashboardEnabled,
    isMarketplaceEnabled,
    isGrowProductEnabled,
    isRetainProductEnabled,
    isRetainNavItemLocked,
    isPmLeadsViewEnabled,
    isPmReferralLeadsEnabled,
    isPmBuyerLeadsEnabled,
    isPmSellerLeadsEnabled,
    isPmBuyBoxLeadsEnabled,
    isPmProspectLeadsEnabled,
    isMarketplaceLeadsViewEnabled,
    isObpEnabled,
    isPmAppEnabled,
    isPmPropertiesDashboardEnabled,
    isReferralPartnersAppEnabled,
    isBillingEnabled,
    isB2CReferralProgramEnabled,
    isSettingsViewEnabled: isPmAppEnabled || isBillingEnabled,
    isGrowthPmPartnersEnabled,
    isGrowthAgentPartnersEnabled,
    isContactsPageEnabled,
    isPmHomePageEnabled,
    isFreemium,
    isFreemiumPM,
    isReferOwnerNavWidgetEnabled,
    isUpgradeGrowProductNavWidgetEnabled,
    isShareListingEnabled,
    isLeadsThirdPartyIntegrationEnabled,
    isCraneAlternativeTextEnabled,
    isCraneCommunityNavItemEnabled,
    isInviteProspectsEnabled,
    isUserGuidingEnabled,
    isAnnualReportEnabled,
    isAnnualReportPmEnabled,
    isRiskV2Enabled,
    isMarketplaceListViewEnabled,
    isMarketplaceGrossYieldAsDollar,
  };

  useEffect(() => {
    featuresSignal.value = features;
  }, [features]);

  return null;
};

const useIsOwnerDashboardEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPMFF();
  const { value: isRetainProductEnabledFF, loading: isLoadingRetainProductFF } = useIsRetainProductEnabledFF();

  const isLoading = loadingIsLegacyPM || isLoadingRetainProductFF;

  return {
    isEnabled: (isLegacyPM || isRetainProductEnabledFF) && !!permissions?.viewDashboard,
    isLoading,
  };
};

const useIsMarketplaceEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPMFF();
  const { value: isGrowthEnabled, loading: isLoadingGrowth } = useIsGrowProductEnabledFF();
  const { isEnabled: isFreemium, isLoading: isLoadingFreemium } = useIsFreemium();

  const legacyMarketplaceEnabled = isLegacyPM && permissions?.viewMarketplace;
  const isMarketplaceEnabled = isGrowthEnabled && permissions?.viewMarketplace;
  const isFreemiumMarketplaceEnabled = isFreemium && permissions?.viewMarketplace;
  const isLoading = (
    loadingIsLegacyPM || isLoadingGrowth || isLoadingFreemium
  );

  return {
    isEnabled: legacyMarketplaceEnabled || isMarketplaceEnabled || isFreemiumMarketplaceEnabled,
    isLoading,
  };
};

const useIsCreateAdminEnabled = (permissions: Permissions | undefined): Feature => {
  const createAdminEnabled = permissions?.createAdminUser;

  return {
    isEnabled: createAdminEnabled ?? false,
    isLoading: false,
  };
};

const useIsGrowProductEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isGrowthProductEnabled, loading: isLoadingGrowthProductFF } = useIsGrowProductEnabledFF();
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isEnabled = (isFreemiumPM || isGrowthProductEnabled) && permissions?.viewGrow;
  const isLoading = (
    isLoadingGrowthProductFF || loadingIsFreemiumPM
  );

  return {
    isEnabled,
    isLoading,
  };
};

const useIsRetainProductEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isRetainProductEnabled, loading: isLoadingRetainProductFF } = useIsRetainProductEnabledFF();
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isEnabled = !isFreemiumPM && isRetainProductEnabled && permissions?.viewRetain;
  const isLoading = (
    isLoadingRetainProductFF || loadingIsFreemiumPM
  );

  return {
    isEnabled,
    isLoading,
  };
};

const useIsRetainNavItemLocked = (permissions: Permissions | undefined): Feature => {
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  return {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  };
};

const useIsPmLeadsViewEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isMarketplaceEnabled, isLoading: loadingIsMarketplaceEnabled } = useIsMarketplaceEnabled(permissions);

  const isLoading = loadingIsMarketplaceEnabled;

  return {
    isEnabled: isMarketplaceEnabled && !!permissions?.viewLeads,
    isLoading,
  };
};

const useIsMarketplaceLeadsViewEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isMarketplaceEnabled, isLoading: loadingIsMarketplaceEnabled } = useIsMarketplaceEnabled(permissions);
  const { isEnabled: isPmLeadsViewEnabled, isLoading: loadingIsPmLeadsViewEnabled } = useIsPmLeadsViewEnabled(permissions);

  const isLoading = loadingIsPmLeadsViewEnabled || loadingIsMarketplaceEnabled;

  return {
    isEnabled: isMarketplaceEnabled && !isPmLeadsViewEnabled,
    isLoading,
  };
};

const useIsObpEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isOBPEnabled, loading: loadingIsOBPEnabled } = useIsOBPEnabledFF();

  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  return {
    isEnabled: !!permissions?.viewOBP && !isFreemiumPM && isOBPEnabled,
    isLoading: loadingIsFreemiumPM || loadingIsOBPEnabled,
  };
};

const useIsPmAppEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isRetainAppEnabled, isLoading: loadingIsRetainAppEnabled } = useIsRetainProductEnabled(permissions);
  const { isEnabled: isGrowAppEnabled, isLoading: loadingIsGrowAppEnabled } = useIsGrowProductEnabled(permissions);
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPMFF();
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isLoading = (
    loadingIsRetainAppEnabled || loadingIsGrowAppEnabled || loadingIsLegacyPM || loadingIsFreemiumPM
  );

  const isEnabled = permissions?.viewPMDashboard && (
    isRetainAppEnabled || isGrowAppEnabled || isLegacyPM || isFreemiumPM
  );

  return {
    isEnabled,
    isLoading,
  };
};

const useIsPmPropertiesDashboardEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPMFF();
  const {
    value: isPmPropertiesDashboardEnabled,
    loading: loadingIsPmPropertiesDashboardEnabled,
  } = useIsPmPropertiesDashboardEnabledFF();
  const { isEnabled: isRetainProductEnabled, isLoading: loadingIsRetainProductEnabled } = useIsRetainProductEnabled(permissions);

  const isLoading = (
    loadingIsLegacyPM || loadingIsPmPropertiesDashboardEnabled || loadingIsRetainProductEnabled
  );

  return {
    isEnabled: !!permissions?.viewPMDashboard && ((isLegacyPM && isPmPropertiesDashboardEnabled) || isRetainProductEnabled),
    isLoading,
  };
};

const useIsReferralPartnersAppEnabled = (permissions: Permissions | undefined): Feature => ({
  isEnabled: !!permissions?.viewReferralPartner,
  isLoading: false,
});

const useIsBillingEnabled = (permissions: Permissions | undefined): Feature => {
  const {
    isEnabled: isRetainAppEnabled,
    isLoading: loadingIsRetainAppEnabled,
  } = useIsRetainProductEnabled(permissions);

  const {
    isEnabled: isGrowAppEnabled,
    isLoading: loadingIsGrowAppEnabled,
  } = useIsGrowProductEnabled(permissions);

  return {
    isEnabled: (isRetainAppEnabled || isGrowAppEnabled) && !!permissions?.viewBilling,
    isLoading: loadingIsRetainAppEnabled && loadingIsGrowAppEnabled,
  };
};

const useIsB2CReferralProgramEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: b2cProgramFFEnabled, loading: isB2cProgramFFLoading } = useIsB2CReferralProgramEnabledFF();

  const isLoading = isB2cProgramFFLoading;

  return {
    isEnabled: b2cProgramFFEnabled && !!permissions?.viewB2CReferralProgram,
    isLoading,
  };
};

const useIsPmReferralLeadsEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPMFF();
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  return {
    isEnabled: (!isLegacyPM || isFreemiumPM) && !!permissions?.viewReferralLeads,
    isLoading: loadingIsLegacyPM || loadingIsFreemiumPM,
  };
};

const useIsPmProspectLeadsEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isGrowthProductEnabled, isLoading: loadingIsGrowthProductEnabled } = useIsGrowProductEnabled(permissions);
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isLoading = (
    loadingIsFreemiumPM || loadingIsGrowthProductEnabled
  );

  return {
    isEnabled: !isFreemiumPM && isGrowthProductEnabled && !!permissions?.viewProspectLeads,
    isLoading,
  };
};

const useIsPmBuyerLeadsEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isPmAppEnabled, isLoading: loadingIsPmAppEnabled } = useIsPmAppEnabled(permissions);
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isLoading = (
    loadingIsFreemiumPM || loadingIsPmAppEnabled
  );

  return {
    // The viewLeads permission exists on agents and is used to display the correct tabs
    isEnabled: !isFreemiumPM && (isPmAppEnabled || !!permissions?.viewBuyerLeads),
    isLoading,
  };
};

const useIsPmSellerLeadsEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isPmAppEnabled, isLoading: loadingIsPmAppEnabled } = useIsPmAppEnabled(permissions);
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isLoading = (
    loadingIsFreemiumPM || loadingIsPmAppEnabled
  );

  return {
    isEnabled: !isFreemiumPM && (isPmAppEnabled || !!permissions?.viewSellerLeads),
    isLoading,
  };
};

const useIsPmBuyBoxLeadsEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isPmAppEnabled, isLoading: loadingIsPmAppEnabled } = useIsPmAppEnabled(permissions);
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isLoading = (
    loadingIsFreemiumPM || loadingIsPmAppEnabled
  );

  return {
    isEnabled: !isFreemiumPM && (isPmAppEnabled || !!permissions?.viewBuyBoxLeads),
    isLoading,
  };
};

const useIsGrowthPmPartnersEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isGrowthPmPartnersEnabledFF, loading: loadingIsGrowthPmPartnersEnabledFF } = useIsGrowthPmPartnersEnabledFF();
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  return {
    isEnabled: (isGrowthPmPartnersEnabledFF || isFreemiumPM) && !!permissions?.viewSuggestedPMs,
    isLoading: loadingIsGrowthPmPartnersEnabledFF || loadingIsFreemiumPM,
  };
};

const useIsGrowthAgentPartnersEnabled = (permissions: Permissions | undefined): Feature => {
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);
  const { isEnabled: isPmAppEnabled, isLoading: loadingIsPmAppEnabled } = useIsPmAppEnabled(permissions);

  const isLoading = (
    loadingIsFreemiumPM || loadingIsPmAppEnabled
  );

  return {
    isLoading,
    isEnabled: !isFreemiumPM && isPmAppEnabled,
  };
};

const useIsContactsPageEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isPmAppEnabled, isLoading: loadingIsPmAppEnabled } = useIsPmAppEnabled(permissions);
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isLoading = (
    loadingIsFreemiumPM || loadingIsPmAppEnabled
  );

  return {
    isLoading,
    isEnabled: !isFreemiumPM && isPmAppEnabled,
  };
};

const useIsFreemiumPM = (permissions: Permissions | undefined): Feature => {
  const { value: isFreemiumVersionEnabledFF, loading: loadingIsFreemiumVersionEnabledFF } = useIsFreemiumVersionEnabledFF();

  return {
    isEnabled: isFreemiumVersionEnabledFF && !!permissions?.viewPMDashboard,
    isLoading: loadingIsFreemiumVersionEnabledFF,
  };
};

const useIsPmHomePageEnabled = (permissions: Permissions | undefined): Feature => {
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);
  const { isEnabled: isPmAppEnabled, isLoading: loadingIsPmAppEnabled } = useIsPmAppEnabled(permissions);

  const isLoading = (
    loadingIsFreemiumPM || loadingIsPmAppEnabled
  );

  return {
    isLoading,
    isEnabled: !isFreemiumPM && isPmAppEnabled,
  };
};

const useIsUpgradeGrowProductNavWidgetEnabled = (permissions: Permissions | undefined): Feature => {
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  const isLoading = (
    loadingIsFreemiumPM
  );

  return {
    isLoading,
    isEnabled: isFreemiumPM,
  };
};

const useIsReferOwnerNavWidgetEnabled = (permissions: Permissions | undefined): Feature => {
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  return {
    isEnabled: !isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  };
};

const useIsShareListingEnabled = (): Feature => {
  const {
    value: isFreemiumPM,
    loading: loadingIsFreemiumPM,
  } = useIsFreemiumVersionEnabledFF();

  return {
    isEnabled: !loadingIsFreemiumPM && !isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  };
};

const useIsFreemium = () => {
  const { value: isFreemiumPM, loading: loadingIsFreemiumPM } = useIsFreemiumVersionEnabledFF();

  return {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  };
};

const useIsLeadsThirdPartyIntegrationEnabled = (): Feature => {
  const {
    value: isLeadsThirdPartyIntegrationEnabled,
    loading: loadingIsLeadsThirdPartyIntegrationEnabled,
  } = useIsOwnerLeadsZapierIntegrationEnabled();

  return {
    isEnabled: isLeadsThirdPartyIntegrationEnabled,
    isLoading: loadingIsLeadsThirdPartyIntegrationEnabled,
  };
};

const useIsCraneAlternativeTextEnabled = (): Feature => {
  const {
    value: isCraneAlternativeTextEnabled,
    loading: loadingIsCraneAlternativeTextEnabled,
  } = useIsCraneAlternativeTextEnabledFF();

  return {
    isEnabled: isCraneAlternativeTextEnabled,
    isLoading: loadingIsCraneAlternativeTextEnabled,
  };
};

const useIsCraneCommunityNavItemEnabled = (permissions: Permissions | undefined): Feature => {
  const {
    value: isCraneCommunityNavItemEnabled,
    loading: loadingIsCraneCommunityNavItemEnabled,
  } = useIsCraneCommunityNavItemEnabledFF();
  const {
    isEnabled: isFreemiumPM,
    isLoading: loadingIsFreemiumPM,
  } = useIsFreemiumPM(permissions);

  return {
    isEnabled: isCraneCommunityNavItemEnabled || isFreemiumPM,
    isLoading: loadingIsCraneCommunityNavItemEnabled || loadingIsFreemiumPM,
  };
};

const useIsInviteProspectsEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isPmAppEnabled, isLoading: isLoadingPmAppEnabled } = useIsPmAppEnabled(permissions);
  const { value: isInviteProspectsEnabledFF, loading: loadingIsInviteProspectsEnabledFF } = useIsInviteProspectsEnabledFF();

  return {
    isEnabled: isPmAppEnabled && isInviteProspectsEnabledFF,
    isLoading: isLoadingPmAppEnabled || loadingIsInviteProspectsEnabledFF,
  };
};

const useIsUserGuidingEnabled = () => {
  const { value: isUserGuidingEnabled, loading: loadingIsUserGuidingEnabled } = useIsUserGuidingEnabledFF();

  return {
    isEnabled: isUserGuidingEnabled,
    isLoading: loadingIsUserGuidingEnabled,
  };
};

const useIsAnnualReportEnabled = (permissions: Permissions | undefined) => {
  const { data: annualInvitationReports, isLoading: isLoadingInvitationReports } = useGetAnnualInvitationReport();

  const { value: isAnnualReportEnabled, loading: loadingIsAnnualReportEnabled } = useIsAnnualReportEnabledFF();
  const { value: isRetainProductEnabled, loading: loadingIsRetainProductEnabled } = useIsRetainProductEnabledFF();
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPMFF();

  const canShowReport = (permissions?.viewDashboard || permissions?.viewPMDashboard) && isRetainProductEnabled
      && !isLegacyPM && isAnnualReportEnabled && annualInvitationReports?.length !== undefined
      && annualInvitationReports?.length > 0;

  return {
    isEnabled: canShowReport,
    isLoading: (loadingIsAnnualReportEnabled || isLoadingInvitationReports || loadingIsRetainProductEnabled || loadingIsLegacyPM),
  };
};

const useIsAnnualReportPmEnabled = (permissions: Permissions | undefined) => {
  const { value: isAnnualReportEnabled, loading: loadingIsAnnualReportEnabled } = useIsAnnualReportEnabledFF();
  const { value: isRetainProductEnabled, loading: loadingIsRetainProductEnabled } = useIsRetainProductEnabledFF();
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPMFF();
  const canShowReport = isAnnualReportEnabled && (permissions?.viewReportInvitation) && isRetainProductEnabled && !isLegacyPM;

  return {
    isEnabled: canShowReport,
    isLoading: (loadingIsAnnualReportEnabled || loadingIsRetainProductEnabled || loadingIsLegacyPM),
  };
};

const useIsRiskV2Enabled = () => {
  const { value: isRiskV2Enabled, loading: loadingIsRiskV2Enabled } = useIsRiskV2EnabledFF();

  return {
    isEnabled: isRiskV2Enabled,
    isLoading: loadingIsRiskV2Enabled,
  };
};

const useIsMarketplaceListViewEnabled = () => {
  const {
    value: isMarketplaceListViewEnabled,
    loading: loadingIsMarketplaceListViewEnabled,
  } = useIsMarketplaceListViewEnabledFF();

  return {
    isEnabled: isMarketplaceListViewEnabled,
    isLoading: loadingIsMarketplaceListViewEnabled,
  };
};

const useIsMarketplaceGrossYieldAsDollar = () => {
  const {
    value: isMarketplaceGrossYieldAsDollar,
    loading: loadingIsMarketplaceGrossYieldAsDollar,
  } = useListingCardGrossYieldAsDollarFF();

  return {
    isEnabled: isMarketplaceGrossYieldAsDollar,
    isLoading: loadingIsMarketplaceGrossYieldAsDollar,
  };
};
