import {
  Box, capitalize, Chip, Stack, Typography, useTheme,
} from '@mui/material';

export const LevelChip = ({ level }: { level: 'low' | 'medium' | 'high' }) => {
  const theme = useTheme();

  const levelToColor = {
    low: theme.palette.success.main,
    medium: theme.palette.warning.main,
    high: theme.palette.error.main,
  } as const;

  return (
    <Chip
      label={(
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Box sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: levelToColor[level],
            mr: 0.5,
          }}
          />
          <Typography variant="body2">
            {capitalize(level)}
          </Typography>
        </Stack>
      )}
      size="small"
      sx={{
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.customColors.chip1,
      }}
    />
  );
};
