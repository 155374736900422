// Necessary because of the generic type
/* eslint-disable react/function-component-definition */
import {
  ComponentProps,
  forwardRef, Fragment, MouseEvent,
  ReactNode, useCallback, useState,
} from 'react';

import { useTrackPageBottom } from 'lib';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import {
  IconButton, lighten, Paper, Stack, styled, SxProps, Table, TableBody, TableCell as MuiTableCell, TableHead,
  TableRow, Theme,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Cell,
  ColumnDef,
  flexRender, getCoreRowModel,
  getSortedRowModel,
  Row as TanStackTableRow, RowData, Table as TanStackTable,
  useReactTable,
} from '@tanstack/react-table';

import { ArrowToggleOpen } from '../icons';
import { SpinnerIcon } from '../spinner';
import { InfoTooltip } from '../tooltip/InfoTooltip';
import { LightTypography } from '../typography/LightTypography';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    sticky?: boolean;
    shownAsText?: boolean;
    textNoWrap?: boolean;
    headerTooltipContent?: ReactNode;
    alignHeaderCenter?: boolean;
  }
}

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: `${theme.spacing(3)} !important`,
}));

const TableHeaderCell = styled(MuiTableCell)(() => ({
  textTransform: 'none',
}));

type Props<TData> = {
  data: TData[];
  columns: (ColumnDef<TData, string> | null)[];
  emptyStateText?: string;
  maxHeight?: number | string;
  onBottom?: () => void;
  showLoader?: boolean;
  onClick?: (row: TData) => void;
};

const TableWithRef = forwardRef<HTMLTableElement, ComponentProps<typeof Table>>((props, ref) => <Table ref={ref} {...props} />);

// Necessary because of the generic type
// eslint-disable-next-line func-style
export function ControlledTableView<TData>({
  data,
  columns,
  emptyStateText = 'No items found',
  maxHeight = undefined,
  onBottom = () => { },
  showLoader = false,
  onClick = undefined,
}: Props<TData>) {
  const theme = useTheme();

  const [tableBodyElement, setTableBodyElement] = useState<HTMLTableElement | undefined>();

  const tableBodyRef = useCallback((node: HTMLTableElement) => {
    if (node === null) {
      return;
    }

    setTableBodyElement(node);
  }, []);

  const nonNullColumns = columns.filter((column) => column !== null) as ColumnDef<TData, string>[];

  const table = useReactTable<TData>({
    data,
    enableSorting: false,
    enableMultiSort: true,
    maxMultiSortColCount: 2,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: nonNullColumns,
    manualPagination: true,
  });

  useTrackPageBottom(onBottom, tableBodyElement ?? undefined);

  return (
    <Stack
      component={Paper}
      elevation={0}
      sx={{
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        maxHeight,
      }}
    >
      <Stack padding={0} sx={{ overflowX: 'auto' }} ref={tableBodyRef}>
        <TableWithRef>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                sx={{
                  position: 'sticky',
                  top: -1,
                  zIndex: 1,
                  background: '#F7F7F7',
                }}
              >
                {table.getCanSomeRowsExpand() && (
                  <TableHeaderCell sx={{ maxWidth: 40, width: 40 }} />
                )}
                {headerGroup.headers.map((header, i, headers) => (
                  <TableHeaderCell
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    sx={{
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                      width: header.column.columnDef.size,
                      ...(header.column.columnDef.meta?.sticky && getStickyHeaderSx(i, headers.length, theme)),
                      ...(header.column.columnDef.meta?.alignHeaderCenter && { textAlign: 'center' }),
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={header.column.columnDef.meta?.alignHeaderCenter ? 'center' : 'flex-start'}
                      gap={1}
                    >
                      <SemiBoldTypography variant="body2">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      </SemiBoldTypography>
                      {header.column.columnDef.meta?.headerTooltipContent && (
                        <InfoTooltip
                          isLight
                          isOutlined
                          arrow
                          isSmall
                          title={header.column.columnDef.meta?.headerTooltipContent}
                        />
                      )}
                      {header.column.getCanSort() && !header.column.getIsSorted() && (
                        <MdArrowUpward color={theme.palette.grey[400]} size={24} />
                      )}
                      {{
                        asc: <MdArrowUpward size={24} />,
                        desc: <MdArrowDownward size={24} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </Stack>
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {data.length > 0 || showLoader ? (
            <Body table={table} showLoader={showLoader} onClick={onClick} />
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
                  <Typography variant="body2">
                    {emptyStateText}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </TableWithRef>
      </Stack>
    </Stack>
  );
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Body = <TData,>({
  table,
  showLoader,
  onClick = undefined,
}: {
  table: TanStackTable<TData>;
  showLoader: boolean;
  onClick?: (row: TData) => void;
}) => {
  const theme = useTheme();

  const loaderRow = (
    <TableRow>
      <TableCell
        colSpan={table.getAllFlatColumns().length}
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack justifyContent="center" alignItems="center" height="100%">
          <SpinnerIcon size={24} />
        </Stack>
      </TableCell>
    </TableRow>
  );

  return (
    <TableBody>
      {table.getRowModel().rows?.map((row, i, rows) => (
        <Fragment key={row.id}>
          <TableRow sx={{
            background: row.getIsExpanded() ? lighten(theme.palette.info.main, 0.88) : undefined,
            transition: 'background 0.3s',
          }}
          >
            {table.getCanSomeRowsExpand() && (
              <TableCell sx={{ maxWidth: 40 }}>
                {row.getCanExpand() && (
                  <IconButton
                    size="small"
                    onClick={row.getToggleExpandedHandler()}
                    color="primary"
                  >
                    <ArrowToggleOpen size={22} className={row.getIsExpanded() ? 'open' : ''} />
                  </IconButton>
                )}
              </TableCell>
            )}
            <Row row={row} hideBottomBorder={i === rows.length - 1} onClick={onClick} />
          </TableRow>
          {showLoader && i === rows.length - 1 && loaderRow}
        </Fragment>
      ))}
      {showLoader && table.getRowModel().rows?.length === 0 && loaderRow}
    </TableBody>
  );
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Row = <TData,>({
  row,
  hideBottomBorder = false,
  onClick = undefined,
}: {
  row: TanStackTableRow<TData>;
  hideBottomBorder?: boolean;
  onClick?: (row: TData) => void;
}) => {
  const theme = useTheme();

  const createCellSx = (
    cell: Cell<TData, unknown>,
    i: number,
    cells: Cell<TData, unknown>[],
  ) => {
    let sx: SxProps = {
      cursor: row.getCanExpand() || onClick ? 'pointer' : 'default',
      borderBottom: hideBottomBorder ? 'none' : `1px solid ${theme.palette.divider}`,
    };

    if (cell.column.columnDef.meta?.blurred?.(row, cell)) {
      sx = {
        ...sx,
        filter: 'blur(3px)',
        pointerEvents: 'none',
        userSelect: 'none',
      };
    }

    if (cell.column.columnDef.meta?.sticky) {
      sx = {
        ...sx,
        ...getStickyHeaderSx(i, cells.length, theme),
      };
    }

    return sx;
  };

  const handleClickCell = (e: MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(row.original);
      return;
    }

    if (!row.getCanExpand()) return;

    // this is a hack to prevent the click event from bubbling up to the table
    if ((e.target as any).classList?.contains?.('MuiBackdrop-root')) return;

    if (e.target) row.getToggleExpandedHandler()();
  };

  return (
    <>
      {row.getVisibleCells().map((cell, i, cells) => (
        <TableCell
          key={cell.id}
          style={{
            minWidth: cell.column.columnDef.minSize,
            maxWidth: cell.column.columnDef.maxSize,
            width: cell.column.columnDef.size,
          }}
          sx={createCellSx(cell, i, cells)}
          onClick={handleClickCell}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {cell.column.columnDef.meta?.shownAsText ? (
              <LightTypography variant="body1" sx={{ textWrap: cell.column.columnDef.meta?.textNoWrap ? 'nowrap' : undefined }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </LightTypography>
            ) : flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Stack>
        </TableCell>
      ))}
    </>
  );
};

const getStickyHeaderSx = (i: number, columns: number, theme: Theme): SxProps => ({
  position: 'sticky',
  left: i === 0 ? 0 : undefined,
  right: i === columns - 1 ? 0 : undefined,
  zIndex: 1,
  background: `${theme.palette.background.paper} !important`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: i === columns - 1 ? 0 : undefined,
    right: i === 0 ? 0 : undefined,
    width: '1px',
    height: '100%',
    background: theme.palette.divider,
  },
});
