import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery } from '@tanstack/react-query';

import { convertSellableProperty } from './converters';
import { PropertyAddress, SellableProperty } from './types';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import {
  DashboardProperty, ListDashboardPropertyByOwnerQuery, ListMarketplacePropertiesQuery, MarketplaceProperty,
} from '../API';
import { axiosWithPayloadContext, createHeaders } from '../axios';
import {
  listDashboardPropertyIDsByOwner, listSellableProperties,
} from '../graphql/queries';

export const useListPropertyIDs = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.PROPERTY_IDS], async (): Promise<string[]> => {
    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: listDashboardPropertyIDsByOwner,
      authToken: token,
      variables: { owner: user?.id },
    }) as GraphQLResult<ListMarketplacePropertiesQuery>;

    if (!res.data?.listMarketplaceProperties) {
      return [];
    }

    const filtered = res.data.listMarketplaceProperties.items.filter(Boolean) as MarketplaceProperty[];
    return filtered.map((p) => p.property.id);
  }, { enabled: !!user?.id });
};

export const useListSellableProperties = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.SELLABLE_PROPERTIES], async (): Promise<SellableProperty[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listSellableProperties,
      authToken: token,
      variables: { owner: user?.id },
    }) as GraphQLResult<ListDashboardPropertyByOwnerQuery>;

    if (!res.data?.listDashboardPropertyByOwner) {
      return [];
    }

    const filtered = res.data.listDashboardPropertyByOwner.items.filter(Boolean) as DashboardProperty[];
    return filtered.map(convertSellableProperty).sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, { enabled: !!user?.id });
};

export const useCreateExternalMarketplaceProperty = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (data: {
    askingPrice: number;
    fullAddress: string;
  }) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/other-listings`,
      method: 'POST',
      headers: createHeaders(token),
      data,
    });
  });
};

export const useCreateMarketplaceProperty = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (data: {
    propertyId: string;
    askingPrice: number;
    address: PropertyAddress;
    category: string;
  }) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/marketplace-property`,
      method: 'POST',
      headers: createHeaders(token),
      data,
    });
  });
};
