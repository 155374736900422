import { calculateGrossYield } from 'lib';
import { formatDecimalToPercentage, useLabels } from 'ui';

import { CalculatedRow } from './Row';
import { getCalculationValue, useProjections } from './useProjections';
import { RowProps } from '../types';

export const GrossYield = ({ form }: RowProps) => {
  const l = useLabels();

  const view = form.watch('view');
  const projections = useProjections(form);

  const year1GrossYield = calculateGrossYield(
    getCalculationValue(projections.expectedRent.year1, view),
    projections.askingPrice.year1,
  );
  const year2GrossYield = calculateGrossYield(
    getCalculationValue(projections.expectedRent.year2, view),
    projections.askingPrice.year2,
  );
  const year3GrossYield = calculateGrossYield(
    getCalculationValue(projections.expectedRent.year3, view),
    projections.askingPrice.year3,
  );

  return (
    <CalculatedRow
      form={form}
      label={l.grossYield}
      bottomBorder={false}
      year1={{
        value: formatDecimalToPercentage(year1GrossYield || 0, 2),
        isNegative: year1GrossYield < 0,
      }}
      year2={{
        value: formatDecimalToPercentage(year2GrossYield || 0, 2),
        isNegative: year2GrossYield < 0,
      }}
      year3={{
        value: formatDecimalToPercentage(year3GrossYield || 0, 2),
        isNegative: year3GrossYield < 0,
      }}
    />
  );
};
