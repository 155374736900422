import { Stack, Typography } from '@mui/material';

import { CalculatedRow, DetailRow } from './Row';
import { formatNumberToCurrency } from '../../../lib/formatFuncs';
import { useLabels } from '../../../lib/translations';
import { InfoTooltip } from '../../../tooltip/InfoTooltip';
import { BoldTypography } from '../../../typography/BoldTypography';
import { useGetPropertyIdParam } from '../state';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const OperatingIncome = ({ form }: RowProps) => {
  const l = useLabels();
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form.watch(), propertyID);

  return (
    <>
      <DetailRow
        rowID="operating-income"
        bottomBorder={false}
        columns={[
          {
            id: 'operating-income',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.operatingIncome}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.netOperatingIncome']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          ...projections.years.map(() => ({})),
        ]}
      />
      <CalculatedRow
        label={l.noi}
        values={projections.years.map((_, i) => {
          const noi = projections.calculations.getNOI(i);

          return {
            value: formatNumberToCurrency(noi || 0, 0),
            isNegative: noi < 0,
          };
        })}
      />
    </>
  );
};
