import {
  UseFormReturn,
} from 'react-hook-form';

export const loanTerms = [120, 180, 240, 360, 'other'] as const;
export const metricRelatedExpenses = ['maintenance', 'capEx', 'estimatedVacancy'] as const;
export type MetricRelatedExpense = typeof metricRelatedExpenses[number];

export type Loan = null | {
  purchasePrice: number,
  amount: number,
  interestRate: number,
  term: typeof loanTerms[number],
  termOther: number,
};

export type ProformaMetricsData = {
  annualHomeAppreciation: number,
  annualRentGrowth: number,
  estimatedVacancy: number,
  inflationRate: number,
  maintenance: number,
  capEx: number,
};

export type PropertyProformaData = {
  askingPrice: number,
  metrics: ProformaMetricsData,
  income: {
    rent: number,
  },
  expenses: {
    estimatedVacancy: number,
    tax: number,
    insurance: number,
    hoa: number,
    managementFee: number,
    maintenance: number,
    capEx: number,
  },
  loan: Loan,
};

export const nonObjectFields = ['askingPrice'] as const;
export const objectFields = ['income', 'expenses', 'metrics', 'loan'] as const;
export type ObjectField = typeof objectFields[number];
export type NonObjectField = typeof nonObjectFields[number];

export type PropertyProformaFormData = Record<string, PropertyProformaData>;

export type Form = UseFormReturn<PropertyProformaFormData>;
export type ProformaMetricsForm = UseFormReturn<ProformaMetricsData>;

export type RowProps = {
  form: Form;
};
