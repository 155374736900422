import { ApexOptions } from 'apexcharts';
import Apexcharts from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

export const GaugeChart = ({
  series,
  colors,
  height = undefined,
  width = undefined,
  totalLabel = undefined,
  totalValue = undefined,
  showTotal = true,
  hoverTitleFormatter = undefined,
  hoverValueFormatter = undefined,
}: {
  series: number[],
  colors: string[],
  height?: number,
  width?: number,
  totalLabel?: string,
  totalValue?: string,
  showTotal?: boolean,
  hoverTitleFormatter?: (seriesName: string) => string,
  hoverValueFormatter?: (value: number) => string,
}) => {
  const theme = useTheme();
  const options: ApexOptions = {
    colors,
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5,
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
          },
          value: {
            offsetY: -40,
            fontFamily: theme.typography.fontFamily,
          },
          total: {
            show: showTotal,
            label: totalValue,
            fontFamily: theme.typography.fontFamily,
            formatter: totalLabel ? () => totalLabel : () => '',
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    tooltip: {
      enabled: !!hoverTitleFormatter || !!hoverValueFormatter,
      y: {
        title: {
          formatter: hoverTitleFormatter ?? (() => ''),
        },
        formatter: hoverValueFormatter ?? (() => ''),
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
  };

  return <Apexcharts options={options} series={series} type="radialBar" height={height} width={width} />;
};
