/* eslint-disable max-len */

export const categories = [
  {
    name: 'Financing',
    description: 'Finance your next acquisition or cash out on your existing property',
    vendors: [
      {
        name: 'Truly Mortgage',
        features: ['Loan Term: 30-year fixed', 'Loan Amount: $90K to $2M', 'LTV: Up to 80%'],
        description: `Truly Mortgage specializes in Business Purpose Lending, including Fix & Flip, Bridge,
                      New Construction, Fix & Hold, Build for Rent, Single Property Rental, and Blanket Property Rental loans.`,
        logo: 'https://assets.blankethomes.com/vendorsa/logos/truly.png',
        cta: 'https://l.blankethomes.com/TrulyMortgage',
        readMore: '',
      },
      {
        name: 'Lendai',
        features: ['Loan Term: 30-year fixed or interest only', 'Loan Amount: $100K to $2M', 'LTV: Up to 75%'],
        description: 'Lendai Is A Fintech Lender Financing US Real Estate Properties For Foreign Investors.',
        logo: 'https://assets.blankethomes.com/vendorsa/logos/Lendai.png',
        cta: 'https://l.blankethomes.com/Lendai',
        readMore: '',
      },
      {
        name: 'Waltz',
        features: ['Loan Term: 30-year fixed or interest only', 'Loan Amount: $100K to $2M', 'LTV: Up to 75%'],
        description: 'Waltz is a fintech lender financing US real estate properties for foreign investors.',
        logo: 'https://assets.blankethomes.com/vendorsa/logos/waltz.svg',
        cta: 'https://l.blankethomes.com/Waltz',
        readMore: '',
      },
      {
        name: 'USA Mortgages',
        features: ['Loan Term: 12-24 months', 'Loan Amount: $200K to $3.5M', 'LTV: Up to 85%'],
        description: `USA Mortgages is a private lender that provides tailored financing solutions to
                      residential real estate investors.
                      We offer short-term bridge loans and products designed to help investors achieve their goals.`,
        logo: 'https://assets.blankethomes.com/vendorsa/logos/USA+Mortgages.png',
        cta: 'https://l.blankethomes.com/USAMortgages',
        readMore: '',
      },
    ],
  },
  {
    name: 'Insurance',
    description: 'Protect your rental property',
    vendors: [
      {
        name: 'Obie',
        features: [
          'No calls or complex paperwork',
          'Minutes to complete the process',
          'Save money/improve property ROI',
        ],
        description: 'Obie is an insurance technology company, hyper-focused on driving value for the modern-day investor.',
        logo: 'https://assets-global.website-files.com/61845d4d9442a6529df9ba48/61845d4d9442a666b4f9bcba_full-color-positive.svg',
        cta: 'https://l.blankethomes.com/Obie',
        readMore: '',
      },
      {
        name: 'Steadily',
        features: [
          'Hassle-free, fast landlord insurance',
          'Customized competitive premiums',
          'Coverage in all 50 US states',
        ],
        description: `Steadily is an innovative landlord insurance provider making coverage effortless and affordable.
                      Get quotes within minutes and access outstanding customer support.`,
        logo: 'https://assets.blankethomes.com/vendorsa/logos/Steadily.png',
        cta: 'https://l.blankethomes.com/Steadily',
        readMore: '',
      },
    ],
  },
  {
    name: 'Home Warranty',
    description: 'Use a home warranty to cover the hassle and cost of unit repairs and replacements.',
    vendors: [
      {
        name: 'Sure',
        features: [
          '$0 service fee and no deductible',
          'Fully digital purchasing experience',
          'Convenient, hassle-free claims process',
        ],
        description: 'Sure provides landlords with fixed-cost warranty options to cover the hassle and cost of unit repairs and replacements.',
        logo: 'https://assets.blankethomes.com/vendorsa/logos/sure.svg',
        cta: 'https://l.blankethomes.com/Sure',
        readMore: '',
      },
    ],
  },
  {
    name: '1031 Exchange',
    description: 'Use the 1031 Exchange tax break to save 30-40% in unnecessary taxes when you sell your properties',
    vendors: [
      {
        name: 'Plenti',
        features: [
          'Qualify for bigger, better property',
          'Keep monthly mortgage costs down',
          'Delay or eliminate up to 100% of your capital gains tax',
        ],
        description: 'Plenti Financial is your all-in-one tech hub for tax-free real estate investing.',
        logo: 'https://assets.blankethomes.com/vendorsa/logos/plenti.png',
        cta: 'https://l.blankethomes.com/Plenti',
        readMore: '',
      },
    ],
  },
  {
    name: 'Inspection',
    description: 'Check your property’s condition',
    vendors: [
      {
        name: 'Inspectify',
        features: [
          'Access to property within 48 hours',
          'Customized inspection scope',
          'Scheduling and coordination',
        ],
        // eslint-disable-next-line max-len
        description: 'Inspectify leverages proprietary technology and a nationwide network of inspectors to enable reliable and consistent data capture, including property condition, appliance data, repair estimates, and rehab scopes.',
        logo: 'https://assets.blankethomes.com/vendorsa/logos/inspectify+logo+(1).png',
        cta: 'https://l.blankethomes.com/Inspectify',
        readMore: 'https://l.blankethomes.com/Inspectify2',
      },
    ],
  },
  {
    name: 'Cost Segregation',
    description: 'Accelerate your depreciation and order repair regulation studies',
    vendors: [
      {
        name: 'CSSI',
        features: [
          'Reduce your taxable income.',
          'Receive 14-20% of your tax savings now.',
          'Up to $55,000 in depreciation expense.',
        ],
        // eslint-disable-next-line max-len
        description: 'CSSI increases your cash flow with accelerated depreciation and repair regulations studies.',
        logo: 'https://assets.blankethomes.com/vendorsa/logos/cssi.png',
        cta: 'https://l.blankethomes.com/cssi',
        readMore: '',
      },
    ],
  },
];

export const homeservicesCategories = [
  {
    name: 'Financing',
    description: 'Finance your next acquisition or cash out on your existing property',
    vendors: [
      {
        category: 'Financing',
        name: 'HSOA Financing',
        description: 'Our mortgage companies are committed to ensuring that everyone—regardless of their race, ethnicity, gender, or other protected status—is treated with equally and fairly in their pursuit of homeownership.',
        logo: 'https://assets.blankethomes.com/gi/hsoa.png',
        cta: 'https://www.homeservices.com/mortgage',
        readMore: '',
        features: ['Loan Term: 30-year fixed', 'Loan Amount: $90K to $2M', 'LTV: Up to 80%'],
      },
    ],
  },
  {
    name: 'Insurance',
    description: 'Protect your rental property',
    vendors: [
      {
        category: 'Insurance',
        name: 'HSOA Insurance',
        description: 'We provide a broad range of insurance services including home, auto, flood and more. Our strength is in our network and also the quality of our local providers. To learn more about our individual insurance companies, click through to inquire.',
        logo: 'https://assets.blankethomes.com/gi/hsoa.png',
        cta: 'https://www.homeservices.com/insurance',
        readMore: '',
        features: ['No calls or complex paperwork', 'Minutes to complete the process', 'Save money/improve property ROI'],
      },
    ],
  },
  {
    name: 'Title/Escrow',
    description: 'Secure your investments with escrow — funds held safely until conditions are met',
    vendors: [
      {
        category: 'Escrow',
        name: 'HSOA Title/Escrow',
        description: 'The HomeServices\' Title and Escrow companies — divisions of HomeServices of America operating companies—offer a full spectrum of title, escrow and closing services for buyers and sellers throughout the country. ',
        logo: 'https://assets.blankethomes.com/gi/hsoa.png',
        cta: 'https://www.homeservices.com/settlement-services',
        readMore: '',
        features: ['No calls or complex paperwork', 'Minutes to complete the process', 'Save money/improve property ROI'],
      },
    ],
  },
];

export const getVendorData = (pm: string) => {
  if (pm === 'homeservices') {
    return homeservicesCategories;
  }

  return categories;
};
