import {
  BuyBoxLeadsWorkflowStatus
  ,
} from 'lib';

import { BuyBoxLeadsKanban } from './kanban/BuyBoxLeadsKanban';
import { BuyBoxLeadsList } from './list/BuyBoxLeadsList';

const orderedStatuses = [
  BuyBoxLeadsWorkflowStatus.new,
  BuyBoxLeadsWorkflowStatus.attemptedContact,
  BuyBoxLeadsWorkflowStatus.spokeWithLead,
  BuyBoxLeadsWorkflowStatus.appointmentSet,
  BuyBoxLeadsWorkflowStatus.metWithLead,
  BuyBoxLeadsWorkflowStatus.propertyChosen,
  BuyBoxLeadsWorkflowStatus.lost,
];

export const BuyBoxLeads = ({ isKanbanViewEnabled }: { isKanbanViewEnabled: boolean }) => (
  isKanbanViewEnabled
    ? <BuyBoxLeadsKanban orderedStatuses={orderedStatuses} />
    : <BuyBoxLeadsList orderedStatuses={orderedStatuses} />
);
