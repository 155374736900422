import dayjs from 'dayjs';
import { Stack, useTheme } from '@mui/material';

import { AreaChart, getYAxisMaxAmount } from '../../../charts';
import { BoldTypography } from '../../../typography/BoldTypography';
import { getTrend } from '../MarketAnalysisGraph';

const xLabel = Array.from({ length: 60 }, (_, i) => i + 1);

export const MarketAnalysisGraphPdf = ({
  color, series, title, scale = 100_000, getHalfMin = false, height = 200,
}: { color: string, series: number[], title: string, scale?: number, getHalfMin?: boolean, height?: number }) => {
  const theme = useTheme();
  let chosenColor: string;
  switch (color) {
    case 'purple':
      chosenColor = 'rgba(138, 91, 174, 1)';
      break;
    case 'green':
      chosenColor = 'rgba(16, 170, 134, 1)';
      break;
    case 'blue':
      chosenColor = 'rgba(38, 120, 245, 1)';
      break;
    default:
      chosenColor = 'rgba(138, 91, 174, 1)';
      break;
  }

  const data = [{
    name: title,
    color: chosenColor,
    data: series,
  }];

  const yAxisMax = getYAxisMaxAmount([...series], scale);
  const yAxisMin = Math.floor(yAxisMax / 2);
  const trend = getTrend(series, title);

  const startYear = 2024 - Math.floor(series.length / 12);

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '6px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" px="1rem" pt="0.75rem">
        <BoldTypography variant="body2">
          {title}
        </BoldTypography>
        {trend}
      </Stack>
      <Stack mt="-1rem" zIndex={-1}>
        <AreaChart
          series={data}
          xLabels={xLabel}
          max={yAxisMax}
          min={getHalfMin ? yAxisMin : 0}
          height={height}
          width="100%"
          chartOptions={{
            offsetX: -1,
          }}
          formatXLabel={(monthNumber, opts) => {
            if (opts?.inTooltip) {
              if (Number(monthNumber) === 60) return 'Dec, 2024';
              const year = Math.floor(Number(monthNumber) / 12) + startYear;
              return dayjs(`${year}-${((Number(monthNumber)) % 12) + 1}-01`).format('MMM, YYYY');
            }
            if (Number(monthNumber) % 12 === 6) {
              return (startYear + Math.floor(Number(monthNumber) / 12)).toString();
            }
            return '';
          }}
        />
      </Stack>
    </Stack>
  );
};
