/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAppSettingsInput = {
    id?: string | null,
    iamDomain?: string | null,
    iamClientId?: string | null,
    iamAudience?: string | null,
    title?: string | null,
    tenant?: string | null,
    pmName?: string | null,
    color?: string | null,
    logo?: string | null,
    favicon?: string | null,
};

export type ModelAppSettingsConditionInput = {
    iamDomain?: ModelStringInput | null,
    iamClientId?: ModelStringInput | null,
    iamAudience?: ModelStringInput | null,
    title?: ModelStringInput | null,
    tenant?: ModelStringInput | null,
    pmName?: ModelStringInput | null,
    color?: ModelStringInput | null,
    logo?: ModelStringInput | null,
    favicon?: ModelStringInput | null,
    and?: Array< ModelAppSettingsConditionInput | null > | null,
    or?: Array< ModelAppSettingsConditionInput | null > | null,
    not?: ModelAppSettingsConditionInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
    ne?: string | null,
    eq?: string | null,
    le?: string | null,
    lt?: string | null,
    ge?: string | null,
    gt?: string | null,
    contains?: string | null,
    notContains?: string | null,
    between?: Array< string | null > | null,
    beginsWith?: string | null,
    attributeExists?: boolean | null,
    attributeType?: ModelAttributeTypes | null,
    size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
    binary = "binary",
    binarySet = "binarySet",
    bool = "bool",
    list = "list",
    map = "map",
    number = "number",
    numberSet = "numberSet",
    string = "string",
    stringSet = "stringSet",
    _null = "_null",
}


export type ModelSizeInput = {
    ne?: number | null,
    eq?: number | null,
    le?: number | null,
    lt?: number | null,
    ge?: number | null,
    gt?: number | null,
    between?: Array< number | null > | null,
};

export type AppSettings = {
    __typename: "AppSettings",
    id: string,
    iamDomain?: string | null,
    iamClientId?: string | null,
    iamAudience?: string | null,
    title?: string | null,
    tenant?: string | null,
    pmName?: string | null,
    color?: string | null,
    logo?: string | null,
    favicon?: string | null,
    createdAt: string,
    updatedAt: string,
};

export type UpdateAppSettingsInput = {
    id: string,
    iamDomain?: string | null,
    iamClientId?: string | null,
    iamAudience?: string | null,
    title?: string | null,
    tenant?: string | null,
    pmName?: string | null,
    color?: string | null,
    logo?: string | null,
    favicon?: string | null,
};

export type DeleteAppSettingsInput = {
    id: string,
};

export type CreateBuyBoxLeadWorkflowItemInput = {
    id?: string | null,
    leadContactDetails?: ContactDetailsInput | null,
    timeline?: string | null,
    amount?: string | null,
    propertyType?: string | null,
    financing?: string | null,
    status: BuyBoxLeadsWorkflowStatus,
    pm: string,
    statusChangeTime: string,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ContactDetailsInput = {
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
};

export enum BuyBoxLeadsWorkflowStatus {
    new = "new",
    attemptedContact = "attemptedContact",
    spokeWithLead = "spokeWithLead",
    appointmentSet = "appointmentSet",
    metWithLead = "metWithLead",
    propertyChosen = "propertyChosen",
    lost = "lost",
}


export type ModelBuyBoxLeadWorkflowItemConditionInput = {
    timeline?: ModelStringInput | null,
    amount?: ModelStringInput | null,
    propertyType?: ModelStringInput | null,
    financing?: ModelStringInput | null,
    status?: ModelBuyBoxLeadsWorkflowStatusInput | null,
    pm?: ModelStringInput | null,
    statusChangeTime?: ModelStringInput | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    revealed?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelBuyBoxLeadWorkflowItemConditionInput | null > | null,
    or?: Array< ModelBuyBoxLeadWorkflowItemConditionInput | null > | null,
    not?: ModelBuyBoxLeadWorkflowItemConditionInput | null,
};

export type ModelBuyBoxLeadsWorkflowStatusInput = {
    eq?: BuyBoxLeadsWorkflowStatus | null,
    ne?: BuyBoxLeadsWorkflowStatus | null,
};

export type ModelBooleanInput = {
    ne?: boolean | null,
    eq?: boolean | null,
    attributeExists?: boolean | null,
    attributeType?: ModelAttributeTypes | null,
};

export type BuyBoxLeadWorkflowItem = {
    __typename: "BuyBoxLeadWorkflowItem",
    id: string,
    leadContactDetails?: ContactDetails | null,
    timeline?: string | null,
    amount?: string | null,
    propertyType?: string | null,
    financing?: string | null,
    status: BuyBoxLeadsWorkflowStatus,
    pm: string,
    statusChangeTime: string,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime: string,
    updatedTime: string,
};

export type ContactDetails = {
    __typename: "ContactDetails",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
};

export type UpdateBuyBoxLeadWorkflowItemInput = {
    id: string,
    leadContactDetails?: ContactDetailsInput | null,
    timeline?: string | null,
    amount?: string | null,
    propertyType?: string | null,
    financing?: string | null,
    status?: BuyBoxLeadsWorkflowStatus | null,
    pm?: string | null,
    statusChangeTime?: string | null,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteBuyBoxLeadWorkflowItemInput = {
    id: string,
};

export type CreateBuyerLeadWorkflowItemInput = {
    id?: string | null,
    buyerContact?: ContactDetailsInput | null,
    sellerAgentContact?: ContactDetailsInput | null,
    askingPrice: number,
    pm: string,
    statusChangeTime: string,
    address: AddressInput,
    propertyId: string,
    isPropertyVisible?: boolean | null,
    status: BuyerLeadsWorkflowStatus,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type AddressInput = {
    street1: string,
    street2?: string | null,
    city: string,
    state: string,
    zipCode: string,
};

export enum BuyerLeadsWorkflowStatus {
    new = "new",
    attemptedContact = "attemptedContact",
    spokeWithLead = "spokeWithLead",
    appointmentSet = "appointmentSet",
    metWithLead = "metWithLead",
    offerSubmitted = "offerSubmitted",
    underContract = "underContract",
    sold = "sold",
    rejected = "rejected",
}


export type ModelBuyerLeadWorkflowItemConditionInput = {
    askingPrice?: ModelIntInput | null,
    pm?: ModelStringInput | null,
    statusChangeTime?: ModelStringInput | null,
    propertyId?: ModelStringInput | null,
    isPropertyVisible?: ModelBooleanInput | null,
    status?: ModelBuyerLeadsWorkflowStatusInput | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    revealed?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelBuyerLeadWorkflowItemConditionInput | null > | null,
    or?: Array< ModelBuyerLeadWorkflowItemConditionInput | null > | null,
    not?: ModelBuyerLeadWorkflowItemConditionInput | null,
};

export type ModelIntInput = {
    ne?: number | null,
    eq?: number | null,
    le?: number | null,
    lt?: number | null,
    ge?: number | null,
    gt?: number | null,
    between?: Array< number | null > | null,
    attributeExists?: boolean | null,
    attributeType?: ModelAttributeTypes | null,
};

export type ModelBuyerLeadsWorkflowStatusInput = {
    eq?: BuyerLeadsWorkflowStatus | null,
    ne?: BuyerLeadsWorkflowStatus | null,
};

export type BuyerLeadWorkflowItem = {
    __typename: "BuyerLeadWorkflowItem",
    id: string,
    buyerContact?: ContactDetails | null,
    sellerAgentContact?: ContactDetails | null,
    askingPrice: number,
    pm: string,
    statusChangeTime: string,
    address: Address,
    propertyId: string,
    isPropertyVisible?: boolean | null,
    status: BuyerLeadsWorkflowStatus,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime: string,
    updatedTime: string,
};

export type Address = {
    __typename: "Address",
    street1: string,
    street2?: string | null,
    city: string,
    state: string,
    zipCode: string,
};

export type UpdateBuyerLeadWorkflowItemInput = {
    id: string,
    buyerContact?: ContactDetailsInput | null,
    sellerAgentContact?: ContactDetailsInput | null,
    askingPrice?: number | null,
    pm?: string | null,
    statusChangeTime?: string | null,
    address?: AddressInput | null,
    propertyId?: string | null,
    isPropertyVisible?: boolean | null,
    status?: BuyerLeadsWorkflowStatus | null,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteBuyerLeadWorkflowItemInput = {
    id: string,
};

export type CreateCrimeInput = {
    id?: string | null,
    all: PercentileInput,
    other: PercentileInput,
    property: PercentileInput,
    violent: PercentileInput,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyCrimeId?: string | null,
};

export type PercentileInput = {
    national: number,
    incidents: number,
    county: number,
};

export type ModelCrimeConditionInput = {
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelCrimeConditionInput | null > | null,
    or?: Array< ModelCrimeConditionInput | null > | null,
    not?: ModelCrimeConditionInput | null,
    marketplacePropertyCrimeId?: ModelIDInput | null,
};

export type ModelIDInput = {
    ne?: string | null,
    eq?: string | null,
    le?: string | null,
    lt?: string | null,
    ge?: string | null,
    gt?: string | null,
    contains?: string | null,
    notContains?: string | null,
    between?: Array< string | null > | null,
    beginsWith?: string | null,
    attributeExists?: boolean | null,
    attributeType?: ModelAttributeTypes | null,
    size?: ModelSizeInput | null,
};

export type Crime = {
    __typename: "Crime",
    id: string,
    all: Percentile,
    other: Percentile,
    property: Percentile,
    violent: Percentile,
    marketplaceProperty: MarketplaceProperty,
    createdTime: string,
    updatedTime: string,
    marketplacePropertyCrimeId?: string | null,
};

export type Percentile = {
    __typename: "Percentile",
    national: number,
    incidents: number,
    county: number,
};

export type MarketplaceProperty = {
    __typename: "MarketplaceProperty",
    id: string,
    askingPrice: number,
    owner: string,
    status: MarketPlacePropertyStatus,
    closedBy?: string | null,
    activatedTime?: string | null,
    address: Address,
    description?: string | null,
    rent?: number | null,
    constructionStatus?: ConstructionStatus | null,
    category?: MarketplacePropertyCategory | null,
    source?: string | null,
    vendor?: string | null,
    listingPictures?: ModelMarketPlacePropertyPictureConnection | null,
    expenses?: MarketPlacePropertyExpenses | null,
    favorites?: ModelFavoriteMarketplacePropertyConnection | null,
    recommendations?: ModelMarketplacePropertyRecommendationConnection | null,
    pmRecommendations?: ModelPmMarketplacePropertyRecommendationConnection | null,
    offers?: ModelOfferConnection | null,
    crime?: ModelCrimeConnection | null,
    readPermissions?: Array< string | null > | null,
    relevantPMs?: Array< string | null > | null,
    shareWith?: Array< string | null > | null,
    propertyRental?: ModelMarketplacePropertyRentalConnection | null,
    property: Property,
    listingItems?: ModelMarketplacePropertyListingItemConnection | null,
    createdTime: string,
    updatedTime: string,
    propertyMarketplacePropertyId?: string | null,
};

export enum MarketPlacePropertyStatus {
    newLead = "newLead",
    pendingListing = "pendingListing",
    active = "active",
    pendingSaleApproval = "pendingSaleApproval",
    sold = "sold",
    canceled = "canceled",
}


export enum ConstructionStatus {
    unknown = "unknown",
    underConstruction = "underConstruction",
    moveInReady = "moveInReady",
}


export enum MarketplacePropertyCategory {
    wholesale = "wholesale",
    turnkey = "turnkey",
    newConstruction = "newConstruction",
    FSBO = "FSBO",
    MLS = "MLS",
    unknown = "unknown",
}


export type ModelMarketPlacePropertyPictureConnection = {
    __typename: "ModelMarketPlacePropertyPictureConnection",
    items:  Array<MarketPlacePropertyPicture | null >,
    nextToken?: string | null,
};

export type MarketPlacePropertyPicture = {
    __typename: "MarketPlacePropertyPicture",
    url: string,
    rank?: number | null,
    property: MarketplaceProperty,
    marketplacePropertyListingPicturesId: string,
    createdTime: string,
    updatedTime: string,
};

export type MarketPlacePropertyExpenses = {
    __typename: "MarketPlacePropertyExpenses",
    hoa?: number | null,
    insurance?: number | null,
    managementFee?: number | null,
    other?: number | null,
    tax?: number | null,
};

export type ModelFavoriteMarketplacePropertyConnection = {
    __typename: "ModelFavoriteMarketplacePropertyConnection",
    items:  Array<FavoriteMarketplaceProperty | null >,
    nextToken?: string | null,
};

export type FavoriteMarketplaceProperty = {
    __typename: "FavoriteMarketplaceProperty",
    id: string,
    owner: string,
    property: MarketplaceProperty,
    createdTime: string,
    updatedTime: string,
    marketplacePropertyFavoritesId?: string | null,
};

export type ModelMarketplacePropertyRecommendationConnection = {
    __typename: "ModelMarketplacePropertyRecommendationConnection",
    items:  Array<MarketplacePropertyRecommendation | null >,
    nextToken?: string | null,
};

export type MarketplacePropertyRecommendation = {
    __typename: "MarketplacePropertyRecommendation",
    id: string,
    owner: string,
    property: MarketplaceProperty,
    score: number,
    createdTime: string,
    updatedTime: string,
    marketplacePropertyRecommendationsId?: string | null,
};

export type ModelPmMarketplacePropertyRecommendationConnection = {
    __typename: "ModelPmMarketplacePropertyRecommendationConnection",
    items:  Array<PmMarketplacePropertyRecommendation | null >,
    nextToken?: string | null,
};

export type PmMarketplacePropertyRecommendation = {
    __typename: "PmMarketplacePropertyRecommendation",
    id: string,
    pm: string,
    property: MarketplaceProperty,
    score: number,
    createdTime: string,
    updatedTime: string,
    marketplacePropertyPmRecommendationsId?: string | null,
};

export type ModelOfferConnection = {
    __typename: "ModelOfferConnection",
    items:  Array<Offer | null >,
    nextToken?: string | null,
};

export type Offer = {
    __typename: "Offer",
    id: string,
    buyer: string,
    seller?: string | null,
    bid?: number | null,
    status: OfferStatus,
    property: MarketplaceProperty,
    createdTime: string,
    updatedTime: string,
    marketplacePropertyOffersId?: string | null,
};

export enum OfferStatus {
    newLead = "newLead",
    offerMade = "offerMade",
    accepted = "accepted",
    psaSigned = "psaSigned",
    depositSubmitted = "depositSubmitted",
    rejected = "rejected",
    won = "won",
}


export type ModelCrimeConnection = {
    __typename: "ModelCrimeConnection",
    items:  Array<Crime | null >,
    nextToken?: string | null,
};

export type ModelMarketplacePropertyRentalConnection = {
    __typename: "ModelMarketplacePropertyRentalConnection",
    items:  Array<MarketplacePropertyRental | null >,
    nextToken?: string | null,
};

export type MarketplacePropertyRental = {
    __typename: "MarketplacePropertyRental",
    id: string,
    date: string,
    priceUpper?: number | null,
    priceLower?: number | null,
    priceMean?: number | null,
    fsd?: number | null,
    provider?: Provider | null,
    marketplacePropertyPropertyRentalId: string,
    property: MarketplaceProperty,
    createdTime: string,
    updatedTime: string,
};

export enum Provider {
    attom = "attom",
    housecanary = "housecanary",
    reapi = "reapi",
    vendor = "vendor",
    manual = "manual",
    internal = "internal",
}


export type Property = {
    __typename: "Property",
    id: string,
    propertyType?: PropertyType | null,
    address: Address,
    yearBuilt?: number | null,
    bedrooms?: number | null,
    bathrooms?: number | null,
    garages?: number | null,
    lotSize?: number | null,
    sqft?: number | null,
    isVacant?: boolean | null,
    extendedAddress?: ModelPropertyExtendedAddressConnection | null,
    units?: ModelUnitConnection | null,
    owner: string,
    DashboardProperty?: ModelDashboardPropertyConnection | null,
    MarketplaceProperty?: ModelMarketplacePropertyConnection | null,
    createdTime: string,
    updatedTime: string,
};

export enum PropertyType {
    unknown = "unknown",
    singleFamily = "singleFamily",
    duplex = "duplex",
    triplex = "triplex",
    quadplex = "quadplex",
    condo = "condo",
    townhouse = "townhouse",
}


export type ModelPropertyExtendedAddressConnection = {
    __typename: "ModelPropertyExtendedAddressConnection",
    items:  Array<PropertyExtendedAddress | null >,
    nextToken?: string | null,
};

export type PropertyExtendedAddress = {
    __typename: "PropertyExtendedAddress",
    id: string,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    lat?: number | null,
    lon?: number | null,
    geoid?: string | null,
    fips?: string | null,
    provider?: string | null,
    property: Property,
    createdTime: string,
    updatedTime: string,
    propertyExtendedAddressId?: string | null,
};

export type ModelUnitConnection = {
    __typename: "ModelUnitConnection",
    items:  Array<Unit | null >,
    nextToken?: string | null,
};

export type Unit = {
    __typename: "Unit",
    id: string,
    bedrooms?: number | null,
    bathrooms?: number | null,
    sqft?: number | null,
    address: Address,
    isVacant?: boolean | null,
    property: Property,
    createdTime: string,
    updatedTime: string,
    propertyUnitsId?: string | null,
};

export type ModelDashboardPropertyConnection = {
    __typename: "ModelDashboardPropertyConnection",
    items:  Array<DashboardProperty | null >,
    nextToken?: string | null,
};

export type DashboardProperty = {
    __typename: "DashboardProperty",
    id: string,
    owner: string,
    name: string,
    transactions?: ModelTransactionConnection | null,
    pmTransactions?: ModelPMTransactionConnection | null,
    leases?: ModelLeaseConnection | null,
    userExpense?: ModelPropertyUserExpenseConnection | null,
    amortizationTable?: ModelPropertyAmortizationTableConnection | null,
    mortgage?: ModelMortgageConnection | null,
    propertyValuation?: ModelDashboardPropertyValuationConnection | null,
    purchaseHistory?: ModelDashboardPropertyPurchaseHistoryConnection | null,
    metrics?: ModelDashboardPropertyMetricConnection | null,
    address: Address,
    hoaMuted?: boolean | null,
    pmId: string,
    readPermissions?: Array< string | null > | null,
    deleted?: boolean | null,
    property: Property,
    createdTime: string,
    updatedTime: string,
    propertyDashboardPropertyId?: string | null,
};

export type ModelTransactionConnection = {
    __typename: "ModelTransactionConnection",
    items:  Array<Transaction | null >,
    nextToken?: string | null,
};

export type Transaction = {
    __typename: "Transaction",
    id: string,
    amount: number,
    transactionDate: string,
    owner: string,
    type: string,
    category: Category,
    label?: Label | null,
    description?: string | null,
    isEditable?: boolean | null,
    readPermissions?: Array< string | null > | null,
    dashboardPropertyTransactionsId: string,
    property: DashboardProperty,
    createdTime: string,
    updatedTime: string,
};

export enum Category {
    other = "other",
    rent = "rent",
    management_fee = "management_fee",
    mortgage = "mortgage",
    property_tax = "property_tax",
    property_insurance = "property_insurance",
    property_hoa = "property_hoa",
    utilities = "utilities",
    legal_and_professional_fees = "legal_and_professional_fees",
    rental_registration = "rental_registration",
    auto_and_travel = "auto_and_travel",
    supplies = "supplies",
    maintenance = "maintenance",
    leasing_and_marketing = "leasing_and_marketing",
    capital_expenditure = "capital_expenditure",
}


export enum Label {
    estimated = "estimated",
    pm = "pm",
    manual = "manual",
    manual_recurring = "manual_recurring",
}


export type ModelPMTransactionConnection = {
    __typename: "ModelPMTransactionConnection",
    items:  Array<PMTransaction | null >,
    nextToken?: string | null,
};

export type PMTransaction = {
    __typename: "PMTransaction",
    id: string,
    amount: number,
    transactionDate: string,
    pm: string,
    owner: string,
    type: string,
    category: PMTransactionCategory,
    label?: Label | null,
    description?: string | null,
    dashboardPropertyPmTransactionsId: string,
    property: DashboardProperty,
    createdTime: string,
    updatedTime: string,
};

export enum PMTransactionCategory {
    RBP = "RBP",
    MANAGEMENT_FEE = "MANAGEMENT_FEE",
    OBP = "OBP",
    INSURANCE = "INSURANCE",
    LATE_FEE = "LATE_FEE",
    LEASING_AND_MARKETING = "LEASING_AND_MARKETING",
    OTHER = "OTHER",
}


export type ModelLeaseConnection = {
    __typename: "ModelLeaseConnection",
    items:  Array<Lease | null >,
    nextToken?: string | null,
};

export type Lease = {
    __typename: "Lease",
    id: string,
    startDate: string,
    endDate?: string | null,
    isActive?: boolean | null,
    term?: Term | null,
    rent?: number | null,
    owner: string,
    property: DashboardProperty,
    readPermissions?: Array< string | null > | null,
    tenant?: string | null,
    createdTime: string,
    updatedTime: string,
    dashboardPropertyLeasesId?: string | null,
};

export enum Term {
    fixed = "fixed",
    monthToMonth = "monthToMonth",
}


export type ModelPropertyUserExpenseConnection = {
    __typename: "ModelPropertyUserExpenseConnection",
    items:  Array<PropertyUserExpense | null >,
    nextToken?: string | null,
};

export type PropertyUserExpense = {
    __typename: "PropertyUserExpense",
    id: string,
    amount: number,
    startDate: string,
    category: ExpenseCategory,
    owner: string,
    property: DashboardProperty,
    createdTime: string,
    updatedTime: string,
    dashboardPropertyUserExpenseId?: string | null,
};

export enum ExpenseCategory {
    other = "other",
    property_tax = "property_tax",
    property_insurance = "property_insurance",
    property_hoa = "property_hoa",
}


export type ModelPropertyAmortizationTableConnection = {
    __typename: "ModelPropertyAmortizationTableConnection",
    items:  Array<PropertyAmortizationTable | null >,
    nextToken?: string | null,
};

export type PropertyAmortizationTable = {
    __typename: "PropertyAmortizationTable",
    id: string,
    period: number,
    transactionDate: string,
    owner: string,
    payment: number,
    interest: number,
    principal: number,
    totalPrincipal: number,
    mortgageId: string,
    dashboardPropertyAmortizationTableId: string,
    property: DashboardProperty,
    readPermissions?: Array< string | null > | null,
    createdTime: string,
    updatedTime: string,
};

export type ModelMortgageConnection = {
    __typename: "ModelMortgageConnection",
    items:  Array<Mortgage | null >,
    nextToken?: string | null,
};

export type Mortgage = {
    __typename: "Mortgage",
    id: string,
    rate: number,
    amount: number,
    startDate: string,
    mortgageDueDay: number,
    term: number,
    owner: string,
    grantor: string,
    apn: string,
    mortgageType: MortgageType,
    property: DashboardProperty,
    readPermissions?: Array< string | null > | null,
    provider?: Provider | null,
    createdTime: string,
    updatedTime: string,
    dashboardPropertyMortgageId?: string | null,
};

export enum MortgageType {
    purchase = "purchase",
    refinance = "refinance",
}


export type ModelDashboardPropertyValuationConnection = {
    __typename: "ModelDashboardPropertyValuationConnection",
    items:  Array<DashboardPropertyValuation | null >,
    nextToken?: string | null,
};

export type DashboardPropertyValuation = {
    __typename: "DashboardPropertyValuation",
    id: string,
    date: string,
    priceUpper?: number | null,
    priceLower?: number | null,
    priceMean?: number | null,
    fsd?: number | null,
    provider?: Provider | null,
    pm?: string | null,
    dashboardPropertyPropertyValuationId: string,
    property: DashboardProperty,
    createdTime: string,
    updatedTime: string,
};

export type ModelDashboardPropertyPurchaseHistoryConnection = {
    __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
    items:  Array<DashboardPropertyPurchaseHistory | null >,
    nextToken?: string | null,
};

export type DashboardPropertyPurchaseHistory = {
    __typename: "DashboardPropertyPurchaseHistory",
    id: string,
    amount: number,
    closingCost?: number | null,
    homeImprovementCost?: number | null,
    date?: string | null,
    provider?: Provider | null,
    property: DashboardProperty,
    createdTime: string,
    updatedTime: string,
    dashboardPropertyPurchaseHistoryId?: string | null,
};

export type ModelDashboardPropertyMetricConnection = {
    __typename: "ModelDashboardPropertyMetricConnection",
    items:  Array<DashboardPropertyMetric | null >,
    nextToken?: string | null,
};

export type DashboardPropertyMetric = {
    __typename: "DashboardPropertyMetric",
    id: string,
    NOI?: number | null,
    cashOnCash?: number | null,
    capRate?: number | null,
    cashFlow?: number | null,
    annualIncome?: number | null,
    annualExpense?: number | null,
    cashInvested?: number | null,
    isDataPartial?: boolean | null,
    currentVacancy?: number | null,
    turnoverTenants?: number | null,
    totalUniqTenants?: number | null,
    monthsToEndLease?: number | null,
    monthsFromStartLease?: number | null,
    leaseTerm?: string | null,
    appreciation?: number | null,
    valuation?: number | null,
    totalEquity?: number | null,
    missingData?: Array< MissingData | null > | null,
    monthsFromTheFirstTransaction?: number | null,
    firstTransactionDate?: string | null,
    purchasePrice?: number | null,
    purchaseDate?: string | null,
    annualMetrics?: AnnualMetrics | null,
    metricsIn3Months?: Metrics | null,
    metricsIn6Months?: Metrics | null,
    metricsIn12Months?: Metrics | null,
    trailingQ4?: Metrics | null,
    trailingQ3?: Metrics | null,
    trailingQ2?: Metrics | null,
    trailingQ1?: Metrics | null,
    monthsMetrics?:  Array<MonthMetrics > | null,
    dashboardPropertyMetricsId: string,
    property: DashboardProperty,
    createdTime: string,
    updatedTime: string,
};

export enum MissingData {
    AVM = "AVM",
    PURCHASE_PRICE = "PURCHASE_PRICE",
    MORTGAGE_AMOUNT = "MORTGAGE_AMOUNT",
    TAX = "TAX",
    INSURANCE = "INSURANCE",
}


export type AnnualMetrics = {
    __typename: "AnnualMetrics",
    income?: number | null,
    estimatedIncome?: number | null,
    noiExpenses?: number | null,
    totalExpenses?: number | null,
    propertyTax?: number | null,
    propertyInsurance?: number | null,
    propertyHoa?: number | null,
};

export type Metrics = {
    __typename: "Metrics",
    income?: number | null,
    noiExpenses?: number | null,
    totalExpenses?: number | null,
    maintenance?: number | null,
    maintenanceEvents?: number | null,
    managementFee?: number | null,
    cashflow?: number | null,
    noi?: number | null,
    caPex?: number | null,
    monthsRent?: number | null,
    from?: string | null,
    to?: string | null,
};

export type MonthMetrics = {
    __typename: "MonthMetrics",
    isVacant?: boolean | null,
    monthDate?: string | null,
};

export type ModelMarketplacePropertyConnection = {
    __typename: "ModelMarketplacePropertyConnection",
    items:  Array<MarketplaceProperty | null >,
    nextToken?: string | null,
};

export type ModelMarketplacePropertyListingItemConnection = {
    __typename: "ModelMarketplacePropertyListingItemConnection",
    items:  Array<MarketplacePropertyListingItem | null >,
    nextToken?: string | null,
};

export type MarketplacePropertyListingItem = {
    __typename: "MarketplacePropertyListingItem",
    id: string,
    pm: string,
    readPermissions?: Array< string | null > | null,
    property?: MarketplaceProperty | null,
    status: MarketPlacePropertyStatus,
    actualRent?: number | null,
    estimatedRent?: number | null,
    stateInsuranceFactor?: number | null,
    totalExpenses?: number | null,
    maintenance?: number | null,
    estimatedVacancy?: number | null,
    managementFee?: string | null,
    capRate?: number | null,
    noi?: number | null,
    cashOnCash?: number | null,
    grossYield?: number | null,
    insurance?: number | null,
    askingPrice: number,
    bedrooms?: number | null,
    bathrooms?: number | null,
    category: string,
    categoryOrder: number,
    tags: Array< ListingItemTag >,
    partialAddresses: Array< string >,
    isManagedByThePM?: boolean | null,
    canBeManagedByThePM?: boolean | null,
    createdTime: string,
    updatedTime: string,
    marketplacePropertyListingItemsId?: string | null,
};

export enum ListingItemTag {
    one_percent_rule = "one_percent_rule",
    good_crime_rate = "good_crime_rate",
    handpicked_by_blanket = "handpicked_by_blanket",
    handpicked_by_pm = "handpicked_by_pm",
    managed_by_pm = "managed_by_pm",
}


export type UpdateCrimeInput = {
    id: string,
    all?: PercentileInput | null,
    other?: PercentileInput | null,
    property?: PercentileInput | null,
    violent?: PercentileInput | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyCrimeId?: string | null,
};

export type DeleteCrimeInput = {
    id: string,
};

export type CreateDashboardPropertyInput = {
    id?: string | null,
    owner: string,
    name: string,
    address: AddressInput,
    hoaMuted?: boolean | null,
    pmId: string,
    readPermissions?: Array< string | null > | null,
    deleted?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyDashboardPropertyId?: string | null,
};

export type ModelDashboardPropertyConditionInput = {
    owner?: ModelStringInput | null,
    name?: ModelStringInput | null,
    hoaMuted?: ModelBooleanInput | null,
    pmId?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    deleted?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyConditionInput | null > | null,
    or?: Array< ModelDashboardPropertyConditionInput | null > | null,
    not?: ModelDashboardPropertyConditionInput | null,
    propertyDashboardPropertyId?: ModelIDInput | null,
};

export type UpdateDashboardPropertyInput = {
    id: string,
    owner?: string | null,
    name?: string | null,
    address?: AddressInput | null,
    hoaMuted?: boolean | null,
    pmId?: string | null,
    readPermissions?: Array< string | null > | null,
    deleted?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyDashboardPropertyId?: string | null,
};

export type DeleteDashboardPropertyInput = {
    id: string,
};

export type CreateDashboardPropertyMetricInput = {
    id?: string | null,
    NOI?: number | null,
    cashOnCash?: number | null,
    capRate?: number | null,
    cashFlow?: number | null,
    annualIncome?: number | null,
    annualExpense?: number | null,
    cashInvested?: number | null,
    isDataPartial?: boolean | null,
    currentVacancy?: number | null,
    turnoverTenants?: number | null,
    totalUniqTenants?: number | null,
    monthsToEndLease?: number | null,
    monthsFromStartLease?: number | null,
    leaseTerm?: string | null,
    appreciation?: number | null,
    valuation?: number | null,
    totalEquity?: number | null,
    missingData?: Array< MissingData | null > | null,
    monthsFromTheFirstTransaction?: number | null,
    firstTransactionDate?: string | null,
    purchasePrice?: number | null,
    purchaseDate?: string | null,
    annualMetrics?: AnnualMetricsInput | null,
    metricsIn3Months?: MetricsInput | null,
    metricsIn6Months?: MetricsInput | null,
    metricsIn12Months?: MetricsInput | null,
    trailingQ4?: MetricsInput | null,
    trailingQ3?: MetricsInput | null,
    trailingQ2?: MetricsInput | null,
    trailingQ1?: MetricsInput | null,
    monthsMetrics?: Array< MonthMetricsInput > | null,
    dashboardPropertyMetricsId: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type AnnualMetricsInput = {
    income?: number | null,
    estimatedIncome?: number | null,
    noiExpenses?: number | null,
    totalExpenses?: number | null,
    propertyTax?: number | null,
    propertyInsurance?: number | null,
    propertyHoa?: number | null,
};

export type MetricsInput = {
    income?: number | null,
    noiExpenses?: number | null,
    totalExpenses?: number | null,
    maintenance?: number | null,
    maintenanceEvents?: number | null,
    managementFee?: number | null,
    cashflow?: number | null,
    noi?: number | null,
    caPex?: number | null,
    monthsRent?: number | null,
    from?: string | null,
    to?: string | null,
};

export type MonthMetricsInput = {
    isVacant?: boolean | null,
    monthDate?: string | null,
};

export type ModelDashboardPropertyMetricConditionInput = {
    NOI?: ModelFloatInput | null,
    cashOnCash?: ModelFloatInput | null,
    capRate?: ModelFloatInput | null,
    cashFlow?: ModelFloatInput | null,
    annualIncome?: ModelFloatInput | null,
    annualExpense?: ModelFloatInput | null,
    cashInvested?: ModelFloatInput | null,
    isDataPartial?: ModelBooleanInput | null,
    currentVacancy?: ModelIntInput | null,
    turnoverTenants?: ModelFloatInput | null,
    totalUniqTenants?: ModelFloatInput | null,
    monthsToEndLease?: ModelIntInput | null,
    monthsFromStartLease?: ModelIntInput | null,
    leaseTerm?: ModelStringInput | null,
    appreciation?: ModelFloatInput | null,
    valuation?: ModelFloatInput | null,
    totalEquity?: ModelFloatInput | null,
    missingData?: ModelMissingDataListInput | null,
    monthsFromTheFirstTransaction?: ModelIntInput | null,
    firstTransactionDate?: ModelStringInput | null,
    purchasePrice?: ModelFloatInput | null,
    purchaseDate?: ModelStringInput | null,
    dashboardPropertyMetricsId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyMetricConditionInput | null > | null,
    or?: Array< ModelDashboardPropertyMetricConditionInput | null > | null,
    not?: ModelDashboardPropertyMetricConditionInput | null,
};

export type ModelFloatInput = {
    ne?: number | null,
    eq?: number | null,
    le?: number | null,
    lt?: number | null,
    ge?: number | null,
    gt?: number | null,
    between?: Array< number | null > | null,
    attributeExists?: boolean | null,
    attributeType?: ModelAttributeTypes | null,
};

export type ModelMissingDataListInput = {
    eq?: Array< MissingData | null > | null,
    ne?: Array< MissingData | null > | null,
    contains?: MissingData | null,
    notContains?: MissingData | null,
};

export type UpdateDashboardPropertyMetricInput = {
    id: string,
    NOI?: number | null,
    cashOnCash?: number | null,
    capRate?: number | null,
    cashFlow?: number | null,
    annualIncome?: number | null,
    annualExpense?: number | null,
    cashInvested?: number | null,
    isDataPartial?: boolean | null,
    currentVacancy?: number | null,
    turnoverTenants?: number | null,
    totalUniqTenants?: number | null,
    monthsToEndLease?: number | null,
    monthsFromStartLease?: number | null,
    leaseTerm?: string | null,
    appreciation?: number | null,
    valuation?: number | null,
    totalEquity?: number | null,
    missingData?: Array< MissingData | null > | null,
    monthsFromTheFirstTransaction?: number | null,
    firstTransactionDate?: string | null,
    purchasePrice?: number | null,
    purchaseDate?: string | null,
    annualMetrics?: AnnualMetricsInput | null,
    metricsIn3Months?: MetricsInput | null,
    metricsIn6Months?: MetricsInput | null,
    metricsIn12Months?: MetricsInput | null,
    trailingQ4?: MetricsInput | null,
    trailingQ3?: MetricsInput | null,
    trailingQ2?: MetricsInput | null,
    trailingQ1?: MetricsInput | null,
    monthsMetrics?: Array< MonthMetricsInput > | null,
    dashboardPropertyMetricsId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteDashboardPropertyMetricInput = {
    id: string,
};

export type CreateDashboardPropertyPurchaseHistoryInput = {
    id?: string | null,
    amount: number,
    closingCost?: number | null,
    homeImprovementCost?: number | null,
    date?: string | null,
    provider?: Provider | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyPurchaseHistoryId?: string | null,
};

export type ModelDashboardPropertyPurchaseHistoryConditionInput = {
    amount?: ModelFloatInput | null,
    closingCost?: ModelFloatInput | null,
    homeImprovementCost?: ModelFloatInput | null,
    date?: ModelStringInput | null,
    provider?: ModelProviderInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyPurchaseHistoryConditionInput | null > | null,
    or?: Array< ModelDashboardPropertyPurchaseHistoryConditionInput | null > | null,
    not?: ModelDashboardPropertyPurchaseHistoryConditionInput | null,
    dashboardPropertyPurchaseHistoryId?: ModelIDInput | null,
};

export type ModelProviderInput = {
    eq?: Provider | null,
    ne?: Provider | null,
};

export type UpdateDashboardPropertyPurchaseHistoryInput = {
    id: string,
    amount?: number | null,
    closingCost?: number | null,
    homeImprovementCost?: number | null,
    date?: string | null,
    provider?: Provider | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyPurchaseHistoryId?: string | null,
};

export type DeleteDashboardPropertyPurchaseHistoryInput = {
    id: string,
};

export type CreateDashboardPropertyValuationInput = {
    id?: string | null,
    date: string,
    priceUpper?: number | null,
    priceLower?: number | null,
    priceMean?: number | null,
    fsd?: number | null,
    provider?: Provider | null,
    pm?: string | null,
    dashboardPropertyPropertyValuationId: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelDashboardPropertyValuationConditionInput = {
    date?: ModelStringInput | null,
    priceUpper?: ModelIntInput | null,
    priceLower?: ModelIntInput | null,
    priceMean?: ModelIntInput | null,
    fsd?: ModelFloatInput | null,
    provider?: ModelProviderInput | null,
    pm?: ModelStringInput | null,
    dashboardPropertyPropertyValuationId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyValuationConditionInput | null > | null,
    or?: Array< ModelDashboardPropertyValuationConditionInput | null > | null,
    not?: ModelDashboardPropertyValuationConditionInput | null,
};

export type UpdateDashboardPropertyValuationInput = {
    id: string,
    date?: string | null,
    priceUpper?: number | null,
    priceLower?: number | null,
    priceMean?: number | null,
    fsd?: number | null,
    provider?: Provider | null,
    pm?: string | null,
    dashboardPropertyPropertyValuationId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteDashboardPropertyValuationInput = {
    id: string,
};

export type CreateFavoriteMarketplacePropertyInput = {
    id?: string | null,
    owner: string,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyFavoritesId?: string | null,
};

export type ModelFavoriteMarketplacePropertyConditionInput = {
    owner?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelFavoriteMarketplacePropertyConditionInput | null > | null,
    or?: Array< ModelFavoriteMarketplacePropertyConditionInput | null > | null,
    not?: ModelFavoriteMarketplacePropertyConditionInput | null,
    marketplacePropertyFavoritesId?: ModelIDInput | null,
};

export type UpdateFavoriteMarketplacePropertyInput = {
    id: string,
    owner?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyFavoritesId?: string | null,
};

export type DeleteFavoriteMarketplacePropertyInput = {
    id: string,
};

export type CreateGrowthPolicyInput = {
    id?: string | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    agentReferralFeeAmount?: number | null,
    agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
    agentProvidedBenefitsOther?: string | null,
    enableOwnerReferralProgram?: boolean | null,
    ownerReferralsIncentive?: OwnerReferralsIncentive | null,
    enablePMReferralProgram?: boolean | null,
    pmReferralsFeeAmount?: number | null,
    typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
    workingCounties?: Array< WorkingCountyInput > | null,
    doorCount?: number | null,
    yearsInBusiness?: number | null,
    website?: string | null,
    companyDescription?: string | null,
    pmAvatarURL?: string | null,
    pmFirstName?: string | null,
    pmLastName?: string | null,
    pmTitle?: string | null,
    companyGoogleReviews?: number | null,
    companyAVGDayOnMarket?: number | null,
    companyTenantRenewalRate?: number | null,
    companyServices?: Array< CompanyServices > | null,
    companyServicesOther?: string | null,
    companyGuarantees?: Array< CompanyGuarantees > | null,
    companyGuaranteesOther?: string | null,
};

export enum AgentProvidedBenefit {
    REFER_BACK_UPON_BUY = "REFER_BACK_UPON_BUY",
    REFER_BACK_UPON_SELL = "REFER_BACK_UPON_SELL",
    OTHER = "OTHER",
}


export enum OwnerReferralsIncentive {
    ZERO_MGMT_FEE_ONE_MONTH = "ZERO_MGMT_FEE_ONE_MONTH",
    ZERO_MGMT_FEE_TWO_MONTHS = "ZERO_MGMT_FEE_TWO_MONTHS",
    ZERO_MGMT_FEE_THREE_MONTHS = "ZERO_MGMT_FEE_THREE_MONTHS",
    NO_INCENTIVE = "NO_INCENTIVE",
}


export enum ManagedPropertyType {
    SINGLE_FAMILY = "SINGLE_FAMILY",
    TOWNHOME = "TOWNHOME",
    SMALL_MULTI_FAMILY = "SMALL_MULTI_FAMILY",
    MULTI_FAMILY = "MULTI_FAMILY",
    CONDO = "CONDO",
    SECTION_8 = "SECTION_8",
    COMMERCIAL = "COMMERCIAL",
    ALL = "ALL",
}


export type WorkingCountyInput = {
    state: string,
    county: string,
};

export enum CompanyServices {
    MARKETING = "MARKETING",
    TENANT_SCREENING = "TENANT_SCREENING",
    RENT_COLLECTION = "RENT_COLLECTION",
    MAINTENANCE = "MAINTENANCE",
    LEASING = "LEASING",
    INSPECTION = "INSPECTION",
    EVICTION = "EVICTION",
    FINANCIAL_REPORTING = "FINANCIAL_REPORTING",
    OTHER = "OTHER",
}


export enum CompanyGuarantees {
    LEASE_GUARANTEE = "LEASE_GUARANTEE",
    PET_GUARANTEE = "PET_GUARANTEE",
    EVICTION_GUARANTEE = "EVICTION_GUARANTEE",
    ONTIME_RENT_GUARANTEE = "ONTIME_RENT_GUARANTEE",
    OTHER = "OTHER",
}


export type ModelGrowthPolicyConditionInput = {
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    agentReferralFeeAmount?: ModelIntInput | null,
    agentProvidedBenefits?: ModelAgentProvidedBenefitListInput | null,
    agentProvidedBenefitsOther?: ModelStringInput | null,
    enableOwnerReferralProgram?: ModelBooleanInput | null,
    ownerReferralsIncentive?: ModelOwnerReferralsIncentiveInput | null,
    enablePMReferralProgram?: ModelBooleanInput | null,
    pmReferralsFeeAmount?: ModelIntInput | null,
    typeOfPropertiesManaged?: ModelManagedPropertyTypeListInput | null,
    doorCount?: ModelIntInput | null,
    yearsInBusiness?: ModelIntInput | null,
    website?: ModelStringInput | null,
    companyDescription?: ModelStringInput | null,
    pmAvatarURL?: ModelStringInput | null,
    pmFirstName?: ModelStringInput | null,
    pmLastName?: ModelStringInput | null,
    pmTitle?: ModelStringInput | null,
    companyGoogleReviews?: ModelFloatInput | null,
    companyAVGDayOnMarket?: ModelIntInput | null,
    companyTenantRenewalRate?: ModelIntInput | null,
    companyServices?: ModelCompanyServicesListInput | null,
    companyServicesOther?: ModelStringInput | null,
    companyGuarantees?: ModelCompanyGuaranteesListInput | null,
    companyGuaranteesOther?: ModelStringInput | null,
    and?: Array< ModelGrowthPolicyConditionInput | null > | null,
    or?: Array< ModelGrowthPolicyConditionInput | null > | null,
    not?: ModelGrowthPolicyConditionInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    id?: ModelStringInput | null,
};

export type ModelAgentProvidedBenefitListInput = {
    eq?: Array< AgentProvidedBenefit | null > | null,
    ne?: Array< AgentProvidedBenefit | null > | null,
    contains?: AgentProvidedBenefit | null,
    notContains?: AgentProvidedBenefit | null,
};

export type ModelOwnerReferralsIncentiveInput = {
    eq?: OwnerReferralsIncentive | null,
    ne?: OwnerReferralsIncentive | null,
};

export type ModelManagedPropertyTypeListInput = {
    eq?: Array< ManagedPropertyType | null > | null,
    ne?: Array< ManagedPropertyType | null > | null,
    contains?: ManagedPropertyType | null,
    notContains?: ManagedPropertyType | null,
};

export type ModelCompanyServicesListInput = {
    eq?: Array< CompanyServices | null > | null,
    ne?: Array< CompanyServices | null > | null,
    contains?: CompanyServices | null,
    notContains?: CompanyServices | null,
};

export type ModelCompanyGuaranteesListInput = {
    eq?: Array< CompanyGuarantees | null > | null,
    ne?: Array< CompanyGuarantees | null > | null,
    contains?: CompanyGuarantees | null,
    notContains?: CompanyGuarantees | null,
};

export type GrowthPolicy = {
    __typename: "GrowthPolicy",
    id: string,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    agentReferralFeeAmount?: number | null,
    agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
    agentProvidedBenefitsOther?: string | null,
    enableOwnerReferralProgram?: boolean | null,
    ownerReferralsIncentive?: OwnerReferralsIncentive | null,
    enablePMReferralProgram?: boolean | null,
    pmReferralsFeeAmount?: number | null,
    typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
    workingCounties?:  Array<WorkingCounty > | null,
    doorCount?: number | null,
    yearsInBusiness?: number | null,
    website?: string | null,
    companyDescription?: string | null,
    pmAvatarURL?: string | null,
    pmFirstName?: string | null,
    pmLastName?: string | null,
    pmTitle?: string | null,
    companyGoogleReviews?: number | null,
    companyAVGDayOnMarket?: number | null,
    companyTenantRenewalRate?: number | null,
    companyServices?: Array< CompanyServices > | null,
    companyServicesOther?: string | null,
    companyGuarantees?: Array< CompanyGuarantees > | null,
    companyGuaranteesOther?: string | null,
    createdTime: string,
    updatedTime: string,
};

export type WorkingCounty = {
    __typename: "WorkingCounty",
    state: string,
    county: string,
};

export type UpdateGrowthPolicyInput = {
    id: string,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    agentReferralFeeAmount?: number | null,
    agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
    agentProvidedBenefitsOther?: string | null,
    enableOwnerReferralProgram?: boolean | null,
    ownerReferralsIncentive?: OwnerReferralsIncentive | null,
    enablePMReferralProgram?: boolean | null,
    pmReferralsFeeAmount?: number | null,
    typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
    workingCounties?: Array< WorkingCountyInput > | null,
    doorCount?: number | null,
    yearsInBusiness?: number | null,
    website?: string | null,
    companyDescription?: string | null,
    pmAvatarURL?: string | null,
    pmFirstName?: string | null,
    pmLastName?: string | null,
    pmTitle?: string | null,
    companyGoogleReviews?: number | null,
    companyAVGDayOnMarket?: number | null,
    companyTenantRenewalRate?: number | null,
    companyServices?: Array< CompanyServices > | null,
    companyServicesOther?: string | null,
    companyGuarantees?: Array< CompanyGuarantees > | null,
    companyGuaranteesOther?: string | null,
};

export type DeleteGrowthPolicyInput = {
    id: string,
};

export type CreateLeaseInput = {
    id?: string | null,
    startDate: string,
    endDate?: string | null,
    isActive?: boolean | null,
    term?: Term | null,
    rent?: number | null,
    owner: string,
    readPermissions?: Array< string | null > | null,
    tenant?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyLeasesId?: string | null,
};

export type ModelLeaseConditionInput = {
    startDate?: ModelStringInput | null,
    endDate?: ModelStringInput | null,
    isActive?: ModelBooleanInput | null,
    term?: ModelTermInput | null,
    rent?: ModelIntInput | null,
    owner?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    tenant?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelLeaseConditionInput | null > | null,
    or?: Array< ModelLeaseConditionInput | null > | null,
    not?: ModelLeaseConditionInput | null,
    dashboardPropertyLeasesId?: ModelIDInput | null,
};

export type ModelTermInput = {
    eq?: Term | null,
    ne?: Term | null,
};

export type UpdateLeaseInput = {
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    isActive?: boolean | null,
    term?: Term | null,
    rent?: number | null,
    owner?: string | null,
    readPermissions?: Array< string | null > | null,
    tenant?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyLeasesId?: string | null,
};

export type DeleteLeaseInput = {
    id: string,
};

export type CreateLegislationDataInput = {
    id?: string | null,
    state: string,
    billID?: number | null,
    lastActionDate?: string | null,
    billNumber?: string | null,
    title?: string | null,
    url?: string | null,
    relevance?: number | null,
    lastAction?: string | null,
    status?: BillStatus | null,
    summary?: string | null,
    executiveSummary?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export enum BillStatus {
    IN_PROGRESS = "IN_PROGRESS",
    ENACTED = "ENACTED",
    CANCELLED = "CANCELLED",
}


export type ModelLegislationDataConditionInput = {
    state?: ModelStringInput | null,
    billID?: ModelIntInput | null,
    lastActionDate?: ModelStringInput | null,
    billNumber?: ModelStringInput | null,
    title?: ModelStringInput | null,
    url?: ModelStringInput | null,
    relevance?: ModelIntInput | null,
    lastAction?: ModelStringInput | null,
    status?: ModelBillStatusInput | null,
    summary?: ModelStringInput | null,
    executiveSummary?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelLegislationDataConditionInput | null > | null,
    or?: Array< ModelLegislationDataConditionInput | null > | null,
    not?: ModelLegislationDataConditionInput | null,
};

export type ModelBillStatusInput = {
    eq?: BillStatus | null,
    ne?: BillStatus | null,
};

export type LegislationData = {
    __typename: "LegislationData",
    id: string,
    state: string,
    billID?: number | null,
    lastActionDate?: string | null,
    billNumber?: string | null,
    title?: string | null,
    url?: string | null,
    relevance?: number | null,
    lastAction?: string | null,
    status?: BillStatus | null,
    summary?: string | null,
    executiveSummary?: string | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateLegislationDataInput = {
    id: string,
    state?: string | null,
    billID?: number | null,
    lastActionDate?: string | null,
    billNumber?: string | null,
    title?: string | null,
    url?: string | null,
    relevance?: number | null,
    lastAction?: string | null,
    status?: BillStatus | null,
    summary?: string | null,
    executiveSummary?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteLegislationDataInput = {
    id: string,
};

export type CreateMarketDataInput = {
    id?: string | null,
    fips: string,
    dataType?: string | null,
    date: string,
    areaName?: string | null,
    homeValueSFR?: number | null,
    homeValueAll?: number | null,
    marketHeat?: number | null,
    rentSFR?: number | null,
    rentAll?: number | null,
    daysOnMarket?: number | null,
    marketSummary?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelMarketDataConditionInput = {
    fips?: ModelStringInput | null,
    dataType?: ModelStringInput | null,
    date?: ModelStringInput | null,
    areaName?: ModelStringInput | null,
    homeValueSFR?: ModelFloatInput | null,
    homeValueAll?: ModelFloatInput | null,
    marketHeat?: ModelIntInput | null,
    rentSFR?: ModelFloatInput | null,
    rentAll?: ModelFloatInput | null,
    daysOnMarket?: ModelIntInput | null,
    marketSummary?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketDataConditionInput | null > | null,
    or?: Array< ModelMarketDataConditionInput | null > | null,
    not?: ModelMarketDataConditionInput | null,
};

export type MarketData = {
    __typename: "MarketData",
    id: string,
    fips: string,
    dataType?: string | null,
    date: string,
    areaName?: string | null,
    homeValueSFR?: number | null,
    homeValueAll?: number | null,
    marketHeat?: number | null,
    rentSFR?: number | null,
    rentAll?: number | null,
    daysOnMarket?: number | null,
    marketSummary?: string | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateMarketDataInput = {
    id: string,
    fips?: string | null,
    dataType?: string | null,
    date?: string | null,
    areaName?: string | null,
    homeValueSFR?: number | null,
    homeValueAll?: number | null,
    marketHeat?: number | null,
    rentSFR?: number | null,
    rentAll?: number | null,
    daysOnMarket?: number | null,
    marketSummary?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteMarketDataInput = {
    id: string,
};

export type CreateMarketPlacePropertyPictureInput = {
    url: string,
    rank?: number | null,
    marketplacePropertyListingPicturesId: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelMarketPlacePropertyPictureConditionInput = {
    rank?: ModelIntInput | null,
    marketplacePropertyListingPicturesId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketPlacePropertyPictureConditionInput | null > | null,
    or?: Array< ModelMarketPlacePropertyPictureConditionInput | null > | null,
    not?: ModelMarketPlacePropertyPictureConditionInput | null,
};

export type UpdateMarketPlacePropertyPictureInput = {
    url: string,
    rank?: number | null,
    marketplacePropertyListingPicturesId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteMarketPlacePropertyPictureInput = {
    url: string,
};

export type CreateMarketplacePropertyRentalInput = {
    id?: string | null,
    date: string,
    priceUpper?: number | null,
    priceLower?: number | null,
    priceMean?: number | null,
    fsd?: number | null,
    provider?: Provider | null,
    marketplacePropertyPropertyRentalId: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelMarketplacePropertyRentalConditionInput = {
    date?: ModelStringInput | null,
    priceUpper?: ModelIntInput | null,
    priceLower?: ModelIntInput | null,
    priceMean?: ModelIntInput | null,
    fsd?: ModelFloatInput | null,
    provider?: ModelProviderInput | null,
    marketplacePropertyPropertyRentalId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyRentalConditionInput | null > | null,
    or?: Array< ModelMarketplacePropertyRentalConditionInput | null > | null,
    not?: ModelMarketplacePropertyRentalConditionInput | null,
};

export type UpdateMarketplacePropertyRentalInput = {
    id: string,
    date?: string | null,
    priceUpper?: number | null,
    priceLower?: number | null,
    priceMean?: number | null,
    fsd?: number | null,
    provider?: Provider | null,
    marketplacePropertyPropertyRentalId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteMarketplacePropertyRentalInput = {
    id: string,
};

export type CreateMarketplacePMPreferencesInput = {
    pm: string,
    managementFee?: string | null,
    leadsThirdPartyIntegrationRequested?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelMarketplacePMPreferencesConditionInput = {
    managementFee?: ModelStringInput | null,
    leadsThirdPartyIntegrationRequested?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePMPreferencesConditionInput | null > | null,
    or?: Array< ModelMarketplacePMPreferencesConditionInput | null > | null,
    not?: ModelMarketplacePMPreferencesConditionInput | null,
    pm?: ModelStringInput | null,
};

export type MarketplacePMPreferences = {
    __typename: "MarketplacePMPreferences",
    pm: string,
    managementFee?: string | null,
    leadsThirdPartyIntegrationRequested?: boolean | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateMarketplacePMPreferencesInput = {
    pm: string,
    managementFee?: string | null,
    leadsThirdPartyIntegrationRequested?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteMarketplacePMPreferencesInput = {
    pm: string,
};

export type CreateMarketplacePropertyInput = {
    id?: string | null,
    askingPrice: number,
    owner: string,
    status: MarketPlacePropertyStatus,
    closedBy?: string | null,
    activatedTime?: string | null,
    address: AddressInput,
    description?: string | null,
    rent?: number | null,
    constructionStatus?: ConstructionStatus | null,
    category?: MarketplacePropertyCategory | null,
    source?: string | null,
    vendor?: string | null,
    expenses?: MarketPlacePropertyExpensesInput | null,
    readPermissions?: Array< string | null > | null,
    relevantPMs?: Array< string | null > | null,
    shareWith?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyMarketplacePropertyId?: string | null,
};

export type MarketPlacePropertyExpensesInput = {
    hoa?: number | null,
    insurance?: number | null,
    managementFee?: number | null,
    other?: number | null,
    tax?: number | null,
};

export type ModelMarketplacePropertyConditionInput = {
    askingPrice?: ModelIntInput | null,
    owner?: ModelStringInput | null,
    status?: ModelMarketPlacePropertyStatusInput | null,
    closedBy?: ModelStringInput | null,
    activatedTime?: ModelStringInput | null,
    description?: ModelStringInput | null,
    rent?: ModelIntInput | null,
    constructionStatus?: ModelConstructionStatusInput | null,
    category?: ModelMarketplacePropertyCategoryInput | null,
    source?: ModelStringInput | null,
    vendor?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    relevantPMs?: ModelStringInput | null,
    shareWith?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyConditionInput | null > | null,
    or?: Array< ModelMarketplacePropertyConditionInput | null > | null,
    not?: ModelMarketplacePropertyConditionInput | null,
    propertyMarketplacePropertyId?: ModelIDInput | null,
};

export type ModelMarketPlacePropertyStatusInput = {
    eq?: MarketPlacePropertyStatus | null,
    ne?: MarketPlacePropertyStatus | null,
};

export type ModelConstructionStatusInput = {
    eq?: ConstructionStatus | null,
    ne?: ConstructionStatus | null,
};

export type ModelMarketplacePropertyCategoryInput = {
    eq?: MarketplacePropertyCategory | null,
    ne?: MarketplacePropertyCategory | null,
};

export type UpdateMarketplacePropertyInput = {
    id: string,
    askingPrice?: number | null,
    owner?: string | null,
    status?: MarketPlacePropertyStatus | null,
    closedBy?: string | null,
    activatedTime?: string | null,
    address?: AddressInput | null,
    description?: string | null,
    rent?: number | null,
    constructionStatus?: ConstructionStatus | null,
    category?: MarketplacePropertyCategory | null,
    source?: string | null,
    vendor?: string | null,
    expenses?: MarketPlacePropertyExpensesInput | null,
    readPermissions?: Array< string | null > | null,
    relevantPMs?: Array< string | null > | null,
    shareWith?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyMarketplacePropertyId?: string | null,
};

export type DeleteMarketplacePropertyInput = {
    id: string,
};

export type CreateMarketplacePropertyListingItemInput = {
    id?: string | null,
    pm: string,
    readPermissions?: Array< string | null > | null,
    status: MarketPlacePropertyStatus,
    actualRent?: number | null,
    estimatedRent?: number | null,
    stateInsuranceFactor?: number | null,
    totalExpenses?: number | null,
    maintenance?: number | null,
    estimatedVacancy?: number | null,
    managementFee?: string | null,
    capRate?: number | null,
    noi?: number | null,
    cashOnCash?: number | null,
    grossYield?: number | null,
    insurance?: number | null,
    askingPrice: number,
    bedrooms?: number | null,
    bathrooms?: number | null,
    category: string,
    categoryOrder: number,
    tags: Array< ListingItemTag >,
    partialAddresses: Array< string >,
    isManagedByThePM?: boolean | null,
    canBeManagedByThePM?: boolean | null,
    marketplacePropertyListingItemsId?: string | null,
};

export type ModelMarketplacePropertyListingItemConditionInput = {
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    status?: ModelMarketPlacePropertyStatusInput | null,
    actualRent?: ModelIntInput | null,
    estimatedRent?: ModelIntInput | null,
    stateInsuranceFactor?: ModelFloatInput | null,
    totalExpenses?: ModelFloatInput | null,
    maintenance?: ModelFloatInput | null,
    estimatedVacancy?: ModelFloatInput | null,
    managementFee?: ModelStringInput | null,
    capRate?: ModelFloatInput | null,
    noi?: ModelFloatInput | null,
    cashOnCash?: ModelFloatInput | null,
    grossYield?: ModelFloatInput | null,
    insurance?: ModelFloatInput | null,
    askingPrice?: ModelIntInput | null,
    bedrooms?: ModelIntInput | null,
    bathrooms?: ModelFloatInput | null,
    category?: ModelStringInput | null,
    categoryOrder?: ModelIntInput | null,
    tags?: ModelListingItemTagInput | null,
    partialAddresses?: ModelStringInput | null,
    isManagedByThePM?: ModelBooleanInput | null,
    canBeManagedByThePM?: ModelBooleanInput | null,
    and?: Array< ModelMarketplacePropertyListingItemConditionInput | null > | null,
    or?: Array< ModelMarketplacePropertyListingItemConditionInput | null > | null,
    not?: ModelMarketplacePropertyListingItemConditionInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    marketplacePropertyListingItemsId?: ModelIDInput | null,
};

export type ModelListingItemTagInput = {
    eq?: ListingItemTag | null,
    ne?: ListingItemTag | null,
};

export type UpdateMarketplacePropertyListingItemInput = {
    id: string,
    pm?: string | null,
    readPermissions?: Array< string | null > | null,
    status?: MarketPlacePropertyStatus | null,
    actualRent?: number | null,
    estimatedRent?: number | null,
    stateInsuranceFactor?: number | null,
    totalExpenses?: number | null,
    maintenance?: number | null,
    estimatedVacancy?: number | null,
    managementFee?: string | null,
    capRate?: number | null,
    noi?: number | null,
    cashOnCash?: number | null,
    grossYield?: number | null,
    insurance?: number | null,
    askingPrice?: number | null,
    bedrooms?: number | null,
    bathrooms?: number | null,
    category?: string | null,
    categoryOrder?: number | null,
    tags?: Array< ListingItemTag > | null,
    partialAddresses?: Array< string > | null,
    isManagedByThePM?: boolean | null,
    canBeManagedByThePM?: boolean | null,
    marketplacePropertyListingItemsId?: string | null,
};

export type DeleteMarketplacePropertyListingItemInput = {
    id: string,
};

export type CreateMarketplacePropertyPmRecommendationInput = {
    pm: string,
    recommendations?: Array< RecommendationInput | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type RecommendationInput = {
    propertyId: string,
    score: number,
};

export type ModelMarketplacePropertyPmRecommendationConditionInput = {
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyPmRecommendationConditionInput | null > | null,
    or?: Array< ModelMarketplacePropertyPmRecommendationConditionInput | null > | null,
    not?: ModelMarketplacePropertyPmRecommendationConditionInput | null,
    pm?: ModelStringInput | null,
};

export type MarketplacePropertyPmRecommendation = {
    __typename: "MarketplacePropertyPmRecommendation",
    pm: string,
    recommendations?:  Array<Recommendation | null > | null,
    createdTime: string,
    updatedTime: string,
};

export type Recommendation = {
    __typename: "Recommendation",
    propertyId: string,
    score: number,
};

export type UpdateMarketplacePropertyPmRecommendationInput = {
    pm: string,
    recommendations?: Array< RecommendationInput | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteMarketplacePropertyPmRecommendationInput = {
    pm: string,
};

export type CreateMarketplacePropertyRecommendationInput = {
    id?: string | null,
    owner: string,
    score: number,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyRecommendationsId?: string | null,
};

export type ModelMarketplacePropertyRecommendationConditionInput = {
    owner?: ModelStringInput | null,
    score?: ModelFloatInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyRecommendationConditionInput | null > | null,
    or?: Array< ModelMarketplacePropertyRecommendationConditionInput | null > | null,
    not?: ModelMarketplacePropertyRecommendationConditionInput | null,
    marketplacePropertyRecommendationsId?: ModelIDInput | null,
};

export type UpdateMarketplacePropertyRecommendationInput = {
    id: string,
    owner?: string | null,
    score?: number | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyRecommendationsId?: string | null,
};

export type DeleteMarketplacePropertyRecommendationInput = {
    id: string,
};

export type CreateMortgageInput = {
    id?: string | null,
    rate: number,
    amount: number,
    startDate: string,
    mortgageDueDay: number,
    term: number,
    owner: string,
    grantor: string,
    apn: string,
    mortgageType: MortgageType,
    readPermissions?: Array< string | null > | null,
    provider?: Provider | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyMortgageId?: string | null,
};

export type ModelMortgageConditionInput = {
    rate?: ModelFloatInput | null,
    amount?: ModelFloatInput | null,
    startDate?: ModelStringInput | null,
    mortgageDueDay?: ModelIntInput | null,
    term?: ModelIntInput | null,
    owner?: ModelStringInput | null,
    grantor?: ModelStringInput | null,
    apn?: ModelStringInput | null,
    mortgageType?: ModelMortgageTypeInput | null,
    readPermissions?: ModelStringInput | null,
    provider?: ModelProviderInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMortgageConditionInput | null > | null,
    or?: Array< ModelMortgageConditionInput | null > | null,
    not?: ModelMortgageConditionInput | null,
    dashboardPropertyMortgageId?: ModelIDInput | null,
};

export type ModelMortgageTypeInput = {
    eq?: MortgageType | null,
    ne?: MortgageType | null,
};

export type UpdateMortgageInput = {
    id: string,
    rate?: number | null,
    amount?: number | null,
    startDate?: string | null,
    mortgageDueDay?: number | null,
    term?: number | null,
    owner?: string | null,
    grantor?: string | null,
    apn?: string | null,
    mortgageType?: MortgageType | null,
    readPermissions?: Array< string | null > | null,
    provider?: Provider | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyMortgageId?: string | null,
};

export type DeleteMortgageInput = {
    id: string,
};

export type CreateNotificationTemplateInput = {
    id?: string | null,
    template: string,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelNotificationTemplateConditionInput = {
    template?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelNotificationTemplateConditionInput | null > | null,
    or?: Array< ModelNotificationTemplateConditionInput | null > | null,
    not?: ModelNotificationTemplateConditionInput | null,
};

export type NotificationTemplate = {
    __typename: "NotificationTemplate",
    id: string,
    template: string,
    readPermissions?: Array< string | null > | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateNotificationTemplateInput = {
    id: string,
    template?: string | null,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteNotificationTemplateInput = {
    id: string,
};

export type CreateOfferInput = {
    id?: string | null,
    buyer: string,
    seller?: string | null,
    bid?: number | null,
    status: OfferStatus,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyOffersId?: string | null,
};

export type ModelOfferConditionInput = {
    buyer?: ModelStringInput | null,
    seller?: ModelStringInput | null,
    bid?: ModelIntInput | null,
    status?: ModelOfferStatusInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOfferConditionInput | null > | null,
    or?: Array< ModelOfferConditionInput | null > | null,
    not?: ModelOfferConditionInput | null,
    marketplacePropertyOffersId?: ModelIDInput | null,
};

export type ModelOfferStatusInput = {
    eq?: OfferStatus | null,
    ne?: OfferStatus | null,
};

export type UpdateOfferInput = {
    id: string,
    buyer?: string | null,
    seller?: string | null,
    bid?: number | null,
    status?: OfferStatus | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyOffersId?: string | null,
};

export type DeleteOfferInput = {
    id: string,
};

export type CreateOwnerLeadInput = {
    id?: string | null,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    pm?: string | null,
    requestRentalAnalysis?: boolean | null,
    whatAreYouLookingFor?: WhatAreYouLookingFor | null,
    numOfProperties?: number | null,
    addresses?: Array< string | null > | null,
    referrer?: string | null,
    source?: OwnerLeadSource | null,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    partnerOwnerLeadId?: string | null,
};

export enum WhatAreYouLookingFor {
    NOT_SET = "NOT_SET",
    UNKNOWN = "UNKNOWN",
    STOP_SELF_MANAGING = "STOP_SELF_MANAGING",
    CHANGE_PM = "CHANGE_PM",
    BUY_INVESTMENT_PROPERTY = "BUY_INVESTMENT_PROPERTY",
}


export enum OwnerLeadSource {
    IN_APP = "IN_APP",
    LANDING_PAGE_FORM = "LANDING_PAGE_FORM",
}


export type ModelOwnerLeadConditionInput = {
    email?: ModelStringInput | null,
    firstName?: ModelStringInput | null,
    lastName?: ModelStringInput | null,
    phoneNumber?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    requestRentalAnalysis?: ModelBooleanInput | null,
    whatAreYouLookingFor?: ModelWhatAreYouLookingForInput | null,
    numOfProperties?: ModelIntInput | null,
    addresses?: ModelStringInput | null,
    referrer?: ModelStringInput | null,
    source?: ModelOwnerLeadSourceInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerLeadConditionInput | null > | null,
    or?: Array< ModelOwnerLeadConditionInput | null > | null,
    not?: ModelOwnerLeadConditionInput | null,
    partnerOwnerLeadId?: ModelIDInput | null,
};

export type ModelWhatAreYouLookingForInput = {
    eq?: WhatAreYouLookingFor | null,
    ne?: WhatAreYouLookingFor | null,
};

export type ModelOwnerLeadSourceInput = {
    eq?: OwnerLeadSource | null,
    ne?: OwnerLeadSource | null,
};

export type OwnerLead = {
    __typename: "OwnerLead",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    pm?: string | null,
    requestRentalAnalysis?: boolean | null,
    whatAreYouLookingFor?: WhatAreYouLookingFor | null,
    numOfProperties?: number | null,
    addresses?: Array< string | null > | null,
    referrer?: string | null,
    source?: OwnerLeadSource | null,
    readPermissions?: Array< string | null > | null,
    ownerLeadWorkflowItem?: ModelOwnerLeadWorkflowItemConnection | null,
    partner?: Partner | null,
    createdTime: string,
    updatedTime: string,
    partnerOwnerLeadId?: string | null,
};

export type ModelOwnerLeadWorkflowItemConnection = {
    __typename: "ModelOwnerLeadWorkflowItemConnection",
    items:  Array<OwnerLeadWorkflowItem | null >,
    nextToken?: string | null,
};

export type OwnerLeadWorkflowItem = {
    __typename: "OwnerLeadWorkflowItem",
    id: string,
    lead?: ContactDetails | null,
    pm: string,
    requestRentalAnalysis?: boolean | null,
    numOfProperties?: number | null,
    addresses?: Array< string > | null,
    referrer?: string | null,
    source?: OwnerLeadsWorkflowSource | null,
    whatAreYouLookingFor?: WhatAreYouLookingFor | null,
    status: OwnerLeadsWorkflowStatus,
    revealed?: boolean | null,
    ownerLead?: OwnerLead | null,
    readPermissions?: Array< string > | null,
    updatePermissions?: Array< string > | null,
    readShareWith?: Array< string > | null,
    createdTime: string,
    updatedTime: string,
    ownerLeadOwnerLeadWorkflowItemId?: string | null,
};

export enum OwnerLeadsWorkflowSource {
    IN_APP = "IN_APP",
    LANDING_PAGE_FORM = "LANDING_PAGE_FORM",
}


export enum OwnerLeadsWorkflowStatus {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    WON = "WON",
    LOST = "LOST",
}


export type Partner = {
    __typename: "Partner",
    id: string,
    pm?: string | null,
    readPermissions?: Array< string > | null,
    partnerType: PartnerType,
    partnerPM?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    referralAmount?: number | null,
    referralFreeMonths?: number | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    address?: string | null,
    website?: string | null,
    experienceInYears?: number | null,
    workingCounties?:  Array<WorkingCounty > | null,
    doorCount?: number | null,
    minPrice?: number | null,
    maxPrice?: number | null,
    propertyTypes?: Array< WorkPropertyType > | null,
    activity?: Activity | null,
    ownerLead?: ModelOwnerLeadConnection | null,
    ownerLeadReferralLink?: ModelOwnerLeadReferralLinkConnection | null,
    suggestedPartnerPartnerId?: string | null,
    createdTime: string,
    updatedTime: string,
};

export enum PartnerType {
    AGENT = "AGENT",
    OWNER = "OWNER",
    PM = "PM",
    CONTRACTOR = "CONTRACTOR",
    LENDER = "LENDER",
}


export enum WorkPropertyType {
    HOUSE = "HOUSE",
    CONDO = "CONDO",
    TOWNHOUSE = "TOWNHOUSE",
    LOT = "LOT",
    COMMERCIAL = "COMMERCIAL",
    MANUFACTURED = "MANUFACTURED",
    OTHER = "OTHER",
    SINGLE_FAMILY = "SINGLE_FAMILY",
    SMALL_MULTI_FAMILY = "SMALL_MULTI_FAMILY",
    MULTI_FAMILY = "MULTI_FAMILY",
    SECTION_8 = "SECTION_8",
    ALL = "ALL",
}


export enum Activity {
    UNKNOWN = "UNKNOWN",
    LOW = "LOW",
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
}


export type ModelOwnerLeadConnection = {
    __typename: "ModelOwnerLeadConnection",
    items:  Array<OwnerLead | null >,
    nextToken?: string | null,
};

export type ModelOwnerLeadReferralLinkConnection = {
    __typename: "ModelOwnerLeadReferralLinkConnection",
    items:  Array<OwnerLeadReferralLink | null >,
    nextToken?: string | null,
};

export type OwnerLeadReferralLink = {
    __typename: "OwnerLeadReferralLink",
    id: string,
    ownerId: string,
    pm?: string | null,
    partner?: Partner | null,
    readPermissions?: Array< string | null > | null,
    createdTime: string,
    updatedTime: string,
    partnerOwnerLeadReferralLinkId?: string | null,
};

export type UpdateOwnerLeadInput = {
    id: string,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
    pm?: string | null,
    requestRentalAnalysis?: boolean | null,
    whatAreYouLookingFor?: WhatAreYouLookingFor | null,
    numOfProperties?: number | null,
    addresses?: Array< string | null > | null,
    referrer?: string | null,
    source?: OwnerLeadSource | null,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    partnerOwnerLeadId?: string | null,
};

export type DeleteOwnerLeadInput = {
    id: string,
};

export type CreateOwnerLeadReferralLinkInput = {
    id?: string | null,
    ownerId: string,
    pm?: string | null,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    partnerOwnerLeadReferralLinkId?: string | null,
};

export type ModelOwnerLeadReferralLinkConditionInput = {
    ownerId?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerLeadReferralLinkConditionInput | null > | null,
    or?: Array< ModelOwnerLeadReferralLinkConditionInput | null > | null,
    not?: ModelOwnerLeadReferralLinkConditionInput | null,
    partnerOwnerLeadReferralLinkId?: ModelIDInput | null,
};

export type UpdateOwnerLeadReferralLinkInput = {
    id: string,
    ownerId?: string | null,
    pm?: string | null,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    partnerOwnerLeadReferralLinkId?: string | null,
};

export type DeleteOwnerLeadReferralLinkInput = {
    id: string,
};

export type CreateOwnerLeadWorkflowItemInput = {
    id?: string | null,
    lead?: ContactDetailsInput | null,
    pm: string,
    requestRentalAnalysis?: boolean | null,
    numOfProperties?: number | null,
    addresses?: Array< string > | null,
    referrer?: string | null,
    source?: OwnerLeadsWorkflowSource | null,
    whatAreYouLookingFor?: WhatAreYouLookingFor | null,
    status: OwnerLeadsWorkflowStatus,
    revealed?: boolean | null,
    readPermissions?: Array< string > | null,
    updatePermissions?: Array< string > | null,
    readShareWith?: Array< string > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerLeadOwnerLeadWorkflowItemId?: string | null,
};

export type ModelOwnerLeadWorkflowItemConditionInput = {
    pm?: ModelStringInput | null,
    requestRentalAnalysis?: ModelBooleanInput | null,
    numOfProperties?: ModelIntInput | null,
    addresses?: ModelStringInput | null,
    referrer?: ModelStringInput | null,
    source?: ModelOwnerLeadsWorkflowSourceInput | null,
    whatAreYouLookingFor?: ModelWhatAreYouLookingForInput | null,
    status?: ModelOwnerLeadsWorkflowStatusInput | null,
    revealed?: ModelBooleanInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    readShareWith?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerLeadWorkflowItemConditionInput | null > | null,
    or?: Array< ModelOwnerLeadWorkflowItemConditionInput | null > | null,
    not?: ModelOwnerLeadWorkflowItemConditionInput | null,
    ownerLeadOwnerLeadWorkflowItemId?: ModelIDInput | null,
};

export type ModelOwnerLeadsWorkflowSourceInput = {
    eq?: OwnerLeadsWorkflowSource | null,
    ne?: OwnerLeadsWorkflowSource | null,
};

export type ModelOwnerLeadsWorkflowStatusInput = {
    eq?: OwnerLeadsWorkflowStatus | null,
    ne?: OwnerLeadsWorkflowStatus | null,
};

export type UpdateOwnerLeadWorkflowItemInput = {
    id: string,
    lead?: ContactDetailsInput | null,
    pm?: string | null,
    requestRentalAnalysis?: boolean | null,
    numOfProperties?: number | null,
    addresses?: Array< string > | null,
    referrer?: string | null,
    source?: OwnerLeadsWorkflowSource | null,
    whatAreYouLookingFor?: WhatAreYouLookingFor | null,
    status?: OwnerLeadsWorkflowStatus | null,
    revealed?: boolean | null,
    readPermissions?: Array< string > | null,
    updatePermissions?: Array< string > | null,
    readShareWith?: Array< string > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerLeadOwnerLeadWorkflowItemId?: string | null,
};

export type DeleteOwnerLeadWorkflowItemInput = {
    id: string,
};

export type CreateOwnerMarketplacePropertyRecommendationInput = {
    owner: string,
    recommendations?: Array< RecommendationInput | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelOwnerMarketplacePropertyRecommendationConditionInput = {
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerMarketplacePropertyRecommendationConditionInput | null > | null,
    or?: Array< ModelOwnerMarketplacePropertyRecommendationConditionInput | null > | null,
    not?: ModelOwnerMarketplacePropertyRecommendationConditionInput | null,
    owner?: ModelStringInput | null,
};

export type OwnerMarketplacePropertyRecommendation = {
    __typename: "OwnerMarketplacePropertyRecommendation",
    owner: string,
    recommendations?:  Array<Recommendation | null > | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateOwnerMarketplacePropertyRecommendationInput = {
    owner: string,
    recommendations?: Array< RecommendationInput | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteOwnerMarketplacePropertyRecommendationInput = {
    owner: string,
};

export type CreateOwnerReportInput = {
    id?: string | null,
    owner: string,
    month: number,
    year: number,
};

export type ModelOwnerReportConditionInput = {
    owner?: ModelStringInput | null,
    month?: ModelIntInput | null,
    year?: ModelIntInput | null,
    and?: Array< ModelOwnerReportConditionInput | null > | null,
    or?: Array< ModelOwnerReportConditionInput | null > | null,
    not?: ModelOwnerReportConditionInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
};

export type OwnerReport = {
    __typename: "OwnerReport",
    id: string,
    owner: string,
    month: number,
    year: number,
    reports?: ModelReportConnection | null,
    createdAt: string,
    updatedAt: string,
};

export type ModelReportConnection = {
    __typename: "ModelReportConnection",
    items:  Array<Report | null >,
    nextToken?: string | null,
};

export type Report = {
    __typename: "Report",
    id: string,
    owner: string,
    name: string,
    version: number,
    totalOccupiedUnits: number,
    totalUnits: number,
    homeValue: number,
    appreciation: number,
    totalOtherIncome?: number | null,
    totalRent: number,
    totalEquity: number,
    noi: number,
    netCashFlow: number,
    ownerReport: OwnerReport,
    expenses: Expenses,
    previousMonthlyMeasures: PreviousMonthlyMeasures,
    yearlyMeasures: YearlyMeasures,
    reportType: ReportType,
    createdAt: string,
    updatedAt: string,
    ownerReportReportsId?: string | null,
};

export type Expenses = {
    __typename: "Expenses",
    hoa: number,
    insurance: number,
    managementFee: number,
    other: number,
    tax: number,
    mortgage: number,
    utilities?: number | null,
    legalAndProfessionalFees?: number | null,
    rentalRegistration?: number | null,
    autoAndTravel?: number | null,
    supplies?: number | null,
    maintenance?: number | null,
    leasingAndMarketing?: number | null,
    totalExpense: number,
};

export type PreviousMonthlyMeasures = {
    __typename: "PreviousMonthlyMeasures",
    homeValue: number,
    appreciation: number,
    totalEquity: number,
};

export type YearlyMeasures = {
    __typename: "YearlyMeasures",
    capRate: number,
    cashOnCash: number,
};

export enum ReportType {
    property = "property",
    portfolio = "portfolio",
}


export type UpdateOwnerReportInput = {
    id: string,
    owner?: string | null,
    month?: number | null,
    year?: number | null,
};

export type DeleteOwnerReportInput = {
    id: string,
};

export type CreateOwnerRetentionDetailsInput = {
    id?: string | null,
    pm: string,
    readPermissions?: Array< string | null > | null,
    satisfactionLevel?: SatisfactionLevel | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskOwnerRetentionDetailsId?: string | null,
};

export enum SatisfactionLevel {
    VERY_DISSATISFIED = "VERY_DISSATISFIED",
    DISSATISFIED = "DISSATISFIED",
    NEUTRAL = "NEUTRAL",
    SATISFIED = "SATISFIED",
    VERY_SATISFIED = "VERY_SATISFIED",
}


export type ModelOwnerRetentionDetailsConditionInput = {
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    satisfactionLevel?: ModelSatisfactionLevelInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRetentionDetailsConditionInput | null > | null,
    or?: Array< ModelOwnerRetentionDetailsConditionInput | null > | null,
    not?: ModelOwnerRetentionDetailsConditionInput | null,
    ownerRiskOwnerRetentionDetailsId?: ModelIDInput | null,
};

export type ModelSatisfactionLevelInput = {
    eq?: SatisfactionLevel | null,
    ne?: SatisfactionLevel | null,
};

export type OwnerRetentionDetails = {
    __typename: "OwnerRetentionDetails",
    id: string,
    pm: string,
    readPermissions?: Array< string | null > | null,
    satisfactionLevel?: SatisfactionLevel | null,
    ownerRisk: OwnerRisk,
    createdTime: string,
    updatedTime: string,
    ownerRiskOwnerRetentionDetailsId?: string | null,
};

export type OwnerRisk = {
    __typename: "OwnerRisk",
    id: string,
    ownerID: string,
    pm: string,
    ownerSince?: string | null,
    ownerDetails: RiskOwnerDetails,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    risk?: number | null,
    riskLevel?: RiskLevel | null,
    rawRisk?: number | null,
    scorePerProperty?:  Array<PropertyScore > | null,
    severity: OwnerRiskSeverity,
    severityScore?: number | null,
    severityManualOverride?: boolean | null,
    insights?:  Array<OwnerRiskInsight > | null,
    clientValue: number,
    clientValueBreakDown?:  Array<ClientValueCategory > | null,
    clientValueGroup: ClientValueGroup,
    status: OwnerRiskStatus,
    resolvedReason?: ResolvedReason | null,
    resolutionExplanation?: string | null,
    inProgressSetDate?: string | null,
    resolvedDate?: string | null,
    statusChangedDate?: string | null,
    recommendations?: ModelOwnerRiskRecommendationConnection | null,
    ownerRiskTrends?: ModelOwnerRiskTrendConnection | null,
    ownerRetentionDetails?: ModelOwnerRetentionDetailsConnection | null,
    ownerRisksInsights?: ModelRiskInsightConnection | null,
    createdTime: string,
    updatedTime: string,
};

export type RiskOwnerDetails = {
    __typename: "RiskOwnerDetails",
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
};

export enum RiskLevel {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
}


export type PropertyScore = {
    __typename: "PropertyScore",
    propertyID?: string | null,
    score?: number | null,
    rawScore?: number | null,
};

export enum OwnerRiskSeverity {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
    NO_RISK = "NO_RISK",
}


export type OwnerRiskInsight = {
    __typename: "OwnerRiskInsight",
    id: string,
    caseName?: string | null,
    propertyID?: string | null,
    ownerEmail?: string | null,
    level: InsightLevel,
    context?:  Array<InsightContext > | null,
    insightType?: InsightType | null,
    weight?: number | null,
    quarters?:  Array<OwnerRiskQuarterInsight > | null,
    value?: number | null,
};

export enum InsightLevel {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
    UNKNOWN = "UNKNOWN",
}


export type InsightContext = {
    __typename: "InsightContext",
    Key?: string | null,
    Value?: number | null,
};

export enum InsightType {
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE",
    NEUTRAL = "NEUTRAL",
}


export type OwnerRiskQuarterInsight = {
    __typename: "OwnerRiskQuarterInsight",
    startDate: string,
    endDate?: string | null,
    level: InsightLevel,
    context?:  Array<InsightContext > | null,
    insightType?: InsightType | null,
    value?: number | null,
    quarter?: number | null,
};

export type ClientValueCategory = {
    __typename: "ClientValueCategory",
    category?: PMTransactionCategory | null,
    value?: number | null,
};

export enum ClientValueGroup {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
}


export enum OwnerRiskStatus {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    RESOLVED = "RESOLVED",
}


export enum ResolvedReason {
    OWNER_WANTS_TO_STAY = "OWNER_WANTS_TO_STAY",
    OWNER_WANTS_TO_SELL = "OWNER_WANTS_TO_SELL",
    OWNER_WANTS_TO_LEAVE = "OWNER_WANTS_TO_LEAVE",
    OTHER = "OTHER",
}


export type ModelOwnerRiskRecommendationConnection = {
    __typename: "ModelOwnerRiskRecommendationConnection",
    items:  Array<OwnerRiskRecommendation | null >,
    nextToken?: string | null,
};

export type OwnerRiskRecommendation = {
    __typename: "OwnerRiskRecommendation",
    id: string,
    pm: string,
    ownerRisk: OwnerRisk,
    mitigations: Array< OwnerRiskMitigation >,
    userSelectedMitigations?: Array< OwnerRiskMitigation > | null,
    summaryMarkdown: string,
    positivePoints?: Array< string > | null,
    negativePoints?: Array< string > | null,
    emailHTML?: string | null,
    modifiedPM?: boolean | null,
    createdTime: string,
    updatedTime: string,
    ownerRiskRecommendationsId?: string | null,
};

export enum OwnerRiskMitigation {
    DISCOUNT_1_MONTH = "DISCOUNT_1_MONTH",
    DISCOUNT_2_MONTH = "DISCOUNT_2_MONTH",
    DISCOUNT_3_MONTH = "DISCOUNT_3_MONTH",
    SCHEDULE_MEETING = "SCHEDULE_MEETING",
    ATTACH_VALUE_REPORT = "ATTACH_VALUE_REPORT",
    ATTACH_PREFORMA_REPORT = "ATTACH_PREFORMA_REPORT",
    LINK_TO_PROFORMA = "LINK_TO_PROFORMA",
    LINK_TO_INVESTOR_DASHBOARD = "LINK_TO_INVESTOR_DASHBOARD",
    PUSH_TO_SELL = "PUSH_TO_SELL",
    PUSH_TO_1031 = "PUSH_TO_1031",
    FIX_PROPERTY_ISSUES = "FIX_PROPERTY_ISSUES",
    FIRE_OWNER = "FIRE_OWNER",
    INCREASE_MGMT_FEE = "INCREASE_MGMT_FEE",
}


export type ModelOwnerRiskTrendConnection = {
    __typename: "ModelOwnerRiskTrendConnection",
    items:  Array<OwnerRiskTrend | null >,
    nextToken?: string | null,
};

export type OwnerRiskTrend = {
    __typename: "OwnerRiskTrend",
    id: string,
    pm: string,
    readPermissions?: Array< string | null > | null,
    risk?: number | null,
    ownerRisk: OwnerRisk,
    createdTime: string,
    updatedTime: string,
    ownerRiskOwnerRiskTrendsId?: string | null,
};

export type ModelOwnerRetentionDetailsConnection = {
    __typename: "ModelOwnerRetentionDetailsConnection",
    items:  Array<OwnerRetentionDetails | null >,
    nextToken?: string | null,
};

export type ModelRiskInsightConnection = {
    __typename: "ModelRiskInsightConnection",
    items:  Array<RiskInsight | null >,
    nextToken?: string | null,
};

export type RiskInsight = {
    __typename: "RiskInsight",
    id: string,
    caseName?: string | null,
    propertyID?: string | null,
    ownerEmail?: string | null,
    level: InsightLevel,
    context?:  Array<InsightContext > | null,
    weight?: number | null,
    value?: number | null,
    insightType?: InsightType | null,
    ownerRisk: OwnerRisk,
    quarters?:  Array<OwnerRiskQuarterInsight > | null,
    createdTime: string,
    updatedTime: string,
    ownerRiskOwnerRisksInsightsId?: string | null,
};

export type UpdateOwnerRetentionDetailsInput = {
    id: string,
    pm?: string | null,
    readPermissions?: Array< string | null > | null,
    satisfactionLevel?: SatisfactionLevel | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskOwnerRetentionDetailsId?: string | null,
};

export type DeleteOwnerRetentionDetailsInput = {
    id: string,
};

export type CreateOwnerRiskInput = {
    id?: string | null,
    ownerID: string,
    pm: string,
    ownerSince?: string | null,
    ownerDetails: RiskOwnerDetailsInput,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    risk?: number | null,
    riskLevel?: RiskLevel | null,
    rawRisk?: number | null,
    scorePerProperty?: Array< PropertyScoreInput > | null,
    severity: OwnerRiskSeverity,
    severityScore?: number | null,
    severityManualOverride?: boolean | null,
    insights?: Array< OwnerRiskInsightInput > | null,
    clientValue: number,
    clientValueBreakDown?: Array< ClientValueCategoryInput > | null,
    clientValueGroup: ClientValueGroup,
    status: OwnerRiskStatus,
    resolvedReason?: ResolvedReason | null,
    resolutionExplanation?: string | null,
    inProgressSetDate?: string | null,
    resolvedDate?: string | null,
    statusChangedDate?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type RiskOwnerDetailsInput = {
    firstName?: string | null,
    lastName?: string | null,
    phoneNumber?: string | null,
};

export type PropertyScoreInput = {
    propertyID?: string | null,
    score?: number | null,
    rawScore?: number | null,
};

export type OwnerRiskInsightInput = {
    id: string,
    caseName?: string | null,
    propertyID?: string | null,
    ownerEmail?: string | null,
    level: InsightLevel,
    context?: Array< InsightContextInput > | null,
    insightType?: InsightType | null,
    weight?: number | null,
    quarters?: Array< OwnerRiskQuarterInsightInput > | null,
    value?: number | null,
};

export type InsightContextInput = {
    Key?: string | null,
    Value?: number | null,
};

export type OwnerRiskQuarterInsightInput = {
    startDate: string,
    endDate?: string | null,
    level: InsightLevel,
    context?: Array< InsightContextInput > | null,
    insightType?: InsightType | null,
    value?: number | null,
    quarter?: number | null,
};

export type ClientValueCategoryInput = {
    category?: PMTransactionCategory | null,
    value?: number | null,
};

export type ModelOwnerRiskConditionInput = {
    ownerID?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    ownerSince?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    risk?: ModelIntInput | null,
    riskLevel?: ModelRiskLevelInput | null,
    rawRisk?: ModelFloatInput | null,
    severity?: ModelOwnerRiskSeverityInput | null,
    severityScore?: ModelFloatInput | null,
    severityManualOverride?: ModelBooleanInput | null,
    clientValue?: ModelIntInput | null,
    clientValueGroup?: ModelClientValueGroupInput | null,
    status?: ModelOwnerRiskStatusInput | null,
    resolvedReason?: ModelResolvedReasonInput | null,
    resolutionExplanation?: ModelStringInput | null,
    inProgressSetDate?: ModelStringInput | null,
    resolvedDate?: ModelStringInput | null,
    statusChangedDate?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRiskConditionInput | null > | null,
    or?: Array< ModelOwnerRiskConditionInput | null > | null,
    not?: ModelOwnerRiskConditionInput | null,
};

export type ModelRiskLevelInput = {
    eq?: RiskLevel | null,
    ne?: RiskLevel | null,
};

export type ModelOwnerRiskSeverityInput = {
    eq?: OwnerRiskSeverity | null,
    ne?: OwnerRiskSeverity | null,
};

export type ModelClientValueGroupInput = {
    eq?: ClientValueGroup | null,
    ne?: ClientValueGroup | null,
};

export type ModelOwnerRiskStatusInput = {
    eq?: OwnerRiskStatus | null,
    ne?: OwnerRiskStatus | null,
};

export type ModelResolvedReasonInput = {
    eq?: ResolvedReason | null,
    ne?: ResolvedReason | null,
};

export type UpdateOwnerRiskInput = {
    id: string,
    ownerID?: string | null,
    pm?: string | null,
    ownerSince?: string | null,
    ownerDetails?: RiskOwnerDetailsInput | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    risk?: number | null,
    riskLevel?: RiskLevel | null,
    rawRisk?: number | null,
    scorePerProperty?: Array< PropertyScoreInput > | null,
    severity?: OwnerRiskSeverity | null,
    severityScore?: number | null,
    severityManualOverride?: boolean | null,
    insights?: Array< OwnerRiskInsightInput > | null,
    clientValue?: number | null,
    clientValueBreakDown?: Array< ClientValueCategoryInput > | null,
    clientValueGroup?: ClientValueGroup | null,
    status?: OwnerRiskStatus | null,
    resolvedReason?: ResolvedReason | null,
    resolutionExplanation?: string | null,
    inProgressSetDate?: string | null,
    resolvedDate?: string | null,
    statusChangedDate?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteOwnerRiskInput = {
    id: string,
};

export type CreateOwnerRiskRecommendationInput = {
    id?: string | null,
    pm: string,
    mitigations: Array< OwnerRiskMitigation >,
    userSelectedMitigations?: Array< OwnerRiskMitigation > | null,
    summaryMarkdown: string,
    positivePoints?: Array< string > | null,
    negativePoints?: Array< string > | null,
    emailHTML?: string | null,
    modifiedPM?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskRecommendationsId?: string | null,
};

export type ModelOwnerRiskRecommendationConditionInput = {
    pm?: ModelStringInput | null,
    mitigations?: ModelOwnerRiskMitigationInput | null,
    userSelectedMitigations?: ModelOwnerRiskMitigationListInput | null,
    summaryMarkdown?: ModelStringInput | null,
    positivePoints?: ModelStringInput | null,
    negativePoints?: ModelStringInput | null,
    emailHTML?: ModelStringInput | null,
    modifiedPM?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRiskRecommendationConditionInput | null > | null,
    or?: Array< ModelOwnerRiskRecommendationConditionInput | null > | null,
    not?: ModelOwnerRiskRecommendationConditionInput | null,
    ownerRiskRecommendationsId?: ModelIDInput | null,
};

export type ModelOwnerRiskMitigationInput = {
    eq?: OwnerRiskMitigation | null,
    ne?: OwnerRiskMitigation | null,
};

export type ModelOwnerRiskMitigationListInput = {
    eq?: Array< OwnerRiskMitigation | null > | null,
    ne?: Array< OwnerRiskMitigation | null > | null,
    contains?: OwnerRiskMitigation | null,
    notContains?: OwnerRiskMitigation | null,
};

export type UpdateOwnerRiskRecommendationInput = {
    id: string,
    pm?: string | null,
    mitigations?: Array< OwnerRiskMitigation > | null,
    userSelectedMitigations?: Array< OwnerRiskMitigation > | null,
    summaryMarkdown?: string | null,
    positivePoints?: Array< string > | null,
    negativePoints?: Array< string > | null,
    emailHTML?: string | null,
    modifiedPM?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskRecommendationsId?: string | null,
};

export type DeleteOwnerRiskRecommendationInput = {
    id: string,
};

export type CreateOwnerRiskTrendInput = {
    id?: string | null,
    pm: string,
    readPermissions?: Array< string | null > | null,
    risk?: number | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskOwnerRiskTrendsId?: string | null,
};

export type ModelOwnerRiskTrendConditionInput = {
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    risk?: ModelIntInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRiskTrendConditionInput | null > | null,
    or?: Array< ModelOwnerRiskTrendConditionInput | null > | null,
    not?: ModelOwnerRiskTrendConditionInput | null,
    ownerRiskOwnerRiskTrendsId?: ModelIDInput | null,
};

export type UpdateOwnerRiskTrendInput = {
    id: string,
    pm?: string | null,
    readPermissions?: Array< string | null > | null,
    risk?: number | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskOwnerRiskTrendsId?: string | null,
};

export type DeleteOwnerRiskTrendInput = {
    id: string,
};

export type CreatePMMetricsInput = {
    id?: string | null,
    usersMetrics?: UsersMetricsInput | null,
    communicationMetrics?: CommunicationMetricsInput | null,
    propertyMetrics?: PropertyMetricsInput | null,
    riskMetrics?: OwnerRiskMetricsInput | null,
    leadMetrics?: LeadMetricsInput | null,
    readPermissions?: Array< string > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type UsersMetricsInput = {
    activatedUsers: number,
    activatedUsersLastMonth?: number | null,
    invitedUsers: number,
    invitedUsersLastMonth?: number | null,
    activeUsersByMonth: Array< ActiveUsersByMonthInput >,
    createdTime: string,
};

export type ActiveUsersByMonthInput = {
    month: number,
    year: number,
    activeUsers: number,
};

export type CommunicationMetricsInput = {
    emailsSentToPMUsersByMonth: Array< EmailsSentToPMUsersByMonthInput >,
    emailsSentThisMonth: number,
    createdTime: string,
};

export type EmailsSentToPMUsersByMonthInput = {
    month: number,
    year: number,
    emailsSent: number,
};

export type PropertyMetricsInput = {
    properties: number,
    units: number,
    unitsByMonth: Array< UnitsByMonthInput >,
    createdTime: string,
};

export type UnitsByMonthInput = {
    month: number,
    year: number,
    units: number,
};

export type OwnerRiskMetricsInput = {
    totalNoRisk: number,
    noRiskTotalValue: number,
    totalNeedsAttention: number,
    needsAttentionTotalValue: number,
    totalInProgress: number,
    inProgressTotalValue: number,
    totalResolved: number,
    resolvedTotalValue: number,
    createdTime: string,
};

export type LeadMetricsInput = {
    totalReferrals: number,
    totalReferralsValue: number,
    totalBuyerLeads: number,
    totalBuyerLeadsValue: number,
    totalSellerLeads: number,
    totalSellerLeadsValue: number,
    totalProspects: number,
    totalProspectsValue: number,
    totalBuyBoxLeads: number,
    totalBuyBoxLeadsValue: number,
    createdTime: string,
};

export type ModelPMMetricsConditionInput = {
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPMMetricsConditionInput | null > | null,
    or?: Array< ModelPMMetricsConditionInput | null > | null,
    not?: ModelPMMetricsConditionInput | null,
};

export type PMMetrics = {
    __typename: "PMMetrics",
    id: string,
    usersMetrics?: UsersMetrics | null,
    communicationMetrics?: CommunicationMetrics | null,
    propertyMetrics?: PropertyMetrics | null,
    riskMetrics?: OwnerRiskMetrics | null,
    leadMetrics?: LeadMetrics | null,
    readPermissions?: Array< string > | null,
    createdTime: string,
    updatedTime: string,
};

export type UsersMetrics = {
    __typename: "UsersMetrics",
    activatedUsers: number,
    activatedUsersLastMonth?: number | null,
    invitedUsers: number,
    invitedUsersLastMonth?: number | null,
    activeUsersByMonth:  Array<ActiveUsersByMonth >,
    createdTime: string,
};

export type ActiveUsersByMonth = {
    __typename: "ActiveUsersByMonth",
    month: number,
    year: number,
    activeUsers: number,
};

export type CommunicationMetrics = {
    __typename: "CommunicationMetrics",
    emailsSentToPMUsersByMonth:  Array<EmailsSentToPMUsersByMonth >,
    emailsSentThisMonth: number,
    createdTime: string,
};

export type EmailsSentToPMUsersByMonth = {
    __typename: "EmailsSentToPMUsersByMonth",
    month: number,
    year: number,
    emailsSent: number,
};

export type PropertyMetrics = {
    __typename: "PropertyMetrics",
    properties: number,
    units: number,
    unitsByMonth:  Array<UnitsByMonth >,
    createdTime: string,
};

export type UnitsByMonth = {
    __typename: "UnitsByMonth",
    month: number,
    year: number,
    units: number,
};

export type OwnerRiskMetrics = {
    __typename: "OwnerRiskMetrics",
    totalNoRisk: number,
    noRiskTotalValue: number,
    totalNeedsAttention: number,
    needsAttentionTotalValue: number,
    totalInProgress: number,
    inProgressTotalValue: number,
    totalResolved: number,
    resolvedTotalValue: number,
    createdTime: string,
};

export type LeadMetrics = {
    __typename: "LeadMetrics",
    totalReferrals: number,
    totalReferralsValue: number,
    totalBuyerLeads: number,
    totalBuyerLeadsValue: number,
    totalSellerLeads: number,
    totalSellerLeadsValue: number,
    totalProspects: number,
    totalProspectsValue: number,
    totalBuyBoxLeads: number,
    totalBuyBoxLeadsValue: number,
    createdTime: string,
};

export type UpdatePMMetricsInput = {
    id: string,
    usersMetrics?: UsersMetricsInput | null,
    communicationMetrics?: CommunicationMetricsInput | null,
    propertyMetrics?: PropertyMetricsInput | null,
    riskMetrics?: OwnerRiskMetricsInput | null,
    leadMetrics?: LeadMetricsInput | null,
    readPermissions?: Array< string > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeletePMMetricsInput = {
    id: string,
};

export type CreatePMSettingsInput = {
    pm: string,
    leadershipSummary2024?: string | null,
    dashboardProformaMetricsOverride?: string | null,
    showLegalSummary?: boolean | null,
    obpTitleOverride?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelPMSettingsConditionInput = {
    leadershipSummary2024?: ModelStringInput | null,
    dashboardProformaMetricsOverride?: ModelStringInput | null,
    showLegalSummary?: ModelBooleanInput | null,
    obpTitleOverride?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPMSettingsConditionInput | null > | null,
    or?: Array< ModelPMSettingsConditionInput | null > | null,
    not?: ModelPMSettingsConditionInput | null,
    pm?: ModelStringInput | null,
};

export type PMSettings = {
    __typename: "PMSettings",
    pm: string,
    leadershipSummary2024?: string | null,
    dashboardProformaMetricsOverride?: string | null,
    showLegalSummary?: boolean | null,
    obpTitleOverride?: string | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdatePMSettingsInput = {
    pm: string,
    leadershipSummary2024?: string | null,
    dashboardProformaMetricsOverride?: string | null,
    showLegalSummary?: boolean | null,
    obpTitleOverride?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeletePMSettingsInput = {
    pm: string,
};

export type CreatePMTransactionInput = {
    id?: string | null,
    amount: number,
    transactionDate: string,
    pm: string,
    owner: string,
    type: string,
    category: PMTransactionCategory,
    label?: Label | null,
    description?: string | null,
    dashboardPropertyPmTransactionsId: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelPMTransactionConditionInput = {
    amount?: ModelFloatInput | null,
    transactionDate?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    owner?: ModelStringInput | null,
    type?: ModelStringInput | null,
    category?: ModelPMTransactionCategoryInput | null,
    label?: ModelLabelInput | null,
    description?: ModelStringInput | null,
    dashboardPropertyPmTransactionsId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPMTransactionConditionInput | null > | null,
    or?: Array< ModelPMTransactionConditionInput | null > | null,
    not?: ModelPMTransactionConditionInput | null,
};

export type ModelPMTransactionCategoryInput = {
    eq?: PMTransactionCategory | null,
    ne?: PMTransactionCategory | null,
};

export type ModelLabelInput = {
    eq?: Label | null,
    ne?: Label | null,
};

export type UpdatePMTransactionInput = {
    id: string,
    amount?: number | null,
    transactionDate?: string | null,
    pm?: string | null,
    owner?: string | null,
    type?: string | null,
    category?: PMTransactionCategory | null,
    label?: Label | null,
    description?: string | null,
    dashboardPropertyPmTransactionsId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeletePMTransactionInput = {
    id: string,
};

export type CreatePartnerInput = {
    id?: string | null,
    pm?: string | null,
    readPermissions?: Array< string > | null,
    partnerType: PartnerType,
    partnerPM?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    referralAmount?: number | null,
    referralFreeMonths?: number | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    address?: string | null,
    website?: string | null,
    experienceInYears?: number | null,
    workingCounties?: Array< WorkingCountyInput > | null,
    doorCount?: number | null,
    minPrice?: number | null,
    maxPrice?: number | null,
    propertyTypes?: Array< WorkPropertyType > | null,
    activity?: Activity | null,
    suggestedPartnerPartnerId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelPartnerConditionInput = {
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    partnerType?: ModelPartnerTypeInput | null,
    partnerPM?: ModelStringInput | null,
    firstName?: ModelStringInput | null,
    lastName?: ModelStringInput | null,
    email?: ModelStringInput | null,
    referralAmount?: ModelIntInput | null,
    referralFreeMonths?: ModelFloatInput | null,
    phoneNumber?: ModelStringInput | null,
    avatar?: ModelStringInput | null,
    address?: ModelStringInput | null,
    website?: ModelStringInput | null,
    experienceInYears?: ModelIntInput | null,
    doorCount?: ModelIntInput | null,
    minPrice?: ModelIntInput | null,
    maxPrice?: ModelIntInput | null,
    propertyTypes?: ModelWorkPropertyTypeListInput | null,
    activity?: ModelActivityInput | null,
    suggestedPartnerPartnerId?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPartnerConditionInput | null > | null,
    or?: Array< ModelPartnerConditionInput | null > | null,
    not?: ModelPartnerConditionInput | null,
};

export type ModelPartnerTypeInput = {
    eq?: PartnerType | null,
    ne?: PartnerType | null,
};

export type ModelWorkPropertyTypeListInput = {
    eq?: Array< WorkPropertyType | null > | null,
    ne?: Array< WorkPropertyType | null > | null,
    contains?: WorkPropertyType | null,
    notContains?: WorkPropertyType | null,
};

export type ModelActivityInput = {
    eq?: Activity | null,
    ne?: Activity | null,
};

export type UpdatePartnerInput = {
    id: string,
    pm?: string | null,
    readPermissions?: Array< string > | null,
    partnerType?: PartnerType | null,
    partnerPM?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    referralAmount?: number | null,
    referralFreeMonths?: number | null,
    phoneNumber?: string | null,
    avatar?: string | null,
    address?: string | null,
    website?: string | null,
    experienceInYears?: number | null,
    workingCounties?: Array< WorkingCountyInput > | null,
    doorCount?: number | null,
    minPrice?: number | null,
    maxPrice?: number | null,
    propertyTypes?: Array< WorkPropertyType > | null,
    activity?: Activity | null,
    suggestedPartnerPartnerId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeletePartnerInput = {
    id: string,
};

export type CreatePmMarketplacePropertyRecommendationInput = {
    id?: string | null,
    pm: string,
    score: number,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyPmRecommendationsId?: string | null,
};

export type ModelPmMarketplacePropertyRecommendationConditionInput = {
    pm?: ModelStringInput | null,
    score?: ModelFloatInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPmMarketplacePropertyRecommendationConditionInput | null > | null,
    or?: Array< ModelPmMarketplacePropertyRecommendationConditionInput | null > | null,
    not?: ModelPmMarketplacePropertyRecommendationConditionInput | null,
    marketplacePropertyPmRecommendationsId?: ModelIDInput | null,
};

export type UpdatePmMarketplacePropertyRecommendationInput = {
    id: string,
    pm?: string | null,
    score?: number | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    marketplacePropertyPmRecommendationsId?: string | null,
};

export type DeletePmMarketplacePropertyRecommendationInput = {
    id: string,
};

export type CreatePropertyInput = {
    id?: string | null,
    propertyType?: PropertyType | null,
    address: AddressInput,
    yearBuilt?: number | null,
    bedrooms?: number | null,
    bathrooms?: number | null,
    garages?: number | null,
    lotSize?: number | null,
    sqft?: number | null,
    isVacant?: boolean | null,
    owner: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelPropertyConditionInput = {
    propertyType?: ModelPropertyTypeInput | null,
    yearBuilt?: ModelIntInput | null,
    bedrooms?: ModelIntInput | null,
    bathrooms?: ModelFloatInput | null,
    garages?: ModelFloatInput | null,
    lotSize?: ModelIntInput | null,
    sqft?: ModelIntInput | null,
    isVacant?: ModelBooleanInput | null,
    owner?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyConditionInput | null > | null,
    or?: Array< ModelPropertyConditionInput | null > | null,
    not?: ModelPropertyConditionInput | null,
};

export type ModelPropertyTypeInput = {
    eq?: PropertyType | null,
    ne?: PropertyType | null,
};

export type UpdatePropertyInput = {
    id: string,
    propertyType?: PropertyType | null,
    address?: AddressInput | null,
    yearBuilt?: number | null,
    bedrooms?: number | null,
    bathrooms?: number | null,
    garages?: number | null,
    lotSize?: number | null,
    sqft?: number | null,
    isVacant?: boolean | null,
    owner?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeletePropertyInput = {
    id: string,
};

export type CreatePropertyAmortizationTableInput = {
    id?: string | null,
    period: number,
    transactionDate: string,
    owner: string,
    payment: number,
    interest: number,
    principal: number,
    totalPrincipal: number,
    mortgageId: string,
    dashboardPropertyAmortizationTableId: string,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelPropertyAmortizationTableConditionInput = {
    period?: ModelIntInput | null,
    transactionDate?: ModelStringInput | null,
    owner?: ModelStringInput | null,
    payment?: ModelFloatInput | null,
    interest?: ModelFloatInput | null,
    principal?: ModelFloatInput | null,
    totalPrincipal?: ModelFloatInput | null,
    mortgageId?: ModelStringInput | null,
    dashboardPropertyAmortizationTableId?: ModelIDInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyAmortizationTableConditionInput | null > | null,
    or?: Array< ModelPropertyAmortizationTableConditionInput | null > | null,
    not?: ModelPropertyAmortizationTableConditionInput | null,
};

export type UpdatePropertyAmortizationTableInput = {
    id: string,
    period?: number | null,
    transactionDate?: string | null,
    owner?: string | null,
    payment?: number | null,
    interest?: number | null,
    principal?: number | null,
    totalPrincipal?: number | null,
    mortgageId?: string | null,
    dashboardPropertyAmortizationTableId?: string | null,
    readPermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeletePropertyAmortizationTableInput = {
    id: string,
};

export type CreatePropertyExtendedAddressInput = {
    id?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    lat?: number | null,
    lon?: number | null,
    geoid?: string | null,
    fips?: string | null,
    provider?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyExtendedAddressId?: string | null,
};

export type ModelPropertyExtendedAddressConditionInput = {
    street1?: ModelStringInput | null,
    street2?: ModelStringInput | null,
    city?: ModelStringInput | null,
    state?: ModelStringInput | null,
    zipCode?: ModelStringInput | null,
    lat?: ModelFloatInput | null,
    lon?: ModelFloatInput | null,
    geoid?: ModelStringInput | null,
    fips?: ModelStringInput | null,
    provider?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyExtendedAddressConditionInput | null > | null,
    or?: Array< ModelPropertyExtendedAddressConditionInput | null > | null,
    not?: ModelPropertyExtendedAddressConditionInput | null,
    propertyExtendedAddressId?: ModelIDInput | null,
};

export type UpdatePropertyExtendedAddressInput = {
    id: string,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    lat?: number | null,
    lon?: number | null,
    geoid?: string | null,
    fips?: string | null,
    provider?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyExtendedAddressId?: string | null,
};

export type DeletePropertyExtendedAddressInput = {
    id: string,
};

export type CreatePropertyUserExpenseInput = {
    id?: string | null,
    amount: number,
    startDate: string,
    category: ExpenseCategory,
    owner: string,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyUserExpenseId?: string | null,
};

export type ModelPropertyUserExpenseConditionInput = {
    amount?: ModelFloatInput | null,
    startDate?: ModelStringInput | null,
    category?: ModelExpenseCategoryInput | null,
    owner?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyUserExpenseConditionInput | null > | null,
    or?: Array< ModelPropertyUserExpenseConditionInput | null > | null,
    not?: ModelPropertyUserExpenseConditionInput | null,
    dashboardPropertyUserExpenseId?: ModelIDInput | null,
};

export type ModelExpenseCategoryInput = {
    eq?: ExpenseCategory | null,
    ne?: ExpenseCategory | null,
};

export type UpdatePropertyUserExpenseInput = {
    id: string,
    amount?: number | null,
    startDate?: string | null,
    category?: ExpenseCategory | null,
    owner?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    dashboardPropertyUserExpenseId?: string | null,
};

export type DeletePropertyUserExpenseInput = {
    id: string,
};

export type CreateProspectLeadWorkflowItemInput = {
    id?: string | null,
    lead?: ContactDetailsInput | null,
    status: ProspectLeadsWorkflowStatus,
    pm: string,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export enum ProspectLeadsWorkflowStatus {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    WON = "WON",
    LOST = "LOST",
}


export type ModelProspectLeadWorkflowItemConditionInput = {
    status?: ModelProspectLeadsWorkflowStatusInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelProspectLeadWorkflowItemConditionInput | null > | null,
    or?: Array< ModelProspectLeadWorkflowItemConditionInput | null > | null,
    not?: ModelProspectLeadWorkflowItemConditionInput | null,
};

export type ModelProspectLeadsWorkflowStatusInput = {
    eq?: ProspectLeadsWorkflowStatus | null,
    ne?: ProspectLeadsWorkflowStatus | null,
};

export type ProspectLeadWorkflowItem = {
    __typename: "ProspectLeadWorkflowItem",
    id: string,
    lead?: ContactDetails | null,
    status: ProspectLeadsWorkflowStatus,
    pm: string,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateProspectLeadWorkflowItemInput = {
    id: string,
    lead?: ContactDetailsInput | null,
    status?: ProspectLeadsWorkflowStatus | null,
    pm?: string | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteProspectLeadWorkflowItemInput = {
    id: string,
};

export type CreateReportInput = {
    id?: string | null,
    owner: string,
    name: string,
    version: number,
    totalOccupiedUnits: number,
    totalUnits: number,
    homeValue: number,
    appreciation: number,
    totalOtherIncome?: number | null,
    totalRent: number,
    totalEquity: number,
    noi: number,
    netCashFlow: number,
    expenses: ExpensesInput,
    previousMonthlyMeasures: PreviousMonthlyMeasuresInput,
    yearlyMeasures: YearlyMeasuresInput,
    reportType: ReportType,
    ownerReportReportsId?: string | null,
};

export type ExpensesInput = {
    hoa: number,
    insurance: number,
    managementFee: number,
    other: number,
    tax: number,
    mortgage: number,
    utilities?: number | null,
    legalAndProfessionalFees?: number | null,
    rentalRegistration?: number | null,
    autoAndTravel?: number | null,
    supplies?: number | null,
    maintenance?: number | null,
    leasingAndMarketing?: number | null,
    totalExpense: number,
};

export type PreviousMonthlyMeasuresInput = {
    homeValue: number,
    appreciation: number,
    totalEquity: number,
};

export type YearlyMeasuresInput = {
    capRate: number,
    cashOnCash: number,
};

export type ModelReportConditionInput = {
    owner?: ModelStringInput | null,
    name?: ModelStringInput | null,
    version?: ModelIntInput | null,
    totalOccupiedUnits?: ModelIntInput | null,
    totalUnits?: ModelIntInput | null,
    homeValue?: ModelFloatInput | null,
    appreciation?: ModelFloatInput | null,
    totalOtherIncome?: ModelFloatInput | null,
    totalRent?: ModelFloatInput | null,
    totalEquity?: ModelFloatInput | null,
    noi?: ModelFloatInput | null,
    netCashFlow?: ModelFloatInput | null,
    reportType?: ModelReportTypeInput | null,
    and?: Array< ModelReportConditionInput | null > | null,
    or?: Array< ModelReportConditionInput | null > | null,
    not?: ModelReportConditionInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
    ownerReportReportsId?: ModelIDInput | null,
};

export type ModelReportTypeInput = {
    eq?: ReportType | null,
    ne?: ReportType | null,
};

export type UpdateReportInput = {
    id: string,
    owner?: string | null,
    name?: string | null,
    version?: number | null,
    totalOccupiedUnits?: number | null,
    totalUnits?: number | null,
    homeValue?: number | null,
    appreciation?: number | null,
    totalOtherIncome?: number | null,
    totalRent?: number | null,
    totalEquity?: number | null,
    noi?: number | null,
    netCashFlow?: number | null,
    expenses?: ExpensesInput | null,
    previousMonthlyMeasures?: PreviousMonthlyMeasuresInput | null,
    yearlyMeasures?: YearlyMeasuresInput | null,
    reportType?: ReportType | null,
    ownerReportReportsId?: string | null,
};

export type DeleteReportInput = {
    id: string,
};

export type CreateRetentionPolicyInput = {
    id?: string | null,
    readPermissions: Array< string | null >,
    updatePermissions: Array< string | null >,
    pm: string,
    maxFinancialIncentive: MaxFinancialIncentive,
    giveIncentiveWillingness: Willingness,
    meetWillingness: Willingness,
    badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
    goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
    writingStyles: Array< WritingStyle >,
    riskDetectionSensitivity: RiskDetectionSensitivity,
    summaryLength: SummaryLength,
};

export enum MaxFinancialIncentive {
    ZERO_MGMT_FEE_HALF_MONTH = "ZERO_MGMT_FEE_HALF_MONTH",
    ZERO_MGMT_FEE_ONE_MONTH = "ZERO_MGMT_FEE_ONE_MONTH",
    ZERO_MGMT_FEE_TWO_MONTHS = "ZERO_MGMT_FEE_TWO_MONTHS",
    ZERO_MGMT_FEE_THREE_MONTHS = "ZERO_MGMT_FEE_THREE_MONTHS",
    NO_INCENTIVE = "NO_INCENTIVE",
}


export enum Willingness {
    WILLINGNESS_LEVEL_1 = "WILLINGNESS_LEVEL_1",
    WILLINGNESS_LEVEL_2 = "WILLINGNESS_LEVEL_2",
    WILLINGNESS_LEVEL_3 = "WILLINGNESS_LEVEL_3",
    WILLINGNESS_LEVEL_4 = "WILLINGNESS_LEVEL_4",
    WILLINGNESS_LEVEL_5 = "WILLINGNESS_LEVEL_5",
}


export enum BadOwnerPreferredMitigation {
    FIRE_OWNER = "FIRE_OWNER",
    REFER_OWNER = "REFER_OWNER",
    RECOMMEND_TO_SELL_PROPERTY = "RECOMMEND_TO_SELL_PROPERTY",
    INCREASE_MGMT_FEE = "INCREASE_MGMT_FEE",
    NO_MITIGATION = "NO_MITIGATION",
}


export enum GoodOwnerPreferredMitigation {
    RECOMMEND_TO_SELL_PROPERTY = "RECOMMEND_TO_SELL_PROPERTY",
    RECOMMEND_1031 = "RECOMMEND_1031",
    FIX_PROPERTY_ISSUES = "FIX_PROPERTY_ISSUES",
    OFFER_INCENTIVES = "OFFER_INCENTIVES",
    NO_MITIGATION = "NO_MITIGATION",
}


export enum WritingStyle {
    PROFESSIONAL = "PROFESSIONAL",
    FRIENDLY = "FRIENDLY",
    TECHNICAL = "TECHNICAL",
}


export enum RiskDetectionSensitivity {
    MINIMAL_DETECTION = "MINIMAL_DETECTION",
    STANDARD_DETECTION = "STANDARD_DETECTION",
    MAXIMUM_DETECTION = "MAXIMUM_DETECTION",
}


export enum SummaryLength {
    CONCISE = "CONCISE",
    STANDARD = "STANDARD",
    DETAILED = "DETAILED",
}


export type ModelRetentionPolicyConditionInput = {
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    maxFinancialIncentive?: ModelMaxFinancialIncentiveInput | null,
    giveIncentiveWillingness?: ModelWillingnessInput | null,
    meetWillingness?: ModelWillingnessInput | null,
    badOwnerPreferredMitigation?: ModelBadOwnerPreferredMitigationInput | null,
    goodOwnerPreferredMitigation?: ModelGoodOwnerPreferredMitigationInput | null,
    writingStyles?: ModelWritingStyleInput | null,
    riskDetectionSensitivity?: ModelRiskDetectionSensitivityInput | null,
    summaryLength?: ModelSummaryLengthInput | null,
    and?: Array< ModelRetentionPolicyConditionInput | null > | null,
    or?: Array< ModelRetentionPolicyConditionInput | null > | null,
    not?: ModelRetentionPolicyConditionInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
};

export type ModelMaxFinancialIncentiveInput = {
    eq?: MaxFinancialIncentive | null,
    ne?: MaxFinancialIncentive | null,
};

export type ModelWillingnessInput = {
    eq?: Willingness | null,
    ne?: Willingness | null,
};

export type ModelBadOwnerPreferredMitigationInput = {
    eq?: BadOwnerPreferredMitigation | null,
    ne?: BadOwnerPreferredMitigation | null,
};

export type ModelGoodOwnerPreferredMitigationInput = {
    eq?: GoodOwnerPreferredMitigation | null,
    ne?: GoodOwnerPreferredMitigation | null,
};

export type ModelWritingStyleInput = {
    eq?: WritingStyle | null,
    ne?: WritingStyle | null,
};

export type ModelRiskDetectionSensitivityInput = {
    eq?: RiskDetectionSensitivity | null,
    ne?: RiskDetectionSensitivity | null,
};

export type ModelSummaryLengthInput = {
    eq?: SummaryLength | null,
    ne?: SummaryLength | null,
};

export type RetentionPolicy = {
    __typename: "RetentionPolicy",
    id: string,
    readPermissions: Array< string | null >,
    updatePermissions: Array< string | null >,
    pm: string,
    maxFinancialIncentive: MaxFinancialIncentive,
    giveIncentiveWillingness: Willingness,
    meetWillingness: Willingness,
    badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
    goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
    writingStyles: Array< WritingStyle >,
    riskDetectionSensitivity: RiskDetectionSensitivity,
    summaryLength: SummaryLength,
    createdAt: string,
    updatedAt: string,
};

export type UpdateRetentionPolicyInput = {
    id: string,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    pm?: string | null,
    maxFinancialIncentive?: MaxFinancialIncentive | null,
    giveIncentiveWillingness?: Willingness | null,
    meetWillingness?: Willingness | null,
    badOwnerPreferredMitigation?: BadOwnerPreferredMitigation | null,
    goodOwnerPreferredMitigation?: GoodOwnerPreferredMitigation | null,
    writingStyles?: Array< WritingStyle > | null,
    riskDetectionSensitivity?: RiskDetectionSensitivity | null,
    summaryLength?: SummaryLength | null,
};

export type DeleteRetentionPolicyInput = {
    id: string,
};

export type CreateRiskInsightInput = {
    id?: string | null,
    caseName?: string | null,
    propertyID?: string | null,
    ownerEmail?: string | null,
    level: InsightLevel,
    context?: Array< InsightContextInput > | null,
    weight?: number | null,
    value?: number | null,
    insightType?: InsightType | null,
    quarters?: Array< OwnerRiskQuarterInsightInput > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskOwnerRisksInsightsId?: string | null,
};

export type ModelRiskInsightConditionInput = {
    caseName?: ModelStringInput | null,
    propertyID?: ModelStringInput | null,
    ownerEmail?: ModelStringInput | null,
    level?: ModelInsightLevelInput | null,
    weight?: ModelFloatInput | null,
    value?: ModelFloatInput | null,
    insightType?: ModelInsightTypeInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelRiskInsightConditionInput | null > | null,
    or?: Array< ModelRiskInsightConditionInput | null > | null,
    not?: ModelRiskInsightConditionInput | null,
    ownerRiskOwnerRisksInsightsId?: ModelIDInput | null,
};

export type ModelInsightLevelInput = {
    eq?: InsightLevel | null,
    ne?: InsightLevel | null,
};

export type ModelInsightTypeInput = {
    eq?: InsightType | null,
    ne?: InsightType | null,
};

export type UpdateRiskInsightInput = {
    id: string,
    caseName?: string | null,
    propertyID?: string | null,
    ownerEmail?: string | null,
    level?: InsightLevel | null,
    context?: Array< InsightContextInput > | null,
    weight?: number | null,
    value?: number | null,
    insightType?: InsightType | null,
    quarters?: Array< OwnerRiskQuarterInsightInput > | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    ownerRiskOwnerRisksInsightsId?: string | null,
};

export type DeleteRiskInsightInput = {
    id: string,
};

export type CreateSellerLeadWorkflowItemInput = {
    id?: string | null,
    sellerContact?: ContactDetailsInput | null,
    address: AddressInput,
    propertyId?: string | null,
    askingPrice: number,
    isPropertyVisible?: boolean | null,
    status: SellerLeadsWorkflowStatus,
    pm: string,
    statusChangeTime: string,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export enum SellerLeadsWorkflowStatus {
    new = "new",
    attemptedContact = "attemptedContact",
    spokeWithLead = "spokeWithLead",
    appointmentSet = "appointmentSet",
    metWithLead = "metWithLead",
    activeListing = "activeListing",
    offerAccepted = "offerAccepted",
    underContract = "underContract",
    sold = "sold",
    rejected = "rejected",
}


export type ModelSellerLeadWorkflowItemConditionInput = {
    propertyId?: ModelStringInput | null,
    askingPrice?: ModelIntInput | null,
    isPropertyVisible?: ModelBooleanInput | null,
    status?: ModelSellerLeadsWorkflowStatusInput | null,
    pm?: ModelStringInput | null,
    statusChangeTime?: ModelStringInput | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    revealed?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelSellerLeadWorkflowItemConditionInput | null > | null,
    or?: Array< ModelSellerLeadWorkflowItemConditionInput | null > | null,
    not?: ModelSellerLeadWorkflowItemConditionInput | null,
};

export type ModelSellerLeadsWorkflowStatusInput = {
    eq?: SellerLeadsWorkflowStatus | null,
    ne?: SellerLeadsWorkflowStatus | null,
};

export type SellerLeadWorkflowItem = {
    __typename: "SellerLeadWorkflowItem",
    id: string,
    sellerContact?: ContactDetails | null,
    address: Address,
    propertyId?: string | null,
    askingPrice: number,
    isPropertyVisible?: boolean | null,
    status: SellerLeadsWorkflowStatus,
    pm: string,
    statusChangeTime: string,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateSellerLeadWorkflowItemInput = {
    id: string,
    sellerContact?: ContactDetailsInput | null,
    address?: AddressInput | null,
    propertyId?: string | null,
    askingPrice?: number | null,
    isPropertyVisible?: boolean | null,
    status?: SellerLeadsWorkflowStatus | null,
    pm?: string | null,
    statusChangeTime?: string | null,
    readShareWith?: Array< string > | null,
    updateShareWith?: Array< string > | null,
    readPermissions?: Array< string | null > | null,
    updatePermissions?: Array< string | null > | null,
    revealed?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteSellerLeadWorkflowItemInput = {
    id: string,
};

export type CreateStateInput = {
    id?: string | null,
    insuranceFactor?: number | null,
    name: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelStateConditionInput = {
    insuranceFactor?: ModelFloatInput | null,
    name?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelStateConditionInput | null > | null,
    or?: Array< ModelStateConditionInput | null > | null,
    not?: ModelStateConditionInput | null,
};

export type State = {
    __typename: "State",
    id: string,
    insuranceFactor?: number | null,
    name: string,
    createdTime: string,
    updatedTime: string,
};

export type UpdateStateInput = {
    id: string,
    insuranceFactor?: number | null,
    name?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteStateInput = {
    id: string,
};

export type CreateSuggestedPartnerInput = {
    id?: string | null,
    pm?: string | null,
    readPermissions?: Array< string > | null,
    updatePermissions?: Array< string > | null,
    status: SuggestedPartnerStatus,
    suggestedPartnerType: SuggestedPartnerType,
    suggestedPartnerPM?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    phoneNumbers?: Array< PhoneNumberInput > | null,
    avatar?: string | null,
    rating?: number | null,
    officeName?: string | null,
    address?: string | null,
    website?: string | null,
    last12MonthsSales?: number | null,
    totalSales?: number | null,
    minPrice?: number | null,
    maxPrice?: number | null,
    totalSalesCount?: number | null,
    totalForSaleCount?: number | null,
    lastTwoYearsSalesCount?: number | null,
    averageSale?: number | null,
    experienceInYears?: number | null,
    description?: string | null,
    profiles?: Array< ProfileInput > | null,
    sources?: Array< Source > | null,
    propertyTypes?: Array< WorkPropertyType > | null,
    activity?: Activity | null,
    relevancy?: number | null,
    workingCounties?: Array< WorkingCountyInput > | null,
    doorCount?: number | null,
    contactEmailBody?: string | null,
    contactEmailSubject?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export enum SuggestedPartnerStatus {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    WON = "WON",
    LOST = "LOST",
}


export enum SuggestedPartnerType {
    AGENT = "AGENT",
    OWNER = "OWNER",
    PM = "PM",
    CONTRACTOR = "CONTRACTOR",
    LENDER = "LENDER",
}


export type PhoneNumberInput = {
    number?: string | null,
    phoneType?: PhoneType | null,
};

export enum PhoneType {
    UNKNOWN = "UNKNOWN",
    MOBILE = "MOBILE",
    OFFICE = "OFFICE",
}


export type ProfileInput = {
    link?: string | null,
    profileType?: ProfileType | null,
};

export enum ProfileType {
    UNKNOWN = "UNKNOWN",
    FACEBOOK = "FACEBOOK",
    LINKEDIN = "LINKEDIN",
    TWITTER = "TWITTER",
    INSTAGRAM = "INSTAGRAM",
    PINTEREST = "PINTEREST",
    REMAX = "REMAX",
    REALTORS = "REALTORS",
    HOMES = "HOMES",
}


export enum Source {
    UNKNOWN = "UNKNOWN",
    REMAX = "REMAX",
    HOMES = "HOMES",
    REALTORS = "REALTORS",
    GROWTH_POLICY = "GROWTH_POLICY",
    CRANE = "CRANE",
}


export type ModelSuggestedPartnerConditionInput = {
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    status?: ModelSuggestedPartnerStatusInput | null,
    suggestedPartnerType?: ModelSuggestedPartnerTypeInput | null,
    suggestedPartnerPM?: ModelStringInput | null,
    firstName?: ModelStringInput | null,
    lastName?: ModelStringInput | null,
    email?: ModelStringInput | null,
    phone?: ModelStringInput | null,
    avatar?: ModelStringInput | null,
    rating?: ModelIntInput | null,
    officeName?: ModelStringInput | null,
    address?: ModelStringInput | null,
    website?: ModelStringInput | null,
    last12MonthsSales?: ModelIntInput | null,
    totalSales?: ModelIntInput | null,
    minPrice?: ModelIntInput | null,
    maxPrice?: ModelIntInput | null,
    totalSalesCount?: ModelIntInput | null,
    totalForSaleCount?: ModelIntInput | null,
    lastTwoYearsSalesCount?: ModelIntInput | null,
    averageSale?: ModelIntInput | null,
    experienceInYears?: ModelIntInput | null,
    description?: ModelStringInput | null,
    sources?: ModelSourceListInput | null,
    propertyTypes?: ModelWorkPropertyTypeListInput | null,
    activity?: ModelActivityInput | null,
    relevancy?: ModelIntInput | null,
    doorCount?: ModelIntInput | null,
    contactEmailBody?: ModelStringInput | null,
    contactEmailSubject?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelSuggestedPartnerConditionInput | null > | null,
    or?: Array< ModelSuggestedPartnerConditionInput | null > | null,
    not?: ModelSuggestedPartnerConditionInput | null,
};

export type ModelSuggestedPartnerStatusInput = {
    eq?: SuggestedPartnerStatus | null,
    ne?: SuggestedPartnerStatus | null,
};

export type ModelSuggestedPartnerTypeInput = {
    eq?: SuggestedPartnerType | null,
    ne?: SuggestedPartnerType | null,
};

export type ModelSourceListInput = {
    eq?: Array< Source | null > | null,
    ne?: Array< Source | null > | null,
    contains?: Source | null,
    notContains?: Source | null,
};

export type SuggestedPartner = {
    __typename: "SuggestedPartner",
    id: string,
    pm?: string | null,
    readPermissions?: Array< string > | null,
    updatePermissions?: Array< string > | null,
    status: SuggestedPartnerStatus,
    suggestedPartnerType: SuggestedPartnerType,
    suggestedPartnerPM?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    phoneNumbers?:  Array<PhoneNumber > | null,
    avatar?: string | null,
    rating?: number | null,
    officeName?: string | null,
    address?: string | null,
    website?: string | null,
    last12MonthsSales?: number | null,
    totalSales?: number | null,
    minPrice?: number | null,
    maxPrice?: number | null,
    totalSalesCount?: number | null,
    totalForSaleCount?: number | null,
    lastTwoYearsSalesCount?: number | null,
    averageSale?: number | null,
    experienceInYears?: number | null,
    description?: string | null,
    profiles?:  Array<Profile > | null,
    sources?: Array< Source > | null,
    propertyTypes?: Array< WorkPropertyType > | null,
    activity?: Activity | null,
    relevancy?: number | null,
    workingCounties?:  Array<WorkingCounty > | null,
    doorCount?: number | null,
    contactEmailBody?: string | null,
    contactEmailSubject?: string | null,
    createdTime: string,
    updatedTime: string,
};

export type PhoneNumber = {
    __typename: "PhoneNumber",
    number?: string | null,
    phoneType?: PhoneType | null,
};

export type Profile = {
    __typename: "Profile",
    link?: string | null,
    profileType?: ProfileType | null,
};

export type UpdateSuggestedPartnerInput = {
    id: string,
    pm?: string | null,
    readPermissions?: Array< string > | null,
    updatePermissions?: Array< string > | null,
    status?: SuggestedPartnerStatus | null,
    suggestedPartnerType?: SuggestedPartnerType | null,
    suggestedPartnerPM?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    phone?: string | null,
    phoneNumbers?: Array< PhoneNumberInput > | null,
    avatar?: string | null,
    rating?: number | null,
    officeName?: string | null,
    address?: string | null,
    website?: string | null,
    last12MonthsSales?: number | null,
    totalSales?: number | null,
    minPrice?: number | null,
    maxPrice?: number | null,
    totalSalesCount?: number | null,
    totalForSaleCount?: number | null,
    lastTwoYearsSalesCount?: number | null,
    averageSale?: number | null,
    experienceInYears?: number | null,
    description?: string | null,
    profiles?: Array< ProfileInput > | null,
    sources?: Array< Source > | null,
    propertyTypes?: Array< WorkPropertyType > | null,
    activity?: Activity | null,
    relevancy?: number | null,
    workingCounties?: Array< WorkingCountyInput > | null,
    doorCount?: number | null,
    contactEmailBody?: string | null,
    contactEmailSubject?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteSuggestedPartnerInput = {
    id: string,
};

export type CreateTransactionInput = {
    id?: string | null,
    amount: number,
    transactionDate: string,
    owner: string,
    type: string,
    category: Category,
    label?: Label | null,
    description?: string | null,
    isEditable?: boolean | null,
    readPermissions?: Array< string | null > | null,
    dashboardPropertyTransactionsId: string,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelTransactionConditionInput = {
    amount?: ModelFloatInput | null,
    transactionDate?: ModelStringInput | null,
    owner?: ModelStringInput | null,
    type?: ModelStringInput | null,
    category?: ModelCategoryInput | null,
    label?: ModelLabelInput | null,
    description?: ModelStringInput | null,
    isEditable?: ModelBooleanInput | null,
    readPermissions?: ModelStringInput | null,
    dashboardPropertyTransactionsId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelTransactionConditionInput | null > | null,
    or?: Array< ModelTransactionConditionInput | null > | null,
    not?: ModelTransactionConditionInput | null,
};

export type ModelCategoryInput = {
    eq?: Category | null,
    ne?: Category | null,
};

export type UpdateTransactionInput = {
    id: string,
    amount?: number | null,
    transactionDate?: string | null,
    owner?: string | null,
    type?: string | null,
    category?: Category | null,
    label?: Label | null,
    description?: string | null,
    isEditable?: boolean | null,
    readPermissions?: Array< string | null > | null,
    dashboardPropertyTransactionsId?: string | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteTransactionInput = {
    id: string,
};

export type CreateUnitInput = {
    id?: string | null,
    bedrooms?: number | null,
    bathrooms?: number | null,
    sqft?: number | null,
    address: AddressInput,
    isVacant?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyUnitsId?: string | null,
};

export type ModelUnitConditionInput = {
    bedrooms?: ModelIntInput | null,
    bathrooms?: ModelFloatInput | null,
    sqft?: ModelIntInput | null,
    isVacant?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelUnitConditionInput | null > | null,
    or?: Array< ModelUnitConditionInput | null > | null,
    not?: ModelUnitConditionInput | null,
    propertyUnitsId?: ModelIDInput | null,
};

export type UpdateUnitInput = {
    id: string,
    bedrooms?: number | null,
    bathrooms?: number | null,
    sqft?: number | null,
    address?: AddressInput | null,
    isVacant?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
    propertyUnitsId?: string | null,
};

export type DeleteUnitInput = {
    id: string,
};

export type CreateUserActionsInput = {
    owner: string,
    pm: string,
    viewedWelcomeToMarketplaceScreen?: boolean | null,
    viewedCashOutTooltip?: boolean | null,
    dashboardProformaOverride?: string | null,
    viewedAnnualReportBanner?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type ModelUserActionsConditionInput = {
    pm?: ModelStringInput | null,
    viewedWelcomeToMarketplaceScreen?: ModelBooleanInput | null,
    viewedCashOutTooltip?: ModelBooleanInput | null,
    dashboardProformaOverride?: ModelStringInput | null,
    viewedAnnualReportBanner?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelUserActionsConditionInput | null > | null,
    or?: Array< ModelUserActionsConditionInput | null > | null,
    not?: ModelUserActionsConditionInput | null,
    owner?: ModelStringInput | null,
};

export type UserActions = {
    __typename: "UserActions",
    owner: string,
    pm: string,
    viewedWelcomeToMarketplaceScreen?: boolean | null,
    viewedCashOutTooltip?: boolean | null,
    dashboardProformaOverride?: string | null,
    viewedAnnualReportBanner?: boolean | null,
    createdTime: string,
    updatedTime: string,
};

export type UpdateUserActionsInput = {
    owner: string,
    pm?: string | null,
    viewedWelcomeToMarketplaceScreen?: boolean | null,
    viewedCashOutTooltip?: boolean | null,
    dashboardProformaOverride?: string | null,
    viewedAnnualReportBanner?: boolean | null,
    createdTime?: string | null,
    updatedTime?: string | null,
};

export type DeleteUserActionsInput = {
    owner: string,
};

export type ModelAppSettingsFilterInput = {
    id?: ModelIDInput | null,
    iamDomain?: ModelStringInput | null,
    iamClientId?: ModelStringInput | null,
    iamAudience?: ModelStringInput | null,
    title?: ModelStringInput | null,
    tenant?: ModelStringInput | null,
    pmName?: ModelStringInput | null,
    color?: ModelStringInput | null,
    logo?: ModelStringInput | null,
    favicon?: ModelStringInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
    and?: Array< ModelAppSettingsFilterInput | null > | null,
    or?: Array< ModelAppSettingsFilterInput | null > | null,
    not?: ModelAppSettingsFilterInput | null,
};

export enum ModelSortDirection {
    ASC = "ASC",
    DESC = "DESC",
}


export type ModelAppSettingsConnection = {
    __typename: "ModelAppSettingsConnection",
    items:  Array<AppSettings | null >,
    nextToken?: string | null,
};

export type ModelBuyBoxLeadWorkflowItemFilterInput = {
    id?: ModelIDInput | null,
    timeline?: ModelStringInput | null,
    amount?: ModelStringInput | null,
    propertyType?: ModelStringInput | null,
    financing?: ModelStringInput | null,
    status?: ModelBuyBoxLeadsWorkflowStatusInput | null,
    pm?: ModelStringInput | null,
    statusChangeTime?: ModelStringInput | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    revealed?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelBuyBoxLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelBuyBoxLeadWorkflowItemFilterInput | null > | null,
    not?: ModelBuyBoxLeadWorkflowItemFilterInput | null,
};

export type ModelBuyBoxLeadWorkflowItemConnection = {
    __typename: "ModelBuyBoxLeadWorkflowItemConnection",
    items:  Array<BuyBoxLeadWorkflowItem | null >,
    nextToken?: string | null,
};

export type ModelBuyerLeadWorkflowItemFilterInput = {
    id?: ModelIDInput | null,
    askingPrice?: ModelIntInput | null,
    pm?: ModelStringInput | null,
    statusChangeTime?: ModelStringInput | null,
    propertyId?: ModelStringInput | null,
    isPropertyVisible?: ModelBooleanInput | null,
    status?: ModelBuyerLeadsWorkflowStatusInput | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    revealed?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelBuyerLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelBuyerLeadWorkflowItemFilterInput | null > | null,
    not?: ModelBuyerLeadWorkflowItemFilterInput | null,
};

export type ModelBuyerLeadWorkflowItemConnection = {
    __typename: "ModelBuyerLeadWorkflowItemConnection",
    items:  Array<BuyerLeadWorkflowItem | null >,
    nextToken?: string | null,
};

export type ModelCrimeFilterInput = {
    id?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelCrimeFilterInput | null > | null,
    or?: Array< ModelCrimeFilterInput | null > | null,
    not?: ModelCrimeFilterInput | null,
    marketplacePropertyCrimeId?: ModelIDInput | null,
};

export type ModelDashboardPropertyFilterInput = {
    id?: ModelIDInput | null,
    owner?: ModelStringInput | null,
    name?: ModelStringInput | null,
    hoaMuted?: ModelBooleanInput | null,
    pmId?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    deleted?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyFilterInput | null > | null,
    or?: Array< ModelDashboardPropertyFilterInput | null > | null,
    not?: ModelDashboardPropertyFilterInput | null,
    propertyDashboardPropertyId?: ModelIDInput | null,
};

export type ModelDashboardPropertyMetricFilterInput = {
    id?: ModelIDInput | null,
    NOI?: ModelFloatInput | null,
    cashOnCash?: ModelFloatInput | null,
    capRate?: ModelFloatInput | null,
    cashFlow?: ModelFloatInput | null,
    annualIncome?: ModelFloatInput | null,
    annualExpense?: ModelFloatInput | null,
    cashInvested?: ModelFloatInput | null,
    isDataPartial?: ModelBooleanInput | null,
    currentVacancy?: ModelIntInput | null,
    turnoverTenants?: ModelFloatInput | null,
    totalUniqTenants?: ModelFloatInput | null,
    monthsToEndLease?: ModelIntInput | null,
    monthsFromStartLease?: ModelIntInput | null,
    leaseTerm?: ModelStringInput | null,
    appreciation?: ModelFloatInput | null,
    valuation?: ModelFloatInput | null,
    totalEquity?: ModelFloatInput | null,
    missingData?: ModelMissingDataListInput | null,
    monthsFromTheFirstTransaction?: ModelIntInput | null,
    firstTransactionDate?: ModelStringInput | null,
    purchasePrice?: ModelFloatInput | null,
    purchaseDate?: ModelStringInput | null,
    dashboardPropertyMetricsId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyMetricFilterInput | null > | null,
    or?: Array< ModelDashboardPropertyMetricFilterInput | null > | null,
    not?: ModelDashboardPropertyMetricFilterInput | null,
};

export type ModelStringKeyConditionInput = {
    eq?: string | null,
    le?: string | null,
    lt?: string | null,
    ge?: string | null,
    gt?: string | null,
    between?: Array< string | null > | null,
    beginsWith?: string | null,
};

export type ModelDashboardPropertyPurchaseHistoryFilterInput = {
    id?: ModelIDInput | null,
    amount?: ModelFloatInput | null,
    closingCost?: ModelFloatInput | null,
    homeImprovementCost?: ModelFloatInput | null,
    date?: ModelStringInput | null,
    provider?: ModelProviderInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyPurchaseHistoryFilterInput | null > | null,
    or?: Array< ModelDashboardPropertyPurchaseHistoryFilterInput | null > | null,
    not?: ModelDashboardPropertyPurchaseHistoryFilterInput | null,
    dashboardPropertyPurchaseHistoryId?: ModelIDInput | null,
};

export type ModelDashboardPropertyValuationFilterInput = {
    id?: ModelIDInput | null,
    date?: ModelStringInput | null,
    priceUpper?: ModelIntInput | null,
    priceLower?: ModelIntInput | null,
    priceMean?: ModelIntInput | null,
    fsd?: ModelFloatInput | null,
    provider?: ModelProviderInput | null,
    pm?: ModelStringInput | null,
    dashboardPropertyPropertyValuationId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelDashboardPropertyValuationFilterInput | null > | null,
    or?: Array< ModelDashboardPropertyValuationFilterInput | null > | null,
    not?: ModelDashboardPropertyValuationFilterInput | null,
};

export type ModelFavoriteMarketplacePropertyFilterInput = {
    id?: ModelIDInput | null,
    owner?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelFavoriteMarketplacePropertyFilterInput | null > | null,
    or?: Array< ModelFavoriteMarketplacePropertyFilterInput | null > | null,
    not?: ModelFavoriteMarketplacePropertyFilterInput | null,
    marketplacePropertyFavoritesId?: ModelIDInput | null,
};

export type ModelGrowthPolicyFilterInput = {
    id?: ModelIDInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    agentReferralFeeAmount?: ModelIntInput | null,
    agentProvidedBenefits?: ModelAgentProvidedBenefitListInput | null,
    agentProvidedBenefitsOther?: ModelStringInput | null,
    enableOwnerReferralProgram?: ModelBooleanInput | null,
    ownerReferralsIncentive?: ModelOwnerReferralsIncentiveInput | null,
    enablePMReferralProgram?: ModelBooleanInput | null,
    pmReferralsFeeAmount?: ModelIntInput | null,
    typeOfPropertiesManaged?: ModelManagedPropertyTypeListInput | null,
    doorCount?: ModelIntInput | null,
    yearsInBusiness?: ModelIntInput | null,
    website?: ModelStringInput | null,
    companyDescription?: ModelStringInput | null,
    pmAvatarURL?: ModelStringInput | null,
    pmFirstName?: ModelStringInput | null,
    pmLastName?: ModelStringInput | null,
    pmTitle?: ModelStringInput | null,
    companyGoogleReviews?: ModelFloatInput | null,
    companyAVGDayOnMarket?: ModelIntInput | null,
    companyTenantRenewalRate?: ModelIntInput | null,
    companyServices?: ModelCompanyServicesListInput | null,
    companyServicesOther?: ModelStringInput | null,
    companyGuarantees?: ModelCompanyGuaranteesListInput | null,
    companyGuaranteesOther?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelGrowthPolicyFilterInput | null > | null,
    or?: Array< ModelGrowthPolicyFilterInput | null > | null,
    not?: ModelGrowthPolicyFilterInput | null,
};

export type ModelGrowthPolicyConnection = {
    __typename: "ModelGrowthPolicyConnection",
    items:  Array<GrowthPolicy | null >,
    nextToken?: string | null,
};

export type ModelLeaseFilterInput = {
    id?: ModelIDInput | null,
    startDate?: ModelStringInput | null,
    endDate?: ModelStringInput | null,
    isActive?: ModelBooleanInput | null,
    term?: ModelTermInput | null,
    rent?: ModelIntInput | null,
    owner?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    tenant?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelLeaseFilterInput | null > | null,
    or?: Array< ModelLeaseFilterInput | null > | null,
    not?: ModelLeaseFilterInput | null,
    dashboardPropertyLeasesId?: ModelIDInput | null,
};

export type ModelLegislationDataFilterInput = {
    id?: ModelIDInput | null,
    state?: ModelStringInput | null,
    billID?: ModelIntInput | null,
    lastActionDate?: ModelStringInput | null,
    billNumber?: ModelStringInput | null,
    title?: ModelStringInput | null,
    url?: ModelStringInput | null,
    relevance?: ModelIntInput | null,
    lastAction?: ModelStringInput | null,
    status?: ModelBillStatusInput | null,
    summary?: ModelStringInput | null,
    executiveSummary?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelLegislationDataFilterInput | null > | null,
    or?: Array< ModelLegislationDataFilterInput | null > | null,
    not?: ModelLegislationDataFilterInput | null,
};

export type ModelLegislationDataConnection = {
    __typename: "ModelLegislationDataConnection",
    items:  Array<LegislationData | null >,
    nextToken?: string | null,
};

export type ModelMarketDataFilterInput = {
    id?: ModelIDInput | null,
    fips?: ModelStringInput | null,
    dataType?: ModelStringInput | null,
    date?: ModelStringInput | null,
    areaName?: ModelStringInput | null,
    homeValueSFR?: ModelFloatInput | null,
    homeValueAll?: ModelFloatInput | null,
    marketHeat?: ModelIntInput | null,
    rentSFR?: ModelFloatInput | null,
    rentAll?: ModelFloatInput | null,
    daysOnMarket?: ModelIntInput | null,
    marketSummary?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketDataFilterInput | null > | null,
    or?: Array< ModelMarketDataFilterInput | null > | null,
    not?: ModelMarketDataFilterInput | null,
};

export type ModelMarketDataConnection = {
    __typename: "ModelMarketDataConnection",
    items:  Array<MarketData | null >,
    nextToken?: string | null,
};

export type ModelMarketPlacePropertyPictureFilterInput = {
    url?: ModelIDInput | null,
    rank?: ModelIntInput | null,
    marketplacePropertyListingPicturesId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    id?: ModelIDInput | null,
    and?: Array< ModelMarketPlacePropertyPictureFilterInput | null > | null,
    or?: Array< ModelMarketPlacePropertyPictureFilterInput | null > | null,
    not?: ModelMarketPlacePropertyPictureFilterInput | null,
};

export type ModelIntKeyConditionInput = {
    eq?: number | null,
    le?: number | null,
    lt?: number | null,
    ge?: number | null,
    gt?: number | null,
    between?: Array< number | null > | null,
};

export type ModelMarketplacePropertyRentalFilterInput = {
    id?: ModelIDInput | null,
    date?: ModelStringInput | null,
    priceUpper?: ModelIntInput | null,
    priceLower?: ModelIntInput | null,
    priceMean?: ModelIntInput | null,
    fsd?: ModelFloatInput | null,
    provider?: ModelProviderInput | null,
    marketplacePropertyPropertyRentalId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyRentalFilterInput | null > | null,
    or?: Array< ModelMarketplacePropertyRentalFilterInput | null > | null,
    not?: ModelMarketplacePropertyRentalFilterInput | null,
};

export type ModelMarketplacePMPreferencesFilterInput = {
    pm?: ModelIDInput | null,
    managementFee?: ModelStringInput | null,
    leadsThirdPartyIntegrationRequested?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    id?: ModelIDInput | null,
    and?: Array< ModelMarketplacePMPreferencesFilterInput | null > | null,
    or?: Array< ModelMarketplacePMPreferencesFilterInput | null > | null,
    not?: ModelMarketplacePMPreferencesFilterInput | null,
};

export type ModelMarketplacePMPreferencesConnection = {
    __typename: "ModelMarketplacePMPreferencesConnection",
    items:  Array<MarketplacePMPreferences | null >,
    nextToken?: string | null,
};

export type ModelMarketplacePropertyFilterInput = {
    id?: ModelIDInput | null,
    askingPrice?: ModelIntInput | null,
    owner?: ModelStringInput | null,
    status?: ModelMarketPlacePropertyStatusInput | null,
    closedBy?: ModelStringInput | null,
    activatedTime?: ModelStringInput | null,
    description?: ModelStringInput | null,
    rent?: ModelIntInput | null,
    constructionStatus?: ModelConstructionStatusInput | null,
    category?: ModelMarketplacePropertyCategoryInput | null,
    source?: ModelStringInput | null,
    vendor?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    relevantPMs?: ModelStringInput | null,
    shareWith?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyFilterInput | null > | null,
    or?: Array< ModelMarketplacePropertyFilterInput | null > | null,
    not?: ModelMarketplacePropertyFilterInput | null,
    propertyMarketplacePropertyId?: ModelIDInput | null,
};

export type ModelMarketplacePropertyListingItemFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    status?: ModelMarketPlacePropertyStatusInput | null,
    actualRent?: ModelIntInput | null,
    estimatedRent?: ModelIntInput | null,
    stateInsuranceFactor?: ModelFloatInput | null,
    totalExpenses?: ModelFloatInput | null,
    maintenance?: ModelFloatInput | null,
    estimatedVacancy?: ModelFloatInput | null,
    managementFee?: ModelStringInput | null,
    capRate?: ModelFloatInput | null,
    noi?: ModelFloatInput | null,
    cashOnCash?: ModelFloatInput | null,
    grossYield?: ModelFloatInput | null,
    insurance?: ModelFloatInput | null,
    askingPrice?: ModelIntInput | null,
    bedrooms?: ModelIntInput | null,
    bathrooms?: ModelFloatInput | null,
    category?: ModelStringInput | null,
    categoryOrder?: ModelIntInput | null,
    tags?: ModelListingItemTagInput | null,
    partialAddresses?: ModelStringInput | null,
    isManagedByThePM?: ModelBooleanInput | null,
    canBeManagedByThePM?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyListingItemFilterInput | null > | null,
    or?: Array< ModelMarketplacePropertyListingItemFilterInput | null > | null,
    not?: ModelMarketplacePropertyListingItemFilterInput | null,
    marketplacePropertyListingItemsId?: ModelIDInput | null,
};

export type SearchableMarketplacePropertyListingItemFilterInput = {
    id?: SearchableIDFilterInput | null,
    pm?: SearchableStringFilterInput | null,
    readPermissions?: SearchableStringFilterInput | null,
    actualRent?: SearchableIntFilterInput | null,
    estimatedRent?: SearchableIntFilterInput | null,
    stateInsuranceFactor?: SearchableFloatFilterInput | null,
    totalExpenses?: SearchableFloatFilterInput | null,
    maintenance?: SearchableFloatFilterInput | null,
    estimatedVacancy?: SearchableFloatFilterInput | null,
    managementFee?: SearchableStringFilterInput | null,
    capRate?: SearchableFloatFilterInput | null,
    noi?: SearchableFloatFilterInput | null,
    cashOnCash?: SearchableFloatFilterInput | null,
    grossYield?: SearchableFloatFilterInput | null,
    insurance?: SearchableFloatFilterInput | null,
    askingPrice?: SearchableIntFilterInput | null,
    bedrooms?: SearchableIntFilterInput | null,
    bathrooms?: SearchableFloatFilterInput | null,
    category?: SearchableStringFilterInput | null,
    categoryOrder?: SearchableIntFilterInput | null,
    partialAddresses?: SearchableStringFilterInput | null,
    isManagedByThePM?: SearchableBooleanFilterInput | null,
    canBeManagedByThePM?: SearchableBooleanFilterInput | null,
    createdTime?: SearchableStringFilterInput | null,
    updatedTime?: SearchableStringFilterInput | null,
    marketplacePropertyListingItemsId?: SearchableIDFilterInput | null,
    status?: SearchableStringFilterInput | null,
    tags?: SearchableStringFilterInput | null,
    and?: Array< SearchableMarketplacePropertyListingItemFilterInput | null > | null,
    or?: Array< SearchableMarketplacePropertyListingItemFilterInput | null > | null,
    not?: SearchableMarketplacePropertyListingItemFilterInput | null,
};

export type SearchableIDFilterInput = {
    ne?: string | null,
    gt?: string | null,
    lt?: string | null,
    gte?: string | null,
    lte?: string | null,
    eq?: string | null,
    match?: string | null,
    matchPhrase?: string | null,
    matchPhrasePrefix?: string | null,
    multiMatch?: string | null,
    exists?: boolean | null,
    wildcard?: string | null,
    regexp?: string | null,
    range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
    ne?: string | null,
    gt?: string | null,
    lt?: string | null,
    gte?: string | null,
    lte?: string | null,
    eq?: string | null,
    match?: string | null,
    matchPhrase?: string | null,
    matchPhrasePrefix?: string | null,
    multiMatch?: string | null,
    exists?: boolean | null,
    wildcard?: string | null,
    regexp?: string | null,
    range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
    ne?: number | null,
    gt?: number | null,
    lt?: number | null,
    gte?: number | null,
    lte?: number | null,
    eq?: number | null,
    range?: Array< number | null > | null,
};

export type SearchableFloatFilterInput = {
    ne?: number | null,
    gt?: number | null,
    lt?: number | null,
    gte?: number | null,
    lte?: number | null,
    eq?: number | null,
    range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
    eq?: boolean | null,
    ne?: boolean | null,
};

export type SearchableMarketplacePropertyListingItemSortInput = {
    field?: SearchableMarketplacePropertyListingItemSortableFields | null,
    direction?: SearchableSortDirection | null,
};

export enum SearchableMarketplacePropertyListingItemSortableFields {
    id = "id",
    pm = "pm",
    readPermissions = "readPermissions",
    actualRent = "actualRent",
    estimatedRent = "estimatedRent",
    stateInsuranceFactor = "stateInsuranceFactor",
    totalExpenses = "totalExpenses",
    maintenance = "maintenance",
    estimatedVacancy = "estimatedVacancy",
    managementFee = "managementFee",
    capRate = "capRate",
    noi = "noi",
    cashOnCash = "cashOnCash",
    grossYield = "grossYield",
    insurance = "insurance",
    askingPrice = "askingPrice",
    bedrooms = "bedrooms",
    bathrooms = "bathrooms",
    category = "category",
    categoryOrder = "categoryOrder",
    partialAddresses = "partialAddresses",
    isManagedByThePM = "isManagedByThePM",
    canBeManagedByThePM = "canBeManagedByThePM",
    createdTime = "createdTime",
    updatedTime = "updatedTime",
    marketplacePropertyListingItemsId = "marketplacePropertyListingItemsId",
}


export enum SearchableSortDirection {
    asc = "asc",
    desc = "desc",
}


export type SearchableMarketplacePropertyListingItemAggregationInput = {
    name: string,
    type: SearchableAggregateType,
    field: SearchableMarketplacePropertyListingItemAggregateField,
};

export enum SearchableAggregateType {
    terms = "terms",
    avg = "avg",
    min = "min",
    max = "max",
    sum = "sum",
    cardinality = "cardinality",
}


export enum SearchableMarketplacePropertyListingItemAggregateField {
    id = "id",
    pm = "pm",
    readPermissions = "readPermissions",
    status = "status",
    actualRent = "actualRent",
    estimatedRent = "estimatedRent",
    stateInsuranceFactor = "stateInsuranceFactor",
    totalExpenses = "totalExpenses",
    maintenance = "maintenance",
    estimatedVacancy = "estimatedVacancy",
    managementFee = "managementFee",
    capRate = "capRate",
    noi = "noi",
    cashOnCash = "cashOnCash",
    grossYield = "grossYield",
    insurance = "insurance",
    askingPrice = "askingPrice",
    bedrooms = "bedrooms",
    bathrooms = "bathrooms",
    category = "category",
    categoryOrder = "categoryOrder",
    tags = "tags",
    partialAddresses = "partialAddresses",
    isManagedByThePM = "isManagedByThePM",
    canBeManagedByThePM = "canBeManagedByThePM",
    createdTime = "createdTime",
    updatedTime = "updatedTime",
    marketplacePropertyListingItemsId = "marketplacePropertyListingItemsId",
}


export type SearchableMarketplacePropertyListingItemConnection = {
    __typename: "SearchableMarketplacePropertyListingItemConnection",
    items:  Array<MarketplacePropertyListingItem | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
    __typename: "SearchableAggregateResult",
    name: string,
    result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
    __typename: "SearchableAggregateScalarResult",
    value: number,
};

export type SearchableAggregateBucketResult = {
    __typename: "SearchableAggregateBucketResult",
    buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
    __typename: "SearchableAggregateBucketResultItem",
    key: string,
    doc_count: number,
};

export type ModelMarketplacePropertyPmRecommendationFilterInput = {
    pm?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    id?: ModelIDInput | null,
    and?: Array< ModelMarketplacePropertyPmRecommendationFilterInput | null > | null,
    or?: Array< ModelMarketplacePropertyPmRecommendationFilterInput | null > | null,
    not?: ModelMarketplacePropertyPmRecommendationFilterInput | null,
};

export type ModelMarketplacePropertyPmRecommendationConnection = {
    __typename: "ModelMarketplacePropertyPmRecommendationConnection",
    items:  Array<MarketplacePropertyPmRecommendation | null >,
    nextToken?: string | null,
};

export type ModelMarketplacePropertyRecommendationFilterInput = {
    id?: ModelIDInput | null,
    owner?: ModelStringInput | null,
    score?: ModelFloatInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMarketplacePropertyRecommendationFilterInput | null > | null,
    or?: Array< ModelMarketplacePropertyRecommendationFilterInput | null > | null,
    not?: ModelMarketplacePropertyRecommendationFilterInput | null,
    marketplacePropertyRecommendationsId?: ModelIDInput | null,
};

export type ModelFloatKeyConditionInput = {
    eq?: number | null,
    le?: number | null,
    lt?: number | null,
    ge?: number | null,
    gt?: number | null,
    between?: Array< number | null > | null,
};

export type ModelMortgageFilterInput = {
    id?: ModelIDInput | null,
    rate?: ModelFloatInput | null,
    amount?: ModelFloatInput | null,
    startDate?: ModelStringInput | null,
    mortgageDueDay?: ModelIntInput | null,
    term?: ModelIntInput | null,
    owner?: ModelStringInput | null,
    grantor?: ModelStringInput | null,
    apn?: ModelStringInput | null,
    mortgageType?: ModelMortgageTypeInput | null,
    readPermissions?: ModelStringInput | null,
    provider?: ModelProviderInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelMortgageFilterInput | null > | null,
    or?: Array< ModelMortgageFilterInput | null > | null,
    not?: ModelMortgageFilterInput | null,
    dashboardPropertyMortgageId?: ModelIDInput | null,
};

export type ModelNotificationTemplateFilterInput = {
    id?: ModelIDInput | null,
    template?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelNotificationTemplateFilterInput | null > | null,
    or?: Array< ModelNotificationTemplateFilterInput | null > | null,
    not?: ModelNotificationTemplateFilterInput | null,
};

export type ModelNotificationTemplateConnection = {
    __typename: "ModelNotificationTemplateConnection",
    items:  Array<NotificationTemplate | null >,
    nextToken?: string | null,
};

export type ModelOfferFilterInput = {
    id?: ModelIDInput | null,
    buyer?: ModelStringInput | null,
    seller?: ModelStringInput | null,
    bid?: ModelIntInput | null,
    status?: ModelOfferStatusInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOfferFilterInput | null > | null,
    or?: Array< ModelOfferFilterInput | null > | null,
    not?: ModelOfferFilterInput | null,
    marketplacePropertyOffersId?: ModelIDInput | null,
};

export type ModelOwnerLeadFilterInput = {
    id?: ModelIDInput | null,
    email?: ModelStringInput | null,
    firstName?: ModelStringInput | null,
    lastName?: ModelStringInput | null,
    phoneNumber?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    requestRentalAnalysis?: ModelBooleanInput | null,
    whatAreYouLookingFor?: ModelWhatAreYouLookingForInput | null,
    numOfProperties?: ModelIntInput | null,
    addresses?: ModelStringInput | null,
    referrer?: ModelStringInput | null,
    source?: ModelOwnerLeadSourceInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerLeadFilterInput | null > | null,
    or?: Array< ModelOwnerLeadFilterInput | null > | null,
    not?: ModelOwnerLeadFilterInput | null,
    partnerOwnerLeadId?: ModelIDInput | null,
};

export type ModelOwnerLeadReferralLinkFilterInput = {
    id?: ModelIDInput | null,
    ownerId?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerLeadReferralLinkFilterInput | null > | null,
    or?: Array< ModelOwnerLeadReferralLinkFilterInput | null > | null,
    not?: ModelOwnerLeadReferralLinkFilterInput | null,
    partnerOwnerLeadReferralLinkId?: ModelIDInput | null,
};

export type ModelOwnerLeadWorkflowItemFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    requestRentalAnalysis?: ModelBooleanInput | null,
    numOfProperties?: ModelIntInput | null,
    addresses?: ModelStringInput | null,
    referrer?: ModelStringInput | null,
    source?: ModelOwnerLeadsWorkflowSourceInput | null,
    whatAreYouLookingFor?: ModelWhatAreYouLookingForInput | null,
    status?: ModelOwnerLeadsWorkflowStatusInput | null,
    revealed?: ModelBooleanInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    readShareWith?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelOwnerLeadWorkflowItemFilterInput | null > | null,
    not?: ModelOwnerLeadWorkflowItemFilterInput | null,
    ownerLeadOwnerLeadWorkflowItemId?: ModelIDInput | null,
};

export type ModelOwnerMarketplacePropertyRecommendationFilterInput = {
    owner?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    id?: ModelIDInput | null,
    and?: Array< ModelOwnerMarketplacePropertyRecommendationFilterInput | null > | null,
    or?: Array< ModelOwnerMarketplacePropertyRecommendationFilterInput | null > | null,
    not?: ModelOwnerMarketplacePropertyRecommendationFilterInput | null,
};

export type ModelOwnerMarketplacePropertyRecommendationConnection = {
    __typename: "ModelOwnerMarketplacePropertyRecommendationConnection",
    items:  Array<OwnerMarketplacePropertyRecommendation | null >,
    nextToken?: string | null,
};

export type ModelOwnerReportFilterInput = {
    id?: ModelIDInput | null,
    owner?: ModelStringInput | null,
    month?: ModelIntInput | null,
    year?: ModelIntInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
    and?: Array< ModelOwnerReportFilterInput | null > | null,
    or?: Array< ModelOwnerReportFilterInput | null > | null,
    not?: ModelOwnerReportFilterInput | null,
};

export type ModelOwnerReportConnection = {
    __typename: "ModelOwnerReportConnection",
    items:  Array<OwnerReport | null >,
    nextToken?: string | null,
};

export type ModelOwnerRetentionDetailsFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    satisfactionLevel?: ModelSatisfactionLevelInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRetentionDetailsFilterInput | null > | null,
    or?: Array< ModelOwnerRetentionDetailsFilterInput | null > | null,
    not?: ModelOwnerRetentionDetailsFilterInput | null,
    ownerRiskOwnerRetentionDetailsId?: ModelIDInput | null,
};

export type ModelOwnerRiskFilterInput = {
    id?: ModelIDInput | null,
    ownerID?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    ownerSince?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    risk?: ModelIntInput | null,
    riskLevel?: ModelRiskLevelInput | null,
    rawRisk?: ModelFloatInput | null,
    severity?: ModelOwnerRiskSeverityInput | null,
    severityScore?: ModelFloatInput | null,
    severityManualOverride?: ModelBooleanInput | null,
    clientValue?: ModelIntInput | null,
    clientValueGroup?: ModelClientValueGroupInput | null,
    status?: ModelOwnerRiskStatusInput | null,
    resolvedReason?: ModelResolvedReasonInput | null,
    resolutionExplanation?: ModelStringInput | null,
    inProgressSetDate?: ModelStringInput | null,
    resolvedDate?: ModelStringInput | null,
    statusChangedDate?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRiskFilterInput | null > | null,
    or?: Array< ModelOwnerRiskFilterInput | null > | null,
    not?: ModelOwnerRiskFilterInput | null,
};

export type ModelOwnerRiskConnection = {
    __typename: "ModelOwnerRiskConnection",
    items:  Array<OwnerRisk | null >,
    nextToken?: string | null,
};

export type ModelOwnerRiskRecommendationFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    mitigations?: ModelOwnerRiskMitigationInput | null,
    userSelectedMitigations?: ModelOwnerRiskMitigationListInput | null,
    summaryMarkdown?: ModelStringInput | null,
    positivePoints?: ModelStringInput | null,
    negativePoints?: ModelStringInput | null,
    emailHTML?: ModelStringInput | null,
    modifiedPM?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRiskRecommendationFilterInput | null > | null,
    or?: Array< ModelOwnerRiskRecommendationFilterInput | null > | null,
    not?: ModelOwnerRiskRecommendationFilterInput | null,
    ownerRiskRecommendationsId?: ModelIDInput | null,
};

export type ModelOwnerRiskTrendFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    risk?: ModelIntInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelOwnerRiskTrendFilterInput | null > | null,
    or?: Array< ModelOwnerRiskTrendFilterInput | null > | null,
    not?: ModelOwnerRiskTrendFilterInput | null,
    ownerRiskOwnerRiskTrendsId?: ModelIDInput | null,
};

export type ModelPMMetricsFilterInput = {
    id?: ModelIDInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPMMetricsFilterInput | null > | null,
    or?: Array< ModelPMMetricsFilterInput | null > | null,
    not?: ModelPMMetricsFilterInput | null,
};

export type ModelPMMetricsConnection = {
    __typename: "ModelPMMetricsConnection",
    items:  Array<PMMetrics | null >,
    nextToken?: string | null,
};

export type ModelPMSettingsFilterInput = {
    pm?: ModelIDInput | null,
    leadershipSummary2024?: ModelStringInput | null,
    dashboardProformaMetricsOverride?: ModelStringInput | null,
    showLegalSummary?: ModelBooleanInput | null,
    obpTitleOverride?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    id?: ModelIDInput | null,
    and?: Array< ModelPMSettingsFilterInput | null > | null,
    or?: Array< ModelPMSettingsFilterInput | null > | null,
    not?: ModelPMSettingsFilterInput | null,
};

export type ModelPMSettingsConnection = {
    __typename: "ModelPMSettingsConnection",
    items:  Array<PMSettings | null >,
    nextToken?: string | null,
};

export type ModelPMTransactionFilterInput = {
    id?: ModelIDInput | null,
    amount?: ModelFloatInput | null,
    transactionDate?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    owner?: ModelStringInput | null,
    type?: ModelStringInput | null,
    category?: ModelPMTransactionCategoryInput | null,
    label?: ModelLabelInput | null,
    description?: ModelStringInput | null,
    dashboardPropertyPmTransactionsId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPMTransactionFilterInput | null > | null,
    or?: Array< ModelPMTransactionFilterInput | null > | null,
    not?: ModelPMTransactionFilterInput | null,
};

export type ModelPartnerFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    partnerType?: ModelPartnerTypeInput | null,
    partnerPM?: ModelStringInput | null,
    firstName?: ModelStringInput | null,
    lastName?: ModelStringInput | null,
    email?: ModelStringInput | null,
    referralAmount?: ModelIntInput | null,
    referralFreeMonths?: ModelFloatInput | null,
    phoneNumber?: ModelStringInput | null,
    avatar?: ModelStringInput | null,
    address?: ModelStringInput | null,
    website?: ModelStringInput | null,
    experienceInYears?: ModelIntInput | null,
    doorCount?: ModelIntInput | null,
    minPrice?: ModelIntInput | null,
    maxPrice?: ModelIntInput | null,
    propertyTypes?: ModelWorkPropertyTypeListInput | null,
    activity?: ModelActivityInput | null,
    suggestedPartnerPartnerId?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPartnerFilterInput | null > | null,
    or?: Array< ModelPartnerFilterInput | null > | null,
    not?: ModelPartnerFilterInput | null,
};

export type ModelPartnerConnection = {
    __typename: "ModelPartnerConnection",
    items:  Array<Partner | null >,
    nextToken?: string | null,
};

export type ModelPmMarketplacePropertyRecommendationFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    score?: ModelFloatInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPmMarketplacePropertyRecommendationFilterInput | null > | null,
    or?: Array< ModelPmMarketplacePropertyRecommendationFilterInput | null > | null,
    not?: ModelPmMarketplacePropertyRecommendationFilterInput | null,
    marketplacePropertyPmRecommendationsId?: ModelIDInput | null,
};

export type ModelPropertyFilterInput = {
    id?: ModelIDInput | null,
    propertyType?: ModelPropertyTypeInput | null,
    yearBuilt?: ModelIntInput | null,
    bedrooms?: ModelIntInput | null,
    bathrooms?: ModelFloatInput | null,
    garages?: ModelFloatInput | null,
    lotSize?: ModelIntInput | null,
    sqft?: ModelIntInput | null,
    isVacant?: ModelBooleanInput | null,
    owner?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyFilterInput | null > | null,
    or?: Array< ModelPropertyFilterInput | null > | null,
    not?: ModelPropertyFilterInput | null,
};

export type ModelPropertyConnection = {
    __typename: "ModelPropertyConnection",
    items:  Array<Property | null >,
    nextToken?: string | null,
};

export type ModelPropertyAmortizationTableFilterInput = {
    id?: ModelIDInput | null,
    period?: ModelIntInput | null,
    transactionDate?: ModelStringInput | null,
    owner?: ModelStringInput | null,
    payment?: ModelFloatInput | null,
    interest?: ModelFloatInput | null,
    principal?: ModelFloatInput | null,
    totalPrincipal?: ModelFloatInput | null,
    mortgageId?: ModelStringInput | null,
    dashboardPropertyAmortizationTableId?: ModelIDInput | null,
    readPermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyAmortizationTableFilterInput | null > | null,
    or?: Array< ModelPropertyAmortizationTableFilterInput | null > | null,
    not?: ModelPropertyAmortizationTableFilterInput | null,
};

export type ModelPropertyExtendedAddressFilterInput = {
    id?: ModelIDInput | null,
    street1?: ModelStringInput | null,
    street2?: ModelStringInput | null,
    city?: ModelStringInput | null,
    state?: ModelStringInput | null,
    zipCode?: ModelStringInput | null,
    lat?: ModelFloatInput | null,
    lon?: ModelFloatInput | null,
    geoid?: ModelStringInput | null,
    fips?: ModelStringInput | null,
    provider?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyExtendedAddressFilterInput | null > | null,
    or?: Array< ModelPropertyExtendedAddressFilterInput | null > | null,
    not?: ModelPropertyExtendedAddressFilterInput | null,
    propertyExtendedAddressId?: ModelIDInput | null,
};

export type ModelPropertyUserExpenseFilterInput = {
    id?: ModelIDInput | null,
    amount?: ModelFloatInput | null,
    startDate?: ModelStringInput | null,
    category?: ModelExpenseCategoryInput | null,
    owner?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelPropertyUserExpenseFilterInput | null > | null,
    or?: Array< ModelPropertyUserExpenseFilterInput | null > | null,
    not?: ModelPropertyUserExpenseFilterInput | null,
    dashboardPropertyUserExpenseId?: ModelIDInput | null,
};

export type ModelProspectLeadWorkflowItemFilterInput = {
    id?: ModelIDInput | null,
    status?: ModelProspectLeadsWorkflowStatusInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelProspectLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelProspectLeadWorkflowItemFilterInput | null > | null,
    not?: ModelProspectLeadWorkflowItemFilterInput | null,
};

export type ModelProspectLeadWorkflowItemConnection = {
    __typename: "ModelProspectLeadWorkflowItemConnection",
    items:  Array<ProspectLeadWorkflowItem | null >,
    nextToken?: string | null,
};

export type ModelReportFilterInput = {
    id?: ModelIDInput | null,
    owner?: ModelStringInput | null,
    name?: ModelStringInput | null,
    version?: ModelIntInput | null,
    totalOccupiedUnits?: ModelIntInput | null,
    totalUnits?: ModelIntInput | null,
    homeValue?: ModelFloatInput | null,
    appreciation?: ModelFloatInput | null,
    totalOtherIncome?: ModelFloatInput | null,
    totalRent?: ModelFloatInput | null,
    totalEquity?: ModelFloatInput | null,
    noi?: ModelFloatInput | null,
    netCashFlow?: ModelFloatInput | null,
    reportType?: ModelReportTypeInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
    and?: Array< ModelReportFilterInput | null > | null,
    or?: Array< ModelReportFilterInput | null > | null,
    not?: ModelReportFilterInput | null,
    ownerReportReportsId?: ModelIDInput | null,
};

export type ModelRetentionPolicyFilterInput = {
    id?: ModelIDInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    pm?: ModelStringInput | null,
    maxFinancialIncentive?: ModelMaxFinancialIncentiveInput | null,
    giveIncentiveWillingness?: ModelWillingnessInput | null,
    meetWillingness?: ModelWillingnessInput | null,
    badOwnerPreferredMitigation?: ModelBadOwnerPreferredMitigationInput | null,
    goodOwnerPreferredMitigation?: ModelGoodOwnerPreferredMitigationInput | null,
    writingStyles?: ModelWritingStyleInput | null,
    riskDetectionSensitivity?: ModelRiskDetectionSensitivityInput | null,
    summaryLength?: ModelSummaryLengthInput | null,
    createdAt?: ModelStringInput | null,
    updatedAt?: ModelStringInput | null,
    and?: Array< ModelRetentionPolicyFilterInput | null > | null,
    or?: Array< ModelRetentionPolicyFilterInput | null > | null,
    not?: ModelRetentionPolicyFilterInput | null,
};

export type ModelRetentionPolicyConnection = {
    __typename: "ModelRetentionPolicyConnection",
    items:  Array<RetentionPolicy | null >,
    nextToken?: string | null,
};

export type ModelRiskInsightFilterInput = {
    id?: ModelIDInput | null,
    caseName?: ModelStringInput | null,
    propertyID?: ModelStringInput | null,
    ownerEmail?: ModelStringInput | null,
    level?: ModelInsightLevelInput | null,
    weight?: ModelFloatInput | null,
    value?: ModelFloatInput | null,
    insightType?: ModelInsightTypeInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelRiskInsightFilterInput | null > | null,
    or?: Array< ModelRiskInsightFilterInput | null > | null,
    not?: ModelRiskInsightFilterInput | null,
    ownerRiskOwnerRisksInsightsId?: ModelIDInput | null,
};

export type ModelSellerLeadWorkflowItemFilterInput = {
    id?: ModelIDInput | null,
    propertyId?: ModelStringInput | null,
    askingPrice?: ModelIntInput | null,
    isPropertyVisible?: ModelBooleanInput | null,
    status?: ModelSellerLeadsWorkflowStatusInput | null,
    pm?: ModelStringInput | null,
    statusChangeTime?: ModelStringInput | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    revealed?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelSellerLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelSellerLeadWorkflowItemFilterInput | null > | null,
    not?: ModelSellerLeadWorkflowItemFilterInput | null,
};

export type ModelSellerLeadWorkflowItemConnection = {
    __typename: "ModelSellerLeadWorkflowItemConnection",
    items:  Array<SellerLeadWorkflowItem | null >,
    nextToken?: string | null,
};

export type ModelStateFilterInput = {
    id?: ModelIDInput | null,
    insuranceFactor?: ModelFloatInput | null,
    name?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelStateFilterInput | null > | null,
    or?: Array< ModelStateFilterInput | null > | null,
    not?: ModelStateFilterInput | null,
};

export type ModelStateConnection = {
    __typename: "ModelStateConnection",
    items:  Array<State | null >,
    nextToken?: string | null,
};

export type ModelSuggestedPartnerFilterInput = {
    id?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    readPermissions?: ModelStringInput | null,
    updatePermissions?: ModelStringInput | null,
    status?: ModelSuggestedPartnerStatusInput | null,
    suggestedPartnerType?: ModelSuggestedPartnerTypeInput | null,
    suggestedPartnerPM?: ModelStringInput | null,
    firstName?: ModelStringInput | null,
    lastName?: ModelStringInput | null,
    email?: ModelStringInput | null,
    phone?: ModelStringInput | null,
    avatar?: ModelStringInput | null,
    rating?: ModelIntInput | null,
    officeName?: ModelStringInput | null,
    address?: ModelStringInput | null,
    website?: ModelStringInput | null,
    last12MonthsSales?: ModelIntInput | null,
    totalSales?: ModelIntInput | null,
    minPrice?: ModelIntInput | null,
    maxPrice?: ModelIntInput | null,
    totalSalesCount?: ModelIntInput | null,
    totalForSaleCount?: ModelIntInput | null,
    lastTwoYearsSalesCount?: ModelIntInput | null,
    averageSale?: ModelIntInput | null,
    experienceInYears?: ModelIntInput | null,
    description?: ModelStringInput | null,
    sources?: ModelSourceListInput | null,
    propertyTypes?: ModelWorkPropertyTypeListInput | null,
    activity?: ModelActivityInput | null,
    relevancy?: ModelIntInput | null,
    doorCount?: ModelIntInput | null,
    contactEmailBody?: ModelStringInput | null,
    contactEmailSubject?: ModelStringInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelSuggestedPartnerFilterInput | null > | null,
    or?: Array< ModelSuggestedPartnerFilterInput | null > | null,
    not?: ModelSuggestedPartnerFilterInput | null,
};

export type ModelSuggestedPartnerConnection = {
    __typename: "ModelSuggestedPartnerConnection",
    items:  Array<SuggestedPartner | null >,
    nextToken?: string | null,
};

export type ModelTransactionFilterInput = {
    id?: ModelIDInput | null,
    amount?: ModelFloatInput | null,
    transactionDate?: ModelStringInput | null,
    owner?: ModelStringInput | null,
    type?: ModelStringInput | null,
    category?: ModelCategoryInput | null,
    label?: ModelLabelInput | null,
    description?: ModelStringInput | null,
    isEditable?: ModelBooleanInput | null,
    readPermissions?: ModelStringInput | null,
    dashboardPropertyTransactionsId?: ModelIDInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelTransactionFilterInput | null > | null,
    or?: Array< ModelTransactionFilterInput | null > | null,
    not?: ModelTransactionFilterInput | null,
};

export type SearchableTransactionFilterInput = {
    id?: SearchableIDFilterInput | null,
    amount?: SearchableFloatFilterInput | null,
    transactionDate?: SearchableStringFilterInput | null,
    owner?: SearchableStringFilterInput | null,
    type?: SearchableStringFilterInput | null,
    description?: SearchableStringFilterInput | null,
    isEditable?: SearchableBooleanFilterInput | null,
    readPermissions?: SearchableStringFilterInput | null,
    dashboardPropertyTransactionsId?: SearchableIDFilterInput | null,
    createdTime?: SearchableStringFilterInput | null,
    updatedTime?: SearchableStringFilterInput | null,
    category?: SearchableStringFilterInput | null,
    label?: SearchableStringFilterInput | null,
    and?: Array< SearchableTransactionFilterInput | null > | null,
    or?: Array< SearchableTransactionFilterInput | null > | null,
    not?: SearchableTransactionFilterInput | null,
};

export type SearchableTransactionSortInput = {
    field?: SearchableTransactionSortableFields | null,
    direction?: SearchableSortDirection | null,
};

export enum SearchableTransactionSortableFields {
    id = "id",
    amount = "amount",
    transactionDate = "transactionDate",
    owner = "owner",
    type = "type",
    description = "description",
    isEditable = "isEditable",
    readPermissions = "readPermissions",
    dashboardPropertyTransactionsId = "dashboardPropertyTransactionsId",
    createdTime = "createdTime",
    updatedTime = "updatedTime",
}


export type SearchableTransactionAggregationInput = {
    name: string,
    type: SearchableAggregateType,
    field: SearchableTransactionAggregateField,
};

export enum SearchableTransactionAggregateField {
    id = "id",
    amount = "amount",
    transactionDate = "transactionDate",
    owner = "owner",
    type = "type",
    category = "category",
    label = "label",
    description = "description",
    isEditable = "isEditable",
    readPermissions = "readPermissions",
    dashboardPropertyTransactionsId = "dashboardPropertyTransactionsId",
    createdTime = "createdTime",
    updatedTime = "updatedTime",
}


export type SearchableTransactionConnection = {
    __typename: "SearchableTransactionConnection",
    items:  Array<Transaction | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelUnitFilterInput = {
    id?: ModelIDInput | null,
    bedrooms?: ModelIntInput | null,
    bathrooms?: ModelFloatInput | null,
    sqft?: ModelIntInput | null,
    isVacant?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    and?: Array< ModelUnitFilterInput | null > | null,
    or?: Array< ModelUnitFilterInput | null > | null,
    not?: ModelUnitFilterInput | null,
    propertyUnitsId?: ModelIDInput | null,
};

export type ModelUserActionsFilterInput = {
    owner?: ModelIDInput | null,
    pm?: ModelStringInput | null,
    viewedWelcomeToMarketplaceScreen?: ModelBooleanInput | null,
    viewedCashOutTooltip?: ModelBooleanInput | null,
    dashboardProformaOverride?: ModelStringInput | null,
    viewedAnnualReportBanner?: ModelBooleanInput | null,
    createdTime?: ModelStringInput | null,
    updatedTime?: ModelStringInput | null,
    id?: ModelIDInput | null,
    and?: Array< ModelUserActionsFilterInput | null > | null,
    or?: Array< ModelUserActionsFilterInput | null > | null,
    not?: ModelUserActionsFilterInput | null,
};

export type ModelUserActionsConnection = {
    __typename: "ModelUserActionsConnection",
    items:  Array<UserActions | null >,
    nextToken?: string | null,
};

export type ModelSubscriptionAppSettingsFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    iamDomain?: ModelSubscriptionStringInput | null,
    iamClientId?: ModelSubscriptionStringInput | null,
    iamAudience?: ModelSubscriptionStringInput | null,
    title?: ModelSubscriptionStringInput | null,
    tenant?: ModelSubscriptionStringInput | null,
    pmName?: ModelSubscriptionStringInput | null,
    color?: ModelSubscriptionStringInput | null,
    logo?: ModelSubscriptionStringInput | null,
    favicon?: ModelSubscriptionStringInput | null,
    createdAt?: ModelSubscriptionStringInput | null,
    updatedAt?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionAppSettingsFilterInput | null > | null,
    or?: Array< ModelSubscriptionAppSettingsFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
    ne?: string | null,
    eq?: string | null,
    le?: string | null,
    lt?: string | null,
    ge?: string | null,
    gt?: string | null,
    contains?: string | null,
    notContains?: string | null,
    between?: Array< string | null > | null,
    beginsWith?: string | null,
    in?: Array< string | null > | null,
    notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
    ne?: string | null,
    eq?: string | null,
    le?: string | null,
    lt?: string | null,
    ge?: string | null,
    gt?: string | null,
    contains?: string | null,
    notContains?: string | null,
    between?: Array< string | null > | null,
    beginsWith?: string | null,
    in?: Array< string | null > | null,
    notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBuyBoxLeadWorkflowItemFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    timeline?: ModelSubscriptionStringInput | null,
    amount?: ModelSubscriptionStringInput | null,
    propertyType?: ModelSubscriptionStringInput | null,
    financing?: ModelSubscriptionStringInput | null,
    status?: ModelSubscriptionStringInput | null,
    pm?: ModelSubscriptionStringInput | null,
    statusChangeTime?: ModelSubscriptionStringInput | null,
    revealed?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionBuyBoxLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelSubscriptionBuyBoxLeadWorkflowItemFilterInput | null > | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
};

export type ModelSubscriptionBooleanInput = {
    ne?: boolean | null,
    eq?: boolean | null,
};

export type ModelSubscriptionBuyerLeadWorkflowItemFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    askingPrice?: ModelSubscriptionIntInput | null,
    pm?: ModelSubscriptionStringInput | null,
    statusChangeTime?: ModelSubscriptionStringInput | null,
    propertyId?: ModelSubscriptionStringInput | null,
    isPropertyVisible?: ModelSubscriptionBooleanInput | null,
    status?: ModelSubscriptionStringInput | null,
    revealed?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionBuyerLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelSubscriptionBuyerLeadWorkflowItemFilterInput | null > | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
};

export type ModelSubscriptionIntInput = {
    ne?: number | null,
    eq?: number | null,
    le?: number | null,
    lt?: number | null,
    ge?: number | null,
    gt?: number | null,
    between?: Array< number | null > | null,
    in?: Array< number | null > | null,
    notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDashboardPropertyFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    name?: ModelSubscriptionStringInput | null,
    hoaMuted?: ModelSubscriptionBooleanInput | null,
    pmId?: ModelSubscriptionStringInput | null,
    deleted?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionDashboardPropertyFilterInput | null > | null,
    or?: Array< ModelSubscriptionDashboardPropertyFilterInput | null > | null,
    dashboardPropertyTransactionsId?: ModelSubscriptionIDInput | null,
    dashboardPropertyPmTransactionsId?: ModelSubscriptionIDInput | null,
    dashboardPropertyLeasesId?: ModelSubscriptionIDInput | null,
    dashboardPropertyUserExpenseId?: ModelSubscriptionIDInput | null,
    dashboardPropertyAmortizationTableId?: ModelSubscriptionIDInput | null,
    dashboardPropertyMortgageId?: ModelSubscriptionIDInput | null,
    dashboardPropertyPropertyValuationId?: ModelSubscriptionIDInput | null,
    dashboardPropertyPurchaseHistoryId?: ModelSubscriptionIDInput | null,
    dashboardPropertyMetricsId?: ModelSubscriptionIDInput | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionFavoriteMarketplacePropertyFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionFavoriteMarketplacePropertyFilterInput | null > | null,
    or?: Array< ModelSubscriptionFavoriteMarketplacePropertyFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionGrowthPolicyFilterInput = {
    agentReferralFeeAmount?: ModelSubscriptionIntInput | null,
    agentProvidedBenefits?: ModelSubscriptionStringInput | null,
    agentProvidedBenefitsOther?: ModelSubscriptionStringInput | null,
    enableOwnerReferralProgram?: ModelSubscriptionBooleanInput | null,
    ownerReferralsIncentive?: ModelSubscriptionStringInput | null,
    enablePMReferralProgram?: ModelSubscriptionBooleanInput | null,
    pmReferralsFeeAmount?: ModelSubscriptionIntInput | null,
    typeOfPropertiesManaged?: ModelSubscriptionStringInput | null,
    doorCount?: ModelSubscriptionIntInput | null,
    yearsInBusiness?: ModelSubscriptionIntInput | null,
    website?: ModelSubscriptionStringInput | null,
    companyDescription?: ModelSubscriptionStringInput | null,
    pmAvatarURL?: ModelSubscriptionStringInput | null,
    pmFirstName?: ModelSubscriptionStringInput | null,
    pmLastName?: ModelSubscriptionStringInput | null,
    pmTitle?: ModelSubscriptionStringInput | null,
    companyGoogleReviews?: ModelSubscriptionFloatInput | null,
    companyAVGDayOnMarket?: ModelSubscriptionIntInput | null,
    companyTenantRenewalRate?: ModelSubscriptionIntInput | null,
    companyServices?: ModelSubscriptionStringInput | null,
    companyServicesOther?: ModelSubscriptionStringInput | null,
    companyGuarantees?: ModelSubscriptionStringInput | null,
    companyGuaranteesOther?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionGrowthPolicyFilterInput | null > | null,
    or?: Array< ModelSubscriptionGrowthPolicyFilterInput | null > | null,
    id?: ModelStringInput | null,
};

export type ModelSubscriptionFloatInput = {
    ne?: number | null,
    eq?: number | null,
    le?: number | null,
    lt?: number | null,
    ge?: number | null,
    gt?: number | null,
    between?: Array< number | null > | null,
    in?: Array< number | null > | null,
    notIn?: Array< number | null > | null,
};

export type ModelSubscriptionLeaseFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    startDate?: ModelSubscriptionStringInput | null,
    endDate?: ModelSubscriptionStringInput | null,
    isActive?: ModelSubscriptionBooleanInput | null,
    term?: ModelSubscriptionStringInput | null,
    rent?: ModelSubscriptionIntInput | null,
    tenant?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionLeaseFilterInput | null > | null,
    or?: Array< ModelSubscriptionLeaseFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionLegislationDataFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    state?: ModelSubscriptionStringInput | null,
    billID?: ModelSubscriptionIntInput | null,
    lastActionDate?: ModelSubscriptionStringInput | null,
    billNumber?: ModelSubscriptionStringInput | null,
    title?: ModelSubscriptionStringInput | null,
    url?: ModelSubscriptionStringInput | null,
    relevance?: ModelSubscriptionIntInput | null,
    lastAction?: ModelSubscriptionStringInput | null,
    status?: ModelSubscriptionStringInput | null,
    summary?: ModelSubscriptionStringInput | null,
    executiveSummary?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionLegislationDataFilterInput | null > | null,
    or?: Array< ModelSubscriptionLegislationDataFilterInput | null > | null,
};

export type ModelSubscriptionMarketDataFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    fips?: ModelSubscriptionStringInput | null,
    dataType?: ModelSubscriptionStringInput | null,
    date?: ModelSubscriptionStringInput | null,
    areaName?: ModelSubscriptionStringInput | null,
    homeValueSFR?: ModelSubscriptionFloatInput | null,
    homeValueAll?: ModelSubscriptionFloatInput | null,
    marketHeat?: ModelSubscriptionIntInput | null,
    rentSFR?: ModelSubscriptionFloatInput | null,
    rentAll?: ModelSubscriptionFloatInput | null,
    daysOnMarket?: ModelSubscriptionIntInput | null,
    marketSummary?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionMarketDataFilterInput | null > | null,
    or?: Array< ModelSubscriptionMarketDataFilterInput | null > | null,
};

export type ModelSubscriptionMarketPlacePropertyPictureFilterInput = {
    url?: ModelSubscriptionIDInput | null,
    rank?: ModelSubscriptionIntInput | null,
    marketplacePropertyListingPicturesId?: ModelSubscriptionIDInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    id?: ModelSubscriptionIDInput | null,
    and?: Array< ModelSubscriptionMarketPlacePropertyPictureFilterInput | null > | null,
    or?: Array< ModelSubscriptionMarketPlacePropertyPictureFilterInput | null > | null,
};

export type ModelSubscriptionMarketplacePMPreferencesFilterInput = {
    managementFee?: ModelSubscriptionStringInput | null,
    leadsThirdPartyIntegrationRequested?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    id?: ModelSubscriptionIDInput | null,
    and?: Array< ModelSubscriptionMarketplacePMPreferencesFilterInput | null > | null,
    or?: Array< ModelSubscriptionMarketplacePMPreferencesFilterInput | null > | null,
    pm?: ModelStringInput | null,
};

export type ModelSubscriptionMarketplacePropertyFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    askingPrice?: ModelSubscriptionIntInput | null,
    status?: ModelSubscriptionStringInput | null,
    closedBy?: ModelSubscriptionStringInput | null,
    activatedTime?: ModelSubscriptionStringInput | null,
    description?: ModelSubscriptionStringInput | null,
    rent?: ModelSubscriptionIntInput | null,
    constructionStatus?: ModelSubscriptionStringInput | null,
    category?: ModelSubscriptionStringInput | null,
    source?: ModelSubscriptionStringInput | null,
    vendor?: ModelSubscriptionStringInput | null,
    relevantPMs?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionMarketplacePropertyFilterInput | null > | null,
    or?: Array< ModelSubscriptionMarketplacePropertyFilterInput | null > | null,
    marketplacePropertyListingPicturesId?: ModelSubscriptionIDInput | null,
    marketplacePropertyFavoritesId?: ModelSubscriptionIDInput | null,
    marketplacePropertyRecommendationsId?: ModelSubscriptionIDInput | null,
    marketplacePropertyPmRecommendationsId?: ModelSubscriptionIDInput | null,
    marketplacePropertyOffersId?: ModelSubscriptionIDInput | null,
    marketplacePropertyCrimeId?: ModelSubscriptionIDInput | null,
    marketplacePropertyPropertyRentalId?: ModelSubscriptionIDInput | null,
    marketplacePropertyListingItemsId?: ModelSubscriptionIDInput | null,
    owner?: ModelStringInput | null,
    shareWith?: ModelStringInput | null,
};

export type ModelSubscriptionMarketplacePropertyListingItemFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    status?: ModelSubscriptionStringInput | null,
    actualRent?: ModelSubscriptionIntInput | null,
    estimatedRent?: ModelSubscriptionIntInput | null,
    stateInsuranceFactor?: ModelSubscriptionFloatInput | null,
    totalExpenses?: ModelSubscriptionFloatInput | null,
    maintenance?: ModelSubscriptionFloatInput | null,
    estimatedVacancy?: ModelSubscriptionFloatInput | null,
    managementFee?: ModelSubscriptionStringInput | null,
    capRate?: ModelSubscriptionFloatInput | null,
    noi?: ModelSubscriptionFloatInput | null,
    cashOnCash?: ModelSubscriptionFloatInput | null,
    grossYield?: ModelSubscriptionFloatInput | null,
    insurance?: ModelSubscriptionFloatInput | null,
    askingPrice?: ModelSubscriptionIntInput | null,
    bedrooms?: ModelSubscriptionIntInput | null,
    bathrooms?: ModelSubscriptionFloatInput | null,
    category?: ModelSubscriptionStringInput | null,
    categoryOrder?: ModelSubscriptionIntInput | null,
    tags?: ModelSubscriptionStringInput | null,
    partialAddresses?: ModelSubscriptionStringInput | null,
    isManagedByThePM?: ModelSubscriptionBooleanInput | null,
    canBeManagedByThePM?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionMarketplacePropertyListingItemFilterInput | null > | null,
    or?: Array< ModelSubscriptionMarketplacePropertyListingItemFilterInput | null > | null,
};

export type ModelSubscriptionMarketplacePropertyPmRecommendationFilterInput = {
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    id?: ModelSubscriptionIDInput | null,
    and?: Array< ModelSubscriptionMarketplacePropertyPmRecommendationFilterInput | null > | null,
    or?: Array< ModelSubscriptionMarketplacePropertyPmRecommendationFilterInput | null > | null,
    pm?: ModelStringInput | null,
};

export type ModelSubscriptionMarketplacePropertyRecommendationFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    score?: ModelSubscriptionFloatInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionMarketplacePropertyRecommendationFilterInput | null > | null,
    or?: Array< ModelSubscriptionMarketplacePropertyRecommendationFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionMortgageFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    rate?: ModelSubscriptionFloatInput | null,
    amount?: ModelSubscriptionFloatInput | null,
    startDate?: ModelSubscriptionStringInput | null,
    mortgageDueDay?: ModelSubscriptionIntInput | null,
    term?: ModelSubscriptionIntInput | null,
    grantor?: ModelSubscriptionStringInput | null,
    apn?: ModelSubscriptionStringInput | null,
    mortgageType?: ModelSubscriptionStringInput | null,
    provider?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionMortgageFilterInput | null > | null,
    or?: Array< ModelSubscriptionMortgageFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionNotificationTemplateFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    template?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionNotificationTemplateFilterInput | null > | null,
    or?: Array< ModelSubscriptionNotificationTemplateFilterInput | null > | null,
};

export type ModelSubscriptionOfferFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    bid?: ModelSubscriptionIntInput | null,
    status?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOfferFilterInput | null > | null,
    or?: Array< ModelSubscriptionOfferFilterInput | null > | null,
    buyer?: ModelStringInput | null,
    seller?: ModelStringInput | null,
};

export type ModelSubscriptionOwnerLeadFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    email?: ModelSubscriptionStringInput | null,
    firstName?: ModelSubscriptionStringInput | null,
    lastName?: ModelSubscriptionStringInput | null,
    phoneNumber?: ModelSubscriptionStringInput | null,
    pm?: ModelSubscriptionStringInput | null,
    requestRentalAnalysis?: ModelSubscriptionBooleanInput | null,
    whatAreYouLookingFor?: ModelSubscriptionStringInput | null,
    numOfProperties?: ModelSubscriptionIntInput | null,
    addresses?: ModelSubscriptionStringInput | null,
    referrer?: ModelSubscriptionStringInput | null,
    source?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOwnerLeadFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerLeadFilterInput | null > | null,
    ownerLeadOwnerLeadWorkflowItemId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionOwnerLeadReferralLinkFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOwnerLeadReferralLinkFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerLeadReferralLinkFilterInput | null > | null,
    ownerId?: ModelStringInput | null,
};

export type ModelSubscriptionOwnerLeadWorkflowItemFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    requestRentalAnalysis?: ModelSubscriptionBooleanInput | null,
    numOfProperties?: ModelSubscriptionIntInput | null,
    addresses?: ModelSubscriptionStringInput | null,
    referrer?: ModelSubscriptionStringInput | null,
    source?: ModelSubscriptionStringInput | null,
    whatAreYouLookingFor?: ModelSubscriptionStringInput | null,
    status?: ModelSubscriptionStringInput | null,
    revealed?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOwnerLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerLeadWorkflowItemFilterInput | null > | null,
    readShareWith?: ModelStringInput | null,
};

export type ModelSubscriptionOwnerMarketplacePropertyRecommendationFilterInput = {
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    id?: ModelSubscriptionIDInput | null,
    and?: Array< ModelSubscriptionOwnerMarketplacePropertyRecommendationFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerMarketplacePropertyRecommendationFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionOwnerReportFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    month?: ModelSubscriptionIntInput | null,
    year?: ModelSubscriptionIntInput | null,
    createdAt?: ModelSubscriptionStringInput | null,
    updatedAt?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOwnerReportFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerReportFilterInput | null > | null,
    ownerReportReportsId?: ModelSubscriptionIDInput | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionOwnerRetentionDetailsFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    satisfactionLevel?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOwnerRetentionDetailsFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerRetentionDetailsFilterInput | null > | null,
};

export type ModelSubscriptionOwnerRiskFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    ownerID?: ModelSubscriptionStringInput | null,
    pm?: ModelSubscriptionStringInput | null,
    ownerSince?: ModelSubscriptionStringInput | null,
    risk?: ModelSubscriptionIntInput | null,
    riskLevel?: ModelSubscriptionStringInput | null,
    rawRisk?: ModelSubscriptionFloatInput | null,
    severity?: ModelSubscriptionStringInput | null,
    severityScore?: ModelSubscriptionFloatInput | null,
    severityManualOverride?: ModelSubscriptionBooleanInput | null,
    clientValue?: ModelSubscriptionIntInput | null,
    clientValueGroup?: ModelSubscriptionStringInput | null,
    status?: ModelSubscriptionStringInput | null,
    resolvedReason?: ModelSubscriptionStringInput | null,
    resolutionExplanation?: ModelSubscriptionStringInput | null,
    inProgressSetDate?: ModelSubscriptionStringInput | null,
    resolvedDate?: ModelSubscriptionStringInput | null,
    statusChangedDate?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOwnerRiskFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerRiskFilterInput | null > | null,
    ownerRiskRecommendationsId?: ModelSubscriptionIDInput | null,
    ownerRiskOwnerRiskTrendsId?: ModelSubscriptionIDInput | null,
    ownerRiskOwnerRetentionDetailsId?: ModelSubscriptionIDInput | null,
    ownerRiskOwnerRisksInsightsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionOwnerRiskTrendFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    risk?: ModelSubscriptionIntInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionOwnerRiskTrendFilterInput | null > | null,
    or?: Array< ModelSubscriptionOwnerRiskTrendFilterInput | null > | null,
};

export type ModelSubscriptionPMMetricsFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionPMMetricsFilterInput | null > | null,
    or?: Array< ModelSubscriptionPMMetricsFilterInput | null > | null,
};

export type ModelSubscriptionPMSettingsFilterInput = {
    leadershipSummary2024?: ModelSubscriptionStringInput | null,
    dashboardProformaMetricsOverride?: ModelSubscriptionStringInput | null,
    showLegalSummary?: ModelSubscriptionBooleanInput | null,
    obpTitleOverride?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    id?: ModelSubscriptionIDInput | null,
    and?: Array< ModelSubscriptionPMSettingsFilterInput | null > | null,
    or?: Array< ModelSubscriptionPMSettingsFilterInput | null > | null,
    pm?: ModelStringInput | null,
};

export type ModelSubscriptionPMTransactionFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    amount?: ModelSubscriptionFloatInput | null,
    transactionDate?: ModelSubscriptionStringInput | null,
    pm?: ModelSubscriptionStringInput | null,
    owner?: ModelSubscriptionStringInput | null,
    type?: ModelSubscriptionStringInput | null,
    category?: ModelSubscriptionStringInput | null,
    label?: ModelSubscriptionStringInput | null,
    description?: ModelSubscriptionStringInput | null,
    dashboardPropertyPmTransactionsId?: ModelSubscriptionIDInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionPMTransactionFilterInput | null > | null,
    or?: Array< ModelSubscriptionPMTransactionFilterInput | null > | null,
};

export type ModelSubscriptionPartnerFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    partnerType?: ModelSubscriptionStringInput | null,
    partnerPM?: ModelSubscriptionStringInput | null,
    firstName?: ModelSubscriptionStringInput | null,
    lastName?: ModelSubscriptionStringInput | null,
    email?: ModelSubscriptionStringInput | null,
    referralAmount?: ModelSubscriptionIntInput | null,
    referralFreeMonths?: ModelSubscriptionFloatInput | null,
    phoneNumber?: ModelSubscriptionStringInput | null,
    avatar?: ModelSubscriptionStringInput | null,
    address?: ModelSubscriptionStringInput | null,
    website?: ModelSubscriptionStringInput | null,
    experienceInYears?: ModelSubscriptionIntInput | null,
    doorCount?: ModelSubscriptionIntInput | null,
    minPrice?: ModelSubscriptionIntInput | null,
    maxPrice?: ModelSubscriptionIntInput | null,
    propertyTypes?: ModelSubscriptionStringInput | null,
    activity?: ModelSubscriptionStringInput | null,
    suggestedPartnerPartnerId?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionPartnerFilterInput | null > | null,
    or?: Array< ModelSubscriptionPartnerFilterInput | null > | null,
    partnerOwnerLeadId?: ModelSubscriptionIDInput | null,
    partnerOwnerLeadReferralLinkId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionPmMarketplacePropertyRecommendationFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    score?: ModelSubscriptionFloatInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionPmMarketplacePropertyRecommendationFilterInput | null > | null,
    or?: Array< ModelSubscriptionPmMarketplacePropertyRecommendationFilterInput | null > | null,
    pm?: ModelStringInput | null,
};

export type ModelSubscriptionPropertyAmortizationTableFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    period?: ModelSubscriptionIntInput | null,
    transactionDate?: ModelSubscriptionStringInput | null,
    payment?: ModelSubscriptionFloatInput | null,
    interest?: ModelSubscriptionFloatInput | null,
    principal?: ModelSubscriptionFloatInput | null,
    totalPrincipal?: ModelSubscriptionFloatInput | null,
    mortgageId?: ModelSubscriptionStringInput | null,
    dashboardPropertyAmortizationTableId?: ModelSubscriptionIDInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionPropertyAmortizationTableFilterInput | null > | null,
    or?: Array< ModelSubscriptionPropertyAmortizationTableFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionPropertyUserExpenseFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    amount?: ModelSubscriptionFloatInput | null,
    startDate?: ModelSubscriptionStringInput | null,
    category?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionPropertyUserExpenseFilterInput | null > | null,
    or?: Array< ModelSubscriptionPropertyUserExpenseFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionProspectLeadWorkflowItemFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    status?: ModelSubscriptionStringInput | null,
    pm?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionProspectLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelSubscriptionProspectLeadWorkflowItemFilterInput | null > | null,
};

export type ModelSubscriptionReportFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    name?: ModelSubscriptionStringInput | null,
    version?: ModelSubscriptionIntInput | null,
    totalOccupiedUnits?: ModelSubscriptionIntInput | null,
    totalUnits?: ModelSubscriptionIntInput | null,
    homeValue?: ModelSubscriptionFloatInput | null,
    appreciation?: ModelSubscriptionFloatInput | null,
    totalOtherIncome?: ModelSubscriptionFloatInput | null,
    totalRent?: ModelSubscriptionFloatInput | null,
    totalEquity?: ModelSubscriptionFloatInput | null,
    noi?: ModelSubscriptionFloatInput | null,
    netCashFlow?: ModelSubscriptionFloatInput | null,
    reportType?: ModelSubscriptionStringInput | null,
    createdAt?: ModelSubscriptionStringInput | null,
    updatedAt?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionReportFilterInput | null > | null,
    or?: Array< ModelSubscriptionReportFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionRetentionPolicyFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    maxFinancialIncentive?: ModelSubscriptionStringInput | null,
    giveIncentiveWillingness?: ModelSubscriptionStringInput | null,
    meetWillingness?: ModelSubscriptionStringInput | null,
    badOwnerPreferredMitigation?: ModelSubscriptionStringInput | null,
    goodOwnerPreferredMitigation?: ModelSubscriptionStringInput | null,
    writingStyles?: ModelSubscriptionStringInput | null,
    riskDetectionSensitivity?: ModelSubscriptionStringInput | null,
    summaryLength?: ModelSubscriptionStringInput | null,
    createdAt?: ModelSubscriptionStringInput | null,
    updatedAt?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionRetentionPolicyFilterInput | null > | null,
    or?: Array< ModelSubscriptionRetentionPolicyFilterInput | null > | null,
};

export type ModelSubscriptionSellerLeadWorkflowItemFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    propertyId?: ModelSubscriptionStringInput | null,
    askingPrice?: ModelSubscriptionIntInput | null,
    isPropertyVisible?: ModelSubscriptionBooleanInput | null,
    status?: ModelSubscriptionStringInput | null,
    pm?: ModelSubscriptionStringInput | null,
    statusChangeTime?: ModelSubscriptionStringInput | null,
    revealed?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionSellerLeadWorkflowItemFilterInput | null > | null,
    or?: Array< ModelSubscriptionSellerLeadWorkflowItemFilterInput | null > | null,
    readShareWith?: ModelStringInput | null,
    updateShareWith?: ModelStringInput | null,
};

export type ModelSubscriptionStateFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    insuranceFactor?: ModelSubscriptionFloatInput | null,
    name?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionStateFilterInput | null > | null,
    or?: Array< ModelSubscriptionStateFilterInput | null > | null,
};

export type ModelSubscriptionSuggestedPartnerFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    pm?: ModelSubscriptionStringInput | null,
    status?: ModelSubscriptionStringInput | null,
    suggestedPartnerType?: ModelSubscriptionStringInput | null,
    suggestedPartnerPM?: ModelSubscriptionStringInput | null,
    firstName?: ModelSubscriptionStringInput | null,
    lastName?: ModelSubscriptionStringInput | null,
    email?: ModelSubscriptionStringInput | null,
    phone?: ModelSubscriptionStringInput | null,
    avatar?: ModelSubscriptionStringInput | null,
    rating?: ModelSubscriptionIntInput | null,
    officeName?: ModelSubscriptionStringInput | null,
    address?: ModelSubscriptionStringInput | null,
    website?: ModelSubscriptionStringInput | null,
    last12MonthsSales?: ModelSubscriptionIntInput | null,
    totalSales?: ModelSubscriptionIntInput | null,
    minPrice?: ModelSubscriptionIntInput | null,
    maxPrice?: ModelSubscriptionIntInput | null,
    totalSalesCount?: ModelSubscriptionIntInput | null,
    totalForSaleCount?: ModelSubscriptionIntInput | null,
    lastTwoYearsSalesCount?: ModelSubscriptionIntInput | null,
    averageSale?: ModelSubscriptionIntInput | null,
    experienceInYears?: ModelSubscriptionIntInput | null,
    description?: ModelSubscriptionStringInput | null,
    sources?: ModelSubscriptionStringInput | null,
    propertyTypes?: ModelSubscriptionStringInput | null,
    activity?: ModelSubscriptionStringInput | null,
    relevancy?: ModelSubscriptionIntInput | null,
    doorCount?: ModelSubscriptionIntInput | null,
    contactEmailBody?: ModelSubscriptionStringInput | null,
    contactEmailSubject?: ModelSubscriptionStringInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionSuggestedPartnerFilterInput | null > | null,
    or?: Array< ModelSubscriptionSuggestedPartnerFilterInput | null > | null,
};

export type ModelSubscriptionTransactionFilterInput = {
    id?: ModelSubscriptionIDInput | null,
    amount?: ModelSubscriptionFloatInput | null,
    transactionDate?: ModelSubscriptionStringInput | null,
    type?: ModelSubscriptionStringInput | null,
    category?: ModelSubscriptionStringInput | null,
    label?: ModelSubscriptionStringInput | null,
    description?: ModelSubscriptionStringInput | null,
    isEditable?: ModelSubscriptionBooleanInput | null,
    dashboardPropertyTransactionsId?: ModelSubscriptionIDInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    and?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
    or?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type ModelSubscriptionUserActionsFilterInput = {
    pm?: ModelSubscriptionStringInput | null,
    viewedWelcomeToMarketplaceScreen?: ModelSubscriptionBooleanInput | null,
    viewedCashOutTooltip?: ModelSubscriptionBooleanInput | null,
    dashboardProformaOverride?: ModelSubscriptionStringInput | null,
    viewedAnnualReportBanner?: ModelSubscriptionBooleanInput | null,
    createdTime?: ModelSubscriptionStringInput | null,
    updatedTime?: ModelSubscriptionStringInput | null,
    id?: ModelSubscriptionIDInput | null,
    and?: Array< ModelSubscriptionUserActionsFilterInput | null > | null,
    or?: Array< ModelSubscriptionUserActionsFilterInput | null > | null,
    owner?: ModelStringInput | null,
};

export type CreateAppSettingsMutationVariables = {
    input: CreateAppSettingsInput,
    condition?: ModelAppSettingsConditionInput | null,
};

export type CreateAppSettingsMutation = {
    createAppSettings?:  {
        __typename: "AppSettings",
        id: string,
        iamDomain?: string | null,
        iamClientId?: string | null,
        iamAudience?: string | null,
        title?: string | null,
        tenant?: string | null,
        pmName?: string | null,
        color?: string | null,
        logo?: string | null,
        favicon?: string | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type UpdateAppSettingsMutationVariables = {
    input: UpdateAppSettingsInput,
    condition?: ModelAppSettingsConditionInput | null,
};

export type UpdateAppSettingsMutation = {
    updateAppSettings?:  {
        __typename: "AppSettings",
        id: string,
        iamDomain?: string | null,
        iamClientId?: string | null,
        iamAudience?: string | null,
        title?: string | null,
        tenant?: string | null,
        pmName?: string | null,
        color?: string | null,
        logo?: string | null,
        favicon?: string | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type DeleteAppSettingsMutationVariables = {
    input: DeleteAppSettingsInput,
    condition?: ModelAppSettingsConditionInput | null,
};

export type DeleteAppSettingsMutation = {
    deleteAppSettings?:  {
        __typename: "AppSettings",
        id: string,
        iamDomain?: string | null,
        iamClientId?: string | null,
        iamAudience?: string | null,
        title?: string | null,
        tenant?: string | null,
        pmName?: string | null,
        color?: string | null,
        logo?: string | null,
        favicon?: string | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type CreateBuyBoxLeadWorkflowItemMutationVariables = {
    input: CreateBuyBoxLeadWorkflowItemInput,
    condition?: ModelBuyBoxLeadWorkflowItemConditionInput | null,
};

export type CreateBuyBoxLeadWorkflowItemMutation = {
    createBuyBoxLeadWorkflowItem?:  {
        __typename: "BuyBoxLeadWorkflowItem",
        id: string,
        leadContactDetails?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        timeline?: string | null,
        amount?: string | null,
        propertyType?: string | null,
        financing?: string | null,
        status: BuyBoxLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateBuyBoxLeadWorkflowItemMutationVariables = {
    input: UpdateBuyBoxLeadWorkflowItemInput,
    condition?: ModelBuyBoxLeadWorkflowItemConditionInput | null,
};

export type UpdateBuyBoxLeadWorkflowItemMutation = {
    updateBuyBoxLeadWorkflowItem?:  {
        __typename: "BuyBoxLeadWorkflowItem",
        id: string,
        leadContactDetails?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        timeline?: string | null,
        amount?: string | null,
        propertyType?: string | null,
        financing?: string | null,
        status: BuyBoxLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteBuyBoxLeadWorkflowItemMutationVariables = {
    input: DeleteBuyBoxLeadWorkflowItemInput,
    condition?: ModelBuyBoxLeadWorkflowItemConditionInput | null,
};

export type DeleteBuyBoxLeadWorkflowItemMutation = {
    deleteBuyBoxLeadWorkflowItem?:  {
        __typename: "BuyBoxLeadWorkflowItem",
        id: string,
        leadContactDetails?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        timeline?: string | null,
        amount?: string | null,
        propertyType?: string | null,
        financing?: string | null,
        status: BuyBoxLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateBuyerLeadWorkflowItemMutationVariables = {
    input: CreateBuyerLeadWorkflowItemInput,
    condition?: ModelBuyerLeadWorkflowItemConditionInput | null,
};

export type CreateBuyerLeadWorkflowItemMutation = {
    createBuyerLeadWorkflowItem?:  {
        __typename: "BuyerLeadWorkflowItem",
        id: string,
        buyerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        sellerAgentContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        askingPrice: number,
        pm: string,
        statusChangeTime: string,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId: string,
        isPropertyVisible?: boolean | null,
        status: BuyerLeadsWorkflowStatus,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateBuyerLeadWorkflowItemMutationVariables = {
    input: UpdateBuyerLeadWorkflowItemInput,
    condition?: ModelBuyerLeadWorkflowItemConditionInput | null,
};

export type UpdateBuyerLeadWorkflowItemMutation = {
    updateBuyerLeadWorkflowItem?:  {
        __typename: "BuyerLeadWorkflowItem",
        id: string,
        buyerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        sellerAgentContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        askingPrice: number,
        pm: string,
        statusChangeTime: string,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId: string,
        isPropertyVisible?: boolean | null,
        status: BuyerLeadsWorkflowStatus,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteBuyerLeadWorkflowItemMutationVariables = {
    input: DeleteBuyerLeadWorkflowItemInput,
    condition?: ModelBuyerLeadWorkflowItemConditionInput | null,
};

export type DeleteBuyerLeadWorkflowItemMutation = {
    deleteBuyerLeadWorkflowItem?:  {
        __typename: "BuyerLeadWorkflowItem",
        id: string,
        buyerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        sellerAgentContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        askingPrice: number,
        pm: string,
        statusChangeTime: string,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId: string,
        isPropertyVisible?: boolean | null,
        status: BuyerLeadsWorkflowStatus,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateCrimeMutationVariables = {
    input: CreateCrimeInput,
    condition?: ModelCrimeConditionInput | null,
};

export type CreateCrimeMutation = {
    createCrime?:  {
        __typename: "Crime",
        id: string,
        all:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        other:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        property:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        violent:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        marketplaceProperty:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyCrimeId?: string | null,
    } | null,
};

export type UpdateCrimeMutationVariables = {
    input: UpdateCrimeInput,
    condition?: ModelCrimeConditionInput | null,
};

export type UpdateCrimeMutation = {
    updateCrime?:  {
        __typename: "Crime",
        id: string,
        all:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        other:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        property:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        violent:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        marketplaceProperty:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyCrimeId?: string | null,
    } | null,
};

export type DeleteCrimeMutationVariables = {
    input: DeleteCrimeInput,
    condition?: ModelCrimeConditionInput | null,
};

export type DeleteCrimeMutation = {
    deleteCrime?:  {
        __typename: "Crime",
        id: string,
        all:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        other:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        property:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        violent:  {
            __typename: "Percentile",
            national: number,
            incidents: number,
            county: number,
        },
        marketplaceProperty:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyCrimeId?: string | null,
    } | null,
};

export type CreateDashboardPropertyMutationVariables = {
    input: CreateDashboardPropertyInput,
    condition?: ModelDashboardPropertyConditionInput | null,
};

export type CreateDashboardPropertyMutation = {
    createDashboardProperty?:  {
        __typename: "DashboardProperty",
        id: string,
        owner: string,
        name: string,
        transactions?:  {
            __typename: "ModelTransactionConnection",
            nextToken?: string | null,
        } | null,
        pmTransactions?:  {
            __typename: "ModelPMTransactionConnection",
            nextToken?: string | null,
        } | null,
        leases?:  {
            __typename: "ModelLeaseConnection",
            nextToken?: string | null,
        } | null,
        userExpense?:  {
            __typename: "ModelPropertyUserExpenseConnection",
            nextToken?: string | null,
        } | null,
        amortizationTable?:  {
            __typename: "ModelPropertyAmortizationTableConnection",
            nextToken?: string | null,
        } | null,
        mortgage?:  {
            __typename: "ModelMortgageConnection",
            nextToken?: string | null,
        } | null,
        propertyValuation?:  {
            __typename: "ModelDashboardPropertyValuationConnection",
            nextToken?: string | null,
        } | null,
        purchaseHistory?:  {
            __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
            nextToken?: string | null,
        } | null,
        metrics?:  {
            __typename: "ModelDashboardPropertyMetricConnection",
            nextToken?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        hoaMuted?: boolean | null,
        pmId: string,
        readPermissions?: Array< string | null > | null,
        deleted?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyDashboardPropertyId?: string | null,
    } | null,
};

export type UpdateDashboardPropertyMutationVariables = {
    input: UpdateDashboardPropertyInput,
    condition?: ModelDashboardPropertyConditionInput | null,
};

export type UpdateDashboardPropertyMutation = {
    updateDashboardProperty?:  {
        __typename: "DashboardProperty",
        id: string,
        owner: string,
        name: string,
        transactions?:  {
            __typename: "ModelTransactionConnection",
            nextToken?: string | null,
        } | null,
        pmTransactions?:  {
            __typename: "ModelPMTransactionConnection",
            nextToken?: string | null,
        } | null,
        leases?:  {
            __typename: "ModelLeaseConnection",
            nextToken?: string | null,
        } | null,
        userExpense?:  {
            __typename: "ModelPropertyUserExpenseConnection",
            nextToken?: string | null,
        } | null,
        amortizationTable?:  {
            __typename: "ModelPropertyAmortizationTableConnection",
            nextToken?: string | null,
        } | null,
        mortgage?:  {
            __typename: "ModelMortgageConnection",
            nextToken?: string | null,
        } | null,
        propertyValuation?:  {
            __typename: "ModelDashboardPropertyValuationConnection",
            nextToken?: string | null,
        } | null,
        purchaseHistory?:  {
            __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
            nextToken?: string | null,
        } | null,
        metrics?:  {
            __typename: "ModelDashboardPropertyMetricConnection",
            nextToken?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        hoaMuted?: boolean | null,
        pmId: string,
        readPermissions?: Array< string | null > | null,
        deleted?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyDashboardPropertyId?: string | null,
    } | null,
};

export type DeleteDashboardPropertyMutationVariables = {
    input: DeleteDashboardPropertyInput,
    condition?: ModelDashboardPropertyConditionInput | null,
};

export type DeleteDashboardPropertyMutation = {
    deleteDashboardProperty?:  {
        __typename: "DashboardProperty",
        id: string,
        owner: string,
        name: string,
        transactions?:  {
            __typename: "ModelTransactionConnection",
            nextToken?: string | null,
        } | null,
        pmTransactions?:  {
            __typename: "ModelPMTransactionConnection",
            nextToken?: string | null,
        } | null,
        leases?:  {
            __typename: "ModelLeaseConnection",
            nextToken?: string | null,
        } | null,
        userExpense?:  {
            __typename: "ModelPropertyUserExpenseConnection",
            nextToken?: string | null,
        } | null,
        amortizationTable?:  {
            __typename: "ModelPropertyAmortizationTableConnection",
            nextToken?: string | null,
        } | null,
        mortgage?:  {
            __typename: "ModelMortgageConnection",
            nextToken?: string | null,
        } | null,
        propertyValuation?:  {
            __typename: "ModelDashboardPropertyValuationConnection",
            nextToken?: string | null,
        } | null,
        purchaseHistory?:  {
            __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
            nextToken?: string | null,
        } | null,
        metrics?:  {
            __typename: "ModelDashboardPropertyMetricConnection",
            nextToken?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        hoaMuted?: boolean | null,
        pmId: string,
        readPermissions?: Array< string | null > | null,
        deleted?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyDashboardPropertyId?: string | null,
    } | null,
};

export type CreateDashboardPropertyMetricMutationVariables = {
    input: CreateDashboardPropertyMetricInput,
    condition?: ModelDashboardPropertyMetricConditionInput | null,
};

export type CreateDashboardPropertyMetricMutation = {
    createDashboardPropertyMetric?:  {
        __typename: "DashboardPropertyMetric",
        id: string,
        NOI?: number | null,
        cashOnCash?: number | null,
        capRate?: number | null,
        cashFlow?: number | null,
        annualIncome?: number | null,
        annualExpense?: number | null,
        cashInvested?: number | null,
        isDataPartial?: boolean | null,
        currentVacancy?: number | null,
        turnoverTenants?: number | null,
        totalUniqTenants?: number | null,
        monthsToEndLease?: number | null,
        monthsFromStartLease?: number | null,
        leaseTerm?: string | null,
        appreciation?: number | null,
        valuation?: number | null,
        totalEquity?: number | null,
        missingData?: Array< MissingData | null > | null,
        monthsFromTheFirstTransaction?: number | null,
        firstTransactionDate?: string | null,
        purchasePrice?: number | null,
        purchaseDate?: string | null,
        annualMetrics?:  {
            __typename: "AnnualMetrics",
            income?: number | null,
            estimatedIncome?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            propertyTax?: number | null,
            propertyInsurance?: number | null,
            propertyHoa?: number | null,
        } | null,
        metricsIn3Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        metricsIn6Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        metricsIn12Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ4?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ3?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ2?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ1?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        monthsMetrics?:  Array< {
            __typename: "MonthMetrics",
            isVacant?: boolean | null,
            monthDate?: string | null,
        } > | null,
        dashboardPropertyMetricsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateDashboardPropertyMetricMutationVariables = {
    input: UpdateDashboardPropertyMetricInput,
    condition?: ModelDashboardPropertyMetricConditionInput | null,
};

export type UpdateDashboardPropertyMetricMutation = {
    updateDashboardPropertyMetric?:  {
        __typename: "DashboardPropertyMetric",
        id: string,
        NOI?: number | null,
        cashOnCash?: number | null,
        capRate?: number | null,
        cashFlow?: number | null,
        annualIncome?: number | null,
        annualExpense?: number | null,
        cashInvested?: number | null,
        isDataPartial?: boolean | null,
        currentVacancy?: number | null,
        turnoverTenants?: number | null,
        totalUniqTenants?: number | null,
        monthsToEndLease?: number | null,
        monthsFromStartLease?: number | null,
        leaseTerm?: string | null,
        appreciation?: number | null,
        valuation?: number | null,
        totalEquity?: number | null,
        missingData?: Array< MissingData | null > | null,
        monthsFromTheFirstTransaction?: number | null,
        firstTransactionDate?: string | null,
        purchasePrice?: number | null,
        purchaseDate?: string | null,
        annualMetrics?:  {
            __typename: "AnnualMetrics",
            income?: number | null,
            estimatedIncome?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            propertyTax?: number | null,
            propertyInsurance?: number | null,
            propertyHoa?: number | null,
        } | null,
        metricsIn3Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        metricsIn6Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        metricsIn12Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ4?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ3?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ2?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ1?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        monthsMetrics?:  Array< {
            __typename: "MonthMetrics",
            isVacant?: boolean | null,
            monthDate?: string | null,
        } > | null,
        dashboardPropertyMetricsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteDashboardPropertyMetricMutationVariables = {
    input: DeleteDashboardPropertyMetricInput,
    condition?: ModelDashboardPropertyMetricConditionInput | null,
};

export type DeleteDashboardPropertyMetricMutation = {
    deleteDashboardPropertyMetric?:  {
        __typename: "DashboardPropertyMetric",
        id: string,
        NOI?: number | null,
        cashOnCash?: number | null,
        capRate?: number | null,
        cashFlow?: number | null,
        annualIncome?: number | null,
        annualExpense?: number | null,
        cashInvested?: number | null,
        isDataPartial?: boolean | null,
        currentVacancy?: number | null,
        turnoverTenants?: number | null,
        totalUniqTenants?: number | null,
        monthsToEndLease?: number | null,
        monthsFromStartLease?: number | null,
        leaseTerm?: string | null,
        appreciation?: number | null,
        valuation?: number | null,
        totalEquity?: number | null,
        missingData?: Array< MissingData | null > | null,
        monthsFromTheFirstTransaction?: number | null,
        firstTransactionDate?: string | null,
        purchasePrice?: number | null,
        purchaseDate?: string | null,
        annualMetrics?:  {
            __typename: "AnnualMetrics",
            income?: number | null,
            estimatedIncome?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            propertyTax?: number | null,
            propertyInsurance?: number | null,
            propertyHoa?: number | null,
        } | null,
        metricsIn3Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        metricsIn6Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        metricsIn12Months?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ4?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ3?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ2?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        trailingQ1?:  {
            __typename: "Metrics",
            income?: number | null,
            noiExpenses?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            maintenanceEvents?: number | null,
            managementFee?: number | null,
            cashflow?: number | null,
            noi?: number | null,
            caPex?: number | null,
            monthsRent?: number | null,
            from?: string | null,
            to?: string | null,
        } | null,
        monthsMetrics?:  Array< {
            __typename: "MonthMetrics",
            isVacant?: boolean | null,
            monthDate?: string | null,
        } > | null,
        dashboardPropertyMetricsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateDashboardPropertyPurchaseHistoryMutationVariables = {
    input: CreateDashboardPropertyPurchaseHistoryInput,
    condition?: ModelDashboardPropertyPurchaseHistoryConditionInput | null,
};

export type CreateDashboardPropertyPurchaseHistoryMutation = {
    createDashboardPropertyPurchaseHistory?:  {
        __typename: "DashboardPropertyPurchaseHistory",
        id: string,
        amount: number,
        closingCost?: number | null,
        homeImprovementCost?: number | null,
        date?: string | null,
        provider?: Provider | null,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyPurchaseHistoryId?: string | null,
    } | null,
};

export type UpdateDashboardPropertyPurchaseHistoryMutationVariables = {
    input: UpdateDashboardPropertyPurchaseHistoryInput,
    condition?: ModelDashboardPropertyPurchaseHistoryConditionInput | null,
};

export type UpdateDashboardPropertyPurchaseHistoryMutation = {
    updateDashboardPropertyPurchaseHistory?:  {
        __typename: "DashboardPropertyPurchaseHistory",
        id: string,
        amount: number,
        closingCost?: number | null,
        homeImprovementCost?: number | null,
        date?: string | null,
        provider?: Provider | null,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyPurchaseHistoryId?: string | null,
    } | null,
};

export type DeleteDashboardPropertyPurchaseHistoryMutationVariables = {
    input: DeleteDashboardPropertyPurchaseHistoryInput,
    condition?: ModelDashboardPropertyPurchaseHistoryConditionInput | null,
};

export type DeleteDashboardPropertyPurchaseHistoryMutation = {
    deleteDashboardPropertyPurchaseHistory?:  {
        __typename: "DashboardPropertyPurchaseHistory",
        id: string,
        amount: number,
        closingCost?: number | null,
        homeImprovementCost?: number | null,
        date?: string | null,
        provider?: Provider | null,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyPurchaseHistoryId?: string | null,
    } | null,
};

export type CreateDashboardPropertyValuationMutationVariables = {
    input: CreateDashboardPropertyValuationInput,
    condition?: ModelDashboardPropertyValuationConditionInput | null,
};

export type CreateDashboardPropertyValuationMutation = {
    createDashboardPropertyValuation?:  {
        __typename: "DashboardPropertyValuation",
        id: string,
        date: string,
        priceUpper?: number | null,
        priceLower?: number | null,
        priceMean?: number | null,
        fsd?: number | null,
        provider?: Provider | null,
        pm?: string | null,
        dashboardPropertyPropertyValuationId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateDashboardPropertyValuationMutationVariables = {
    input: UpdateDashboardPropertyValuationInput,
    condition?: ModelDashboardPropertyValuationConditionInput | null,
};

export type UpdateDashboardPropertyValuationMutation = {
    updateDashboardPropertyValuation?:  {
        __typename: "DashboardPropertyValuation",
        id: string,
        date: string,
        priceUpper?: number | null,
        priceLower?: number | null,
        priceMean?: number | null,
        fsd?: number | null,
        provider?: Provider | null,
        pm?: string | null,
        dashboardPropertyPropertyValuationId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteDashboardPropertyValuationMutationVariables = {
    input: DeleteDashboardPropertyValuationInput,
    condition?: ModelDashboardPropertyValuationConditionInput | null,
};

export type DeleteDashboardPropertyValuationMutation = {
    deleteDashboardPropertyValuation?:  {
        __typename: "DashboardPropertyValuation",
        id: string,
        date: string,
        priceUpper?: number | null,
        priceLower?: number | null,
        priceMean?: number | null,
        fsd?: number | null,
        provider?: Provider | null,
        pm?: string | null,
        dashboardPropertyPropertyValuationId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateFavoriteMarketplacePropertyMutationVariables = {
    input: CreateFavoriteMarketplacePropertyInput,
    condition?: ModelFavoriteMarketplacePropertyConditionInput | null,
};

export type CreateFavoriteMarketplacePropertyMutation = {
    createFavoriteMarketplaceProperty?:  {
        __typename: "FavoriteMarketplaceProperty",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyFavoritesId?: string | null,
    } | null,
};

export type UpdateFavoriteMarketplacePropertyMutationVariables = {
    input: UpdateFavoriteMarketplacePropertyInput,
    condition?: ModelFavoriteMarketplacePropertyConditionInput | null,
};

export type UpdateFavoriteMarketplacePropertyMutation = {
    updateFavoriteMarketplaceProperty?:  {
        __typename: "FavoriteMarketplaceProperty",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyFavoritesId?: string | null,
    } | null,
};

export type DeleteFavoriteMarketplacePropertyMutationVariables = {
    input: DeleteFavoriteMarketplacePropertyInput,
    condition?: ModelFavoriteMarketplacePropertyConditionInput | null,
};

export type DeleteFavoriteMarketplacePropertyMutation = {
    deleteFavoriteMarketplaceProperty?:  {
        __typename: "FavoriteMarketplaceProperty",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyFavoritesId?: string | null,
    } | null,
};

export type CreateGrowthPolicyMutationVariables = {
    input: CreateGrowthPolicyInput,
    condition?: ModelGrowthPolicyConditionInput | null,
};

export type CreateGrowthPolicyMutation = {
    createGrowthPolicy?:  {
        __typename: "GrowthPolicy",
        id: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        agentReferralFeeAmount?: number | null,
        agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
        agentProvidedBenefitsOther?: string | null,
        enableOwnerReferralProgram?: boolean | null,
        ownerReferralsIncentive?: OwnerReferralsIncentive | null,
        enablePMReferralProgram?: boolean | null,
        pmReferralsFeeAmount?: number | null,
        typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        yearsInBusiness?: number | null,
        website?: string | null,
        companyDescription?: string | null,
        pmAvatarURL?: string | null,
        pmFirstName?: string | null,
        pmLastName?: string | null,
        pmTitle?: string | null,
        companyGoogleReviews?: number | null,
        companyAVGDayOnMarket?: number | null,
        companyTenantRenewalRate?: number | null,
        companyServices?: Array< CompanyServices > | null,
        companyServicesOther?: string | null,
        companyGuarantees?: Array< CompanyGuarantees > | null,
        companyGuaranteesOther?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateGrowthPolicyMutationVariables = {
    input: UpdateGrowthPolicyInput,
    condition?: ModelGrowthPolicyConditionInput | null,
};

export type UpdateGrowthPolicyMutation = {
    updateGrowthPolicy?:  {
        __typename: "GrowthPolicy",
        id: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        agentReferralFeeAmount?: number | null,
        agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
        agentProvidedBenefitsOther?: string | null,
        enableOwnerReferralProgram?: boolean | null,
        ownerReferralsIncentive?: OwnerReferralsIncentive | null,
        enablePMReferralProgram?: boolean | null,
        pmReferralsFeeAmount?: number | null,
        typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        yearsInBusiness?: number | null,
        website?: string | null,
        companyDescription?: string | null,
        pmAvatarURL?: string | null,
        pmFirstName?: string | null,
        pmLastName?: string | null,
        pmTitle?: string | null,
        companyGoogleReviews?: number | null,
        companyAVGDayOnMarket?: number | null,
        companyTenantRenewalRate?: number | null,
        companyServices?: Array< CompanyServices > | null,
        companyServicesOther?: string | null,
        companyGuarantees?: Array< CompanyGuarantees > | null,
        companyGuaranteesOther?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteGrowthPolicyMutationVariables = {
    input: DeleteGrowthPolicyInput,
    condition?: ModelGrowthPolicyConditionInput | null,
};

export type DeleteGrowthPolicyMutation = {
    deleteGrowthPolicy?:  {
        __typename: "GrowthPolicy",
        id: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        agentReferralFeeAmount?: number | null,
        agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
        agentProvidedBenefitsOther?: string | null,
        enableOwnerReferralProgram?: boolean | null,
        ownerReferralsIncentive?: OwnerReferralsIncentive | null,
        enablePMReferralProgram?: boolean | null,
        pmReferralsFeeAmount?: number | null,
        typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        yearsInBusiness?: number | null,
        website?: string | null,
        companyDescription?: string | null,
        pmAvatarURL?: string | null,
        pmFirstName?: string | null,
        pmLastName?: string | null,
        pmTitle?: string | null,
        companyGoogleReviews?: number | null,
        companyAVGDayOnMarket?: number | null,
        companyTenantRenewalRate?: number | null,
        companyServices?: Array< CompanyServices > | null,
        companyServicesOther?: string | null,
        companyGuarantees?: Array< CompanyGuarantees > | null,
        companyGuaranteesOther?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateLeaseMutationVariables = {
    input: CreateLeaseInput,
    condition?: ModelLeaseConditionInput | null,
};

export type CreateLeaseMutation = {
    createLease?:  {
        __typename: "Lease",
        id: string,
        startDate: string,
        endDate?: string | null,
        isActive?: boolean | null,
        term?: Term | null,
        rent?: number | null,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        tenant?: string | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyLeasesId?: string | null,
    } | null,
};

export type UpdateLeaseMutationVariables = {
    input: UpdateLeaseInput,
    condition?: ModelLeaseConditionInput | null,
};

export type UpdateLeaseMutation = {
    updateLease?:  {
        __typename: "Lease",
        id: string,
        startDate: string,
        endDate?: string | null,
        isActive?: boolean | null,
        term?: Term | null,
        rent?: number | null,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        tenant?: string | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyLeasesId?: string | null,
    } | null,
};

export type DeleteLeaseMutationVariables = {
    input: DeleteLeaseInput,
    condition?: ModelLeaseConditionInput | null,
};

export type DeleteLeaseMutation = {
    deleteLease?:  {
        __typename: "Lease",
        id: string,
        startDate: string,
        endDate?: string | null,
        isActive?: boolean | null,
        term?: Term | null,
        rent?: number | null,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        tenant?: string | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyLeasesId?: string | null,
    } | null,
};

export type CreateLegislationDataMutationVariables = {
    input: CreateLegislationDataInput,
    condition?: ModelLegislationDataConditionInput | null,
};

export type CreateLegislationDataMutation = {
    createLegislationData?:  {
        __typename: "LegislationData",
        id: string,
        state: string,
        billID?: number | null,
        lastActionDate?: string | null,
        billNumber?: string | null,
        title?: string | null,
        url?: string | null,
        relevance?: number | null,
        lastAction?: string | null,
        status?: BillStatus | null,
        summary?: string | null,
        executiveSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateLegislationDataMutationVariables = {
    input: UpdateLegislationDataInput,
    condition?: ModelLegislationDataConditionInput | null,
};

export type UpdateLegislationDataMutation = {
    updateLegislationData?:  {
        __typename: "LegislationData",
        id: string,
        state: string,
        billID?: number | null,
        lastActionDate?: string | null,
        billNumber?: string | null,
        title?: string | null,
        url?: string | null,
        relevance?: number | null,
        lastAction?: string | null,
        status?: BillStatus | null,
        summary?: string | null,
        executiveSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteLegislationDataMutationVariables = {
    input: DeleteLegislationDataInput,
    condition?: ModelLegislationDataConditionInput | null,
};

export type DeleteLegislationDataMutation = {
    deleteLegislationData?:  {
        __typename: "LegislationData",
        id: string,
        state: string,
        billID?: number | null,
        lastActionDate?: string | null,
        billNumber?: string | null,
        title?: string | null,
        url?: string | null,
        relevance?: number | null,
        lastAction?: string | null,
        status?: BillStatus | null,
        summary?: string | null,
        executiveSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateMarketDataMutationVariables = {
    input: CreateMarketDataInput,
    condition?: ModelMarketDataConditionInput | null,
};

export type CreateMarketDataMutation = {
    createMarketData?:  {
        __typename: "MarketData",
        id: string,
        fips: string,
        dataType?: string | null,
        date: string,
        areaName?: string | null,
        homeValueSFR?: number | null,
        homeValueAll?: number | null,
        marketHeat?: number | null,
        rentSFR?: number | null,
        rentAll?: number | null,
        daysOnMarket?: number | null,
        marketSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateMarketDataMutationVariables = {
    input: UpdateMarketDataInput,
    condition?: ModelMarketDataConditionInput | null,
};

export type UpdateMarketDataMutation = {
    updateMarketData?:  {
        __typename: "MarketData",
        id: string,
        fips: string,
        dataType?: string | null,
        date: string,
        areaName?: string | null,
        homeValueSFR?: number | null,
        homeValueAll?: number | null,
        marketHeat?: number | null,
        rentSFR?: number | null,
        rentAll?: number | null,
        daysOnMarket?: number | null,
        marketSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteMarketDataMutationVariables = {
    input: DeleteMarketDataInput,
    condition?: ModelMarketDataConditionInput | null,
};

export type DeleteMarketDataMutation = {
    deleteMarketData?:  {
        __typename: "MarketData",
        id: string,
        fips: string,
        dataType?: string | null,
        date: string,
        areaName?: string | null,
        homeValueSFR?: number | null,
        homeValueAll?: number | null,
        marketHeat?: number | null,
        rentSFR?: number | null,
        rentAll?: number | null,
        daysOnMarket?: number | null,
        marketSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateMarketPlacePropertyPictureMutationVariables = {
    input: CreateMarketPlacePropertyPictureInput,
    condition?: ModelMarketPlacePropertyPictureConditionInput | null,
};

export type CreateMarketPlacePropertyPictureMutation = {
    createMarketPlacePropertyPicture?:  {
        __typename: "MarketPlacePropertyPicture",
        url: string,
        rank?: number | null,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        marketplacePropertyListingPicturesId: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateMarketPlacePropertyPictureMutationVariables = {
    input: UpdateMarketPlacePropertyPictureInput,
    condition?: ModelMarketPlacePropertyPictureConditionInput | null,
};

export type UpdateMarketPlacePropertyPictureMutation = {
    updateMarketPlacePropertyPicture?:  {
        __typename: "MarketPlacePropertyPicture",
        url: string,
        rank?: number | null,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        marketplacePropertyListingPicturesId: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteMarketPlacePropertyPictureMutationVariables = {
    input: DeleteMarketPlacePropertyPictureInput,
    condition?: ModelMarketPlacePropertyPictureConditionInput | null,
};

export type DeleteMarketPlacePropertyPictureMutation = {
    deleteMarketPlacePropertyPicture?:  {
        __typename: "MarketPlacePropertyPicture",
        url: string,
        rank?: number | null,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        marketplacePropertyListingPicturesId: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateMarketplacePropertyRentalMutationVariables = {
    input: CreateMarketplacePropertyRentalInput,
    condition?: ModelMarketplacePropertyRentalConditionInput | null,
};

export type CreateMarketplacePropertyRentalMutation = {
    createMarketplacePropertyRental?:  {
        __typename: "MarketplacePropertyRental",
        id: string,
        date: string,
        priceUpper?: number | null,
        priceLower?: number | null,
        priceMean?: number | null,
        fsd?: number | null,
        provider?: Provider | null,
        marketplacePropertyPropertyRentalId: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateMarketplacePropertyRentalMutationVariables = {
    input: UpdateMarketplacePropertyRentalInput,
    condition?: ModelMarketplacePropertyRentalConditionInput | null,
};

export type UpdateMarketplacePropertyRentalMutation = {
    updateMarketplacePropertyRental?:  {
        __typename: "MarketplacePropertyRental",
        id: string,
        date: string,
        priceUpper?: number | null,
        priceLower?: number | null,
        priceMean?: number | null,
        fsd?: number | null,
        provider?: Provider | null,
        marketplacePropertyPropertyRentalId: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteMarketplacePropertyRentalMutationVariables = {
    input: DeleteMarketplacePropertyRentalInput,
    condition?: ModelMarketplacePropertyRentalConditionInput | null,
};

export type DeleteMarketplacePropertyRentalMutation = {
    deleteMarketplacePropertyRental?:  {
        __typename: "MarketplacePropertyRental",
        id: string,
        date: string,
        priceUpper?: number | null,
        priceLower?: number | null,
        priceMean?: number | null,
        fsd?: number | null,
        provider?: Provider | null,
        marketplacePropertyPropertyRentalId: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateMarketplacePMPreferencesMutationVariables = {
    input: CreateMarketplacePMPreferencesInput,
    condition?: ModelMarketplacePMPreferencesConditionInput | null,
};

export type CreateMarketplacePMPreferencesMutation = {
    createMarketplacePMPreferences?:  {
        __typename: "MarketplacePMPreferences",
        pm: string,
        managementFee?: string | null,
        leadsThirdPartyIntegrationRequested?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateMarketplacePMPreferencesMutationVariables = {
    input: UpdateMarketplacePMPreferencesInput,
    condition?: ModelMarketplacePMPreferencesConditionInput | null,
};

export type UpdateMarketplacePMPreferencesMutation = {
    updateMarketplacePMPreferences?:  {
        __typename: "MarketplacePMPreferences",
        pm: string,
        managementFee?: string | null,
        leadsThirdPartyIntegrationRequested?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteMarketplacePMPreferencesMutationVariables = {
    input: DeleteMarketplacePMPreferencesInput,
    condition?: ModelMarketplacePMPreferencesConditionInput | null,
};

export type DeleteMarketplacePMPreferencesMutation = {
    deleteMarketplacePMPreferences?:  {
        __typename: "MarketplacePMPreferences",
        pm: string,
        managementFee?: string | null,
        leadsThirdPartyIntegrationRequested?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateMarketplacePropertyMutationVariables = {
    input: CreateMarketplacePropertyInput,
    condition?: ModelMarketplacePropertyConditionInput | null,
};

export type CreateMarketplacePropertyMutation = {
    createMarketplaceProperty?:  {
        __typename: "MarketplaceProperty",
        id: string,
        askingPrice: number,
        owner: string,
        status: MarketPlacePropertyStatus,
        closedBy?: string | null,
        activatedTime?: string | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        description?: string | null,
        rent?: number | null,
        constructionStatus?: ConstructionStatus | null,
        category?: MarketplacePropertyCategory | null,
        source?: string | null,
        vendor?: string | null,
        listingPictures?:  {
            __typename: "ModelMarketPlacePropertyPictureConnection",
            nextToken?: string | null,
        } | null,
        expenses?:  {
            __typename: "MarketPlacePropertyExpenses",
            hoa?: number | null,
            insurance?: number | null,
            managementFee?: number | null,
            other?: number | null,
            tax?: number | null,
        } | null,
        favorites?:  {
            __typename: "ModelFavoriteMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        recommendations?:  {
            __typename: "ModelMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        pmRecommendations?:  {
            __typename: "ModelPmMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        offers?:  {
            __typename: "ModelOfferConnection",
            nextToken?: string | null,
        } | null,
        crime?:  {
            __typename: "ModelCrimeConnection",
            nextToken?: string | null,
        } | null,
        readPermissions?: Array< string | null > | null,
        relevantPMs?: Array< string | null > | null,
        shareWith?: Array< string | null > | null,
        propertyRental?:  {
            __typename: "ModelMarketplacePropertyRentalConnection",
            nextToken?: string | null,
        } | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        listingItems?:  {
            __typename: "ModelMarketplacePropertyListingItemConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
        propertyMarketplacePropertyId?: string | null,
    } | null,
};

export type UpdateMarketplacePropertyMutationVariables = {
    input: UpdateMarketplacePropertyInput,
    condition?: ModelMarketplacePropertyConditionInput | null,
};

export type UpdateMarketplacePropertyMutation = {
    updateMarketplaceProperty?:  {
        __typename: "MarketplaceProperty",
        id: string,
        askingPrice: number,
        owner: string,
        status: MarketPlacePropertyStatus,
        closedBy?: string | null,
        activatedTime?: string | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        description?: string | null,
        rent?: number | null,
        constructionStatus?: ConstructionStatus | null,
        category?: MarketplacePropertyCategory | null,
        source?: string | null,
        vendor?: string | null,
        listingPictures?:  {
            __typename: "ModelMarketPlacePropertyPictureConnection",
            nextToken?: string | null,
        } | null,
        expenses?:  {
            __typename: "MarketPlacePropertyExpenses",
            hoa?: number | null,
            insurance?: number | null,
            managementFee?: number | null,
            other?: number | null,
            tax?: number | null,
        } | null,
        favorites?:  {
            __typename: "ModelFavoriteMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        recommendations?:  {
            __typename: "ModelMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        pmRecommendations?:  {
            __typename: "ModelPmMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        offers?:  {
            __typename: "ModelOfferConnection",
            nextToken?: string | null,
        } | null,
        crime?:  {
            __typename: "ModelCrimeConnection",
            nextToken?: string | null,
        } | null,
        readPermissions?: Array< string | null > | null,
        relevantPMs?: Array< string | null > | null,
        shareWith?: Array< string | null > | null,
        propertyRental?:  {
            __typename: "ModelMarketplacePropertyRentalConnection",
            nextToken?: string | null,
        } | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        listingItems?:  {
            __typename: "ModelMarketplacePropertyListingItemConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
        propertyMarketplacePropertyId?: string | null,
    } | null,
};

export type DeleteMarketplacePropertyMutationVariables = {
    input: DeleteMarketplacePropertyInput,
    condition?: ModelMarketplacePropertyConditionInput | null,
};

export type DeleteMarketplacePropertyMutation = {
    deleteMarketplaceProperty?:  {
        __typename: "MarketplaceProperty",
        id: string,
        askingPrice: number,
        owner: string,
        status: MarketPlacePropertyStatus,
        closedBy?: string | null,
        activatedTime?: string | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        description?: string | null,
        rent?: number | null,
        constructionStatus?: ConstructionStatus | null,
        category?: MarketplacePropertyCategory | null,
        source?: string | null,
        vendor?: string | null,
        listingPictures?:  {
            __typename: "ModelMarketPlacePropertyPictureConnection",
            nextToken?: string | null,
        } | null,
        expenses?:  {
            __typename: "MarketPlacePropertyExpenses",
            hoa?: number | null,
            insurance?: number | null,
            managementFee?: number | null,
            other?: number | null,
            tax?: number | null,
        } | null,
        favorites?:  {
            __typename: "ModelFavoriteMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        recommendations?:  {
            __typename: "ModelMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        pmRecommendations?:  {
            __typename: "ModelPmMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        offers?:  {
            __typename: "ModelOfferConnection",
            nextToken?: string | null,
        } | null,
        crime?:  {
            __typename: "ModelCrimeConnection",
            nextToken?: string | null,
        } | null,
        readPermissions?: Array< string | null > | null,
        relevantPMs?: Array< string | null > | null,
        shareWith?: Array< string | null > | null,
        propertyRental?:  {
            __typename: "ModelMarketplacePropertyRentalConnection",
            nextToken?: string | null,
        } | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        listingItems?:  {
            __typename: "ModelMarketplacePropertyListingItemConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
        propertyMarketplacePropertyId?: string | null,
    } | null,
};

export type CreateMarketplacePropertyListingItemMutationVariables = {
    input: CreateMarketplacePropertyListingItemInput,
    condition?: ModelMarketplacePropertyListingItemConditionInput | null,
};

export type CreateMarketplacePropertyListingItemMutation = {
    createMarketplacePropertyListingItem?:  {
        __typename: "MarketplacePropertyListingItem",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        property?:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null,
        status: MarketPlacePropertyStatus,
        actualRent?: number | null,
        estimatedRent?: number | null,
        stateInsuranceFactor?: number | null,
        totalExpenses?: number | null,
        maintenance?: number | null,
        estimatedVacancy?: number | null,
        managementFee?: string | null,
        capRate?: number | null,
        noi?: number | null,
        cashOnCash?: number | null,
        grossYield?: number | null,
        insurance?: number | null,
        askingPrice: number,
        bedrooms?: number | null,
        bathrooms?: number | null,
        category: string,
        categoryOrder: number,
        tags: Array< ListingItemTag >,
        partialAddresses: Array< string >,
        isManagedByThePM?: boolean | null,
        canBeManagedByThePM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyListingItemsId?: string | null,
    } | null,
};

export type UpdateMarketplacePropertyListingItemMutationVariables = {
    input: UpdateMarketplacePropertyListingItemInput,
    condition?: ModelMarketplacePropertyListingItemConditionInput | null,
};

export type UpdateMarketplacePropertyListingItemMutation = {
    updateMarketplacePropertyListingItem?:  {
        __typename: "MarketplacePropertyListingItem",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        property?:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null,
        status: MarketPlacePropertyStatus,
        actualRent?: number | null,
        estimatedRent?: number | null,
        stateInsuranceFactor?: number | null,
        totalExpenses?: number | null,
        maintenance?: number | null,
        estimatedVacancy?: number | null,
        managementFee?: string | null,
        capRate?: number | null,
        noi?: number | null,
        cashOnCash?: number | null,
        grossYield?: number | null,
        insurance?: number | null,
        askingPrice: number,
        bedrooms?: number | null,
        bathrooms?: number | null,
        category: string,
        categoryOrder: number,
        tags: Array< ListingItemTag >,
        partialAddresses: Array< string >,
        isManagedByThePM?: boolean | null,
        canBeManagedByThePM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyListingItemsId?: string | null,
    } | null,
};

export type DeleteMarketplacePropertyListingItemMutationVariables = {
    input: DeleteMarketplacePropertyListingItemInput,
    condition?: ModelMarketplacePropertyListingItemConditionInput | null,
};

export type DeleteMarketplacePropertyListingItemMutation = {
    deleteMarketplacePropertyListingItem?:  {
        __typename: "MarketplacePropertyListingItem",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        property?:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null,
        status: MarketPlacePropertyStatus,
        actualRent?: number | null,
        estimatedRent?: number | null,
        stateInsuranceFactor?: number | null,
        totalExpenses?: number | null,
        maintenance?: number | null,
        estimatedVacancy?: number | null,
        managementFee?: string | null,
        capRate?: number | null,
        noi?: number | null,
        cashOnCash?: number | null,
        grossYield?: number | null,
        insurance?: number | null,
        askingPrice: number,
        bedrooms?: number | null,
        bathrooms?: number | null,
        category: string,
        categoryOrder: number,
        tags: Array< ListingItemTag >,
        partialAddresses: Array< string >,
        isManagedByThePM?: boolean | null,
        canBeManagedByThePM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyListingItemsId?: string | null,
    } | null,
};

export type CreateMarketplacePropertyPmRecommendationMutationVariables = {
    input: CreateMarketplacePropertyPmRecommendationInput,
    condition?: ModelMarketplacePropertyPmRecommendationConditionInput | null,
};

export type CreateMarketplacePropertyPmRecommendationMutation = {
    createMarketplacePropertyPmRecommendation?:  {
        __typename: "MarketplacePropertyPmRecommendation",
        pm: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateMarketplacePropertyPmRecommendationMutationVariables = {
    input: UpdateMarketplacePropertyPmRecommendationInput,
    condition?: ModelMarketplacePropertyPmRecommendationConditionInput | null,
};

export type UpdateMarketplacePropertyPmRecommendationMutation = {
    updateMarketplacePropertyPmRecommendation?:  {
        __typename: "MarketplacePropertyPmRecommendation",
        pm: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteMarketplacePropertyPmRecommendationMutationVariables = {
    input: DeleteMarketplacePropertyPmRecommendationInput,
    condition?: ModelMarketplacePropertyPmRecommendationConditionInput | null,
};

export type DeleteMarketplacePropertyPmRecommendationMutation = {
    deleteMarketplacePropertyPmRecommendation?:  {
        __typename: "MarketplacePropertyPmRecommendation",
        pm: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateMarketplacePropertyRecommendationMutationVariables = {
    input: CreateMarketplacePropertyRecommendationInput,
    condition?: ModelMarketplacePropertyRecommendationConditionInput | null,
};

export type CreateMarketplacePropertyRecommendationMutation = {
    createMarketplacePropertyRecommendation?:  {
        __typename: "MarketplacePropertyRecommendation",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyRecommendationsId?: string | null,
    } | null,
};

export type UpdateMarketplacePropertyRecommendationMutationVariables = {
    input: UpdateMarketplacePropertyRecommendationInput,
    condition?: ModelMarketplacePropertyRecommendationConditionInput | null,
};

export type UpdateMarketplacePropertyRecommendationMutation = {
    updateMarketplacePropertyRecommendation?:  {
        __typename: "MarketplacePropertyRecommendation",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyRecommendationsId?: string | null,
    } | null,
};

export type DeleteMarketplacePropertyRecommendationMutationVariables = {
    input: DeleteMarketplacePropertyRecommendationInput,
    condition?: ModelMarketplacePropertyRecommendationConditionInput | null,
};

export type DeleteMarketplacePropertyRecommendationMutation = {
    deleteMarketplacePropertyRecommendation?:  {
        __typename: "MarketplacePropertyRecommendation",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyRecommendationsId?: string | null,
    } | null,
};

export type CreateMortgageMutationVariables = {
    input: CreateMortgageInput,
    condition?: ModelMortgageConditionInput | null,
};

export type CreateMortgageMutation = {
    createMortgage?:  {
        __typename: "Mortgage",
        id: string,
        rate: number,
        amount: number,
        startDate: string,
        mortgageDueDay: number,
        term: number,
        owner: string,
        grantor: string,
        apn: string,
        mortgageType: MortgageType,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        provider?: Provider | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyMortgageId?: string | null,
    } | null,
};

export type UpdateMortgageMutationVariables = {
    input: UpdateMortgageInput,
    condition?: ModelMortgageConditionInput | null,
};

export type UpdateMortgageMutation = {
    updateMortgage?:  {
        __typename: "Mortgage",
        id: string,
        rate: number,
        amount: number,
        startDate: string,
        mortgageDueDay: number,
        term: number,
        owner: string,
        grantor: string,
        apn: string,
        mortgageType: MortgageType,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        provider?: Provider | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyMortgageId?: string | null,
    } | null,
};

export type DeleteMortgageMutationVariables = {
    input: DeleteMortgageInput,
    condition?: ModelMortgageConditionInput | null,
};

export type DeleteMortgageMutation = {
    deleteMortgage?:  {
        __typename: "Mortgage",
        id: string,
        rate: number,
        amount: number,
        startDate: string,
        mortgageDueDay: number,
        term: number,
        owner: string,
        grantor: string,
        apn: string,
        mortgageType: MortgageType,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        provider?: Provider | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyMortgageId?: string | null,
    } | null,
};

export type CreateNotificationTemplateMutationVariables = {
    input: CreateNotificationTemplateInput,
    condition?: ModelNotificationTemplateConditionInput | null,
};

export type CreateNotificationTemplateMutation = {
    createNotificationTemplate?:  {
        __typename: "NotificationTemplate",
        id: string,
        template: string,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateNotificationTemplateMutationVariables = {
    input: UpdateNotificationTemplateInput,
    condition?: ModelNotificationTemplateConditionInput | null,
};

export type UpdateNotificationTemplateMutation = {
    updateNotificationTemplate?:  {
        __typename: "NotificationTemplate",
        id: string,
        template: string,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteNotificationTemplateMutationVariables = {
    input: DeleteNotificationTemplateInput,
    condition?: ModelNotificationTemplateConditionInput | null,
};

export type DeleteNotificationTemplateMutation = {
    deleteNotificationTemplate?:  {
        __typename: "NotificationTemplate",
        id: string,
        template: string,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateOfferMutationVariables = {
    input: CreateOfferInput,
    condition?: ModelOfferConditionInput | null,
};

export type CreateOfferMutation = {
    createOffer?:  {
        __typename: "Offer",
        id: string,
        buyer: string,
        seller?: string | null,
        bid?: number | null,
        status: OfferStatus,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyOffersId?: string | null,
    } | null,
};

export type UpdateOfferMutationVariables = {
    input: UpdateOfferInput,
    condition?: ModelOfferConditionInput | null,
};

export type UpdateOfferMutation = {
    updateOffer?:  {
        __typename: "Offer",
        id: string,
        buyer: string,
        seller?: string | null,
        bid?: number | null,
        status: OfferStatus,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyOffersId?: string | null,
    } | null,
};

export type DeleteOfferMutationVariables = {
    input: DeleteOfferInput,
    condition?: ModelOfferConditionInput | null,
};

export type DeleteOfferMutation = {
    deleteOffer?:  {
        __typename: "Offer",
        id: string,
        buyer: string,
        seller?: string | null,
        bid?: number | null,
        status: OfferStatus,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyOffersId?: string | null,
    } | null,
};

export type CreateOwnerLeadMutationVariables = {
    input: CreateOwnerLeadInput,
    condition?: ModelOwnerLeadConditionInput | null,
};

export type CreateOwnerLeadMutation = {
    createOwnerLead?:  {
        __typename: "OwnerLead",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        pm?: string | null,
        requestRentalAnalysis?: boolean | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        numOfProperties?: number | null,
        addresses?: Array< string | null > | null,
        referrer?: string | null,
        source?: OwnerLeadSource | null,
        readPermissions?: Array< string | null > | null,
        ownerLeadWorkflowItem?:  {
            __typename: "ModelOwnerLeadWorkflowItemConnection",
            nextToken?: string | null,
        } | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadId?: string | null,
    } | null,
};

export type UpdateOwnerLeadMutationVariables = {
    input: UpdateOwnerLeadInput,
    condition?: ModelOwnerLeadConditionInput | null,
};

export type UpdateOwnerLeadMutation = {
    updateOwnerLead?:  {
        __typename: "OwnerLead",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        pm?: string | null,
        requestRentalAnalysis?: boolean | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        numOfProperties?: number | null,
        addresses?: Array< string | null > | null,
        referrer?: string | null,
        source?: OwnerLeadSource | null,
        readPermissions?: Array< string | null > | null,
        ownerLeadWorkflowItem?:  {
            __typename: "ModelOwnerLeadWorkflowItemConnection",
            nextToken?: string | null,
        } | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadId?: string | null,
    } | null,
};

export type DeleteOwnerLeadMutationVariables = {
    input: DeleteOwnerLeadInput,
    condition?: ModelOwnerLeadConditionInput | null,
};

export type DeleteOwnerLeadMutation = {
    deleteOwnerLead?:  {
        __typename: "OwnerLead",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        pm?: string | null,
        requestRentalAnalysis?: boolean | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        numOfProperties?: number | null,
        addresses?: Array< string | null > | null,
        referrer?: string | null,
        source?: OwnerLeadSource | null,
        readPermissions?: Array< string | null > | null,
        ownerLeadWorkflowItem?:  {
            __typename: "ModelOwnerLeadWorkflowItemConnection",
            nextToken?: string | null,
        } | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadId?: string | null,
    } | null,
};

export type CreateOwnerLeadReferralLinkMutationVariables = {
    input: CreateOwnerLeadReferralLinkInput,
    condition?: ModelOwnerLeadReferralLinkConditionInput | null,
};

export type CreateOwnerLeadReferralLinkMutation = {
    createOwnerLeadReferralLink?:  {
        __typename: "OwnerLeadReferralLink",
        id: string,
        ownerId: string,
        pm?: string | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadReferralLinkId?: string | null,
    } | null,
};

export type UpdateOwnerLeadReferralLinkMutationVariables = {
    input: UpdateOwnerLeadReferralLinkInput,
    condition?: ModelOwnerLeadReferralLinkConditionInput | null,
};

export type UpdateOwnerLeadReferralLinkMutation = {
    updateOwnerLeadReferralLink?:  {
        __typename: "OwnerLeadReferralLink",
        id: string,
        ownerId: string,
        pm?: string | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadReferralLinkId?: string | null,
    } | null,
};

export type DeleteOwnerLeadReferralLinkMutationVariables = {
    input: DeleteOwnerLeadReferralLinkInput,
    condition?: ModelOwnerLeadReferralLinkConditionInput | null,
};

export type DeleteOwnerLeadReferralLinkMutation = {
    deleteOwnerLeadReferralLink?:  {
        __typename: "OwnerLeadReferralLink",
        id: string,
        ownerId: string,
        pm?: string | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadReferralLinkId?: string | null,
    } | null,
};

export type CreateOwnerLeadWorkflowItemMutationVariables = {
    input: CreateOwnerLeadWorkflowItemInput,
    condition?: ModelOwnerLeadWorkflowItemConditionInput | null,
};

export type CreateOwnerLeadWorkflowItemMutation = {
    createOwnerLeadWorkflowItem?:  {
        __typename: "OwnerLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        pm: string,
        requestRentalAnalysis?: boolean | null,
        numOfProperties?: number | null,
        addresses?: Array< string > | null,
        referrer?: string | null,
        source?: OwnerLeadsWorkflowSource | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        status: OwnerLeadsWorkflowStatus,
        revealed?: boolean | null,
        ownerLead?:  {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        readShareWith?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
        ownerLeadOwnerLeadWorkflowItemId?: string | null,
    } | null,
};

export type UpdateOwnerLeadWorkflowItemMutationVariables = {
    input: UpdateOwnerLeadWorkflowItemInput,
    condition?: ModelOwnerLeadWorkflowItemConditionInput | null,
};

export type UpdateOwnerLeadWorkflowItemMutation = {
    updateOwnerLeadWorkflowItem?:  {
        __typename: "OwnerLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        pm: string,
        requestRentalAnalysis?: boolean | null,
        numOfProperties?: number | null,
        addresses?: Array< string > | null,
        referrer?: string | null,
        source?: OwnerLeadsWorkflowSource | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        status: OwnerLeadsWorkflowStatus,
        revealed?: boolean | null,
        ownerLead?:  {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        readShareWith?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
        ownerLeadOwnerLeadWorkflowItemId?: string | null,
    } | null,
};

export type DeleteOwnerLeadWorkflowItemMutationVariables = {
    input: DeleteOwnerLeadWorkflowItemInput,
    condition?: ModelOwnerLeadWorkflowItemConditionInput | null,
};

export type DeleteOwnerLeadWorkflowItemMutation = {
    deleteOwnerLeadWorkflowItem?:  {
        __typename: "OwnerLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        pm: string,
        requestRentalAnalysis?: boolean | null,
        numOfProperties?: number | null,
        addresses?: Array< string > | null,
        referrer?: string | null,
        source?: OwnerLeadsWorkflowSource | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        status: OwnerLeadsWorkflowStatus,
        revealed?: boolean | null,
        ownerLead?:  {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        readShareWith?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
        ownerLeadOwnerLeadWorkflowItemId?: string | null,
    } | null,
};

export type CreateOwnerMarketplacePropertyRecommendationMutationVariables = {
    input: CreateOwnerMarketplacePropertyRecommendationInput,
    condition?: ModelOwnerMarketplacePropertyRecommendationConditionInput | null,
};

export type CreateOwnerMarketplacePropertyRecommendationMutation = {
    createOwnerMarketplacePropertyRecommendation?:  {
        __typename: "OwnerMarketplacePropertyRecommendation",
        owner: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateOwnerMarketplacePropertyRecommendationMutationVariables = {
    input: UpdateOwnerMarketplacePropertyRecommendationInput,
    condition?: ModelOwnerMarketplacePropertyRecommendationConditionInput | null,
};

export type UpdateOwnerMarketplacePropertyRecommendationMutation = {
    updateOwnerMarketplacePropertyRecommendation?:  {
        __typename: "OwnerMarketplacePropertyRecommendation",
        owner: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteOwnerMarketplacePropertyRecommendationMutationVariables = {
    input: DeleteOwnerMarketplacePropertyRecommendationInput,
    condition?: ModelOwnerMarketplacePropertyRecommendationConditionInput | null,
};

export type DeleteOwnerMarketplacePropertyRecommendationMutation = {
    deleteOwnerMarketplacePropertyRecommendation?:  {
        __typename: "OwnerMarketplacePropertyRecommendation",
        owner: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateOwnerReportMutationVariables = {
    input: CreateOwnerReportInput,
    condition?: ModelOwnerReportConditionInput | null,
};

export type CreateOwnerReportMutation = {
    createOwnerReport?:  {
        __typename: "OwnerReport",
        id: string,
        owner: string,
        month: number,
        year: number,
        reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type UpdateOwnerReportMutationVariables = {
    input: UpdateOwnerReportInput,
    condition?: ModelOwnerReportConditionInput | null,
};

export type UpdateOwnerReportMutation = {
    updateOwnerReport?:  {
        __typename: "OwnerReport",
        id: string,
        owner: string,
        month: number,
        year: number,
        reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type DeleteOwnerReportMutationVariables = {
    input: DeleteOwnerReportInput,
    condition?: ModelOwnerReportConditionInput | null,
};

export type DeleteOwnerReportMutation = {
    deleteOwnerReport?:  {
        __typename: "OwnerReport",
        id: string,
        owner: string,
        month: number,
        year: number,
        reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type CreateOwnerRetentionDetailsMutationVariables = {
    input: CreateOwnerRetentionDetailsInput,
    condition?: ModelOwnerRetentionDetailsConditionInput | null,
};

export type CreateOwnerRetentionDetailsMutation = {
    createOwnerRetentionDetails?:  {
        __typename: "OwnerRetentionDetails",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        satisfactionLevel?: SatisfactionLevel | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRetentionDetailsId?: string | null,
    } | null,
};

export type UpdateOwnerRetentionDetailsMutationVariables = {
    input: UpdateOwnerRetentionDetailsInput,
    condition?: ModelOwnerRetentionDetailsConditionInput | null,
};

export type UpdateOwnerRetentionDetailsMutation = {
    updateOwnerRetentionDetails?:  {
        __typename: "OwnerRetentionDetails",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        satisfactionLevel?: SatisfactionLevel | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRetentionDetailsId?: string | null,
    } | null,
};

export type DeleteOwnerRetentionDetailsMutationVariables = {
    input: DeleteOwnerRetentionDetailsInput,
    condition?: ModelOwnerRetentionDetailsConditionInput | null,
};

export type DeleteOwnerRetentionDetailsMutation = {
    deleteOwnerRetentionDetails?:  {
        __typename: "OwnerRetentionDetails",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        satisfactionLevel?: SatisfactionLevel | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRetentionDetailsId?: string | null,
    } | null,
};

export type CreateOwnerRiskMutationVariables = {
    input: CreateOwnerRiskInput,
    condition?: ModelOwnerRiskConditionInput | null,
};

export type CreateOwnerRiskMutation = {
    createOwnerRisk?:  {
        __typename: "OwnerRisk",
        id: string,
        ownerID: string,
        pm: string,
        ownerSince?: string | null,
        ownerDetails:  {
            __typename: "RiskOwnerDetails",
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        risk?: number | null,
        riskLevel?: RiskLevel | null,
        rawRisk?: number | null,
        scorePerProperty?:  Array< {
            __typename: "PropertyScore",
            propertyID?: string | null,
            score?: number | null,
            rawScore?: number | null,
        } > | null,
        severity: OwnerRiskSeverity,
        severityScore?: number | null,
        severityManualOverride?: boolean | null,
        insights?:  Array< {
            __typename: "OwnerRiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            weight?: number | null,
            value?: number | null,
        } > | null,
        clientValue: number,
        clientValueBreakDown?:  Array< {
            __typename: "ClientValueCategory",
            category?: PMTransactionCategory | null,
            value?: number | null,
        } > | null,
        clientValueGroup: ClientValueGroup,
        status: OwnerRiskStatus,
        resolvedReason?: ResolvedReason | null,
        resolutionExplanation?: string | null,
        inProgressSetDate?: string | null,
        resolvedDate?: string | null,
        statusChangedDate?: string | null,
        recommendations?:  {
            __typename: "ModelOwnerRiskRecommendationConnection",
            nextToken?: string | null,
        } | null,
        ownerRiskTrends?:  {
            __typename: "ModelOwnerRiskTrendConnection",
            nextToken?: string | null,
        } | null,
        ownerRetentionDetails?:  {
            __typename: "ModelOwnerRetentionDetailsConnection",
            nextToken?: string | null,
        } | null,
        ownerRisksInsights?:  {
            __typename: "ModelRiskInsightConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateOwnerRiskMutationVariables = {
    input: UpdateOwnerRiskInput,
    condition?: ModelOwnerRiskConditionInput | null,
};

export type UpdateOwnerRiskMutation = {
    updateOwnerRisk?:  {
        __typename: "OwnerRisk",
        id: string,
        ownerID: string,
        pm: string,
        ownerSince?: string | null,
        ownerDetails:  {
            __typename: "RiskOwnerDetails",
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        risk?: number | null,
        riskLevel?: RiskLevel | null,
        rawRisk?: number | null,
        scorePerProperty?:  Array< {
            __typename: "PropertyScore",
            propertyID?: string | null,
            score?: number | null,
            rawScore?: number | null,
        } > | null,
        severity: OwnerRiskSeverity,
        severityScore?: number | null,
        severityManualOverride?: boolean | null,
        insights?:  Array< {
            __typename: "OwnerRiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            weight?: number | null,
            value?: number | null,
        } > | null,
        clientValue: number,
        clientValueBreakDown?:  Array< {
            __typename: "ClientValueCategory",
            category?: PMTransactionCategory | null,
            value?: number | null,
        } > | null,
        clientValueGroup: ClientValueGroup,
        status: OwnerRiskStatus,
        resolvedReason?: ResolvedReason | null,
        resolutionExplanation?: string | null,
        inProgressSetDate?: string | null,
        resolvedDate?: string | null,
        statusChangedDate?: string | null,
        recommendations?:  {
            __typename: "ModelOwnerRiskRecommendationConnection",
            nextToken?: string | null,
        } | null,
        ownerRiskTrends?:  {
            __typename: "ModelOwnerRiskTrendConnection",
            nextToken?: string | null,
        } | null,
        ownerRetentionDetails?:  {
            __typename: "ModelOwnerRetentionDetailsConnection",
            nextToken?: string | null,
        } | null,
        ownerRisksInsights?:  {
            __typename: "ModelRiskInsightConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteOwnerRiskMutationVariables = {
    input: DeleteOwnerRiskInput,
    condition?: ModelOwnerRiskConditionInput | null,
};

export type DeleteOwnerRiskMutation = {
    deleteOwnerRisk?:  {
        __typename: "OwnerRisk",
        id: string,
        ownerID: string,
        pm: string,
        ownerSince?: string | null,
        ownerDetails:  {
            __typename: "RiskOwnerDetails",
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        risk?: number | null,
        riskLevel?: RiskLevel | null,
        rawRisk?: number | null,
        scorePerProperty?:  Array< {
            __typename: "PropertyScore",
            propertyID?: string | null,
            score?: number | null,
            rawScore?: number | null,
        } > | null,
        severity: OwnerRiskSeverity,
        severityScore?: number | null,
        severityManualOverride?: boolean | null,
        insights?:  Array< {
            __typename: "OwnerRiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            weight?: number | null,
            value?: number | null,
        } > | null,
        clientValue: number,
        clientValueBreakDown?:  Array< {
            __typename: "ClientValueCategory",
            category?: PMTransactionCategory | null,
            value?: number | null,
        } > | null,
        clientValueGroup: ClientValueGroup,
        status: OwnerRiskStatus,
        resolvedReason?: ResolvedReason | null,
        resolutionExplanation?: string | null,
        inProgressSetDate?: string | null,
        resolvedDate?: string | null,
        statusChangedDate?: string | null,
        recommendations?:  {
            __typename: "ModelOwnerRiskRecommendationConnection",
            nextToken?: string | null,
        } | null,
        ownerRiskTrends?:  {
            __typename: "ModelOwnerRiskTrendConnection",
            nextToken?: string | null,
        } | null,
        ownerRetentionDetails?:  {
            __typename: "ModelOwnerRetentionDetailsConnection",
            nextToken?: string | null,
        } | null,
        ownerRisksInsights?:  {
            __typename: "ModelRiskInsightConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateOwnerRiskRecommendationMutationVariables = {
    input: CreateOwnerRiskRecommendationInput,
    condition?: ModelOwnerRiskRecommendationConditionInput | null,
};

export type CreateOwnerRiskRecommendationMutation = {
    createOwnerRiskRecommendation?:  {
        __typename: "OwnerRiskRecommendation",
        id: string,
        pm: string,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        mitigations: Array< OwnerRiskMitigation >,
        userSelectedMitigations?: Array< OwnerRiskMitigation > | null,
        summaryMarkdown: string,
        positivePoints?: Array< string > | null,
        negativePoints?: Array< string > | null,
        emailHTML?: string | null,
        modifiedPM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        ownerRiskRecommendationsId?: string | null,
    } | null,
};

export type UpdateOwnerRiskRecommendationMutationVariables = {
    input: UpdateOwnerRiskRecommendationInput,
    condition?: ModelOwnerRiskRecommendationConditionInput | null,
};

export type UpdateOwnerRiskRecommendationMutation = {
    updateOwnerRiskRecommendation?:  {
        __typename: "OwnerRiskRecommendation",
        id: string,
        pm: string,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        mitigations: Array< OwnerRiskMitigation >,
        userSelectedMitigations?: Array< OwnerRiskMitigation > | null,
        summaryMarkdown: string,
        positivePoints?: Array< string > | null,
        negativePoints?: Array< string > | null,
        emailHTML?: string | null,
        modifiedPM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        ownerRiskRecommendationsId?: string | null,
    } | null,
};

export type DeleteOwnerRiskRecommendationMutationVariables = {
    input: DeleteOwnerRiskRecommendationInput,
    condition?: ModelOwnerRiskRecommendationConditionInput | null,
};

export type DeleteOwnerRiskRecommendationMutation = {
    deleteOwnerRiskRecommendation?:  {
        __typename: "OwnerRiskRecommendation",
        id: string,
        pm: string,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        mitigations: Array< OwnerRiskMitigation >,
        userSelectedMitigations?: Array< OwnerRiskMitigation > | null,
        summaryMarkdown: string,
        positivePoints?: Array< string > | null,
        negativePoints?: Array< string > | null,
        emailHTML?: string | null,
        modifiedPM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        ownerRiskRecommendationsId?: string | null,
    } | null,
};

export type CreateOwnerRiskTrendMutationVariables = {
    input: CreateOwnerRiskTrendInput,
    condition?: ModelOwnerRiskTrendConditionInput | null,
};

export type CreateOwnerRiskTrendMutation = {
    createOwnerRiskTrend?:  {
        __typename: "OwnerRiskTrend",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        risk?: number | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRiskTrendsId?: string | null,
    } | null,
};

export type UpdateOwnerRiskTrendMutationVariables = {
    input: UpdateOwnerRiskTrendInput,
    condition?: ModelOwnerRiskTrendConditionInput | null,
};

export type UpdateOwnerRiskTrendMutation = {
    updateOwnerRiskTrend?:  {
        __typename: "OwnerRiskTrend",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        risk?: number | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRiskTrendsId?: string | null,
    } | null,
};

export type DeleteOwnerRiskTrendMutationVariables = {
    input: DeleteOwnerRiskTrendInput,
    condition?: ModelOwnerRiskTrendConditionInput | null,
};

export type DeleteOwnerRiskTrendMutation = {
    deleteOwnerRiskTrend?:  {
        __typename: "OwnerRiskTrend",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        risk?: number | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRiskTrendsId?: string | null,
    } | null,
};

export type CreatePMMetricsMutationVariables = {
    input: CreatePMMetricsInput,
    condition?: ModelPMMetricsConditionInput | null,
};

export type CreatePMMetricsMutation = {
    createPMMetrics?:  {
        __typename: "PMMetrics",
        id: string,
        usersMetrics?:  {
            __typename: "UsersMetrics",
            activatedUsers: number,
            activatedUsersLastMonth?: number | null,
            invitedUsers: number,
            invitedUsersLastMonth?: number | null,
            createdTime: string,
        } | null,
        communicationMetrics?:  {
            __typename: "CommunicationMetrics",
            emailsSentThisMonth: number,
            createdTime: string,
        } | null,
        propertyMetrics?:  {
            __typename: "PropertyMetrics",
            properties: number,
            units: number,
            createdTime: string,
        } | null,
        riskMetrics?:  {
            __typename: "OwnerRiskMetrics",
            totalNoRisk: number,
            noRiskTotalValue: number,
            totalNeedsAttention: number,
            needsAttentionTotalValue: number,
            totalInProgress: number,
            inProgressTotalValue: number,
            totalResolved: number,
            resolvedTotalValue: number,
            createdTime: string,
        } | null,
        leadMetrics?:  {
            __typename: "LeadMetrics",
            totalReferrals: number,
            totalReferralsValue: number,
            totalBuyerLeads: number,
            totalBuyerLeadsValue: number,
            totalSellerLeads: number,
            totalSellerLeadsValue: number,
            totalProspects: number,
            totalProspectsValue: number,
            totalBuyBoxLeads: number,
            totalBuyBoxLeadsValue: number,
            createdTime: string,
        } | null,
        readPermissions?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdatePMMetricsMutationVariables = {
    input: UpdatePMMetricsInput,
    condition?: ModelPMMetricsConditionInput | null,
};

export type UpdatePMMetricsMutation = {
    updatePMMetrics?:  {
        __typename: "PMMetrics",
        id: string,
        usersMetrics?:  {
            __typename: "UsersMetrics",
            activatedUsers: number,
            activatedUsersLastMonth?: number | null,
            invitedUsers: number,
            invitedUsersLastMonth?: number | null,
            createdTime: string,
        } | null,
        communicationMetrics?:  {
            __typename: "CommunicationMetrics",
            emailsSentThisMonth: number,
            createdTime: string,
        } | null,
        propertyMetrics?:  {
            __typename: "PropertyMetrics",
            properties: number,
            units: number,
            createdTime: string,
        } | null,
        riskMetrics?:  {
            __typename: "OwnerRiskMetrics",
            totalNoRisk: number,
            noRiskTotalValue: number,
            totalNeedsAttention: number,
            needsAttentionTotalValue: number,
            totalInProgress: number,
            inProgressTotalValue: number,
            totalResolved: number,
            resolvedTotalValue: number,
            createdTime: string,
        } | null,
        leadMetrics?:  {
            __typename: "LeadMetrics",
            totalReferrals: number,
            totalReferralsValue: number,
            totalBuyerLeads: number,
            totalBuyerLeadsValue: number,
            totalSellerLeads: number,
            totalSellerLeadsValue: number,
            totalProspects: number,
            totalProspectsValue: number,
            totalBuyBoxLeads: number,
            totalBuyBoxLeadsValue: number,
            createdTime: string,
        } | null,
        readPermissions?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeletePMMetricsMutationVariables = {
    input: DeletePMMetricsInput,
    condition?: ModelPMMetricsConditionInput | null,
};

export type DeletePMMetricsMutation = {
    deletePMMetrics?:  {
        __typename: "PMMetrics",
        id: string,
        usersMetrics?:  {
            __typename: "UsersMetrics",
            activatedUsers: number,
            activatedUsersLastMonth?: number | null,
            invitedUsers: number,
            invitedUsersLastMonth?: number | null,
            createdTime: string,
        } | null,
        communicationMetrics?:  {
            __typename: "CommunicationMetrics",
            emailsSentThisMonth: number,
            createdTime: string,
        } | null,
        propertyMetrics?:  {
            __typename: "PropertyMetrics",
            properties: number,
            units: number,
            createdTime: string,
        } | null,
        riskMetrics?:  {
            __typename: "OwnerRiskMetrics",
            totalNoRisk: number,
            noRiskTotalValue: number,
            totalNeedsAttention: number,
            needsAttentionTotalValue: number,
            totalInProgress: number,
            inProgressTotalValue: number,
            totalResolved: number,
            resolvedTotalValue: number,
            createdTime: string,
        } | null,
        leadMetrics?:  {
            __typename: "LeadMetrics",
            totalReferrals: number,
            totalReferralsValue: number,
            totalBuyerLeads: number,
            totalBuyerLeadsValue: number,
            totalSellerLeads: number,
            totalSellerLeadsValue: number,
            totalProspects: number,
            totalProspectsValue: number,
            totalBuyBoxLeads: number,
            totalBuyBoxLeadsValue: number,
            createdTime: string,
        } | null,
        readPermissions?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreatePMSettingsMutationVariables = {
    input: CreatePMSettingsInput,
    condition?: ModelPMSettingsConditionInput | null,
};

export type CreatePMSettingsMutation = {
    createPMSettings?:  {
        __typename: "PMSettings",
        pm: string,
        leadershipSummary2024?: string | null,
        dashboardProformaMetricsOverride?: string | null,
        showLegalSummary?: boolean | null,
        obpTitleOverride?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdatePMSettingsMutationVariables = {
    input: UpdatePMSettingsInput,
    condition?: ModelPMSettingsConditionInput | null,
};

export type UpdatePMSettingsMutation = {
    updatePMSettings?:  {
        __typename: "PMSettings",
        pm: string,
        leadershipSummary2024?: string | null,
        dashboardProformaMetricsOverride?: string | null,
        showLegalSummary?: boolean | null,
        obpTitleOverride?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeletePMSettingsMutationVariables = {
    input: DeletePMSettingsInput,
    condition?: ModelPMSettingsConditionInput | null,
};

export type DeletePMSettingsMutation = {
    deletePMSettings?:  {
        __typename: "PMSettings",
        pm: string,
        leadershipSummary2024?: string | null,
        dashboardProformaMetricsOverride?: string | null,
        showLegalSummary?: boolean | null,
        obpTitleOverride?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreatePMTransactionMutationVariables = {
    input: CreatePMTransactionInput,
    condition?: ModelPMTransactionConditionInput | null,
};

export type CreatePMTransactionMutation = {
    createPMTransaction?:  {
        __typename: "PMTransaction",
        id: string,
        amount: number,
        transactionDate: string,
        pm: string,
        owner: string,
        type: string,
        category: PMTransactionCategory,
        label?: Label | null,
        description?: string | null,
        dashboardPropertyPmTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdatePMTransactionMutationVariables = {
    input: UpdatePMTransactionInput,
    condition?: ModelPMTransactionConditionInput | null,
};

export type UpdatePMTransactionMutation = {
    updatePMTransaction?:  {
        __typename: "PMTransaction",
        id: string,
        amount: number,
        transactionDate: string,
        pm: string,
        owner: string,
        type: string,
        category: PMTransactionCategory,
        label?: Label | null,
        description?: string | null,
        dashboardPropertyPmTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeletePMTransactionMutationVariables = {
    input: DeletePMTransactionInput,
    condition?: ModelPMTransactionConditionInput | null,
};

export type DeletePMTransactionMutation = {
    deletePMTransaction?:  {
        __typename: "PMTransaction",
        id: string,
        amount: number,
        transactionDate: string,
        pm: string,
        owner: string,
        type: string,
        category: PMTransactionCategory,
        label?: Label | null,
        description?: string | null,
        dashboardPropertyPmTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreatePartnerMutationVariables = {
    input: CreatePartnerInput,
    condition?: ModelPartnerConditionInput | null,
};

export type CreatePartnerMutation = {
    createPartner?:  {
        __typename: "Partner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        partnerType: PartnerType,
        partnerPM?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        referralAmount?: number | null,
        referralFreeMonths?: number | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        address?: string | null,
        website?: string | null,
        experienceInYears?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        ownerLead?:  {
            __typename: "ModelOwnerLeadConnection",
            nextToken?: string | null,
        } | null,
        ownerLeadReferralLink?:  {
            __typename: "ModelOwnerLeadReferralLinkConnection",
            nextToken?: string | null,
        } | null,
        suggestedPartnerPartnerId?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdatePartnerMutationVariables = {
    input: UpdatePartnerInput,
    condition?: ModelPartnerConditionInput | null,
};

export type UpdatePartnerMutation = {
    updatePartner?:  {
        __typename: "Partner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        partnerType: PartnerType,
        partnerPM?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        referralAmount?: number | null,
        referralFreeMonths?: number | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        address?: string | null,
        website?: string | null,
        experienceInYears?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        ownerLead?:  {
            __typename: "ModelOwnerLeadConnection",
            nextToken?: string | null,
        } | null,
        ownerLeadReferralLink?:  {
            __typename: "ModelOwnerLeadReferralLinkConnection",
            nextToken?: string | null,
        } | null,
        suggestedPartnerPartnerId?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeletePartnerMutationVariables = {
    input: DeletePartnerInput,
    condition?: ModelPartnerConditionInput | null,
};

export type DeletePartnerMutation = {
    deletePartner?:  {
        __typename: "Partner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        partnerType: PartnerType,
        partnerPM?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        referralAmount?: number | null,
        referralFreeMonths?: number | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        address?: string | null,
        website?: string | null,
        experienceInYears?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        ownerLead?:  {
            __typename: "ModelOwnerLeadConnection",
            nextToken?: string | null,
        } | null,
        ownerLeadReferralLink?:  {
            __typename: "ModelOwnerLeadReferralLinkConnection",
            nextToken?: string | null,
        } | null,
        suggestedPartnerPartnerId?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreatePmMarketplacePropertyRecommendationMutationVariables = {
    input: CreatePmMarketplacePropertyRecommendationInput,
    condition?: ModelPmMarketplacePropertyRecommendationConditionInput | null,
};

export type CreatePmMarketplacePropertyRecommendationMutation = {
    createPmMarketplacePropertyRecommendation?:  {
        __typename: "PmMarketplacePropertyRecommendation",
        id: string,
        pm: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyPmRecommendationsId?: string | null,
    } | null,
};

export type UpdatePmMarketplacePropertyRecommendationMutationVariables = {
    input: UpdatePmMarketplacePropertyRecommendationInput,
    condition?: ModelPmMarketplacePropertyRecommendationConditionInput | null,
};

export type UpdatePmMarketplacePropertyRecommendationMutation = {
    updatePmMarketplacePropertyRecommendation?:  {
        __typename: "PmMarketplacePropertyRecommendation",
        id: string,
        pm: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyPmRecommendationsId?: string | null,
    } | null,
};

export type DeletePmMarketplacePropertyRecommendationMutationVariables = {
    input: DeletePmMarketplacePropertyRecommendationInput,
    condition?: ModelPmMarketplacePropertyRecommendationConditionInput | null,
};

export type DeletePmMarketplacePropertyRecommendationMutation = {
    deletePmMarketplacePropertyRecommendation?:  {
        __typename: "PmMarketplacePropertyRecommendation",
        id: string,
        pm: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyPmRecommendationsId?: string | null,
    } | null,
};

export type CreatePropertyMutationVariables = {
    input: CreatePropertyInput,
    condition?: ModelPropertyConditionInput | null,
};

export type CreatePropertyMutation = {
    createProperty?:  {
        __typename: "Property",
        id: string,
        propertyType?: PropertyType | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        yearBuilt?: number | null,
        bedrooms?: number | null,
        bathrooms?: number | null,
        garages?: number | null,
        lotSize?: number | null,
        sqft?: number | null,
        isVacant?: boolean | null,
        extendedAddress?:  {
            __typename: "ModelPropertyExtendedAddressConnection",
            nextToken?: string | null,
        } | null,
        units?:  {
            __typename: "ModelUnitConnection",
            nextToken?: string | null,
        } | null,
        owner: string,
        DashboardProperty?:  {
            __typename: "ModelDashboardPropertyConnection",
            nextToken?: string | null,
        } | null,
        MarketplaceProperty?:  {
            __typename: "ModelMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdatePropertyMutationVariables = {
    input: UpdatePropertyInput,
    condition?: ModelPropertyConditionInput | null,
};

export type UpdatePropertyMutation = {
    updateProperty?:  {
        __typename: "Property",
        id: string,
        propertyType?: PropertyType | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        yearBuilt?: number | null,
        bedrooms?: number | null,
        bathrooms?: number | null,
        garages?: number | null,
        lotSize?: number | null,
        sqft?: number | null,
        isVacant?: boolean | null,
        extendedAddress?:  {
            __typename: "ModelPropertyExtendedAddressConnection",
            nextToken?: string | null,
        } | null,
        units?:  {
            __typename: "ModelUnitConnection",
            nextToken?: string | null,
        } | null,
        owner: string,
        DashboardProperty?:  {
            __typename: "ModelDashboardPropertyConnection",
            nextToken?: string | null,
        } | null,
        MarketplaceProperty?:  {
            __typename: "ModelMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeletePropertyMutationVariables = {
    input: DeletePropertyInput,
    condition?: ModelPropertyConditionInput | null,
};

export type DeletePropertyMutation = {
    deleteProperty?:  {
        __typename: "Property",
        id: string,
        propertyType?: PropertyType | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        yearBuilt?: number | null,
        bedrooms?: number | null,
        bathrooms?: number | null,
        garages?: number | null,
        lotSize?: number | null,
        sqft?: number | null,
        isVacant?: boolean | null,
        extendedAddress?:  {
            __typename: "ModelPropertyExtendedAddressConnection",
            nextToken?: string | null,
        } | null,
        units?:  {
            __typename: "ModelUnitConnection",
            nextToken?: string | null,
        } | null,
        owner: string,
        DashboardProperty?:  {
            __typename: "ModelDashboardPropertyConnection",
            nextToken?: string | null,
        } | null,
        MarketplaceProperty?:  {
            __typename: "ModelMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreatePropertyAmortizationTableMutationVariables = {
    input: CreatePropertyAmortizationTableInput,
    condition?: ModelPropertyAmortizationTableConditionInput | null,
};

export type CreatePropertyAmortizationTableMutation = {
    createPropertyAmortizationTable?:  {
        __typename: "PropertyAmortizationTable",
        id: string,
        period: number,
        transactionDate: string,
        owner: string,
        payment: number,
        interest: number,
        principal: number,
        totalPrincipal: number,
        mortgageId: string,
        dashboardPropertyAmortizationTableId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdatePropertyAmortizationTableMutationVariables = {
    input: UpdatePropertyAmortizationTableInput,
    condition?: ModelPropertyAmortizationTableConditionInput | null,
};

export type UpdatePropertyAmortizationTableMutation = {
    updatePropertyAmortizationTable?:  {
        __typename: "PropertyAmortizationTable",
        id: string,
        period: number,
        transactionDate: string,
        owner: string,
        payment: number,
        interest: number,
        principal: number,
        totalPrincipal: number,
        mortgageId: string,
        dashboardPropertyAmortizationTableId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeletePropertyAmortizationTableMutationVariables = {
    input: DeletePropertyAmortizationTableInput,
    condition?: ModelPropertyAmortizationTableConditionInput | null,
};

export type DeletePropertyAmortizationTableMutation = {
    deletePropertyAmortizationTable?:  {
        __typename: "PropertyAmortizationTable",
        id: string,
        period: number,
        transactionDate: string,
        owner: string,
        payment: number,
        interest: number,
        principal: number,
        totalPrincipal: number,
        mortgageId: string,
        dashboardPropertyAmortizationTableId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreatePropertyExtendedAddressMutationVariables = {
    input: CreatePropertyExtendedAddressInput,
    condition?: ModelPropertyExtendedAddressConditionInput | null,
};

export type CreatePropertyExtendedAddressMutation = {
    createPropertyExtendedAddress?:  {
        __typename: "PropertyExtendedAddress",
        id: string,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zipCode?: string | null,
        lat?: number | null,
        lon?: number | null,
        geoid?: string | null,
        fips?: string | null,
        provider?: string | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyExtendedAddressId?: string | null,
    } | null,
};

export type UpdatePropertyExtendedAddressMutationVariables = {
    input: UpdatePropertyExtendedAddressInput,
    condition?: ModelPropertyExtendedAddressConditionInput | null,
};

export type UpdatePropertyExtendedAddressMutation = {
    updatePropertyExtendedAddress?:  {
        __typename: "PropertyExtendedAddress",
        id: string,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zipCode?: string | null,
        lat?: number | null,
        lon?: number | null,
        geoid?: string | null,
        fips?: string | null,
        provider?: string | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyExtendedAddressId?: string | null,
    } | null,
};

export type DeletePropertyExtendedAddressMutationVariables = {
    input: DeletePropertyExtendedAddressInput,
    condition?: ModelPropertyExtendedAddressConditionInput | null,
};

export type DeletePropertyExtendedAddressMutation = {
    deletePropertyExtendedAddress?:  {
        __typename: "PropertyExtendedAddress",
        id: string,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zipCode?: string | null,
        lat?: number | null,
        lon?: number | null,
        geoid?: string | null,
        fips?: string | null,
        provider?: string | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyExtendedAddressId?: string | null,
    } | null,
};

export type CreatePropertyUserExpenseMutationVariables = {
    input: CreatePropertyUserExpenseInput,
    condition?: ModelPropertyUserExpenseConditionInput | null,
};

export type CreatePropertyUserExpenseMutation = {
    createPropertyUserExpense?:  {
        __typename: "PropertyUserExpense",
        id: string,
        amount: number,
        startDate: string,
        category: ExpenseCategory,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyUserExpenseId?: string | null,
    } | null,
};

export type UpdatePropertyUserExpenseMutationVariables = {
    input: UpdatePropertyUserExpenseInput,
    condition?: ModelPropertyUserExpenseConditionInput | null,
};

export type UpdatePropertyUserExpenseMutation = {
    updatePropertyUserExpense?:  {
        __typename: "PropertyUserExpense",
        id: string,
        amount: number,
        startDate: string,
        category: ExpenseCategory,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyUserExpenseId?: string | null,
    } | null,
};

export type DeletePropertyUserExpenseMutationVariables = {
    input: DeletePropertyUserExpenseInput,
    condition?: ModelPropertyUserExpenseConditionInput | null,
};

export type DeletePropertyUserExpenseMutation = {
    deletePropertyUserExpense?:  {
        __typename: "PropertyUserExpense",
        id: string,
        amount: number,
        startDate: string,
        category: ExpenseCategory,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyUserExpenseId?: string | null,
    } | null,
};

export type CreateProspectLeadWorkflowItemMutationVariables = {
    input: CreateProspectLeadWorkflowItemInput,
    condition?: ModelProspectLeadWorkflowItemConditionInput | null,
};

export type CreateProspectLeadWorkflowItemMutation = {
    createProspectLeadWorkflowItem?:  {
        __typename: "ProspectLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        status: ProspectLeadsWorkflowStatus,
        pm: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateProspectLeadWorkflowItemMutationVariables = {
    input: UpdateProspectLeadWorkflowItemInput,
    condition?: ModelProspectLeadWorkflowItemConditionInput | null,
};

export type UpdateProspectLeadWorkflowItemMutation = {
    updateProspectLeadWorkflowItem?:  {
        __typename: "ProspectLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        status: ProspectLeadsWorkflowStatus,
        pm: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteProspectLeadWorkflowItemMutationVariables = {
    input: DeleteProspectLeadWorkflowItemInput,
    condition?: ModelProspectLeadWorkflowItemConditionInput | null,
};

export type DeleteProspectLeadWorkflowItemMutation = {
    deleteProspectLeadWorkflowItem?:  {
        __typename: "ProspectLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        status: ProspectLeadsWorkflowStatus,
        pm: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateReportMutationVariables = {
    input: CreateReportInput,
    condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
    createReport?:  {
        __typename: "Report",
        id: string,
        owner: string,
        name: string,
        version: number,
        totalOccupiedUnits: number,
        totalUnits: number,
        homeValue: number,
        appreciation: number,
        totalOtherIncome?: number | null,
        totalRent: number,
        totalEquity: number,
        noi: number,
        netCashFlow: number,
        ownerReport:  {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        },
        expenses:  {
            __typename: "Expenses",
            hoa: number,
            insurance: number,
            managementFee: number,
            other: number,
            tax: number,
            mortgage: number,
            utilities?: number | null,
            legalAndProfessionalFees?: number | null,
            rentalRegistration?: number | null,
            autoAndTravel?: number | null,
            supplies?: number | null,
            maintenance?: number | null,
            leasingAndMarketing?: number | null,
            totalExpense: number,
        },
        previousMonthlyMeasures:  {
            __typename: "PreviousMonthlyMeasures",
            homeValue: number,
            appreciation: number,
            totalEquity: number,
        },
        yearlyMeasures:  {
            __typename: "YearlyMeasures",
            capRate: number,
            cashOnCash: number,
        },
        reportType: ReportType,
        createdAt: string,
        updatedAt: string,
        ownerReportReportsId?: string | null,
    } | null,
};

export type UpdateReportMutationVariables = {
    input: UpdateReportInput,
    condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
    updateReport?:  {
        __typename: "Report",
        id: string,
        owner: string,
        name: string,
        version: number,
        totalOccupiedUnits: number,
        totalUnits: number,
        homeValue: number,
        appreciation: number,
        totalOtherIncome?: number | null,
        totalRent: number,
        totalEquity: number,
        noi: number,
        netCashFlow: number,
        ownerReport:  {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        },
        expenses:  {
            __typename: "Expenses",
            hoa: number,
            insurance: number,
            managementFee: number,
            other: number,
            tax: number,
            mortgage: number,
            utilities?: number | null,
            legalAndProfessionalFees?: number | null,
            rentalRegistration?: number | null,
            autoAndTravel?: number | null,
            supplies?: number | null,
            maintenance?: number | null,
            leasingAndMarketing?: number | null,
            totalExpense: number,
        },
        previousMonthlyMeasures:  {
            __typename: "PreviousMonthlyMeasures",
            homeValue: number,
            appreciation: number,
            totalEquity: number,
        },
        yearlyMeasures:  {
            __typename: "YearlyMeasures",
            capRate: number,
            cashOnCash: number,
        },
        reportType: ReportType,
        createdAt: string,
        updatedAt: string,
        ownerReportReportsId?: string | null,
    } | null,
};

export type DeleteReportMutationVariables = {
    input: DeleteReportInput,
    condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
    deleteReport?:  {
        __typename: "Report",
        id: string,
        owner: string,
        name: string,
        version: number,
        totalOccupiedUnits: number,
        totalUnits: number,
        homeValue: number,
        appreciation: number,
        totalOtherIncome?: number | null,
        totalRent: number,
        totalEquity: number,
        noi: number,
        netCashFlow: number,
        ownerReport:  {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        },
        expenses:  {
            __typename: "Expenses",
            hoa: number,
            insurance: number,
            managementFee: number,
            other: number,
            tax: number,
            mortgage: number,
            utilities?: number | null,
            legalAndProfessionalFees?: number | null,
            rentalRegistration?: number | null,
            autoAndTravel?: number | null,
            supplies?: number | null,
            maintenance?: number | null,
            leasingAndMarketing?: number | null,
            totalExpense: number,
        },
        previousMonthlyMeasures:  {
            __typename: "PreviousMonthlyMeasures",
            homeValue: number,
            appreciation: number,
            totalEquity: number,
        },
        yearlyMeasures:  {
            __typename: "YearlyMeasures",
            capRate: number,
            cashOnCash: number,
        },
        reportType: ReportType,
        createdAt: string,
        updatedAt: string,
        ownerReportReportsId?: string | null,
    } | null,
};

export type CreateRetentionPolicyMutationVariables = {
    input: CreateRetentionPolicyInput,
    condition?: ModelRetentionPolicyConditionInput | null,
};

export type CreateRetentionPolicyMutation = {
    createRetentionPolicy?:  {
        __typename: "RetentionPolicy",
        id: string,
        readPermissions: Array< string | null >,
        updatePermissions: Array< string | null >,
        pm: string,
        maxFinancialIncentive: MaxFinancialIncentive,
        giveIncentiveWillingness: Willingness,
        meetWillingness: Willingness,
        badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
        goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
        writingStyles: Array< WritingStyle >,
        riskDetectionSensitivity: RiskDetectionSensitivity,
        summaryLength: SummaryLength,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type UpdateRetentionPolicyMutationVariables = {
    input: UpdateRetentionPolicyInput,
    condition?: ModelRetentionPolicyConditionInput | null,
};

export type UpdateRetentionPolicyMutation = {
    updateRetentionPolicy?:  {
        __typename: "RetentionPolicy",
        id: string,
        readPermissions: Array< string | null >,
        updatePermissions: Array< string | null >,
        pm: string,
        maxFinancialIncentive: MaxFinancialIncentive,
        giveIncentiveWillingness: Willingness,
        meetWillingness: Willingness,
        badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
        goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
        writingStyles: Array< WritingStyle >,
        riskDetectionSensitivity: RiskDetectionSensitivity,
        summaryLength: SummaryLength,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type DeleteRetentionPolicyMutationVariables = {
    input: DeleteRetentionPolicyInput,
    condition?: ModelRetentionPolicyConditionInput | null,
};

export type DeleteRetentionPolicyMutation = {
    deleteRetentionPolicy?:  {
        __typename: "RetentionPolicy",
        id: string,
        readPermissions: Array< string | null >,
        updatePermissions: Array< string | null >,
        pm: string,
        maxFinancialIncentive: MaxFinancialIncentive,
        giveIncentiveWillingness: Willingness,
        meetWillingness: Willingness,
        badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
        goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
        writingStyles: Array< WritingStyle >,
        riskDetectionSensitivity: RiskDetectionSensitivity,
        summaryLength: SummaryLength,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type CreateRiskInsightMutationVariables = {
    input: CreateRiskInsightInput,
    condition?: ModelRiskInsightConditionInput | null,
};

export type CreateRiskInsightMutation = {
    createRiskInsight?:  {
        __typename: "RiskInsight",
        id: string,
        caseName?: string | null,
        propertyID?: string | null,
        ownerEmail?: string | null,
        level: InsightLevel,
        context?:  Array< {
            __typename: "InsightContext",
            Key?: string | null,
            Value?: number | null,
        } > | null,
        weight?: number | null,
        value?: number | null,
        insightType?: InsightType | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        quarters?:  Array< {
            __typename: "OwnerRiskQuarterInsight",
            startDate: string,
            endDate?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            value?: number | null,
            quarter?: number | null,
        } > | null,
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRisksInsightsId?: string | null,
    } | null,
};

export type UpdateRiskInsightMutationVariables = {
    input: UpdateRiskInsightInput,
    condition?: ModelRiskInsightConditionInput | null,
};

export type UpdateRiskInsightMutation = {
    updateRiskInsight?:  {
        __typename: "RiskInsight",
        id: string,
        caseName?: string | null,
        propertyID?: string | null,
        ownerEmail?: string | null,
        level: InsightLevel,
        context?:  Array< {
            __typename: "InsightContext",
            Key?: string | null,
            Value?: number | null,
        } > | null,
        weight?: number | null,
        value?: number | null,
        insightType?: InsightType | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        quarters?:  Array< {
            __typename: "OwnerRiskQuarterInsight",
            startDate: string,
            endDate?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            value?: number | null,
            quarter?: number | null,
        } > | null,
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRisksInsightsId?: string | null,
    } | null,
};

export type DeleteRiskInsightMutationVariables = {
    input: DeleteRiskInsightInput,
    condition?: ModelRiskInsightConditionInput | null,
};

export type DeleteRiskInsightMutation = {
    deleteRiskInsight?:  {
        __typename: "RiskInsight",
        id: string,
        caseName?: string | null,
        propertyID?: string | null,
        ownerEmail?: string | null,
        level: InsightLevel,
        context?:  Array< {
            __typename: "InsightContext",
            Key?: string | null,
            Value?: number | null,
        } > | null,
        weight?: number | null,
        value?: number | null,
        insightType?: InsightType | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        quarters?:  Array< {
            __typename: "OwnerRiskQuarterInsight",
            startDate: string,
            endDate?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            value?: number | null,
            quarter?: number | null,
        } > | null,
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRisksInsightsId?: string | null,
    } | null,
};

export type CreateSellerLeadWorkflowItemMutationVariables = {
    input: CreateSellerLeadWorkflowItemInput,
    condition?: ModelSellerLeadWorkflowItemConditionInput | null,
};

export type CreateSellerLeadWorkflowItemMutation = {
    createSellerLeadWorkflowItem?:  {
        __typename: "SellerLeadWorkflowItem",
        id: string,
        sellerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId?: string | null,
        askingPrice: number,
        isPropertyVisible?: boolean | null,
        status: SellerLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateSellerLeadWorkflowItemMutationVariables = {
    input: UpdateSellerLeadWorkflowItemInput,
    condition?: ModelSellerLeadWorkflowItemConditionInput | null,
};

export type UpdateSellerLeadWorkflowItemMutation = {
    updateSellerLeadWorkflowItem?:  {
        __typename: "SellerLeadWorkflowItem",
        id: string,
        sellerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId?: string | null,
        askingPrice: number,
        isPropertyVisible?: boolean | null,
        status: SellerLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteSellerLeadWorkflowItemMutationVariables = {
    input: DeleteSellerLeadWorkflowItemInput,
    condition?: ModelSellerLeadWorkflowItemConditionInput | null,
};

export type DeleteSellerLeadWorkflowItemMutation = {
    deleteSellerLeadWorkflowItem?:  {
        __typename: "SellerLeadWorkflowItem",
        id: string,
        sellerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId?: string | null,
        askingPrice: number,
        isPropertyVisible?: boolean | null,
        status: SellerLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateStateMutationVariables = {
    input: CreateStateInput,
    condition?: ModelStateConditionInput | null,
};

export type CreateStateMutation = {
    createState?:  {
        __typename: "State",
        id: string,
        insuranceFactor?: number | null,
        name: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateStateMutationVariables = {
    input: UpdateStateInput,
    condition?: ModelStateConditionInput | null,
};

export type UpdateStateMutation = {
    updateState?:  {
        __typename: "State",
        id: string,
        insuranceFactor?: number | null,
        name: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteStateMutationVariables = {
    input: DeleteStateInput,
    condition?: ModelStateConditionInput | null,
};

export type DeleteStateMutation = {
    deleteState?:  {
        __typename: "State",
        id: string,
        insuranceFactor?: number | null,
        name: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateSuggestedPartnerMutationVariables = {
    input: CreateSuggestedPartnerInput,
    condition?: ModelSuggestedPartnerConditionInput | null,
};

export type CreateSuggestedPartnerMutation = {
    createSuggestedPartner?:  {
        __typename: "SuggestedPartner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        status: SuggestedPartnerStatus,
        suggestedPartnerType: SuggestedPartnerType,
        suggestedPartnerPM?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        phoneNumbers?:  Array< {
            __typename: "PhoneNumber",
            number?: string | null,
            phoneType?: PhoneType | null,
        } > | null,
        avatar?: string | null,
        rating?: number | null,
        officeName?: string | null,
        address?: string | null,
        website?: string | null,
        last12MonthsSales?: number | null,
        totalSales?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        totalSalesCount?: number | null,
        totalForSaleCount?: number | null,
        lastTwoYearsSalesCount?: number | null,
        averageSale?: number | null,
        experienceInYears?: number | null,
        description?: string | null,
        profiles?:  Array< {
            __typename: "Profile",
            link?: string | null,
            profileType?: ProfileType | null,
        } > | null,
        sources?: Array< Source > | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        relevancy?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        contactEmailBody?: string | null,
        contactEmailSubject?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateSuggestedPartnerMutationVariables = {
    input: UpdateSuggestedPartnerInput,
    condition?: ModelSuggestedPartnerConditionInput | null,
};

export type UpdateSuggestedPartnerMutation = {
    updateSuggestedPartner?:  {
        __typename: "SuggestedPartner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        status: SuggestedPartnerStatus,
        suggestedPartnerType: SuggestedPartnerType,
        suggestedPartnerPM?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        phoneNumbers?:  Array< {
            __typename: "PhoneNumber",
            number?: string | null,
            phoneType?: PhoneType | null,
        } > | null,
        avatar?: string | null,
        rating?: number | null,
        officeName?: string | null,
        address?: string | null,
        website?: string | null,
        last12MonthsSales?: number | null,
        totalSales?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        totalSalesCount?: number | null,
        totalForSaleCount?: number | null,
        lastTwoYearsSalesCount?: number | null,
        averageSale?: number | null,
        experienceInYears?: number | null,
        description?: string | null,
        profiles?:  Array< {
            __typename: "Profile",
            link?: string | null,
            profileType?: ProfileType | null,
        } > | null,
        sources?: Array< Source > | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        relevancy?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        contactEmailBody?: string | null,
        contactEmailSubject?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteSuggestedPartnerMutationVariables = {
    input: DeleteSuggestedPartnerInput,
    condition?: ModelSuggestedPartnerConditionInput | null,
};

export type DeleteSuggestedPartnerMutation = {
    deleteSuggestedPartner?:  {
        __typename: "SuggestedPartner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        status: SuggestedPartnerStatus,
        suggestedPartnerType: SuggestedPartnerType,
        suggestedPartnerPM?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        phoneNumbers?:  Array< {
            __typename: "PhoneNumber",
            number?: string | null,
            phoneType?: PhoneType | null,
        } > | null,
        avatar?: string | null,
        rating?: number | null,
        officeName?: string | null,
        address?: string | null,
        website?: string | null,
        last12MonthsSales?: number | null,
        totalSales?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        totalSalesCount?: number | null,
        totalForSaleCount?: number | null,
        lastTwoYearsSalesCount?: number | null,
        averageSale?: number | null,
        experienceInYears?: number | null,
        description?: string | null,
        profiles?:  Array< {
            __typename: "Profile",
            link?: string | null,
            profileType?: ProfileType | null,
        } > | null,
        sources?: Array< Source > | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        relevancy?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        contactEmailBody?: string | null,
        contactEmailSubject?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateTransactionMutationVariables = {
    input: CreateTransactionInput,
    condition?: ModelTransactionConditionInput | null,
};

export type CreateTransactionMutation = {
    createTransaction?:  {
        __typename: "Transaction",
        id: string,
        amount: number,
        transactionDate: string,
        owner: string,
        type: string,
        category: Category,
        label?: Label | null,
        description?: string | null,
        isEditable?: boolean | null,
        readPermissions?: Array< string | null > | null,
        dashboardPropertyTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateTransactionMutationVariables = {
    input: UpdateTransactionInput,
    condition?: ModelTransactionConditionInput | null,
};

export type UpdateTransactionMutation = {
    updateTransaction?:  {
        __typename: "Transaction",
        id: string,
        amount: number,
        transactionDate: string,
        owner: string,
        type: string,
        category: Category,
        label?: Label | null,
        description?: string | null,
        isEditable?: boolean | null,
        readPermissions?: Array< string | null > | null,
        dashboardPropertyTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteTransactionMutationVariables = {
    input: DeleteTransactionInput,
    condition?: ModelTransactionConditionInput | null,
};

export type DeleteTransactionMutation = {
    deleteTransaction?:  {
        __typename: "Transaction",
        id: string,
        amount: number,
        transactionDate: string,
        owner: string,
        type: string,
        category: Category,
        label?: Label | null,
        description?: string | null,
        isEditable?: boolean | null,
        readPermissions?: Array< string | null > | null,
        dashboardPropertyTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type CreateUnitMutationVariables = {
    input: CreateUnitInput,
    condition?: ModelUnitConditionInput | null,
};

export type CreateUnitMutation = {
    createUnit?:  {
        __typename: "Unit",
        id: string,
        bedrooms?: number | null,
        bathrooms?: number | null,
        sqft?: number | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        isVacant?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyUnitsId?: string | null,
    } | null,
};

export type UpdateUnitMutationVariables = {
    input: UpdateUnitInput,
    condition?: ModelUnitConditionInput | null,
};

export type UpdateUnitMutation = {
    updateUnit?:  {
        __typename: "Unit",
        id: string,
        bedrooms?: number | null,
        bathrooms?: number | null,
        sqft?: number | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        isVacant?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyUnitsId?: string | null,
    } | null,
};

export type DeleteUnitMutationVariables = {
    input: DeleteUnitInput,
    condition?: ModelUnitConditionInput | null,
};

export type DeleteUnitMutation = {
    deleteUnit?:  {
        __typename: "Unit",
        id: string,
        bedrooms?: number | null,
        bathrooms?: number | null,
        sqft?: number | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        isVacant?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyUnitsId?: string | null,
    } | null,
};

export type CreateUserActionsMutationVariables = {
    input: CreateUserActionsInput,
    condition?: ModelUserActionsConditionInput | null,
};

export type CreateUserActionsMutation = {
    createUserActions?:  {
        __typename: "UserActions",
        owner: string,
        pm: string,
        viewedWelcomeToMarketplaceScreen?: boolean | null,
        viewedCashOutTooltip?: boolean | null,
        dashboardProformaOverride?: string | null,
        viewedAnnualReportBanner?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type UpdateUserActionsMutationVariables = {
    input: UpdateUserActionsInput,
    condition?: ModelUserActionsConditionInput | null,
};

export type UpdateUserActionsMutation = {
    updateUserActions?:  {
        __typename: "UserActions",
        owner: string,
        pm: string,
        viewedWelcomeToMarketplaceScreen?: boolean | null,
        viewedCashOutTooltip?: boolean | null,
        dashboardProformaOverride?: string | null,
        viewedAnnualReportBanner?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type DeleteUserActionsMutationVariables = {
    input: DeleteUserActionsInput,
    condition?: ModelUserActionsConditionInput | null,
};

export type DeleteUserActionsMutation = {
    deleteUserActions?:  {
        __typename: "UserActions",
        owner: string,
        pm: string,
        viewedWelcomeToMarketplaceScreen?: boolean | null,
        viewedCashOutTooltip?: boolean | null,
        dashboardProformaOverride?: string | null,
        viewedAnnualReportBanner?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type GetAppSettingsQueryVariables = {
    id: string,
};

export type GetAppSettingsQuery = {
    getAppSettings?:  {
        __typename: "AppSettings",
        id: string,
        iamDomain?: string | null,
        iamClientId?: string | null,
        iamAudience?: string | null,
        title?: string | null,
        tenant?: string | null,
        pmName?: string | null,
        color?: string | null,
        logo?: string | null,
        favicon?: string | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type ListAppSettingsQueryVariables = {
    id?: string | null,
    filter?: ModelAppSettingsFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListAppSettingsQuery = {
    listAppSettings?:  {
        __typename: "ModelAppSettingsConnection",
        items:  Array< {
            __typename: "AppSettings",
            id: string,
            iamDomain?: string | null,
            iamClientId?: string | null,
            iamAudience?: string | null,
            title?: string | null,
            tenant?: string | null,
            pmName?: string | null,
            color?: string | null,
            logo?: string | null,
            favicon?: string | null,
            createdAt: string,
            updatedAt: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetBuyBoxLeadWorkflowItemQueryVariables = {
    id: string,
};

export type GetBuyBoxLeadWorkflowItemQuery = {
    getBuyBoxLeadWorkflowItem?:  {
        __typename: "BuyBoxLeadWorkflowItem",
        id: string,
        leadContactDetails?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        timeline?: string | null,
        amount?: string | null,
        propertyType?: string | null,
        financing?: string | null,
        status: BuyBoxLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListBuyBoxLeadWorkflowItemsQueryVariables = {
    id?: string | null,
    filter?: ModelBuyBoxLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListBuyBoxLeadWorkflowItemsQuery = {
    listBuyBoxLeadWorkflowItems?:  {
        __typename: "ModelBuyBoxLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "BuyBoxLeadWorkflowItem",
            id: string,
            timeline?: string | null,
            amount?: string | null,
            propertyType?: string | null,
            financing?: string | null,
            status: BuyBoxLeadsWorkflowStatus,
            pm: string,
            statusChangeTime: string,
            readShareWith?: Array< string > | null,
            updateShareWith?: Array< string > | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            revealed?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListBuyBoxLeadWorkflowItemsByPmQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelBuyBoxLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListBuyBoxLeadWorkflowItemsByPmQuery = {
    listBuyBoxLeadWorkflowItemsByPm?:  {
        __typename: "ModelBuyBoxLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "BuyBoxLeadWorkflowItem",
            id: string,
            timeline?: string | null,
            amount?: string | null,
            propertyType?: string | null,
            financing?: string | null,
            status: BuyBoxLeadsWorkflowStatus,
            pm: string,
            statusChangeTime: string,
            readShareWith?: Array< string > | null,
            updateShareWith?: Array< string > | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            revealed?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetBuyerLeadWorkflowItemQueryVariables = {
    id: string,
};

export type GetBuyerLeadWorkflowItemQuery = {
    getBuyerLeadWorkflowItem?:  {
        __typename: "BuyerLeadWorkflowItem",
        id: string,
        buyerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        sellerAgentContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        askingPrice: number,
        pm: string,
        statusChangeTime: string,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId: string,
        isPropertyVisible?: boolean | null,
        status: BuyerLeadsWorkflowStatus,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListBuyerLeadWorkflowItemsQueryVariables = {
    id?: string | null,
    filter?: ModelBuyerLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListBuyerLeadWorkflowItemsQuery = {
    listBuyerLeadWorkflowItems?:  {
        __typename: "ModelBuyerLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "BuyerLeadWorkflowItem",
            id: string,
            askingPrice: number,
            pm: string,
            statusChangeTime: string,
            propertyId: string,
            isPropertyVisible?: boolean | null,
            status: BuyerLeadsWorkflowStatus,
            readShareWith?: Array< string > | null,
            updateShareWith?: Array< string > | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            revealed?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListBuyerLeadWorkflowItemsByPmQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelBuyerLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListBuyerLeadWorkflowItemsByPmQuery = {
    listBuyerLeadWorkflowItemsByPm?:  {
        __typename: "ModelBuyerLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "BuyerLeadWorkflowItem",
            id: string,
            askingPrice: number,
            pm: string,
            statusChangeTime: string,
            propertyId: string,
            isPropertyVisible?: boolean | null,
            status: BuyerLeadsWorkflowStatus,
            readShareWith?: Array< string > | null,
            updateShareWith?: Array< string > | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            revealed?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListCrimeQueryVariables = {
    id?: string | null,
    filter?: ModelCrimeFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListCrimeQuery = {
    listCrime?:  {
        __typename: "ModelCrimeConnection",
        items:  Array< {
            __typename: "Crime",
            id: string,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyCrimeId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetDashboardPropertyQueryVariables = {
    id: string,
};

export type GetDashboardPropertyQuery = {
    getDashboardProperty?:  {
        __typename: "DashboardProperty",
        id: string,
        owner: string,
        name: string,
        transactions?:  {
            __typename: "ModelTransactionConnection",
            nextToken?: string | null,
        } | null,
        pmTransactions?:  {
            __typename: "ModelPMTransactionConnection",
            nextToken?: string | null,
        } | null,
        leases?:  {
            __typename: "ModelLeaseConnection",
            nextToken?: string | null,
        } | null,
        userExpense?:  {
            __typename: "ModelPropertyUserExpenseConnection",
            nextToken?: string | null,
        } | null,
        amortizationTable?:  {
            __typename: "ModelPropertyAmortizationTableConnection",
            nextToken?: string | null,
        } | null,
        mortgage?:  {
            __typename: "ModelMortgageConnection",
            nextToken?: string | null,
        } | null,
        propertyValuation?:  {
            __typename: "ModelDashboardPropertyValuationConnection",
            nextToken?: string | null,
        } | null,
        purchaseHistory?:  {
            __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
            nextToken?: string | null,
        } | null,
        metrics?:  {
            __typename: "ModelDashboardPropertyMetricConnection",
            nextToken?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        hoaMuted?: boolean | null,
        pmId: string,
        readPermissions?: Array< string | null > | null,
        deleted?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyDashboardPropertyId?: string | null,
    } | null,
};

export type ListDashboardPropertiesQueryVariables = {
    id?: string | null,
    filter?: ModelDashboardPropertyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListDashboardPropertiesQuery = {
    listDashboardProperties?:  {
        __typename: "ModelDashboardPropertyConnection",
        items:  Array< {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListDashboardPropertyByOwnerQueryVariables = {
    owner: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelDashboardPropertyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListDashboardPropertyByOwnerQuery = {
    listDashboardPropertyByOwner?:  {
        __typename: "ModelDashboardPropertyConnection",
        items:  Array< {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListDashboardPropertiesByPmQueryVariables = {
    pmId: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelDashboardPropertyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListDashboardPropertiesByPmQuery = {
    listDashboardPropertiesByPm?:  {
        __typename: "ModelDashboardPropertyConnection",
        items:  Array< {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListDashboardPropertyMetricQueryVariables = {
    id?: string | null,
    filter?: ModelDashboardPropertyMetricFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListDashboardPropertyMetricQuery = {
    listDashboardPropertyMetric?:  {
        __typename: "ModelDashboardPropertyMetricConnection",
        items:  Array< {
            __typename: "DashboardPropertyMetric",
            id: string,
            NOI?: number | null,
            cashOnCash?: number | null,
            capRate?: number | null,
            cashFlow?: number | null,
            annualIncome?: number | null,
            annualExpense?: number | null,
            cashInvested?: number | null,
            isDataPartial?: boolean | null,
            currentVacancy?: number | null,
            turnoverTenants?: number | null,
            totalUniqTenants?: number | null,
            monthsToEndLease?: number | null,
            monthsFromStartLease?: number | null,
            leaseTerm?: string | null,
            appreciation?: number | null,
            valuation?: number | null,
            totalEquity?: number | null,
            missingData?: Array< MissingData | null > | null,
            monthsFromTheFirstTransaction?: number | null,
            firstTransactionDate?: string | null,
            purchasePrice?: number | null,
            purchaseDate?: string | null,
            dashboardPropertyMetricsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListDashboardPropertyMetricsByPropertyIDQueryVariables = {
    dashboardPropertyMetricsId: string,
    createdTime?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelDashboardPropertyMetricFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListDashboardPropertyMetricsByPropertyIDQuery = {
    listDashboardPropertyMetricsByPropertyID?:  {
        __typename: "ModelDashboardPropertyMetricConnection",
        items:  Array< {
            __typename: "DashboardPropertyMetric",
            id: string,
            NOI?: number | null,
            cashOnCash?: number | null,
            capRate?: number | null,
            cashFlow?: number | null,
            annualIncome?: number | null,
            annualExpense?: number | null,
            cashInvested?: number | null,
            isDataPartial?: boolean | null,
            currentVacancy?: number | null,
            turnoverTenants?: number | null,
            totalUniqTenants?: number | null,
            monthsToEndLease?: number | null,
            monthsFromStartLease?: number | null,
            leaseTerm?: string | null,
            appreciation?: number | null,
            valuation?: number | null,
            totalEquity?: number | null,
            missingData?: Array< MissingData | null > | null,
            monthsFromTheFirstTransaction?: number | null,
            firstTransactionDate?: string | null,
            purchasePrice?: number | null,
            purchaseDate?: string | null,
            dashboardPropertyMetricsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListDashboardPropertyPurchaseHistoriesQueryVariables = {
    id?: string | null,
    filter?: ModelDashboardPropertyPurchaseHistoryFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListDashboardPropertyPurchaseHistoriesQuery = {
    listDashboardPropertyPurchaseHistories?:  {
        __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
        items:  Array< {
            __typename: "DashboardPropertyPurchaseHistory",
            id: string,
            amount: number,
            closingCost?: number | null,
            homeImprovementCost?: number | null,
            date?: string | null,
            provider?: Provider | null,
            createdTime: string,
            updatedTime: string,
            dashboardPropertyPurchaseHistoryId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListDashboardPropertyValuationsQueryVariables = {
    id?: string | null,
    filter?: ModelDashboardPropertyValuationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListDashboardPropertyValuationsQuery = {
    listDashboardPropertyValuations?:  {
        __typename: "ModelDashboardPropertyValuationConnection",
        items:  Array< {
            __typename: "DashboardPropertyValuation",
            id: string,
            date: string,
            priceUpper?: number | null,
            priceLower?: number | null,
            priceMean?: number | null,
            fsd?: number | null,
            provider?: Provider | null,
            pm?: string | null,
            dashboardPropertyPropertyValuationId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListDashboardPropertyValuationByPropertyIdQueryVariables = {
    dashboardPropertyPropertyValuationId: string,
    date?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelDashboardPropertyValuationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListDashboardPropertyValuationByPropertyIdQuery = {
    listDashboardPropertyValuationByPropertyId?:  {
        __typename: "ModelDashboardPropertyValuationConnection",
        items:  Array< {
            __typename: "DashboardPropertyValuation",
            id: string,
            date: string,
            priceUpper?: number | null,
            priceLower?: number | null,
            priceMean?: number | null,
            fsd?: number | null,
            provider?: Provider | null,
            pm?: string | null,
            dashboardPropertyPropertyValuationId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetFavoriteMarketplacePropertyQueryVariables = {
    id: string,
};

export type GetFavoriteMarketplacePropertyQuery = {
    getFavoriteMarketplaceProperty?:  {
        __typename: "FavoriteMarketplaceProperty",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyFavoritesId?: string | null,
    } | null,
};

export type ListFavoriteMarketplacePropertiesQueryVariables = {
    id?: string | null,
    filter?: ModelFavoriteMarketplacePropertyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListFavoriteMarketplacePropertiesQuery = {
    listFavoriteMarketplaceProperties?:  {
        __typename: "ModelFavoriteMarketplacePropertyConnection",
        items:  Array< {
            __typename: "FavoriteMarketplaceProperty",
            id: string,
            owner: string,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyFavoritesId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListFavoritesByOwnerQueryVariables = {
    owner: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelFavoriteMarketplacePropertyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListFavoritesByOwnerQuery = {
    listFavoritesByOwner?:  {
        __typename: "ModelFavoriteMarketplacePropertyConnection",
        items:  Array< {
            __typename: "FavoriteMarketplaceProperty",
            id: string,
            owner: string,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyFavoritesId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetGrowthPolicyQueryVariables = {
    id: string,
};

export type GetGrowthPolicyQuery = {
    getGrowthPolicy?:  {
        __typename: "GrowthPolicy",
        id: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        agentReferralFeeAmount?: number | null,
        agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
        agentProvidedBenefitsOther?: string | null,
        enableOwnerReferralProgram?: boolean | null,
        ownerReferralsIncentive?: OwnerReferralsIncentive | null,
        enablePMReferralProgram?: boolean | null,
        pmReferralsFeeAmount?: number | null,
        typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        yearsInBusiness?: number | null,
        website?: string | null,
        companyDescription?: string | null,
        pmAvatarURL?: string | null,
        pmFirstName?: string | null,
        pmLastName?: string | null,
        pmTitle?: string | null,
        companyGoogleReviews?: number | null,
        companyAVGDayOnMarket?: number | null,
        companyTenantRenewalRate?: number | null,
        companyServices?: Array< CompanyServices > | null,
        companyServicesOther?: string | null,
        companyGuarantees?: Array< CompanyGuarantees > | null,
        companyGuaranteesOther?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListGrowthPoliciesQueryVariables = {
    id?: string | null,
    filter?: ModelGrowthPolicyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListGrowthPoliciesQuery = {
    listGrowthPolicies?:  {
        __typename: "ModelGrowthPolicyConnection",
        items:  Array< {
            __typename: "GrowthPolicy",
            id: string,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            agentReferralFeeAmount?: number | null,
            agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
            agentProvidedBenefitsOther?: string | null,
            enableOwnerReferralProgram?: boolean | null,
            ownerReferralsIncentive?: OwnerReferralsIncentive | null,
            enablePMReferralProgram?: boolean | null,
            pmReferralsFeeAmount?: number | null,
            typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
            doorCount?: number | null,
            yearsInBusiness?: number | null,
            website?: string | null,
            companyDescription?: string | null,
            pmAvatarURL?: string | null,
            pmFirstName?: string | null,
            pmLastName?: string | null,
            pmTitle?: string | null,
            companyGoogleReviews?: number | null,
            companyAVGDayOnMarket?: number | null,
            companyTenantRenewalRate?: number | null,
            companyServices?: Array< CompanyServices > | null,
            companyServicesOther?: string | null,
            companyGuarantees?: Array< CompanyGuarantees > | null,
            companyGuaranteesOther?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetLeaseQueryVariables = {
    id: string,
};

export type GetLeaseQuery = {
    getLease?:  {
        __typename: "Lease",
        id: string,
        startDate: string,
        endDate?: string | null,
        isActive?: boolean | null,
        term?: Term | null,
        rent?: number | null,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        tenant?: string | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyLeasesId?: string | null,
    } | null,
};

export type ListLeasesQueryVariables = {
    id?: string | null,
    filter?: ModelLeaseFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListLeasesQuery = {
    listLeases?:  {
        __typename: "ModelLeaseConnection",
        items:  Array< {
            __typename: "Lease",
            id: string,
            startDate: string,
            endDate?: string | null,
            isActive?: boolean | null,
            term?: Term | null,
            rent?: number | null,
            owner: string,
            readPermissions?: Array< string | null > | null,
            tenant?: string | null,
            createdTime: string,
            updatedTime: string,
            dashboardPropertyLeasesId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetLegislationDataQueryVariables = {
    id: string,
};

export type GetLegislationDataQuery = {
    getLegislationData?:  {
        __typename: "LegislationData",
        id: string,
        state: string,
        billID?: number | null,
        lastActionDate?: string | null,
        billNumber?: string | null,
        title?: string | null,
        url?: string | null,
        relevance?: number | null,
        lastAction?: string | null,
        status?: BillStatus | null,
        summary?: string | null,
        executiveSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListLegislationDataQueryVariables = {
    id?: string | null,
    filter?: ModelLegislationDataFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListLegislationDataQuery = {
    listLegislationData?:  {
        __typename: "ModelLegislationDataConnection",
        items:  Array< {
            __typename: "LegislationData",
            id: string,
            state: string,
            billID?: number | null,
            lastActionDate?: string | null,
            billNumber?: string | null,
            title?: string | null,
            url?: string | null,
            relevance?: number | null,
            lastAction?: string | null,
            status?: BillStatus | null,
            summary?: string | null,
            executiveSummary?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListLegislationDataByStateQueryVariables = {
    state: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelLegislationDataFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListLegislationDataByStateQuery = {
    listLegislationDataByState?:  {
        __typename: "ModelLegislationDataConnection",
        items:  Array< {
            __typename: "LegislationData",
            id: string,
            state: string,
            billID?: number | null,
            lastActionDate?: string | null,
            billNumber?: string | null,
            title?: string | null,
            url?: string | null,
            relevance?: number | null,
            lastAction?: string | null,
            status?: BillStatus | null,
            summary?: string | null,
            executiveSummary?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetMarketDataQueryVariables = {
    id: string,
};

export type GetMarketDataQuery = {
    getMarketData?:  {
        __typename: "MarketData",
        id: string,
        fips: string,
        dataType?: string | null,
        date: string,
        areaName?: string | null,
        homeValueSFR?: number | null,
        homeValueAll?: number | null,
        marketHeat?: number | null,
        rentSFR?: number | null,
        rentAll?: number | null,
        daysOnMarket?: number | null,
        marketSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListMarketDataQueryVariables = {
    id?: string | null,
    filter?: ModelMarketDataFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMarketDataQuery = {
    listMarketData?:  {
        __typename: "ModelMarketDataConnection",
        items:  Array< {
            __typename: "MarketData",
            id: string,
            fips: string,
            dataType?: string | null,
            date: string,
            areaName?: string | null,
            homeValueSFR?: number | null,
            homeValueAll?: number | null,
            marketHeat?: number | null,
            rentSFR?: number | null,
            rentAll?: number | null,
            daysOnMarket?: number | null,
            marketSummary?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListMarketDataByFIPSQueryVariables = {
    fips: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelMarketDataFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListMarketDataByFIPSQuery = {
    listMarketDataByFIPS?:  {
        __typename: "ModelMarketDataConnection",
        items:  Array< {
            __typename: "MarketData",
            id: string,
            fips: string,
            dataType?: string | null,
            date: string,
            areaName?: string | null,
            homeValueSFR?: number | null,
            homeValueAll?: number | null,
            marketHeat?: number | null,
            rentSFR?: number | null,
            rentAll?: number | null,
            daysOnMarket?: number | null,
            marketSummary?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetMarketPlacePropertyPictureQueryVariables = {
    url: string,
};

export type GetMarketPlacePropertyPictureQuery = {
    getMarketPlacePropertyPicture?:  {
        __typename: "MarketPlacePropertyPicture",
        url: string,
        rank?: number | null,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        marketplacePropertyListingPicturesId: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListMarketPlacePropertyPicturesQueryVariables = {
    url?: string | null,
    filter?: ModelMarketPlacePropertyPictureFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMarketPlacePropertyPicturesQuery = {
    listMarketPlacePropertyPictures?:  {
        __typename: "ModelMarketPlacePropertyPictureConnection",
        items:  Array< {
            __typename: "MarketPlacePropertyPicture",
            url: string,
            rank?: number | null,
            marketplacePropertyListingPicturesId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListMarketplacePropertyPicturesByPropertyIdQueryVariables = {
    marketplacePropertyListingPicturesId: string,
    rank?: ModelIntKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelMarketPlacePropertyPictureFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListMarketplacePropertyPicturesByPropertyIdQuery = {
    listMarketplacePropertyPicturesByPropertyId?:  {
        __typename: "ModelMarketPlacePropertyPictureConnection",
        items:  Array< {
            __typename: "MarketPlacePropertyPicture",
            url: string,
            rank?: number | null,
            marketplacePropertyListingPicturesId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListMarketplacePropertyRentalQueryVariables = {
    id?: string | null,
    filter?: ModelMarketplacePropertyRentalFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMarketplacePropertyRentalQuery = {
    listMarketplacePropertyRental?:  {
        __typename: "ModelMarketplacePropertyRentalConnection",
        items:  Array< {
            __typename: "MarketplacePropertyRental",
            id: string,
            date: string,
            priceUpper?: number | null,
            priceLower?: number | null,
            priceMean?: number | null,
            fsd?: number | null,
            provider?: Provider | null,
            marketplacePropertyPropertyRentalId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListMarketplacePropertyRentalByPropertyIdQueryVariables = {
    marketplacePropertyPropertyRentalId: string,
    date?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelMarketplacePropertyRentalFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListMarketplacePropertyRentalByPropertyIdQuery = {
    listMarketplacePropertyRentalByPropertyId?:  {
        __typename: "ModelMarketplacePropertyRentalConnection",
        items:  Array< {
            __typename: "MarketplacePropertyRental",
            id: string,
            date: string,
            priceUpper?: number | null,
            priceLower?: number | null,
            priceMean?: number | null,
            fsd?: number | null,
            provider?: Provider | null,
            marketplacePropertyPropertyRentalId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetMarketplacePMPreferencesQueryVariables = {
    pm: string,
};

export type GetMarketplacePMPreferencesQuery = {
    getMarketplacePMPreferences?:  {
        __typename: "MarketplacePMPreferences",
        pm: string,
        managementFee?: string | null,
        leadsThirdPartyIntegrationRequested?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListMarketplacePMPreferencesQueryVariables = {
    pm?: string | null,
    filter?: ModelMarketplacePMPreferencesFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMarketplacePMPreferencesQuery = {
    listMarketplacePMPreferences?:  {
        __typename: "ModelMarketplacePMPreferencesConnection",
        items:  Array< {
            __typename: "MarketplacePMPreferences",
            pm: string,
            managementFee?: string | null,
            leadsThirdPartyIntegrationRequested?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetMarketplacePropertyQueryVariables = {
    id: string,
};

export type GetMarketplacePropertyQuery = {
    getMarketplaceProperty?:  {
        __typename: "MarketplaceProperty",
        id: string,
        askingPrice: number,
        owner: string,
        status: MarketPlacePropertyStatus,
        closedBy?: string | null,
        activatedTime?: string | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        description?: string | null,
        rent?: number | null,
        constructionStatus?: ConstructionStatus | null,
        category?: MarketplacePropertyCategory | null,
        source?: string | null,
        vendor?: string | null,
        listingPictures?:  {
            __typename: "ModelMarketPlacePropertyPictureConnection",
            nextToken?: string | null,
        } | null,
        expenses?:  {
            __typename: "MarketPlacePropertyExpenses",
            hoa?: number | null,
            insurance?: number | null,
            managementFee?: number | null,
            other?: number | null,
            tax?: number | null,
        } | null,
        favorites?:  {
            __typename: "ModelFavoriteMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        recommendations?:  {
            __typename: "ModelMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        pmRecommendations?:  {
            __typename: "ModelPmMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        offers?:  {
            __typename: "ModelOfferConnection",
            nextToken?: string | null,
        } | null,
        crime?:  {
            __typename: "ModelCrimeConnection",
            nextToken?: string | null,
        } | null,
        readPermissions?: Array< string | null > | null,
        relevantPMs?: Array< string | null > | null,
        shareWith?: Array< string | null > | null,
        propertyRental?:  {
            __typename: "ModelMarketplacePropertyRentalConnection",
            nextToken?: string | null,
        } | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        listingItems?:  {
            __typename: "ModelMarketplacePropertyListingItemConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
        propertyMarketplacePropertyId?: string | null,
    } | null,
};

export type ListMarketplacePropertiesQueryVariables = {
    id?: string | null,
    filter?: ModelMarketplacePropertyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMarketplacePropertiesQuery = {
    listMarketplaceProperties?:  {
        __typename: "ModelMarketplacePropertyConnection",
        items:  Array< {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetMarketplacePropertyListingItemQueryVariables = {
    id: string,
};

export type GetMarketplacePropertyListingItemQuery = {
    getMarketplacePropertyListingItem?:  {
        __typename: "MarketplacePropertyListingItem",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        property?:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null,
        status: MarketPlacePropertyStatus,
        actualRent?: number | null,
        estimatedRent?: number | null,
        stateInsuranceFactor?: number | null,
        totalExpenses?: number | null,
        maintenance?: number | null,
        estimatedVacancy?: number | null,
        managementFee?: string | null,
        capRate?: number | null,
        noi?: number | null,
        cashOnCash?: number | null,
        grossYield?: number | null,
        insurance?: number | null,
        askingPrice: number,
        bedrooms?: number | null,
        bathrooms?: number | null,
        category: string,
        categoryOrder: number,
        tags: Array< ListingItemTag >,
        partialAddresses: Array< string >,
        isManagedByThePM?: boolean | null,
        canBeManagedByThePM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyListingItemsId?: string | null,
    } | null,
};

export type ListMarketplacePropertyListingItemsQueryVariables = {
    filter?: ModelMarketplacePropertyListingItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListMarketplacePropertyListingItemsQuery = {
    listMarketplacePropertyListingItems?:  {
        __typename: "ModelMarketplacePropertyListingItemConnection",
        items:  Array< {
            __typename: "MarketplacePropertyListingItem",
            id: string,
            pm: string,
            readPermissions?: Array< string | null > | null,
            status: MarketPlacePropertyStatus,
            actualRent?: number | null,
            estimatedRent?: number | null,
            stateInsuranceFactor?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            estimatedVacancy?: number | null,
            managementFee?: string | null,
            capRate?: number | null,
            noi?: number | null,
            cashOnCash?: number | null,
            grossYield?: number | null,
            insurance?: number | null,
            askingPrice: number,
            bedrooms?: number | null,
            bathrooms?: number | null,
            category: string,
            categoryOrder: number,
            tags: Array< ListingItemTag >,
            partialAddresses: Array< string >,
            isManagedByThePM?: boolean | null,
            canBeManagedByThePM?: boolean | null,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyListingItemsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type SearchMarketplacePropertyListingItemsQueryVariables = {
    filter?: SearchableMarketplacePropertyListingItemFilterInput | null,
    sort?: Array< SearchableMarketplacePropertyListingItemSortInput | null > | null,
    limit?: number | null,
    nextToken?: string | null,
    from?: number | null,
    aggregates?: Array< SearchableMarketplacePropertyListingItemAggregationInput | null > | null,
};

export type SearchMarketplacePropertyListingItemsQuery = {
    searchMarketplacePropertyListingItems?:  {
        __typename: "SearchableMarketplacePropertyListingItemConnection",
        items:  Array< {
            __typename: "MarketplacePropertyListingItem",
            id: string,
            pm: string,
            readPermissions?: Array< string | null > | null,
            status: MarketPlacePropertyStatus,
            actualRent?: number | null,
            estimatedRent?: number | null,
            stateInsuranceFactor?: number | null,
            totalExpenses?: number | null,
            maintenance?: number | null,
            estimatedVacancy?: number | null,
            managementFee?: string | null,
            capRate?: number | null,
            noi?: number | null,
            cashOnCash?: number | null,
            grossYield?: number | null,
            insurance?: number | null,
            askingPrice: number,
            bedrooms?: number | null,
            bathrooms?: number | null,
            category: string,
            categoryOrder: number,
            tags: Array< ListingItemTag >,
            partialAddresses: Array< string >,
            isManagedByThePM?: boolean | null,
            canBeManagedByThePM?: boolean | null,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyListingItemsId?: string | null,
        } | null >,
        nextToken?: string | null,
        total?: number | null,
        aggregateItems:  Array< {
            __typename: "SearchableAggregateResult",
            name: string,
            result: ( {
                __typename: "SearchableAggregateScalarResult",
                value: number,
            } | {
                __typename: "SearchableAggregateBucketResult",
                buckets?:  Array< {
                    __typename: string,
                    key: string,
                    doc_count: number,
                } | null > | null,
            }
                ) | null,
        } | null >,
    } | null,
};

export type GetMarketplacePropertyPmRecommendationQueryVariables = {
    pm: string,
};

export type GetMarketplacePropertyPmRecommendationQuery = {
    getMarketplacePropertyPmRecommendation?:  {
        __typename: "MarketplacePropertyPmRecommendation",
        pm: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListMarketplacePropertyPmRecommendationsQueryVariables = {
    pm?: string | null,
    filter?: ModelMarketplacePropertyPmRecommendationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMarketplacePropertyPmRecommendationsQuery = {
    listMarketplacePropertyPmRecommendations?:  {
        __typename: "ModelMarketplacePropertyPmRecommendationConnection",
        items:  Array< {
            __typename: "MarketplacePropertyPmRecommendation",
            pm: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetMarketplacePropertyRecommendationQueryVariables = {
    id: string,
};

export type GetMarketplacePropertyRecommendationQuery = {
    getMarketplacePropertyRecommendation?:  {
        __typename: "MarketplacePropertyRecommendation",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyRecommendationsId?: string | null,
    } | null,
};

export type ListMarketplacePropertyRecommendationsQueryVariables = {
    id?: string | null,
    filter?: ModelMarketplacePropertyRecommendationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMarketplacePropertyRecommendationsQuery = {
    listMarketplacePropertyRecommendations?:  {
        __typename: "ModelMarketplacePropertyRecommendationConnection",
        items:  Array< {
            __typename: "MarketplacePropertyRecommendation",
            id: string,
            owner: string,
            score: number,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyRecommendationsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListRecommendationsByOwnerQueryVariables = {
    owner: string,
    score?: ModelFloatKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelMarketplacePropertyRecommendationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListRecommendationsByOwnerQuery = {
    listRecommendationsByOwner?:  {
        __typename: "ModelMarketplacePropertyRecommendationConnection",
        items:  Array< {
            __typename: "MarketplacePropertyRecommendation",
            id: string,
            owner: string,
            score: number,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyRecommendationsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetMortgageQueryVariables = {
    id: string,
};

export type GetMortgageQuery = {
    getMortgage?:  {
        __typename: "Mortgage",
        id: string,
        rate: number,
        amount: number,
        startDate: string,
        mortgageDueDay: number,
        term: number,
        owner: string,
        grantor: string,
        apn: string,
        mortgageType: MortgageType,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        provider?: Provider | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyMortgageId?: string | null,
    } | null,
};

export type ListMortgagesQueryVariables = {
    id?: string | null,
    filter?: ModelMortgageFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListMortgagesQuery = {
    listMortgages?:  {
        __typename: "ModelMortgageConnection",
        items:  Array< {
            __typename: "Mortgage",
            id: string,
            rate: number,
            amount: number,
            startDate: string,
            mortgageDueDay: number,
            term: number,
            owner: string,
            grantor: string,
            apn: string,
            mortgageType: MortgageType,
            readPermissions?: Array< string | null > | null,
            provider?: Provider | null,
            createdTime: string,
            updatedTime: string,
            dashboardPropertyMortgageId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetNotificationTemplateQueryVariables = {
    id: string,
};

export type GetNotificationTemplateQuery = {
    getNotificationTemplate?:  {
        __typename: "NotificationTemplate",
        id: string,
        template: string,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListNotificationTemplatesQueryVariables = {
    id?: string | null,
    filter?: ModelNotificationTemplateFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListNotificationTemplatesQuery = {
    listNotificationTemplates?:  {
        __typename: "ModelNotificationTemplateConnection",
        items:  Array< {
            __typename: "NotificationTemplate",
            id: string,
            template: string,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOfferQueryVariables = {
    id: string,
};

export type GetOfferQuery = {
    getOffer?:  {
        __typename: "Offer",
        id: string,
        buyer: string,
        seller?: string | null,
        bid?: number | null,
        status: OfferStatus,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyOffersId?: string | null,
    } | null,
};

export type ListOffersQueryVariables = {
    id?: string | null,
    filter?: ModelOfferFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOffersQuery = {
    listOffers?:  {
        __typename: "ModelOfferConnection",
        items:  Array< {
            __typename: "Offer",
            id: string,
            buyer: string,
            seller?: string | null,
            bid?: number | null,
            status: OfferStatus,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyOffersId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerLeadQueryVariables = {
    id: string,
};

export type GetOwnerLeadQuery = {
    getOwnerLead?:  {
        __typename: "OwnerLead",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        pm?: string | null,
        requestRentalAnalysis?: boolean | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        numOfProperties?: number | null,
        addresses?: Array< string | null > | null,
        referrer?: string | null,
        source?: OwnerLeadSource | null,
        readPermissions?: Array< string | null > | null,
        ownerLeadWorkflowItem?:  {
            __typename: "ModelOwnerLeadWorkflowItemConnection",
            nextToken?: string | null,
        } | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadId?: string | null,
    } | null,
};

export type ListOwnerLeadsQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerLeadFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerLeadsQuery = {
    listOwnerLeads?:  {
        __typename: "ModelOwnerLeadConnection",
        items:  Array< {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerLeadsByPmQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelOwnerLeadFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListOwnerLeadsByPmQuery = {
    listOwnerLeadsByPm?:  {
        __typename: "ModelOwnerLeadConnection",
        items:  Array< {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerLeadReferralLinkQueryVariables = {
    id: string,
};

export type GetOwnerLeadReferralLinkQuery = {
    getOwnerLeadReferralLink?:  {
        __typename: "OwnerLeadReferralLink",
        id: string,
        ownerId: string,
        pm?: string | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadReferralLinkId?: string | null,
    } | null,
};

export type ListOwnerLeadReferralLinksQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerLeadReferralLinkFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerLeadReferralLinksQuery = {
    listOwnerLeadReferralLinks?:  {
        __typename: "ModelOwnerLeadReferralLinkConnection",
        items:  Array< {
            __typename: "OwnerLeadReferralLink",
            id: string,
            ownerId: string,
            pm?: string | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadReferralLinkId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerLeadWorkflowItemQueryVariables = {
    id: string,
};

export type GetOwnerLeadWorkflowItemQuery = {
    getOwnerLeadWorkflowItem?:  {
        __typename: "OwnerLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        pm: string,
        requestRentalAnalysis?: boolean | null,
        numOfProperties?: number | null,
        addresses?: Array< string > | null,
        referrer?: string | null,
        source?: OwnerLeadsWorkflowSource | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        status: OwnerLeadsWorkflowStatus,
        revealed?: boolean | null,
        ownerLead?:  {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        readShareWith?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
        ownerLeadOwnerLeadWorkflowItemId?: string | null,
    } | null,
};

export type ListOwnerLeadWorkflowItemsQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerLeadWorkflowItemsQuery = {
    listOwnerLeadWorkflowItems?:  {
        __typename: "ModelOwnerLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "OwnerLeadWorkflowItem",
            id: string,
            pm: string,
            requestRentalAnalysis?: boolean | null,
            numOfProperties?: number | null,
            addresses?: Array< string > | null,
            referrer?: string | null,
            source?: OwnerLeadsWorkflowSource | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            status: OwnerLeadsWorkflowStatus,
            revealed?: boolean | null,
            readPermissions?: Array< string > | null,
            updatePermissions?: Array< string > | null,
            readShareWith?: Array< string > | null,
            createdTime: string,
            updatedTime: string,
            ownerLeadOwnerLeadWorkflowItemId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerLeadWorkflowItemsByPmQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelOwnerLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListOwnerLeadWorkflowItemsByPmQuery = {
    listOwnerLeadWorkflowItemsByPm?:  {
        __typename: "ModelOwnerLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "OwnerLeadWorkflowItem",
            id: string,
            pm: string,
            requestRentalAnalysis?: boolean | null,
            numOfProperties?: number | null,
            addresses?: Array< string > | null,
            referrer?: string | null,
            source?: OwnerLeadsWorkflowSource | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            status: OwnerLeadsWorkflowStatus,
            revealed?: boolean | null,
            readPermissions?: Array< string > | null,
            updatePermissions?: Array< string > | null,
            readShareWith?: Array< string > | null,
            createdTime: string,
            updatedTime: string,
            ownerLeadOwnerLeadWorkflowItemId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerMarketplacePropertyRecommendationQueryVariables = {
    owner: string,
};

export type GetOwnerMarketplacePropertyRecommendationQuery = {
    getOwnerMarketplacePropertyRecommendation?:  {
        __typename: "OwnerMarketplacePropertyRecommendation",
        owner: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListOwnerMarketplacePropertyRecommendationsQueryVariables = {
    owner?: string | null,
    filter?: ModelOwnerMarketplacePropertyRecommendationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerMarketplacePropertyRecommendationsQuery = {
    listOwnerMarketplacePropertyRecommendations?:  {
        __typename: "ModelOwnerMarketplacePropertyRecommendationConnection",
        items:  Array< {
            __typename: "OwnerMarketplacePropertyRecommendation",
            owner: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerReportQueryVariables = {
    id: string,
};

export type GetOwnerReportQuery = {
    getOwnerReport?:  {
        __typename: "OwnerReport",
        id: string,
        owner: string,
        month: number,
        year: number,
        reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type ListOwnerReportsQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerReportFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerReportsQuery = {
    listOwnerReports?:  {
        __typename: "ModelOwnerReportConnection",
        items:  Array< {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerReportsByOwnerQueryVariables = {
    owner: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelOwnerReportFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListOwnerReportsByOwnerQuery = {
    listOwnerReportsByOwner?:  {
        __typename: "ModelOwnerReportConnection",
        items:  Array< {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerRetentionDetailsQueryVariables = {
    id: string,
};

export type GetOwnerRetentionDetailsQuery = {
    getOwnerRetentionDetails?:  {
        __typename: "OwnerRetentionDetails",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        satisfactionLevel?: SatisfactionLevel | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRetentionDetailsId?: string | null,
    } | null,
};

export type ListOwnerRetentionDetailsQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerRetentionDetailsFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerRetentionDetailsQuery = {
    listOwnerRetentionDetails?:  {
        __typename: "ModelOwnerRetentionDetailsConnection",
        items:  Array< {
            __typename: "OwnerRetentionDetails",
            id: string,
            pm: string,
            readPermissions?: Array< string | null > | null,
            satisfactionLevel?: SatisfactionLevel | null,
            createdTime: string,
            updatedTime: string,
            ownerRiskOwnerRetentionDetailsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerRetentionDetailsByPMQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelOwnerRetentionDetailsFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListOwnerRetentionDetailsByPMQuery = {
    listOwnerRetentionDetailsByPM?:  {
        __typename: "ModelOwnerRetentionDetailsConnection",
        items:  Array< {
            __typename: "OwnerRetentionDetails",
            id: string,
            pm: string,
            readPermissions?: Array< string | null > | null,
            satisfactionLevel?: SatisfactionLevel | null,
            createdTime: string,
            updatedTime: string,
            ownerRiskOwnerRetentionDetailsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerRiskQueryVariables = {
    id: string,
};

export type GetOwnerRiskQuery = {
    getOwnerRisk?:  {
        __typename: "OwnerRisk",
        id: string,
        ownerID: string,
        pm: string,
        ownerSince?: string | null,
        ownerDetails:  {
            __typename: "RiskOwnerDetails",
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        risk?: number | null,
        riskLevel?: RiskLevel | null,
        rawRisk?: number | null,
        scorePerProperty?:  Array< {
            __typename: "PropertyScore",
            propertyID?: string | null,
            score?: number | null,
            rawScore?: number | null,
        } > | null,
        severity: OwnerRiskSeverity,
        severityScore?: number | null,
        severityManualOverride?: boolean | null,
        insights?:  Array< {
            __typename: "OwnerRiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            weight?: number | null,
            value?: number | null,
        } > | null,
        clientValue: number,
        clientValueBreakDown?:  Array< {
            __typename: "ClientValueCategory",
            category?: PMTransactionCategory | null,
            value?: number | null,
        } > | null,
        clientValueGroup: ClientValueGroup,
        status: OwnerRiskStatus,
        resolvedReason?: ResolvedReason | null,
        resolutionExplanation?: string | null,
        inProgressSetDate?: string | null,
        resolvedDate?: string | null,
        statusChangedDate?: string | null,
        recommendations?:  {
            __typename: "ModelOwnerRiskRecommendationConnection",
            nextToken?: string | null,
        } | null,
        ownerRiskTrends?:  {
            __typename: "ModelOwnerRiskTrendConnection",
            nextToken?: string | null,
        } | null,
        ownerRetentionDetails?:  {
            __typename: "ModelOwnerRetentionDetailsConnection",
            nextToken?: string | null,
        } | null,
        ownerRisksInsights?:  {
            __typename: "ModelRiskInsightConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListOwnerRisksQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerRiskFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerRisksQuery = {
    listOwnerRisks?:  {
        __typename: "ModelOwnerRiskConnection",
        items:  Array< {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerRiskByOwnerQueryVariables = {
    ownerID: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelOwnerRiskFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListOwnerRiskByOwnerQuery = {
    listOwnerRiskByOwner?:  {
        __typename: "ModelOwnerRiskConnection",
        items:  Array< {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerRiskByPMQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelOwnerRiskFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListOwnerRiskByPMQuery = {
    listOwnerRiskByPM?:  {
        __typename: "ModelOwnerRiskConnection",
        items:  Array< {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerRiskRecommendationsQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerRiskRecommendationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerRiskRecommendationsQuery = {
    listOwnerRiskRecommendations?:  {
        __typename: "ModelOwnerRiskRecommendationConnection",
        items:  Array< {
            __typename: "OwnerRiskRecommendation",
            id: string,
            pm: string,
            mitigations: Array< OwnerRiskMitigation >,
            userSelectedMitigations?: Array< OwnerRiskMitigation > | null,
            summaryMarkdown: string,
            positivePoints?: Array< string > | null,
            negativePoints?: Array< string > | null,
            emailHTML?: string | null,
            modifiedPM?: boolean | null,
            createdTime: string,
            updatedTime: string,
            ownerRiskRecommendationsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetOwnerRiskTrendQueryVariables = {
    id: string,
};

export type GetOwnerRiskTrendQuery = {
    getOwnerRiskTrend?:  {
        __typename: "OwnerRiskTrend",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        risk?: number | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRiskTrendsId?: string | null,
    } | null,
};

export type ListOwnerRiskTrendsQueryVariables = {
    id?: string | null,
    filter?: ModelOwnerRiskTrendFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerRiskTrendsQuery = {
    listOwnerRiskTrends?:  {
        __typename: "ModelOwnerRiskTrendConnection",
        items:  Array< {
            __typename: "OwnerRiskTrend",
            id: string,
            pm: string,
            readPermissions?: Array< string | null > | null,
            risk?: number | null,
            createdTime: string,
            updatedTime: string,
            ownerRiskOwnerRiskTrendsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPMMetricsQueryVariables = {
    id: string,
};

export type GetPMMetricsQuery = {
    getPMMetrics?:  {
        __typename: "PMMetrics",
        id: string,
        usersMetrics?:  {
            __typename: "UsersMetrics",
            activatedUsers: number,
            activatedUsersLastMonth?: number | null,
            invitedUsers: number,
            invitedUsersLastMonth?: number | null,
            createdTime: string,
        } | null,
        communicationMetrics?:  {
            __typename: "CommunicationMetrics",
            emailsSentThisMonth: number,
            createdTime: string,
        } | null,
        propertyMetrics?:  {
            __typename: "PropertyMetrics",
            properties: number,
            units: number,
            createdTime: string,
        } | null,
        riskMetrics?:  {
            __typename: "OwnerRiskMetrics",
            totalNoRisk: number,
            noRiskTotalValue: number,
            totalNeedsAttention: number,
            needsAttentionTotalValue: number,
            totalInProgress: number,
            inProgressTotalValue: number,
            totalResolved: number,
            resolvedTotalValue: number,
            createdTime: string,
        } | null,
        leadMetrics?:  {
            __typename: "LeadMetrics",
            totalReferrals: number,
            totalReferralsValue: number,
            totalBuyerLeads: number,
            totalBuyerLeadsValue: number,
            totalSellerLeads: number,
            totalSellerLeadsValue: number,
            totalProspects: number,
            totalProspectsValue: number,
            totalBuyBoxLeads: number,
            totalBuyBoxLeadsValue: number,
            createdTime: string,
        } | null,
        readPermissions?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListPMMetricsQueryVariables = {
    id?: string | null,
    filter?: ModelPMMetricsFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPMMetricsQuery = {
    listPMMetrics?:  {
        __typename: "ModelPMMetricsConnection",
        items:  Array< {
            __typename: "PMMetrics",
            id: string,
            readPermissions?: Array< string > | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPMSettingsQueryVariables = {
    pm: string,
};

export type GetPMSettingsQuery = {
    getPMSettings?:  {
        __typename: "PMSettings",
        pm: string,
        leadershipSummary2024?: string | null,
        dashboardProformaMetricsOverride?: string | null,
        showLegalSummary?: boolean | null,
        obpTitleOverride?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListPMSettingsQueryVariables = {
    pm?: string | null,
    filter?: ModelPMSettingsFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPMSettingsQuery = {
    listPMSettings?:  {
        __typename: "ModelPMSettingsConnection",
        items:  Array< {
            __typename: "PMSettings",
            pm: string,
            leadershipSummary2024?: string | null,
            dashboardProformaMetricsOverride?: string | null,
            showLegalSummary?: boolean | null,
            obpTitleOverride?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPMTransactionQueryVariables = {
    id: string,
};

export type GetPMTransactionQuery = {
    getPMTransaction?:  {
        __typename: "PMTransaction",
        id: string,
        amount: number,
        transactionDate: string,
        pm: string,
        owner: string,
        type: string,
        category: PMTransactionCategory,
        label?: Label | null,
        description?: string | null,
        dashboardPropertyPmTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListPMTransactionsQueryVariables = {
    id?: string | null,
    filter?: ModelPMTransactionFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPMTransactionsQuery = {
    listPMTransactions?:  {
        __typename: "ModelPMTransactionConnection",
        items:  Array< {
            __typename: "PMTransaction",
            id: string,
            amount: number,
            transactionDate: string,
            pm: string,
            owner: string,
            type: string,
            category: PMTransactionCategory,
            label?: Label | null,
            description?: string | null,
            dashboardPropertyPmTransactionsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPMTransactionByPMQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelPMTransactionFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type GetPMTransactionByPMQuery = {
    getPMTransactionByPM?:  {
        __typename: "ModelPMTransactionConnection",
        items:  Array< {
            __typename: "PMTransaction",
            id: string,
            amount: number,
            transactionDate: string,
            pm: string,
            owner: string,
            type: string,
            category: PMTransactionCategory,
            label?: Label | null,
            description?: string | null,
            dashboardPropertyPmTransactionsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListPropertyPMTransactionByPropertyIdQueryVariables = {
    dashboardPropertyPmTransactionsId: string,
    transactionDate?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelPMTransactionFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListPropertyPMTransactionByPropertyIdQuery = {
    listPropertyPMTransactionByPropertyId?:  {
        __typename: "ModelPMTransactionConnection",
        items:  Array< {
            __typename: "PMTransaction",
            id: string,
            amount: number,
            transactionDate: string,
            pm: string,
            owner: string,
            type: string,
            category: PMTransactionCategory,
            label?: Label | null,
            description?: string | null,
            dashboardPropertyPmTransactionsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPartnerQueryVariables = {
    id: string,
};

export type GetPartnerQuery = {
    getPartner?:  {
        __typename: "Partner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        partnerType: PartnerType,
        partnerPM?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        referralAmount?: number | null,
        referralFreeMonths?: number | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        address?: string | null,
        website?: string | null,
        experienceInYears?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        ownerLead?:  {
            __typename: "ModelOwnerLeadConnection",
            nextToken?: string | null,
        } | null,
        ownerLeadReferralLink?:  {
            __typename: "ModelOwnerLeadReferralLinkConnection",
            nextToken?: string | null,
        } | null,
        suggestedPartnerPartnerId?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListPartnersQueryVariables = {
    id?: string | null,
    filter?: ModelPartnerFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPartnersQuery = {
    listPartners?:  {
        __typename: "ModelPartnerConnection",
        items:  Array< {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListPartnersByPmQueryVariables = {
    pm: string,
    partnerType?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelPartnerFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListPartnersByPmQuery = {
    listPartnersByPm?:  {
        __typename: "ModelPartnerConnection",
        items:  Array< {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPmMarketplacePropertyRecommendationQueryVariables = {
    id: string,
};

export type GetPmMarketplacePropertyRecommendationQuery = {
    getPmMarketplacePropertyRecommendation?:  {
        __typename: "PmMarketplacePropertyRecommendation",
        id: string,
        pm: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyPmRecommendationsId?: string | null,
    } | null,
};

export type ListPmMarketplacePropertyRecommendationsQueryVariables = {
    id?: string | null,
    filter?: ModelPmMarketplacePropertyRecommendationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPmMarketplacePropertyRecommendationsQuery = {
    listPmMarketplacePropertyRecommendations?:  {
        __typename: "ModelPmMarketplacePropertyRecommendationConnection",
        items:  Array< {
            __typename: "PmMarketplacePropertyRecommendation",
            id: string,
            pm: string,
            score: number,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyPmRecommendationsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListPmMarketplacePropertyRecommendationsByPmQueryVariables = {
    pm: string,
    score?: ModelFloatKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelPmMarketplacePropertyRecommendationFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListPmMarketplacePropertyRecommendationsByPmQuery = {
    listPmMarketplacePropertyRecommendationsByPm?:  {
        __typename: "ModelPmMarketplacePropertyRecommendationConnection",
        items:  Array< {
            __typename: "PmMarketplacePropertyRecommendation",
            id: string,
            pm: string,
            score: number,
            createdTime: string,
            updatedTime: string,
            marketplacePropertyPmRecommendationsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListPropertiesQueryVariables = {
    id?: string | null,
    filter?: ModelPropertyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPropertiesQuery = {
    listProperties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPropertyAmortizationTableQueryVariables = {
    id: string,
};

export type GetPropertyAmortizationTableQuery = {
    getPropertyAmortizationTable?:  {
        __typename: "PropertyAmortizationTable",
        id: string,
        period: number,
        transactionDate: string,
        owner: string,
        payment: number,
        interest: number,
        principal: number,
        totalPrincipal: number,
        mortgageId: string,
        dashboardPropertyAmortizationTableId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListPropertyAmortizationTablesQueryVariables = {
    id?: string | null,
    filter?: ModelPropertyAmortizationTableFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPropertyAmortizationTablesQuery = {
    listPropertyAmortizationTables?:  {
        __typename: "ModelPropertyAmortizationTableConnection",
        items:  Array< {
            __typename: "PropertyAmortizationTable",
            id: string,
            period: number,
            transactionDate: string,
            owner: string,
            payment: number,
            interest: number,
            principal: number,
            totalPrincipal: number,
            mortgageId: string,
            dashboardPropertyAmortizationTableId: string,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListAmortizationTableByMortgageIdQueryVariables = {
    mortgageId: string,
    period?: ModelIntKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelPropertyAmortizationTableFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListAmortizationTableByMortgageIdQuery = {
    listAmortizationTableByMortgageId?:  {
        __typename: "ModelPropertyAmortizationTableConnection",
        items:  Array< {
            __typename: "PropertyAmortizationTable",
            id: string,
            period: number,
            transactionDate: string,
            owner: string,
            payment: number,
            interest: number,
            principal: number,
            totalPrincipal: number,
            mortgageId: string,
            dashboardPropertyAmortizationTableId: string,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListAmortizationTableByPropertyIdQueryVariables = {
    dashboardPropertyAmortizationTableId: string,
    transactionDate?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelPropertyAmortizationTableFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListAmortizationTableByPropertyIdQuery = {
    listAmortizationTableByPropertyId?:  {
        __typename: "ModelPropertyAmortizationTableConnection",
        items:  Array< {
            __typename: "PropertyAmortizationTable",
            id: string,
            period: number,
            transactionDate: string,
            owner: string,
            payment: number,
            interest: number,
            principal: number,
            totalPrincipal: number,
            mortgageId: string,
            dashboardPropertyAmortizationTableId: string,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListPropertyExtendedAddressesQueryVariables = {
    id?: string | null,
    filter?: ModelPropertyExtendedAddressFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPropertyExtendedAddressesQuery = {
    listPropertyExtendedAddresses?:  {
        __typename: "ModelPropertyExtendedAddressConnection",
        items:  Array< {
            __typename: "PropertyExtendedAddress",
            id: string,
            street1?: string | null,
            street2?: string | null,
            city?: string | null,
            state?: string | null,
            zipCode?: string | null,
            lat?: number | null,
            lon?: number | null,
            geoid?: string | null,
            fips?: string | null,
            provider?: string | null,
            createdTime: string,
            updatedTime: string,
            propertyExtendedAddressId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetPropertyUserExpenseQueryVariables = {
    id: string,
};

export type GetPropertyUserExpenseQuery = {
    getPropertyUserExpense?:  {
        __typename: "PropertyUserExpense",
        id: string,
        amount: number,
        startDate: string,
        category: ExpenseCategory,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyUserExpenseId?: string | null,
    } | null,
};

export type ListPropertyUserExpensesQueryVariables = {
    id?: string | null,
    filter?: ModelPropertyUserExpenseFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListPropertyUserExpensesQuery = {
    listPropertyUserExpenses?:  {
        __typename: "ModelPropertyUserExpenseConnection",
        items:  Array< {
            __typename: "PropertyUserExpense",
            id: string,
            amount: number,
            startDate: string,
            category: ExpenseCategory,
            owner: string,
            createdTime: string,
            updatedTime: string,
            dashboardPropertyUserExpenseId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetProspectLeadWorkflowItemQueryVariables = {
    id: string,
};

export type GetProspectLeadWorkflowItemQuery = {
    getProspectLeadWorkflowItem?:  {
        __typename: "ProspectLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        status: ProspectLeadsWorkflowStatus,
        pm: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListProspectLeadWorkflowItemsQueryVariables = {
    id?: string | null,
    filter?: ModelProspectLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListProspectLeadWorkflowItemsQuery = {
    listProspectLeadWorkflowItems?:  {
        __typename: "ModelProspectLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "ProspectLeadWorkflowItem",
            id: string,
            status: ProspectLeadsWorkflowStatus,
            pm: string,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListProspectLeadWorkflowItemByPmQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelProspectLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListProspectLeadWorkflowItemByPmQuery = {
    listProspectLeadWorkflowItemByPm?:  {
        __typename: "ModelProspectLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "ProspectLeadWorkflowItem",
            id: string,
            status: ProspectLeadsWorkflowStatus,
            pm: string,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetReportQueryVariables = {
    id: string,
};

export type GetReportQuery = {
    getReport?:  {
        __typename: "Report",
        id: string,
        owner: string,
        name: string,
        version: number,
        totalOccupiedUnits: number,
        totalUnits: number,
        homeValue: number,
        appreciation: number,
        totalOtherIncome?: number | null,
        totalRent: number,
        totalEquity: number,
        noi: number,
        netCashFlow: number,
        ownerReport:  {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        },
        expenses:  {
            __typename: "Expenses",
            hoa: number,
            insurance: number,
            managementFee: number,
            other: number,
            tax: number,
            mortgage: number,
            utilities?: number | null,
            legalAndProfessionalFees?: number | null,
            rentalRegistration?: number | null,
            autoAndTravel?: number | null,
            supplies?: number | null,
            maintenance?: number | null,
            leasingAndMarketing?: number | null,
            totalExpense: number,
        },
        previousMonthlyMeasures:  {
            __typename: "PreviousMonthlyMeasures",
            homeValue: number,
            appreciation: number,
            totalEquity: number,
        },
        yearlyMeasures:  {
            __typename: "YearlyMeasures",
            capRate: number,
            cashOnCash: number,
        },
        reportType: ReportType,
        createdAt: string,
        updatedAt: string,
        ownerReportReportsId?: string | null,
    } | null,
};

export type ListReportsQueryVariables = {
    id?: string | null,
    filter?: ModelReportFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListReportsQuery = {
    listReports?:  {
        __typename: "ModelReportConnection",
        items:  Array< {
            __typename: "Report",
            id: string,
            owner: string,
            name: string,
            version: number,
            totalOccupiedUnits: number,
            totalUnits: number,
            homeValue: number,
            appreciation: number,
            totalOtherIncome?: number | null,
            totalRent: number,
            totalEquity: number,
            noi: number,
            netCashFlow: number,
            reportType: ReportType,
            createdAt: string,
            updatedAt: string,
            ownerReportReportsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetRetentionPolicyQueryVariables = {
    id: string,
};

export type GetRetentionPolicyQuery = {
    getRetentionPolicy?:  {
        __typename: "RetentionPolicy",
        id: string,
        readPermissions: Array< string | null >,
        updatePermissions: Array< string | null >,
        pm: string,
        maxFinancialIncentive: MaxFinancialIncentive,
        giveIncentiveWillingness: Willingness,
        meetWillingness: Willingness,
        badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
        goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
        writingStyles: Array< WritingStyle >,
        riskDetectionSensitivity: RiskDetectionSensitivity,
        summaryLength: SummaryLength,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type ListRetentionPoliciesQueryVariables = {
    id?: string | null,
    filter?: ModelRetentionPolicyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListRetentionPoliciesQuery = {
    listRetentionPolicies?:  {
        __typename: "ModelRetentionPolicyConnection",
        items:  Array< {
            __typename: "RetentionPolicy",
            id: string,
            readPermissions: Array< string | null >,
            updatePermissions: Array< string | null >,
            pm: string,
            maxFinancialIncentive: MaxFinancialIncentive,
            giveIncentiveWillingness: Willingness,
            meetWillingness: Willingness,
            badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
            goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
            writingStyles: Array< WritingStyle >,
            riskDetectionSensitivity: RiskDetectionSensitivity,
            summaryLength: SummaryLength,
            createdAt: string,
            updatedAt: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetRetentionPolicyByPMQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelRetentionPolicyFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type GetRetentionPolicyByPMQuery = {
    getRetentionPolicyByPM?:  {
        __typename: "ModelRetentionPolicyConnection",
        items:  Array< {
            __typename: "RetentionPolicy",
            id: string,
            readPermissions: Array< string | null >,
            updatePermissions: Array< string | null >,
            pm: string,
            maxFinancialIncentive: MaxFinancialIncentive,
            giveIncentiveWillingness: Willingness,
            meetWillingness: Willingness,
            badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
            goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
            writingStyles: Array< WritingStyle >,
            riskDetectionSensitivity: RiskDetectionSensitivity,
            summaryLength: SummaryLength,
            createdAt: string,
            updatedAt: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListOwnerInsightsQueryVariables = {
    id?: string | null,
    filter?: ModelRiskInsightFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListOwnerInsightsQuery = {
    listOwnerInsights?:  {
        __typename: "ModelRiskInsightConnection",
        items:  Array< {
            __typename: "RiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            weight?: number | null,
            value?: number | null,
            insightType?: InsightType | null,
            createdTime: string,
            updatedTime: string,
            ownerRiskOwnerRisksInsightsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetSellerLeadWorkflowItemQueryVariables = {
    id: string,
};

export type GetSellerLeadWorkflowItemQuery = {
    getSellerLeadWorkflowItem?:  {
        __typename: "SellerLeadWorkflowItem",
        id: string,
        sellerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId?: string | null,
        askingPrice: number,
        isPropertyVisible?: boolean | null,
        status: SellerLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListSellerLeadWorkflowItemsQueryVariables = {
    id?: string | null,
    filter?: ModelSellerLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListSellerLeadWorkflowItemsQuery = {
    listSellerLeadWorkflowItems?:  {
        __typename: "ModelSellerLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "SellerLeadWorkflowItem",
            id: string,
            propertyId?: string | null,
            askingPrice: number,
            isPropertyVisible?: boolean | null,
            status: SellerLeadsWorkflowStatus,
            pm: string,
            statusChangeTime: string,
            readShareWith?: Array< string > | null,
            updateShareWith?: Array< string > | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            revealed?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListSellerLeadWorkflowItemsByPmQueryVariables = {
    pm: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelSellerLeadWorkflowItemFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListSellerLeadWorkflowItemsByPmQuery = {
    listSellerLeadWorkflowItemsByPm?:  {
        __typename: "ModelSellerLeadWorkflowItemConnection",
        items:  Array< {
            __typename: "SellerLeadWorkflowItem",
            id: string,
            propertyId?: string | null,
            askingPrice: number,
            isPropertyVisible?: boolean | null,
            status: SellerLeadsWorkflowStatus,
            pm: string,
            statusChangeTime: string,
            readShareWith?: Array< string > | null,
            updateShareWith?: Array< string > | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            revealed?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetStateQueryVariables = {
    id: string,
};

export type GetStateQuery = {
    getState?:  {
        __typename: "State",
        id: string,
        insuranceFactor?: number | null,
        name: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListStatesQueryVariables = {
    id?: string | null,
    filter?: ModelStateFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListStatesQuery = {
    listStates?:  {
        __typename: "ModelStateConnection",
        items:  Array< {
            __typename: "State",
            id: string,
            insuranceFactor?: number | null,
            name: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetSuggestedPartnerQueryVariables = {
    id: string,
};

export type GetSuggestedPartnerQuery = {
    getSuggestedPartner?:  {
        __typename: "SuggestedPartner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        status: SuggestedPartnerStatus,
        suggestedPartnerType: SuggestedPartnerType,
        suggestedPartnerPM?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        phoneNumbers?:  Array< {
            __typename: "PhoneNumber",
            number?: string | null,
            phoneType?: PhoneType | null,
        } > | null,
        avatar?: string | null,
        rating?: number | null,
        officeName?: string | null,
        address?: string | null,
        website?: string | null,
        last12MonthsSales?: number | null,
        totalSales?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        totalSalesCount?: number | null,
        totalForSaleCount?: number | null,
        lastTwoYearsSalesCount?: number | null,
        averageSale?: number | null,
        experienceInYears?: number | null,
        description?: string | null,
        profiles?:  Array< {
            __typename: "Profile",
            link?: string | null,
            profileType?: ProfileType | null,
        } > | null,
        sources?: Array< Source > | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        relevancy?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        contactEmailBody?: string | null,
        contactEmailSubject?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListSuggestedPartnersQueryVariables = {
    id?: string | null,
    filter?: ModelSuggestedPartnerFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListSuggestedPartnersQuery = {
    listSuggestedPartners?:  {
        __typename: "ModelSuggestedPartnerConnection",
        items:  Array< {
            __typename: "SuggestedPartner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            updatePermissions?: Array< string > | null,
            status: SuggestedPartnerStatus,
            suggestedPartnerType: SuggestedPartnerType,
            suggestedPartnerPM?: string | null,
            firstName: string,
            lastName: string,
            email: string,
            phone?: string | null,
            avatar?: string | null,
            rating?: number | null,
            officeName?: string | null,
            address?: string | null,
            website?: string | null,
            last12MonthsSales?: number | null,
            totalSales?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            totalSalesCount?: number | null,
            totalForSaleCount?: number | null,
            lastTwoYearsSalesCount?: number | null,
            averageSale?: number | null,
            experienceInYears?: number | null,
            description?: string | null,
            sources?: Array< Source > | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            relevancy?: number | null,
            doorCount?: number | null,
            contactEmailBody?: string | null,
            contactEmailSubject?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListSuggestedPartnerByPmQueryVariables = {
    pm: string,
    suggestedPartnerType?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelSuggestedPartnerFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListSuggestedPartnerByPmQuery = {
    listSuggestedPartnerByPm?:  {
        __typename: "ModelSuggestedPartnerConnection",
        items:  Array< {
            __typename: "SuggestedPartner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            updatePermissions?: Array< string > | null,
            status: SuggestedPartnerStatus,
            suggestedPartnerType: SuggestedPartnerType,
            suggestedPartnerPM?: string | null,
            firstName: string,
            lastName: string,
            email: string,
            phone?: string | null,
            avatar?: string | null,
            rating?: number | null,
            officeName?: string | null,
            address?: string | null,
            website?: string | null,
            last12MonthsSales?: number | null,
            totalSales?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            totalSalesCount?: number | null,
            totalForSaleCount?: number | null,
            lastTwoYearsSalesCount?: number | null,
            averageSale?: number | null,
            experienceInYears?: number | null,
            description?: string | null,
            sources?: Array< Source > | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            relevancy?: number | null,
            doorCount?: number | null,
            contactEmailBody?: string | null,
            contactEmailSubject?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListSuggestedPartnerByEmailQueryVariables = {
    email: string,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelSuggestedPartnerFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListSuggestedPartnerByEmailQuery = {
    listSuggestedPartnerByEmail?:  {
        __typename: "ModelSuggestedPartnerConnection",
        items:  Array< {
            __typename: "SuggestedPartner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            updatePermissions?: Array< string > | null,
            status: SuggestedPartnerStatus,
            suggestedPartnerType: SuggestedPartnerType,
            suggestedPartnerPM?: string | null,
            firstName: string,
            lastName: string,
            email: string,
            phone?: string | null,
            avatar?: string | null,
            rating?: number | null,
            officeName?: string | null,
            address?: string | null,
            website?: string | null,
            last12MonthsSales?: number | null,
            totalSales?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            totalSalesCount?: number | null,
            totalForSaleCount?: number | null,
            lastTwoYearsSalesCount?: number | null,
            averageSale?: number | null,
            experienceInYears?: number | null,
            description?: string | null,
            sources?: Array< Source > | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            relevancy?: number | null,
            doorCount?: number | null,
            contactEmailBody?: string | null,
            contactEmailSubject?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetTransactionQueryVariables = {
    id: string,
};

export type GetTransactionQuery = {
    getTransaction?:  {
        __typename: "Transaction",
        id: string,
        amount: number,
        transactionDate: string,
        owner: string,
        type: string,
        category: Category,
        label?: Label | null,
        description?: string | null,
        isEditable?: boolean | null,
        readPermissions?: Array< string | null > | null,
        dashboardPropertyTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListTransactionsQueryVariables = {
    id?: string | null,
    filter?: ModelTransactionFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListTransactionsQuery = {
    listTransactions?:  {
        __typename: "ModelTransactionConnection",
        items:  Array< {
            __typename: "Transaction",
            id: string,
            amount: number,
            transactionDate: string,
            owner: string,
            type: string,
            category: Category,
            label?: Label | null,
            description?: string | null,
            isEditable?: boolean | null,
            readPermissions?: Array< string | null > | null,
            dashboardPropertyTransactionsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type ListPropertyTransactionByPropertyIdQueryVariables = {
    dashboardPropertyTransactionsId: string,
    transactionDate?: ModelStringKeyConditionInput | null,
    sortDirection?: ModelSortDirection | null,
    filter?: ModelTransactionFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
};

export type ListPropertyTransactionByPropertyIdQuery = {
    listPropertyTransactionByPropertyId?:  {
        __typename: "ModelTransactionConnection",
        items:  Array< {
            __typename: "Transaction",
            id: string,
            amount: number,
            transactionDate: string,
            owner: string,
            type: string,
            category: Category,
            label?: Label | null,
            description?: string | null,
            isEditable?: boolean | null,
            readPermissions?: Array< string | null > | null,
            dashboardPropertyTransactionsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type SearchTransactionsQueryVariables = {
    filter?: SearchableTransactionFilterInput | null,
    sort?: Array< SearchableTransactionSortInput | null > | null,
    limit?: number | null,
    nextToken?: string | null,
    from?: number | null,
    aggregates?: Array< SearchableTransactionAggregationInput | null > | null,
};

export type SearchTransactionsQuery = {
    searchTransactions?:  {
        __typename: "SearchableTransactionConnection",
        items:  Array< {
            __typename: "Transaction",
            id: string,
            amount: number,
            transactionDate: string,
            owner: string,
            type: string,
            category: Category,
            label?: Label | null,
            description?: string | null,
            isEditable?: boolean | null,
            readPermissions?: Array< string | null > | null,
            dashboardPropertyTransactionsId: string,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
        total?: number | null,
        aggregateItems:  Array< {
            __typename: "SearchableAggregateResult",
            name: string,
            result: ( {
                __typename: "SearchableAggregateScalarResult",
                value: number,
            } | {
                __typename: "SearchableAggregateBucketResult",
                buckets?:  Array< {
                    __typename: string,
                    key: string,
                    doc_count: number,
                } | null > | null,
            }
                ) | null,
        } | null >,
    } | null,
};

export type ListUnitsQueryVariables = {
    id?: string | null,
    filter?: ModelUnitFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListUnitsQuery = {
    listUnits?:  {
        __typename: "ModelUnitConnection",
        items:  Array< {
            __typename: "Unit",
            id: string,
            bedrooms?: number | null,
            bathrooms?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyUnitsId?: string | null,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type GetUserActionsQueryVariables = {
    owner: string,
};

export type GetUserActionsQuery = {
    getUserActions?:  {
        __typename: "UserActions",
        owner: string,
        pm: string,
        viewedWelcomeToMarketplaceScreen?: boolean | null,
        viewedCashOutTooltip?: boolean | null,
        dashboardProformaOverride?: string | null,
        viewedAnnualReportBanner?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type ListUserActionsQueryVariables = {
    owner?: string | null,
    filter?: ModelUserActionsFilterInput | null,
    limit?: number | null,
    nextToken?: string | null,
    sortDirection?: ModelSortDirection | null,
};

export type ListUserActionsQuery = {
    listUserActions?:  {
        __typename: "ModelUserActionsConnection",
        items:  Array< {
            __typename: "UserActions",
            owner: string,
            pm: string,
            viewedWelcomeToMarketplaceScreen?: boolean | null,
            viewedCashOutTooltip?: boolean | null,
            dashboardProformaOverride?: string | null,
            viewedAnnualReportBanner?: boolean | null,
            createdTime: string,
            updatedTime: string,
        } | null >,
        nextToken?: string | null,
    } | null,
};

export type OnCreateAppSettingsSubscriptionVariables = {
    filter?: ModelSubscriptionAppSettingsFilterInput | null,
};

export type OnCreateAppSettingsSubscription = {
    onCreateAppSettings?:  {
        __typename: "AppSettings",
        id: string,
        iamDomain?: string | null,
        iamClientId?: string | null,
        iamAudience?: string | null,
        title?: string | null,
        tenant?: string | null,
        pmName?: string | null,
        color?: string | null,
        logo?: string | null,
        favicon?: string | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnUpdateAppSettingsSubscriptionVariables = {
    filter?: ModelSubscriptionAppSettingsFilterInput | null,
};

export type OnUpdateAppSettingsSubscription = {
    onUpdateAppSettings?:  {
        __typename: "AppSettings",
        id: string,
        iamDomain?: string | null,
        iamClientId?: string | null,
        iamAudience?: string | null,
        title?: string | null,
        tenant?: string | null,
        pmName?: string | null,
        color?: string | null,
        logo?: string | null,
        favicon?: string | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnDeleteAppSettingsSubscriptionVariables = {
    filter?: ModelSubscriptionAppSettingsFilterInput | null,
};

export type OnDeleteAppSettingsSubscription = {
    onDeleteAppSettings?:  {
        __typename: "AppSettings",
        id: string,
        iamDomain?: string | null,
        iamClientId?: string | null,
        iamAudience?: string | null,
        title?: string | null,
        tenant?: string | null,
        pmName?: string | null,
        color?: string | null,
        logo?: string | null,
        favicon?: string | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnCreateBuyBoxLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionBuyBoxLeadWorkflowItemFilterInput | null,
};

export type OnCreateBuyBoxLeadWorkflowItemSubscription = {
    onCreateBuyBoxLeadWorkflowItem?:  {
        __typename: "BuyBoxLeadWorkflowItem",
        id: string,
        leadContactDetails?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        timeline?: string | null,
        amount?: string | null,
        propertyType?: string | null,
        financing?: string | null,
        status: BuyBoxLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateBuyBoxLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionBuyBoxLeadWorkflowItemFilterInput | null,
};

export type OnUpdateBuyBoxLeadWorkflowItemSubscription = {
    onUpdateBuyBoxLeadWorkflowItem?:  {
        __typename: "BuyBoxLeadWorkflowItem",
        id: string,
        leadContactDetails?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        timeline?: string | null,
        amount?: string | null,
        propertyType?: string | null,
        financing?: string | null,
        status: BuyBoxLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteBuyBoxLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionBuyBoxLeadWorkflowItemFilterInput | null,
};

export type OnDeleteBuyBoxLeadWorkflowItemSubscription = {
    onDeleteBuyBoxLeadWorkflowItem?:  {
        __typename: "BuyBoxLeadWorkflowItem",
        id: string,
        leadContactDetails?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        timeline?: string | null,
        amount?: string | null,
        propertyType?: string | null,
        financing?: string | null,
        status: BuyBoxLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateBuyerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionBuyerLeadWorkflowItemFilterInput | null,
};

export type OnCreateBuyerLeadWorkflowItemSubscription = {
    onCreateBuyerLeadWorkflowItem?:  {
        __typename: "BuyerLeadWorkflowItem",
        id: string,
        buyerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        sellerAgentContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        askingPrice: number,
        pm: string,
        statusChangeTime: string,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId: string,
        isPropertyVisible?: boolean | null,
        status: BuyerLeadsWorkflowStatus,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateBuyerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionBuyerLeadWorkflowItemFilterInput | null,
};

export type OnUpdateBuyerLeadWorkflowItemSubscription = {
    onUpdateBuyerLeadWorkflowItem?:  {
        __typename: "BuyerLeadWorkflowItem",
        id: string,
        buyerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        sellerAgentContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        askingPrice: number,
        pm: string,
        statusChangeTime: string,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId: string,
        isPropertyVisible?: boolean | null,
        status: BuyerLeadsWorkflowStatus,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteBuyerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionBuyerLeadWorkflowItemFilterInput | null,
};

export type OnDeleteBuyerLeadWorkflowItemSubscription = {
    onDeleteBuyerLeadWorkflowItem?:  {
        __typename: "BuyerLeadWorkflowItem",
        id: string,
        buyerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        sellerAgentContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        askingPrice: number,
        pm: string,
        statusChangeTime: string,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId: string,
        isPropertyVisible?: boolean | null,
        status: BuyerLeadsWorkflowStatus,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateDashboardPropertySubscriptionVariables = {
    filter?: ModelSubscriptionDashboardPropertyFilterInput | null,
    owner?: string | null,
};

export type OnCreateDashboardPropertySubscription = {
    onCreateDashboardProperty?:  {
        __typename: "DashboardProperty",
        id: string,
        owner: string,
        name: string,
        transactions?:  {
            __typename: "ModelTransactionConnection",
            nextToken?: string | null,
        } | null,
        pmTransactions?:  {
            __typename: "ModelPMTransactionConnection",
            nextToken?: string | null,
        } | null,
        leases?:  {
            __typename: "ModelLeaseConnection",
            nextToken?: string | null,
        } | null,
        userExpense?:  {
            __typename: "ModelPropertyUserExpenseConnection",
            nextToken?: string | null,
        } | null,
        amortizationTable?:  {
            __typename: "ModelPropertyAmortizationTableConnection",
            nextToken?: string | null,
        } | null,
        mortgage?:  {
            __typename: "ModelMortgageConnection",
            nextToken?: string | null,
        } | null,
        propertyValuation?:  {
            __typename: "ModelDashboardPropertyValuationConnection",
            nextToken?: string | null,
        } | null,
        purchaseHistory?:  {
            __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
            nextToken?: string | null,
        } | null,
        metrics?:  {
            __typename: "ModelDashboardPropertyMetricConnection",
            nextToken?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        hoaMuted?: boolean | null,
        pmId: string,
        readPermissions?: Array< string | null > | null,
        deleted?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyDashboardPropertyId?: string | null,
    } | null,
};

export type OnUpdateDashboardPropertySubscriptionVariables = {
    filter?: ModelSubscriptionDashboardPropertyFilterInput | null,
    owner?: string | null,
};

export type OnUpdateDashboardPropertySubscription = {
    onUpdateDashboardProperty?:  {
        __typename: "DashboardProperty",
        id: string,
        owner: string,
        name: string,
        transactions?:  {
            __typename: "ModelTransactionConnection",
            nextToken?: string | null,
        } | null,
        pmTransactions?:  {
            __typename: "ModelPMTransactionConnection",
            nextToken?: string | null,
        } | null,
        leases?:  {
            __typename: "ModelLeaseConnection",
            nextToken?: string | null,
        } | null,
        userExpense?:  {
            __typename: "ModelPropertyUserExpenseConnection",
            nextToken?: string | null,
        } | null,
        amortizationTable?:  {
            __typename: "ModelPropertyAmortizationTableConnection",
            nextToken?: string | null,
        } | null,
        mortgage?:  {
            __typename: "ModelMortgageConnection",
            nextToken?: string | null,
        } | null,
        propertyValuation?:  {
            __typename: "ModelDashboardPropertyValuationConnection",
            nextToken?: string | null,
        } | null,
        purchaseHistory?:  {
            __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
            nextToken?: string | null,
        } | null,
        metrics?:  {
            __typename: "ModelDashboardPropertyMetricConnection",
            nextToken?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        hoaMuted?: boolean | null,
        pmId: string,
        readPermissions?: Array< string | null > | null,
        deleted?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyDashboardPropertyId?: string | null,
    } | null,
};

export type OnDeleteDashboardPropertySubscriptionVariables = {
    filter?: ModelSubscriptionDashboardPropertyFilterInput | null,
    owner?: string | null,
};

export type OnDeleteDashboardPropertySubscription = {
    onDeleteDashboardProperty?:  {
        __typename: "DashboardProperty",
        id: string,
        owner: string,
        name: string,
        transactions?:  {
            __typename: "ModelTransactionConnection",
            nextToken?: string | null,
        } | null,
        pmTransactions?:  {
            __typename: "ModelPMTransactionConnection",
            nextToken?: string | null,
        } | null,
        leases?:  {
            __typename: "ModelLeaseConnection",
            nextToken?: string | null,
        } | null,
        userExpense?:  {
            __typename: "ModelPropertyUserExpenseConnection",
            nextToken?: string | null,
        } | null,
        amortizationTable?:  {
            __typename: "ModelPropertyAmortizationTableConnection",
            nextToken?: string | null,
        } | null,
        mortgage?:  {
            __typename: "ModelMortgageConnection",
            nextToken?: string | null,
        } | null,
        propertyValuation?:  {
            __typename: "ModelDashboardPropertyValuationConnection",
            nextToken?: string | null,
        } | null,
        purchaseHistory?:  {
            __typename: "ModelDashboardPropertyPurchaseHistoryConnection",
            nextToken?: string | null,
        } | null,
        metrics?:  {
            __typename: "ModelDashboardPropertyMetricConnection",
            nextToken?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        hoaMuted?: boolean | null,
        pmId: string,
        readPermissions?: Array< string | null > | null,
        deleted?: boolean | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        propertyDashboardPropertyId?: string | null,
    } | null,
};

export type OnCreateFavoriteMarketplacePropertySubscriptionVariables = {
    filter?: ModelSubscriptionFavoriteMarketplacePropertyFilterInput | null,
    owner?: string | null,
};

export type OnCreateFavoriteMarketplacePropertySubscription = {
    onCreateFavoriteMarketplaceProperty?:  {
        __typename: "FavoriteMarketplaceProperty",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyFavoritesId?: string | null,
    } | null,
};

export type OnUpdateFavoriteMarketplacePropertySubscriptionVariables = {
    filter?: ModelSubscriptionFavoriteMarketplacePropertyFilterInput | null,
    owner?: string | null,
};

export type OnUpdateFavoriteMarketplacePropertySubscription = {
    onUpdateFavoriteMarketplaceProperty?:  {
        __typename: "FavoriteMarketplaceProperty",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyFavoritesId?: string | null,
    } | null,
};

export type OnDeleteFavoriteMarketplacePropertySubscriptionVariables = {
    filter?: ModelSubscriptionFavoriteMarketplacePropertyFilterInput | null,
    owner?: string | null,
};

export type OnDeleteFavoriteMarketplacePropertySubscription = {
    onDeleteFavoriteMarketplaceProperty?:  {
        __typename: "FavoriteMarketplaceProperty",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyFavoritesId?: string | null,
    } | null,
};

export type OnCreateGrowthPolicySubscriptionVariables = {
    filter?: ModelSubscriptionGrowthPolicyFilterInput | null,
    id?: string | null,
};

export type OnCreateGrowthPolicySubscription = {
    onCreateGrowthPolicy?:  {
        __typename: "GrowthPolicy",
        id: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        agentReferralFeeAmount?: number | null,
        agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
        agentProvidedBenefitsOther?: string | null,
        enableOwnerReferralProgram?: boolean | null,
        ownerReferralsIncentive?: OwnerReferralsIncentive | null,
        enablePMReferralProgram?: boolean | null,
        pmReferralsFeeAmount?: number | null,
        typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        yearsInBusiness?: number | null,
        website?: string | null,
        companyDescription?: string | null,
        pmAvatarURL?: string | null,
        pmFirstName?: string | null,
        pmLastName?: string | null,
        pmTitle?: string | null,
        companyGoogleReviews?: number | null,
        companyAVGDayOnMarket?: number | null,
        companyTenantRenewalRate?: number | null,
        companyServices?: Array< CompanyServices > | null,
        companyServicesOther?: string | null,
        companyGuarantees?: Array< CompanyGuarantees > | null,
        companyGuaranteesOther?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateGrowthPolicySubscriptionVariables = {
    filter?: ModelSubscriptionGrowthPolicyFilterInput | null,
    id?: string | null,
};

export type OnUpdateGrowthPolicySubscription = {
    onUpdateGrowthPolicy?:  {
        __typename: "GrowthPolicy",
        id: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        agentReferralFeeAmount?: number | null,
        agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
        agentProvidedBenefitsOther?: string | null,
        enableOwnerReferralProgram?: boolean | null,
        ownerReferralsIncentive?: OwnerReferralsIncentive | null,
        enablePMReferralProgram?: boolean | null,
        pmReferralsFeeAmount?: number | null,
        typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        yearsInBusiness?: number | null,
        website?: string | null,
        companyDescription?: string | null,
        pmAvatarURL?: string | null,
        pmFirstName?: string | null,
        pmLastName?: string | null,
        pmTitle?: string | null,
        companyGoogleReviews?: number | null,
        companyAVGDayOnMarket?: number | null,
        companyTenantRenewalRate?: number | null,
        companyServices?: Array< CompanyServices > | null,
        companyServicesOther?: string | null,
        companyGuarantees?: Array< CompanyGuarantees > | null,
        companyGuaranteesOther?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteGrowthPolicySubscriptionVariables = {
    filter?: ModelSubscriptionGrowthPolicyFilterInput | null,
    id?: string | null,
};

export type OnDeleteGrowthPolicySubscription = {
    onDeleteGrowthPolicy?:  {
        __typename: "GrowthPolicy",
        id: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        agentReferralFeeAmount?: number | null,
        agentProvidedBenefits?: Array< AgentProvidedBenefit > | null,
        agentProvidedBenefitsOther?: string | null,
        enableOwnerReferralProgram?: boolean | null,
        ownerReferralsIncentive?: OwnerReferralsIncentive | null,
        enablePMReferralProgram?: boolean | null,
        pmReferralsFeeAmount?: number | null,
        typeOfPropertiesManaged?: Array< ManagedPropertyType > | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        yearsInBusiness?: number | null,
        website?: string | null,
        companyDescription?: string | null,
        pmAvatarURL?: string | null,
        pmFirstName?: string | null,
        pmLastName?: string | null,
        pmTitle?: string | null,
        companyGoogleReviews?: number | null,
        companyAVGDayOnMarket?: number | null,
        companyTenantRenewalRate?: number | null,
        companyServices?: Array< CompanyServices > | null,
        companyServicesOther?: string | null,
        companyGuarantees?: Array< CompanyGuarantees > | null,
        companyGuaranteesOther?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateLeaseSubscriptionVariables = {
    filter?: ModelSubscriptionLeaseFilterInput | null,
    owner?: string | null,
};

export type OnCreateLeaseSubscription = {
    onCreateLease?:  {
        __typename: "Lease",
        id: string,
        startDate: string,
        endDate?: string | null,
        isActive?: boolean | null,
        term?: Term | null,
        rent?: number | null,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        tenant?: string | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyLeasesId?: string | null,
    } | null,
};

export type OnUpdateLeaseSubscriptionVariables = {
    filter?: ModelSubscriptionLeaseFilterInput | null,
    owner?: string | null,
};

export type OnUpdateLeaseSubscription = {
    onUpdateLease?:  {
        __typename: "Lease",
        id: string,
        startDate: string,
        endDate?: string | null,
        isActive?: boolean | null,
        term?: Term | null,
        rent?: number | null,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        tenant?: string | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyLeasesId?: string | null,
    } | null,
};

export type OnDeleteLeaseSubscriptionVariables = {
    filter?: ModelSubscriptionLeaseFilterInput | null,
    owner?: string | null,
};

export type OnDeleteLeaseSubscription = {
    onDeleteLease?:  {
        __typename: "Lease",
        id: string,
        startDate: string,
        endDate?: string | null,
        isActive?: boolean | null,
        term?: Term | null,
        rent?: number | null,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        tenant?: string | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyLeasesId?: string | null,
    } | null,
};

export type OnCreateLegislationDataSubscriptionVariables = {
    filter?: ModelSubscriptionLegislationDataFilterInput | null,
};

export type OnCreateLegislationDataSubscription = {
    onCreateLegislationData?:  {
        __typename: "LegislationData",
        id: string,
        state: string,
        billID?: number | null,
        lastActionDate?: string | null,
        billNumber?: string | null,
        title?: string | null,
        url?: string | null,
        relevance?: number | null,
        lastAction?: string | null,
        status?: BillStatus | null,
        summary?: string | null,
        executiveSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateLegislationDataSubscriptionVariables = {
    filter?: ModelSubscriptionLegislationDataFilterInput | null,
};

export type OnUpdateLegislationDataSubscription = {
    onUpdateLegislationData?:  {
        __typename: "LegislationData",
        id: string,
        state: string,
        billID?: number | null,
        lastActionDate?: string | null,
        billNumber?: string | null,
        title?: string | null,
        url?: string | null,
        relevance?: number | null,
        lastAction?: string | null,
        status?: BillStatus | null,
        summary?: string | null,
        executiveSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteLegislationDataSubscriptionVariables = {
    filter?: ModelSubscriptionLegislationDataFilterInput | null,
};

export type OnDeleteLegislationDataSubscription = {
    onDeleteLegislationData?:  {
        __typename: "LegislationData",
        id: string,
        state: string,
        billID?: number | null,
        lastActionDate?: string | null,
        billNumber?: string | null,
        title?: string | null,
        url?: string | null,
        relevance?: number | null,
        lastAction?: string | null,
        status?: BillStatus | null,
        summary?: string | null,
        executiveSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateMarketDataSubscriptionVariables = {
    filter?: ModelSubscriptionMarketDataFilterInput | null,
};

export type OnCreateMarketDataSubscription = {
    onCreateMarketData?:  {
        __typename: "MarketData",
        id: string,
        fips: string,
        dataType?: string | null,
        date: string,
        areaName?: string | null,
        homeValueSFR?: number | null,
        homeValueAll?: number | null,
        marketHeat?: number | null,
        rentSFR?: number | null,
        rentAll?: number | null,
        daysOnMarket?: number | null,
        marketSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateMarketDataSubscriptionVariables = {
    filter?: ModelSubscriptionMarketDataFilterInput | null,
};

export type OnUpdateMarketDataSubscription = {
    onUpdateMarketData?:  {
        __typename: "MarketData",
        id: string,
        fips: string,
        dataType?: string | null,
        date: string,
        areaName?: string | null,
        homeValueSFR?: number | null,
        homeValueAll?: number | null,
        marketHeat?: number | null,
        rentSFR?: number | null,
        rentAll?: number | null,
        daysOnMarket?: number | null,
        marketSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteMarketDataSubscriptionVariables = {
    filter?: ModelSubscriptionMarketDataFilterInput | null,
};

export type OnDeleteMarketDataSubscription = {
    onDeleteMarketData?:  {
        __typename: "MarketData",
        id: string,
        fips: string,
        dataType?: string | null,
        date: string,
        areaName?: string | null,
        homeValueSFR?: number | null,
        homeValueAll?: number | null,
        marketHeat?: number | null,
        rentSFR?: number | null,
        rentAll?: number | null,
        daysOnMarket?: number | null,
        marketSummary?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateMarketPlacePropertyPictureSubscriptionVariables = {
    filter?: ModelSubscriptionMarketPlacePropertyPictureFilterInput | null,
};

export type OnCreateMarketPlacePropertyPictureSubscription = {
    onCreateMarketPlacePropertyPicture?:  {
        __typename: "MarketPlacePropertyPicture",
        url: string,
        rank?: number | null,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        marketplacePropertyListingPicturesId: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateMarketPlacePropertyPictureSubscriptionVariables = {
    filter?: ModelSubscriptionMarketPlacePropertyPictureFilterInput | null,
};

export type OnUpdateMarketPlacePropertyPictureSubscription = {
    onUpdateMarketPlacePropertyPicture?:  {
        __typename: "MarketPlacePropertyPicture",
        url: string,
        rank?: number | null,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        marketplacePropertyListingPicturesId: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteMarketPlacePropertyPictureSubscriptionVariables = {
    filter?: ModelSubscriptionMarketPlacePropertyPictureFilterInput | null,
};

export type OnDeleteMarketPlacePropertyPictureSubscription = {
    onDeleteMarketPlacePropertyPicture?:  {
        __typename: "MarketPlacePropertyPicture",
        url: string,
        rank?: number | null,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        marketplacePropertyListingPicturesId: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateMarketplacePMPreferencesSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePMPreferencesFilterInput | null,
    pm?: string | null,
};

export type OnCreateMarketplacePMPreferencesSubscription = {
    onCreateMarketplacePMPreferences?:  {
        __typename: "MarketplacePMPreferences",
        pm: string,
        managementFee?: string | null,
        leadsThirdPartyIntegrationRequested?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateMarketplacePMPreferencesSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePMPreferencesFilterInput | null,
    pm?: string | null,
};

export type OnUpdateMarketplacePMPreferencesSubscription = {
    onUpdateMarketplacePMPreferences?:  {
        __typename: "MarketplacePMPreferences",
        pm: string,
        managementFee?: string | null,
        leadsThirdPartyIntegrationRequested?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteMarketplacePMPreferencesSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePMPreferencesFilterInput | null,
    pm?: string | null,
};

export type OnDeleteMarketplacePMPreferencesSubscription = {
    onDeleteMarketplacePMPreferences?:  {
        __typename: "MarketplacePMPreferences",
        pm: string,
        managementFee?: string | null,
        leadsThirdPartyIntegrationRequested?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateMarketplacePropertySubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyFilterInput | null,
    owner?: string | null,
};

export type OnCreateMarketplacePropertySubscription = {
    onCreateMarketplaceProperty?:  {
        __typename: "MarketplaceProperty",
        id: string,
        askingPrice: number,
        owner: string,
        status: MarketPlacePropertyStatus,
        closedBy?: string | null,
        activatedTime?: string | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        description?: string | null,
        rent?: number | null,
        constructionStatus?: ConstructionStatus | null,
        category?: MarketplacePropertyCategory | null,
        source?: string | null,
        vendor?: string | null,
        listingPictures?:  {
            __typename: "ModelMarketPlacePropertyPictureConnection",
            nextToken?: string | null,
        } | null,
        expenses?:  {
            __typename: "MarketPlacePropertyExpenses",
            hoa?: number | null,
            insurance?: number | null,
            managementFee?: number | null,
            other?: number | null,
            tax?: number | null,
        } | null,
        favorites?:  {
            __typename: "ModelFavoriteMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        recommendations?:  {
            __typename: "ModelMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        pmRecommendations?:  {
            __typename: "ModelPmMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        offers?:  {
            __typename: "ModelOfferConnection",
            nextToken?: string | null,
        } | null,
        crime?:  {
            __typename: "ModelCrimeConnection",
            nextToken?: string | null,
        } | null,
        readPermissions?: Array< string | null > | null,
        relevantPMs?: Array< string | null > | null,
        shareWith?: Array< string | null > | null,
        propertyRental?:  {
            __typename: "ModelMarketplacePropertyRentalConnection",
            nextToken?: string | null,
        } | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        listingItems?:  {
            __typename: "ModelMarketplacePropertyListingItemConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
        propertyMarketplacePropertyId?: string | null,
    } | null,
};

export type OnUpdateMarketplacePropertySubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyFilterInput | null,
    owner?: string | null,
};

export type OnUpdateMarketplacePropertySubscription = {
    onUpdateMarketplaceProperty?:  {
        __typename: "MarketplaceProperty",
        id: string,
        askingPrice: number,
        owner: string,
        status: MarketPlacePropertyStatus,
        closedBy?: string | null,
        activatedTime?: string | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        description?: string | null,
        rent?: number | null,
        constructionStatus?: ConstructionStatus | null,
        category?: MarketplacePropertyCategory | null,
        source?: string | null,
        vendor?: string | null,
        listingPictures?:  {
            __typename: "ModelMarketPlacePropertyPictureConnection",
            nextToken?: string | null,
        } | null,
        expenses?:  {
            __typename: "MarketPlacePropertyExpenses",
            hoa?: number | null,
            insurance?: number | null,
            managementFee?: number | null,
            other?: number | null,
            tax?: number | null,
        } | null,
        favorites?:  {
            __typename: "ModelFavoriteMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        recommendations?:  {
            __typename: "ModelMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        pmRecommendations?:  {
            __typename: "ModelPmMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        offers?:  {
            __typename: "ModelOfferConnection",
            nextToken?: string | null,
        } | null,
        crime?:  {
            __typename: "ModelCrimeConnection",
            nextToken?: string | null,
        } | null,
        readPermissions?: Array< string | null > | null,
        relevantPMs?: Array< string | null > | null,
        shareWith?: Array< string | null > | null,
        propertyRental?:  {
            __typename: "ModelMarketplacePropertyRentalConnection",
            nextToken?: string | null,
        } | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        listingItems?:  {
            __typename: "ModelMarketplacePropertyListingItemConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
        propertyMarketplacePropertyId?: string | null,
    } | null,
};

export type OnDeleteMarketplacePropertySubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyFilterInput | null,
    owner?: string | null,
};

export type OnDeleteMarketplacePropertySubscription = {
    onDeleteMarketplaceProperty?:  {
        __typename: "MarketplaceProperty",
        id: string,
        askingPrice: number,
        owner: string,
        status: MarketPlacePropertyStatus,
        closedBy?: string | null,
        activatedTime?: string | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        description?: string | null,
        rent?: number | null,
        constructionStatus?: ConstructionStatus | null,
        category?: MarketplacePropertyCategory | null,
        source?: string | null,
        vendor?: string | null,
        listingPictures?:  {
            __typename: "ModelMarketPlacePropertyPictureConnection",
            nextToken?: string | null,
        } | null,
        expenses?:  {
            __typename: "MarketPlacePropertyExpenses",
            hoa?: number | null,
            insurance?: number | null,
            managementFee?: number | null,
            other?: number | null,
            tax?: number | null,
        } | null,
        favorites?:  {
            __typename: "ModelFavoriteMarketplacePropertyConnection",
            nextToken?: string | null,
        } | null,
        recommendations?:  {
            __typename: "ModelMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        pmRecommendations?:  {
            __typename: "ModelPmMarketplacePropertyRecommendationConnection",
            nextToken?: string | null,
        } | null,
        offers?:  {
            __typename: "ModelOfferConnection",
            nextToken?: string | null,
        } | null,
        crime?:  {
            __typename: "ModelCrimeConnection",
            nextToken?: string | null,
        } | null,
        readPermissions?: Array< string | null > | null,
        relevantPMs?: Array< string | null > | null,
        shareWith?: Array< string | null > | null,
        propertyRental?:  {
            __typename: "ModelMarketplacePropertyRentalConnection",
            nextToken?: string | null,
        } | null,
        property:  {
            __typename: "Property",
            id: string,
            propertyType?: PropertyType | null,
            yearBuilt?: number | null,
            bedrooms?: number | null,
            bathrooms?: number | null,
            garages?: number | null,
            lotSize?: number | null,
            sqft?: number | null,
            isVacant?: boolean | null,
            owner: string,
            createdTime: string,
            updatedTime: string,
        },
        listingItems?:  {
            __typename: "ModelMarketplacePropertyListingItemConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
        propertyMarketplacePropertyId?: string | null,
    } | null,
};

export type OnCreateMarketplacePropertyListingItemSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyListingItemFilterInput | null,
};

export type OnCreateMarketplacePropertyListingItemSubscription = {
    onCreateMarketplacePropertyListingItem?:  {
        __typename: "MarketplacePropertyListingItem",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        property?:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null,
        status: MarketPlacePropertyStatus,
        actualRent?: number | null,
        estimatedRent?: number | null,
        stateInsuranceFactor?: number | null,
        totalExpenses?: number | null,
        maintenance?: number | null,
        estimatedVacancy?: number | null,
        managementFee?: string | null,
        capRate?: number | null,
        noi?: number | null,
        cashOnCash?: number | null,
        grossYield?: number | null,
        insurance?: number | null,
        askingPrice: number,
        bedrooms?: number | null,
        bathrooms?: number | null,
        category: string,
        categoryOrder: number,
        tags: Array< ListingItemTag >,
        partialAddresses: Array< string >,
        isManagedByThePM?: boolean | null,
        canBeManagedByThePM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyListingItemsId?: string | null,
    } | null,
};

export type OnUpdateMarketplacePropertyListingItemSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyListingItemFilterInput | null,
};

export type OnUpdateMarketplacePropertyListingItemSubscription = {
    onUpdateMarketplacePropertyListingItem?:  {
        __typename: "MarketplacePropertyListingItem",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        property?:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null,
        status: MarketPlacePropertyStatus,
        actualRent?: number | null,
        estimatedRent?: number | null,
        stateInsuranceFactor?: number | null,
        totalExpenses?: number | null,
        maintenance?: number | null,
        estimatedVacancy?: number | null,
        managementFee?: string | null,
        capRate?: number | null,
        noi?: number | null,
        cashOnCash?: number | null,
        grossYield?: number | null,
        insurance?: number | null,
        askingPrice: number,
        bedrooms?: number | null,
        bathrooms?: number | null,
        category: string,
        categoryOrder: number,
        tags: Array< ListingItemTag >,
        partialAddresses: Array< string >,
        isManagedByThePM?: boolean | null,
        canBeManagedByThePM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyListingItemsId?: string | null,
    } | null,
};

export type OnDeleteMarketplacePropertyListingItemSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyListingItemFilterInput | null,
};

export type OnDeleteMarketplacePropertyListingItemSubscription = {
    onDeleteMarketplacePropertyListingItem?:  {
        __typename: "MarketplacePropertyListingItem",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        property?:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        } | null,
        status: MarketPlacePropertyStatus,
        actualRent?: number | null,
        estimatedRent?: number | null,
        stateInsuranceFactor?: number | null,
        totalExpenses?: number | null,
        maintenance?: number | null,
        estimatedVacancy?: number | null,
        managementFee?: string | null,
        capRate?: number | null,
        noi?: number | null,
        cashOnCash?: number | null,
        grossYield?: number | null,
        insurance?: number | null,
        askingPrice: number,
        bedrooms?: number | null,
        bathrooms?: number | null,
        category: string,
        categoryOrder: number,
        tags: Array< ListingItemTag >,
        partialAddresses: Array< string >,
        isManagedByThePM?: boolean | null,
        canBeManagedByThePM?: boolean | null,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyListingItemsId?: string | null,
    } | null,
};

export type OnCreateMarketplacePropertyPmRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyPmRecommendationFilterInput | null,
    pm?: string | null,
};

export type OnCreateMarketplacePropertyPmRecommendationSubscription = {
    onCreateMarketplacePropertyPmRecommendation?:  {
        __typename: "MarketplacePropertyPmRecommendation",
        pm: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateMarketplacePropertyPmRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyPmRecommendationFilterInput | null,
    pm?: string | null,
};

export type OnUpdateMarketplacePropertyPmRecommendationSubscription = {
    onUpdateMarketplacePropertyPmRecommendation?:  {
        __typename: "MarketplacePropertyPmRecommendation",
        pm: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteMarketplacePropertyPmRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyPmRecommendationFilterInput | null,
    pm?: string | null,
};

export type OnDeleteMarketplacePropertyPmRecommendationSubscription = {
    onDeleteMarketplacePropertyPmRecommendation?:  {
        __typename: "MarketplacePropertyPmRecommendation",
        pm: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyRecommendationFilterInput | null,
    owner?: string | null,
};

export type OnCreateMarketplacePropertyRecommendationSubscription = {
    onCreateMarketplacePropertyRecommendation?:  {
        __typename: "MarketplacePropertyRecommendation",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyRecommendationsId?: string | null,
    } | null,
};

export type OnUpdateMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyRecommendationFilterInput | null,
    owner?: string | null,
};

export type OnUpdateMarketplacePropertyRecommendationSubscription = {
    onUpdateMarketplacePropertyRecommendation?:  {
        __typename: "MarketplacePropertyRecommendation",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyRecommendationsId?: string | null,
    } | null,
};

export type OnDeleteMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionMarketplacePropertyRecommendationFilterInput | null,
    owner?: string | null,
};

export type OnDeleteMarketplacePropertyRecommendationSubscription = {
    onDeleteMarketplacePropertyRecommendation?:  {
        __typename: "MarketplacePropertyRecommendation",
        id: string,
        owner: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyRecommendationsId?: string | null,
    } | null,
};

export type OnCreateMortgageSubscriptionVariables = {
    filter?: ModelSubscriptionMortgageFilterInput | null,
    owner?: string | null,
};

export type OnCreateMortgageSubscription = {
    onCreateMortgage?:  {
        __typename: "Mortgage",
        id: string,
        rate: number,
        amount: number,
        startDate: string,
        mortgageDueDay: number,
        term: number,
        owner: string,
        grantor: string,
        apn: string,
        mortgageType: MortgageType,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        provider?: Provider | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyMortgageId?: string | null,
    } | null,
};

export type OnUpdateMortgageSubscriptionVariables = {
    filter?: ModelSubscriptionMortgageFilterInput | null,
    owner?: string | null,
};

export type OnUpdateMortgageSubscription = {
    onUpdateMortgage?:  {
        __typename: "Mortgage",
        id: string,
        rate: number,
        amount: number,
        startDate: string,
        mortgageDueDay: number,
        term: number,
        owner: string,
        grantor: string,
        apn: string,
        mortgageType: MortgageType,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        provider?: Provider | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyMortgageId?: string | null,
    } | null,
};

export type OnDeleteMortgageSubscriptionVariables = {
    filter?: ModelSubscriptionMortgageFilterInput | null,
    owner?: string | null,
};

export type OnDeleteMortgageSubscription = {
    onDeleteMortgage?:  {
        __typename: "Mortgage",
        id: string,
        rate: number,
        amount: number,
        startDate: string,
        mortgageDueDay: number,
        term: number,
        owner: string,
        grantor: string,
        apn: string,
        mortgageType: MortgageType,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        provider?: Provider | null,
        createdTime: string,
        updatedTime: string,
        dashboardPropertyMortgageId?: string | null,
    } | null,
};

export type OnCreateNotificationTemplateSubscriptionVariables = {
    filter?: ModelSubscriptionNotificationTemplateFilterInput | null,
};

export type OnCreateNotificationTemplateSubscription = {
    onCreateNotificationTemplate?:  {
        __typename: "NotificationTemplate",
        id: string,
        template: string,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateNotificationTemplateSubscriptionVariables = {
    filter?: ModelSubscriptionNotificationTemplateFilterInput | null,
};

export type OnUpdateNotificationTemplateSubscription = {
    onUpdateNotificationTemplate?:  {
        __typename: "NotificationTemplate",
        id: string,
        template: string,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteNotificationTemplateSubscriptionVariables = {
    filter?: ModelSubscriptionNotificationTemplateFilterInput | null,
};

export type OnDeleteNotificationTemplateSubscription = {
    onDeleteNotificationTemplate?:  {
        __typename: "NotificationTemplate",
        id: string,
        template: string,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateOfferSubscriptionVariables = {
    filter?: ModelSubscriptionOfferFilterInput | null,
    buyer?: string | null,
    seller?: string | null,
};

export type OnCreateOfferSubscription = {
    onCreateOffer?:  {
        __typename: "Offer",
        id: string,
        buyer: string,
        seller?: string | null,
        bid?: number | null,
        status: OfferStatus,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyOffersId?: string | null,
    } | null,
};

export type OnUpdateOfferSubscriptionVariables = {
    filter?: ModelSubscriptionOfferFilterInput | null,
    buyer?: string | null,
    seller?: string | null,
};

export type OnUpdateOfferSubscription = {
    onUpdateOffer?:  {
        __typename: "Offer",
        id: string,
        buyer: string,
        seller?: string | null,
        bid?: number | null,
        status: OfferStatus,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyOffersId?: string | null,
    } | null,
};

export type OnDeleteOfferSubscriptionVariables = {
    filter?: ModelSubscriptionOfferFilterInput | null,
    buyer?: string | null,
    seller?: string | null,
};

export type OnDeleteOfferSubscription = {
    onDeleteOffer?:  {
        __typename: "Offer",
        id: string,
        buyer: string,
        seller?: string | null,
        bid?: number | null,
        status: OfferStatus,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        marketplacePropertyOffersId?: string | null,
    } | null,
};

export type OnCreateOwnerLeadSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadFilterInput | null,
};

export type OnCreateOwnerLeadSubscription = {
    onCreateOwnerLead?:  {
        __typename: "OwnerLead",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        pm?: string | null,
        requestRentalAnalysis?: boolean | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        numOfProperties?: number | null,
        addresses?: Array< string | null > | null,
        referrer?: string | null,
        source?: OwnerLeadSource | null,
        readPermissions?: Array< string | null > | null,
        ownerLeadWorkflowItem?:  {
            __typename: "ModelOwnerLeadWorkflowItemConnection",
            nextToken?: string | null,
        } | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadId?: string | null,
    } | null,
};

export type OnUpdateOwnerLeadSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadFilterInput | null,
};

export type OnUpdateOwnerLeadSubscription = {
    onUpdateOwnerLead?:  {
        __typename: "OwnerLead",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        pm?: string | null,
        requestRentalAnalysis?: boolean | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        numOfProperties?: number | null,
        addresses?: Array< string | null > | null,
        referrer?: string | null,
        source?: OwnerLeadSource | null,
        readPermissions?: Array< string | null > | null,
        ownerLeadWorkflowItem?:  {
            __typename: "ModelOwnerLeadWorkflowItemConnection",
            nextToken?: string | null,
        } | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadId?: string | null,
    } | null,
};

export type OnDeleteOwnerLeadSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadFilterInput | null,
};

export type OnDeleteOwnerLeadSubscription = {
    onDeleteOwnerLead?:  {
        __typename: "OwnerLead",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: string | null,
        pm?: string | null,
        requestRentalAnalysis?: boolean | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        numOfProperties?: number | null,
        addresses?: Array< string | null > | null,
        referrer?: string | null,
        source?: OwnerLeadSource | null,
        readPermissions?: Array< string | null > | null,
        ownerLeadWorkflowItem?:  {
            __typename: "ModelOwnerLeadWorkflowItemConnection",
            nextToken?: string | null,
        } | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadId?: string | null,
    } | null,
};

export type OnCreateOwnerLeadReferralLinkSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadReferralLinkFilterInput | null,
    ownerId?: string | null,
};

export type OnCreateOwnerLeadReferralLinkSubscription = {
    onCreateOwnerLeadReferralLink?:  {
        __typename: "OwnerLeadReferralLink",
        id: string,
        ownerId: string,
        pm?: string | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadReferralLinkId?: string | null,
    } | null,
};

export type OnUpdateOwnerLeadReferralLinkSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadReferralLinkFilterInput | null,
    ownerId?: string | null,
};

export type OnUpdateOwnerLeadReferralLinkSubscription = {
    onUpdateOwnerLeadReferralLink?:  {
        __typename: "OwnerLeadReferralLink",
        id: string,
        ownerId: string,
        pm?: string | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadReferralLinkId?: string | null,
    } | null,
};

export type OnDeleteOwnerLeadReferralLinkSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadReferralLinkFilterInput | null,
    ownerId?: string | null,
};

export type OnDeleteOwnerLeadReferralLinkSubscription = {
    onDeleteOwnerLeadReferralLink?:  {
        __typename: "OwnerLeadReferralLink",
        id: string,
        ownerId: string,
        pm?: string | null,
        partner?:  {
            __typename: "Partner",
            id: string,
            pm?: string | null,
            readPermissions?: Array< string > | null,
            partnerType: PartnerType,
            partnerPM?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            referralAmount?: number | null,
            referralFreeMonths?: number | null,
            phoneNumber?: string | null,
            avatar?: string | null,
            address?: string | null,
            website?: string | null,
            experienceInYears?: number | null,
            doorCount?: number | null,
            minPrice?: number | null,
            maxPrice?: number | null,
            propertyTypes?: Array< WorkPropertyType > | null,
            activity?: Activity | null,
            suggestedPartnerPartnerId?: string | null,
            createdTime: string,
            updatedTime: string,
        } | null,
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
        partnerOwnerLeadReferralLinkId?: string | null,
    } | null,
};

export type OnCreateOwnerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadWorkflowItemFilterInput | null,
};

export type OnCreateOwnerLeadWorkflowItemSubscription = {
    onCreateOwnerLeadWorkflowItem?:  {
        __typename: "OwnerLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        pm: string,
        requestRentalAnalysis?: boolean | null,
        numOfProperties?: number | null,
        addresses?: Array< string > | null,
        referrer?: string | null,
        source?: OwnerLeadsWorkflowSource | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        status: OwnerLeadsWorkflowStatus,
        revealed?: boolean | null,
        ownerLead?:  {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        readShareWith?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
        ownerLeadOwnerLeadWorkflowItemId?: string | null,
    } | null,
};

export type OnUpdateOwnerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadWorkflowItemFilterInput | null,
};

export type OnUpdateOwnerLeadWorkflowItemSubscription = {
    onUpdateOwnerLeadWorkflowItem?:  {
        __typename: "OwnerLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        pm: string,
        requestRentalAnalysis?: boolean | null,
        numOfProperties?: number | null,
        addresses?: Array< string > | null,
        referrer?: string | null,
        source?: OwnerLeadsWorkflowSource | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        status: OwnerLeadsWorkflowStatus,
        revealed?: boolean | null,
        ownerLead?:  {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        readShareWith?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
        ownerLeadOwnerLeadWorkflowItemId?: string | null,
    } | null,
};

export type OnDeleteOwnerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerLeadWorkflowItemFilterInput | null,
};

export type OnDeleteOwnerLeadWorkflowItemSubscription = {
    onDeleteOwnerLeadWorkflowItem?:  {
        __typename: "OwnerLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        pm: string,
        requestRentalAnalysis?: boolean | null,
        numOfProperties?: number | null,
        addresses?: Array< string > | null,
        referrer?: string | null,
        source?: OwnerLeadsWorkflowSource | null,
        whatAreYouLookingFor?: WhatAreYouLookingFor | null,
        status: OwnerLeadsWorkflowStatus,
        revealed?: boolean | null,
        ownerLead?:  {
            __typename: "OwnerLead",
            id: string,
            email: string,
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
            pm?: string | null,
            requestRentalAnalysis?: boolean | null,
            whatAreYouLookingFor?: WhatAreYouLookingFor | null,
            numOfProperties?: number | null,
            addresses?: Array< string | null > | null,
            referrer?: string | null,
            source?: OwnerLeadSource | null,
            readPermissions?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            partnerOwnerLeadId?: string | null,
        } | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        readShareWith?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
        ownerLeadOwnerLeadWorkflowItemId?: string | null,
    } | null,
};

export type OnCreateOwnerMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerMarketplacePropertyRecommendationFilterInput | null,
    owner?: string | null,
};

export type OnCreateOwnerMarketplacePropertyRecommendationSubscription = {
    onCreateOwnerMarketplacePropertyRecommendation?:  {
        __typename: "OwnerMarketplacePropertyRecommendation",
        owner: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateOwnerMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerMarketplacePropertyRecommendationFilterInput | null,
    owner?: string | null,
};

export type OnUpdateOwnerMarketplacePropertyRecommendationSubscription = {
    onUpdateOwnerMarketplacePropertyRecommendation?:  {
        __typename: "OwnerMarketplacePropertyRecommendation",
        owner: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteOwnerMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerMarketplacePropertyRecommendationFilterInput | null,
    owner?: string | null,
};

export type OnDeleteOwnerMarketplacePropertyRecommendationSubscription = {
    onDeleteOwnerMarketplacePropertyRecommendation?:  {
        __typename: "OwnerMarketplacePropertyRecommendation",
        owner: string,
        recommendations?:  Array< {
            __typename: "Recommendation",
            propertyId: string,
            score: number,
        } | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateOwnerReportSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerReportFilterInput | null,
    owner?: string | null,
};

export type OnCreateOwnerReportSubscription = {
    onCreateOwnerReport?:  {
        __typename: "OwnerReport",
        id: string,
        owner: string,
        month: number,
        year: number,
        reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnUpdateOwnerReportSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerReportFilterInput | null,
    owner?: string | null,
};

export type OnUpdateOwnerReportSubscription = {
    onUpdateOwnerReport?:  {
        __typename: "OwnerReport",
        id: string,
        owner: string,
        month: number,
        year: number,
        reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnDeleteOwnerReportSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerReportFilterInput | null,
    owner?: string | null,
};

export type OnDeleteOwnerReportSubscription = {
    onDeleteOwnerReport?:  {
        __typename: "OwnerReport",
        id: string,
        owner: string,
        month: number,
        year: number,
        reports?:  {
            __typename: "ModelReportConnection",
            nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnCreateOwnerRetentionDetailsSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRetentionDetailsFilterInput | null,
};

export type OnCreateOwnerRetentionDetailsSubscription = {
    onCreateOwnerRetentionDetails?:  {
        __typename: "OwnerRetentionDetails",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        satisfactionLevel?: SatisfactionLevel | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRetentionDetailsId?: string | null,
    } | null,
};

export type OnUpdateOwnerRetentionDetailsSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRetentionDetailsFilterInput | null,
};

export type OnUpdateOwnerRetentionDetailsSubscription = {
    onUpdateOwnerRetentionDetails?:  {
        __typename: "OwnerRetentionDetails",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        satisfactionLevel?: SatisfactionLevel | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRetentionDetailsId?: string | null,
    } | null,
};

export type OnDeleteOwnerRetentionDetailsSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRetentionDetailsFilterInput | null,
};

export type OnDeleteOwnerRetentionDetailsSubscription = {
    onDeleteOwnerRetentionDetails?:  {
        __typename: "OwnerRetentionDetails",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        satisfactionLevel?: SatisfactionLevel | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRetentionDetailsId?: string | null,
    } | null,
};

export type OnCreateOwnerRiskSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRiskFilterInput | null,
};

export type OnCreateOwnerRiskSubscription = {
    onCreateOwnerRisk?:  {
        __typename: "OwnerRisk",
        id: string,
        ownerID: string,
        pm: string,
        ownerSince?: string | null,
        ownerDetails:  {
            __typename: "RiskOwnerDetails",
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        risk?: number | null,
        riskLevel?: RiskLevel | null,
        rawRisk?: number | null,
        scorePerProperty?:  Array< {
            __typename: "PropertyScore",
            propertyID?: string | null,
            score?: number | null,
            rawScore?: number | null,
        } > | null,
        severity: OwnerRiskSeverity,
        severityScore?: number | null,
        severityManualOverride?: boolean | null,
        insights?:  Array< {
            __typename: "OwnerRiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            weight?: number | null,
            value?: number | null,
        } > | null,
        clientValue: number,
        clientValueBreakDown?:  Array< {
            __typename: "ClientValueCategory",
            category?: PMTransactionCategory | null,
            value?: number | null,
        } > | null,
        clientValueGroup: ClientValueGroup,
        status: OwnerRiskStatus,
        resolvedReason?: ResolvedReason | null,
        resolutionExplanation?: string | null,
        inProgressSetDate?: string | null,
        resolvedDate?: string | null,
        statusChangedDate?: string | null,
        recommendations?:  {
            __typename: "ModelOwnerRiskRecommendationConnection",
            nextToken?: string | null,
        } | null,
        ownerRiskTrends?:  {
            __typename: "ModelOwnerRiskTrendConnection",
            nextToken?: string | null,
        } | null,
        ownerRetentionDetails?:  {
            __typename: "ModelOwnerRetentionDetailsConnection",
            nextToken?: string | null,
        } | null,
        ownerRisksInsights?:  {
            __typename: "ModelRiskInsightConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateOwnerRiskSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRiskFilterInput | null,
};

export type OnUpdateOwnerRiskSubscription = {
    onUpdateOwnerRisk?:  {
        __typename: "OwnerRisk",
        id: string,
        ownerID: string,
        pm: string,
        ownerSince?: string | null,
        ownerDetails:  {
            __typename: "RiskOwnerDetails",
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        risk?: number | null,
        riskLevel?: RiskLevel | null,
        rawRisk?: number | null,
        scorePerProperty?:  Array< {
            __typename: "PropertyScore",
            propertyID?: string | null,
            score?: number | null,
            rawScore?: number | null,
        } > | null,
        severity: OwnerRiskSeverity,
        severityScore?: number | null,
        severityManualOverride?: boolean | null,
        insights?:  Array< {
            __typename: "OwnerRiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            weight?: number | null,
            value?: number | null,
        } > | null,
        clientValue: number,
        clientValueBreakDown?:  Array< {
            __typename: "ClientValueCategory",
            category?: PMTransactionCategory | null,
            value?: number | null,
        } > | null,
        clientValueGroup: ClientValueGroup,
        status: OwnerRiskStatus,
        resolvedReason?: ResolvedReason | null,
        resolutionExplanation?: string | null,
        inProgressSetDate?: string | null,
        resolvedDate?: string | null,
        statusChangedDate?: string | null,
        recommendations?:  {
            __typename: "ModelOwnerRiskRecommendationConnection",
            nextToken?: string | null,
        } | null,
        ownerRiskTrends?:  {
            __typename: "ModelOwnerRiskTrendConnection",
            nextToken?: string | null,
        } | null,
        ownerRetentionDetails?:  {
            __typename: "ModelOwnerRetentionDetailsConnection",
            nextToken?: string | null,
        } | null,
        ownerRisksInsights?:  {
            __typename: "ModelRiskInsightConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteOwnerRiskSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRiskFilterInput | null,
};

export type OnDeleteOwnerRiskSubscription = {
    onDeleteOwnerRisk?:  {
        __typename: "OwnerRisk",
        id: string,
        ownerID: string,
        pm: string,
        ownerSince?: string | null,
        ownerDetails:  {
            __typename: "RiskOwnerDetails",
            firstName?: string | null,
            lastName?: string | null,
            phoneNumber?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        risk?: number | null,
        riskLevel?: RiskLevel | null,
        rawRisk?: number | null,
        scorePerProperty?:  Array< {
            __typename: "PropertyScore",
            propertyID?: string | null,
            score?: number | null,
            rawScore?: number | null,
        } > | null,
        severity: OwnerRiskSeverity,
        severityScore?: number | null,
        severityManualOverride?: boolean | null,
        insights?:  Array< {
            __typename: "OwnerRiskInsight",
            id: string,
            caseName?: string | null,
            propertyID?: string | null,
            ownerEmail?: string | null,
            level: InsightLevel,
            insightType?: InsightType | null,
            weight?: number | null,
            value?: number | null,
        } > | null,
        clientValue: number,
        clientValueBreakDown?:  Array< {
            __typename: "ClientValueCategory",
            category?: PMTransactionCategory | null,
            value?: number | null,
        } > | null,
        clientValueGroup: ClientValueGroup,
        status: OwnerRiskStatus,
        resolvedReason?: ResolvedReason | null,
        resolutionExplanation?: string | null,
        inProgressSetDate?: string | null,
        resolvedDate?: string | null,
        statusChangedDate?: string | null,
        recommendations?:  {
            __typename: "ModelOwnerRiskRecommendationConnection",
            nextToken?: string | null,
        } | null,
        ownerRiskTrends?:  {
            __typename: "ModelOwnerRiskTrendConnection",
            nextToken?: string | null,
        } | null,
        ownerRetentionDetails?:  {
            __typename: "ModelOwnerRetentionDetailsConnection",
            nextToken?: string | null,
        } | null,
        ownerRisksInsights?:  {
            __typename: "ModelRiskInsightConnection",
            nextToken?: string | null,
        } | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateOwnerRiskTrendSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRiskTrendFilterInput | null,
};

export type OnCreateOwnerRiskTrendSubscription = {
    onCreateOwnerRiskTrend?:  {
        __typename: "OwnerRiskTrend",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        risk?: number | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRiskTrendsId?: string | null,
    } | null,
};

export type OnUpdateOwnerRiskTrendSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRiskTrendFilterInput | null,
};

export type OnUpdateOwnerRiskTrendSubscription = {
    onUpdateOwnerRiskTrend?:  {
        __typename: "OwnerRiskTrend",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        risk?: number | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRiskTrendsId?: string | null,
    } | null,
};

export type OnDeleteOwnerRiskTrendSubscriptionVariables = {
    filter?: ModelSubscriptionOwnerRiskTrendFilterInput | null,
};

export type OnDeleteOwnerRiskTrendSubscription = {
    onDeleteOwnerRiskTrend?:  {
        __typename: "OwnerRiskTrend",
        id: string,
        pm: string,
        readPermissions?: Array< string | null > | null,
        risk?: number | null,
        ownerRisk:  {
            __typename: "OwnerRisk",
            id: string,
            ownerID: string,
            pm: string,
            ownerSince?: string | null,
            readPermissions?: Array< string | null > | null,
            updatePermissions?: Array< string | null > | null,
            risk?: number | null,
            riskLevel?: RiskLevel | null,
            rawRisk?: number | null,
            severity: OwnerRiskSeverity,
            severityScore?: number | null,
            severityManualOverride?: boolean | null,
            clientValue: number,
            clientValueGroup: ClientValueGroup,
            status: OwnerRiskStatus,
            resolvedReason?: ResolvedReason | null,
            resolutionExplanation?: string | null,
            inProgressSetDate?: string | null,
            resolvedDate?: string | null,
            statusChangedDate?: string | null,
            createdTime: string,
            updatedTime: string,
        },
        createdTime: string,
        updatedTime: string,
        ownerRiskOwnerRiskTrendsId?: string | null,
    } | null,
};

export type OnCreatePMMetricsSubscriptionVariables = {
    filter?: ModelSubscriptionPMMetricsFilterInput | null,
};

export type OnCreatePMMetricsSubscription = {
    onCreatePMMetrics?:  {
        __typename: "PMMetrics",
        id: string,
        usersMetrics?:  {
            __typename: "UsersMetrics",
            activatedUsers: number,
            activatedUsersLastMonth?: number | null,
            invitedUsers: number,
            invitedUsersLastMonth?: number | null,
            createdTime: string,
        } | null,
        communicationMetrics?:  {
            __typename: "CommunicationMetrics",
            emailsSentThisMonth: number,
            createdTime: string,
        } | null,
        propertyMetrics?:  {
            __typename: "PropertyMetrics",
            properties: number,
            units: number,
            createdTime: string,
        } | null,
        riskMetrics?:  {
            __typename: "OwnerRiskMetrics",
            totalNoRisk: number,
            noRiskTotalValue: number,
            totalNeedsAttention: number,
            needsAttentionTotalValue: number,
            totalInProgress: number,
            inProgressTotalValue: number,
            totalResolved: number,
            resolvedTotalValue: number,
            createdTime: string,
        } | null,
        leadMetrics?:  {
            __typename: "LeadMetrics",
            totalReferrals: number,
            totalReferralsValue: number,
            totalBuyerLeads: number,
            totalBuyerLeadsValue: number,
            totalSellerLeads: number,
            totalSellerLeadsValue: number,
            totalProspects: number,
            totalProspectsValue: number,
            totalBuyBoxLeads: number,
            totalBuyBoxLeadsValue: number,
            createdTime: string,
        } | null,
        readPermissions?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdatePMMetricsSubscriptionVariables = {
    filter?: ModelSubscriptionPMMetricsFilterInput | null,
};

export type OnUpdatePMMetricsSubscription = {
    onUpdatePMMetrics?:  {
        __typename: "PMMetrics",
        id: string,
        usersMetrics?:  {
            __typename: "UsersMetrics",
            activatedUsers: number,
            activatedUsersLastMonth?: number | null,
            invitedUsers: number,
            invitedUsersLastMonth?: number | null,
            createdTime: string,
        } | null,
        communicationMetrics?:  {
            __typename: "CommunicationMetrics",
            emailsSentThisMonth: number,
            createdTime: string,
        } | null,
        propertyMetrics?:  {
            __typename: "PropertyMetrics",
            properties: number,
            units: number,
            createdTime: string,
        } | null,
        riskMetrics?:  {
            __typename: "OwnerRiskMetrics",
            totalNoRisk: number,
            noRiskTotalValue: number,
            totalNeedsAttention: number,
            needsAttentionTotalValue: number,
            totalInProgress: number,
            inProgressTotalValue: number,
            totalResolved: number,
            resolvedTotalValue: number,
            createdTime: string,
        } | null,
        leadMetrics?:  {
            __typename: "LeadMetrics",
            totalReferrals: number,
            totalReferralsValue: number,
            totalBuyerLeads: number,
            totalBuyerLeadsValue: number,
            totalSellerLeads: number,
            totalSellerLeadsValue: number,
            totalProspects: number,
            totalProspectsValue: number,
            totalBuyBoxLeads: number,
            totalBuyBoxLeadsValue: number,
            createdTime: string,
        } | null,
        readPermissions?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeletePMMetricsSubscriptionVariables = {
    filter?: ModelSubscriptionPMMetricsFilterInput | null,
};

export type OnDeletePMMetricsSubscription = {
    onDeletePMMetrics?:  {
        __typename: "PMMetrics",
        id: string,
        usersMetrics?:  {
            __typename: "UsersMetrics",
            activatedUsers: number,
            activatedUsersLastMonth?: number | null,
            invitedUsers: number,
            invitedUsersLastMonth?: number | null,
            createdTime: string,
        } | null,
        communicationMetrics?:  {
            __typename: "CommunicationMetrics",
            emailsSentThisMonth: number,
            createdTime: string,
        } | null,
        propertyMetrics?:  {
            __typename: "PropertyMetrics",
            properties: number,
            units: number,
            createdTime: string,
        } | null,
        riskMetrics?:  {
            __typename: "OwnerRiskMetrics",
            totalNoRisk: number,
            noRiskTotalValue: number,
            totalNeedsAttention: number,
            needsAttentionTotalValue: number,
            totalInProgress: number,
            inProgressTotalValue: number,
            totalResolved: number,
            resolvedTotalValue: number,
            createdTime: string,
        } | null,
        leadMetrics?:  {
            __typename: "LeadMetrics",
            totalReferrals: number,
            totalReferralsValue: number,
            totalBuyerLeads: number,
            totalBuyerLeadsValue: number,
            totalSellerLeads: number,
            totalSellerLeadsValue: number,
            totalProspects: number,
            totalProspectsValue: number,
            totalBuyBoxLeads: number,
            totalBuyBoxLeadsValue: number,
            createdTime: string,
        } | null,
        readPermissions?: Array< string > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreatePMSettingsSubscriptionVariables = {
    filter?: ModelSubscriptionPMSettingsFilterInput | null,
};

export type OnCreatePMSettingsSubscription = {
    onCreatePMSettings?:  {
        __typename: "PMSettings",
        pm: string,
        leadershipSummary2024?: string | null,
        dashboardProformaMetricsOverride?: string | null,
        showLegalSummary?: boolean | null,
        obpTitleOverride?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdatePMSettingsSubscriptionVariables = {
    filter?: ModelSubscriptionPMSettingsFilterInput | null,
};

export type OnUpdatePMSettingsSubscription = {
    onUpdatePMSettings?:  {
        __typename: "PMSettings",
        pm: string,
        leadershipSummary2024?: string | null,
        dashboardProformaMetricsOverride?: string | null,
        showLegalSummary?: boolean | null,
        obpTitleOverride?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeletePMSettingsSubscriptionVariables = {
    filter?: ModelSubscriptionPMSettingsFilterInput | null,
};

export type OnDeletePMSettingsSubscription = {
    onDeletePMSettings?:  {
        __typename: "PMSettings",
        pm: string,
        leadershipSummary2024?: string | null,
        dashboardProformaMetricsOverride?: string | null,
        showLegalSummary?: boolean | null,
        obpTitleOverride?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreatePMTransactionSubscriptionVariables = {
    filter?: ModelSubscriptionPMTransactionFilterInput | null,
};

export type OnCreatePMTransactionSubscription = {
    onCreatePMTransaction?:  {
        __typename: "PMTransaction",
        id: string,
        amount: number,
        transactionDate: string,
        pm: string,
        owner: string,
        type: string,
        category: PMTransactionCategory,
        label?: Label | null,
        description?: string | null,
        dashboardPropertyPmTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdatePMTransactionSubscriptionVariables = {
    filter?: ModelSubscriptionPMTransactionFilterInput | null,
};

export type OnUpdatePMTransactionSubscription = {
    onUpdatePMTransaction?:  {
        __typename: "PMTransaction",
        id: string,
        amount: number,
        transactionDate: string,
        pm: string,
        owner: string,
        type: string,
        category: PMTransactionCategory,
        label?: Label | null,
        description?: string | null,
        dashboardPropertyPmTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeletePMTransactionSubscriptionVariables = {
    filter?: ModelSubscriptionPMTransactionFilterInput | null,
};

export type OnDeletePMTransactionSubscription = {
    onDeletePMTransaction?:  {
        __typename: "PMTransaction",
        id: string,
        amount: number,
        transactionDate: string,
        pm: string,
        owner: string,
        type: string,
        category: PMTransactionCategory,
        label?: Label | null,
        description?: string | null,
        dashboardPropertyPmTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreatePartnerSubscriptionVariables = {
    filter?: ModelSubscriptionPartnerFilterInput | null,
};

export type OnCreatePartnerSubscription = {
    onCreatePartner?:  {
        __typename: "Partner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        partnerType: PartnerType,
        partnerPM?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        referralAmount?: number | null,
        referralFreeMonths?: number | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        address?: string | null,
        website?: string | null,
        experienceInYears?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        ownerLead?:  {
            __typename: "ModelOwnerLeadConnection",
            nextToken?: string | null,
        } | null,
        ownerLeadReferralLink?:  {
            __typename: "ModelOwnerLeadReferralLinkConnection",
            nextToken?: string | null,
        } | null,
        suggestedPartnerPartnerId?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdatePartnerSubscriptionVariables = {
    filter?: ModelSubscriptionPartnerFilterInput | null,
};

export type OnUpdatePartnerSubscription = {
    onUpdatePartner?:  {
        __typename: "Partner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        partnerType: PartnerType,
        partnerPM?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        referralAmount?: number | null,
        referralFreeMonths?: number | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        address?: string | null,
        website?: string | null,
        experienceInYears?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        ownerLead?:  {
            __typename: "ModelOwnerLeadConnection",
            nextToken?: string | null,
        } | null,
        ownerLeadReferralLink?:  {
            __typename: "ModelOwnerLeadReferralLinkConnection",
            nextToken?: string | null,
        } | null,
        suggestedPartnerPartnerId?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeletePartnerSubscriptionVariables = {
    filter?: ModelSubscriptionPartnerFilterInput | null,
};

export type OnDeletePartnerSubscription = {
    onDeletePartner?:  {
        __typename: "Partner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        partnerType: PartnerType,
        partnerPM?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        referralAmount?: number | null,
        referralFreeMonths?: number | null,
        phoneNumber?: string | null,
        avatar?: string | null,
        address?: string | null,
        website?: string | null,
        experienceInYears?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        ownerLead?:  {
            __typename: "ModelOwnerLeadConnection",
            nextToken?: string | null,
        } | null,
        ownerLeadReferralLink?:  {
            __typename: "ModelOwnerLeadReferralLinkConnection",
            nextToken?: string | null,
        } | null,
        suggestedPartnerPartnerId?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreatePmMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionPmMarketplacePropertyRecommendationFilterInput | null,
    pm?: string | null,
};

export type OnCreatePmMarketplacePropertyRecommendationSubscription = {
    onCreatePmMarketplacePropertyRecommendation?:  {
        __typename: "PmMarketplacePropertyRecommendation",
        id: string,
        pm: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyPmRecommendationsId?: string | null,
    } | null,
};

export type OnUpdatePmMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionPmMarketplacePropertyRecommendationFilterInput | null,
    pm?: string | null,
};

export type OnUpdatePmMarketplacePropertyRecommendationSubscription = {
    onUpdatePmMarketplacePropertyRecommendation?:  {
        __typename: "PmMarketplacePropertyRecommendation",
        id: string,
        pm: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyPmRecommendationsId?: string | null,
    } | null,
};

export type OnDeletePmMarketplacePropertyRecommendationSubscriptionVariables = {
    filter?: ModelSubscriptionPmMarketplacePropertyRecommendationFilterInput | null,
    pm?: string | null,
};

export type OnDeletePmMarketplacePropertyRecommendationSubscription = {
    onDeletePmMarketplacePropertyRecommendation?:  {
        __typename: "PmMarketplacePropertyRecommendation",
        id: string,
        pm: string,
        property:  {
            __typename: "MarketplaceProperty",
            id: string,
            askingPrice: number,
            owner: string,
            status: MarketPlacePropertyStatus,
            closedBy?: string | null,
            activatedTime?: string | null,
            description?: string | null,
            rent?: number | null,
            constructionStatus?: ConstructionStatus | null,
            category?: MarketplacePropertyCategory | null,
            source?: string | null,
            vendor?: string | null,
            readPermissions?: Array< string | null > | null,
            relevantPMs?: Array< string | null > | null,
            shareWith?: Array< string | null > | null,
            createdTime: string,
            updatedTime: string,
            propertyMarketplacePropertyId?: string | null,
        },
        score: number,
        createdTime: string,
        updatedTime: string,
        marketplacePropertyPmRecommendationsId?: string | null,
    } | null,
};

export type OnCreatePropertyAmortizationTableSubscriptionVariables = {
    filter?: ModelSubscriptionPropertyAmortizationTableFilterInput | null,
    owner?: string | null,
};

export type OnCreatePropertyAmortizationTableSubscription = {
    onCreatePropertyAmortizationTable?:  {
        __typename: "PropertyAmortizationTable",
        id: string,
        period: number,
        transactionDate: string,
        owner: string,
        payment: number,
        interest: number,
        principal: number,
        totalPrincipal: number,
        mortgageId: string,
        dashboardPropertyAmortizationTableId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdatePropertyAmortizationTableSubscriptionVariables = {
    filter?: ModelSubscriptionPropertyAmortizationTableFilterInput | null,
    owner?: string | null,
};

export type OnUpdatePropertyAmortizationTableSubscription = {
    onUpdatePropertyAmortizationTable?:  {
        __typename: "PropertyAmortizationTable",
        id: string,
        period: number,
        transactionDate: string,
        owner: string,
        payment: number,
        interest: number,
        principal: number,
        totalPrincipal: number,
        mortgageId: string,
        dashboardPropertyAmortizationTableId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeletePropertyAmortizationTableSubscriptionVariables = {
    filter?: ModelSubscriptionPropertyAmortizationTableFilterInput | null,
    owner?: string | null,
};

export type OnDeletePropertyAmortizationTableSubscription = {
    onDeletePropertyAmortizationTable?:  {
        __typename: "PropertyAmortizationTable",
        id: string,
        period: number,
        transactionDate: string,
        owner: string,
        payment: number,
        interest: number,
        principal: number,
        totalPrincipal: number,
        mortgageId: string,
        dashboardPropertyAmortizationTableId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        readPermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreatePropertyUserExpenseSubscriptionVariables = {
    filter?: ModelSubscriptionPropertyUserExpenseFilterInput | null,
    owner?: string | null,
};

export type OnCreatePropertyUserExpenseSubscription = {
    onCreatePropertyUserExpense?:  {
        __typename: "PropertyUserExpense",
        id: string,
        amount: number,
        startDate: string,
        category: ExpenseCategory,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyUserExpenseId?: string | null,
    } | null,
};

export type OnUpdatePropertyUserExpenseSubscriptionVariables = {
    filter?: ModelSubscriptionPropertyUserExpenseFilterInput | null,
    owner?: string | null,
};

export type OnUpdatePropertyUserExpenseSubscription = {
    onUpdatePropertyUserExpense?:  {
        __typename: "PropertyUserExpense",
        id: string,
        amount: number,
        startDate: string,
        category: ExpenseCategory,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyUserExpenseId?: string | null,
    } | null,
};

export type OnDeletePropertyUserExpenseSubscriptionVariables = {
    filter?: ModelSubscriptionPropertyUserExpenseFilterInput | null,
    owner?: string | null,
};

export type OnDeletePropertyUserExpenseSubscription = {
    onDeletePropertyUserExpense?:  {
        __typename: "PropertyUserExpense",
        id: string,
        amount: number,
        startDate: string,
        category: ExpenseCategory,
        owner: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
        dashboardPropertyUserExpenseId?: string | null,
    } | null,
};

export type OnCreateProspectLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionProspectLeadWorkflowItemFilterInput | null,
};

export type OnCreateProspectLeadWorkflowItemSubscription = {
    onCreateProspectLeadWorkflowItem?:  {
        __typename: "ProspectLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        status: ProspectLeadsWorkflowStatus,
        pm: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateProspectLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionProspectLeadWorkflowItemFilterInput | null,
};

export type OnUpdateProspectLeadWorkflowItemSubscription = {
    onUpdateProspectLeadWorkflowItem?:  {
        __typename: "ProspectLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        status: ProspectLeadsWorkflowStatus,
        pm: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteProspectLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionProspectLeadWorkflowItemFilterInput | null,
};

export type OnDeleteProspectLeadWorkflowItemSubscription = {
    onDeleteProspectLeadWorkflowItem?:  {
        __typename: "ProspectLeadWorkflowItem",
        id: string,
        lead?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        status: ProspectLeadsWorkflowStatus,
        pm: string,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateReportSubscriptionVariables = {
    filter?: ModelSubscriptionReportFilterInput | null,
    owner?: string | null,
};

export type OnCreateReportSubscription = {
    onCreateReport?:  {
        __typename: "Report",
        id: string,
        owner: string,
        name: string,
        version: number,
        totalOccupiedUnits: number,
        totalUnits: number,
        homeValue: number,
        appreciation: number,
        totalOtherIncome?: number | null,
        totalRent: number,
        totalEquity: number,
        noi: number,
        netCashFlow: number,
        ownerReport:  {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        },
        expenses:  {
            __typename: "Expenses",
            hoa: number,
            insurance: number,
            managementFee: number,
            other: number,
            tax: number,
            mortgage: number,
            utilities?: number | null,
            legalAndProfessionalFees?: number | null,
            rentalRegistration?: number | null,
            autoAndTravel?: number | null,
            supplies?: number | null,
            maintenance?: number | null,
            leasingAndMarketing?: number | null,
            totalExpense: number,
        },
        previousMonthlyMeasures:  {
            __typename: "PreviousMonthlyMeasures",
            homeValue: number,
            appreciation: number,
            totalEquity: number,
        },
        yearlyMeasures:  {
            __typename: "YearlyMeasures",
            capRate: number,
            cashOnCash: number,
        },
        reportType: ReportType,
        createdAt: string,
        updatedAt: string,
        ownerReportReportsId?: string | null,
    } | null,
};

export type OnUpdateReportSubscriptionVariables = {
    filter?: ModelSubscriptionReportFilterInput | null,
    owner?: string | null,
};

export type OnUpdateReportSubscription = {
    onUpdateReport?:  {
        __typename: "Report",
        id: string,
        owner: string,
        name: string,
        version: number,
        totalOccupiedUnits: number,
        totalUnits: number,
        homeValue: number,
        appreciation: number,
        totalOtherIncome?: number | null,
        totalRent: number,
        totalEquity: number,
        noi: number,
        netCashFlow: number,
        ownerReport:  {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        },
        expenses:  {
            __typename: "Expenses",
            hoa: number,
            insurance: number,
            managementFee: number,
            other: number,
            tax: number,
            mortgage: number,
            utilities?: number | null,
            legalAndProfessionalFees?: number | null,
            rentalRegistration?: number | null,
            autoAndTravel?: number | null,
            supplies?: number | null,
            maintenance?: number | null,
            leasingAndMarketing?: number | null,
            totalExpense: number,
        },
        previousMonthlyMeasures:  {
            __typename: "PreviousMonthlyMeasures",
            homeValue: number,
            appreciation: number,
            totalEquity: number,
        },
        yearlyMeasures:  {
            __typename: "YearlyMeasures",
            capRate: number,
            cashOnCash: number,
        },
        reportType: ReportType,
        createdAt: string,
        updatedAt: string,
        ownerReportReportsId?: string | null,
    } | null,
};

export type OnDeleteReportSubscriptionVariables = {
    filter?: ModelSubscriptionReportFilterInput | null,
    owner?: string | null,
};

export type OnDeleteReportSubscription = {
    onDeleteReport?:  {
        __typename: "Report",
        id: string,
        owner: string,
        name: string,
        version: number,
        totalOccupiedUnits: number,
        totalUnits: number,
        homeValue: number,
        appreciation: number,
        totalOtherIncome?: number | null,
        totalRent: number,
        totalEquity: number,
        noi: number,
        netCashFlow: number,
        ownerReport:  {
            __typename: "OwnerReport",
            id: string,
            owner: string,
            month: number,
            year: number,
            createdAt: string,
            updatedAt: string,
        },
        expenses:  {
            __typename: "Expenses",
            hoa: number,
            insurance: number,
            managementFee: number,
            other: number,
            tax: number,
            mortgage: number,
            utilities?: number | null,
            legalAndProfessionalFees?: number | null,
            rentalRegistration?: number | null,
            autoAndTravel?: number | null,
            supplies?: number | null,
            maintenance?: number | null,
            leasingAndMarketing?: number | null,
            totalExpense: number,
        },
        previousMonthlyMeasures:  {
            __typename: "PreviousMonthlyMeasures",
            homeValue: number,
            appreciation: number,
            totalEquity: number,
        },
        yearlyMeasures:  {
            __typename: "YearlyMeasures",
            capRate: number,
            cashOnCash: number,
        },
        reportType: ReportType,
        createdAt: string,
        updatedAt: string,
        ownerReportReportsId?: string | null,
    } | null,
};

export type OnCreateRetentionPolicySubscriptionVariables = {
    filter?: ModelSubscriptionRetentionPolicyFilterInput | null,
};

export type OnCreateRetentionPolicySubscription = {
    onCreateRetentionPolicy?:  {
        __typename: "RetentionPolicy",
        id: string,
        readPermissions: Array< string | null >,
        updatePermissions: Array< string | null >,
        pm: string,
        maxFinancialIncentive: MaxFinancialIncentive,
        giveIncentiveWillingness: Willingness,
        meetWillingness: Willingness,
        badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
        goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
        writingStyles: Array< WritingStyle >,
        riskDetectionSensitivity: RiskDetectionSensitivity,
        summaryLength: SummaryLength,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnUpdateRetentionPolicySubscriptionVariables = {
    filter?: ModelSubscriptionRetentionPolicyFilterInput | null,
};

export type OnUpdateRetentionPolicySubscription = {
    onUpdateRetentionPolicy?:  {
        __typename: "RetentionPolicy",
        id: string,
        readPermissions: Array< string | null >,
        updatePermissions: Array< string | null >,
        pm: string,
        maxFinancialIncentive: MaxFinancialIncentive,
        giveIncentiveWillingness: Willingness,
        meetWillingness: Willingness,
        badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
        goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
        writingStyles: Array< WritingStyle >,
        riskDetectionSensitivity: RiskDetectionSensitivity,
        summaryLength: SummaryLength,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnDeleteRetentionPolicySubscriptionVariables = {
    filter?: ModelSubscriptionRetentionPolicyFilterInput | null,
};

export type OnDeleteRetentionPolicySubscription = {
    onDeleteRetentionPolicy?:  {
        __typename: "RetentionPolicy",
        id: string,
        readPermissions: Array< string | null >,
        updatePermissions: Array< string | null >,
        pm: string,
        maxFinancialIncentive: MaxFinancialIncentive,
        giveIncentiveWillingness: Willingness,
        meetWillingness: Willingness,
        badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
        goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
        writingStyles: Array< WritingStyle >,
        riskDetectionSensitivity: RiskDetectionSensitivity,
        summaryLength: SummaryLength,
        createdAt: string,
        updatedAt: string,
    } | null,
};

export type OnCreateSellerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionSellerLeadWorkflowItemFilterInput | null,
};

export type OnCreateSellerLeadWorkflowItemSubscription = {
    onCreateSellerLeadWorkflowItem?:  {
        __typename: "SellerLeadWorkflowItem",
        id: string,
        sellerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId?: string | null,
        askingPrice: number,
        isPropertyVisible?: boolean | null,
        status: SellerLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateSellerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionSellerLeadWorkflowItemFilterInput | null,
};

export type OnUpdateSellerLeadWorkflowItemSubscription = {
    onUpdateSellerLeadWorkflowItem?:  {
        __typename: "SellerLeadWorkflowItem",
        id: string,
        sellerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId?: string | null,
        askingPrice: number,
        isPropertyVisible?: boolean | null,
        status: SellerLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteSellerLeadWorkflowItemSubscriptionVariables = {
    filter?: ModelSubscriptionSellerLeadWorkflowItemFilterInput | null,
};

export type OnDeleteSellerLeadWorkflowItemSubscription = {
    onDeleteSellerLeadWorkflowItem?:  {
        __typename: "SellerLeadWorkflowItem",
        id: string,
        sellerContact?:  {
            __typename: "ContactDetails",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            phoneNumber?: string | null,
        } | null,
        address:  {
            __typename: "Address",
            street1: string,
            street2?: string | null,
            city: string,
            state: string,
            zipCode: string,
        },
        propertyId?: string | null,
        askingPrice: number,
        isPropertyVisible?: boolean | null,
        status: SellerLeadsWorkflowStatus,
        pm: string,
        statusChangeTime: string,
        readShareWith?: Array< string > | null,
        updateShareWith?: Array< string > | null,
        readPermissions?: Array< string | null > | null,
        updatePermissions?: Array< string | null > | null,
        revealed?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateStateSubscriptionVariables = {
    filter?: ModelSubscriptionStateFilterInput | null,
};

export type OnCreateStateSubscription = {
    onCreateState?:  {
        __typename: "State",
        id: string,
        insuranceFactor?: number | null,
        name: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateStateSubscriptionVariables = {
    filter?: ModelSubscriptionStateFilterInput | null,
};

export type OnUpdateStateSubscription = {
    onUpdateState?:  {
        __typename: "State",
        id: string,
        insuranceFactor?: number | null,
        name: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteStateSubscriptionVariables = {
    filter?: ModelSubscriptionStateFilterInput | null,
};

export type OnDeleteStateSubscription = {
    onDeleteState?:  {
        __typename: "State",
        id: string,
        insuranceFactor?: number | null,
        name: string,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateSuggestedPartnerSubscriptionVariables = {
    filter?: ModelSubscriptionSuggestedPartnerFilterInput | null,
};

export type OnCreateSuggestedPartnerSubscription = {
    onCreateSuggestedPartner?:  {
        __typename: "SuggestedPartner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        status: SuggestedPartnerStatus,
        suggestedPartnerType: SuggestedPartnerType,
        suggestedPartnerPM?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        phoneNumbers?:  Array< {
            __typename: "PhoneNumber",
            number?: string | null,
            phoneType?: PhoneType | null,
        } > | null,
        avatar?: string | null,
        rating?: number | null,
        officeName?: string | null,
        address?: string | null,
        website?: string | null,
        last12MonthsSales?: number | null,
        totalSales?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        totalSalesCount?: number | null,
        totalForSaleCount?: number | null,
        lastTwoYearsSalesCount?: number | null,
        averageSale?: number | null,
        experienceInYears?: number | null,
        description?: string | null,
        profiles?:  Array< {
            __typename: "Profile",
            link?: string | null,
            profileType?: ProfileType | null,
        } > | null,
        sources?: Array< Source > | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        relevancy?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        contactEmailBody?: string | null,
        contactEmailSubject?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateSuggestedPartnerSubscriptionVariables = {
    filter?: ModelSubscriptionSuggestedPartnerFilterInput | null,
};

export type OnUpdateSuggestedPartnerSubscription = {
    onUpdateSuggestedPartner?:  {
        __typename: "SuggestedPartner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        status: SuggestedPartnerStatus,
        suggestedPartnerType: SuggestedPartnerType,
        suggestedPartnerPM?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        phoneNumbers?:  Array< {
            __typename: "PhoneNumber",
            number?: string | null,
            phoneType?: PhoneType | null,
        } > | null,
        avatar?: string | null,
        rating?: number | null,
        officeName?: string | null,
        address?: string | null,
        website?: string | null,
        last12MonthsSales?: number | null,
        totalSales?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        totalSalesCount?: number | null,
        totalForSaleCount?: number | null,
        lastTwoYearsSalesCount?: number | null,
        averageSale?: number | null,
        experienceInYears?: number | null,
        description?: string | null,
        profiles?:  Array< {
            __typename: "Profile",
            link?: string | null,
            profileType?: ProfileType | null,
        } > | null,
        sources?: Array< Source > | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        relevancy?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        contactEmailBody?: string | null,
        contactEmailSubject?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteSuggestedPartnerSubscriptionVariables = {
    filter?: ModelSubscriptionSuggestedPartnerFilterInput | null,
};

export type OnDeleteSuggestedPartnerSubscription = {
    onDeleteSuggestedPartner?:  {
        __typename: "SuggestedPartner",
        id: string,
        pm?: string | null,
        readPermissions?: Array< string > | null,
        updatePermissions?: Array< string > | null,
        status: SuggestedPartnerStatus,
        suggestedPartnerType: SuggestedPartnerType,
        suggestedPartnerPM?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        phoneNumbers?:  Array< {
            __typename: "PhoneNumber",
            number?: string | null,
            phoneType?: PhoneType | null,
        } > | null,
        avatar?: string | null,
        rating?: number | null,
        officeName?: string | null,
        address?: string | null,
        website?: string | null,
        last12MonthsSales?: number | null,
        totalSales?: number | null,
        minPrice?: number | null,
        maxPrice?: number | null,
        totalSalesCount?: number | null,
        totalForSaleCount?: number | null,
        lastTwoYearsSalesCount?: number | null,
        averageSale?: number | null,
        experienceInYears?: number | null,
        description?: string | null,
        profiles?:  Array< {
            __typename: "Profile",
            link?: string | null,
            profileType?: ProfileType | null,
        } > | null,
        sources?: Array< Source > | null,
        propertyTypes?: Array< WorkPropertyType > | null,
        activity?: Activity | null,
        relevancy?: number | null,
        workingCounties?:  Array< {
            __typename: "WorkingCounty",
            state: string,
            county: string,
        } > | null,
        doorCount?: number | null,
        contactEmailBody?: string | null,
        contactEmailSubject?: string | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateTransactionSubscriptionVariables = {
    filter?: ModelSubscriptionTransactionFilterInput | null,
    owner?: string | null,
};

export type OnCreateTransactionSubscription = {
    onCreateTransaction?:  {
        __typename: "Transaction",
        id: string,
        amount: number,
        transactionDate: string,
        owner: string,
        type: string,
        category: Category,
        label?: Label | null,
        description?: string | null,
        isEditable?: boolean | null,
        readPermissions?: Array< string | null > | null,
        dashboardPropertyTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateTransactionSubscriptionVariables = {
    filter?: ModelSubscriptionTransactionFilterInput | null,
    owner?: string | null,
};

export type OnUpdateTransactionSubscription = {
    onUpdateTransaction?:  {
        __typename: "Transaction",
        id: string,
        amount: number,
        transactionDate: string,
        owner: string,
        type: string,
        category: Category,
        label?: Label | null,
        description?: string | null,
        isEditable?: boolean | null,
        readPermissions?: Array< string | null > | null,
        dashboardPropertyTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteTransactionSubscriptionVariables = {
    filter?: ModelSubscriptionTransactionFilterInput | null,
    owner?: string | null,
};

export type OnDeleteTransactionSubscription = {
    onDeleteTransaction?:  {
        __typename: "Transaction",
        id: string,
        amount: number,
        transactionDate: string,
        owner: string,
        type: string,
        category: Category,
        label?: Label | null,
        description?: string | null,
        isEditable?: boolean | null,
        readPermissions?: Array< string | null > | null,
        dashboardPropertyTransactionsId: string,
        property:  {
            __typename: "DashboardProperty",
            id: string,
            owner: string,
            name: string,
            hoaMuted?: boolean | null,
            pmId: string,
            readPermissions?: Array< string | null > | null,
            deleted?: boolean | null,
            createdTime: string,
            updatedTime: string,
            propertyDashboardPropertyId?: string | null,
        },
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnCreateUserActionsSubscriptionVariables = {
    filter?: ModelSubscriptionUserActionsFilterInput | null,
    owner?: string | null,
};

export type OnCreateUserActionsSubscription = {
    onCreateUserActions?:  {
        __typename: "UserActions",
        owner: string,
        pm: string,
        viewedWelcomeToMarketplaceScreen?: boolean | null,
        viewedCashOutTooltip?: boolean | null,
        dashboardProformaOverride?: string | null,
        viewedAnnualReportBanner?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnUpdateUserActionsSubscriptionVariables = {
    filter?: ModelSubscriptionUserActionsFilterInput | null,
    owner?: string | null,
};

export type OnUpdateUserActionsSubscription = {
    onUpdateUserActions?:  {
        __typename: "UserActions",
        owner: string,
        pm: string,
        viewedWelcomeToMarketplaceScreen?: boolean | null,
        viewedCashOutTooltip?: boolean | null,
        dashboardProformaOverride?: string | null,
        viewedAnnualReportBanner?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};

export type OnDeleteUserActionsSubscriptionVariables = {
    filter?: ModelSubscriptionUserActionsFilterInput | null,
    owner?: string | null,
};

export type OnDeleteUserActionsSubscription = {
    onDeleteUserActions?:  {
        __typename: "UserActions",
        owner: string,
        pm: string,
        viewedWelcomeToMarketplaceScreen?: boolean | null,
        viewedCashOutTooltip?: boolean | null,
        dashboardProformaOverride?: string | null,
        viewedAnnualReportBanner?: boolean | null,
        createdTime: string,
        updatedTime: string,
    } | null,
};
