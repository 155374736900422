import { ReactNode, useMemo } from 'react';

import dayjs from 'dayjs';
import {
  buildPartnerReferralLink, OwnerLeadsWorkflowStatus, useAnalytics,
} from 'lib';
import { BsDoorClosed } from 'react-icons/bs';
import { GrMapLocation } from 'react-icons/gr';
import { IoMdGlobe } from 'react-icons/io';
import { IoPricetagOutline } from 'react-icons/io5';
import {
  MdClose, MdLocationPin, MdOutlineMapsHomeWork, MdPhone,
} from 'react-icons/md';
import { TbBriefcase2 } from 'react-icons/tb';
import { toast } from 'react-toastify';
import {
  Avatar, BoldTypography,
  Chip,
  DollarRect, formatNumber, formatNumberToCurrency,
  formatPotentialTodayDate,
  hexToRGBA,
  InfoTooltip,
  PersonSquareTopLeft, PropertyIcon, replaceUnderscoreWithSpace,
  SemiBoldTypography, Spacer, TableControls, TableView,
  ThemeColor, TooltipMetric, useLabels,
} from 'ui';
import {
  Button,
  Card,
  CardContent,
  Dialog, DialogActions, DialogContent, Grid, IconButton, Link, Stack, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';
import { ColumnDef } from '@tanstack/react-table';

import { PartnerTypeChip } from './components/PartnerType';
import { ListPartner, OwnerLeadItem } from '../../api/partners/types';
import { CopyEmail } from '../../components/copy-email/CopyEmail';
import { ManagedPropertyTypes } from '../opportunities/ManagedPropertyTypes';
import { WorkingCounties } from '../opportunities/WorkingCounties';

const metricIconSize = 40;

const BoxedIcon = ({ icon }: { icon: ReactNode }) => {
  const theme = useTheme();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      p={1}
      border={`1px solid ${theme.palette.grey.A100}`}
      borderRadius="4px"
      height={metricIconSize}
      width={metricIconSize}
    >
      {icon}
    </Stack>
  );
};

const tableControlsState = signal<TableControls>({
  resetExpandedRows: () => { },
  setSorting: () => { },
});

const LeadsTable = ({ partner }: { partner: ListPartner }) => {
  const l = useLabels();
  const theme = useTheme();

  const statusToColor: Record<OwnerLeadsWorkflowStatus, ThemeColor> = {
    [OwnerLeadsWorkflowStatus.WON]: 'success',
    [OwnerLeadsWorkflowStatus.LOST]: 'error',
    [OwnerLeadsWorkflowStatus.NEW]: 'info',
    [OwnerLeadsWorkflowStatus.IN_PROGRESS]: 'warning',
  };

  const columns = useMemo(() => {
    const cols: (ColumnDef<OwnerLeadItem, string> | null)[] = [
      {
        header: l['referral-partners.table.lead'],
        enableSorting: true,
        accessorFn: (row) => `${row.firstName ?? ''} ${row.lastName ?? ''}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.firstName}
            {' '}
            {info.row.original.lastName}
          </Typography>
        ),
      },
      {
        header: l['referral-partners.add.phoneNumber'],
        enableSorting: true,
        minSize: 180,
        maxSize: 180,
        accessorFn: (row) => row.phoneNumber,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.phoneNumber}
          </Typography>
        ),
      },
      {
        header: l['referral-partners.table.email'],
        enableSorting: true,
        accessorFn: (row) => row.email,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.email}
          </Typography>
        ),
      },
      {
        header: l.address,
        enableSorting: true,
        accessorFn: (row) => row.addresses.join('::'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Stack gap={2}>
            {info.row.original.addresses.length ? info.row.original.addresses.map((address) => (
              <Typography key={`${partner.id}-${info.row.original.id}-${address}`} variant="body1" sx={{ textWrap: 'nowrap' }}>
                {address}
              </Typography>
            )) : <Stack height="1px" width={20} sx={{ background: theme.palette.primary.main }} />}
          </Stack>
        ),
      },
      {
        header: l.properties,
        enableSorting: true,
        maxSize: 200,
        accessorFn: (row) => row.email,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.numOfProperties}
          </Typography>
        ),
      },
      {
        header: l['growth.table.dateAdded'],
        enableSorting: true,
        maxSize: 200,
        accessorFn: (row) => row.email,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {formatPotentialTodayDate(info.row.original.createdTime)}
          </Typography>
        ),
      },
      {
        header: l['referral-partners.table.status'],
        enableSorting: false,
        accessorFn: (row) => row.status,
        minSize: 160,
        size: 160,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Chip
            onClick={() => window.open('/pm/leads?tab=referrals', '_blank')}
            color={statusToColor[info.row.original.status]}
            label={replaceUnderscoreWithSpace(info.row.original.status)}
            size="small"
            sx={{
              width: 120,
            }}
          />
        ),
      },
    ];

    return cols;
  }, []);

  return (
    <TableView
      tableControlsState={tableControlsState}
      allowSearch={false}
      usePadding={false}
      data={partner.leads}
      columns={columns}
      searchTermFilter={() => partner.leads}
    />
  );
};

export const ViewPartnerDialog = ({
  open,
  onClose,
  rank,
  partner = undefined,
}: {
  open: boolean,
  onClose: () => void,
  rank: ReactNode,
  partner?: ListPartner,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickClose = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Close Partner',
    });

    onClose();
  };

  const wonLeads = partner?.leads.filter((lead) => lead.status === OwnerLeadsWorkflowStatus.WON) ?? [];

  const handleClickCopyReferralLink = () => {
    const link = buildPartnerReferralLink(partner!.ownerReferralLink?.id ?? '');

    analytics.track('Button Clicked', {
      buttonName: 'Copy Referral Link',
      link,
    });

    navigator.clipboard.writeText(link);
    toast.success(l['referral-partners.copyText']);
  };

  if (!partner) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth fullScreen={isMobile}>
      <DialogActions sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        justifyContent: 'space-between',
      }}
      >
        <Typography variant="h6">
          {l.partner}
        </Typography>
        <IconButton onClick={handleClickClose}>
          <MdClose />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={4} lg={2}>
            <Stack gap={3}>
              <Stack direction="row" alignItems="center" gap={3}>
                <Avatar
                  src={partner?.avatar || `https://ui-avatars.com/api/?name=${partner.firstName}+${partner.lastName}`}
                  sx={{ width: 80, height: 80 }}
                  color="secondary"
                  style={{ opacity: partner?.avatar ? 1 : 0.6 }}
                />
                {typeof rank !== 'number' && rank}
              </Stack>
              <SemiBoldTypography variant="body1">
                {partner.firstName}
                {' '}
                {partner.lastName}
              </SemiBoldTypography>
              <Stack>
                <PartnerTypeChip compact partner={partner} />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={8} lg={3}>
            <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}`, height: '100%' }}>
              <CardContent
                sx={{
                  '&&&': {
                    p: 3,
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                  },
                }}
              >
                <Stack justifyContent="space-between" gap={4} height="100%">
                  <Stack gap={2}>
                    <SemiBoldTypography variant="body1">
                      {l.details}
                    </SemiBoldTypography>
                    {partner.website && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <IoMdGlobe size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
                        <Link
                          href={partner.website}
                          component="a"
                          variant="body2"
                          target="_blank"
                          sx={{ textDecoration: 'underline' }}
                        >
                          {partner.website}
                        </Link>
                      </Stack>
                    )}
                    {partner.phoneNumber && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <MdPhone size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
                        <Typography variant="body2" color="secondary">
                          {partner.phoneNumber}
                        </Typography>
                      </Stack>
                    )}
                    {partner.email && <CopyEmail email={partner.email} />}
                    {partner.address && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <MdLocationPin size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
                        <Typography variant="body2" color="secondary">
                          {partner.address}
                        </Typography>
                      </Stack>
                    )}
                    {!!partner.doorCount && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <InfoTooltip
                          arrow
                          isSmall
                          isLight
                          isOutlined
                          iconProps={{ disableRipple: true }}
                          icon={<BsDoorClosed size={18} color={hexToRGBA(theme.palette.primary.main, 0.8)} />}
                          title={(
                            <Typography variant="body2">{l['partners.card.doorCount']}</Typography>
                          )}
                        />
                        <Typography variant="body2" color="secondary">
                          {formatNumber(partner.doorCount, 0)}
                        </Typography>
                      </Stack>
                    )}
                    {partner.propertyTypes.length > 0 && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <InfoTooltip
                          arrow
                          isSmall
                          isLight
                          isOutlined
                          iconProps={{ disableRipple: true }}
                          icon={<MdOutlineMapsHomeWork size={18} color={hexToRGBA(theme.palette.primary.main, 0.8)} />}
                          title={(
                            <Typography variant="body2">{l['partners.card.propertyTypes']}</Typography>
                          )}
                        />
                        <ManagedPropertyTypes
                          colorSecondary
                          variant="body2"
                          id={partner.id}
                          propertyTypes={partner.propertyTypes}
                        />
                      </Stack>
                    )}
                    {!!partner.experienceInYears && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <InfoTooltip
                          arrow
                          isSmall
                          isLight
                          isOutlined
                          iconProps={{ disableRipple: true }}
                          icon={<TbBriefcase2 size={18} color={hexToRGBA(theme.palette.primary.main, 0.8)} />}
                          title={(
                            <Typography variant="body2">{l['partners.card.yearsOfExperience']}</Typography>
                          )}
                        />
                        <Typography variant="body2" color="secondary">
                          {partner.experienceInYears}
                        </Typography>
                      </Stack>
                    )}
                    {partner.workingCounties.length > 0 && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <InfoTooltip
                          arrow
                          isSmall
                          isLight
                          isOutlined
                          iconProps={{ disableRipple: true }}
                          icon={<GrMapLocation size={18} color={hexToRGBA(theme.palette.primary.main, 0.8)} />}
                          title={(
                            <Typography variant="body2">{l['partners.card.workingCounties']}</Typography>
                          )}
                        />
                        <WorkingCounties variant="body2" colorSecondary {...partner} />
                      </Stack>
                    )}
                    {!!partner.minPrice && !!partner.maxPrice && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <InfoTooltip
                          arrow
                          isSmall
                          isLight
                          isOutlined
                          iconProps={{ disableRipple: true }}
                          icon={<IoPricetagOutline size={18} color={hexToRGBA(theme.palette.primary.main, 0.8)} />}
                          title={(
                            <Typography variant="body2">{l['partners.card.priceRange']}</Typography>
                          )}
                        />
                        <Typography variant="body2" color="secondary">
                          {formatNumberToCurrency(partner.minPrice, 0, { notation: 'compact' })}
                          {' - '}
                          {formatNumberToCurrency(partner.maxPrice, 0, { notation: 'compact' })}
                        </Typography>
                      </Stack>
                    )}
                    {/* eslint-disable-next-line etc/no-commented-out-code */}
                    {/* <Stack direction="row" alignItems="center" gap={2}>
                      <Typography variant="body2" color="secondary">
                        {l['partners.card.activityLevel']}
                      </Typography>
                      <ActivityLevel activity={Activity.MEDIUM} />
                    </Stack> */}
                  </Stack>
                </Stack>
                {!!partner.ownerReferralLink && (
                  <Stack width="fit-content">
                    <Button variant="outlined" size="small" onClick={handleClickCopyReferralLink}>
                      {l['referral-partners.copyLink']}
                    </Button>
                  </Stack>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={12} lg={7} spacing={4}>
            <Grid item xs={12} sm={6}>
              <TooltipMetric
                label={l.dateAdded}
                displayValue={(
                  <BoldTypography variant="h6">
                    {dayjs(partner.createdTime).format('MMM D, YYYY')}
                  </BoldTypography>
                )}
                icon={<PersonSquareTopLeft color={theme.palette.primary.dark} size={40} />}
                tooltipName="View Partner Dialog - Date Added"
                tooltipContent={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {l['partner.dateAdded.tooltip']}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TooltipMetric
                label={l.leads}
                displayValue={(
                  <BoldTypography variant="h6">
                    {formatNumber(partner.leads.length, 0)}
                  </BoldTypography>
                )}
                icon={<BoxedIcon icon={<PropertyIcon />} />}
                tooltipName="View Partner Dialog - Leads"
                tooltipContent={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {l['partner.leads.tooltip']}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TooltipMetric
                label={l.clients}
                displayValue={(
                  <BoldTypography variant="h6">
                    {formatNumber(wonLeads.length, 0)}
                  </BoldTypography>
                )}
                icon={<PersonSquareTopLeft color={theme.palette.primary.dark} size={40} />}
                tooltipName="View Partner Dialog - Clients"
                tooltipContent={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {l['partner.clients.tooltip']}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TooltipMetric
                label={l['partners.partnerEarnings']}
                displayValue={(
                  <BoldTypography variant="h6">
                    {formatNumberToCurrency(partner.referralAmount * wonLeads.length, 0)}
                  </BoldTypography>
                )}
                icon={<BoxedIcon icon={<DollarRect />} />}
                tooltipName="View Partner Dialog - Earnings"
                tooltipContent={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {l['partner.earnings.tooltip']}
                  </Typography>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {partner.leads.length > 0 && (
          <>
            <Spacer spacing={3} />
            <BoldTypography variant="body1">
              {l['growth.referredLeads']}
            </BoldTypography>
            <Spacer spacing={3} />
            <LeadsTable partner={partner} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
