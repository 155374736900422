import {
  convertArrayToFirstElement, filterNulls, InsightLevel, InsightType, OwnerRisk, OwnerRiskSeverity, RiskInsight,
} from 'lib';

import {
  AppRiskInsight, BasicOwnerRisk, BasicOwnerRiskInsight, ExpandedOwnerRisk,
} from '../properties/types';

export const convertBasicOwnerRisk = (ownerRisk: OwnerRisk): BasicOwnerRisk => {
  const recommendations = filterNulls(ownerRisk.recommendations?.items);
  const ownerRisksInsights = filterNulls(ownerRisk.ownerRisksInsights?.items);

  return {
    id: ownerRisk.id,
    ownerID: ownerRisk.ownerID,
    clientValue: ownerRisk.clientValue,
    ownerDetails: ownerRisk.ownerDetails,
    updatedTime: ownerRisk.updatedTime,
    risk: ownerRisk.risk,
    riskLevel: ownerRisk.riskLevel,
    ownerSince: ownerRisk.ownerSince,
    insights: ownerRisk.insights,
    status: ownerRisk.status,
    severity: ownerRisk.severity,
    severityScore: ownerRisk.severityScore,
    inProgressSetDate: ownerRisk.inProgressSetDate,
    resolvedDate: ownerRisk.resolvedDate,
    resolvedReason: ownerRisk.resolvedReason,
    recommendation: convertArrayToFirstElement({ items: recommendations }),
    isNoRisk: ownerRisk.severity === OwnerRiskSeverity.NO_RISK,
    ownerRetentionDetails: convertArrayToFirstElement(ownerRisk.ownerRetentionDetails),
    statusChangedDate: ownerRisk.statusChangedDate,
    ownerRisksInsights: ownerRisksInsights?.map(convertBasicOwnerRiskInsight) ?? [],
  };
};

export const convertExpandedOwnerRisk = (risk: OwnerRisk): ExpandedOwnerRisk => {
  const ownerRisk = risk!;
  const items = filterNulls(ownerRisk.recommendations?.items);
  const insights = filterNulls(ownerRisk.ownerRisksInsights?.items);

  return {
    id: ownerRisk.id,
    ownerID: ownerRisk.ownerID,
    recommendation: convertArrayToFirstElement({ items }),
    insights: ownerRisk.insights ?? [],
    ownerDetails: ownerRisk.ownerDetails,
    ownerRisksInsights: insights.map(convertRiskInsight),
  };
};

const convertRiskInsight = (insight: RiskInsight): AppRiskInsight => ({
  id: insight.id,
  context: insight.context ?? [],
  caseName: insight.caseName ?? '',
  propertyID: insight.propertyID ?? '',
  quarters: insight.quarters?.sort((a, b) => (b.quarter ?? 0) - (a.quarter ?? 0)) ?? [],
  level: insight.level ?? InsightLevel.MEDIUM,
  insightType: insight.insightType ?? InsightType.NEUTRAL,
});

const convertBasicOwnerRiskInsight = (insight: RiskInsight): BasicOwnerRiskInsight => ({
  id: insight.id,
  caseName: insight.caseName ?? '',
  level: insight.level ?? InsightLevel.MEDIUM,
  insightType: insight.insightType ?? InsightType.NEUTRAL,
  propertyID: insight.propertyID ?? '',
});
