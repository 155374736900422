import {
  DashboardProperty as APIProperty,
  ListDashboardPropertiesByPmQuery,
  ListDashboardPropertyByOwnerQuery,
  queryGraphQL,
  useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { convertOwnerPropertyData } from './converters';
import { OwnerPropertyData } from './types';
import { QueryKey } from '../../types/enums';
import { getOwnerPropertiesQuery, listDashboardPropertiesFilterDeletedQuery, listPropertyValuations } from '../graphql/queries';

export const useListPropertyValuations = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchProperties = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    valuations: number[];
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listPropertyValuations,
      authToken: token,
      variables: {
        nextToken: nextToken || undefined,
        pmId: user?.pm,
      },
    }) as GraphQLResult<ListDashboardPropertiesByPmQuery>;

    if (!res.data?.listDashboardPropertiesByPm) {
      return { nextToken: null, valuations: [] };
    }

    const valuations: number[] = [];

    const filtered = res.data.listDashboardPropertiesByPm.items.filter(Boolean) as APIProperty[];

    filtered.forEach((property) => {
      if (property.propertyValuation?.items?.length) {
        const valuation = property.propertyValuation?.items[0];

        if (!valuation || !valuation.priceMean) return;

        valuations.push(valuation.priceMean);
      }
    });

    return {
      nextToken: res.data.listDashboardPropertiesByPm.nextToken,
      valuations,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.PROPERTY_VALUATIONS],
    queryFn: fetchProperties,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

export const useGetOwnerPropertyData = (owner?: string, { enabled = true }: { enabled?: boolean } = {}) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery({
    queryKey: [QueryKey.OWNER_PROPERTY_DATA, owner],
    queryFn: async (): Promise<OwnerPropertyData | null > => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: getOwnerPropertiesQuery,
        authToken: token,
        variables: { owner },
      }) as GraphQLResult<ListDashboardPropertyByOwnerQuery>;

      if (!res.data?.listDashboardPropertyByOwner) {
        return null;
      }

      const properties = res.data.listDashboardPropertyByOwner.items.filter(Boolean) as APIProperty[];

      return convertOwnerPropertyData(properties);
    },
    enabled: !!owner && enabled,
  });
};

export const useGetPmDashboardProperties = () => {
  const { getAccessTokenSilently, user } = useAuth();

  const pm = user?.pm;

  const query = useInfiniteQuery({
    queryKey: [QueryKey.PM_DASHBOARD_PROPERTIES],
    queryFn: async ({ pageParam: nextToken }): Promise<{
      properties: { id: string, owner: string }[];
      nextToken: string | null | undefined;
    }> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listDashboardPropertiesFilterDeletedQuery,
        variables: { pm, nextToken },
        authToken: token,
      }) as GraphQLResult<ListDashboardPropertiesByPmQuery>;

      if (!res.data?.listDashboardPropertiesByPm?.items?.length) {
        return { properties: [], nextToken: null };
      }

      const properties: { id: string, owner: string }[] = [];

      res.data.listDashboardPropertiesByPm.items.forEach((property) => {
        if (!property) return;

        properties.push({ id: property.id, owner: property.owner });
      });

      return {
        properties,
        nextToken: res.data.listDashboardPropertiesByPm.nextToken,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

export const useGetDashboardPropertyIDs = () => {
  const query = useGetPmDashboardProperties();

  const propertyIDs = new Set<string>();

  const flatPages = query.data?.pages.flatMap((page) => page.properties) ?? [];

  flatPages.forEach((property) => {
    propertyIDs.add(property.id);
  });

  return {
    isLoading: query.isLoading,
    propertyIDs,
  };
};

export const useGetOwnerToNumOfProperties = () => {
  const query = useGetPmDashboardProperties();

  const ownerToNumOfProperties = new Map<string, number>();

  query.data?.pages.forEach((page) => {
    page.properties.forEach((property) => {
      ownerToNumOfProperties.set(property.owner, (ownerToNumOfProperties.get(property.owner) ?? 0) + 1);
    });
  });

  return {
    isLoading: query.isLoading,
    ownerToNumOfProperties,
  };
};
