import { ReactNode } from 'react';

import { InsightType, useAnalytics } from 'lib';
import { LightTooltip, useLabels } from 'ui';
import {
  Chip, Stack, Typography, useTheme,
} from '@mui/material';

import { BasicOwnerRisk } from '../../../../api/properties/types';
import { insightDrawerState } from '../dialogs/insights/utils';

const IssueChip = ({
  label,
  onClick = () => {},
  showAsNegative = false,
  fluidWidth = false,
  tooltip = undefined,
  useTooltip = true,
}: {
  label: string,
  onClick?: () => void,
  showAsNegative?: boolean,
  fluidWidth?: boolean,
  tooltip?: ReactNode,
  useTooltip?: boolean,
}) => {
  const theme = useTheme();

  return (
    <LightTooltip
      arrow
      title={useTooltip && (!tooltip || typeof tooltip === 'string') ? (
        <Typography variant="body2" p={1}>{tooltip || label}</Typography>
      ) : tooltip}
    >
      <Chip
        onClick={onClick}
        label={label}
        color="error"
        size="small"
        sx={{
          height: 24,
          width: fluidWidth ? 'auto' : 90,
          color: showAsNegative ? theme.palette.error.main : theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      />
    </LightTooltip>
  );
};

export const IssuesFound = ({ ownerRisk }: { ownerRisk: BasicOwnerRisk }) => {
  const l = useLabels();
  const analytics = useAnalytics();

  const seenCaseNames = new Set<string>();
  const negativeInsights = ownerRisk.ownerRisksInsights?.filter((insight) => {
    if (insight.insightType !== InsightType.NEGATIVE) return false;

    if (seenCaseNames.has(insight.caseName ?? '')) return false;

    seenCaseNames.add(insight.caseName ?? '');
    return insight.insightType === InsightType.NEGATIVE;
  }) ?? [];

  const selectOwnerRisk = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Owner Risk - Click on insight chip',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    insightDrawerState.value.ownerRisk = ownerRisk;

    insightDrawerState.value.openDrawer?.();
  };

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {negativeInsights.length === 0 && (
        <IssueChip label="No issues" />
      )}
      {negativeInsights.length > 3 ? (
        <>
          <IssueChip
            onClick={() => {
              selectOwnerRisk();
            }}
            label={l.dynamic['retention.insight'](negativeInsights[0].caseName ?? '')}
          />
          <IssueChip
            onClick={() => {
              selectOwnerRisk();
            }}
            label={l.dynamic['retention.insight'](negativeInsights[1].caseName ?? '')}
          />
          <IssueChip
            onClick={() => {
              selectOwnerRisk();
            }}
            fluidWidth
            useTooltip={false}
            label={`+${negativeInsights.length - 2}`}
          />
        </>
      ) : negativeInsights.map((i) => (
        <IssueChip
          onClick={() => {
            selectOwnerRisk();
          }}
          key={i.id}
          label={l.dynamic['retention.insight'](i.caseName ?? '')}
        />
      ))}
    </Stack>
  );
};
