import { useLabels } from 'ui';
import {
  Box, Stack, Typography, useTheme,
} from '@mui/material';

export const PositiveNegativeLegend = () => {
  const l = useLabels();
  const theme = useTheme();

  return (

    <Stack direction="row" alignItems="center" gap={3} justifyContent="center" mt={-4} pb={3}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Box sx={{
          width: 14, height: 14, borderRadius: '50%', bgcolor: theme.palette.success.main,
        }}
        />
        <Typography variant="body2">
          {l.positive}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Box sx={{
          width: 14, height: 14, borderRadius: '50%', bgcolor: theme.palette.error.main,
        }}
        />
        <Typography variant="body2">
          {l.negative}
        </Typography>
      </Stack>
    </Stack>
  );
};
