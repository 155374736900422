import {
  MouseEvent, ReactNode, useState,
} from 'react';

import {
  getOwnerFromID,
  OwnerRiskStatus, useAnalytics,
  useFeatures,
  useImpersonate,
} from 'lib';
import {
  IoIosCheckmarkCircleOutline,
  IoMdCheckmark, IoMdMore,
} from 'react-icons/io';
import {
  MdKeyboardArrowDown,
  MdPersonOutline,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  ChartIcon,
  GaugeIcon,
  InvertedButton,
  LightTooltip,
  RobotHead, SparkIcon, Spinner, SpinnerIcon, useLabels,
} from 'ui';
import {
  Button,
  ButtonGroup,
  darken,
  IconButton,
  Menu, MenuItem,
  MenuList,
  Stack, Typography, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useDeleteRecommendation, useUpdateOwnerRisk } from '../../../../api/owner-risk';
import { BasicOwnerRisk } from '../../../../api/properties/types';
import { QueryKey } from '../../../../types/enums';
import { checkOwnerSentiment } from '../../state';
import { insightDrawerState } from '../dialogs/insights/utils';

type Props = {
  ownerRisk: BasicOwnerRisk,
  onResolveClick: (ownerRisk: BasicOwnerRisk) => void,
  onActClick: (ownerRisk: BasicOwnerRisk) => Promise<void>,
  forceActButtonLoading: boolean,
};

export const OwnerActions = (props: Props) => {
  const features = useFeatures();

  return features.isRiskV2Enabled ? <OwnerActionsV2 {...props} /> : <OwnerActionsV1 {...props} />;
};

const OwnerActionsV1 = ({
  ownerRisk,
  onResolveClick,
  onActClick,
  forceActButtonLoading,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isInvalidatingQueries, setIsInvalidatingQueries] = useState(false);
  const features = useFeatures();
  const open = Boolean(anchorEl);
  const { mutateAsync: impersonateOwner, isLoading: isLoadingImpersonation } = useImpersonate();
  const { mutateAsync: updateOwnerRisk, isLoading: isUpdatingOwnerRisk } = useUpdateOwnerRisk();
  const { mutateAsync: deleteRecommendation, isLoading: isDeletingRecommendation } = useDeleteRecommendation();

  const loadingMoveToNew = isInvalidatingQueries || isUpdatingOwnerRisk || isDeletingRecommendation;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickResolve = (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Resolve Owner',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    onResolveClick(ownerRisk);
    handleCloseMenu();
  };

  const handleClickAct = async (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Act on Owner',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
      generatingRiskRecommendation: !ownerRisk.recommendation,
    });

    if (checkOwnerSentiment(ownerRisk, features, onActClick)) {
      return;
    }

    onActClick(ownerRisk);
    handleCloseMenu();
  };

  const handleClickOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Owner Row - Open Menu',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    setAnchorEl(e.currentTarget);
  };

  const handleClickImpersonate = async (e: MouseEvent, actionName: string) => {
    e.stopPropagation();
    const owner = getOwnerFromID(ownerRisk!.ownerID);

    analytics.track('User Impersonated', {
      actionName,
      userEmail: owner.email,
      status: ownerRisk.status,
    });

    const res = await impersonateOwner(getOwnerFromID(ownerRisk!.ownerID).email);
    window.open(`/?impersonation-token=${res.data.token}`, '_blank');
  };

  const handleClickMoveToNew = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Owner Risk Move to In Progress',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    setIsInvalidatingQueries(true);

    try {
      if (ownerRisk.recommendation?.id) {
        await deleteRecommendation({ id: ownerRisk.recommendation.id });
      }

      await updateOwnerRisk({
        ownerRiskID: ownerRisk.id,
        status: OwnerRiskStatus.NEW,
      });

      await Promise.all([
        queryClient.invalidateQueries([QueryKey.OWNER_RISK, ownerRisk.id]),
        queryClient.invalidateQueries([QueryKey.OWNER_RISKS_BY_PM]),
      ]);

      handleCloseMenu();
      toast.success(l['retention.ownerRisk.movedToNeedsAttention']);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }

    setIsInvalidatingQueries(false);
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  const actButton = (
    <InvertedButton
      variant="contained"
      size="small"
      startIcon={forceActButtonLoading ? null : <RobotHead />}
      onClick={handleClickAct}
      disabled={forceActButtonLoading}
    >
      {forceActButtonLoading ? spinner : null}
      {!forceActButtonLoading && (
        ownerRisk.recommendation ? l['retention.view'] : l['retention.action']
      )}
    </InvertedButton>
  );

  if (ownerRisk.isNoRisk) {
    return (
      <Stack direction="row" alignItems="center" gap={2} ml="auto">
        <InvertedButton
          onClick={(e) => handleClickImpersonate(e, l['retention.viewOwner'])}
          variant="contained"
          size="small"
        >
          {l['retention.viewOwner']}
        </InvertedButton>
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2} ml="auto">
        {[OwnerRiskStatus.NEW, OwnerRiskStatus.IN_PROGRESS].includes(ownerRisk.status) ? (
          <ButtonGroup>
            {actButton}
            {ownerRisk.status === OwnerRiskStatus.IN_PROGRESS && (
              <InvertedButton
                variant="contained"
                size="small"
                startIcon={<IoMdCheckmark />}
                onClick={handleClickResolve}
                sx={{
                  borderLeft: `1px solid ${darken(theme.palette.primary.dark, 0.1)} !important`,
                }}
              >
                {l['retention.resolve']}
              </InvertedButton>
            )}
            <InvertedButton
              onClick={(e) => handleClickOpenMenu(e)}
              variant="contained"
              size="small"
              sx={{
                p: '0 !important',
                borderLeft: `1px solid ${darken(theme.palette.primary.dark, 0.1)} !important`,
              }}
            >
              <MdKeyboardArrowDown size={24} />
            </InvertedButton>
          </ButtonGroup>
        ) : actButton}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {ownerRisk.status !== OwnerRiskStatus.IN_PROGRESS && (
          <MenuItem onClick={handleClickResolve}>
            {l['retention.resolve']}
          </MenuItem>
        )}
        {ownerRisk.status === OwnerRiskStatus.IN_PROGRESS && (
          <MenuItem onClick={handleClickMoveToNew} disabled={loadingMoveToNew}>
            <Stack position="relative" width="100%" minWidth={100}>
              {loadingMoveToNew ? spinner : l['retention.moveToAttentionNeeded']}
            </Stack>
          </MenuItem>
        )}
        {[OwnerRiskStatus.NEW, OwnerRiskStatus.IN_PROGRESS].includes(ownerRisk.status) && (
          <MenuItem onClick={(e) => handleClickImpersonate(e, l.viewOwnerDashboard)} disabled={isLoadingImpersonation}>
            <Stack position="relative" width="100%">
              {isLoadingImpersonation ? spinner : l.viewOwnerDashboard}
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const OwnerActionMenuItem = ({
  onClick,
  label,
  icon,
  disabled = false,
  loading = false,
}: {
  onClick: (e: MouseEvent<HTMLElement>) => void,
  label: string,
  icon: ReactNode,
  disabled?: boolean,
  loading?: boolean,
}) => (
  <MenuItem onClick={onClick} disabled={disabled} sx={{ transition: 'width 0.3s ease-in-out' }}>
    <Stack direction="row" alignItems="center" gap={3}>
      {icon}
      <Stack direction="row" alignItems="center" gap={3}>
        <Typography variant="body1">
          {label}
        </Typography>
        {loading && (
          <SpinnerIcon size={20} />
        )}
      </Stack>
    </Stack>
  </MenuItem>
);

const OwnerActionsV2 = ({
  ownerRisk,
  onResolveClick,
  onActClick,
  forceActButtonLoading,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isInvalidatingQueries, setIsInvalidatingQueries] = useState(false);
  const features = useFeatures();
  const open = Boolean(anchorEl);
  const { mutateAsync: impersonateOwner, isLoading: isLoadingImpersonation } = useImpersonate();
  const { mutateAsync: updateOwnerRisk, isLoading: isUpdatingOwnerRisk } = useUpdateOwnerRisk();
  const { mutateAsync: deleteRecommendation, isLoading: isDeletingRecommendation } = useDeleteRecommendation();

  const loadingMoveToNew = isInvalidatingQueries || isUpdatingOwnerRisk || isDeletingRecommendation;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickResolve = (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Resolve Owner',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    onResolveClick(ownerRisk);
    handleCloseMenu();
  };

  const handleClickAct = async (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Act on Owner',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
      generatingRiskRecommendation: !ownerRisk.recommendation,
    });

    if (checkOwnerSentiment(ownerRisk, features, onActClick)) {
      return;
    }

    onActClick(ownerRisk);
    handleCloseMenu();
  };

  const handleClickOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Owner Row - Open Menu',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    setAnchorEl(e.currentTarget);
  };

  const handleClickImpersonate = async (e: MouseEvent, actionName: string) => {
    e.stopPropagation();
    const owner = getOwnerFromID(ownerRisk!.ownerID);

    analytics.track('User Impersonated', {
      actionName,
      userEmail: owner.email,
      status: ownerRisk.status,
    });

    const res = await impersonateOwner(getOwnerFromID(ownerRisk!.ownerID).email);
    window.open(`/?impersonation-token=${res.data.token}`, '_blank');
  };

  const handleClickMoveToNew = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Owner Risk Move to In Progress',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    setIsInvalidatingQueries(true);

    try {
      if (ownerRisk.recommendation?.id) {
        await deleteRecommendation({ id: ownerRisk.recommendation.id });
      }

      await updateOwnerRisk({
        ownerRiskID: ownerRisk.id,
        status: OwnerRiskStatus.NEW,
      });

      await Promise.all([
        queryClient.invalidateQueries([QueryKey.OWNER_RISK, ownerRisk.id]),
        queryClient.invalidateQueries([QueryKey.OWNER_RISKS_BY_PM]),
      ]);

      handleCloseMenu();
      toast.success(l['retention.ownerRisk.movedToNeedsAttention']);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }

    setIsInvalidatingQueries(false);
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  const selectOwnerRisk = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Owner Risk - Open Insights Drawer',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    insightDrawerState.value.ownerRisk = ownerRisk;

    insightDrawerState.value.openDrawer?.();
  };

  const viewInsightsButton = (
    <LightTooltip
      arrow
      title={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {l['retention.tooltip.viewInsights']}
        </Typography>
      )}
    >
      <IconButton
        onClick={selectOwnerRisk}
        sx={{
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.grey.A200}`,
          borderRadius: '4px',
          p: 1.75,
        }}
      >
        <ChartIcon />
      </IconButton>
    </LightTooltip>
  );

  if (ownerRisk.isNoRisk) {
    return (
      <Stack direction="row" alignItems="center" gap={2} ml="auto">
        {viewInsightsButton}
        <Button
          disabled={isLoadingImpersonation}
          sx={{ color: `${theme.palette.primary.contrastText} !important` }}
          onClick={(e) => handleClickImpersonate(e, l['retention.viewOwner'])}
          variant="contained"
          size="small"
          endIcon={isLoadingImpersonation ? <SpinnerIcon size={18} /> : null}
        >
          {l['retention.viewOwner']}
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2} ml="auto">
        {viewInsightsButton}
        <Button
          variant="contained"
          size="small"
          startIcon={forceActButtonLoading ? null : <SparkIcon size={18} />}
          onClick={handleClickAct}
          disabled={forceActButtonLoading}
          sx={{
            color: `${theme.palette.primary.contrastText} !important`,
          }}
        >
          {forceActButtonLoading ? spinner : null}
          {!forceActButtonLoading && l.retain}
        </Button>
        {[OwnerRiskStatus.NEW, OwnerRiskStatus.IN_PROGRESS].includes(ownerRisk.status) && (
          <IconButton onClick={handleClickOpenMenu} size="small">
            <IoMdMore />
          </IconButton>
        )}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuList>
          <OwnerActionMenuItem
            label={l['retention.resolve']}
            onClick={handleClickResolve}
            icon={<IoIosCheckmarkCircleOutline size={24} />}
          />
          {ownerRisk.status === OwnerRiskStatus.IN_PROGRESS && (
            <OwnerActionMenuItem
              label={l['retention.moveToAtRisk']}
              onClick={handleClickMoveToNew}
              icon={<GaugeIcon />}
              disabled={loadingMoveToNew}
              loading={loadingMoveToNew}
            />
          )}
          {[OwnerRiskStatus.NEW, OwnerRiskStatus.IN_PROGRESS].includes(ownerRisk.status) && (
            <OwnerActionMenuItem
              label={l.viewOwnerDashboard}
              onClick={(e) => handleClickImpersonate(e, l.viewOwnerDashboard)}
              icon={<MdPersonOutline size={24} />}
              disabled={isLoadingImpersonation}
              loading={isLoadingImpersonation}
            />
          )}
        </MenuList>
      </Menu>
    </>
  );
};
