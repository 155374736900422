import dayjs from 'dayjs';
import {
  sum,
} from 'lib';
import {
  MdTrendingDown,
  MdTrendingUp,
} from 'react-icons/md';
import {
  Avatar,
  BoldTypography,
  ColumnChart,
  DocumentIcon,
  DoubleYAxisColumnChart,
  formatDecimalToPercentage,
  formatNumber,
  formatNumberToCurrency, SemiBoldTypography,
  useLabels,
} from 'ui';
import {
  Card,
  Grid,
  Stack, Typography, useTheme,
} from '@mui/material';

import { InsightDetailCard } from './InsightDetailCard';
import { InsightDisclaimer } from './InsightDisclaimer';
import { PositiveNegativeLegend } from './PositiveNegativeLegend';
import { CaseExpandedContentProps, ContextKey, getQuarterLabels } from './utils';

export const ManagementFeeExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const theme = useTheme();
  const l = useLabels();
  const labels = getQuarterLabels(insight?.quarters ?? []);
  const mgmtFeeData = insight?.quarters?.map(
    (q) => Math.abs(q.context?.find((c) => c.Key === ContextKey.MANAGEMENT_FEE)?.Value ?? 0),
  ) ?? [];
  const incomeData = insight?.quarters?.map(
    (q) => Math.abs(q.context?.find((c) => c.Key === ContextKey.INCOME)?.Value ?? 0),
  ) ?? [];
  const feeToIncomeRatio = sum(mgmtFeeData) / sum(incomeData);
  const rightAxisMaxValue = Math.max(...mgmtFeeData.map((c, i) => c / incomeData[i]));
  const rightAxisMaxValueRounded = Math.ceil(rightAxisMaxValue * 10) / 10;

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={l['insight.name.managementFee.tooltip']} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
        <Stack>
          <DoubleYAxisColumnChart
            width="103%"
            height={290}
            labels={labels}
            leftAxisMax={Math.max(...mgmtFeeData, ...incomeData)}
            rightAxisMax={rightAxisMaxValueRounded}
            series={[
              {
                name: 'Management Fee',
                type: 'column',
                data: mgmtFeeData,
                color: '#FF8A5F',
              },
              {
                name: 'Income',
                type: 'column',
                data: incomeData,
                color: theme.palette.success.main,
              },
              {
                name: 'Total Fees To Income Ratio',
                type: 'line',
                data: mgmtFeeData.map((c, i) => c / incomeData[i]),
                color: '#F1C40F',
              },
            ]}
          />
        </Stack>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            hideDot
            label={l.feeToIncomeRatio}
            value={formatDecimalToPercentage(feeToIncomeRatio, 0)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            hideDot
            label={l.managementFee}
            value={formatNumberToCurrency(sum(mgmtFeeData), 0, {
              notation: 'compact',
              compactDisplay: 'short',
            })}
          />
        </Grid>
      </Grid>
      <InsightDisclaimer
        description={l['insight.name.managementFee.tooltip']}
        showNavigateToTransactions
        propertyId={property.id}
        category="management_fee"
      />
    </Stack>
  );
};

export const VacancyExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const theme = useTheme();
  const l = useLabels();
  const labels = property.monthMetrics?.map((m) => dayjs(m.monthDate).format('MMM')) ?? [];
  const monthsVacantData = property.monthMetrics?.map((m) => (m.isVacant ? 1 : 0)) ?? [];
  const isFullyOccupied = monthsVacantData.every((v) => v === 0);

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={l['insight.name.vacancy.tooltip']} />
      </Stack>
    );
  }

  if (isFullyOccupied) {
    return (
      <Stack gap={3}>
        <Typography variant="body2" color="text.secondary">
          {l.dynamic['retention.insight.vacancy.wellDone'](monthsVacantData.length)}
        </Typography>
        <InsightDisclaimer description={l['insight.name.vacancy.tooltip']} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      {monthsVacantData.length === 0 ? <NoDataCard /> : (
        <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
          <Stack>
            <ColumnChart
              asNumber
              yAxisStepSize={1}
              yAxisFormatter={(val) => (val === 1 ? 'Vacant' : 'Occupied')}
              yAxisMax={1}
              series={[{
                name: l.vacancy,
                data: monthsVacantData,
                color: theme.palette.error.main,
              }]}
              labels={labels}
              height={225}
              width="100%"
            />
          </Stack>
        </Card>
      )}
      <InsightDisclaimer
        description={l['insight.name.vacancy.tooltip']}
        showNavigateToTransactions
        propertyId={property.id}
      />
    </Stack>
  );
};

export const NOIExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const theme = useTheme();
  const l = useLabels();
  const labels = getQuarterLabels(insight?.quarters ?? []);
  const noiData = insight?.quarters?.map((q) => q.context?.find((c) => c.Key === ContextKey.NOI)?.Value ?? 0) ?? [];

  const insightDescription = (
    <Stack>
      <SemiBoldTypography variant="caption" sx={{ fontSize: 12 }}>
        {l['insight.name.noi.tooltip1']}
      </SemiBoldTypography>
      <SemiBoldTypography variant="caption" sx={{ fontSize: 12 }}>
        {l['insight.name.noi.tooltip2']}
      </SemiBoldTypography>
      <SemiBoldTypography variant="caption" sx={{ fontSize: 12 }}>
        {l['insight.name.noi.tooltip3']}
      </SemiBoldTypography>
    </Stack>
  );

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={insightDescription} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
        <Stack gap={1}>
          <ColumnChart
            hideLegend
            usePositiveNegativeColors
            series={[{
              name: l.noi,
              data: noiData,
            }]}
            labels={labels}
            width="105%"
            height={215}
          />
          <PositiveNegativeLegend />
        </Stack>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            label={l.totalIncome}
            value={formatNumberToCurrency(property.annualIncome, 0, {
              notation: 'compact',
              compactDisplay: 'short',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            negative
            label={l.operatingExpenses}
            value={formatNumberToCurrency(Math.abs(property.annualOperatingExpenses), 0, {
              notation: 'compact',
              compactDisplay: 'short',
            })}
          />
        </Grid>
      </Grid>
      <InsightDisclaimer description={insightDescription} showNavigateToTransactions propertyId={property.id} />
    </Stack>
  );
};

export const CashFlowExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const theme = useTheme();
  const l = useLabels();

  const cashFlowData = insight?.quarters?.map((q) => q.context?.find((c) => c.Key === ContextKey.CASH_FLOW)?.Value ?? 0) ?? [];
  const cashFlowLabels = getQuarterLabels(insight?.quarters ?? []);
  const totalCashFlow = insight?.quarters?.reduce(
    (acc, q) => acc + (q.context?.find((c) => c.Key === ContextKey.CASH_FLOW)?.Value ?? 0
    ), 0,
  ) ?? 0;
  const { annualIncome } = property;
  const { annualExpenses } = property;

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={l['insight.name.cashflow.tooltip']} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
        <Stack>
          <ColumnChart
            hideLegend
            usePositiveNegativeColors
            series={[{
              name: l.cashFlow,
              data: cashFlowData,
            }]}
            labels={cashFlowLabels}
            width="105%"
            height={215}
          />
          <PositiveNegativeLegend />
        </Stack>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            negative={annualIncome < 0}
            label={l.totalIncome}
            value={formatNumberToCurrency(annualIncome, 0, {
              notation: 'compact',
              compactDisplay: 'short',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            negative
            label={l.totalExpenses}
            value={formatNumberToCurrency(Math.abs(annualExpenses), 0, {
              notation: 'compact',
              compactDisplay: 'short',
            })}
          />
        </Grid>
      </Grid>
      <InsightDetailCard
        negative={totalCashFlow < 0}
        label={l.totalCashFlow}
        value={formatNumberToCurrency(totalCashFlow, 0, {
          notation: 'compact',
          compactDisplay: 'short',
        })}
      />
      <InsightDisclaimer description={l['insight.name.cashflow.tooltip']} showNavigateToTransactions propertyId={property.id} />
    </Stack>
  );
};

export const MaintenanceCostsExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const theme = useTheme();
  const l = useLabels();

  const maintenanceCostsData = insight?.quarters?.map(
    (q) => Math.abs(q.context?.find((c) => c.Key === ContextKey.MAINTENANCE)?.Value ?? 0),
  ) ?? [];
  const incomeData = insight?.quarters?.map(
    (q) => Math.abs(q.context?.find((c) => c.Key === ContextKey.INCOME)?.Value ?? 0),
  ) ?? [];
  const maintenanceCostsLabels = getQuarterLabels(insight?.quarters ?? []);
  const maintenanceToIncome = sum(maintenanceCostsData) / sum(incomeData);

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={l['insight.name.maintenanceCost.tooltip']} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
        <Stack>
          <DoubleYAxisColumnChart
            width="103%"
            height={290}
            labels={maintenanceCostsLabels}
            leftAxisMax={Math.max(...incomeData, ...maintenanceCostsData)}
            series={[
              {
                name: l.maintenanceCosts,
                type: 'column',
                data: maintenanceCostsData,
                color: '#FF8A5F',
              },
              {
                name: l.income,
                type: 'column',
                data: incomeData,
                color: theme.palette.success.main,
              },
              {
                name: l.maintenanceRatioToIncome,
                type: 'line',
                data: maintenanceCostsData.map((c, i) => c / incomeData[i]),
                color: '#F1C40F',
              },
            ]}
          />
        </Stack>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            hideDot
            label={l.maintenanceRatioToIncome}
            value={formatDecimalToPercentage(maintenanceToIncome, 0)}
            negative={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard
            hideDot
            label={l.totalCosts}
            value={formatNumberToCurrency(sum(maintenanceCostsData), 0, {
              notation: 'compact',
              compactDisplay: 'short',
            })}
            negative={false}
          />
        </Grid>
      </Grid>
      <InsightDisclaimer
        description={l['insight.name.maintenanceCost.tooltip']}
        showNavigateToTransactions
        propertyId={property.id}
        category="maintenance"
      />
    </Stack>
  );
};

export const AppreciationExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const theme = useTheme();
  const l = useLabels();

  const averageMonthlyAppreciation = insight?.context.find((c) => c.Key === ContextKey.AVERAGE_MONTHLY_APPRECIATION)?.Value ?? 0;
  const appreciation = insight?.context.find((c) => c.Key === ContextKey.APPRECIATION)?.Value ?? 0;
  const { purchasePrice, purchaseDate } = property;
  const appreciationPercentage = purchasePrice ? appreciation / purchasePrice : 0;

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={l['insight.name.appreciation.tooltip']} />
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <Stack gap={1} p={3}>
              <Typography variant="body2" color="text.secondary">
                {l.averageMonthlyAppreciationShort}
              </Typography>
              <Typography variant="h6">
                {formatNumberToCurrency(averageMonthlyAppreciation, 0)}
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <Stack gap={1} p={3}>
              <Typography variant="body2" color="text.secondary">
                {l.appreciation}
              </Typography>
              <Stack direction="row" gap={1}>
                <Typography variant="h6">
                  {formatNumberToCurrency(appreciation, 0)}
                </Typography>
                {appreciationPercentage !== 0 && (
                  <Stack direction="row" alignItems="center" gap={0.5} alignSelf="flex-end" pb={1}>
                    <Typography variant="body2" color={appreciationPercentage > 0 ? 'success.main' : 'error.main'}>
                      {appreciationPercentage > 0 && '+'}
                      {appreciationPercentage ? formatDecimalToPercentage(appreciationPercentage, 0) : undefined}
                    </Typography>
                    {appreciationPercentage > 0 ? (
                      <MdTrendingUp
                        size={20}
                        color={theme.palette.success.main}
                      />
                    ) : (
                      <MdTrendingDown
                        size={20}
                        color={theme.palette.error.main}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
        <Stack p={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {l.purchase}
            </Typography>
            <BoldTypography variant="h6">
              {formatNumberToCurrency(purchasePrice, 0)}
            </BoldTypography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {l.purchaseDate}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {purchaseDate?.format('MM/DD/YYYY')}
            </Typography>
          </Stack>
        </Stack>
      </Card>
      <InsightDisclaimer description={l['insight.name.appreciation.tooltip']} />
    </Stack>
  );
};

export const MaintenanceEventsExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const theme = useTheme();
  const l = useLabels();

  const maintenanceEventsData = insight?.quarters?.map(
    (q) => q.context?.find((c) => c.Key === ContextKey.MAINTENANCE_EVENTS)?.Value ?? 0,
  ) ?? [];
  const maintenanceEventsLabels = getQuarterLabels(insight?.quarters ?? []);

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={l['insight.name.maintenanceEvents.tooltip']} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
        <Stack>
          <ColumnChart
            asNumber
            series={[{
              name: l.events,
              data: maintenanceEventsData,
              color: '#FF8A5F',
            }]}
            labels={maintenanceEventsLabels}
            height={225}
            width="103%"
          />
        </Stack>
      </Card>
      <InsightDetailCard hideDot label={l.totalEvents} value={formatNumber(sum(maintenanceEventsData), 0)} />
      <InsightDisclaimer
        description={l['insight.name.maintenanceEvents.tooltip']}
        showNavigateToTransactions
        propertyId={property.id}
        category="maintenance"
      />
    </Stack>
  );
};

export const TenantsTurnoverExpandedContent = ({ insight, property }: CaseExpandedContentProps) => {
  const l = useLabels();

  const disclaimer = (
    <InsightDisclaimer description={(
      <Stack gap={2}>
        <Typography variant="caption" sx={{ fontSize: 12 }}>
          {l['insight.name.tenantsTurnover.tooltip.item1']}
        </Typography>
      </Stack>
    )}
    />
  );

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        {disclaimer}
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard hideDot label={l.totalUnits} value={formatNumber(property.totalUnits, 0)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InsightDetailCard hideDot label={l.uniqueTenants} value={formatNumber(property.totalUniqueTenants, 0)} />
        </Grid>
      </Grid>
      <InsightDetailCard hideDot label={l.turnover} value={formatNumber(property.turnoverTenants, 0)} />
      {disclaimer}
    </Stack>
  );
};

export const LeaseExpandedContent = ({ insight }: CaseExpandedContentProps) => {
  const l = useLabels();

  const monthsToEndLease = insight?.context?.find((c) => c.Key === ContextKey.LEASE)?.Value ?? 0;

  if (!insight) {
    return (
      <Stack gap={2}>
        <NoDataCard />
        <InsightDisclaimer description={l['retention.insight.lease.tooltip']} />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <BoldTypography variant="body1">
        {l.dynamic['retention.insight.lease.title'](monthsToEndLease)}
      </BoldTypography>
      <Typography variant="body2" color="text.secondary">
        {l.dynamic['retention.insight.lease.description'](monthsToEndLease)}
      </Typography>
      <InsightDisclaimer description={l['retention.insight.lease.tooltip']} />
    </Stack>
  );
};

const NoDataCard = () => {
  const l = useLabels();
  const theme = useTheme();

  return (
    <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
      <Stack gap={3} p={6} alignItems="center">
        <Avatar sx={{
          width: 40,
          height: 40,
          background: '#EBEDF5',
          color: theme.palette.primary.main,
          fill: theme.palette.primary.main,
        }}
        >
          <DocumentIcon size={24} />
        </Avatar>
        <BoldTypography variant="h6" sx={{ textAlign: 'center' }}>{l['retention.insight.missingData.title']}</BoldTypography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>{l['retention.insight.missingData.description']}</Typography>
      </Stack>
    </Card>
  );
};
