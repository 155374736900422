import { Slider as MuiSlider, SliderProps, useTheme } from '@mui/material';

export const Slider = ({
  value,
  min,
  max,
  step = 1,
  onChange,
}: {
  value: number,
  min: number,
  max: number,
  step?: number,
  onChange: SliderProps['onChange'],
}) => {
  const theme = useTheme();

  return (
    <MuiSlider
      value={value}
      onChange={onChange}
      step={step}
      marks
      min={0}
      max={max}
      sx={{
        color: theme.palette.primary.dark,
        '& .MuiSlider-track, & .MuiSlider-rail': {
          height: 14,
        },
        '& .MuiSlider-rail': {
          background: theme.palette.grey.A100,
        },
        '& .MuiSlider-mark': {
          height: 8,
          width: 8,
          borderRadius: '50%',
          color: '#ABABAB',
        },
        '& .MuiSlider-thumb': {
          color: theme.palette.background.paper,
          height: 24,
          width: 24,
        },
        '& .MuiSlider-mark[data-index="0"]': {
          left: `${theme.spacing(2)} !important`,
        },
        '& .MuiSlider-mark[data-index="4"]': {
          left: '96% !important',
        },
        ...(value === min && {
          '& .MuiSlider-thumb': {
            ml: 3,
            color: theme.palette.background.paper,
          },
          '& .MuiSlider-track': {
            opacity: 0,
          },
        }),
        ...(value === max && {
          '& .MuiSlider-thumb': {
            ml: -2.5,
            color: theme.palette.background.paper,
          },
        }),
      }}
    />
  );
};
