import {
  axiosWithPayloadContext,
  BadOwnerPreferredMitigation,
  config,
  convertArrayToFirstElement,
  createHeaders,
  GetRetentionPolicyByPMQuery,
  GoodOwnerPreferredMitigation,
  MaxFinancialIncentive,
  queryGraphQL,
  RetentionPolicy,
  RiskDetectionSensitivity,
  SummaryLength, useAuth,
  Willingness,
  WritingStyle,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery } from '@tanstack/react-query';

import { QueryKey } from '../../types/enums';
import { getRetentionPolicyByPm } from '../graphql/queries';

export const useGetRetentionPolicy = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.RETENTION_POLICY], async (): Promise<RetentionPolicy | null> => {
    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getRetentionPolicyByPm,
      variables: { pm },
      authToken: token,
    }) as GraphQLResult<GetRetentionPolicyByPMQuery>;

    if (!res.data?.getRetentionPolicyByPM?.items || res.data.getRetentionPolicyByPM.items.length === 0) {
      return null;
    }

    return convertArrayToFirstElement<RetentionPolicy>(res.data.getRetentionPolicyByPM);
  });
};

export type RetentionPolicyData = {
  maxFinancialIncentive: MaxFinancialIncentive,
  giveIncentiveWillingness: Willingness,
  meetWillingness: Willingness,
  badOwnerPreferredMitigation: BadOwnerPreferredMitigation,
  goodOwnerPreferredMitigation: GoodOwnerPreferredMitigation,
  writingStyle: WritingStyle,
  riskDetectionSensitivity: RiskDetectionSensitivity,
  summaryLength: SummaryLength
};

export const useMutateRetentionPolicy = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    retentionPolicy,
    currentID,
  }: {
    retentionPolicy: RetentionPolicyData,
    currentID?: string,
  }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/retention-policy`,
      method: currentID ? 'PUT' : 'POST',
      headers: createHeaders(token),
      data: {
        id: currentID,
        ...retentionPolicy,
        writingStyles: [retentionPolicy.writingStyle],
      },
    });
  });
};
