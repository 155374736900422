import {
  BoldTypography,
} from 'ui';
import {
  Box,
  Card,
  Stack, Typography, useTheme,
} from '@mui/material';

export const InsightDetailCard = ({
  negative = false,
  hideDot = false,
  label,
  value,
}: {
  negative?: boolean,
  label: string,
  value: string,
  hideDot?: boolean,
}) => {
  const theme = useTheme();

  return (
    <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
      <Stack gap={1} p={3}>
        <Stack gap={2} direction="row" alignItems="center">
          {!hideDot && (
            <Box sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: negative ? theme.palette.error.main : theme.palette.success.main,
            }}
            />
          )}
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Stack>
        <BoldTypography variant="h6">
          {value}
        </BoldTypography>
      </Stack>
    </Card>
  );
};
