import {
  Features, getOwnerFromID, OwnerRiskStatus, SatisfactionLevel,
} from 'lib';
import { signal } from '@preact/signals-react';

import { BasicOwnerRisk } from '../../api/properties/types';

export enum RetainTab {
  NEW = 'new',
  IN_PROGRESS = 'in-progress',
  RESOLVED = 'resolved',
  NO_RISK = 'no-risk',
}

export const currentRetainTab = signal<RetainTab | null>(null);

// eslint-disable-next-line no-spaced-func
export const retentionPolicyDialogState = signal<{ open: boolean, openDialog: () => void }>({
  open: false,
  openDialog: () => { },
});

export const showOwnerRiskMovedToInProgressToast = signal(false);

type OwnerSentimentDialogState = {
  openDialog: () => void,
  ownerEmail: string | null,
  ownerRiskID: string | null,
  satisfactionLevel: SatisfactionLevel | null,
  afterSubmit: () => Promise<void>,
};

export const ownerSentimentDialogState = signal<OwnerSentimentDialogState>({
  openDialog: () => { },
  ownerEmail: null,
  ownerRiskID: null,
  satisfactionLevel: null,
  afterSubmit: () => Promise.resolve(),
});

export const checkOwnerSentiment = (
  ownerRisk: BasicOwnerRisk,
  features: Features,
  onActClick: (ownerRisk: BasicOwnerRisk) => Promise<void>,
) => {
  if (ownerRisk.status !== OwnerRiskStatus.RESOLVED
    && features.isRiskV2Enabled
    && !ownerRisk.ownerRetentionDetails?.satisfactionLevel) {
    const owner = getOwnerFromID(ownerRisk!.ownerID);

    ownerSentimentDialogState.value.ownerEmail = owner.email;
    ownerSentimentDialogState.value.ownerRiskID = ownerRisk.id;
    ownerSentimentDialogState.value.satisfactionLevel = ownerRisk.ownerRetentionDetails?.satisfactionLevel ?? null;
    ownerSentimentDialogState.value.openDialog();
    ownerSentimentDialogState.value.afterSubmit = () => onActClick(ownerRisk);

    return true;
  }

  return false;
};
