import {
  useCallback,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAnalytics, useFeatures } from 'lib';
import { HiViewList } from 'react-icons/hi';
import { IoGrid } from 'react-icons/io5';
import {
  BoldTypography,
  PageTitle,
  Spacer, useLabels,
} from 'ui';
import { z } from 'zod';
import {
  Box,
  Button,
  ButtonGroup,
  Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { FiltersContainer } from './filters/Filters';
import { BuyBox } from './BuyBox';
import { ListingsCardView } from './ListingsCardView';
import { ListingsTableView } from './ListingsTableView';
import { SettableFilters } from './props';
import { Filters, filterSchema } from '../../api/properties/searchable';
import { WantToSellPropertyWidget } from '../../components/sell-property/SellPropertyButton';
import { useGetB2CReferralProgram } from '../../hooks/useGetB2CReferralProgram';

type FilterKey = keyof Filters;

const predefinedFiltersKey: FilterKey = 'predefinedFilters';
const minPriceKey: FilterKey = 'minPrice';
const maxPriceKey: FilterKey = 'maxPrice';
const minBedsKey: FilterKey = 'minBeds';
const minBathsKey: FilterKey = 'minBaths';
const bedsUseExactMatchKey: FilterKey = 'bedsUseExactMatch';
const sortByKey: FilterKey = 'sortBy';
const selectedSearchOptionsKey: FilterKey = 'selectedSearchOptions';
const categoriesKey: FilterKey = 'categories';
const modeKey = 'mode';

export const ListingsPage = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const features = useFeatures();

  const [searchParams, setSearchParamsOriginal] = useSearchParams({});
  const [purchaseQuestionnaireDialogOpen, setPurchaseQuestionnaireDialogOpen] = useState<boolean>(false);
  const [tableView, setTableView] = useState<boolean>(searchParams.get(modeKey) === 'list');
  const { shouldDisplayReferralProgram } = useGetB2CReferralProgram();
  const navigate = useNavigate();

  const selectedOptionsFromParams = z.array(z.string());

  const parseStringArray = (jsonData: string) => {
    try {
      return selectedOptionsFromParams.parse(JSON.parse(jsonData));
    } catch (e) {
      console.error(e);
    }

    return [];
  };

  const filters = filterSchema.parse({
    [predefinedFiltersKey]: parseStringArray(searchParams.get(predefinedFiltersKey) ?? '[]'),
    [minPriceKey]: searchParams.get(minPriceKey),
    [maxPriceKey]: searchParams.get(maxPriceKey),
    [minBedsKey]: searchParams.get(minBedsKey),
    [minBathsKey]: searchParams.get(minBathsKey),
    [bedsUseExactMatchKey]: searchParams.get(bedsUseExactMatchKey),
    [sortByKey]: searchParams.get(sortByKey),
    [selectedSearchOptionsKey]: parseStringArray(searchParams.get(selectedSearchOptionsKey) ?? '[]'),
    [categoriesKey]: parseStringArray(searchParams.get(categoriesKey) ?? '[]'),
  });

  const filtersClearedWithoutCategories = (
    !filters.predefinedFilters.length
    && !filters.minPrice
    && !filters.maxPrice
    && !filters.minBeds
    && !filters.minBaths
    && !filters.bedsUseExactMatch
    && !filters.selectedSearchOptions.length
  );

  const filtersCleared = (
    filtersClearedWithoutCategories && !filters.categories.length
  );

  const isFilterRelevantForCount = (key: keyof SettableFilters) => {
    if (key === categoriesKey) {
      return filters.categories?.length > 0;
    }

    if (key === predefinedFiltersKey) {
      return filters.predefinedFilters?.length > 0;
    }

    return key in filters && key !== sortByKey && key !== selectedSearchOptionsKey;
  };

  const activeFilterCount = Array.from(searchParams.keys()).filter(
    (k) => isFilterRelevantForCount(k as keyof SettableFilters),
  ).length;

  const setSearchParams = (params: Partial<SettableFilters>) => {
    if (!params) return;

    const filtersFromSearchParams = {
      ...Object.fromEntries(searchParams.entries()),
    };

    Object.keys(params).forEach((key) => {
      const value = (
        key === predefinedFiltersKey || key === categoriesKey
          ? JSON.stringify(params[key])
          : params[key as keyof SettableFilters]?.toString()!
      );
      if (value) {
        filtersFromSearchParams[key] = value;
      } else if (filtersFromSearchParams[key]) {
        delete filtersFromSearchParams[key];
      }
    });

    setSearchParamsOriginal(filtersFromSearchParams);
  };

  const setSelectedOptions = (options: string[]) => {
    setSearchParams({
      selectedSearchOptions: JSON.stringify(options),
    });
  };

  const resetFilters = () => {
    setSearchParamsOriginal({
      [modeKey]: tableView ? 'list' : 'card',
    });
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [headerHeight, setHeaderHeight] = useState(0);

  const headerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeaderHeight(node.clientHeight);
    }
    // these dependencies are necessary because otherwise the size will be fixed after the initial load
  }, [isMobile, filters.selectedSearchOptions]);

  const handleTableView = (isTableView: boolean) => {
    analytics.track('Button Clicked', {
      buttonName: 'Table/Card View',
      isTableView,
    });
    setSearchParamsOriginal({
      [modeKey]: isTableView ? 'list' : 'card',
    });
    setTableView(isTableView);
  };

  const changeModeButtons = (
    <ButtonGroup size="large">
      <Button
        variant={tableView ? 'outlined' : 'contained'}
        sx={{
          px: `${theme.spacing(1)} !important`,
        }}
        onClick={() => handleTableView(false)}
      >
        <IoGrid />
      </Button>
      <Button
        variant={tableView ? 'contained' : 'outlined'}
        sx={{
          px: `${theme.spacing(1)} !important`,
        }}
        onClick={() => handleTableView(true)}
      >
        <HiViewList />
      </Button>
    </ButtonGroup>
  );

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '100vw',
          top: { xs: 52, lg: 0 },
          left: 0,
          height: headerHeight,
          zIndex: -1,
          backgroundColor: theme.palette.background.paper,
        }}
      />
      <Stack px={3} minHeight="100%">
        <Stack ref={headerRef}>
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            justifyContent="space-between"
            gap={{ xs: 3, md: 0 }}
            py={5}
            sx={{
              overflow: 'visible',
            }}
          >
            <Stack
              justifyContent="space-between"
              flexGrow={1}
            >
              {!isMobile && (
                <PageTitle title={l['listings.marketplace']} />
              )}
              <Stack
                gap={2}
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
              >
                <FiltersContainer
                  filters={filters}
                  setFilters={setSearchParams}
                  resetFilters={resetFilters}
                  setSelectedOptions={setSelectedOptions}
                  activeFilterCount={activeFilterCount}
                  usedFilters={Array.from(searchParams.keys()) as (keyof SettableFilters)[]}
                />
                {features.isMarketplaceListViewEnabled && (
                  <Stack ml={{ xs: 'auto', md: 'unset' }}>
                    {changeModeButtons}
                  </Stack>
                )}
              </Stack>
            </Stack>
            {shouldDisplayReferralProgram && (
              <Box maxWidth={{ xs: '100%', md: '50%' }} maxHeight={{ xs: 'auto', md: '144px' }}>
                <WantToSellPropertyWidget
                  onClick={() => { navigate('/marketplace/sell-property'); }}
                  height={140}
                />
              </Box>
            )}
            <Box maxWidth={{ xs: 'auto', md: '50%' }}>
              <BuyBox
                purchaseQuestionnaireDialogOpen={purchaseQuestionnaireDialogOpen}
                setPurchaseQuestionnaireDialogOpen={setPurchaseQuestionnaireDialogOpen}
              />
            </Box>
            {isMobile && (
              <BoldTypography variant="h6">
                {l['listings.marketplace']}
              </BoldTypography>
            )}
          </Stack>
        </Stack>
        <Spacer spacing={3} />
        {tableView && features.isMarketplaceListViewEnabled ? (
          <ListingsTableView
            filters={filters}
            filtersCleared={filtersCleared}
            setPurchaseQuestionnaireDialogOpen={setPurchaseQuestionnaireDialogOpen}
          />
        ) : (
          <ListingsCardView
            filters={filters}
            filtersCleared={filtersCleared}
            filtersClearedWithoutCategories={filtersClearedWithoutCategories}
            setFilters={setSearchParams}
            setPurchaseQuestionnaireDialogOpen={setPurchaseQuestionnaireDialogOpen}
          />
        )}
      </Stack>
    </>
  );
};
