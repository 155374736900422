import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useAnalytics,
  useAuth,
  useFeatures,
  useGetUserActions, useMutateUserActions,
} from 'lib';
import { MdHome } from 'react-icons/md';
import {
  Avatar,
  ControlledTableView,
  formatDecimalToPercentage,
  formatNumber,
  formatNumberToCurrency,
  SemiBoldTypography,
  Store, useLabels,
} from 'ui';
import {
  Box,
  Grid, Stack, Typography, useTheme,
} from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { FavoriteButton } from './FavoriteButton';
import { ShareButton } from './ShareButton';
import { WelcomeToMarketplaceDialog } from './WelcomeToMarketplace';
import { useSearchProperties } from '../../api/properties';
import { Filters } from '../../api/properties/searchable';
import { ListingProperty } from '../../types/property';

const useColumns = () => {
  const l = useLabels();
  const theme = useTheme();
  const features = useFeatures();

  const dash = (
    <Box
      height="1px"
      width={20}
      sx={{ background: theme.palette.primary.main }}
      mx="auto"
    />
  );

  const columns: ColumnDef<ListingProperty, string>[] = [
    {
      header: 'LISTING',
      cell: ({ row }) => {
        const listingItem = row.original;

        return (
          <Stack direction="row" alignItems="center" gap={3}>
            <Avatar
              src={listingItem.primaryPicture ?? undefined}
              sx={{
                width: 34, height: 34, borderRadius: '8px', backgroundColor: '#DADADA',
              }}
            >
              <MdHome size={24} color={theme.palette.primary.contrastText} />
            </Avatar>
            <Stack justifyContent="space-between">
              <SemiBoldTypography variant="body2" sx={{ textWrap: 'nowrap' }}>
                {row.original.address.fullAddress}
              </SemiBoldTypography>
              <Stack direction="row" spacing={3} justifyContent="flex-start" alignItems="center" mt={1}>
                {listingItem.property.bedrooms ? (
                  <Typography variant="caption" color="text.secondary">
                    {listingItem.property.bedrooms}
                    {' '}
                    {l.beds.toLocaleLowerCase()}
                  </Typography>
                ) : null}
                {listingItem.property.bathrooms ? (
                  <Typography variant="caption" color="text.secondary">
                    {listingItem.property.bathrooms}
                    {' '}
                    {l.baths.toLocaleLowerCase()}
                  </Typography>
                ) : null}
                {listingItem.property.sqft ? (
                  <Typography variant="caption" color="text.secondary">
                    {formatNumber(listingItem.property.sqft, 0)}
                    {' '}
                    {l.sqft.toLocaleLowerCase()}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
        );
      },
      meta: {
        textNoWrap: true,
      },
    },
    {
      header: 'PRICE',
      cell: ({ row }) => (
        <Typography variant="body2">{formatNumberToCurrency(row.original.askingPrice, 0)}</Typography>
      ),
    },
    {
      header: 'RENT',
      cell: ({ row }) => (row.original.rent.value ? (
        <Typography variant="body2">{formatNumberToCurrency(row.original.rent.value ?? 0, 0)}</Typography>
      ) : dash),
    },
    {
      header: 'GROSS YIELD',
      cell: ({ row }) => {
        const listing = row.original;

        const grossYield = features.isMarketplaceGrossYieldAsDollar ? (
          formatNumberToCurrency(listing.measures.grossYield * listing.askingPrice, 1, {
            style: 'currency',
            notation: 'compact',
            compactDisplay: 'short',
          })
        ) : formatDecimalToPercentage(listing.measures.grossYield, 2);

        return (
          <Typography variant="body2" sx={{ textAlign: 'center', width: '100%' }}>{grossYield}</Typography>
        );
      },
      meta: {
        headerTooltipContent: (
          <Typography variant="body2" sx={{ p: 1 }}>
            {l['listings.grossYield.tooltip']}
          </Typography>
        ),
        alignHeaderCenter: true,
      },
    },
    {
      header: 'CAP RATE',
      cell: ({ row }) => (
        <Typography variant="body2" sx={{ textAlign: 'center', width: '100%' }}>
          {formatDecimalToPercentage(row.original.measures.capRate, 2)}
        </Typography>
      ),
      meta: {
        headerTooltipContent: (
          <Typography variant="body2" sx={{ p: 1 }}>
            {l['listings.capRate.tooltip']}
          </Typography>
        ),
        alignHeaderCenter: true,
      },
    },
    {
      header: 'YEAR BUILT',
      size: 120,
      minSize: 120,
      cell: ({ row }) => (row.original.property.yearBuilt ? (
        <Typography variant="body2" sx={{ textAlign: 'center', width: '100%' }}>{row.original.property.yearBuilt}</Typography>
      ) : dash),
      meta: {
        alignHeaderCenter: true,
      },
    },
    {
      header: '',
      id: 'actions',
      cell: ({ row }) => {
        const listingItem = row.original;
        const [favoriteId, setFavoriteId] = useState<string | null>(listingItem.favoriteId);

        return (
          <Stack direction="row" gap={3}>
            <ShareButton hideIconButtonBackground listingID={listingItem.id} />
            <FavoriteButton
              onChangeFavorite={(id: string | null) => {
                setFavoriteId(id);
              }}
              favoriteId={favoriteId}
              marketplacePropertyId={listingItem.id}
            />
          </Stack>
        );
      },
    },
  ];

  return columns;
};

export const ListingsTableView = ({
  filters,
  setPurchaseQuestionnaireDialogOpen,
  filtersCleared,
}: {
  filters: Filters,
  filtersCleared: boolean,
  setPurchaseQuestionnaireDialogOpen: (v: boolean) => void,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const [welcomeToMarketplaceDialogOpen, setWelcomeToMarketplaceDialogOpen] = useState<boolean>(false);
  const [viewedWelcomeToMarketplaceScreen, setViewedWelcomeToMarketplaceScreen] = useState<boolean>(false);

  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const {
    data: listingPages,
    isLoading: isLoadingListings,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSearchProperties(filters, 20);

  const onBottom = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const columns = useColumns();

  const listings = listingPages?.pages.flatMap((page) => page.properties) ?? [];

  const isLoading = isLoadingFeatures || isLoadingListings;

  const { user } = useAuth();

  const {
    data: userActionsData,
  } = useGetUserActions(user?.id ?? '');

  const { mutateAsync: updateUserActions } = useMutateUserActions();

  useEffect(() => {
    if (!user?.isPm
      && !features.isFreemium
      && userActionsData?.viewedWelcomeToMarketplaceScreen === false
      && !welcomeToMarketplaceDialogOpen
      && !viewedWelcomeToMarketplaceScreen) {
      setWelcomeToMarketplaceDialogOpen(true);
      updateUserActions({ viewedWelcomeToMarketplaceScreen: true });
      setViewedWelcomeToMarketplaceScreen(true);
    }
  }, [userActionsData, welcomeToMarketplaceDialogOpen, viewedWelcomeToMarketplaceScreen]);

  if (listings.length === 0 && !isLoading && filtersCleared) {
    return (
      <Grid item xs={12}>
        <Stack alignItems="center" justifyContent="center">
          <Box my={3}>
            <Store height={40} width={40} />
          </Box>
          <Typography variant="h6">
            {l['listings.marketplaceIsEmptyTitle']}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {l['listings.marketplaceIsEmptyDescription']}
          </Typography>
        </Stack>
      </Grid>
    );
  }

  const totalProperties = listings.length;

  const noPropertiesFound = totalProperties === 0 && !isLoading && !isFetchingNextPage;

  return (
    <>
      <Stack gap={2}>
        {!noPropertiesFound && (
          <ControlledTableView
            data={listings}
            columns={columns}
            maxHeight={800}
            onBottom={onBottom}
            showLoader={isFetchingNextPage || isLoading}
            onClick={(listingItem) => {
              analytics.track('Button Clicked', {
                buttonName: 'Listing Row',
                propertyAddress: listingItem.address.fullAddress,
                propertyId: listingItem.propertyID,
                listingId: listingItem.id,
              });

              navigate(`/marketplace/${listingItem.id}`);
            }}
          />
        )}
        {noPropertiesFound && (
          <Grid item xs={12}>
            <Stack alignItems="center" mt={10} p={4}>
              <Box my={3}>
                <Store height={40} width={40} />
              </Box>
              <Typography variant="h6">
                {l['listings.noMatchesTitle']}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {l['listings.noMatchesDescription']}
              </Typography>
            </Stack>
          </Grid>
        )}
      </Stack>
      <WelcomeToMarketplaceDialog
        open={welcomeToMarketplaceDialogOpen}
        closeDialog={() => {
          setWelcomeToMarketplaceDialogOpen(false);
          setPurchaseQuestionnaireDialogOpen(true);
        }}
      />
    </>
  );
};
