import { ResolvedReason } from 'lib';
import { formatPotentialTodayDate, LightTooltip, useLabels } from 'ui';
import { Stack, Typography } from '@mui/material';

import { BasicOwnerRisk } from '../../../../api/properties/types';

export const ResolutionStatus = ({ ownerRisk }: { ownerRisk: BasicOwnerRisk }) => {
  const l = useLabels();

  const resolvedReasonToShort: Record<ResolvedReason, string> = {
    [ResolvedReason.OWNER_WANTS_TO_STAY]: 'Stayed',
    [ResolvedReason.OWNER_WANTS_TO_SELL]: 'Sold',
    [ResolvedReason.OWNER_WANTS_TO_LEAVE]: 'Left',
    [ResolvedReason.OTHER]: 'Other',
  };

  return (
    <LightTooltip
      arrow
      title={(
        <Stack p={1} gap={1}>
          <Typography variant="body2">
            {({
              [ResolvedReason.OWNER_WANTS_TO_STAY]: l['retention.resolve.reason.ownerWantsToStay'],
              [ResolvedReason.OWNER_WANTS_TO_SELL]: l['retention.resolve.reason.ownerWantsToSell'],
              [ResolvedReason.OWNER_WANTS_TO_LEAVE]: l['retention.resolve.reason.ownerWantsToLeave'],
              [ResolvedReason.OTHER]: l['retention.resolve.reason.other'],
            }[ownerRisk.resolvedReason ?? ResolvedReason.OTHER])}
          </Typography>
          <Typography variant="body2">
            {l.dynamic['retention.resolutionDate'](ownerRisk.resolvedDate!)}
          </Typography>
          {ownerRisk.resolvedReason === ResolvedReason.OTHER && (
            <Typography variant="body2">
              {l.reason}
              :
              {' '}
              {ownerRisk.resolvedReason}
            </Typography>
          )}
        </Stack>
      )}
    >
      <Typography variant="body1">
        {formatPotentialTodayDate(ownerRisk.resolvedDate!)}
        {' '}
        (
        {resolvedReasonToShort[ownerRisk.resolvedReason ?? ResolvedReason.OTHER]}
        )
      </Typography>
    </LightTooltip>
  );
};
