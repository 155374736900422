import { ReactNode } from 'react';

import { useAppSettings, useAuth } from 'lib';
import { MdLanguage, MdPerson } from 'react-icons/md';
import {
  Divider,
  Stack, SxProps, Typography, useTheme,
} from '@mui/material';

import { useLabels } from '../../lib/translations';

export const PdfPage = ({
  children,
  sx = undefined,
  constraintHeight = true,
}: {
  children: ReactNode,
  sx?: SxProps,
  constraintHeight?: boolean,
}) => (
  <Stack
    width="8.5in"
    height={constraintHeight ? '11in' : undefined}
    sx={sx}
    className="pdf-page"
  >
    {children}
  </Stack>
);

export const PdfPageWithLayout = ({
  children,
  sx = undefined,
  pageNumber = undefined,
  ...props
}: {
  children: ReactNode,
  pageNumber?: number,
  sx?: SxProps,
  constraintHeight?: boolean,
}) => {
  const theme = useTheme();
  const l = useLabels();
  const { data: appSettings } = useAppSettings();
  const { user } = useAuth();

  return (
    <PdfPage
      sx={{
        backgroundColor: theme.palette.background.paper,
        zIndex: pageNumber,
        ...sx,
      }}
      {...props}
    >
      <Stack width="100%">
        <Stack
          pt={2}
          sx={{
            '&&&&': {
              px: 6,
              fontSize: '1rem !important',
              '&&& .dynamic-html': {
                fontSize: '1rem !important',
              },
              '& .apexcharts-canvas text': { fontSize: '0.75rem' },
              '& .portfolio-performance-icon': {
                height: '2rem',
                width: '2rem',
              },
              '& .graph-circle-icon': {
                height: '1.5rem',
                width: '1.5rem',
              },
              '& .graph-trend-icon': {
                height: '1.25rem',
                width: '1.25rem',
              },
              '&&& .area-chart': {
                minHeight: '10rem !important',
              },
            },
          }}
        >
          <Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <img src={`${appSettings?.logo ?? ''}?v=annual-report-pdf`} style={{ height: '3rem' }} alt="logo" />
              <Stack direction="row" gap="1rem">
                <Stack direction="row" gap="0.25rem" alignItems="center">
                  <MdLanguage size="1.25rem" />
                  <Typography variant="body1">{appSettings?.id}</Typography>
                </Stack>
                <Stack direction="row" gap="0.25rem" alignItems="center">
                  <MdPerson size="1.25rem" />
                  <Typography variant="body1">
                    {`${user?.givenName} ${user?.familyName ?? ''}`}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
          </Stack>
          {children}
        </Stack>
      </Stack>
      <Stack width="100%" mt="auto">
        <Divider sx={{ my: 0 }} />
        <Stack width="100%" direction="row" justifyContent="space-between" pr="3rem" py="1rem">
          <Stack flexGrow={1} justifyContent="center" alignItems="center">
            <Typography variant="body1">
              {l['annualReport.header.title']}
            </Typography>
          </Stack>
          {pageNumber && <Typography variant="caption">{pageNumber}</Typography>}
        </Stack>
      </Stack>
    </PdfPage>
  );
};
