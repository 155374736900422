import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useAnalytics,
  useAuth,
  useFeatures,
  useGetUserActions, useListingCardGrossYieldAsDollarFF, useMutateUserActions,
} from 'lib';
import {
  Store, useLabels,
} from 'ui';
import {
  Box,
  Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { InviteProspectBanner } from './filters/InviteProspectBanner';
import { CategoryListings, CategoryListingsProps } from './CategoryListings';
import { ExpandableListingProps, ExpandableListings } from './ExpandableListings';
import { InviteOwnersBanner } from './InviteOwnersBanner';
import { SettableFilters } from './props';
import { getListingsFromPages } from './utils';
import { WelcomeToMarketplaceDialog } from './WelcomeToMarketplace';
import { useSearchProperties } from '../../api/properties';
import { Category, Filters } from '../../api/properties/searchable';
import { ListingProperty } from '../../types/property';

import './carousel.css';
import 'react-multi-carousel/lib/styles.css';

const toggleCategory = (categories: Category[], category: Category) => {
  if (categories.includes(category)) {
    return categories.filter((c) => c !== category);
  }

  return [...categories, category];
};

const useFetchAllCategories = (filters: Filters, propertiesPerPage: number, filtersCleared: boolean) => {
  const {
    data: mixedCategoryData,
    isLoading: mixedCategoryIsLoading,
    fetchNextPage: mixedCategoryFetchNextPage,
    hasNextPage: mixedCategoryHasNextPage,
    isFetchingNextPage: mixedCategoryIsFetchingNextPage,
  } = useSearchProperties({
    ...filters,
    categories: filters.categories,
  }, propertiesPerPage, !filtersCleared);
  const {
    data: internalData,
    isLoading: internalIsLoading,
    fetchNextPage: internalFetchNextPage,
    hasNextPage: internalHasNextPage,
    isFetchingNextPage: internalIsFetchingNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['internal'],
  }, propertiesPerPage);
  const {
    data: newConstructionData,
    isLoading: newConstructionIsLoading,
    fetchNextPage: newConstructionFetchNextPage,
    isFetchingNextPage: newConstructionIsFetchingNextPage,
    hasNextPage: newConstructionHasNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['new_construction'],
  }, propertiesPerPage);
  const {
    data: wholesaleData,
    isLoading: wholesaleIsLoading,
    fetchNextPage: wholesaleFetchNextPage,
    isFetchingNextPage: wholesaleIsFetchingNextPage,
    hasNextPage: wholesaleHasNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['wholesale'],
  }, propertiesPerPage);
  const {
    data: turnkeyData,
    isLoading: turnkeyIsLoading,
    fetchNextPage: turnkeyFetchNextPage,
    isFetchingNextPage: turnkeyIsFetchingNextPage,
    hasNextPage: turnkeyHasNextPage,
  } = useSearchProperties({
    ...filters,
    categories: ['turnkey'],
  }, propertiesPerPage);

  return {
    isLoading: internalIsLoading || newConstructionIsLoading || wholesaleIsLoading || turnkeyIsLoading,
    isFetchingNextPage: (
      mixedCategoryIsFetchingNextPage || internalIsFetchingNextPage
      || newConstructionIsFetchingNextPage || wholesaleIsFetchingNextPage || turnkeyIsFetchingNextPage
    ),
    mixedCategories: {
      data: mixedCategoryData,
      isLoading: mixedCategoryIsLoading,
      fetchNextPage: mixedCategoryFetchNextPage,
      hasNextPage: mixedCategoryHasNextPage,
      isFetchingNextPage: mixedCategoryIsFetchingNextPage,
    },
    internal: {
      data: internalData,
      isLoading: internalIsLoading,
      fetchNextPage: internalFetchNextPage,
      hasNextPage: internalHasNextPage,
      isFetchingNextPage: internalIsFetchingNextPage,
    },
    newConstruction: {
      data: newConstructionData,
      isLoading: newConstructionIsLoading,
      fetchNextPage: newConstructionFetchNextPage,
      isFetchingNextPage: newConstructionIsFetchingNextPage,
      hasNextPage: newConstructionHasNextPage,
    },
    turnkey: {
      data: turnkeyData,
      isLoading: turnkeyIsLoading,
      fetchNextPage: turnkeyFetchNextPage,
      isFetchingNextPage: turnkeyIsFetchingNextPage,
      hasNextPage: turnkeyHasNextPage,
    },
    wholesale: {
      data: wholesaleData,
      isLoading: wholesaleIsLoading,
      fetchNextPage: wholesaleFetchNextPage,
      isFetchingNextPage: wholesaleIsFetchingNextPage,
      hasNextPage: wholesaleHasNextPage,
    },
  };
};

export const ListingsCardView = ({
  filters,
  setFilters,
  setPurchaseQuestionnaireDialogOpen,
  filtersCleared,
  filtersClearedWithoutCategories,
}: {
  filters: Filters,
  filtersCleared: boolean,
  filtersClearedWithoutCategories: boolean,
  setFilters: (filters: Partial<SettableFilters>) => void,
  setPurchaseQuestionnaireDialogOpen: (v: boolean) => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const analytics = useAnalytics();
  const { value: showGrossYieldAsDollar, loading: loadingGrossYieldAsDollarFF } = useListingCardGrossYieldAsDollarFF();

  const [welcomeToMarketplaceDialogOpen, setWelcomeToMarketplaceDialogOpen] = useState<boolean>(false);
  const [viewedWelcomeToMarketplaceScreen, setViewedWelcomeToMarketplaceScreen] = useState<boolean>(false);
  const propertiesPerPage = isMobile ? 8 : 18;

  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const {
    isLoading: isLoadingCategories,
    isFetchingNextPage,
    ...fetchData
  } = useFetchAllCategories(filters, propertiesPerPage, filtersCleared);

  const isLoading = isLoadingFeatures || isLoadingCategories || loadingGrossYieldAsDollarFF;

  const { user } = useAuth();

  const {
    data: userActionsData,
  } = useGetUserActions(user?.id ?? '');

  const { mutateAsync: updateUserActions } = useMutateUserActions();

  useEffect(() => {
    analytics.identify(user!.realID, {
      showGrossYieldAsDollar,
    }, features, !!user?.actAs);
  }, [showGrossYieldAsDollar, features.isUserGuidingEnabled]);

  useEffect(() => {
    if (!user?.isPm
      && !features.isFreemium
      && userActionsData?.viewedWelcomeToMarketplaceScreen === false
      && !welcomeToMarketplaceDialogOpen
      && !viewedWelcomeToMarketplaceScreen) {
      setWelcomeToMarketplaceDialogOpen(true);
      updateUserActions({ viewedWelcomeToMarketplaceScreen: true });
      setViewedWelcomeToMarketplaceScreen(true);
    }
  }, [userActionsData, welcomeToMarketplaceDialogOpen, viewedWelcomeToMarketplaceScreen]);

  const mixedCategoryProperties = getListingsFromPages(fetchData.mixedCategories.data?.pages ?? []);
  const internalProperties = getListingsFromPages(fetchData.internal.data?.pages ?? []);
  const newConstructionProperties = getListingsFromPages(fetchData.newConstruction.data?.pages ?? []);
  const turnkeyProperties = getListingsFromPages(fetchData.turnkey.data?.pages ?? []);
  const wholesaleProperties = getListingsFromPages(fetchData.wholesale.data?.pages ?? []);

  const changeExpandedCategory = (cat: Category) => {
    setFilters({ categories: toggleCategory(filters.categories ?? [], cat) });
  };

  const getCategoryLabel = (category: Category) => {
    switch (category) {
      case 'internal':
        return l.exclusive;
      case 'new_construction':
        return l.newConstruction;
      case 'turnkey':
        return l.turnkey;
      case 'wholesale':
        return l.wholesale;
      default:
        return '';
    }
  };

  type CategoryAndListings = {
    category: Category,
    properties: ListingProperty[],
  };

  const singleAvailableCategory: Category | null = useMemo(() => {
    const categoryAndListings: CategoryAndListings[] = [
      { category: 'internal', properties: internalProperties },
      { category: 'new_construction', properties: newConstructionProperties },
      { category: 'turnkey', properties: turnkeyProperties },
      { category: 'wholesale', properties: wholesaleProperties },
    ];

    const filtered = categoryAndListings.filter((c) => c.properties.length > 0);

    if (!isLoadingCategories && filtersCleared && filtered.length === 1) {
      return filtered[0].category;
    }

    return null;
  }, [
    filtersCleared,
    internalProperties,
    fetchData.internal,
    newConstructionProperties,
    fetchData.newConstruction,
    turnkeyProperties,
    fetchData.turnkey,
    wholesaleProperties,
    fetchData.wholesale,
    isLoadingCategories,
  ]);

  const shouldUseClassName = (category: Category) => {
    // use the class name only for the first category with data

    if (isLoading) return false;

    switch (category) {
      case 'internal':
        return !!internalProperties.length;
      case 'new_construction':
        return !!newConstructionProperties.length && !internalProperties.length;
      case 'turnkey':
        return !!turnkeyProperties.length && !internalProperties.length && !newConstructionProperties.length;
      case 'wholesale':
        return (
          !!wholesaleProperties.length
          && !internalProperties.length
          && !newConstructionProperties.length
          && !turnkeyProperties.length
        );
      default:
        return false;
    }
  };

  const categoryToProps: Record<Category, Omit<CategoryListingsProps, 'forceLoading'>> = {
    internal: {
      categoryLabel: getCategoryLabel('internal'),
      properties: internalProperties,
      isLoading: fetchData.internal.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('internal'),
      showExpandButton: internalProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('internal'),
    },
    new_construction: {
      categoryLabel: getCategoryLabel('new_construction'),
      properties: newConstructionProperties,
      isLoading: fetchData.newConstruction.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('new_construction'),
      showExpandButton: newConstructionProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('new_construction'),
    },
    turnkey: {
      categoryLabel: getCategoryLabel('turnkey'),
      properties: turnkeyProperties,
      isLoading: fetchData.turnkey.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('turnkey'),
      showExpandButton: turnkeyProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('turnkey'),
    },
    wholesale: {
      categoryLabel: getCategoryLabel('wholesale'),
      properties: wholesaleProperties,
      isLoading: fetchData.wholesale.isLoading,
      changeExpandedCategory: () => changeExpandedCategory('wholesale'),
      showExpandButton: wholesaleProperties.length > 3,
      filtersCleared,
      forceHide: !!singleAvailableCategory,
      useClassName: shouldUseClassName('wholesale'),
    },
  };

  const handleMixedCategoryChange = () => {
    setFilters({ categories: [] });
  };

  const hasMixedCategoriesSelected = (filters.categories?.length ?? 0) > 1;
  const oneCategorySelected = (filters.categories?.length ?? 0) === 1;
  const defaultProps = oneCategorySelected ? categoryToProps[filters.categories![0]] : categoryToProps.internal;

  const mixedCategoryProps: Omit<ExpandableListingProps, 'forceLoading'> = {
    label: (hasMixedCategoriesSelected || !filtersClearedWithoutCategories) ? '' : defaultProps.categoryLabel,
    showHeader: !hasMixedCategoriesSelected && filtersClearedWithoutCategories,
    properties: mixedCategoryProperties,
    isLoading: fetchData.mixedCategories.isLoading,
    isFetchingNextPage: fetchData.mixedCategories.isFetchingNextPage,
    fetchNextPage: fetchData.mixedCategories.fetchNextPage,
    changeExpandedCategory: hasMixedCategoriesSelected ? handleMixedCategoryChange : defaultProps.changeExpandedCategory,
    showExpandButton: hasMixedCategoriesSelected ? false : defaultProps.properties.length > 3,
    expanded: !!singleAvailableCategory || !filtersCleared,
    hasNextPage: fetchData.mixedCategories.hasNextPage,
  };

  type NextPageProps = { isFetchingNextPage: boolean, hasNextPage: boolean, fetchNextPage: () => void };

  const categoryToNextPageProps: Record<Category, NextPageProps> = {
    internal: {
      isFetchingNextPage: fetchData.internal.isFetchingNextPage,
      hasNextPage: !!fetchData.internal.hasNextPage,
      fetchNextPage: fetchData.internal.fetchNextPage,
    },
    new_construction: {
      isFetchingNextPage: fetchData.newConstruction.isFetchingNextPage,
      hasNextPage: !!fetchData.newConstruction.hasNextPage,
      fetchNextPage: fetchData.newConstruction.fetchNextPage,
    },
    turnkey: {
      isFetchingNextPage: fetchData.turnkey.isFetchingNextPage,
      hasNextPage: !!fetchData.turnkey.hasNextPage,
      fetchNextPage: fetchData.turnkey.fetchNextPage,
    },
    wholesale: {
      isFetchingNextPage: fetchData.wholesale.isFetchingNextPage,
      hasNextPage: !!fetchData.wholesale.hasNextPage,
      fetchNextPage: fetchData.wholesale.fetchNextPage,
    },
  };

  const singleAvailableCategoryProps: Omit<ExpandableListingProps, 'forceLoading'> | null = singleAvailableCategory && {
    label: categoryToProps[singleAvailableCategory].categoryLabel,
    showHeader: true,
    properties: categoryToProps[singleAvailableCategory].properties,
    isLoading: categoryToProps[singleAvailableCategory].isLoading,
    isFetchingNextPage: categoryToNextPageProps[singleAvailableCategory].isFetchingNextPage,
    hasNextPage: categoryToNextPageProps[singleAvailableCategory].hasNextPage,
    fetchNextPage: categoryToNextPageProps[singleAvailableCategory].fetchNextPage,
    changeExpandedCategory: undefined,
    showExpandButton: false,
    expanded: true,
  };

  const allEmpty = Object.values(categoryToProps).every((c) => !c.properties.length);

  if (allEmpty && !isLoading && filtersCleared) {
    return (
      <Grid item xs={12}>
        <Stack alignItems="center" justifyContent="center">
          <Box my={3}>
            <Store height={40} width={40} />
          </Box>
          <Typography variant="h6">
            {l['listings.marketplaceIsEmptyTitle']}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {l['listings.marketplaceIsEmptyDescription']}
          </Typography>
        </Stack>
      </Grid>
    );
  }

  const totalProperties = (
    mixedCategoryProperties.length + internalProperties.length + newConstructionProperties.length + wholesaleProperties.length
  );

  // either nothing was found for all categories or nothing was found for the selected categories
  const noPropertiesFound = (
    (totalProperties === 0 && !isLoading && !isFetchingNextPage && !filters.categories?.length)
    || (filters.categories.length > 0 && mixedCategoryProperties.length === 0 && !fetchData.mixedCategories.isLoading)
  );

  return (
    <>
      <Grid
        container
        item
        rowGap={filters.categories ? 0 : 4}
        sx={{ transition: 'all 0.3s ease-in-out !important' }}
      >
        <ExpandableListings
          {...((singleAvailableCategory && singleAvailableCategoryProps) ? singleAvailableCategoryProps : mixedCategoryProps)}
          forceLoading={false}
        />
        {fetchData.internal.isLoading || internalProperties.length
          ? <CategoryListings {...categoryToProps.internal} forceLoading={isLoading} /> : null}
        {!isLoading && features.isFreemiumPM ? (
          <InviteOwnersBanner />
        ) : !isLoading && features.isInviteProspectsEnabled && (
          <InviteProspectBanner />
        )}
        {fetchData.newConstruction.isLoading || newConstructionProperties.length
          ? <CategoryListings {...categoryToProps.new_construction} forceLoading={isLoading} /> : null}
        {fetchData.turnkey.isLoading || turnkeyProperties.length
          ? <CategoryListings {...categoryToProps.turnkey} forceLoading={isLoading} /> : null}
        {fetchData.wholesale.isLoading || wholesaleProperties.length
          ? <CategoryListings {...categoryToProps.wholesale} forceLoading={isLoading} /> : null}
        {noPropertiesFound ? (
          <Grid item xs={12}>
            <Stack alignItems="center" mt={10} p={4}>
              <Box my={3}>
                <Store height={40} width={40} />
              </Box>
              <Typography variant="h6">
                {l['listings.noMatchesTitle']}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {l['listings.noMatchesDescription']}
              </Typography>
            </Stack>
          </Grid>
        ) : null}
      </Grid>
      <WelcomeToMarketplaceDialog
        open={welcomeToMarketplaceDialogOpen}
        closeDialog={() => {
          setWelcomeToMarketplaceDialogOpen(false);
          setPurchaseQuestionnaireDialogOpen(true);
        }}
      />
    </>
  );
};
