import { Stack, Typography } from '@mui/material';

import { CalculatedRow, DetailRow } from './Row';
import { formatDecimalToPercentage, formatNumberToCurrency } from '../../../lib/formatFuncs';
import { useLabels } from '../../../lib/translations';
import { InfoTooltip } from '../../../tooltip/InfoTooltip';
import { BoldTypography } from '../../../typography/BoldTypography';
import { useGetPropertyIdParam } from '../state';
import {
  Form,
} from '../types';
import { useProjections } from '../useProjections';

export const CashFlow = ({ form }: { form: Form }) => {
  const l = useLabels();
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form.watch(), propertyID);

  return (
    <>
      <DetailRow
        rowID="cash-flow"
        bottomBorder={false}
        columns={[
          {
            id: 'cash-flow"',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.cashFlow}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.netCashFlow']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          ...projections.years.map(() => ({})),
        ]}
      />
      <CalculatedRow
        totalRow
        label={l.netCashFlow}
        values={projections.years.map((_, i) => {
          const netCashFlow = projections.calculations.getNetCashFlow(i);

          return {
            value: formatNumberToCurrency(netCashFlow || 0, 0),
            isNegative: netCashFlow < 0,
          };
        })}
      />
      <CalculatedRow
        label={l.cashOnCash}
        bottomBorder={false}
        values={projections.years.map((_, i) => {
          const cashOnCash = projections.calculations.getCashOnCash(i);

          return {
            value: formatDecimalToPercentage(cashOnCash || 0, 2),
            isNegative: cashOnCash < 0,
          };
        })}
      />
    </>
  );
};
