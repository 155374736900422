import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  SuggestedPartnerType,
  useAnalytics,
  useFeatures,
} from 'lib';
import { MdFormatListBulleted, MdPersonAddAlt, MdPersonOutline } from 'react-icons/md';
import { TbBriefcase2 } from 'react-icons/tb';
import {
  FullScreenBlur, InvertedButton, PartnersIcon, SpinnerWithLogo, Tabs, useEnableScroll, useLabels,
} from 'ui';
import {
  Alert,
  Button,
  Divider,
  Paper,
  Stack, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Agents, AgentsHeader } from './Agents';
import { PMsHeader, PropertyManagers } from './PropertyManagers';
import { partnerStatusState } from './state';
import { useGetGrowthPolicy } from '../../api/growth-policy';
import { useGetSuggestedPartners } from '../../api/suggested-partners';
import { AddPartnerDialog } from '../../components/add-partner/AddPartnerDialog';
import { GrowthPolicyDialog } from '../../templates/growth-policy/GrowthPolicyDialog';
import { growthPolicyDialogState } from '../../templates/growth-policy/state';

const tabValues = ['agents', 'pms'] as const;
type TableValue = typeof tabValues[number];

export const Partners = () => {
  const l = useLabels();
  const theme = useTheme();
  const location = useLocation();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { suggestedPartnerType } = useParams<{ suggestedPartnerType: string }>();
  const [tab, setTab] = useState<TableValue | null>(
    tabValues.includes(suggestedPartnerType as TableValue) ? suggestedPartnerType as TableValue : null,
  );
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [growthPolicyDialogOpen, setGrowthPolicyDialogOpen] = useState(false);
  const {
    data: suggestedPartnerPages,
    isLoading: isLoadingSuggestedPartners,
    isFetchingNextPage: isFetchingNextPageSuggestedPartners,
    hasNextPage: hasNextPageSuggestedPartners,
  } = useGetSuggestedPartners();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const { data: growthPolicy, isLoading: isLoadingGrowthPolicy } = useGetGrowthPolicy();

  const shouldLockCranePMTab = (!growthPolicy && !isLoadingGrowthPolicy)
      || !growthPolicy?.enablePMReferralProgram || !growthPolicy?.pmReferralsFeeAmount;

  const shouldShowBlurAlert = !growthPolicy && !isLoadingGrowthPolicy && !features.isFreemiumPM;

  const navigateToTab = (value: TableValue) => {
    setTab(value);
    navigate(`/pm/grow/opportunities/${value}`);
  };

  const handleClickWizard = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Wizard',
      partnerStatus: partnerStatusState.value,
    });

    setGrowthPolicyDialogOpen(true);
  };

  const handleClickAdd = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Add',
      partnerStatus: partnerStatusState.value,
    });

    setAddDialogOpen(true);
  };

  useEnableScroll(!shouldShowBlurAlert && !growthPolicyDialogState.value.open);

  const agentSuggestedPartners = suggestedPartnerPages?.pages.flatMap((page) => page.partners).filter(
    (p) => p.suggestedPartnerType === SuggestedPartnerType.AGENT,
  ) ?? [];
  const pmSuggestedPartners = suggestedPartnerPages?.pages.flatMap(
    (page) => page.partners,
  ).filter((p) => p.suggestedPartnerType === SuggestedPartnerType.PM) ?? [];

  const isLoading = (
    isLoadingSuggestedPartners || isFetchingNextPageSuggestedPartners || hasNextPageSuggestedPartners || isLoadingGrowthPolicy
    || isLoadingFeatures
  );

  useEffect(() => {
    growthPolicyDialogState.value = {
      open: growthPolicyDialogOpen,
      openDialog: () => setGrowthPolicyDialogOpen(true),
    };

    return () => {
      growthPolicyDialogState.value = {
        open: false,
        openDialog: () => { },
      };
    };
  }, [growthPolicyDialogOpen, setGrowthPolicyDialogOpen]);

  useEffect(() => {
    if (isLoadingFeatures) return;

    if (features.isGrowthAgentPartnersEnabled && !tab) {
      navigateToTab('agents');
      return;
    }

    if (features.isGrowthPmPartnersEnabled && !tab) {
      navigateToTab('pms');
      return;
    }

    if (!features.isGrowthAgentPartnersEnabled && !features.isGrowthPmPartnersEnabled && !tab) {
      navigateToTab('agents');
      return;
    }

    if (tab === 'pms' && !features.isGrowthPmPartnersEnabled) {
      navigateToTab('agents');
    }
  }, [features, isLoadingFeatures, location.pathname, tab]);

  const getPmTab = () => {
    if (features.isGrowthPmPartnersEnabled) {
      if (features.isFreemium) {
        return {
          label: l.dynamic.craneMembers(features),
          value: 'pms',
          icon: <TbBriefcase2 size={16} />,
        };
      }
      return {
        label: l.propertyManagers,
        value: 'pms',
        icon: <TbBriefcase2 size={16} />,
      };
    }
    return null;
  };

  return (
    <>
      <Stack position="relative" p={{ xs: 3, md: 6 }} gap={3} flexGrow={1} height="100%">
        <FullScreenBlur
          shown={shouldShowBlurAlert}
        >
          <Alert
            variant="filled"
            severity="error"
            icon={<PartnersIcon height={18} width={18} />}
            sx={{ color: theme.palette.primary.contrastText, alignItems: 'center' }}
            action={(
              <Button
                variant="outlined"
                size="small"
                onClick={() => growthPolicyDialogState.value.openDialog()}
                sx={{
                  mb: 1,
                  color: 'white',
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'white',
                  },
                }}
              >
                {l.setup}
              </Button>
            )}
          >
            {l['partners.setupGrowthPolicy']}
          </Alert>
        </FullScreenBlur>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3} flexWrap="wrap">
          <Typography variant="h6" className={isLoading ? '' : 'grow-opportunities'}>
            {l.opportunities}
          </Typography>
          <Stack direction="row" alignItems="center" gap={3}>
            <Button variant="outlined" onClick={handleClickWizard} startIcon={<MdFormatListBulleted />}>
              {l['partners.wizard']}
            </Button>
            <InvertedButton
              variant="outlined"
              onClick={handleClickAdd}
              startIcon={<MdPersonAddAlt />}
              aria-hidden={addDialogOpen}
            >
              {l.invite}
            </InvertedButton>
          </Stack>
        </Stack>
        <Paper
          elevation={0}
          sx={isMobile ? {
            border: `1px solid ${theme.palette.divider}`,
          } : {
            border: `1px solid ${theme.palette.divider}`,
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        >
          <Tabs
            isLoading={isLoading}
            tabs={[
              {
                label: l.agents,
                value: 'agents',
                icon: <MdPersonOutline size={16} />,
              },
              getPmTab(),
            ]}
            activeTab={tab}
            setTab={(value) => navigateToTab(value as TableValue)}
          />
          <Divider sx={{ my: 0 }} />
          {tab === 'agents' && <AgentsHeader suggestedPartners={agentSuggestedPartners} />}
          {tab === 'pms' && <PMsHeader suggestedPartners={pmSuggestedPartners} />}
        </Paper>
        {isLoading && (
          <Stack flexGrow={1} justifyContent="center" alignItems="center">
            <SpinnerWithLogo />
          </Stack>
        )}
        {!isLoading && tab === 'agents' && (
          <Agents
            suggestedPartners={agentSuggestedPartners}
            growthPolicy={growthPolicy!}
          />
        )}
        {!isLoading && tab === 'pms' && (
          <PropertyManagers
            suggestedPartners={pmSuggestedPartners}
            growthPolicy={growthPolicy!}
            blur={shouldLockCranePMTab}
          />
        )}
      </Stack>
      <AddPartnerDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        defaultReferralAmount={growthPolicy?.pmReferralsFeeAmount ?? 0}
      />
      <GrowthPolicyDialog open={growthPolicyDialogOpen} onClose={() => setGrowthPolicyDialogOpen(false)} />
    </>
  );
};
