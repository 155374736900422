import {
  useEffect, useMemo, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  APIUser, pmState, useAnalytics, useFeatures, useGetUsers,
} from 'lib';
import { BsSendPlus } from 'react-icons/bs';
import { toast } from 'react-toastify';
import {
  InvertedButton, ShareLinkButton, TableView, useLabels,
} from 'ui';
import {
  Stack,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import {
  ColumnDef,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';

import {
  ImpersonateOwnerButton, InviteUserButton,
} from './buttons';
import { ImportProspectsDialog } from './ImportProspectsDialog';
import { useGetNotificationTemplate } from '../../api/notification-templates';

const sortByActivated = (a: APIUser, b: APIUser) => {
  if (a.isActivated && !b.isActivated) {
    return -1;
  }

  if (!a.isActivated && b.isActivated) {
    return 1;
  }

  return 0;
};

const sortColumnByActivated = (a: Row<APIUser>, b: Row<APIUser>) => sortByActivated(a.original, b.original);

export const Prospects = ({ renderedOnRoot = true }: { renderedOnRoot?: boolean }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [openImportProspectsDialog, setOpenImportProspectsDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data,
    isLoading: isLoadingUsers,
    isError,
  } = useGetUsers(['prospect_user']);

  const isLoading = isLoadingUsers || isLoadingFeatures;

  const {
    data: inviteNotificationTemplate,
  } = useGetNotificationTemplate('invitation_user');

  const getSMSText = (user: APIUser) => {
    if (!inviteNotificationTemplate?.template) return '';

    return inviteNotificationTemplate.template
      .replace('{{ .PMCompanyName }}', pmState.value?.name || '')
      .replace('{{ .Name }}', user.name)
      .replace(
        '{{ .Link }}', '<a href="#">https://api.blankethomes.com/link</a>',
      );
  };

  const columnHelper = createColumnHelper<APIUser>();
  const columns = useMemo(() => {
    const cols: ColumnDef<APIUser, string>[] = [
      columnHelper.accessor('name', {
        header: l['pm-dashboard.owner.name'],
        enableSorting: true,
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('email', {
        header: l['pm-dashboard.owner.email'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('phoneNumber', {
        header: l['pm-dashboard.owner.phone'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
    ];

    cols.push({
      header: 'Activation',
      accessorKey: 'login-as',
      size: 100,
      maxSize: 210,
      enableSorting: true,
      sortingFn: sortColumnByActivated,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Typography variant="body2">{info.row.original.isActivated ? 'Yes' : 'No'}</Typography>
      ),
    });

    cols.push({
      header: ' ',
      accessorKey: 'actions',
      enableSorting: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Stack spacing={2} direction="row">
          {(info.row.original.isActivated || features.isRetainProductEnabled) && (
            <ImpersonateOwnerButton
              user={info.row.original}
            />
          )}
          {!info.row.original.isActivated && (
            <InviteUserButton
              user={info.row.original}
              smsText={getSMSText(info.row.original)}
            />
          )}
        </Stack>
      ),
    });

    return cols;
  }, [isMobile, inviteNotificationTemplate, features]);

  useEffect(() => {
    if (isError) {
      toast.error(l['error.unknownError']);
    }
  }, [isError]);

  const prospects = useMemo(() => {
    if (data && inviteNotificationTemplate) {
      return data?.sort(sortByActivated) as APIUser[];
    }

    return [];
  }, [data, inviteNotificationTemplate]);

  return (
    <>
      <ImportProspectsDialog
        open={openImportProspectsDialog}
        closeDialog={() => setOpenImportProspectsDialog(false)}
      />
      <TableView
        tableHeader={(
          <Stack direction="row" alignItems="center" gap={3}>
            <ShareLinkButton
              size="medium"
              copyURL={`${window.location.origin}/?register=true`}
              buttonText={l['pm-dashboard.prospect.inviteProspect']}
              onCopy={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Freemium Marketplace - Invite Owners',
                });

                toast.success(l['pm-dashboard.prospect.copyLink']);
              }}
            />
            <InvertedButton
              type="button"
              onClick={() => setOpenImportProspectsDialog(true)}
              startIcon={<BsSendPlus />}
            >
              {l['pm-dashboard.prospect.inviteProspects']}
            </InvertedButton>
          </Stack>
        )}
        initSearch={searchParams.get('search') ?? ''}
        title={renderedOnRoot ? l['menu-prospects'] : undefined}
        searchPlaceholder={l['pm-dashboard.prospect.search']}
        data={isLoading ? [] : prospects}
        columns={columns}
        searchTermFilter={(currProperties, search) => currProperties.filter(
          (p) => (p.email + p.name).toLowerCase().includes(search.toLowerCase()),
        )}
      />
    </>

  );
};
