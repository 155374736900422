import { RefObject } from 'react';

import {
  useFetchMetrics, useGetPMPreferences, useGetUserActions, useListOwnerProperties,
} from 'lib';
import { Stack } from '@mui/material';

import { AnnualReportHeaderBannerPdf } from './AnnualReportHeaderBannerPdf';
import { LegislationOverviewPdf } from './LegislationOverviewPdf';
import { MarketAnalysisPdf } from './MarketAnalysisPdf';
import { PortfolioPerformancePdf } from './PortfolioPerformancePdf';
import { PortfolioProjectionsPdf } from './PortfolioProjectionsPdf';
import { LeadershipSummary } from '../LeadershipSummary';
import { LegalDisclaimer } from '../LegalDisclaimer';
import { PdfPage, PdfPageWithLayout } from '../PdfPage';
import { PropertyWithMetrics } from '../PortfolioPerformance';
import { showLegalSummaryState } from '../state';

export const AnnualReportPdf = ({
  owner,
  targetRef,
  areaNameWithFips,
}: {
  owner: string,
  targetRef: RefObject<HTMLDivElement>,
  areaNameWithFips: { areaName: string, fips: string, state: string },
}) => {
  const { data: userActions, isLoading: isLoadingUserActions } = useGetUserActions(owner);
  const { data: pmPreferences, isLoading: pmPreferencesLoading } = useGetPMPreferences();
  const { data: ownerProperties } = useListOwnerProperties(owner);
  const properties = ownerProperties?.properties ?? [];
  const { data: metricsResult } = useFetchMetrics({
    properties: properties.map(({ id }) => id),
    pointInTime: new Date('01/01/2025').getTime() / 1000,
  }, !!properties.length, owner);

  let totalValue = 0;
  let totalEquity = 0;
  let totalAppreciation = 0;
  let totalCashflow = 0;
  const propertiesWithMetrics: PropertyWithMetrics[] = [];

  properties.forEach((property) => {
    if (!metricsResult) return;

    const responseMetrics = metricsResult.metrics[property.id];
    const isValuationAvailable = !!responseMetrics?.valuation;

    if (isValuationAvailable) {
      totalValue += responseMetrics?.valuation || 0;
      totalEquity += responseMetrics?.totalEquity || 0;
      totalAppreciation += responseMetrics?.appreciation || 0;
      totalCashflow += responseMetrics?.cashFlow || 0;
    }

    const isMetricsAvailable = !!responseMetrics
      && property.transactions.filter((t) => t.label !== 'estimated'
        && new Date(t.transactionDate).getTime() > new Date('01/01/2024').getTime()
        && new Date(t.transactionDate).getTime() < new Date('01/01/2025').getTime()).length > 0;

    propertiesWithMetrics.push({
      ...property,
      isMetricsAvailable,
      isValuationAvailable,
      propertyId: property.id,
      fullAddress: property.address.fullAddress,
    });
  });

  const shouldHidePortfolioPerformance = propertiesWithMetrics.filter((p) => p.isMetricsAvailable).length === 0;

  return !isLoadingUserActions && !pmPreferencesLoading && (
    <Stack sx={{
      position: 'fixed',
      left: -99999,
      bottom: -99999,
      zIndex: -99999,
    }}
    >
      <Stack position="relative" ref={targetRef} width="100%">
        <PdfPage>
          <AnnualReportHeaderBannerPdf />
        </PdfPage>
        <PdfPageWithLayout pageNumber={2}>
          <MarketAnalysisPdf fips={areaNameWithFips?.fips || ''} />
        </PdfPageWithLayout>
        {showLegalSummaryState.value && (
          <PdfPageWithLayout pageNumber={3}>
            <LegislationOverviewPdf state={areaNameWithFips?.state || ''} />
          </PdfPageWithLayout>
        )}
        {!shouldHidePortfolioPerformance && (
          <PdfPageWithLayout pageNumber={4}>
            <PortfolioPerformancePdf
              owner={owner}
              properties={propertiesWithMetrics}
              totalValue={totalValue}
              totalEquity={totalEquity}
              totalAppreciation={totalAppreciation}
              totalCashflow={totalCashflow}
            />
            <PortfolioProjectionsPdf
              userActions={userActions}
              pmPreferences={pmPreferences || undefined}
              ownerProperties={ownerProperties?.properties ?? []}
            />
          </PdfPageWithLayout>
        )}
        <PdfPageWithLayout pageNumber={shouldHidePortfolioPerformance ? 4 : 5} constraintHeight={false}>
          <LeadershipSummary isPdf />
          <LegalDisclaimer />
        </PdfPageWithLayout>
      </Stack>
    </Stack>
  );
};
