import { calculateNOI } from 'lib';
import {
  BoldTypography,
  formatNumberToCurrency, InfoTooltip, useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';

import { CalculatedRow, DetailRow } from './Row';
import { useProjections } from './useProjections';
import { RowProps } from '../types';

export const OperatingIncome = ({ form }: RowProps) => {
  const l = useLabels();
  const projections = useProjections(form);
  const year1NOI = calculateNOI(projections.expectedRent.year1, projections.totalExpenses.year1);
  const year2NOI = calculateNOI(projections.expectedRent.year2, projections.totalExpenses.year2);
  const year3NOI = calculateNOI(projections.expectedRent.year3, projections.totalExpenses.year3);

  return (
    <>
      <DetailRow
        form={form}
        rowID="operating-income"
        bottomBorder={false}
        columns={[
          {
            id: 'operating-income',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.operatingIncome}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.netOperatingIncome']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          {}, {}, {},
        ]}
      />
      <CalculatedRow
        form={form}
        label={l.noi}
        year1={{
          value: formatNumberToCurrency(year1NOI || 0, 0),
          isNegative: year1NOI < 0,
        }}
        year2={{
          value: formatNumberToCurrency(year2NOI || 0, 0),
          isNegative: year2NOI < 0,
        }}
        year3={{
          value: formatNumberToCurrency(year3NOI || 0, 0),
          isNegative: year3NOI < 0,
        }}
      />
    </>
  );
};
