export enum QueryKey {
  PROPERTY_VALUATIONS = 'pm-dashboard_property_valuations',
  PM_PROPERTIES_DASHBOARD_URL = 'pm_properties_dashboard_url',
  MARKETPLACE_PROPERTIES = 'pm-dashboard_marketplaceProperties',
  NOTIFICATION_TEMPLATE = 'pm-dashboard_notificationTemplate',
  OWNER_LEADS = 'pm-dashboard_owner_leads',
  SELLER_LEADS = 'pm-dashboard_seller_leads',
  BUYER_LEADS = 'pm-dashboard_buyer_leads',
  BUY_BOX_LEADS = 'pm-dashboard_buy_box_leads',
  PROSPECT_LEADS = 'pm-dashboard_prospect_leads',
  COUNT_OWNER_LEADS = 'pm-dashboard_count_owner_leads',
  COUNT_SELLER_LEADS = 'pm-dashboard_count_seller_leads',
  COUNT_BUYER_LEADS = 'pm-dashboard_count_buyer_leads',
  COUNT_BUY_BOX_LEADS = 'pm-dashboard_count_buy_box_leads',
  COUNT_PROSPECT_LEADS = 'pm-dashboard_count_prospect_leads',
  PM_DASHBOARD_PROPERTIES = 'pm-dashboard_property_ids',
  OWNER_PROPERTY_DATA = 'owner_property_data',
  OWNER_RISK = 'owner_risk',
  OWNER_RISKS_BY_PM = 'owner_risks_by_pm',
  RETENTION_POLICY = 'retention_policy',
  GROWTH_POLICY = 'growth_policy',
  SUGGESTED_PARTNERS = 'suggested_partners',
  SUGGESTED_PARTNER_COUNT = 'suggested_partner_count',
  PARTNERS = 'partners',
  PM_METRICS = 'pm_metrics',
  OWNER_RETENTION_DETAILS = 'owner_retention_details',
}
