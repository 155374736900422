import { ReactNode } from 'react';

import { Stack, SxProps, useTheme } from '@mui/material';

export const CircledIcon = ({
  icon = undefined,
  color = undefined,
  size = 18,
  sx = undefined,
}: {
  icon?: ReactNode,
  color?: string,
  size?: number,
  sx?: SxProps,
}) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      width={size}
      height={size}
      bgcolor={color ?? theme.palette.grey.A100}
      sx={sx}
    >
      {icon}
    </Stack>
  );
};
