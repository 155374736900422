import {
  MouseEvent, ReactNode, useState,
} from 'react';

import dayjs from 'dayjs';
import {
  getOwnerFromID,
  useAnalytics, useImpersonate,
} from 'lib';
import {
  MdInfoOutline,
  MdKeyboardArrowDown, MdOpenInNew,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography,
  SemiBoldTypography, Spinner, useLabels,
} from 'ui';
import {
  Button, Collapse, IconButton,
  Paper,
  Stack, useTheme,
} from '@mui/material';

import { insightDrawerState } from './utils';

export const GoToTransactionsButton = ({
  onClick,
  isLoadingImpersonate,
}: { onClick: (e: MouseEvent) => void, isLoadingImpersonate: boolean }) => {
  const l = useLabels();
  const theme = useTheme();

  return (
    <Stack>
      <Button
        variant="text"
        onClick={onClick}
        disabled={isLoadingImpersonate}
        sx={{
          width: '100%',
          textTransform: 'none',
          color: theme.palette.primary.main,
          padding: 3,
          paddingTop: 4.5,
          paddingBottom: 4.5,
          justifyContent: 'left',
        }}
      >
        { isLoadingImpersonate
          ? (
            <>
              &nbsp;
              <Spinner size={16} />
            </>
          ) : (
            <Stack direction="row" justifyContent="left" alignItems="center">
              <MdOpenInNew size={20} />
              <BoldTypography
                variant="caption"
                sx={{
                  fontSize: 12,
                  color: theme.palette.primary.main,
                  paddingLeft: theme.spacing(2),
                }}
              >
                {l.viewTransactions}
              </BoldTypography>
            </Stack>
          )}

      </Button>
    </Stack>

  );
};

export const InsightDisclaimer = ({
  description,
  title = undefined,
  showNavigateToTransactions = false,
  propertyId = undefined,
  category = undefined,
}: { title?: string, description: ReactNode, showNavigateToTransactions?: boolean, propertyId?: string, category?: string, }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [expanded, setExpanded] = useState(false);
  const { mutateAsync: impersonate, isLoading: isLoadingImpersonate } = useImpersonate();

  const userID = insightDrawerState.value?.ownerRisk?.ownerID;

  const { email } = getOwnerFromID(userID || '');

  const getImpersonateUrl = (token: string): string => {
    let resultUrl = `/transactions?&impersonation-token=${token}`;

    if (propertyId) {
      resultUrl += `&propertyid=${propertyId}`;
    }

    if (category) {
      resultUrl += `&category=${category}`;
    }

    const lastYearDate = dayjs().subtract(1, 'year').endOf('day');
    resultUrl += `&fromDate=${lastYearDate.toISOString()}`;

    return resultUrl;
  };

  const handleViewClick = async () => {
    analytics.track('User Impersonated', {
      userEmail: email || '',
      actionName: 'View transactions from insight',
    });

    try {
      const impersonationToken = await impersonate(email || '');
      window.open(getImpersonateUrl(impersonationToken.data.token), '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const toggleExpanded = (e: MouseEvent) => {
    e.stopPropagation();

    setExpanded((prev) => !prev);
  };

  return (
    <>
      {showNavigateToTransactions && (
        <Paper elevation={0} sx={{ background: '#EBEDF5' }}>

          <GoToTransactionsButton
            onClick={async (e) => {
              e.stopPropagation();
              await handleViewClick();
            }}
            isLoadingImpersonate={isLoadingImpersonate}
          />

        </Paper>
      )}
      <Paper elevation={0} sx={{ background: '#EBEDF5' }}>
        <Stack p={3} justifyContent="space-between">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={toggleExpanded}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Stack direction="row" alignItems="center" gap={1.5}>
                <MdInfoOutline size={20} />
                <BoldTypography variant="caption" sx={{ fontSize: 12 }}>
                  {title ?? l['retention.tooltip.howCalculated']}
                </BoldTypography>
              </Stack>
            </Stack>
            <IconButton size="small" onClick={toggleExpanded}>
              <MdKeyboardArrowDown
                style={{
                  fill: theme.palette.primary.main,
                  transform: expanded ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </IconButton>
          </Stack>
          <Collapse in={expanded}>
            <Stack pl={8}>
              {typeof description === 'string' ? (
                <SemiBoldTypography variant="caption" sx={{ fontSize: 12 }}>
                  {description}
                </SemiBoldTypography>
              ) : (
                description
              )}
            </Stack>
          </Collapse>
        </Stack>
      </Paper>
    </>

  );
};
