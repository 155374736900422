import {
  AppDashboardProperty,
} from 'lib';
import CountUp from 'react-countup';
import {
  Card, CardContent, Grid,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { MissingDataButton } from './MissingDataButton';
import {
  CheckCircleNoBG, DollarCircleNoBG, DollarRectNoBG, GraphCircle, PinkDotIcon, TrendingUpNoBG,
} from '../../icons';
import { useLabels } from '../../lib/translations';
import { BoldTypography } from '../../typography/BoldTypography';

export type PortfolioPerformanceProps = {
  properties: PropertyWithMetrics[],
  owner: string,
  totalValue: number,
  totalAppreciation: number,
  totalEquity: number,
  totalCashflow: number,
  inPreview?: boolean,
};

export type PropertyWithMetrics = AppDashboardProperty & {
  isMetricsAvailable: boolean,
  isValuationAvailable: boolean,
  propertyId: string,
  fullAddress: string,
};

export const PortfolioPerformance = ({
  properties,
  owner,
  totalValue,
  totalAppreciation,
  totalEquity,
  totalCashflow,
  inPreview = false,

}: PortfolioPerformanceProps) => {
  const l = useLabels();

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" gap={2} pb={4} justifyContent="space-between" flexWrap="wrap">
        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
          <GraphCircle className="graph-circle-icon" />
          <BoldTypography variant="body1">{`${l['annualReport.portfolioPerformance.title']} `}</BoldTypography>
        </Stack>
        <MissingDataButton owner={owner} inPreview={inPreview} />
      </Stack>
      <Grid container spacing={1}>
        <Grid item md={3} sm={6} xs={12}>
          <Card
            elevation={0}
            sx={{
              height: '100%',
              backgroundColor: 'rgba(247, 247, 250, 1)',
            }}
          >
            <CardContent>
              <Stack gap={4}>
                <BoldTypography variant="h6">
                  {l.dollarCurrency}
                  <CountUp end={totalValue} start={0} duration={5} />
                </BoldTypography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2">{l['annualReport.portfolioPerformance.totalValue']}</Typography>
                  <CheckCircleNoBG className="portfolio-performance-icon" />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Card
            sx={{
              height: '100%',
              backgroundColor: 'rgba(247, 247, 250, 1)',
            }}
            elevation={0}
          >
            <CardContent>
              <Stack gap={4}>
                <BoldTypography variant="h6">
                  {l.dollarCurrency}
                  <CountUp end={totalAppreciation} start={0} duration={5} />
                </BoldTypography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2">{l['annualReport.portfolioPerformance.totalAppreciation']}</Typography>
                  <TrendingUpNoBG className="portfolio-performance-icon" />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Card
            sx={{
              height: '100%',
              backgroundColor: 'rgba(247, 247, 250, 1)',
            }}
            elevation={0}
          >
            <CardContent>
              <Stack gap={4}>
                <BoldTypography variant="h6">
                  {l.dollarCurrency}
                  <CountUp end={totalEquity} start={0} duration={5} />
                </BoldTypography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2">{l['annualReport.portfolioPerformance.totalEquity']}</Typography>
                  <DollarRectNoBG className="portfolio-performance-icon" />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Card
            elevation={0}
            sx={{
              height: '100%',
              backgroundColor: 'rgba(247, 247, 250, 1)',
            }}
          >
            <CardContent>
              <Stack gap={4}>
                <BoldTypography variant="h6">
                  {l.dollarCurrency}
                  <CountUp end={totalCashflow} start={0} duration={5} />
                </BoldTypography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2">{l['annualReport.portfolioPerformance.totalCashflow']}</Typography>
                  <DollarCircleNoBG className="portfolio-performance-icon" />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'rgba(247, 247, 250, 1)',
        }}
      >
        <CardContent>
          <Stack gap={4}>
            <BoldTypography variant="h6">{l.properties}</BoldTypography>
            <Stack justifyContent="space-between">
              {properties.map((property) => {
                const propertyAddressText = property.isValuationAvailable ? property.fullAddress
                  : `${property.fullAddress} (${l['annualReport.portfolioPerformance.valuationUnavailable']})`;

                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={3}
                    key={property.id}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.open(`/properties/${property.id}`, '_blank');
                    }}
                  >
                    <PinkDotIcon className="pink-dot-icon" />
                    <Typography
                      variant="body2"
                      sx={{
                        textDecoration: property.isMetricsAvailable ? 'none' : 'line-through',
                      }}
                    >
                      {propertyAddressText}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
