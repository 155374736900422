/* eslint-disable max-len */
export const TrendingUpDollarIcon = ({ size = 24 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 6L16.29 8.29L11.41 13.17L7.41 9.17L0 16.59L1.41 18L7.41 12L11.41 16L17.71 9.71L20 12V6H14Z"
      fill="currentColor"
    />
    <path
      d="M17.2588 17.2667C15.7455 16.8733 15.2588 16.4667 15.2588 15.8333C15.2588 15.1067 15.9321 14.6 17.0588 14.6C18.2455 14.6 18.6855 15.1667 18.7255 16H20.1988C20.1521 14.8533 19.4521 13.8 18.0588 13.46V12H16.0588V13.44C14.7655 13.72 13.7255 14.56 13.7255 15.8467C13.7255 17.3867 14.9988 18.1533 16.8588 18.6C18.5255 19 18.8588 19.5867 18.8588 20.2067C18.8588 20.6667 18.5321 21.4 17.0588 21.4C15.6855 21.4 15.1455 20.7867 15.0721 20H13.6055C13.6855 21.46 14.7788 22.28 16.0588 22.5533V24H18.0588V22.5667C19.3588 22.32 20.3921 21.5667 20.3921 20.2C20.3921 18.3067 18.7721 17.66 17.2588 17.2667Z"
      fill="currentColor"
    />
  </svg>
);
