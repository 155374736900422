import { useEffect } from 'react';

export const useTrackPageBottom = (onBottom: () => void, element?: HTMLElement) => {
  const el = element ?? document.documentElement;

  const handleScroll = () => {
    const scrollTop = (el && el.scrollTop) || document.body.scrollTop;
    const scrollHeight = (el && el.scrollHeight) || document.body.scrollHeight;
    const clientHeight = el.clientHeight || window.innerHeight;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      onBottom();
    }
  };

  useEffect(() => {
    if (el) {
      el.addEventListener('scroll', handleScroll);
      return () => el.removeEventListener('scroll', handleScroll);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [el, handleScroll]);
};
