import { ReactNode, useMemo, useState } from 'react';

import {
  AppDashboardProperty,
  calculateCashInvested,
  calculateTotalClosingCost,
  calculateTotalHomeImprovements,
  calculateTotalMortgageAmount,
  calculateTotalPurchase,
  getLastValuationDate,
  getValuation,
  useAnalytics,
  useMissingData,
} from 'lib';
import {
  MdArrowDropDown, MdArrowDropUp,
} from 'react-icons/md';
import {
  ArrowToggleOpenUpDown,
  formatDecimalToPercentage,
  formatNumberToCurrency,
  InfoTooltip,
  MissingData,
  MissingDataIcon,
  SemiBoldTypography,
  useLabels,
} from 'ui';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { TooltipContent } from './TooltipContent';
import { UpdateProperties } from '../../components/update-properties';

type DataPoint = {
  color?: string;
  stats: ReactNode;
  title: string;
  breakdown?: {
    label: string;
    value: number;
  }[];
};

type Summary = {
  totalValue: number;
  totalPurchasePrice: number;
  totalDownPayment: number;
  totalClosingCosts: number;
  totalHomeImprovements: number;
};

export const PortfolioValue = ({ properties }: { properties: AppDashboardProperty[] }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();
  const [missingDataPopupOpen, setMissingDataPopupOpen] = useState(false);
  const singleProperty = properties.length === 1;

  const tooltipContent = [
    {
      bold: l['tooltip.estimated_value.title'],
      label: l['tooltip.estimated_value.desc'],
    },
    {
      bold: l['tooltip.purchase_price.title'],
      label: l['tooltip.purchase_price.desc'],
    },
    {
      bold: l['tooltip.cash_invested.title'],
      label: l['tooltip.cash_invested.desc'],
    },
  ];

  const allHaveValuation = properties.every((property) => !!property.metrics?.valuation);

  const metrics = properties.map((property) => property.metrics);
  const lastValuationDate = getLastValuationDate(properties);

  const summary = useMemo<Summary>(() => {
    const values = getValuation(metrics);
    const purchasePrices = calculateTotalPurchase(properties);
    const mortgages = calculateTotalMortgageAmount(properties);
    const totalClosingCosts = calculateTotalClosingCost(properties);
    const totalHomeImprovements = calculateTotalHomeImprovements(properties);
    return {
      totalValue: values,
      totalPurchasePrice: purchasePrices,
      totalDownPayment: calculateCashInvested(purchasePrices, mortgages),
      totalClosingCosts,
      totalHomeImprovements,
    };
  }, [properties, metrics]);
  const { missingData } = useMissingData(properties);
  const allHouseCanary = properties.every((p) => p.latestValuation?.provider === 'housecanary');

  const appreciation = summary.totalValue && summary.totalPurchasePrice ? summary.totalValue - summary.totalPurchasePrice : 0;
  const gain = summary.totalPurchasePrice ? appreciation / summary.totalPurchasePrice : 0;
  const [showDetails, setShowDetails] = useState(false);

  const data: DataPoint[] = [
    {
      color: missingData.purchasePrice.isMissing ? 'secondary' : undefined,
      stats: (
        <>
          {summary.totalPurchasePrice ? formatNumberToCurrency(summary.totalPurchasePrice, 0) : null}
          {missingData.purchasePrice.isMissing && <MissingDataIcon />}
        </>
      ),
      title: singleProperty ? l.purchasePrice : l.totalPurchasePrice,
    },
    {
      color: missingData.cashInvested.isMissing ? 'secondary' : undefined,
      stats: (
        <>
          {summary.totalDownPayment + summary.totalClosingCosts + summary.totalHomeImprovements
            ? formatNumberToCurrency(summary.totalDownPayment + summary.totalClosingCosts + summary.totalHomeImprovements, 0)
            : null}
          {missingData.cashInvested.isMissing && <MissingDataIcon />}
        </>
      ),
      title: l.cashInvested,
      breakdown: [{
        label: l.downPayment,
        value: summary.totalDownPayment,
      }, {
        label: l.closingCosts,
        value: summary.totalClosingCosts,
      }, {
        label: l.homeImprovements,
        value: summary.totalHomeImprovements,
      }],
    },
  ];

  const renderDataPoints = (dataPoints: DataPoint[]) => dataPoints.map((point: DataPoint, i: number, array: DataPoint[]) => (
    <Stack
      key={point.title}
      direction="column"
      alignItems="flex-start"
      justifyContent="space-between"
      pb={i !== array.length - 1 ? 4 : 0}
      pt={3}
      borderBottom={i !== array.length - 1 ? '1px solid rgba(23, 55, 113, 0.12)' : ''}
      {...(point.breakdown ? { onClick: () => setShowDetails(!showDetails), sx: { cursor: 'pointer' } } : {})}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Stack direction="row" alignItems="center" spacing={1}>
          <SemiBoldTypography variant="body2">{point.title}</SemiBoldTypography>
          {point.breakdown && (
            <ArrowToggleOpenUpDown fontSize="20px" className={showDetails ? 'open' : ''} />
          )}
        </Stack>
        <Box ml="auto">
          <Typography variant="body2bold" color={point.color} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {point.stats}
          </Typography>
        </Box>
      </Stack>
      {point.breakdown && (
        <Collapse in={showDetails} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
          <Stack direction="column" mt={2}>
            {point.breakdown.map((item) => (
              <Stack direction="row" justifyContent="space-between" key={item.label}>
                <Typography variant="body3">
                  {item.label}
                </Typography>
                <Box sx={{ textAlign: 'right', flex: 1 }}>
                  <Typography variant="body3">{formatNumberToCurrency(item.value, 0)}</Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  ));

  const renderGain = () => {
    if (!gain || !allHaveValuation || !summary.totalPurchasePrice || !summary.totalValue) {
      return null;
    }

    return (
      <>
        {gain > 0 ? <MdArrowDropUp size={24} color={theme.palette.success.main} />
          : <MdArrowDropDown color={theme.palette.error.main} size={24} />}
        <Stack direction="row" gap={1}>
          <Typography variant="body2bold" sx={{ color: gain > 0 ? 'success.main' : 'error.main' }}>
            {appreciation ? formatNumberToCurrency(appreciation, 1, {
              notation: 'compact',
              compactDisplay: 'short',
            }) : l.na}
          </Typography>
          <Typography variant="body2bold" sx={{ color: gain > 0 ? 'success.main' : 'error.main' }}>
            (
            {formatDecimalToPercentage(gain, 1)}
            )
          </Typography>
        </Stack>
      </>
    );
  };

  return (
    <Card
      component={Stack}
      sx={{
        height: '100%',
      }}
    >
      <Box>
        <CardHeader
          sx={{ p: 4, pb: 2 }}
          titleTypographyProps={{ variant: 'h6' }}
          title={singleProperty ? 'Est. Property Value' : 'Est. Portfolio Value'}
          action={(
            <InfoTooltip
              arrow
              isLight
              title={<TooltipContent bullets={tooltipContent} />}
              isOutlined
              track={(value) => {
                analytics.track('Tooltip Toggled', {
                  value,
                  tooltipName: 'Portfolio Value Tooltip',
                });
              }}
            />
          )}
        />
        <CardContent sx={{ paddingBottom: '10px !important' }}>
          <Stack>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
              <Typography
                variant="h6"
                sx={{ mr: 1.5, fontWeight: 700 }}
                data-testid="estimate-portfolio-value"
              >
                {allHaveValuation ? formatNumberToCurrency(summary.totalValue, 0) : l.na}
              </Typography>
              {renderGain()}
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
              <Typography
                variant="body2"
                sx={{ mr: 1.5, fontWeight: 700 }}
                data-testid="estimate-portfolio-last-valuation-date"
              >
                {l.lastUpdate}
                {' '}
                {(allHaveValuation && lastValuationDate !== null) ? lastValuationDate.format('MMM, YYYY') : l.na}
                {' '}
                {(allHaveValuation && lastValuationDate !== null && allHouseCanary && l.by)}
              </Typography>
              {allHouseCanary && (
                <Box
                  component="img"
                  src="https://assets.blankethomes.com/gi/housecanary.svg"
                  sx={{
                    width: '100px',
                  }}
                />
              )}
            </Stack>
            <Stack
              mt={2}
              pb={1}
              sx={{
                paddingTop: '9px',
                paddingBottom: 1,
              }}
            >
              {renderDataPoints(data)}
            </Stack>
          </Stack>
        </CardContent>
      </Box>
      {(missingData.purchasePrice.isMissing || missingData.cashInvested.isMissing) && (
        <>
          <Box marginTop="auto">
            <MissingData
              onAdd={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Missing Data CTA',
                  widgetName: 'Portfolio Value',
                });

                setMissingDataPopupOpen(true);
              }}
              containerSx={{ mt: 0 }}
            />
          </Box>
          <UpdateProperties
            open={missingDataPopupOpen}
            onClose={() => setMissingDataPopupOpen(false)}
            properties={properties}
            propertyID={missingData.purchasePrice.propertyIDs[0] ?? missingData.cashInvested.propertyIDs[0]}
            showPurchase
            showMortgage
          />
        </>
      )}
    </Card>
  );
};
