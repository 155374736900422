import { useEffect, useState } from 'react';

import {
  AppDashboardProperty,
  calculateTotalMortgageAmount,
  calculateTotalMortgagePaydown,
  calculateTotalPrincipal,
  calculateTotalPurchase,
  calculateTotalValuation,
  useAnalytics, useAuth, useFeatures, useGetUserActions,
  useListPropertyIDs, useListSellableProperties, useMissingData, useMutateUserActions,
} from 'lib';
import { GiReceiveMoney } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';
import {
  Avatar, BoldTypography, CheckCircle, DarkTooltip, formatDecimalToPercentage,
  formatNumberToCurrency, GraphTrendUpHeadless, InfoTooltip,
  MissingData, MissingDataIcon, SemiBoldTypography, Spinner, useLabels,
} from 'ui';
import {
  Box,
  Button,
  Card, CardContent, Grid, IconButton,
  LinearProgress, Stack, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { DialogCashOut } from './DialogCashOut';
import { StyledGrid } from './styled';
import { TooltipContent } from './TooltipContent';
import { InsightTypography } from '../../components/typography/InsightTypography';
import { UpdateProperties } from '../../components/update-properties';

export const PortfolioTotalEquity = ({ properties }: { properties: AppDashboardProperty[] }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();
  const [missingDataPopupOpen, setMissingDataPopupOpen] = useState(false);
  const { missingData } = useMissingData(properties);
  const purchasePrices = calculateTotalPurchase(properties);
  const totalValue = calculateTotalValuation(properties);
  const someHaveValuation = properties.some((property) => !!property.latestValuation?.priceMean);
  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));
  const [cashOutDialogOpen, setCashOutDialogOpen] = useState<boolean>(false);
  const [cashTooltipOpen, setCashTooltipOpen] = useState(false);
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const { user } = useAuth();

  const {
    data: userActionsData,
  } = useGetUserActions(user?.id ?? '');

  const { mutateAsync: updateUserActions } = useMutateUserActions();

  useEffect(() => {
    if (userActionsData?.viewedCashOutTooltip === false) {
      setCashTooltipOpen(true);
      updateUserActions({ viewedCashOutTooltip: true });
    }
  }, [userActionsData]);

  const {
    data: sellableProperties,
    isLoading: isSellablePropertiesLoading,
  } = useListSellableProperties();
  const { data: propertyIDs, isLoading: propertyIDsLoading } = useListPropertyIDs();

  const isLoading = isLoadingFeatures
    || !sellableProperties || isSellablePropertiesLoading || !propertyIDs || propertyIDsLoading;

  const summary = {
    appreciation: totalValue - purchasePrices,
    purchasePrice: purchasePrices,
    mortgageAmount: calculateTotalMortgageAmount(properties),
    principalPaydown: calculateTotalMortgagePaydown(properties),
    totalPrincipal: calculateTotalPrincipal(properties),
  };

  const totalEquity = properties.map((p) => p.metrics?.totalEquity ?? 0).reduce((acc, num) => acc + num, 0);

  const tooltipContent = [
    {
      bold: l['tooltip.total_equity.title'],
      label: l['tooltip.total_equity.desc'],
    },
    {
      bold: l['tooltip.principal_paid.title'],
      label: l['tooltip.principal_paid.desc'],
    },
    {
      bold: l['tooltip.appreciation.title'],
      label: l['tooltip.appreciation.desc'],
    },
    {
      bold: l['tooltip.progressbar.title'],
      label: l['tooltip.progressbar.desc'],
    },
  ];

  return (
    <>
      <DialogCashOut
        propertyIDs={propertyIDs ?? []}
        sellableProperties={sellableProperties ?? []}
        closeDialog={() => setCashOutDialogOpen(false)}
        open={cashOutDialogOpen}
      />
      <Card
        component={Stack}
        sx={{
          height: '100%',
        }}
      >
        {isLoading
          ? <Stack height="100%" width="100%" position="relative"><Spinner size={48} /></Stack> : (
            <Box>
              <Stack
                sx={{ p: 4, pb: 2 }}
              >
                <Stack direction="row" justifyContent="space-between">

                  <Stack>
                    <BoldTypography variant="h6">
                      {l.totalEquity}
                    </BoldTypography>
                    <BoldTypography
                      pt={6}
                      variant="h6"
                      color={missingData.principalPaydown.isMissing ? 'secondary' : undefined}
                      sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                    >
                      {someHaveValuation ? formatNumberToCurrency(totalEquity, 0) : l.na}
                      {missingData.principalPaydown.isMissing ? <MissingDataIcon /> : null}
                    </BoldTypography>
                  </Stack>
                  <Stack>
                    {features.isMarketplaceEnabled ? (
                      <Stack
                        height="100%"
                        sx={{
                          padding: '2px 8px 6px 8px',
                        }}
                        spacing={2}
                      >
                        <Stack
                          alignItems="flex-end"
                          pb={4}
                        >
                          <InfoTooltip
                            arrow
                            isLight
                            title={<TooltipContent bullets={tooltipContent} />}
                            isOutlined
                            track={(value) => {
                              analytics.track('Tooltip Toggled', {
                                value,
                                tooltipName: 'Total Equity Tooltip',
                              });
                            }}
                          />
                        </Stack>
                        <Stack>
                          <DarkTooltip
                            className="align-right"
                            open={cashTooltipOpen}
                            title={(
                              <Stack direction="row">
                                <Stack>
                                  <Typography color={theme.palette.common.white}>
                                    {l['tooltip.cash_invested.tooltip1']}
                                  </Typography>
                                  <Typography color={theme.palette.common.white}>
                                    {l['tooltip.cash_invested.tooltip2']}
                                  </Typography>
                                </Stack>
                                <Stack>
                                  <IconButton onClick={() => { setCashTooltipOpen(false); }}>
                                    <MdClose fontSize={16} color={theme.palette.common.white} />
                                  </IconButton>
                                </Stack>
                              </Stack>
                            )}
                            placement="bottom"
                            arrow
                          >
                            <Button
                              startIcon={<GiReceiveMoney size={18} />}
                              variant="contained"
                              type="button"
                              data-testid="save-and-exit-button"
                              onClick={() => {
                                analytics.track('Button Clicked', {
                                  buttonName: 'cash out',
                                });
                                setCashOutDialogOpen(true);
                              }}
                            >
                              <Box pl={2}>
                                {l['tooltip.cash_invested.cashOut']}
                              </Box>
                            </Button>
                          </DarkTooltip>
                        </Stack>
                      </Stack>
                    ) : (
                      <InfoTooltip
                        arrow
                        isLight
                        title={<TooltipContent bullets={tooltipContent} />}
                        isOutlined
                        track={(value) => {
                          analytics.track('Tooltip Toggled', {
                            value,
                            tooltipName: 'Total Equity Tooltip',
                          });
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <CardContent sx={{ pt: '20px !important', pb: '8px !important' }}>
                <Stack spacing={7}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      pb: 3,
                    }}
                  />
                  <Grid
                    container
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      pb: 3,
                      marginTop: '12px !important',
                      flexDirection: isDesktop ? 'row' : 'column',
                      gap: isDesktop ? 0 : 2,
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        borderRight: isDesktop ? `1px solid ${theme.palette.divider}` : 'none',
                        width: isDesktop ? '50%' : '100% !important',
                        maxWidth: isDesktop ? '50%' : '100% !important',
                      }}
                    >
                      <Avatar
                        skin="light"
                        sx={{
                          mr: 2, height: 32, width: 32, borderRadius: '4px',
                        }}
                        color="primary"
                        variant="rounded"
                      >
                        <CheckCircle />
                      </Avatar>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          flexGrow: isDesktop ? 0 : 1,
                        }}
                      >
                        <Typography sx={{ mb: 0.5, mr: 2 }} variant="body2">
                          {l.principalPaid}
                        </Typography>
                        <SemiBoldTypography
                          color={missingData.principalPaid.isMissing ? 'secondary' : undefined}
                          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                        >
                          {summary.principalPaydown ? formatNumberToCurrency(summary.principalPaydown, 0) : ''}
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {missingData.principalPaid.isMissing ? <MissingDataIcon /> : summary.principalPaydown ? null : l.na}
                        </SemiBoldTypography>
                      </Stack>
                    </Grid>
                    <StyledGrid item xs={6}>
                      <Avatar
                        skin="light"
                        sx={{
                          ml: isDesktop ? 3 : 0,
                          mr: 2,
                          height: 32,
                          width: 32,
                          borderRadius: '4px',

                        }}
                        variant="rounded"
                        color="success"
                      >
                        <GraphTrendUpHeadless />
                      </Avatar>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          flexGrow: isDesktop ? 0 : 1,
                        }}
                      >
                        <Typography sx={{ mb: 0.5, mr: 2 }} variant="body2">
                          {l.appreciation}
                        </Typography>
                        <SemiBoldTypography
                          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                          color={missingData.appreciation.isMissing ? 'secondary' : undefined}
                        >
                          {!missingData.purchasePrice.isMissing && someHaveValuation && summary.appreciation
                            ? formatNumberToCurrency(summary.appreciation, 0)
                            : l.na}
                          {missingData.appreciation.isMissing ? <MissingDataIcon /> : null}
                        </SemiBoldTypography>
                      </Stack>
                    </StyledGrid>
                  </Grid>
                  {/* must use !important, because overwise its not override the basic style */}
                  <Stack sx={{ marginTop: '4px !important' }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mb={1}
                    >
                      <BoldTypography variant="body1">{l.principalPaydown}</BoldTypography>
                      <BoldTypography variant="body1" color={missingData.principalPaydown.isMissing ? 'secondary' : undefined}>
                        {missingData.principalPaydown.isMissing
                          ? <MissingDataIcon />
                          : formatDecimalToPercentage(
                            summary.mortgageAmount ? summary.principalPaydown / summary.mortgageAmount : 0,
                            2,
                          )}
                      </BoldTypography>
                    </Stack>
                    <LinearProgress
                      value={summary.mortgageAmount ? 100 * (summary.principalPaydown / summary.mortgageAmount) : 0}
                      color="success"
                      variant="determinate"
                    />
                  </Stack>
                  {!missingData.principalPaid && !missingData.principalPaydown && (
                    <InsightTypography>
                      {l.yourLoanBalance}
                      {' '}
                      <Typography variant="body1" component="span" color="info.main">
                        {formatNumberToCurrency(summary.totalPrincipal, 1)}
                      </Typography>
                    </InsightTypography>
                  )}
                </Stack>
              </CardContent>
            </Box>
          )}
        {(missingData.principalPaid.isMissing || missingData.appreciation.isMissing) && (
          <>
            <Box marginTop="auto">
              <MissingData
                onAdd={() => {
                  analytics.track('Button Clicked', {
                    buttonName: 'Missing Data CTA',
                    widgetName: 'Total Equity',
                  });

                  setMissingDataPopupOpen(true);
                }}
                containerSx={{ mt: 0 }}
              />
            </Box>
            <UpdateProperties
              open={missingDataPopupOpen}
              onClose={() => setMissingDataPopupOpen(false)}
              properties={properties}
              propertyID={missingData.principalPaid.propertyIDs[0] ?? missingData.appreciation.propertyIDs[0]}
              showPurchase
              showMortgage
            />
          </>
        )}
      </Card>
    </>

  );
};
