import dayjs from 'dayjs';

import { AppDashboardProperty } from './types/property';
import { createDisplayAddress } from '../../lib/address';
import { convertArrayToFirstElement } from '../../lib/convert';
import { filterNulls } from '../../lib/filter';
import { sortByDesc } from '../../lib/sort';
import {
  Category, GroupedTransaction, Lease, Mortgage, MortgageType, Transaction, TransactionType,
  Unit,
} from '../../types/property';
import {
  Category as APICategory,
  DashboardProperty as APIProperty, DashboardPropertyMetric,
  DashboardPropertyPurchaseHistory,
  DashboardPropertyValuation,
  Lease as APILease,
  Mortgage as APIMortgage,
  MortgageType as APIMortgageType,
  PropertyAmortizationTable, PropertyExtendedAddress,
  Transaction as APITransaction,
  Unit as APIUnit,
} from '../API';

export const transactionDateFormat = 'YYYY-MM-DD';

export const createGroupedTransactions = (transactions: Transaction[], propertyId: string): GroupedTransaction[] => {
  const groupTransactionMap = new Map<string, GroupedTransaction>();

  transactions.forEach((item) => {
    const transactionDate = dayjs(item.transactionDate, transactionDateFormat);

    const month = (transactionDate.month() + 1).toString().padStart(2, '0');

    const key = `${propertyId}::${item.label}::${item.category}::${item.description}::${transactionDate.year()}::${month}`;
    const itemGroup = groupTransactionMap.get(key);

    if (itemGroup) {
      itemGroup.amount += item.amount;
      itemGroup.transactions.push(item);
    } else {
      groupTransactionMap.set(key, {
        transactionMonth: `${transactionDate.year()}-${month}`,
        amount: item.amount,
        category: item.category,
        label: item.label,
        transactions: [item],
        id: key,
        description: item.description ?? '',
        isEditable: item.isEditable ?? false,
      });
    }
  });

  return Array.from(groupTransactionMap.values());
};

export const convertCategory = (category: APICategory, transactionType: TransactionType): Category => {
  if (category === APICategory.other && transactionType === TransactionType.EXPENSE) {
    return Category.OTHER_EXPENSES;
  }
  if (category === APICategory.other && transactionType === TransactionType.INCOME) {
    return Category.OTHER_INCOME;
  }
  return category as unknown as Category; // other type covered in if statement
};

export const convertTransaction = (transaction: APITransaction): Transaction => {
  const { category, type, ...rest } = transaction;
  const newType: TransactionType = transaction.type === 'income' ? TransactionType.INCOME : TransactionType.EXPENSE;

  return {
    ...rest,
    label: transaction.label || undefined,
    category: convertCategory(category, newType),
    type: newType,
  };
};

export const convertMortgage = (mortgage: APIMortgage): Mortgage => {
  const { mortgageType, ...rest } = mortgage;
  const mortgageTypeMap = {
    [APIMortgageType.purchase]: MortgageType.PURCHASE,
    [APIMortgageType.refinance]: MortgageType.REFINANCE,
  };

  return {
    ...rest,
    mortgageType: mortgageTypeMap[mortgageType],
  };
};

export const convertProperty = (dashboardProperty: APIProperty): AppDashboardProperty => {
  const valuations = sortByDesc<DashboardPropertyValuation>(
    filterNulls<DashboardPropertyValuation>(dashboardProperty.propertyValuation?.items),
    'date',
  );
  const purchaseHistory = sortByDesc<DashboardPropertyPurchaseHistory>(
    filterNulls<DashboardPropertyPurchaseHistory>(dashboardProperty.purchaseHistory?.items),
    'date',
  );
  const dashboardMetrics = sortByDesc<DashboardPropertyMetric>(
    filterNulls<DashboardPropertyMetric>(dashboardProperty.metrics?.items),
    'createdTime',
  );
  const amortizationTable = sortByDesc<PropertyAmortizationTable>(
    filterNulls<PropertyAmortizationTable>(dashboardProperty.amortizationTable?.items),
    'transactionDate',
  );
  const { address } = dashboardProperty;
  const propertyDisplayName = address?.street1 || dashboardProperty.name || dashboardProperty.id;
  const transactions: Transaction[] = filterNulls<APITransaction>(dashboardProperty.transactions?.items).map(convertTransaction);
  const units: Unit[] = filterNulls<APIUnit>(dashboardProperty.property?.units?.items);
  const leases: Lease[] = sortByDesc<Lease>(
    filterNulls<APILease>(dashboardProperty.leases?.items),
    'startDate',
  );

  const extendedAddress = convertArrayToFirstElement<PropertyExtendedAddress>(
    dashboardProperty?.property?.extendedAddress ?? { items: [] },
  );

  return {
    ...dashboardProperty,
    address: {
      ...address,
      fullAddress: createDisplayAddress(address),
      shortAddress: createDisplayAddress(address, true),
      fips: extendedAddress?.fips ?? '',
    },
    displayName: propertyDisplayName,
    amortizationTable: convertArrayToFirstElement<PropertyAmortizationTable>({
      items: amortizationTable,
    }),
    mortgage: convertArrayToFirstElement<Mortgage>(dashboardProperty.mortgage),
    transactions,
    groupTransactions: createGroupedTransactions(transactions, dashboardProperty.id),
    valuations,
    leases,
    latestValuation: convertArrayToFirstElement<DashboardPropertyValuation>({
      items: valuations,
    }),
    purchaseHistory: convertArrayToFirstElement<DashboardPropertyPurchaseHistory>({
      items: purchaseHistory,
    }),
    metrics: convertArrayToFirstElement<DashboardPropertyMetric>({
      items: dashboardMetrics,
    }),
    bedrooms: dashboardProperty.property?.bedrooms || 0,
    bathrooms: dashboardProperty.property?.bathrooms || 0,
    sqft: dashboardProperty.property?.sqft || 0,
    yearBuilt: dashboardProperty.property?.yearBuilt,
    units,
  };
};
