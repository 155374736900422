import { ReactElement, useEffect, useState } from 'react';

import {
  BadOwnerPreferredMitigation,
  GoodOwnerPreferredMitigation,
  MaxFinancialIncentive,
  OwnerRiskStatus,
  RetentionPolicy as APIRetentionPolicy,
  RiskDetectionSensitivity,
  SummaryLength,
  useAnalytics,
  useAuth,
  useFeatures,
  useHideChat,
  Willingness,
  WritingStyle,
} from 'lib';
import {
  Controller, useForm, UseFormReturn,
} from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import {
  BoldTypography, capitalize, InfoTooltip, InvertedButton, LightButton, RetentionPolicyIcon, Spinner, useLabels,
} from 'ui';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useGetOwnerRisksByPM } from '../../../api/owner-risk';
import { RetentionPolicyData, useGetRetentionPolicy, useMutateRetentionPolicy } from '../../../api/retention-policy';
import { QueryKey } from '../../../types/enums';
import { retentionPolicyDialogState } from '../state';

const createRetentionPolicyData = (retentionPolicy: APIRetentionPolicy | null): RetentionPolicyData => ({
  maxFinancialIncentive: retentionPolicy?.maxFinancialIncentive ?? MaxFinancialIncentive.ZERO_MGMT_FEE_ONE_MONTH,
  giveIncentiveWillingness: retentionPolicy?.giveIncentiveWillingness ?? Willingness.WILLINGNESS_LEVEL_3,
  meetWillingness: retentionPolicy?.meetWillingness ?? Willingness.WILLINGNESS_LEVEL_3,
  badOwnerPreferredMitigation: retentionPolicy?.badOwnerPreferredMitigation ?? BadOwnerPreferredMitigation.NO_MITIGATION,
  goodOwnerPreferredMitigation: (
    retentionPolicy?.goodOwnerPreferredMitigation ?? GoodOwnerPreferredMitigation.RECOMMEND_TO_SELL_PROPERTY
  ),
  writingStyle: retentionPolicy?.writingStyles[0] ?? WritingStyle.PROFESSIONAL,
  riskDetectionSensitivity: retentionPolicy?.riskDetectionSensitivity ?? RiskDetectionSensitivity.STANDARD_DETECTION,
  summaryLength: retentionPolicy?.summaryLength ?? SummaryLength.STANDARD,
});

const Question = <FieldName extends keyof RetentionPolicyData>({
  fieldName,
  form,
  question,
  options,
  multiple = false,
  tooltip = undefined,
  disabled = false,
  onSelectChange = () => {},
}: {
  fieldName: FieldName,
  form: UseFormReturn<RetentionPolicyData>,
  question: string,
  options: {
    label: string,
    value: (
      FieldName extends 'writingStyles' ? RetentionPolicyData['writingStyle'][number] : RetentionPolicyData[FieldName]
    ),
    capitalize?: boolean
  }[],
  multiple?: boolean,
  tooltip?: ReactElement,
  disabled?: boolean,
  onSelectChange?: (e: any) => void,
}) => {
  const analytics = useAnalytics();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={{ xs: 'flex-start', md: 'space-between' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      width="100%"
      p={4}
      gap={{ xs: 2, md: 0 }}
      flexWrap="wrap"
    >

      {tooltip ? (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="body1" pr={2}>{question}</Typography>
          {tooltip && tooltip}
        </Stack>
      ) : (
        <Typography variant="body1" sx={{ width: { xs: undefined, md: '50%' } }}>{question}</Typography>
      )}
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field: { onChange, value, ...field } }) => (
          <Select
            size="small"
            data-hj-allow
            multiple={multiple}
            {...field}
            sx={{ width: 400 }}
            disabled={disabled}
            onChange={(e, ...args) => {
              onChange?.(e, ...args);
              onSelectChange?.(e);

              analytics.track('Selector Changed', {
                selectorName: fieldName,
                value: Array.isArray(e.target.value) ? e.target.value.join(',') : e.target.value,
              });
            }}
            value={value as string}
          >
            {options.map((option) => (
              <MenuItem
                value={option.value}
                key={`${option.value}`}
                sx={{ textTransform: option.capitalize ? 'capitalize' : undefined }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Stack>
  );
};

const RetentionPolicyForm = ({
  form,
  ownersSeverityScores,
}: {
  form: UseFormReturn<RetentionPolicyData>,
  ownersSeverityScores: number[],
}) => {
  const l = useLabels();
  const analytics = useAnalytics();

  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  // Thresholds hardcoded also in backend risk-engine, if updated here, update in backend as well
  const maximalOwnersAtRiskCount = ownersSeverityScores.filter((severityScore) => severityScore > 30).length;
  const standardOwnersAtRiskCount = ownersSeverityScores.filter((severityScore) => severityScore > 40).length;
  const minimalOwnersAtRiskCount = ownersSeverityScores.filter((severityScore) => severityScore > 50).length;
  const maxFinancialIncentive = form.watch('maxFinancialIncentive');

  const maxFinancialIncentiveOptions = [
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.oneMonth'],
      value: MaxFinancialIncentive.ZERO_MGMT_FEE_ONE_MONTH,
    },
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.twoMonths'],
      value: MaxFinancialIncentive.ZERO_MGMT_FEE_TWO_MONTHS,
    },
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.threeMonths'],
      value: MaxFinancialIncentive.ZERO_MGMT_FEE_THREE_MONTHS,
    },
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.noIncentive'],
      value: MaxFinancialIncentive.NO_INCENTIVE,
    },
  ];

  const summaryLengthOptions = [
    {
      label: l['retention.setupRetentionPolicy.summaryLengthOptions.concise'],
      value: SummaryLength.CONCISE,
    },
    {
      label: l['retention.setupRetentionPolicy.summaryLengthOptions.standard'],
      value: SummaryLength.STANDARD,
    },
    {
      label: l['retention.setupRetentionPolicy.summaryLengthOptions.detailed'],
      value: SummaryLength.DETAILED,
    },
  ];

  const willingnessOptions = [
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.anyHomeowner'],
      value: Willingness.WILLINGNESS_LEVEL_5,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithHighPriority'],
      value: Willingness.WILLINGNESS_LEVEL_4,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithMediumPriorityAndAbove'],
      value: Willingness.WILLINGNESS_LEVEL_3,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithLowPriorityAndAbove'],
      value: Willingness.WILLINGNESS_LEVEL_2,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.none'],
      value: Willingness.WILLINGNESS_LEVEL_1,
    },
  ];

  const badOwnerPreferredMitigationOptions = [
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.fireOwner'],
      value: BadOwnerPreferredMitigation.FIRE_OWNER,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.refer'],
      value: BadOwnerPreferredMitigation.REFER_OWNER,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.sell'],
      value: BadOwnerPreferredMitigation.RECOMMEND_TO_SELL_PROPERTY,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.increaseMgmtFee'],
      value: BadOwnerPreferredMitigation.INCREASE_MGMT_FEE,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.noMitigation'],
      value: BadOwnerPreferredMitigation.NO_MITIGATION,
    },
  ];

  const goodOwnerPreferredMitigationOptions = [
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.recommendToSell'],
      value: GoodOwnerPreferredMitigation.RECOMMEND_TO_SELL_PROPERTY,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.1031'],
      value: GoodOwnerPreferredMitigation.RECOMMEND_1031,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.fix'],
      value: GoodOwnerPreferredMitigation.FIX_PROPERTY_ISSUES,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.offerIncentives'],
      value: GoodOwnerPreferredMitigation.OFFER_INCENTIVES,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.noMitigation'],
      value: GoodOwnerPreferredMitigation.NO_MITIGATION,
    },
  ];

  const writingStylesOptions = [
    { label: capitalize(WritingStyle.PROFESSIONAL), value: WritingStyle.PROFESSIONAL, titleCase: true },
    { label: capitalize(WritingStyle.FRIENDLY), value: WritingStyle.FRIENDLY, titleCase: true },
    { label: capitalize(WritingStyle.TECHNICAL), value: WritingStyle.TECHNICAL, titleCase: true },
  ];

  const generateRiskDetectionSensitivityOption = (sensitivity: RiskDetectionSensitivity, count: number) => ({
    label: l.dynamic['retention.setupRetentionPolicy.riskDetectionSensitivity.options'](sensitivity, count),
    value: sensitivity,
  });

  const riskDetectionSensitivityOptions = [
    generateRiskDetectionSensitivityOption(RiskDetectionSensitivity.MINIMAL_DETECTION, minimalOwnersAtRiskCount),
    generateRiskDetectionSensitivityOption(RiskDetectionSensitivity.STANDARD_DETECTION, standardOwnersAtRiskCount),
    generateRiskDetectionSensitivityOption(RiskDetectionSensitivity.MAXIMUM_DETECTION, maximalOwnersAtRiskCount),
  ];

  return (
    <>
      <Question
        fieldName="maxFinancialIncentive"
        form={form}
        question={l['retention.setupRetentionPolicy.maximumIncentiveQuestion']}
        options={maxFinancialIncentiveOptions}
        onSelectChange={(e) => {
          if (e.target.value === MaxFinancialIncentive.NO_INCENTIVE) {
            form.setValue('giveIncentiveWillingness', Willingness.WILLINGNESS_LEVEL_1);
          }
        }}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="giveIncentiveWillingness"
        form={form}
        question={l['retention.setupRetentionPolicy.financialIncentiveQuestion']}
        options={willingnessOptions}
        disabled={maxFinancialIncentive === MaxFinancialIncentive.NO_INCENTIVE}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="meetWillingness"
        form={form}
        question={l['retention.setupRetentionPolicy.meetWillingnessQuestion']}
        options={willingnessOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="badOwnerPreferredMitigation"
        form={form}
        question={l['retention.setupRetentionPolicy.badOwnerMitigationQuestion']}
        options={badOwnerPreferredMitigationOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="goodOwnerPreferredMitigation"
        form={form}
        question={l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion']}
        options={goodOwnerPreferredMitigationOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="writingStyle"
        form={form}
        question={l['retention.setupRetentionPolicy.writingStylesQuestion']}
        options={writingStylesOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="riskDetectionSensitivity"
        form={form}
        question={l['retention.setupRetentionPolicy.riskDetectionSensitivity']}
        options={riskDetectionSensitivityOptions}
      />
      {features.isRiskV2Enabled && (
        <>
          <Divider sx={{ my: 0 }} />
          <Question
            fieldName="summaryLength"
            form={form}
            question={l['retention.setupRetentionPolicy.summaryLengthOptions.question']}
            tooltip={(
              <InfoTooltip
                arrow
                isLight
                title={(
                  <Stack>
                    <List
                      dense
                      sx={{
                        listStyleType: 'disc',
                        pl: 0,
                        '& .MuiListItem-root': { pl: 0 },
                        '& .MuiListItem-root::marker': { fontSize: '1.5em' },
                      }}
                    >
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body2" component="span">
                          {l['retention.setupRetentionPolicy.summaryLengthOptions.tooltip.concise']}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body2" component="span">
                          {l['retention.setupRetentionPolicy.summaryLengthOptions.tooltip.standard']}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography variant="body2" component="span">
                          {l['retention.setupRetentionPolicy.summaryLengthOptions.tooltip.detailed']}
                        </Typography>
                      </ListItem>
                    </List>
                  </Stack>
                )}
                isOutlined
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Retention Policy Summary Length',
                  });
                }}
              />
            )}
            options={summaryLengthOptions}
          />
        </>
      )}
    </>
  );
};

const RetentionPolicyDialog = ({
  open,
  onClose,
  retentionPolicy = null,
  ownersSeverityScores,
  invalidateData,
}: {
  open: boolean,
  onClose: () => void,
  retentionPolicy?: APIRetentionPolicy | null,
  ownersSeverityScores: number[],
  invalidateData: () => Promise<void>,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync: saveRetentionPolicy, isLoading: isSavingRetentionPolicy } = useMutateRetentionPolicy();

  useHideChat(open && isMobile);

  const form = useForm<RetentionPolicyData>({
    defaultValues: createRetentionPolicyData(retentionPolicy),
  });

  const handleClickSave = async () => {
    analytics.track('Form Submitted', {
      formName: 'Retention Policy',
      previousRetentionPolicy: retentionPolicy?.id,
    });

    const currentValues = form.getValues();
    const wasRiskDetectionSensitivityChanged = currentValues.riskDetectionSensitivity
      !== retentionPolicy?.riskDetectionSensitivity;

    await saveRetentionPolicy({
      retentionPolicy: currentValues,
      currentID: retentionPolicy?.id,
    });

    if (wasRiskDetectionSensitivityChanged) {
      setIsSaving(true);
      await new Promise((resolve) => { setTimeout(resolve, 5000); });
      await invalidateData();
      setIsSaving(false);
    }

    await queryClient.invalidateQueries([QueryKey.RETENTION_POLICY]);

    onClose();
  };

  const handleClickReset = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Reset Retention Policy Form',
    });

    form.reset(createRetentionPolicyData(null));
  };

  const handleClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  useEffect(() => {
    if (!open) {
      // to avoid the text flashing at the same time as the popup is closing
      setTimeout(() => form.reset(createRetentionPolicyData(retentionPolicy)), 150);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
      disableScrollLock
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['retention.policy']}
          </BoldTypography>
          <IconButton onClick={onClose}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Stack mt={3} borderRadius="10px" border={`1px solid ${theme.palette.divider}`}>
          <RetentionPolicyForm form={form} ownersSeverityScores={ownersSeverityScores} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" pt={3}>
          <Button
            variant="text"
            onClick={() => handleClickReset()}
          >
            {l['retention.setupRetentionPolicy.resetToDefault']}
          </Button>
          <InvertedButton
            disabled={isSavingRetentionPolicy}
            onClick={handleClickSave}
          >
            {isSaving ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l.save}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const RetentionPolicy = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const { data: retentionPolicy, isLoading: isRetentionPolicyLoading, isError } = useGetRetentionPolicy();
  const { data: ownerRisksData, isLoading: isOwnerRisksLoading } = useGetOwnerRisksByPM();

  const isLoading = isRetentionPolicyLoading || isOwnerRisksLoading;

  const ownersSeverityScores = ownerRisksData?.pages
    .flatMap((page) => page.ownerRisks)
    .filter((ownerRisk) => ownerRisk.status !== OwnerRiskStatus.RESOLVED
      && ownerRisk.severityScore !== null
      && ownerRisk.severityScore !== undefined)
    .map((ownerRisk) => ownerRisk.severityScore as number) ?? [];

  const buttonText = retentionPolicy ? l['retention.edit'] : l['retention.setup'];

  const handleClickSetup = () => {
    analytics.track('Button Clicked', {
      buttonName: `${buttonText} - Retention Policy Popup`,
    });

    setOpen(true);
  };

  const invalidateData = async () => {
    await queryClient.invalidateQueries({ queryKey: [QueryKey.OWNER_RISKS_BY_PM, user?.pm] });
  };

  useEffect(() => {
    if (!retentionPolicy && !isLoading && !isError) {
      setOpen(true);
    }
  }, [retentionPolicy, isLoading, isError]);

  useEffect(() => {
    retentionPolicyDialogState.value = {
      open,
      openDialog: () => setOpen(true),
    };
  }, [open, setOpen]);

  return (
    <>
      <Card sx={{ height: '100%', background: theme.palette.primary.main }}>
        <CardContent sx={{ height: '100%' }}>
          <Stack height="100%" gap={3} pt={1}>
            <RetentionPolicyIcon color={theme.palette.primary.contrastText} />
            <Stack direction="row" justifyContent="space-between">
              <BoldTypography variant="h6" color="primary.contrastText">
                {l['retention.policy']}
              </BoldTypography>
              <InfoTooltip
                color={theme.palette.grey[400]}
                title={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {l['retention.tooltip.setupRetention']}
                  </Typography>
                )}
                isOutlined
                arrow
                isLight
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Retention Policy Tooltip',
                  });
                }}
              />
            </Stack>
            <Box mt="auto">
              <LightButton disabled={isError || isLoading} onClick={() => handleClickSetup()}>
                {isLoading && <Skeleton variant="text" width={80} />}
                {!isLoading && buttonText}
              </LightButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      {!isLoading && open && (
        <RetentionPolicyDialog
          open={open}
          onClose={() => setOpen(false)}
          retentionPolicy={retentionPolicy}
          ownersSeverityScores={ownersSeverityScores}
          invalidateData={invalidateData}
        />
      )}
    </>
  );
};

export const RetentionPolicyButton = () => {
  const l = useLabels();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const { data: retentionPolicy, isLoading: isRetentionPolicyLoading, isError } = useGetRetentionPolicy();
  const { data: ownerRisksData, isLoading: isOwnerRisksLoading } = useGetOwnerRisksByPM();

  const isLoading = isRetentionPolicyLoading || isOwnerRisksLoading;

  const ownersSeverityScores = ownerRisksData?.pages
    .flatMap((page) => page.ownerRisks)
    .filter((ownerRisk) => ownerRisk.status !== OwnerRiskStatus.RESOLVED
      && ownerRisk.severityScore !== null
      && ownerRisk.severityScore !== undefined)
    .map((ownerRisk) => ownerRisk.severityScore as number) ?? [];

  const invalidateData = async () => {
    await queryClient.invalidateQueries({ queryKey: [QueryKey.OWNER_RISKS_BY_PM, user?.pm] });
  };

  const handleClickSetup = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Set Retention Policy',
    });

    setOpen(true);
  };

  useEffect(() => {
    if (!retentionPolicy && !isLoading && !isError) {
      setOpen(true);
    }
  }, [retentionPolicy, isLoading, isError]);

  useEffect(() => {
    retentionPolicyDialogState.value = {
      open,
      openDialog: () => setOpen(true),
    };
  }, [open, setOpen]);

  return (
    <>
      <Button
        variant="contained"
        disabled={isError || isLoading}
        onClick={() => handleClickSetup()}
        startIcon={!isLoading && <RetentionPolicyIcon height={20} width={20} />}
      >
        {isLoading && <Skeleton variant="text" width={80} />}
        {!isLoading && l['retention.editRetentionPolicy']}
      </Button>
      {!isLoading && open && (
        <RetentionPolicyDialog
          open={open}
          onClose={() => setOpen(false)}
          retentionPolicy={retentionPolicy}
          ownersSeverityScores={ownersSeverityScores}
          invalidateData={invalidateData}
        />
      )}
    </>
  );
};
