import { calculateCapRate, calculateNOI } from 'lib';
import { formatDecimalToPercentage, useLabels } from 'ui';

import { CalculatedRow } from './Row';
import { getCalculationValue, useProjections } from './useProjections';
import { Form } from '../types';

export const CapRate = ({ form }: { form: Form }) => {
  const l = useLabels();
  const view = form.watch('view');
  const projections = useProjections(form);
  const year1NOI = calculateNOI(projections.expectedRent.year1, projections.totalExpenses.year1);
  const year2NOI = calculateNOI(projections.expectedRent.year2, projections.totalExpenses.year2);
  const year3NOI = calculateNOI(projections.expectedRent.year3, projections.totalExpenses.year3);

  const year1CapRate = calculateCapRate(getCalculationValue(year1NOI, view), projections.askingPrice.year1);
  const year2CapRate = calculateCapRate(getCalculationValue(year2NOI, view), projections.askingPrice.year2);
  const year3CapRate = calculateCapRate(getCalculationValue(year3NOI, view), projections.askingPrice.year3);

  return (
    <CalculatedRow
      form={form}
      label={l.capRate}
      bottomBorder={false}
      year1={{
        value: formatDecimalToPercentage(year1CapRate || 0, 2),
        isNegative: year1CapRate < 0,
      }}
      year2={{
        value: formatDecimalToPercentage(year2CapRate || 0, 2),
        isNegative: year2CapRate < 0,
      }}
      year3={{
        value: formatDecimalToPercentage(year3CapRate || 0, 2),
        isNegative: year3CapRate < 0,
      }}
    />
  );
};
