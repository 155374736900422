import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { annualReportLeadershipSummaryState } from './state';
import { useLabels } from '../../lib/translations';
import { BoldTypography } from '../../typography/BoldTypography';

export const LeadershipSummary = ({ isPdf } : { isPdf: boolean }) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Stack
      spacing={2}
      p={6}
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <BoldTypography
        variant="body1"
        color={theme.palette.primary.contrastText}
      >
        {l['annualReport.leadershipSummary.title']}
      </BoldTypography>
      <Stack
        p={3}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: '8px',
        }}
      >
        <Typography
          dangerouslySetInnerHTML={{ __html: annualReportLeadershipSummaryState.value }}
          className="leadership-summary-text"
          sx={
            isPdf ? {
              '& ol li': {
                paddingLeft: theme.spacing(2.5),
              },
            } : {}
          }
        />
      </Stack>
    </Stack>
  );
};
