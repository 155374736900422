import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useFeatures } from 'lib';
import { MdClose } from 'react-icons/md';
import {
  Avatar, BoldTypography, SparkIcon, SpinnerWithLogo, useLabels,
} from 'ui';
import {
  Button, Drawer, IconButton, MenuItem,
  Select, Stack, Typography, useTheme,
} from '@mui/material';

import { InsightRow } from './insights/InsightRow';
import { CaseName, insightCaseNames, insightDrawerState } from './insights/utils';
import { useGetOwnerRisk } from '../../../../api/owner-risk';
import { useGetOwnerPropertyData } from '../../../../api/properties';
import { BasicOwnerRisk } from '../../../../api/properties/types';
import { checkOwnerSentiment } from '../../state';

export const InsightDrawer = ({
  onActClick,
  forceActButtonLoading,
}: {
  onActClick: (ownerRisk: BasicOwnerRisk) => Promise<void>,
  forceActButtonLoading: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const basicOwnerRisk = insightDrawerState.value?.ownerRisk;
  const features = useFeatures();
  const [open, setOpen] = useState(false);
  const [selectedPropertyID, setSelectedPropertyID] = useState<string>('');
  const { data: ownerPropertyData, isLoading: isLoadingOwnerPropertyData } = useGetOwnerPropertyData(
    basicOwnerRisk?.ownerID ?? '',
    { enabled: !!basicOwnerRisk?.ownerID },
  );
  const { data: ownerRisk, isLoading: isLoadingOwnerRisk } = useGetOwnerRisk(
    basicOwnerRisk?.id ?? '',
    !!basicOwnerRisk?.id,
  );

  const isLoading = isLoadingOwnerPropertyData || isLoadingOwnerRisk;

  const properties = ownerPropertyData?.propertyData ?? [];
  const selectedProperty = properties.find((property) => property.id === selectedPropertyID);
  const selectedPropertyInsights = ownerRisk?.ownerRisksInsights.filter(
    (insight) => insight.propertyID === selectedPropertyID,
  ) ?? [];
  const insightsToDisplay = (
    selectedPropertyInsights.length > 0
      ? selectedPropertyInsights
      : ownerRisk?.ownerRisksInsights.filter((insight) => !insight.propertyID) ?? []
  );

  const handleClickRetain = () => {
    if (checkOwnerSentiment(basicOwnerRisk!, features, onActClick)) {
      return;
    }

    onActClick(basicOwnerRisk!);
  };

  useEffect(() => {
    if (properties.length > 0) {
      const propertiesWithInsights = properties.filter(
        (property) => ownerRisk?.ownerRisksInsights.some((insight) => insight.propertyID === property.id),
      );

      setSelectedPropertyID(propertiesWithInsights[0]?.id ?? properties[0]?.id ?? '');
    }
  }, [properties.length]);

  useEffect(() => {
    insightDrawerState.value.openDrawer = () => setOpen(true);
    insightDrawerState.value.closeDrawer = () => {
      setOpen(false);

      setTimeout(() => {
        insightDrawerState.value.ownerRisk = undefined;
        setSelectedPropertyID('');
      }, 500);
    };
  }, [setSelectedPropertyID, setOpen]);

  const renderInsightRow = (caseName: CaseName, i: number) => {
    const insight = insightsToDisplay.find((ins) => ins.caseName === caseName) ?? null;
    const key = insight ? `${insight.id}-${insight.propertyID}-${i}` : `${selectedProperty!.id}-${caseName}-${i}`;

    return <InsightRow key={key} insight={insight} caseName={caseName} property={selectedProperty!} />;
  };

  const avatarURL = (
    `https://ui-avatars.com/api/?name=${basicOwnerRisk?.ownerDetails.firstName}+${basicOwnerRisk?.ownerDetails.lastName}`
  );

  return (
    <Drawer
      open={open}
      onClose={insightDrawerState.value?.closeDrawer}
      anchor="right"
      keepMounted
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: 450 },
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      }}
    >
      <Stack
        position="sticky"
        top={0}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={4}
        gap={10}
        borderBottom={`1px solid ${theme.palette.divider}`}
        bgcolor="background.paper"
        zIndex={1}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
          <Avatar
            src={avatarURL}
            sx={{ width: 44, height: 44 }}
            color="secondary"
            style={{ opacity: 0.6 }}
          />
          <Stack gap={1}>
            <BoldTypography variant="body1">
              {basicOwnerRisk?.ownerDetails.firstName}
              {' '}
              {basicOwnerRisk?.ownerDetails.lastName}
            </BoldTypography>
            <Typography variant="body2" color="text.secondary">
              {l.riskLastUpdate}
              :
              {' '}
              {dayjs(basicOwnerRisk?.updatedTime ?? '').format('MMM D, YYYY')}
            </Typography>
          </Stack>
        </Stack>
        <IconButton onClick={insightDrawerState.value.closeDrawer}>
          <MdClose />
        </IconButton>
      </Stack>
      {isLoading || !selectedPropertyID ? (
        <Stack height="100%" justifyContent="center" alignItems="center">
          {!isLoadingOwnerPropertyData && properties.length === 0 && <h1>No properties found</h1>}
          {isLoading && <SpinnerWithLogo size={80} />}
        </Stack>
      ) : (
        <Stack justifyContent="space-between" height="100%">
          <Stack>
            {properties.length === 1 ? (
              <Stack p={5}>
                <BoldTypography variant="body1">
                  {properties[0]!.fullAddress}
                </BoldTypography>
              </Stack>
            ) : (
              <Stack p={4} gap={3}>
                <BoldTypography variant="body1">
                  {l.property}
                </BoldTypography>
                <Select size="small" value={selectedPropertyID} onChange={(e) => setSelectedPropertyID(e.target.value)}>
                  {properties?.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {property.fullAddress}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            )}
            <Stack px={4} my={3}>
              {insightCaseNames.map((caseName, i) => renderInsightRow(caseName, i))}
            </Stack>
          </Stack>
          <Stack px={4} py={3} position="sticky" bottom={0} bgcolor="background.paper">
            <Button
              disabled={forceActButtonLoading || !basicOwnerRisk}
              variant="contained"
              size="large"
              startIcon={<SparkIcon />}
              onClick={handleClickRetain}
            >
              {l.retain}
            </Button>
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};
