import { useCallback, useMemo } from 'react';

import { jwtDecode } from 'jwt-decode';
import { useDescope, useSession, useUser } from '@descope/react-sdk';

import { useAppSettings } from '../api/app-settings';

declare global {
  interface Window { analytics: any, userGuiding: any }
}

export const impersonationToken = 'impersonation-token';

export const isImpersonationUser = () => sessionStorage.getItem(impersonationToken) !== null;

export type Claims = {
  isActAs: boolean
  isFirstLogin: boolean
};

export type Permissions = {
  viewDashboard: boolean
  viewMarketplace: boolean
  viewPMDashboard: boolean
  viewBilling: boolean
  viewLeads: boolean
  viewOBP: boolean
  viewReferralPartner: boolean
  viewReferralLeads: boolean
  viewRetain: boolean
  viewGrow: boolean
  viewB2CReferralProgram: boolean
  viewProspectLeads: boolean
  viewSuggestedPMs: boolean
  viewBuyBoxLeads: boolean
  viewBuyerLeads: boolean
  viewSellerLeads: boolean
  createAdminUser: boolean
  viewReportInvitation: boolean
  createReportInvitation: boolean
};

export type User = {
  id: string
  realID: string
  name: string
  pm: string
  email: string
  givenName?: string
  familyName?: string
  picture?: string
  actAs: boolean
  smsConsent: boolean
  userProvidedPhone: string
  effectivePhone: string
  permissions: Permissions
  isPm: boolean
  roles: string[]
};

type Auth = {
  isAuthenticated: boolean,
  user: User | undefined,
  refreshUser: () => Promise<void>,
  getAccessTokenSilently: () => Promise<string>;
  getIdTokenClaims: () => Promise<Claims>;
  logout: () => void;
};

export const useAuth = (): Auth => {
  const { data: appSettings } = useAppSettings();
  const { logout, me } = useDescope();
  const { sessionToken, isAuthenticated } = useSession();
  const { user } = useUser();

  const getAccessTokenSilently = useCallback(async () => {
    if (isImpersonationUser()) {
      return sessionStorage.getItem(impersonationToken) ?? '';
    }

    return sessionToken;
  }, [sessionToken]);

  const token = isImpersonationUser() ? sessionStorage.getItem(impersonationToken) : sessionToken;

  const authUser = useMemo<User | undefined>(() => {
    if (!user) return undefined;

    if (!token) return undefined;

    const claims = jwtDecode(token!) as any;
    const { pm, email, invitationEmail: realEmail } = claims;
    const roles = convertRoles(claims?.roles ?? [], pm);

    return {
      pm,
      email,
      id: `${pm}::${email!}`,
      realID: `${pm}::${realEmail!}`,
      name: user.name!,
      givenName: claims?.givenName ?? user.givenName,
      familyName: claims?.familyName ?? user.familyName,
      picture: user.picture,
      actAs: realEmail !== email,
      smsConsent: user.customAttributes?.smsConcent ?? false,
      userProvidedPhone: user.customAttributes?.userProvidedPhone ?? '',
      effectivePhone: user.customAttributes?.userProvidedPhone || user.phone || '',
      permissions: getPermissions(claims?.permissions ?? [], pm),
      isPm: isPm(roles),
      roles,
    };
  }, [user, appSettings, token]);

  return {
    isAuthenticated,
    logout: () => {
      logout();

      window.analytics?.reset();
    },
    user: authUser,
    refreshUser: async () => {
      await me();
    },
    getAccessTokenSilently,
    getIdTokenClaims: async () => {
      const claims = jwtDecode(sessionToken) as any;

      return {
        isActAs: !!user.customAttributes?.actAsEmail,
        isFirstLogin: claims?.firstLogin,
      };
    },
  };
};

const getPermissions = (permissions: string[], pm: string) => ({
  viewDashboard: permissions.includes('view-dashboard'),
  viewMarketplace: permissions.includes('view-marketplace'),
  viewPMDashboard: permissions.includes('view-pm-dashboard'),
  viewBilling: permissions.includes('view-billing'),
  viewLeads: permissions.includes('view-pm-leads-page'),
  viewOBP: permissions.includes('view-obp'),
  viewReferralPartner: permissions.includes('view-referral-partners'),
  viewReferralLeads: permissions.includes(`view-${pm}-leads`),
  viewRetain: permissions.includes('view-retain'),
  viewGrow: permissions.includes('view-grow'),
  viewB2CReferralProgram: permissions.includes('view-b2c-referral-program'),
  viewProspectLeads: permissions.includes(`view-${pm}-leads`),
  viewSuggestedPMs: permissions.includes('view-suggested-pms'),
  createAdminUser: permissions.includes('create-admin-user'),
  viewBuyBoxLeads: permissions.includes('view-buybox-leads'),
  viewSellerLeads: permissions.includes('view-seller-leads'),
  viewBuyerLeads: permissions.includes('view-buyer-leads'),
  viewReportInvitation: permissions.includes('view-report-invitation'),
  createReportInvitation: permissions.includes('create-report-invitation'),
});

const convertRoles = (roles: string[], pm: string) => {
  const filteredRoles = roles.filter((role) => role.startsWith(`${pm}_`) || !role.includes('_'));

  return filteredRoles.map((role) => role.replace(`${pm}_`, ''));
};

const isPm = (roles: string[]) :boolean => (
  roles.some((role) => ['admin', 'sale_manager', 'portfolio_manager'].includes(role))
);
