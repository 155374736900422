import { ReactNode, useEffect, useState } from 'react';

import {
  objectKeys, useAnalytics, useGetPMSettings, useUpdatePmSettings,
} from 'lib';
import { useForm } from 'react-hook-form';
import { MdEdit, MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  ControlledFormattedNumberField, formatDecimalToPercentage, InfoTooltip, metricRelatedExpenses, PercentGreen, PercentRed,
  proformaDefaultMetrics,
  ProformaMetricsData, ProformaMetricsForm, ResetIcon, Spinner, SpinnerWithLogo, useLabels,
  YesNoDialog,
} from 'ui';
import {
  Button,
  Card,
  CardContent,
  Grid, IconButton, List, ListItem, ListItemText, Stack, Typography,
} from '@mui/material';

export const PmSettings = () => {
  const l = useLabels();
  const { data: pmSettings, isLoading: isLoadingPmSettings } = useGetPMSettings();
  const { mutateAsync: updateProformaMetrics, isLoading: isUpdatingMetrics } = useUpdatePmSettings();
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);

  const form = useForm<ProformaMetricsData>({
    defaultValues: proformaDefaultMetrics,
  });

  const handleResetToDefault = async () => {
    try {
      await updateProformaMetrics({
        dashboardProformaMetricsOverride: '',
      });
      setConfirmResetOpen(false);
    } catch (error) {
      console.error(error);

      toast.error(l['error.unknownError']);
    }
  };

  useEffect(() => {
    if (!pmSettings) return;

    const data = JSON.parse(pmSettings.dashboardProformaMetricsOverride || '{}');
    form.reset({
      ...proformaDefaultMetrics,
      ...data,
    });
  }, [pmSettings]);

  if (isLoadingPmSettings) {
    return (
      <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
        <SpinnerWithLogo />
      </Stack>
    );
  }

  return (
    <Stack px={5} pt={5}>
      <Stack gap={3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack gap={1}>
            <Typography variant="subtitle1">
              {l['pm-dashboard.settings.proformaDefaults.title']}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {l['pm-dashboard.settings.proformaDefaults.description']}
            </Typography>
          </Stack>
          <Button variant="outlined" startIcon={<ResetIcon />} onClick={() => setConfirmResetOpen(true)}>
            {l.resetToDefault}
          </Button>
        </Stack>
        <Grid container spacing={3}>
          <EditableMetrics form={form} />
        </Grid>
        <YesNoDialog
          open={confirmResetOpen}
          onClose={() => setConfirmResetOpen(false)}
          title={l['proforma.resetToDefault.title']}
          description={l['proforma.resetToDefault.description']}
          onSubmit={handleResetToDefault}
          isLoading={isUpdatingMetrics}
        />
      </Stack>
    </Stack>
  );
};

const EditableMetric = ({
  form,
  label,
  icon,
  metricKey,
  tooltip,
}: {
  form: ProformaMetricsForm,
  label: string,
  icon: ReactNode,
  tooltip: string,
  metricKey: MetricDataKey,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [editMode, setEditMode] = useState(false);
  const value = form.watch(metricKey);
  const { mutateAsync: updateProformaMetrics, isLoading: isUpdatingMetrics } = useUpdatePmSettings();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const saveOverride = async () => {
    try {
      const data = form.getValues();
      objectKeys(proformaDefaultMetrics).forEach((key) => {
        if (data[key] === proformaDefaultMetrics[key]) {
          delete data[key];
        }
      });
      await updateProformaMetrics({
        dashboardProformaMetricsOverride: JSON.stringify(data),
      });
    } catch (error) {
      console.error(error);

      toast.error(l['error.unknownError']);
    } finally {
      setEditMode(false);
      setConfirmDialogOpen(false);
    }
  };

  const handleClickEditMode = async () => {
    const isEditMode = !editMode;

    // if switch from edit mode to view mode, save the override
    if (!isEditMode) {
      if (metricRelatedExpenses.filter((key) => key !== 'estimatedVacancy').includes(metricKey as any)) {
        setConfirmDialogOpen(true);
        return;
      }

      await saveOverride();
    }

    setEditMode(isEditMode);
  };

  return (
    <>
      <Card elevation={0} sx={{ border: '1px solid', borderColor: 'divider', height: '100%' }}>
        <CardContent sx={{ height: '100%' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3} height="100%">
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack>
                {icon}
              </Stack>
              <Stack gap={1}>
                <Stack direction="row" alignItems="center" gap={3}>
                  {editMode ? (
                    <ControlledFormattedNumberField
                      name={metricKey}
                      control={form.control}
                      useExternalValue
                      externalValue={value}
                      prefix=""
                      suffix="%"
                      size="small"
                      decimalScale={2}
                      showZero
                      inputProps={{
                        sx: {
                          height: 16,
                        },
                      }}
                      onBlur={() => {
                        analytics.track('Input Changed', {
                          inputName: `Proforma Metrics - ${metricKey}`,
                          value,
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="body1">
                      {formatDecimalToPercentage(value / 100, 2)}
                    </Typography>
                  )}
                  <InfoTooltip
                    title={(
                      <Typography variant="body2" sx={{ p: 1 }}>
                        {tooltip}
                      </Typography>
                    )}
                    isOutlined
                    arrow
                    isSmall
                    isLight
                  />
                </Stack>
                <Typography variant="body2" color="text.secondary">
                  {label}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={handleClickEditMode} size="large" disabled={isUpdatingMetrics}>
              {isUpdatingMetrics && <Spinner />}
              {!isUpdatingMetrics && (editMode ? <MdSave /> : <MdEdit />)}
            </IconButton>
          </Stack>
        </CardContent>
      </Card>
      <YesNoDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        title={l['pmSettings.metrics.confirm.title']}
        description={(
          <Stack gap={1}>
            <Typography variant="body1">
              {l['pmSettings.metrics.confirm.descriptionTitle']}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {l['pmSettings.metrics.confirm.description.item1']}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {l['pmSettings.metrics.confirm.description.item2']}
                </ListItemText>
              </ListItem>
            </List>
            <Typography variant="body1">
              {l['pmSettings.metrics.confirm.description.example']}
            </Typography>
            <Typography variant="body1">
              {l['pmSettings.metrics.confirm.description.confirmation']}
            </Typography>
          </Stack>
        )}
        onSubmit={saveOverride}
        isLoading={isUpdatingMetrics}
      />
    </>
  );
};

type MetricDataKey = keyof Omit<ProformaMetricsData, 'managementFee' | 'maintenance' | 'capEx'>;

type MetricConfig = Record<MetricDataKey, {
  label: string,
  icon: ReactNode,
  tooltip: string,
}
>;

export const EditableMetrics = ({
  form,
}: {
  form: ProformaMetricsForm,
}) => {
  const l = useLabels();

  const metrics = form.watch();
  const metricKeys = Object.keys(metrics) as (keyof ProformaMetricsData)[];
  const iconSize = 40;

  const metricConfig: MetricConfig = {
    annualHomeAppreciation: {
      label: l.annualHomeAppreciation,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.annualHomeAppreciation.tooltip'],
    },
    annualRentGrowth: {
      label: l.annualRentGrowth,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.annualRentGrowth.tooltip'],
    },
    estimatedVacancy: {
      label: l.estimatedVacancy,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.estimatedVacancy.tooltip'],
    },
    inflationRate: {
      label: l.inflation,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.inflationRate.tooltip'],
    },
    // TODO: return these later
    // eslint-disable-next-line etc/no-commented-out-code
    // maintenance: {
    //   label: l.maintenanceReserve,
    //   icon: <PercentGreen height={iconSize} width={iconSize} />,
    //   tooltip: l['proforma.maintenanceReserve.tooltip'],
    // },
    // capEx: {
    //   label: l.capEx,
    //   icon: <PercentRed height={iconSize} width={iconSize} />,
    //   tooltip: l['proforma.capEx.tooltip'],
    // },
  };

  return (
    <>
      {metricKeys.map((key) => key !== 'maintenance' && key !== 'capEx' && (
        <Grid item xs={12} sm={6} md={3} key={key}>
          <EditableMetric
            tooltip={metricConfig[key].tooltip}
            form={form}
            label={metricConfig[key].label}
            icon={metricConfig[key].icon}
            metricKey={key}
          />
        </Grid>
      ))}
    </>
  );
};
