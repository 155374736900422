import { getOwnerFromID, useAnalytics, useFeatures } from 'lib';
import { MdVerified } from 'react-icons/md';
import {
  BoldTypography,
  InfoTooltip, LightTooltip, LightTypography, Spinner, useLabels,
} from 'ui';
import {
  Box, Stack, Typography, useTheme,
} from '@mui/material';

import { BasicOwnerRisk } from '../../../../api/properties/types';

type Props = {
  ownerRisk: BasicOwnerRisk,
  activated: boolean,
  isLoading: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  numOfProperties?: number,
  // eslint-disable-next-line react/no-unused-prop-types
  isLoadingNumOfProperties?: boolean,
};
export const OwnerCell = ({ ...props }: Props) => {
  const features = useFeatures();

  if (features.isRiskV2Enabled) {
    return <OwnerCellV2 {...props} />;
  }

  return <OwnerCellV1 {...props} />;
};

export const OwnerCellV1 = ({
  ownerRisk,
  activated,
  isLoading,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const iconSize = 16;

  const owner = getOwnerFromID(ownerRisk.ownerID);

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Typography variant="body1" sx={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {ownerRisk.ownerDetails.firstName
          ? `${ownerRisk.ownerDetails.firstName} ${ownerRisk.ownerDetails.lastName ?? ''}`
          : owner.email}
      </Typography>
      {isLoading ? (
        <Box position="relative" width={iconSize} height={iconSize}><Spinner size={iconSize} /></Box>
      ) : activated && (
        <InfoTooltip
          title={(
            <Typography variant="body2" sx={{ p: 1 }}>
              {l['retention.tooltip.verifiedOwner']}
            </Typography>
          )}
          arrow
          isLight
          icon={<MdVerified color={theme.palette.primary.dark} size={iconSize} />}
          track={(value) => {
            analytics.track('Tooltip Toggled', {
              value,
              tooltipName: 'Activated Owner Tooltip',
            });
          }}
        />
      )}
    </Stack>
  );
};

export const OwnerCellV2 = ({
  ownerRisk,
  activated,
  isLoading,
  numOfProperties = undefined,
  isLoadingNumOfProperties = false,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const iconSize = 16;

  const owner = getOwnerFromID(ownerRisk.ownerID);

  return (
    <Stack maxWidth="100%">
      <Stack direction="row" alignItems="center" gap={2} maxWidth="100%">
        {isLoading ? (
          <Box position="relative" width={iconSize} height={iconSize}>
            <Spinner size={iconSize} />
          </Box>
        ) : activated && (
          <InfoTooltip
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {l['retention.tooltip.verifiedOwner']}
              </Typography>
            )}
            arrow
            isLight
            icon={<MdVerified color={theme.palette.primary.dark} size={iconSize} />}
            track={(value) => analytics.track('Tooltip Toggled', {
              value,
              tooltipName: 'Activated Owner Tooltip',
            })}
          />
        )}
        <LightTooltip
          arrow
          title={(
            <Stack p={1}>
              <Typography variant="body1">
                {ownerRisk.ownerDetails.firstName
                  ? `${ownerRisk.ownerDetails.firstName} ${ownerRisk.ownerDetails.lastName ?? ''}`
                  : owner.email}
              </Typography>
              <Typography variant="body2">
                {isLoadingNumOfProperties ? <Spinner size={16} /> : numOfProperties}
                {' '}
                {numOfProperties !== 1 ? l.properties : l.property}
              </Typography>
            </Stack>
          )}
        >
          <Stack direction="row" alignItems="center" gap={1} sx={{ overflow: 'hidden', flex: 1 }}>
            <BoldTypography
              variant="body2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {ownerRisk.ownerDetails.firstName
                ? `${ownerRisk.ownerDetails.firstName} ${ownerRisk.ownerDetails.lastName ?? ''}`
                : owner.email}
            </BoldTypography>
            <BoldTypography variant="body2">
              {isLoadingNumOfProperties ? <Spinner size={16} /> : `(${numOfProperties})`}
            </BoldTypography>
          </Stack>
        </LightTooltip>

      </Stack>
      {ownerRisk.ownerSince && (
        <LightTypography variant="caption" color="secondary" sx={{ fontSize: 12 }}>
          {l.dynamic['retention.ownerSince'](ownerRisk.ownerSince)}
        </LightTypography>
      )}
    </Stack>
  );
};
