/* eslint-disable max-len */
export const SparkIcon = ({ size = 20 }: { size?: number }) => {
  const clipPathID = Math.random().toString();
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipPathID})`}>
        <path
          d="M8.74995 1.25C7.82945 8.33338 7.08332 9.07952 0 10C7.08332 10.9205 7.82945 11.6667 8.74995 18.75C9.67049 11.6667 10.4167 10.9205 17.4999 10C10.4167 9.07952 9.67049 8.33338 8.74995 1.25Z"
          fill="currentColor"
        />
        <path
          d="M15.6081 -0.155396C15.1872 3.08366 14.846 3.42485 11.6069 3.84576C14.846 4.26668 15.1872 4.60793 15.6081 7.84692C16.029 4.60793 16.3702 4.26668 19.6092 3.84576C16.3702 3.42485 16.029 3.08366 15.6081 -0.155396Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipPathID}>
          <rect width={size} height={size} fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
