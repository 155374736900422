import { PaletteMode } from '@mui/material';
import { lighten } from '@mui/material/styles';

import { hexToRGBA } from '../../lib/hexToRGBA';

export const palette = (mode: PaletteMode) => {
  const lightColor = '#eaeaff';
  const darkColor = '#272629';
  const mainColor = mode === 'light' ? darkColor : lightColor;

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: '#91A1BD',
      darkBg: '#282A42',
      lightBg: '#FAFBFF',
      bodyBg: mode === 'light' ? '#FAFBFF' : '#282A42', // Same as palette.background.default but doesn't consider bordered skin
      tooltipBg: mode === 'light' ? '#262732' : '#464A65',
      tableHeaderBg: mode === 'light' ? '#F5F5F7' : '#3A3E5B',
      info: ' linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #7FB3FF;',
      chip1: '#F4F5F8',
    },
    common: {
      black: '#000',
      white: '#FFF',
    },
    mode,
    primary: {
      light: '#F244760D',
      dark: '#F24476',
      main: '#362642',
      contrastText: '#FFF',
    },
    secondary: {
      light: lighten('#6D788D', 0.2),
      main: '#6D788D',
      dark: '#596274',
      contrastText: '#FFF',
    },
    success: {
      light: lighten('#5ED297', 0.2),
      main: '#10AA86',
      dark: '#31BC68',
      contrastText: '#FFF',
    },
    error: {
      light: lighten('#FF4D49', 0.2),
      main: '#FD646F',
      dark: '#EA5F69',
      contrastText: '#FFF',
    },
    warning: {
      light: '#FDBE42',
      main: '#FFC700',
      dark: '#F5C000',
      contrastText: '#FFF',
    },
    info: {
      light: '#40CDFA',
      main: '#2678F5',
      dark: '#77AAF6',
      contrastText: '#FFF',
    },
    grey: {
      50: '#FAFAFA',
      100: '#F7F7F7',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D8D8D8',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030',
    },
    text: {
      primary: mainColor,
      secondary: hexToRGBA(mainColor, 0.8),
      light: hexToRGBA(mainColor, 0.5),
      disabled: '#BFC6D1',
    },
    divider: hexToRGBA(mainColor, 0.12),
    background: {
      paper: mode === 'light' ? '#ffffff' : '#30334E',
      default: mode === 'light' ? '#FAFBFF' : '#30334E',
    },
    action: {
      active: hexToRGBA(mainColor, 0.54),
      hover: hexToRGBA(mainColor, 0.05),
      hoverOpacity: 0.05,
      selected: hexToRGBA(mainColor, 0.08),
      disabled: hexToRGBA(mainColor, 0.26),
      disabledBackground: hexToRGBA(mainColor, 0.12),
      focus: hexToRGBA(mainColor, 0.12),
    },
  };
};
