// Necessary because of the generic type
/* eslint-disable react/function-component-definition */
import {
  ComponentProps,
  forwardRef, Fragment, MouseEvent,
  ReactElement, ReactNode, useCallback, useEffect, useState,
} from 'react';

import { useDebounce } from 'lib';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import {
  Box, Collapse, IconButton, lighten, Paper, Stack, styled, SxProps, Table, TableBody, TableCell as MuiTableCell, TableHead,
  TablePagination,
  TableRow, TextField, Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { Signal, signal } from '@preact/signals-react';
import {
  Cell,
  ColumnDef, ColumnSort,
  ExpandedState, flexRender, getCoreRowModel, getPaginationRowModel,
  getSortedRowModel, Header,
  Row as TanStackTableRow, RowData, SortingState, Table as TanStackTable,
  Updater, useReactTable,
} from '@tanstack/react-table';

import { ArrowToggleOpen } from '../icons';
import { PageTitle } from '../pages/PageTitle';
import { Spacer } from '../spacer/Spacer';
import { Spinner } from '../spinner';
import { InfoTooltip } from '../tooltip/InfoTooltip';
import { LightTypography } from '../typography/LightTypography';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    sticky?: boolean;
    shownAsText?: boolean;
    blurred?: (row: TanStackTableRow<TData>, cell: Cell<TData, unknown>) => boolean;
    textNoWrap?: boolean;
    headerTooltipContent?: ReactNode;
  }
}

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  '&.minimal': {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  textTransform: 'none',
  '&.sortable': {
    cursor: 'pointer',
  },
  '&.sortable.sorted .MuiTypography-root': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.minimal': {
    background: 'transparent',
    padding: 0,
    border: 'none',
  },
}));

type Props<TData> = {
  title?: string;
  searchInLeft?: boolean;
  searchPlaceholder?: string;
  data: TData[];
  initSearch?: string;
  columns: (ColumnDef<TData, string> | null)[];
  searchTermFilter: (data: TData[], search: string) => TData[];
  independentFilter?: (data: TData[], search: string) => TData[];
  tableHeader?: ReactNode;
  tableHeaderWithSearch?: ReactNode;
  pageHeader?: ReactNode;
  renderSubComponent?: ((props: { row: TanStackTableRow<TData> }) => ReactElement) | null;
  getRowCanExpand?: (row: TanStackTableRow<TData>) => boolean;
  initialState?: ColumnSort[];
  emptyStateText?: string;
  allowSearch?: boolean;
  usePadding?: boolean;
  tableControlsState?: Signal<TableControls>;
  minimal?: boolean;
  triggerSearchDependencies?: any;
  smallRows?: boolean;
  defaultPageSize?: number;
  searchDisabled?: boolean;
  disablePaddingY?: boolean;
};

export const defaultTableControlsState = signal<TableControls>({
  resetExpandedRows: () => { },
  setSorting: () => { },
});

export type TableControls = {
  resetExpandedRows: () => void;
  setSorting: (updater: Updater<SortingState>) => void;
};

const TableWithRef = forwardRef<HTMLTableElement, ComponentProps<typeof Table>>((props, ref) => <Table ref={ref} {...props} />);

// Necessary because of the generic type
// eslint-disable-next-line func-style
export function TableView<TData>({
  title = undefined,
  data,
  columns,
  searchTermFilter,
  searchPlaceholder = '',
  initialState = [],
  tableHeader = null,
  tableHeaderWithSearch = null,
  searchInLeft = true,
  pageHeader = null,
  initSearch = '',
  renderSubComponent = null,
  getRowCanExpand = () => false,
  emptyStateText = 'No items found',
  allowSearch = true,
  usePadding = true,
  tableControlsState = defaultTableControlsState,
  minimal = false,
  triggerSearchDependencies = undefined,
  independentFilter = undefined,
  smallRows = false,
  defaultPageSize = 50,
  searchDisabled = false,
  disablePaddingY = false,
}: Props<TData>) {
  const theme = useTheme();

  const [sorting, setSorting] = useState<SortingState>(initialState);
  const [filteredData, setFilteredData] = useState(data);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [tableWidth, setTableWidth] = useState(0);
  const [tableContainerElement, setTableContainerElement] = useState<HTMLTableElement | null>(null);
  const tableContainerRef = useCallback((node: HTMLTableElement) => {
    if (node === null) {
      return;
    }

    setTableContainerElement(node);
  }, []);

  const [search, setSearch] = useState(initSearch);
  const [isDebouncing, debouncedSearch] = useDebounce(search, 500);

  const nonNullColumns = columns.filter((column) => column !== null) as ColumnDef<TData, string>[];

  const table = useReactTable<TData>({
    data: filteredData,
    state: {
      sorting,
      expanded,
    },
    enableMultiSort: true,
    maxMultiSortColCount: 2,
    onExpandedChange: setExpanded,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    columns: nonNullColumns,
  });

  useEffect(() => {
    const value: TableControls = {
      resetExpandedRows: () => setExpanded({}),
      setSorting: table.setSorting,
    };

    // eslint-disable-next-line no-param-reassign
    tableControlsState.value = value;
  }, [table.resetExpanded, table.setSorting]);

  useEffect(() => {
    table.setPageSize(defaultPageSize);
  }, [defaultPageSize]);

  useEffect(() => {
    if (independentFilter) {
      setFilteredData(independentFilter(data, debouncedSearch));
      return;
    }

    if (debouncedSearch) {
      setFilteredData(searchTermFilter(data, debouncedSearch));
    } else {
      setFilteredData(data);
    }
  }, [debouncedSearch, data, triggerSearchDependencies]);

  useEffect(() => {
    if (tableContainerElement) {
      setTableWidth(tableContainerElement.getBoundingClientRect().width);

      // use resize observer to update the table width when the window is resized
      const resizeObserver = new ResizeObserver(() => {
        setTableWidth(tableContainerElement.getBoundingClientRect().width);
      });

      resizeObserver.observe(tableContainerElement);

      return () => {
        resizeObserver.disconnect();
      };
    }

    return () => null;
  }, [tableContainerElement]);

  const renderAboveTable = tableHeader || allowSearch;

  const getHeaderClasses = (header: Header<TData, unknown>) => {
    const classes = [];
    if (minimal) {
      classes.push('minimal');
    }

    if (header.column.getCanSort()) {
      classes.push('sortable');
    }

    if (header.column.getIsSorted()) {
      classes.push('sorted');
    }

    return classes.join(' ');
  };

  const searchHeader = (
    <Stack position="relative">
      <TextField
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder={searchPlaceholder}
        sx={{ my: 5 }}
        size="small"
        disabled={searchDisabled}
      />
      {isDebouncing && (
        <Stack
          position="absolute"
          top={30}
          right={theme.spacing(2)}
          height={20}
          width={20}
          zIndex={1000}
        >
          <Spinner size={20} />
        </Stack>
      )}
    </Stack>
  );

  return (
    <Stack p={usePadding ? { xs: 3, md: 6 } : undefined} height="100%">
      {title && <PageTitle title={title} />}
      {pageHeader && (
        <Stack mt={pageHeader && title ? 3 : 0}>
          {pageHeader}
        </Stack>
      )}
      {(pageHeader || title) && (
        <Spacer spacing={2} />
      )}
      <Stack
        component={Paper}
        elevation={title ? 4 : 0}
        sx={{ width: '100%', border: (title || minimal) ? undefined : `1px solid ${theme.palette.divider}` }}
      >
        <Stack alignItems="space-between" height="100%">
          <Box>
            {(renderAboveTable && searchInLeft) && (
              <Stack
                direction="row"
                p={4}
                alignItems="center"
                spacing={2}
                justifyContent="space-between"
                flexWrap="wrap"
                py={disablePaddingY ? 0 : undefined}
              >
                {allowSearch && (
                  <Stack direction="row" alignItems="center">
                    {tableHeaderWithSearch}
                    {searchHeader}
                  </Stack>
                )}
                {tableHeader}
              </Stack>
            )}
            {(renderAboveTable && !searchInLeft) && (
              <Stack
                direction="row"
                p={4}
                alignItems="center"
                spacing={2}
                justifyContent="space-between"
                flexWrap="wrap"
                py={disablePaddingY ? 0 : undefined}
              >
                {tableHeader}
                {allowSearch && (
                  <Stack direction="row" alignItems="center">
                    {tableHeaderWithSearch}
                    {searchHeader}
                  </Stack>
                )}
              </Stack>
            )}
            {filteredData.length === 0 && (
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {emptyStateText}
              </Typography>
            )}
            {filteredData.length > 0 && (
              <Stack padding={0} sx={{ overflowX: 'auto' }}>
                <TableWithRef ref={tableContainerRef}>
                  <TableHead sx={{
                    background: minimal ? 'transparent' : '#F5F5F7',
                  }}
                  >
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {table.getCanSomeRowsExpand() && (
                          <TableHeaderCell sx={{ maxWidth: 40, width: 40 }} className={minimal ? 'minimal' : ''} />
                        )}
                        {headerGroup.headers.map((header, i, headers) => (
                          <TableHeaderCell
                            key={header.id}
                            className={`${getHeaderClasses(header)}`}
                            onClick={header.column.getToggleSortingHandler()}
                            sx={{
                              minWidth: header.column.columnDef.minSize,
                              maxWidth: header.column.columnDef.maxSize,
                              width: header.column.columnDef.size,
                              ...(header.column.columnDef.meta?.sticky && getStickyHeaderSx(i, headers.length, theme)),
                            }}
                          >
                            <Stack direction="row" alignItems="center" gap={1}>
                              {header.column.columnDef.meta?.headerTooltipContent && (
                                <InfoTooltip
                                  isLight
                                  isOutlined
                                  arrow
                                  isSmall
                                  title={header.column.columnDef.meta?.headerTooltipContent}
                                />
                              )}
                              <SemiBoldTypography variant="body2">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                              </SemiBoldTypography>
                              {header.column.getCanSort() && !header.column.getIsSorted() && (
                                <MdArrowUpward color={theme.palette.grey[400]} size={24} />
                              )}
                              {{
                                asc: <MdArrowUpward size={24} />,
                                desc: <MdArrowDownward size={24} />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </Stack>
                          </TableHeaderCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <Body
                    table={table}
                    renderSubComponent={renderSubComponent}
                    tableWidth={tableWidth}
                    minimal={minimal}
                    smallRows={smallRows}
                  />
                </TableWithRef>
              </Stack>
            )}
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 250, 500]}
            component="div"
            count={data.length}
            rowsPerPage={table.getState().pagination.pageSize}
            page={table.getState().pagination.pageIndex}
            onPageChange={(_, page) => table.setPageIndex(page)}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Body = <TData,>({
  table,
  tableWidth,
  renderSubComponent = null,
  minimal = false,
  smallRows = false,
}: {
  table: TanStackTable<TData>;
  tableWidth: number;
  minimal?: boolean;
} & Pick<Props<TData>, 'renderSubComponent' | 'smallRows'>) => {
  const theme = useTheme();

  return (
    <TableBody>
      {table.getRowModel().rows?.map((row) => (
        <Fragment key={row.id}>
          <TableRow sx={{
            background: row.getIsExpanded() ? lighten(theme.palette.info.main, 0.88) : undefined,
            transition: 'background 0.3s',
          }}
          >
            {table.getCanSomeRowsExpand() && (
              <TableCell sx={{ maxWidth: 40 }}>
                {row.getCanExpand() && (
                  <IconButton
                    size="small"
                    onClick={row.getToggleExpandedHandler()}
                    color="primary"
                  >
                    <ArrowToggleOpen size={22} className={row.getIsExpanded() ? 'open' : ''} />
                  </IconButton>
                )}
              </TableCell>
            )}
            <Row row={row} minimal={minimal} isSmall={smallRows} />
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                padding: 0,
                borderTop: 'none',
                borderBottom: row.getIsExpanded() ? `1px solid ${theme.palette.divider}` : 'unset',
              }}
              colSpan={
                table.getCanSomeRowsExpand() ? row.getVisibleCells().length + 1 : row.getVisibleCells().length
              }
            >
              <Collapse
                in={row.getIsExpanded()}
                timeout="auto"
                unmountOnExit
                sx={{ '& .MuiCollapse-wrapperInner > *': { maxWidth: tableWidth, overflowX: 'auto', pb: 2 } }}
              >
                {renderSubComponent?.({ row })}
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      ))}
    </TableBody>
  );
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Row = <TData,>({
  row,
  minimal = false,
  isSmall = false,
}: {
  row: TanStackTableRow<TData>;
  minimal?: boolean;
  isSmall?: boolean;
}) => {
  const theme = useTheme();

  const createCellSx = (
    cell: Cell<TData, unknown>,
    i: number,
    cells: Cell<TData, unknown>[],
  ) => {
    let sx: SxProps = {
      cursor: row.getCanExpand() ? 'pointer' : 'default',
      '&&&': {
        py: isSmall ? `${theme.spacing(2)} !important` : undefined,
      },
    };

    if (cell.column.columnDef.meta?.blurred?.(row, cell)) {
      sx = {
        ...sx,
        filter: 'blur(3px)',
        pointerEvents: 'none',
        userSelect: 'none',
      };
    }

    if (cell.column.columnDef.meta?.sticky) {
      sx = {
        ...sx,
        ...getStickyHeaderSx(i, cells.length, theme),
      };
    }

    return sx;
  };

  const handleClickCell = (e: MouseEvent<HTMLElement>) => {
    if (!row.getCanExpand()) return;

    // this is a hack to prevent the click event from bubbling up to the table
    if ((e.target as any).classList?.contains?.('MuiBackdrop-root')) return;

    if (e.target) row.getToggleExpandedHandler()();
  };

  return (
    <>
      {row.getVisibleCells().map((cell, i, cells) => (
        <TableCell
          className={minimal ? 'minimal' : ''}
          key={cell.id}
          style={{
            minWidth: cell.column.columnDef.minSize,
            maxWidth: cell.column.columnDef.maxSize,
            width: cell.column.columnDef.size,
          }}
          sx={createCellSx(cell, i, cells)}
          onClick={handleClickCell}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {cell.column.columnDef.meta?.shownAsText ? (
              <LightTypography variant="body1" sx={{ textWrap: cell.column.columnDef.meta?.textNoWrap ? 'nowrap' : undefined }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </LightTypography>
            ) : flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Stack>
        </TableCell>
      ))}
    </>
  );
};

const getStickyHeaderSx = (i: number, columns: number, theme: Theme): SxProps => ({
  position: 'sticky',
  left: i === 0 ? 0 : undefined,
  right: i === columns - 1 ? 0 : undefined,
  zIndex: 1,
  background: `${theme.palette.background.paper} !important`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: i === columns - 1 ? 0 : undefined,
    right: i === 0 ? 0 : undefined,
    width: '1px',
    height: '100%',
    background: theme.palette.divider,
  },
});
