import dayjs from 'dayjs';
import {
  convertArrayToFirstElement, createDisplayAddress, DashboardProperty,
  DashboardPropertyMetric, filterNulls,
  getActualCashflow, getActualMetricData, getCapRate, getValuation,
  sum,
} from 'lib';

import { OwnerPropertyData, PropertyData } from './types';

export const convertProperties = (properties: DashboardProperty[]): PropertyData[] => {
  const propertyData = properties.map((property) => {
    const latestMetric = convertArrayToFirstElement<DashboardPropertyMetric>({
      items: filterNulls(property.metrics?.items),
    });

    return {
      id: property.id,
      street1: property.address.street1,
      fullAddress: createDisplayAddress(property.address),
      valuation: latestMetric?.valuation ?? 0,
      purchasePrice: latestMetric?.purchasePrice ?? 0,
      purchaseDate: latestMetric?.purchaseDate ? dayjs(latestMetric.purchaseDate) : undefined,
      isVacant: (latestMetric?.currentVacancy ?? 0) > 0,
      capRate: getCapRate([latestMetric]),
      cashFlow: latestMetric?.cashFlow ?? 0,
      noi: latestMetric?.NOI ?? 0,
      annualIncome: getActualMetricData([latestMetric], 'income'),
      annualExpenses: getActualMetricData([latestMetric], 'totalExpenses'),
      annualOperatingExpenses: getActualMetricData([latestMetric], 'noiExpenses'),
      monthMetrics: (latestMetric?.monthsMetrics ?? []).sort((a, b) => dayjs(a.monthDate).diff(dayjs(b.monthDate))),
      totalUniqueTenants: latestMetric?.totalUniqTenants ?? 0,
      turnoverTenants: latestMetric?.turnoverTenants ?? 0,
      totalUnits: property.property?.units?.items.length ?? 0,
      deleted: property.deleted,
    };
  });

  return propertyData;
};

export const convertOwnerPropertyData = (properties: DashboardProperty[]): OwnerPropertyData => {
  const metrics: DashboardPropertyMetric[] = filterNulls(
    properties.map((property) => convertArrayToFirstElement<DashboardPropertyMetric>({
      items: filterNulls(property.metrics?.items),
    })),
  );
  const portfolioValue = getValuation(metrics);
  const cashFlow = getActualCashflow(metrics);
  const capRate = getCapRate(metrics);

  const propertyData = convertProperties(properties.filter((p) => !p.deleted));
  const propertyDeletedData = convertProperties(properties.filter((p) => p.deleted));

  return {
    portfolioValue,
    capRate,
    cashFlow,
    unitCount: sum(properties.map((p) => (p.property?.units?.items.length ?? 0))),
    nonVacantUnits: sum(properties.map((p) => (p.property?.units?.items.filter((u) => u && !u.isVacant).length ?? 0))),
    propertyData,
    propertyDeletedData,
    isEstimated: metrics.some((metric) => metric.isDataPartial),
  };
};
