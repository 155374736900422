import dayjs from 'dayjs';
import { Features } from 'lib';

import {
  getCraneName, getCrimeTextCounty, getCrimeTextNational, getReferAndEarnSubtitle, getRiskDetectionSensitivity,
} from './dynamic';

type NoLoadingFeatures = Omit<Features, 'isLoading'>;

/* eslint-disable max-len */
const labels = {
  totalFees: 'Total Fees',
  totalUnits: 'Total Units',
  uniqueTenants: 'Unique Tenants',
  turnover: 'Turnover',
  events: 'Events',
  averageMonthlyAppreciationShort: 'Avg. monthly apprec.',
  reason: 'Reason',
  maintenanceRatioToIncome: 'Maint. Ratio to Income',
  feeToIncomeRatio: 'Fee To Income Ratio',
  totalIncome: 'Total Income',
  totalCosts: 'Total Costs',
  totalEvents: 'Total Events',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  positive: 'Positive',
  negative: 'Negative',
  is: 'Is',
  isNot: 'Is Not',
  selectInsight: 'Select Insight',
  pleaseSelectInsight: 'Please select Insight',
  moveToInProgress: 'Move to In Progress',
  ok: 'OK',
  selectCategory: 'Select Category',
  resetToDefault: 'Reset to default',
  fillInLater: 'Fill in later',
  referralFeeAmount: 'Referral Fee Amount',
  areas: 'Areas',
  filterByStates: 'Filter by states',
  loanBalance: 'Loan Balance',
  equity: 'Equity',
  cumEquity: 'Cum. Equity',
  cumNetCashFlow: 'Cum. Net Cash Flow',
  cumAppreciation: 'Cum. Appreciation',
  estInvestmentValue: 'Est. Investment Value',
  propertyValue: 'Property Value',
  or: 'or',
  connectNow: 'Connect Now',
  emailIsSent: 'The email has been sent out!',
  propertyManagers: 'Property Managers',
  unlock: 'Unlock',
  general: 'General',
  kanbanView: 'Kanban View',
  listView: 'List View',
  reveal: 'Reveal',
  revealLead: 'Reveal Lead',
  sellerEmail: 'Seller Email',
  addAddress: 'Add Address',
  sortBy: 'Sort By',
  details: 'Details',
  clients: 'Clients',
  dateAdded: 'Date Added',
  view: 'View',
  review: 'Review',
  assumptions: 'Assumptions',
  name: 'Name',
  phone: 'Phone',
  status: 'Status',
  services: 'Services',
  guarantees: 'Guarantees',
  enterAmount: 'Enter amount',
  all: 'All',
  iDontMind: 'I don’t mind',
  pleaseSpecify: 'Please specify',
  showMore: 'Show more',
  retain: 'Retain',
  risks: 'Risks',
  awards: 'Awards',
  opportunities: 'Opportunities',
  partners: 'Partners',
  network: 'Network',
  leads: 'Leads',
  grow: 'Grow',
  buy: 'Buy',
  sell: 'Sell',
  settings: 'Settings',
  system: 'System',
  faq: 'FAQ',
  blog: 'Blog',
  data: 'Data',
  academy: 'Academy',
  propertyDetails: 'Property details',
  selectProperty: 'Select property',
  products: 'Products',
  investmentPlanner: 'Investment Planner',
  favorite: 'Favorite',
  share: 'Share',
  shareListingWithFriends: 'Share this listing with friends',
  linkCopied: 'Link copied to clipboard',
  copyToClipboard: 'Copy to clipboard',
  copied: 'Copied',
  shareThisListing: 'Share this listing',
  shareListingText: 'Check out this investment opportunity!',
  back: 'Back',
  oneYear: '1 Year',
  twoYear: '2 Year',
  threeYear: '3 Year',
  estimatedVacancy: 'Estimated Vacancy',
  expectedRent: 'Expected Rent',
  totalExpenses: 'Total Expenses',
  buyerLeads: 'Buyer Leads',
  sellerLeads: 'Seller Leads',
  portfolioValue: 'Portfolio Value',
  numberOfOwners: 'Number of Owners',
  numberOfProspects: 'Number of Prospects',
  recommendedListings: 'Recommended Listings',
  invited: 'Invited',
  received: 'Received',
  activated: 'Activated',
  created: 'Created',
  invite: 'Invite',
  refer: 'Refer',
  referLead: 'Refer Lead',
  updated: 'Updated',
  call: 'Call',
  email: 'Email',
  owner: 'Owner',
  manage: 'Manage',
  reviewSession: 'Review session',
  InviteByEmail: 'Invite by email',
  InviteBySms: 'Invite by SMS',
  sendReport: 'Send report',
  sfrOwner: 'SFR Owner',
  owners: 'Owners',
  partner: 'Partner',
  agent: 'Agent',
  agents: 'Agents',
  toDiscuss: 'To Discuss',
  timeline: 'Timeline',
  propertyType: 'Property Type',
  financing: 'Financing',
  buyBox: 'Buy Box',
  exclusive: 'Exclusive',
  newConstruction: 'New Construction',
  turnkey: 'Turnkey',
  wholesale: 'Existing',
  proforma: 'Proforma',
  seeAll: 'See All',
  seeLess: 'See Less',
  wrongEmailOrPassword: 'Wrong email or password',
  deals: 'Deals',
  prospects: 'Prospects',
  buyer: 'Buyer',
  buyers: 'Buyers',
  seller: 'Seller',
  sellers: 'Sellers',
  referrals: 'Referrals',
  total: 'Total',
  filter: 'Filter',
  filters: 'Filters',
  cancel: 'Cancel',
  close: 'Close',
  reset: 'Reset',
  apply: 'Apply',
  amount: 'Amount',
  date: 'Date',
  other: 'Other',
  otherMonths: 'Other (Months)',
  error: 'error',
  step: 'Step',
  import: 'Import',
  thankYou: 'Thank You',
  congratulations: 'Congratulations!',
  purchase: 'Purchase',
  'purchase-description': '',
  letsGetStarted: 'Let’s get started',
  welcomeToMarketPlaceTitle: 'Welcome to the Blanket Marketplace',
  welcomeToMarketplaceInfo: 'By configuring your investment preferences, our system will match you with properties that fit your needs',
  prev: 'PREV',
  done: 'DONE',
  next: 'NEXT',
  skip: 'Skip',
  add: 'Add',
  confirm: 'Confirm',
  edit: 'Edit',
  editAssumptions: 'Edit Assumptions',
  save: 'Save',
  submit: 'Submit',
  show: 'Show',
  hide: 'Hide',
  lowest: 'Lowest',
  highest: 'Highest',
  calculation: 'calculation',
  addExpense: 'Add Expense',
  confirmExpense: 'Review And Confirm Expense Details',
  perMonth: '/mo',
  perYear: '/yr',
  yes: 'YES',
  no: 'NO',
  income: 'Income',
  expense: 'Expense',
  expenses: 'Expenses',
  support: 'Support',
  success: 'Success',
  excitedSuccess: 'Success!',
  thatIsIt: 'That’s It!',
  getStarted: 'Get Started',
  gotIt: 'Got it',
  activeListings: 'Active Listings',
  pleaseWait: 'Please wait',
  provideMissingData: 'Please provide missing data',
  askingPrice: 'Asking Price',
  priceOrValue: 'Price/Value',
  address: 'Address',
  category: 'Category',
  startDate: 'Start Date',
  firstPaymentDate: 'First Payment Date',
  secondPaymentDate: 'Second Payment Date',
  expenseAmount: 'Expense Amount',
  dollar: 'Dollar',
  dollarCurrency: '$',
  percentSymbol: '%',
  location: 'Location',
  hoa: 'HOA',
  hoaFees: 'HOA fees',
  insurance: 'Insurance',
  tax: 'Tax',
  propertyTax: 'Property Tax',
  autoAndTravel: 'Auto & Travel',
  supplies: 'Supplies',
  maintenanceReserve: 'Maintenance Reserve',
  maintenance: 'Maintenance',
  monthsToEndLease: 'Months to End Lease',
  portfolio: 'Portfolio',
  profFees: 'Prof. Fees',
  utilities: 'Utilities',
  legalAndProfessionalFees: 'Legal And Professional Fees',
  rentalRegistration: 'Rental Registration',
  leasingAndMarketing: 'Leasing & Marketing',
  otherExpenses: 'Other expenses',
  operatingExpenses: 'Operating Expenses',
  managementFees: 'Management Fees',
  operatingIncome: 'Operating Income',
  cashFlow: 'Cash Flow',
  totalOperatingExpenses: 'Total operating expenses',
  iAmInterested: 'I’m interested',
  showAllPhotos: 'SHOW ALL PHOTOS',
  paymentType: 'Payment Type',
  oneTime: 'One Time',
  monthlyRecurring: 'Monthly Recurring',
  biAnnual: 'Bi-Annual',
  editTransaction: 'Edit transaction',
  recurringAllOrSingleEditSelection: 'Do you want to edit all or just this transaction?',
  deleteRecurringTitle: 'Delete recurring transaction',
  deleteRecurringDescription: 'Do you want to delete selected transaction or all?',
  principalPaydown: 'Principal Paydown',
  errorFieldIsRequired: 'This field is required',
  errorFieldIsInvalid: 'This field is invalid',
  listingNotFound: 'We couldn’t find the listing you’re looking for, please try again later.',
  propertiesNotFound: 'No properties found',
  errorGettingProperties: 'Error getting properties',
  errorGettingPropertiesDescription: 'There was an error getting your properties. Please try again later.',
  errorMadeButtonDisabled: 'An error prevented this action from being completed. Please try again later.',
  tryAgainLater: 'Please try again later',
  'error.missingContactEmail': 'The contact email is missing',
  'error.missingContactPhone': 'The contact phone number is missing',
  'error.unknownError': 'The application has encountered an unknown error.\nOur technical staff has been automatically notified and will be looking into this issue with the utmost urgency.',
  'skip-modal-title': 'Exit onboarding?',
  'skip-modal-desc': 'Are you sure? The more data we have the more accurate your dashboard will be. You can always come back and edit the information here by clicking on "Update properties" in the main menu.',
  'delete-modal-title': 'Are you sure you want to delete?',
  continue: 'CONTINUE',
  purchasePrice: 'Purchase Price',
  homePrice: 'Home Price',
  totalPurchasePrice: 'Total Purchase Price',
  cashInvested: 'Cash Invested',
  downPayment: 'Down Payment',
  property: 'Property',
  properties: 'Properties',
  units: 'Units',
  reviewDetails: 'Review Details',
  purchaseDate: 'Purchase Date',
  poweredBy: 'Powered by',
  closingCost: 'Closing Costs',
  homeImprovements: 'Home Improvements',
  homeImprovementsAbbr: 'Home Improv.',
  performanceReview: 'Performance Review',
  emailInvite: 'Email',
  smsInvite: 'SMS',
  loan: 'Loan',
  'loan-description': '',
  'tooltip.purchase': 'The purchase price of your property, including closing costs and home improvements.',
  'tooltip.loan': 'Loan details are used to calculate how much equity you have in your property.\nNote: We use this data to calculate your mortgage payments shown on the transactions page.',
  closingCosts: 'Closing Costs',
  loanTerm: 'Loan Term',
  loanType: 'Loan type',
  lastYear: 'Last 12 months',
  '120-months': '10 Years',
  '180-months': '15 Years',
  '240-months': '20 Years',
  '360-months': '30 Years',
  otherLoanTerm: 'Other Loan Term (Months)',
  interestRate: 'Interest Rate',
  loading: 'loading',
  mostProfitableMonth: 'Your most profitable month was',
  highestMonth: 'Your highest monthly expense was on',
  missingPropertyData: 'Missing Property Data',
  totalEquity: 'Total Equity',
  principalPaid: 'Principal Paid',
  appreciation: 'Appreciation',
  yourLoanBalance: 'Your loan balance is',
  'missingData.provideInfo1': 'To make sure your property metrics are as accurate as possible, head over to the ',
  'missingData.updateProperties': '"Update Properties"',
  'missingData.provideInfo2': ' page in the menu and fill in any missing data.',
  expenseBreakdown: 'A breakdown of all your month-to-month expenses',
  portfolioSummary: 'Portfolio Summary',
  summary: 'Summary',
  propertyPrice: 'Choose your asking price',
  price: 'Price',
  priceRange: 'Price Range',
  normal: 'Normal',
  na: 'N/A',
  beds: 'Beds',
  bedrooms: 'Bedrooms',
  bedsShort: 'bd',
  baths: 'Baths',
  yearBuilt: 'Year built',
  bathRooms: 'Bathrooms',
  bathsShort: 'ba',
  bedsAndBaths: 'Beds & Baths',
  numOfBedsBaths: 'Number of Bedrooms & Bathrooms',
  sqft: 'Sqft',
  builtIn: 'Built in',
  lotSize: 'Lot size',
  garages: 'Garages',
  rent: 'Rent',
  totalOtherIncome: 'Total other income',
  actualRent: 'Actual Rent',
  estimatedRent: 'Estimated Rent',
  none: 'None',
  mortgage: 'Mortgage',
  missingExpenses: 'Please provide missing expenses',
  loanPayments: 'Loan Payments',
  loanAmount: 'Loan Amount',
  mortgageAmortizationPeriod: 'Mortgage Amortization Period',
  percentage: 'Percentage',
  monthlyMortgagePayment: 'Monthly Mortgage Payment',
  month: 'Month',
  year: 'Year',
  years: 'Years',
  by: 'by',
  annualHomeAppreciation: 'Annual Home Appreciation',
  annualRentGrowth: 'Annual Rent Growth',
  inflation: 'Inflation',
  managementFee: 'Management Fee',
  capEx: 'Cap Ex',
  lastUpdate: 'Last update',
  description: 'Descr.',
  originationMonthShort: 'Orig. Month',
  paymentDay: 'Payment Day',
  loadMore: 'Load More',
  more: 'more',
  addFilter: 'Add Filter',
  addressPlaceholder: 'Search...',
  clear: 'Clear',
  clearAll: 'Clear All',
  clearFilters: 'Clear Filters',
  handpicked: 'Handpicked',
  lowCrime: 'Low Crime',
  onePercentRule: '1% Rule',
  PM: 'PM',
  managedBy: 'Managed by',
  calculated: 'Calculated',
  estimated: 'Estimated',
  allProperties: 'All Properties',
  profile: 'Profile',
  upTo: 'Up to',
  minimum: 'Minimum',
  noMin: 'No Min',
  maximum: 'Maximum',
  noMax: 'No Max',
  useExactMatch: 'Use exact match',
  any: 'Any',
  startReview: 'Start Review',
  endReview: 'End Review',
  totalCashFlow: 'Total Cashflow',
  'mortgageType.purchase': 'Purchase Mortgage',
  'mortgageType.refinance': 'Refinance',
  'transactions.title': 'Transactions',
  'label.pm': 'PM',
  'label.manual': 'Manual',
  'tooltip.estimated': 'Mortgage transactions are calculated using the property’s purchase price, loan term, interest rate, payment day, and payment date. These metrics come from the property data input during the onboarding process. To edit, go to the “Update properties” page in the menu.',
  'tooltip.pm': 'Property Management transactions are records of transactions automatically received from the property management software.',
  'tooltip.manual': 'Manual transactions are transactions that are added manually by clicking “ADD” on the top right. These transactions could include one-time payments like Insurance or Property Taxes or recurring monthly payments like HOA fees.',
  'transactions.filters.period': 'Period',
  'transactions.filters.fromDate': 'From Date',
  'transactions.filters.toDate': 'To Date',
  'transactions.filters.category': 'Category',
  'transactions.filters.property': 'Property',
  'transactions.no-results': 'No transactions found.',
  'transactions.no-results.try-again': 'Consider modifying your filters or clearing them to see all available transactions.',
  'select-property': 'Select Property',
  'good-to-go': 'You’re good to go.',
  'onboarding-welcome-next': 'Next, you’ll be asked to fill in some details on your properties like purchase price, mortgage info, and expenses.\nIt should only take a few minutes. ',
  'loader-phrase-1': 'Grabbing CRM data',
  'loader-phrase-2': 'Mixing in public market data',
  'loader-phrase-3': 'Looking for missing data points',
  'loader-phrase-4': 'Calculating property values',
  'loader-phrase-5': 'Adding the final details',
  'terms-and-conditions': 'Terms and Conditions',
  'menu-billing': 'Billing',
  'menu-dashboard': 'Dashboard',
  'menu-properties': 'Properties',
  'menu-portfolio': 'Portfolio',
  'menu-marketplace': 'Marketplace',
  'menu-transactions': 'Transactions',
  'menu-update-properties': 'Update Properties',
  'menu-reports': 'Reports',
  'menu-annual-report': 'Annual Report 2024',
  'menu-owner-benefits': 'Owner Benefits',
  'menu-owners': 'Owners',
  'menu-prospects': 'Prospects',
  'menu-contacts': 'Contacts',
  'menu-partners': 'Partners',
  'menu-team': 'Team',
  'menu-pm': 'PM',
  'menu-blanket-overview': 'Overview',
  logout: 'Logout',
  capRate: 'Cap Rate',
  viewTransactions: 'View Transaction Details',
  propertyNoLongerMonitored: 'This property is no longer being monitored.',
  estimatedCapRate: 'Estimated Cap Rate',
  calculatedCapRate: 'Calculated Cap Rate',
  grossYield: 'Gross Yield',
  cashOnCash: 'Cash on cash',
  calculatedCashOnCash: 'Calculated Cash on Cash',
  netCashFlow: 'Net Cash Flow',
  noi: 'NOI',
  netOperatingIncome: 'Net Operating Income',
  grossRent: '12 months gross income',
  lastMonthIncome: "Last month's income",
  cashOnCashReturn: 'Cash on cash return',
  occupied: 'Occupied',
  notOccupied: 'Not occupied',
  noHoa: 'No HOA',
  noHoaText: 'You won’t receive any HOA notifications for this property.',
  'last-12-months': 'Last 12 months',
  'tooltip.noi.title': 'Net Operating Income (NOl):',
  'tooltip.noi.desc': 'The income collected from your property after subtracting operating expenses from total income (excluding loan payments)',
  'tooltip.caprate.title': 'Cap Rate:',
  'tooltip.caprate.desc': 'The expected returns your properties will generate compared to their objective value. This is calculated by comparing your net operating income relative to your current estimated portfolio value.',
  'tooltip.cash.title': 'Cash On Cash return:',
  'tooltip.cash.desc': 'The expected returns of the overall portfolio. This is calculated by comparing the net cash flow relative to the initial cash invested in your properties. Cash invested can be your purchase price or down payment (if purchase was financed) including closing costs and home improvements.',
  'tooltip.cashflow.title': 'Cash Flow:',
  'tooltip.cashflow.desc': 'The total income collected minus total expenses (operating expenses and loan payments).',
  'tooltip.totalIncome.title': 'Total Income:',
  'tooltip.totalIncome.desc': 'The total amount of rent and any other income collected in the last 12 months.',
  'tooltip.totalExpense.title': 'Total Expenses:',
  'tooltip.totalExpense.desc': 'The total amount of operating expenses (property tax, HOA fees, insurance, management fees, and maintenance) and loan payments in the last 12 months.',
  'tooltip.total-income.title': 'Total income (12 mo):',
  'tooltip.total-income.desc': 'The sum of all rent payments and any other income collected.',
  'tooltip.last_month_income.title': 'Last month’s income:',
  'tooltip.last_month_income.desc': 'Rent payments and any other income collected last month.',
  'tooltip.occupancy.title': 'Occupancy:',
  'tooltip.occupancy.desc': 'The number of occupied units in your portfolio',
  'tooltip.total_equity.title': 'Total Equity:',
  'tooltip.total_equity.desc': 'The current value of your properties after subtracting your loan balance.',
  'tooltip.principal_paid.title': 'Principal Paid:',
  'tooltip.principal_paid.desc': 'Total amount of loan payments made towards the original loan amount.',
  'tooltip.appreciation.title': 'Appreciation:',
  'tooltip.appreciation.desc': 'How much your property increased in value since it was purchased.',
  'tooltip.progressbar.title': 'Principal paydown progress bar:',
  'tooltip.progressbar.desc': 'Represents your outstanding loan balance',
  'tooltip.estimated_value.title': 'Portfolio Value:',
  'tooltip.estimated_value.desc': 'The estimated value of all your properties, including appreciation rate, presented in % and $ value increase. The calculation is powered by an Automated Valuation Model.',
  'tooltip.purchase_price.title': 'Total purchase price:',
  'tooltip.purchase_price.desc': 'The purchase price of all your properties, including closing costs and home improvements.',
  'tooltip.cash_invested.title': 'Cash Invested:',
  'tooltip.cash_invested.desc': 'The down payment of all your properties, including closing costs, and home improvements.',
  'tooltip.cash_invested.tooltip1': 'Press this button to cash out',
  'tooltip.cash_invested.tooltip2': 'your total equity.',
  'tooltip.cash_invested.cashOut': 'CASH OUT',
  'tooltip.proforma.share': 'Share this page',
  'tooltip.proforma.homePrice': 'The asking price provided by the seller.',
  'tooltip.proforma.homePrice.valuation': 'Home price represents the current and the future estimated value of your property/portfolio with an annual appreciation rate that can be customized at the top of the page.',
  'tooltip.proforma.expenses': 'The recurring costs associated with maintaining this rental property, including property taxes (Representing the last bill paid), insurance (Estimated or actual value in occupied properties), management fees, and HOA fees (Provided by the seller). Costs excluded from operating expenses include mortgage payments, capital expenses, and depreciation expenses.',
  'tooltip.proforma.income': 'Income represents rent payments and other income sources. To simulate a conservative estimation of future income, estimated vacancy is also included.',
  'tooltip.proforma.netOperatingIncome': 'The income collected from this property after subtracting operating expenses (excluding loan payments).',
  'tooltip.proforma.capRate': 'The expected net operating income this property will generate relative to the asking price.',
  'tooltip.proforma.loanPayments': 'Estimated loan payment, calculated by your inputs, applied to the interest accrued on the mortgage balance and to the reduction of the outstanding principal.',
  'tooltip.proforma.netCashFlow': 'The total income collected from this property minus total expenses (operating expenses and loan payments)',
  'tooltip.proforma.cashOnCashReturn': 'The expected return of this property represented by net cash flow (factoring in loan payments) relative to the cash invested in this property. Cash invested can be your purchase price or down payment (if the purchase was financed).',
  'transactions.alerts.title': 'Missing Data',
  'transactions.alerts.subtitle': 'Please provide information about the following transactions',
  'reports.estValue': 'Property Value (est.)',
  'reports.estValue.tooltip': 'The estimated value of your property',
  'reports.portfolio.estValue': 'Portfolio Value (est.)',
  'reports.portfolio.estValue.tooltip': 'The estimated total value of all your properties',
  'reports.monthlyAppreciation': 'Monthly Appreciation',
  'reports.portfolio.tooltip': 'How much your property increased in value this month',
  'reports.portfolio.monthlyAppreciation.tooltip': 'How much your portfolio increased in value this month',
  'reports.totalEquity': 'Total Equity',
  'reports.totalEquity.tooltip': 'The current value of your property after subtracting your loan balance.',
  'reports.portfolio.totalEquity.tooltip': 'The current value of your properties after subtracting your loan balance.',
  'reports.occupancy': 'Occupancy',
  'reports.occupancy.tooltip': 'Whether your property is occupied or not.',
  'reports.portfolio.occupancy.tooltip': 'The number of occupied units in your portfolio.',
  'reports.occupancy.yes': 'Yes',
  'reports.occupancy.no': 'No',
  'reports.widgets.loanPayments': 'Loan payments',
  'reports.widgets.loanPayments.tooltip': 'The amount you pay towards your property loans each month, based on the loan amount, loan term, and interest rate.',
  'reports.widgets.cashFlow.tooltip': 'The total income collected minus total expenses (operating expenses and loan payments).',
  'reports.widgets.cashOnCashReturn.tooltip': 'The expected returns of the overall portfolio. This is calculated by comparing the net cash flow relative to the initial cash invested in your properties. Cash invested can be your purchase price or down payment (if purchase was financed) including closing costs and home improvements.',
  'reports.widgets.vacancyStatus': 'Vacancy status',
  'reports.filters.date': 'Date',
  'reports.filters.type': 'Type',
  'reports.filters.tooltip': 'tooltip',
  'sellProperty.wantToSell': 'Do you want to sell your property?',
  'sellProperty.getStarted.title': 'Sell in just a few steps',
  'sellProperty.getStarted.intro': 'We’ll walk you through a few short steps to get your property listed on the marketplace. It’s super simple.',
  'sellProperty.getStarted.selectProperty.header': 'Choose a property',
  'sellProperty.getStarted.selectProperty.description': 'Select one of the properties you own',
  'sellProperty.getStarted.agent.header': 'Meet our experts',
  'sellProperty.getStarted.agent.description': 'Click on Get Started and one of our experts will contact and guide you through every step of the way',
  'sellProperty.getStarted.success.description': 'Get your listing approved and start receiving offers from qualified buyers.',
  'sellProperty.sell.priceTooltip': 'Your asking price is the amount you are offering the buyer to pay to purchase the property. The graph below is the estimated market value of the property. The estimated value is provided by leading data providers and is calculated by comparable properties sold in your property\'s area',
  'sellProperty.sell.selectProperty': 'Choose the property you want to sell',
  'sellProperty.sell.selectPropertyNoAvailable': 'Enter your property address',
  'sellProperty.sell.propertyAddress': 'Property Address',
  'sellProperty.sell.propertyPrice.description': 'Keep in mind that your actual earning may be further reduced by applicable taxes.',
  'sellProperty.sell.propertyPrice.idkPrice': 'Do you have a price in mind?',
  'sellProperty.sell.propertyPrice.yes': 'Yes',
  'sellProperty.sell.propertyPrice.no': 'No',
  'sellProperty.sell.marketPrice.title': 'Estimated Property Value',
  'sellProperty.sell.marketPrice.description': 'Your property’s est. range of market value, including its avg. market value',
  'sellProperty.listingSubmitted': 'One of our agents will contact you soon to get your listing approved!',
  'listings.shareConfirm': 'Listing Copied! The share link has been successfully copied to your clipboard and is ready to be shared.',
  'listings.share.disabled.tooltip': 'Easily share exclusive listings with owners and industry peers to streamline collaboration. Upgrade your plan to unlock this feature.',
  'listings.marketplaceIsEmptyTitle': 'Marketplace is empty.',
  'listings.marketplaceIsEmptyDescription': 'Properties available for buying will be displayed here',
  'listings.noMatchesTitle': 'We couldn’t find any matches',
  'listings.noMatchesDescription': 'Try adjusting your filters or search terms to discover more properties.',
  'listings.marketplace': 'Marketplace',
  'listings.menu.marketplace': 'Marketplace',
  'listing.aboutTheProperty': 'About the property',
  'listing.propertyDetails': 'Property Details',
  'listing.moreInsights': 'Need a hand?',
  'listing.disclaimer': 'Due to various factors, including but not limited to data transmission errors or changes in source data, some listings may have incomplete or outdated information. For example, Property taxes are pulled from public records based on prior owners’ ownership and tax designation. When purchasing as an investor, property taxes can be different. We recommend that all potential buyers conduct their due diligence and verify all property details to their satisfaction before making any decisions based on the information provided in listings. Blanket is not responsible for any errors, omissions, or inaccuracies in the listing information. The availability, pricing, sourced data, and specifications of properties listed are subject to change without notice. By accessing and using listings, you acknowledge and agree to these terms and accept that Blanket shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from reliance on information in these listings.',
  'listings.contactAgent': 'Speak with an expert real estate agent to learn more about properties and get professional advice',
  'listings.contactAgent.cta': 'Contact an agent',
  'listings.activitiesEmptyTitle': 'No activities yet',
  'listings.activitiesEmptyDescription': 'All your selling or buying activities will be displayed here',
  'listings.activitiesTitle': 'Deals',
  'listings.interested': 'Interested? Let’s talk.',
  'listings.interested.description1': 'Our real estate experts will walk you through a simple process to get your offer ready.',
  'listings.interested.description2': 'On the call, you’ll learn more about the property, decide on the right offer to make, and get help negotiating the price and terms of the sale. ',
  'listings.interested.description3': 'Remember, you can always follow the status of this property in the Deals tab.',
  'listings.marketplace.inviteProspectsBanner.title': 'Show Your Prospects the Tools That Set You Apart',
  'listings.marketplace.inviteProspectsBanner.buttonText': 'Send the Link',
  'listings.marketplace.preferences.add': 'Add preferences',
  'listings.marketplace.preferences.edit': 'Edit preferences',
  'listings.marketplace.preferences': 'Investment Planner',
  'listings.marketplace.preferences.successConfirm': 'Thank you for trusting us with your investing needs!',
  'listings.marketplace.preferences.description': 'Set your buying and selling preferences',
  'listings.marketplace.wantToSell': 'Looking to sell?',
  'listings.marketplace.wantToSell.description': 'We’ll help you set everything up and find the perfect buyer for your property',
  'listings.marketplace.wantToSell.cta': 'Schedule a call',
  'listings.marketplace.crime.title': 'Crime Reports',
  'listings.marketplace.crime.details': 'Details',
  'listings.marketplace.crime.desc.part1': 'This block has a higher crime report rate than',
  'listings.marketplace.crime.desc.part2': 'of blocks in the County.',
  'listings.marketplace.crime.modal.county': 'County',
  'listings.marketplace.crime.modal.national': 'National',
  'listings.marketplace.crime.desc.national.part2': 'of blocks in the US.',
  'listings.marketplace.school.title': 'Schools - Percentile',
  'listings.marketplace.school.details': 'Details',
  'listings.marketplace.school.desc': 'The closest schools are above average.',
  'listings.marketplace.school.data-provider': 'School rankings provided by Pitney Bowes.',
  'listings.marketplace.successPopup.title': 'We`re on it!',
  'listings.marketplace.successPopup.description': 'One of our experts will contact you soon to help you get your offer ready.',
  'listings.marketplace.sortOption.capRate': 'Cap Rate',
  'listings.marketplace.sortOption.grossYield': 'Gross Yield',
  'listings.marketplace.sortOption.askingPriceHtL': 'Asking Price: High to Low',
  'listings.marketplace.sortOption.askingPriceLtH': 'Asking Price: Low to High',
  'listings.marketplace.sortOption.topPicks': 'Top Picks',
  'listings.marketplace.sort': 'Sort:',
  'listings.marketplace.noMoreListings': 'No more listings to load.',
  'listings.grossYield.tooltip': 'Gross Yield is the annual rent divided by the property price — before expenses. It’s a quick way to estimate potential income.',
  'listings.capRate.tooltip': 'Cap Rate is the Net Operating Income (NOI) divided by the property price. It shows your expected return after expenses, not including financing.',
  'obp.owners-benefits': 'Owners benefits',
  'obp.discount-title': 'All the services you need, in one place',
  'obp.discount-description': 'Grow and optimize your property’s performance with access to all the services you need',
  'obp.get.started': 'Get Started',
  'obp.read-more': 'Read More',
  'obp.successFormMessage': 'If you have any questions or want to chat about real estate investing, our experts are just a click away and ready to help.',
  'updateProperties.whereDidWeGetThisInfo.label': 'Where did we get this data?',
  'updateProperties.whereDidWeGetThisInfo.tooltip': 'This property information is automatically generated from public records. Don’t worry - your info is completely safe with us',
  'dashboard.welcomeTooltip.title': 'Welcome to your dashboard! Where you will find all the important details about your properties.',
  'dashboard.welcomeTooltip.text': 'To get accurate data, you may need to manually update some expenses by clicking on the “ADD” buttons below.',
  'annualReport.previewReport': 'Preview Report',

  'annualReport.previewReport.leadership.summarizeTitle': 'How to write your leadership summary?',
  'annualReport.previewReport.leadership.summarizeSuccessesTitle': 'Summarize Successes with Impactful Data: ',
  'annualReport.previewReport.leadership.summarizeSuccessesDescription': 'Property managers achieved portfolio growth, maintained high occupancy rates, and enhanced tenant retention.Efficiency improvements and cost-saving initiatives have further solidified operational success.',
  'annualReport.previewReport.leadership.acknowledgeHurdlesTitle': 'Acknowledge Hurdles and Responsive Actions: ',
  'annualReport.previewReport.leadership.acknowledgeHurdlesDescription': 'Rising maintenance costs and shifting tenant demands posed challenge Quick adaptations, including improved workflows and technology upgrades, ensured service quality remained high.',
  'annualReport.previewReport.leadership.setClearVisionTitle': 'Set a Clear Vision for Future Growth: ',
  'annualReport.previewReport.leadership.setClearVisionDescription': 'The focus shifts to sustainable practices, strengthening client relationships, and expanding property retention efforts. Adaptability and innovation will drive continued success in the coming year.',
  'annualReport.previewReport.leadership.exampleTitle': 'Example: ',
  'annualReport.previewReport.leadership.exampleDescription': ' Rental laws may tighten with proposed rent control bills. Renter demand will stay segmented — suburban single-family homes see strong interest, while urban condos lag. Rents are expected to remain flat, with ownership costs rising moderately. While challenges persist, focusing on long-term stability is key.',

  'annualReport.TotalSent': 'Total Sent',
  'annualReport.owner.subtitle': 'See how your properties performed this year.',
  'annualReport.owner.button': 'View My Report',
  'annualReport.owner.ClickedReport': 'Total Open',
  'annualReport.owner.SummaryPlaceholder': 'Example: Rental laws may tighten with proposed rent control bills. Renter demand will stay segmented—suburban single-family homes see strong interest, while urban condos lag. Rents are expected to remain flat, with ownership costs rising moderately. While challenges persist, focusing on long-term stability is key.',
  'annualReport.owner.noOwnerSelected': 'Please select at least one owner to send the annual report.',
  'annualReport.owner.noSummarySet': 'Please add a Leadership Summary before sending the annual report. This provides essential context for your owners and enhances the reports value.',
  'annualReport.pm.title': 'Send Annual Reports to Your Owners',
  'annualReport.pm.subtitle': 'Provide clear property performance insights in minutes.',
  'annualReport.pm.button': 'Start Generating Reports',
  'annualReportPdf.header.annual': 'Annual Investor',
  'annualReportPdf.header.report': 'Report',
  'annualReportPdf.header.year': '2024',
  'pm-dashboard.settings.proformaDefaults.title': 'Default Proforma Assumptions',
  'pm-dashboard.settings.proformaDefaults.description': 'Set default assumptions for proformas. Changes apply immediately to all owners unless manually overridden.',
  'pm-dashboard.owner.name': 'Name',
  'pm-dashboard.owner.email': 'Email',
  'pm-dashboard.owner.annualReport2024': 'Annual Report 2024',
  'pm-dashboard.owner.phone': 'Phone',
  'pm-dashboard.owner.nonSfrSelected': 'This feature is available only for Single-Family Rental (SFR) owners. Select applicable owners to proceed',
  'pm-dashboard.owner.reportSent': 'Report Sent',
  'pm-dashboard.owner.activated-owners': 'Activated Owners',
  'pm-dashboard.owner.invited-owners': 'Invited Owners',
  'pm-dashboard.owner.conversion-rate': 'Conversion Rate',
  'click-through-rate': 'Click through rate',
  'pm-dashboard.owner.confirmation': 'We will email the property owner an invite to Blanket.',
  'pm-dashboard.owner.search': 'Search Owner',
  'pm-dashboard.teamMember.name': 'Name',
  'pm-dashboard.teamMember.email': 'Email',
  'pm-dashboard.teamMember.phone': 'Phone',
  'pm-dashboard.teamMember.roles': 'Roles',

  'pm-dashboard.report.confirmationTitle': 'Confirm Annual Report Sending',

  'pm-dashboard.report.confirmationDescription': 'The annual report will be sent to the owner. This step helps strengthen owner relationships with key property insights. Proceed?',

  'pm-dashboard.prospect.messageSuccess': 'An email invite to prospect users have been sent',
  'pm-dashboard.prospect.search': 'Search Prospects',
  'pm-dashboard.teamMember.messageSuccess': 'An email invite has been sent',
  'pm-dashboard.network.messageSuccessInviteNewPartner': 'An invitation email has been sent to the partner, you can find their details in the "Network" page',
  'pm-dashboard.teamMember.search': 'Search Team Members',
  'pm-dashboard.prospect.copyLink': 'Link copied! Share it with your prospects. New sign-ups will appear in your Leads page under Prospects.',
  'pm-dashboard.prospect.inviteProspect': 'Invite Prospect',
  'pm-dashboard.prospect.inviteProspects': 'Bulk Invite',
  'pm-dashboard.owners.sendReport': 'Send Report',
  'pm-dashboard.owners.addLeadershipSummary': 'add leadership summary',
  'pm-dashboard.teamMember.inviteTeamMember': 'Invite your team members',
  'pm-dashboard.teamMember.enterValidEmail': 'Please enter a valid email address.',
  'pm-dashboard.teamMember.enterValidFirstName': 'First name is required',
  'pm-dashboard.teamMember.enterValidLastName': 'Last name is  required',
  'pm-dashboard.prospect.invite': 'Invite',
  'pm-dashboard.teamMember.invite': 'Invite',
  'pm-dashboard.teamMember.inviteDescription': 'Add a user by filling out the following information',
  'pm-dashboard.prospects.importSuccess': 'List submitted successfully! We’re processing your prospects and will have them ready for you soon.',
  'pm-dashboard.prospects.invalidProspectsPart1': 'This file contains ',
  'pm-dashboard.prospects.invalidProspectsPart2': ' invalid prospect(s). Please fix and upload the file again.',
  'pm-dashboard.prospects.prospectsWillBeInvited': ' prospect(s) will be invited to the marketplace.',
  'pm-dashboard.prospects.dragAndDrop': 'Drag & drop CSV file here, or click to select file',
  'pm-dashboard.prospects.inviteProspects': 'Invite Prospects',
  'pm-dashboard.prospects.inviteDescription': 'To invite prospects to the marketplace please download the template and upload it with prospect details',
  'pm-dashboard.prospects.downloadTemplate': 'Download Template',
  'pm-dashboard.prospect.activated-prospects': 'Activated Prospects',
  'pm-dashboard.prospect.invited-prospects': 'Invited Prospects',
  'pm-dashboard.properties.search': 'Search Property',
  'pm-dashboard.properties.comingSoon': 'Properties overview coming soon...',
  'pm-dashboard.marketplace.comingSoon': 'Marketplace overview coming soon...',
  'pm-dashboard.performance-review.intro.title': 'How to conduct a Performance Review Session?',
  'pm-dashboard.performance-review.intro.item1': 'Update property data and missing expenses',
  'pm-dashboard.performance-review.intro.item2': 'Complete Investor Planner',
  'pm-dashboard.performance-review.intro.item3': 'Go over Dashboard – Focus on Portfolio/Property value, Equity, Cashflow, and Cash-on cash return. If you think your client should sell their property or take advantage of high levels of equity, recommend to 1031 exchange or refinance cash out by clicking “Exchange” or “Refinance”',
  'pm-dashboard.performance-review.intro.item4': 'Go over Marketplace – Filter based on Investment Planner inputs and choose 5 properties to mark as favorites. If the client wants to buy a property, choose the best one for him, open its listing page, go over the performance of that property with him, and click on "I’m Interested" to start the buying process',
  'pm-dashboard.performance-review.intro.item5': 'Go over Owner Benefits – Ask if any services are needed and recommend those that can help grow/optimize your client’s portfolio/property',
  loginAs: 'Login as',
  loggedInAs: 'Logged in as',
  inviteOwners: 'Invite Owners',
  inviteOwnerByEmail: 'Invite Owner By Email',
  inviteOwnerBySMS: 'Invite Owner By SMS',
  areYouSure: 'Are you sure?',
  areYouSureOwnerLead: 'Are you sure? We will refer',
  successCreatePmOwnerLeadMessage: 'An email with the lead details was sent to',
  'user.phone.input': 'Enter phone number',
  'user.smsConsent': 'By checking this box, I agree to receive calls and SMS notifications about updates, offers, and promotions.',
  'leads.new': 'New',
  'leads.emailCopied': 'Email copied to clipboard',
  'leads.selectStatus': 'Select status',
  'leads.call.success': 'Your call request has been added to the queue, and you will be contacted soon.',
  'leads.leadsThirdPartyIntegrationEnabled': 'You’re using a Zapier integration. All status changes in the connected app will be reflected here.',
  'leads.leadsThirdPartyIntegrationEnabled.one': 'Easily manage leads with third-party tools like LeadSimple.',
  'leads.leadsThirdPartyIntegrationEnabled.two': 'Connect now and streamline your workflow!',
  'leads.thirdPartyIntegrationRequested': 'Thank you! Your request has been sent to the team.',
  'pm-dashboard.leads.inProgress': 'In Progress',
  'pm-dashboard.leads.won': 'Won',
  'pm-dashboard.leads.lost': 'Lost',
  'pm-dashboard.leads.new.tooltip': 'New owner leads from you referral partners (Agents, Property Managers, Owners, and more)',
  'pm-dashboard.leads.inProgress.tooltip': 'Move new owner leads to this stage after contacting the lead and deciding on next steps',
  'pm-dashboard.leads.won.tooltip': 'Owner leads that have signed the property management agreement',
  'pm-dashboard.leads.lost.tooltip': 'Owner leads that decided not to continue the process',
  'pm-dashboard.leads.attemptedContact': 'Attempted Contact',
  'pm-dashboard.leads.spokeWithLead': 'Spoke With Lead',
  'pm-dashboard.leads.appointmentSet': 'Appointment Set',
  'pm-dashboard.leads.metWithLead': 'Met With Lead',
  'pm-dashboard.leads.activeListing': 'Active Listing',
  'pm-dashboard.leads.offerSubmitted': 'Offer Submitted',
  'pm-dashboard.leads.offerAccepted': 'Offer Accepted',
  'pm-dashboard.leads.showingProperties': 'Showing Properties',
  'pm-dashboard.leads.submittingOffers': 'Submitting Offers',
  'pm-dashboard.leads.underContract': 'Under Contract',
  'pm-dashboard.leads.sold': 'Sold',
  'pm-dashboard.leads.rejected': 'Rejected',
  'pm-dashboard.leads.propertyChosen': 'Property Chosen',
  'pm-dashboard.leads.requestRentalAnalysis': 'Rental Analysis',
  'pm-dashboard.leads.requestRentalAnalysis.tooltip': 'Lead stated they would like to receive a rental analysis',
  'cashOut.question.name': 'Choose the way you want to Cash Out',
  'cashOut.answer.sell': 'Sell',
  'cashOut.answer.refinanceCashOut': 'Refinance Cash Out',
  'cashOut.answer.exchange1031': '1031 Exchange',
  'cashOut.answer.chooseYourLender': 'Explore Lender Options',
  'cashOut.answer.chooseYourLender.description': 'Click "Next" to view trusted lenders on the Owner Benefits page. Compare offers, choose the best fit, and move forward with refinancing or cashing out easily.',
  'cashOut.answer.pickYourExchange1031': 'Pick your 1031 Exchange',
  'pm-dashboard.blanket-oversight.portfolioValue.tooltip': 'The total market value of all properties owned by activated owners',
  'pm-dashboard.blanket-oversight.owners.tooltip': 'Owner users who are active users on our platform out of total number of owners invited',
  'pm-dashboard.blanket-oversight.prospects.tooltip': 'Prospect users who are active users on our platform out of total number of prospects invited',
  'dashboard.ownerSurvey': 'Owner survey',
  'pm-dashboard.blanket-overview.portfolioValue.tooltip': 'The total market value of all properties owned by activated owners',
  'pm-dashboard.blanket-overview.owners.tooltip': 'Owner users who are active users on our platform out of total number of owners invited',
  'pm-dashboard.blanket-overview.prospects.tooltip': 'Prospect users who are active users on our platform out of total number of prospects invited',
  'pm-dashboard.contacts.performanceReview.tooltip': 'Start Owner Performance Review Session',
  'pm-dashboard.ownerSentimentDialog.title': 'Share Your Feedback',
  'pm-dashboard.ownerSentimentDialog.description': 'Before continuing, rate your relationship with this client. Your feedback helps us fine-tune our AI to give you smarter, more relevant suggestions.',
  'pm-dashboard.ownerSentimentDialog.submit': 'Submit',

  always: 'Always',
  never: 'Never',
  setup: 'Setup',

  'retention.widget.riskBreakdown.title': 'Risk Breakdown',
  'retention.widget.riskBreakdown.tooltip': '',
  'retention.searchOwners': 'Search Owners',
  'retention.createPolicy': 'Create Policy',
  'retention.editRetentionPolicy': 'Edit Retention Policy',
  'retention.pleaseSetYourPreferences': 'Please set your preferences to analyze your portfolio risk',
  'retention.blanketScore': 'Blanket Score™',
  'retention.portfolioRisk': 'Portfolio Risk',
  'retention.portfolioHealth.attempted': 'Attempted',
  'retention.portfolioHealth.retained': 'Retained',
  'retention.portfolioHealth.retentionRate': 'Retention Rate',
  'retention.policy': 'Retention Policy',
  'retention.setup': 'Setup Policy',
  'retention.edit': 'Edit Policy',
  'retention.action': 'Action',
  'retention.issues': 'issues',
  'retention.issue': 'issue',
  'retention.resolve': 'Resolve',
  'retention.viewOwner': 'View Owner',
  'retention.moveToAttentionNeeded': 'Move to Attention Needed',
  'retention.moveToAtRisk': 'Move to "At Risk"',
  'retention.moveToAttentionNeededSuccess': 'Moved to the "At Risk" status successfully.',
  viewOwnerDashboard: 'View Owner Dashboard',
  'retention.resolve.reason.ownerWantsToStay': 'Owner wants to stay 😍',
  'retention.resolve.reason.ownerWantsToSell': 'Owner wants to sell 🏡',
  'retention.resolve.reason.ownerWantsToLeave': 'Owner wants to leave 😢',
  'retention.resolve.reason.other': 'Other',
  'retention.resolve.question': 'What is the resolution reason?',
  'retention.resolve.addTheReason': 'Add the reason',
  'retention.table.owner': 'Owner',
  'retention.table.issuesFound': 'Issues Found',
  'retention.table.clientValue': 'Client Value',
  'retention.table.clientValue.tooltip': 'The total monthly rent across all properties in the client’s portfolio.',
  'retention.table.riskScore': 'Risk Score',
  'retention.table.riskScore.tooltip': 'A calculated risk level for this portfolio, determined by our AI algorithm.',
  'retention.table.updated': 'Updated',
  'retention.table.priority': 'Priority',
  'retention.table.risk': 'Risk',
  'retention.table.resolutionStatus': 'Resolution Status',
  'retention.table.priority.tooltip': 'An overall priority score for the portfolio, considering client value, risk, and loyalty.',
  'retention.setupRetentionPolicy.resetToDefault': 'Reset to blanket default',
  'retention.setupRetentionPolicy.riskDetectionSensitivity': 'How sensitive should risk detection be?',
  'retention.setupRetentionPolicy.maximumIncentiveQuestion': 'What is the maximum financial incentive you are willing to give to retain a homeowner?',
  'retention.setupRetentionPolicy.maximumIncentiveQuestion.threeMonths': 'ZERO Management Fee for 3 Months',
  'retention.setupRetentionPolicy.maximumIncentiveQuestion.twoMonths': 'ZERO Management Fee for 2 Months',
  'retention.setupRetentionPolicy.maximumIncentiveQuestion.oneMonth': 'ZERO Management Fee for 1 Month',
  'retention.setupRetentionPolicy.maximumIncentiveQuestion.halfMonth': 'ZERO Management Fee for 0.5 Month',
  'retention.setupRetentionPolicy.maximumIncentiveQuestion.noIncentive': 'We don’t give incentives',
  'retention.setupRetentionPolicy.maximumIncentiveQuestion.blanketBenchmark': 'Blanket Benchmark: “A 1-month fee waiver can have a high impact on the owner’s satisfaction”',
  'retention.setupRetentionPolicy.financialIncentiveQuestion': 'Which of your homeowners would you be willing to give a concession to?',
  'retention.setupRetentionPolicy.meetWillingnessQuestion': 'Which of your homeowners would you be willing to meet with?',
  'retention.setupRetentionPolicy.badOwnerMitigationQuestion': 'What is your preferred method of dealing with disappointed homeowners that are bad for your business?',
  'retention.setupRetentionPolicy.badOwnerMitigationQuestion.fireOwner': 'Fire them',
  'retention.setupRetentionPolicy.badOwnerMitigationQuestion.refer': 'Refer them to a different company',
  'retention.setupRetentionPolicy.badOwnerMitigationQuestion.sell': 'Recommend selling their property',
  'retention.setupRetentionPolicy.badOwnerMitigationQuestion.increaseMgmtFee': 'Increase management fee',
  'retention.setupRetentionPolicy.badOwnerMitigationQuestion.noMitigation': 'Do nothing',
  'retention.setupRetentionPolicy.goodOwnerMitigationQuestion': 'What is your preferred method of dealing with good homeowners with a bad-performing property?',
  'retention.setupRetentionPolicy.goodOwnerMitigationQuestion.recommendToSell': 'Recommend selling their property',
  'retention.setupRetentionPolicy.goodOwnerMitigationQuestion.1031': 'Recommend to 1031 Exchange their property',
  'retention.setupRetentionPolicy.goodOwnerMitigationQuestion.fix': 'Renovate/Fix the issues in the property',
  'retention.setupRetentionPolicy.goodOwnerMitigationQuestion.offerIncentives': 'Offer incentives',
  'retention.setupRetentionPolicy.goodOwnerMitigationQuestion.noMitigation': 'Do nothing',
  'retention.setupRetentionPolicy.willingnessOptions.anyHomeowner': 'Any homeowner',
  'retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithHighPriority': 'Only homeowners of high priority',
  'retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithMediumPriorityAndAbove': 'Only homeowners of medium priority and above',
  'retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithLowPriorityAndAbove': 'Only homeowners of low priority and above',
  'retention.setupRetentionPolicy.willingnessOptions.none': 'None',
  'retention.setupRetentionPolicy.summaryLengthOptions.concise': 'Concise',
  'retention.setupRetentionPolicy.summaryLengthOptions.standard': 'Standard',
  'retention.setupRetentionPolicy.summaryLengthOptions.detailed': 'Detailed',
  'retention.setupRetentionPolicy.summaryLengthOptions.question': 'Choose how much detail you want in retention emails',
  'retention.setupRetentionPolicy.summaryLengthOptions.tooltip.concise': 'Concise – A brief, high-level summary of the property status and recommended action',
  'retention.setupRetentionPolicy.summaryLengthOptions.tooltip.standard': 'Standard – A balanced breakdown with key details and reasoning behind the recommendation',
  'retention.setupRetentionPolicy.summaryLengthOptions.tooltip.detailed': 'Detailed – A full explanation, including trends over time and a thorough rationale for the suggested action.',
  'retention.setupRetentionPolicy.writingStylesQuestion': 'Select your preferred writing style',
  'retention.tab.healthy': 'Healthy',
  'retention.tab.noRisk': 'No risk',
  'retention.tab.new': 'Needs attention',
  'retention.tab.atRisk': 'At risk',
  'retention.tab.inProgress': 'In progress',
  'retention.tab.resolved': 'Resolved',
  'retention.reopen': 'Reopen',
  'retention.view': 'View',
  'retention.severity': 'Severity',
  'retention.act.createPhoneCallScript': 'Create a phone call script',
  'retention.act.generateEmail': 'Generate an email',
  'retention.act.regenerate': 'Regenerate',
  'retention.act.generateEmailTitle': 'Blanket AI Generated Email',
  'retention.act.analyzingData': 'Analyzing data...',
  'retention.act.results': 'Results',
  'retention.advantages': 'Advantages',
  'retention.disadvantages': 'Disadvantages',
  'retention.occupancy': 'Occupancy',
  'retention.recommendation': 'Recommended next steps',
  'retention.recommendation.tellUsMore': 'Tell us more:',
  'retention.recommendation.feedbackText1': 'Don’t like the style',
  'retention.recommendation.feedbackText2': 'It’s incorrect',
  'retention.recommendation.feedbackTextOther': 'Other..',
  'retention.recommendation.feedbackSubmitted': 'Feedback submitted, we are on it!',
  'retention.moreActions': 'More actions',
  'retention.totalClientValue': 'Total Client Value',
  'retention.monitoredProperties': 'Monitored Properties',
  'retention.resolvedDate': 'Resolved Date',
  'retention.resolvedReason': 'Resolved Reason',
  'retention.risks': 'Risks',
  'retention.data': 'Data',
  'retention.pmPortfolioAvg': 'PM’S Portfolio Average',
  'retention.propertyValue': 'Property Value',
  'retention.vacant': 'Vacant',
  'retention.generateEmail.send': 'Send',
  'retention.generateEmail.copyAndSave': 'Copy and save',
  'retention.generateEmail.regenerate': 'Regenerate',
  'retention.generateEmail.suggestion': 'Suggestion',
  'retention.act.discount1': 'Discount 1 month',
  'retention.act.discount2': 'Discount 2 months',
  'retention.act.discount3': 'Discount 3 months',
  'retention.act.pushTo1031': 'Push to 1031',
  'retention.act.pushToSell': 'Push to Sell',
  'retention.act.attachValueReport': 'Attach Value Report', // TODO: Remove after migration
  'retention.act.attachPreformaReport': 'Attach Preforma Report', // TODO: Remove after migration
  'retention.act.sendGift': 'Send Gift', // TODO: Remove after migration
  'retention.act.linkToProforma': 'Link to Proforma',
  'retention.act.linkToProforma.tooltip': 'To edit the Proforma click on the button below',
  'retention.act.linkToInvestorPlatform': 'Link to Investor Dashboard',
  'retention.act.linkToInvestorPlatform.tooltip': 'To view the Investor Dashboard click on the button below',
  'retention.act.scheduleMeeting': 'Schedule Meeting',
  'retention.act.copiedToClipboard': 'Copied to clipboard',
  'retention.act.loadingPhrase1': 'Fetching homeowner data...',
  'retention.act.loadingPhrase2': 'Calculating investment metrics...',
  'retention.act.loadingPhrase3': 'Predicting future trends...',
  'retention.act.loadingPhrase4': 'Simulating outcomes...',
  'retention.act.loadingPhrase5': 'Generating recommendations...',
  'retention.act.theAnalyzedResults': 'Your Blanket AI Powered Results',
  'retention.act.noPositiveInsights': 'No Advantages Found',
  'retention.act.noNegativeInsights': 'No Disadvantages Found',
  'retention.generateEmail.features': 'Features',
  'retention.tooltip.howCalculated': 'How Is This Insight Calculated?',
  'retention.tooltip.blanketScore': 'The Average Risk Score represents the probability of an owner to churn by leaving or selling their property. This is an AI-Powered Algorithm that takes into account risks like vacancy and negative cashflow against positive property aspects like high appreciation and cashflow',
  'retention.tooltip.portfolioHealth.attempted': 'Owners currently in the retention process.',
  'retention.tooltip.portfolioHealth.retained': 'Successfully retained owners who did not churn.',
  'retention.tooltip.portfolioHealth.retentionRate': 'Percentage of successfully retained owners out of all resolved cases.',
  'retention.tooltip.priority': 'The Priority status helps you focus on the accounts that matter the most by combining the Owner Risk Score with the Client Value. For example: A high Client Value and a high Owner Risk Score will set the Priority as "High"',
  'retention.tooltip.totalClientValue': 'This amount represents the total rent value of all the properties that need attention',
  'retention.tooltip.monitoredProperties': 'This number represents all the properties that are currently monitored in real-time by Blanket. Currently, we only support Single-Family Rental properties with up to 4 units',
  'retention.tooltip.setupRetention': 'The Retention Policy setup will help Blanket’s AI Engine to offer the best ways to deal with properties that need your attention',
  'retention.tooltip.verifiedOwner': 'Clients with the verified badge represent owners that have activated their Blanket account',
  'retention.tooltip.viewInsights': 'View Insights',
  'retention.insight.missingData.title': 'Data Update in Progress',
  'retention.insight.missingData.description': 'Some data for this property is still on its way. We’re actively working on bringing you the full picture soon!',
  'retention.insight.lease.tooltip': 'When a lease nears expiration, owner churn risk rises due to potential vacancy, turnover costs, or property performance concerns. Poor unit condition at lease end can further prompt owners to sell or switch management.',
  'retention.widget.riskBreakdown.tooltip.title': 'This chart shows the total client value assigned to each risk stage',
  'retention.widget.riskBreakdown.tooltip.noRisk': 'No retention risks.',
  'retention.widget.riskBreakdown.tooltip.atRisk': 'Potential churn detected',
  'retention.widget.riskBreakdown.tooltip.inProgress': 'Retention efforts underway.',

  'metric.context.cashflow': '$currency Cashflow In $months Months',
  'metric.context.noi': '$currency NOI in $months Months',
  'metric.context.maintenance': '$currency Maintenance in $months Months',
  'metric.context.income': '$currency Income in $months Months',
  'metric.context.managementFee': '$currency Management Fee in $months Months',
  'metric.context.maintenance.events': '$number Maintenance Events in $months Months',
  'metric.context.months.rent': '$number Months Rent in $months Months',

  'metric.appreciation': '$currency Appreciation',
  'metric.averageAppreciation': '$currency Average Appreciation Per Month',

  'metric.current.vacancy': '$number Months Vacant',
  'metric.months.to.end.lease': '$number Months to end lease',
  'metric.turnover.tenants': '$number Turnover tenants is the last year',
  'insight.name.cashflow': 'Cash flow',
  'insight.name.currently.vacancy': 'Vacancy',
  'insight.name.noi': 'NOI',
  'insight.name.maintenance': 'Maintenance',
  'insight.name.turnover.tenants': 'Turnover Tenants',
  'insight.name.management.fee': 'Management Fee',
  'insight.name.number.of.months.rent': 'Months Rent',
  'insight.name.maintenance.events': 'Maintenance Events',
  'insight.name.months.to.end.lease': 'Months To End Lease',
  'insight.name.appreciation': 'Appreciation',
  vacancy: 'Vacancy',
  'insight.name.cashflow.tooltip': 'Shows the potential cash on cash return on investment if the property was purchased at today’s market value. This helps owners understand how their property performs in the current market. Formula: Annual Cash Flow / Current Market Value',
  'insight.name.noi.tooltip1': 'NOI measures profitability by subtracting operating expenses from rental income, excluding taxes, financing, and depreciation.',
  'insight.name.noi.tooltip2': 'Formula: NOI = Gross Rental Income - Operating Expenses (maintenance, management fees, insurance, utilities, etc.)',
  'insight.name.noi.tooltip3': 'Higher NOI signals better profitability.',
  'insight.name.noi.tooltip': 'Evaluates Net Operating Income relative to property valuation, highlighting profitability or concerns',
  'insight.name.maintenanceCost.tooltip': 'Maintenance-to-Income Ratio shows how maintenance costs stack up against rental income. High ratios may point to recurring issues or aging properties; low ratios suggest efficient upkeep and healthier property performance.',
  'insight.name.managementFee.tooltip': 'Tracks all management-related fees charged by the property management company. This includes management fees, tenant placement, legal fees, renewal, late fees, and other charges. It gives a clear breakdown of the actual costs paid by the owner.',
  'insight.name.tenantsTurnover.tooltip': 'Monitors tenant turnover rates, identifying potential stability issues or high turnover concerns',
  'insight.name.tenantsTurnover.tooltip.item1': 'Tracks how often tenants are replaced. High turnover can result in lost rent, added costs (e.g. marketing, cleaning), and owner frustration from frequent changes.',
  'insight.name.lease.tooltip': 'Assesses the remaining lease term, identifying potential risks related to short-term lease expirations',
  'insight.name.maintenanceEvents.tooltip': 'Tracks the number of maintenance events and work orders over time. Frequent maintenance may indicate underlying property issues or simply cause owner frustration due to ongoing expenses and disruptions.',
  'insight.name.vacancy.tooltip': 'Measures how often a property sits empty during a given period. High vacancy rates may signal pricing issues, market conditions, or other reasons.',
  'insight.name.appreciation.tooltip': 'Measures how much the property\'s value has increased relative to its original purchase price. This gives a clear view of the property\'s growth and overall return on investment over time.',
  'retention.ownerRisk.movedToInProgressNotice': 'The owner’s risk status is now marked as "In Progress." You can revert this change at any time if needed.',
  'retention.ownerRisk.movedToNeedsAttention': 'Moved to the "Needs Attention" status successfully.',
  'retention.act.impersonate': 'View owner dashboard',
  'retention.act.impersonateForProforma': 'Edit Proforma',
  'retention.noPotentialRisks': 'No owners are currently flagged as at risk. Stay proactive by working on those in progress and reviewing financial insights to prevent potential churn.',
  'retention.noHealthy': 'No owners are currently in a healthy state. Focus on resolving at-risk cases to bring them back to stability.',
  'retention.noInProgress': 'No ongoing risk cases at the moment. Keep monitoring owner activity and address potential risks early to maintain stability.',
  'retention.noResolved': 'No recently resolved cases. Work on owners in progress to resolve risks and keep your portfolio healthy.',
  'retention.resolve.reason.selectReason': 'Please select a reason',
  'growth.searchPartners': 'Search Partners',
  'growth.partners': 'Partners',
  'growth.referredLeads': 'Referred Leads',
  'growth.noPartnersFound': 'No partners found.',
  'growth.table.partner': 'Partner',
  'growth.table.leads': 'Leads',
  'growth.table.dateAdded': 'Date Added',
  'growth.table.clients': 'Clients',
  'growth.table.partnerType': 'Partner Type',
  'growth.table.clientEarned': 'Partner Earnings',
  'retention.table.activity': 'Activity',
  'partners.noMorePartners': 'Engage with current opportunities and stay ready for new ones!',
  'partners.partnerEarnings': 'Partner Earnings',
  'partners.referBackUponBuy': 'Refer back buyer lead',
  'partners.referBackUponSell': 'Refer back seller lead',
  'partners.card.activityLevel': 'Activity Level',
  'partners.card.yearsOfExperience': 'Years of Experience',
  'partners.card.priceRange': 'Price Range',
  'partners.card.midRange': 'Mid Range',
  'partners.card.workingCounties': 'Operating Counties',
  'partners.card.yearsInBusiness': 'Years in Business',
  'partners.card.propertyTypes': 'Property Types',
  'partners.card.doorCount': 'Door Count',
  'partners.searchPartner': 'Search',
  'partners.status.suggested': 'Suggested',
  'partners.status.inProgress': 'In progress',
  'partners.status.notRelevant': 'Not relevant',
  'partners.wizard': 'Referral Policy',
  'partners.add.email': 'Email',
  'partners.add.title': 'Add a Partner to Your Network',
  'owner.summaryTitle': 'Leadership Summary: Add Your Personal Touch to the Annual Report.',
  'partners.add.firstName': 'First Name',
  'partners.add.lastName': 'Last Name',
  'partners.add.referralAmount': 'Referral Amount',
  'partners.add.category': 'Category',
  'partners.add.category.agent': 'Agent',
  'partners.add.category.owner': 'Owner',
  'partners.add.category.contractor': 'Contractor',
  'partners.add.category.lender': 'Lender',
  'partners.add.referralAmount.errorText': 'Referral amount is required',
  'partners.wizard.referralProgram': 'Referral Program',
  'partners.wizard.referralProgram.ownerReferralFee.disclaimer': 'Please be aware that paying a referral fee to non-licensed parties is prohibited in certain States',
  'partners.wizard.ownerLeadProfile': 'Owner Lead Profile',
  'partners.wizard.referralLandingPage': 'Referral Page Setup',
  'partners.noPartnersFound': 'No opportunities to work on yet.',
  'partners.moveToNotRelevant': 'Move to Not Relevant',
  'partners.addToMyNetwork': 'Invite',
  'partners.contact': 'Contact',
  'partners.contact.copyAndClose': 'Copy and close',
  'partners.contact.copyAndClose.success': 'The email has been copied to the clipboard. Please note that the partner has moved to the "In Progress" status.',
  'partners.add.error.partnerAlreadyExists': 'This partner already exists',
  'partners.successStatusChange': 'Partner moved successfully',
  'partners.agents.disabled.title': 'Grow Your Referral Network',
  'partners.agents.disabled.subtitle': 'Unlock connections with top agents nearby and gain quality leads for faster growth',
  'partners.agents.disabled.button': 'Expand Your Network',
  'partners.pms.disabled.title': 'This feature is disabled',
  'partners.pms.disabled.subtitle': 'Please contact us to unlock it.',

  'partner.propertyType.singleFamily': 'Single Family',
  'partner.propertyType.townHome': 'Townhome',
  'partner.propertyType.smallMf': 'Small Multi-Family',
  'partner.propertyType.multiFamily': 'Multi-Family',
  'partner.propertyType.condo': 'Condo',
  'partner.propertyType.section8': 'Section 8',
  'partner.propertyType.commercial': 'Commercial',
  'partner.propertyType.house': 'House',
  'partner.propertyType.lot': 'Lot',
  'partner.propertyType.manufactured': 'Manufactured',
  'partner.dateAdded.tooltip': 'The date this partner was added to your network.',
  'partner.leads.tooltip': 'The total number of leads referred by this partner.',
  'partner.clients.tooltip': 'The number of leads referred by this partner that successfully became your clients.',
  'partner.earnings.tooltip': 'The total earnings this partner has received from referral fees for successful conversions.',

  'partners.pm.confirmAddToNetwork': 'Confirm Add to Network',
  'partners.pm.confirmAddToNetwork.description': 'Are you sure you want to add this PM to your network?',
  'partners.wizard.question.agentReferralFeeAmount': 'Set your agent referral fee',
  'partners.wizard.question.agentProvidedBenefits': 'Choose the benefits you will provide to agent partners',
  'partners.wizard.question.agentProvidedBenefits.placeholder': 'Select benefit',
  'partners.wizard.question.enableOwnerReferralProgram.title': 'Do you want to activate an owner referral program?',
  'partners.wizard.question.enableOwnerReferralProgram.description': 'Activating this program will offer owners the opportunity to refer you leads from multiple areas in the platform',
  'partners.wizard.question.ownerReferralsIncentive.threeMonths': 'ZERO Management Fee for 3 Months',
  'partners.wizard.question.ownerReferralsIncentive.twoMonths': 'ZERO Management Fee for 2 Months',
  'partners.wizard.question.ownerReferralsIncentive.oneMonth': 'ZERO Management Fee for 1 Month',
  'partners.wizard.question.ownerReferralsIncentive.noIncentive': 'We don’t give incentives',
  'partners.wizard.question.ownerReferralsIncentive.placeholder': 'Select incentive',
  'partners.wizard.question.enablePMReferralProgram.description': 'Activating this program allows you to exchange owner leads that do not fit your perfect client profile with other property managers. You’ll pay or receive a referral fee only when a lead converts into a signed property management contract.',
  'partners.wizard.question.pmReferralsFeeAmount': 'Set your property management referral fee',
  'partners.wizard.step2.disclaimer': 'The answers to these questions will allow our data analysis engine to provide you with additional information on new owner leads, helping you save time and make smarter decisions. Plus, it will help Blanket recommend to you who to refer this lead to in case it does not fit your profile.',

  'partners.wizard.question.typeOfPropertiesManaged': 'Type of Properties Managed',
  'partners.wizard.question.doorCount': 'Number of Doors Under Management',
  'partners.wizard.question.yearsInBusiness': 'Years in Business',
  'partners.wizard.question.workingCounties': 'Counties Managed In',
  'partners.wizard.question.manageCommercialProperties': 'Do you also manage commercial properties?',
  'partners.wizard.question.manageSection8Properties': 'Do you manage Section 8 properties?',

  'partners.wizard.question.typeOfPropertiesManaged.singleFamily': 'Single Family (1 unit)',
  'partners.wizard.question.typeOfPropertiesManaged.townhome': 'Townhome',
  'partners.wizard.question.typeOfPropertiesManaged.smallMultiFamily': 'Small Multi-Family (up to 4 units)',
  'partners.wizard.question.typeOfPropertiesManaged.multiFamily': 'Multi-Family (4+ units)',
  'partners.wizard.question.typeOfPropertiesManaged.condo': 'Condo',
  'partners.wizard.question.typeOfPropertiesManaged.section8': 'Section 8',
  'partners.wizard.question.typeOfPropertiesManaged.commercial': 'Commercial',

  'partners.wizard.savedSuccessfully': 'Referral Policy saved successfully.',

  'partners.wizard.question.companyName': 'Company name',
  'partners.wizard.question.companyDescription': 'Company Description',
  'partners.wizard.question.companyDescription.description': 'Showcase your company’s strengths to build trust and attract leads.',
  'partners.wizard.question.pmAvatar': 'Profile Picture',
  'partners.wizard.question.pmAvatar.description': 'Upload a clear profile picture of yourself to help build trust and credibility.',
  'partners.wizard.question.pmAvatar.limit': 'Allowed JPG or PNG. Max size of 800K',
  'partners.wizard.question.pmAvatar.fileTooLarge': 'File too large. Max size of 800K',
  'partners.wizard.question.pmAvatar.upload': 'Select Photo',
  'partners.wizard.question.pmName': 'Full Name',
  'partners.wizard.question.pmFirstName': 'First name',
  'partners.wizard.question.pmLastName': 'Last name',
  'partners.wizard.question.pmTitle': 'Role at company',
  'partners.wizard.companyDetails': 'Company Details',
  'partners.wizard.contactDetails': 'Your Contact Details',
  'partners.wizard.companyMetrics': 'Company Metrics',
  'partners.wizard.question.companyGoogleReviews': 'Avg. Google Score (1-5)',
  'partners.wizard.question.companyAVGDayOnMarket': 'Avg. Days On Market',
  'partners.wizard.question.companyTenantRenewalRate': 'Tenant Renewal Rate',
  'partners.wizard.question.companyGuarantees': 'Our Guarantees',
  'partners.wizard.question.companyServices.marketing': 'Marketing',
  'partners.wizard.question.companyServices.tenantScreening': 'Tenant screening',
  'partners.wizard.question.companyServices.rentCollection': 'Rent collection',
  'partners.wizard.question.companyServices.maintenance': 'Maintenance',
  'partners.wizard.question.companyServices.leasing': 'Leasing',
  'partners.wizard.question.companyServices.eviction': 'Eviction',
  'partners.wizard.question.companyServices.financialReporting': 'Financial reporting',
  'partners.wizard.question.companyServices': 'Our Services',
  'partners.wizard.question.companyGuarantees.leaseGuarantee': 'Lease guarantee',
  'partners.wizard.question.companyGuarantees.petGuarantee': 'Pet guarantee',
  'partners.wizard.question.companyGuarantees.evictionGuarantee': 'Eviction guarantee',
  'partners.wizard.question.companyGuarantees.onTimeRentGuarantee': 'On-time rent guarantee',
  'partners.wizard.question.website': 'Website',
  'partners.setupGrowthPolicy': 'Please set up your referral policy',
  'referral-partners.table.lead': 'Lead',
  'referral-partners.table.email': 'Email',
  'referral-partners.table.status': 'Status',
  'referral-partners.table.lastUpdated': 'Last Updated',
  'referral-partners.add.title': 'Lead Details',
  'referral-partners-to-pm.add.title': 'Complete lead details to refer to partner',
  'referral-partners.add.email': 'Email',
  'referral-partners.add.firstName': 'First Name',
  'referral-partners.add.lastName': 'Last Name',
  'referral-partners.add.address': 'Address',
  'referral-partners.add.propertyAddress': 'Property Address',
  'referral-partners.add.numOfProperties': 'How many properties do you own in our market?',
  'referral-partners.add.numOfProperties.errorText': 'How many properties do you want management services for?',
  'referral-partners.add.phoneNumber': 'Phone Number',
  'referral-partners.add.wouldYouLikeARentalAnalysis': 'Would you like a rental analysis?',
  'referral-partners.copyText': 'Sharable link copied to clipboard!',
  'referral-partners.copyLink': 'Copy Link',
  'referral-partners.inviteDealsText': 'Start creating referrals by using the buttons below',
  'referral-partners.noDealsYet': 'No Referrals Yet',
  'referral-partners.whatAreYouLookingFor.placeHolder': 'What are you looking for?',
  'referral-partners.add.unknown': 'I’m not sure',
  'referral-partners.add.stopSelfManaging': 'Looking to stop self-managing and hire a property manager',
  'referral-partners.add.changePM': 'Looking to change property management company',
  'referral-partners.add.buyInvestmentProperty': 'Looking to buy an investment property in our area',
  'proforma.title': 'Investor Proforma',
  'proforma.annualHomeAppreciation.tooltip': 'The yearly increase in the property’s market value based on historical trends.',
  'proforma.annualRentGrowth.tooltip': 'The yearly percentage increase in rental income based on market conditions.',
  'proforma.estimatedVacancy.tooltip': 'The expected percentage of the rental income lost due to vacancy.',
  'proforma.inflationRate.tooltip': 'The expected general increase in expenses due to inflation.',
  'proforma.managementFee.tooltip': 'The percentage of rental income paid to the property manager for managing the property.',
  'proforma.maintenanceReserve.tooltip': 'The percentage of rental income set aside for routine maintenance and repairs.',
  'proforma.capEx.tooltip': 'The percentage of rental income allocated for major property improvements and replacements.',
  'proforma.monthlyMortgagePayment.tooltip': 'The fixed monthly amount paid towards the mortgage, including principal and interest.',
  'proforma.investmentValueOverTime': 'Est. Investment Value Over Time',
  'proforma.investmentValueOverTime.tooltip.title': 'Track the projected total investment value over time through four key metrics:',
  'proforma.investmentValueOverTime.tooltip.listItems': [
    'Cumulative net cash flow (total profit from rental income after expenses)',
    'Cumulative appreciation (forecasted asset value growth)',
    'Cumulative equity (asset’s portion you own as you pay down the mortgage)',
    'Projected asset value (estimated future market valuation based on appreciation rates)',
  ],
  'proforma.investmentValueOverTime.tooltip.footnote': 'Use these insights to evaluate long-term financial potential and guide reinvestment or portfolio growth decisions.',
  'proforma.equityBuildUp': 'Equity Build Up',
  'proforma.equityBuildUp.tooltip.title': 'Track how your equity builds over time as your loan balance decreases and your property value appreciates:',
  'proforma.equityBuildUp.tooltip.listItems': [
    'Loan balance (remaining mortgage amount)',
    'Equity (portion of the property you fully own)',
    'Property value (estimated market value of your property)',
  ],
  'proforma.equityBuildUp.tooltip.footnote': 'Use these insights to monitor ownership growth, assess leverage, and evaluate the financial health of your investment.',
  'proforma.resetToDefault.title': 'Reset to Default Settings?',
  'proforma.resetToDefault.description': 'This action will clear all changes you’ve made, including both saved and unsaved adjustments. Are you sure you want to proceed?',
  'proforma.editDisabled.tooltip': 'Editing is unavailable in portfolio view because you’re viewing a summary of multiple properties. To edit, select an individual property from the dropdown menu.',
  'proforma.shareConfirm': 'Link copied! Your investor proforma is ready to share.',

  'owner.successMessageSaveSummary': 'Leadership summary saved! You can now select owners and send them their annual reports.',

  'referAnOwner.referralButtonText.noIncentive': 'Refer a friend who owns a property!',
  'referAnOwner.referralButtonText.oneMonth': 'Refer a friend who owns a property and get 1 month management fee ',
  'referAnOwner.referralButtonText.twoMonths': 'Refer a friend who owns a property and get 2 months management fee ',
  'referAnOwner.referralButtonText.threeMonths': 'Refer a friend who owns a property and get 3 months management fee ',
  'referAnOwner.referralButtonText.free': 'FREE!',
  'referAnOwner.referAndEarn': 'Invite & Earn Rewards',
  'referAnOwner.ownerLead.ownerLeadCreated': 'Thanks for the referral! We appreciate your trust in us and look forward to welcoming your friend!',
  'referAnOwner.ownerLead.dialogTitle': 'Refer an Owner',
  'referAnOwner.ownerLead.email': 'Email',
  'referAnOwner.ownerLead.firstName': 'First Name',
  'referAnOwner.ownerLead.lastName': 'Last Name',
  'referAnOwner.referAClient': 'Refer a client',
  'referAnOwner.shareAndInvite': 'Copy and Share Your Referral Link',
  'referAnOwner.referDirectly': 'Refer a Friend Directly',
  'referAnOwner.referDirectly.subtitle': 'Fill in their details to help us connect with them',

  'blanketOverview.totalUsers': 'Total Users',
  'blanketOverview.userData': 'User Data',
  'blanketOverview.activated': 'Activated',
  'blanketOverview.invited': 'Invited',
  'blanketOverview.conversionRate': 'Conversion Rate',
  'blanketOverview.chart.noData.title': 'No Available Data',
  'blanketOverview.chart.noData.subtitle': 'Certain data points are populated only 30 days after onboarding.',
  'blanketOverview.totalOwners': 'Total Owners',
  'blanketOverview.commsFromBlanket': 'Comms from Blanket',
  'blanketOverview.commsFromBlanket.tooltip': 'Email communications represents the total amount of emails sent by Blanket in a specific month',
  'blanketOverview.monthlyActiveUsers': 'Monthly Active Users',
  'blanketOverview.monthlyActiveUsers.tooltip': 'Monthly Active Users represents the number of unique user logins (For example, if an owner logged in 5 times, it will count as 1 in that month)',
  'blanketOverview.totalMonitoredProperties': 'Total Monitored',
  'blanketOverview.totalMonitoredProperties.tooltip': 'The total number of properties and units monitored by Blanket',
  'blanketOverview.monitoredUnits': 'Monitored Units',
  'blanketOverview.revenueAtRisk': 'Revenue at Risk',
  'blanketOverview.revenueAtRisk.monitoredRevenue': 'Monitored Revenue',
  'blanketOverview.revenueAtRisk.tooltip': 'Revenue at risk represents owner accounts and their total RPU broken down by Risk Statuses',
  'blanketOverview.revenueOpportunities': 'Revenue Opportunities',
  'blanketOverview.revenueOpportunities.tooltip.title': 'Revenue Opportunities represent the estimated revenue that can be generated from different types of Lead Categories. The calculation takes into account various metrics that are unique to your company’s portfolio.',
  'blanketOverview.revenueOpportunities.tooltip.item1': 'Buyer and Seller Leads = Property/Asking Price * 3%',
  'blanketOverview.revenueOpportunities.tooltip.item2': 'Buybox Leads = Average Property Price in your portfolio * 3%',
  'blanketOverview.revenueOpportunities.tooltip.item3': 'Referrals and Prospects = Average Annual RPU in your portfolio',
  'blanketOverview.revenueOpportunities.referrals': 'Referrals',
  'blanketOverview.revenueOpportunities.buyers': 'Buyers',
  'blanketOverview.revenueOpportunities.sellers': 'Sellers',
  'blanketOverview.revenueOpportunities.prospects': 'Prospects',
  'blanketOverview.revenueOpportunities.buybox': 'Buy Box',
  'blanketOverview.revenueAtRisk.noRisk': 'No risk',
  'blanketOverview.revenueAtRisk.atRisk': 'At risk',
  'blanketOverview.revenueAtRisk.resolved': 'Resolved',
  'blanketOverview.revenueAtRisk.healthy': 'Healthy',
  'blanketOverview.revenueAtRisk.inProgress': 'In Progress',
  'blanketOverview.revenueAtRisk.lost': 'Lost',
  'blanketOverview.unlock.retainTitle': 'Unlock retain and get up-to-date data',
  'blanketOverview.unlock.retainSubtitle': 'AI-driven insights, automated annual owner letters, a real-time investor dashboard for your owners, and much more!',
  'blanketOverview.unlock.growTitle': 'Unlock grow and get more data',
  'blanketOverview.unlock.growSubtitle': 'Generate More Revenue & Increase Your Door Count with Blanket\'s Grow package',
  'blanketOverview.unlock.growAction': 'Activate Grow Now',
  'blanketOverview.unlock.retainAction': 'Activate Retain Now',
  'freemium.inviteOwnersBanner.title': 'Help your owners buy more properties',
  'product.navWidget.title': 'Want to stop losing doors and grow?',
  'product.unlockPopup.purchase.startNow': 'Start Now',
  'product.unlockPopup.purchase.basePrice': 'Base Price',
  'product.unlockPopup.purchase.afterDue.item1': 'Integrates with your systems.',
  'product.unlockPopup.purchase.afterDue.item2': 'No manual data entry.',
  'product.unlockPopup.purchase.bookDemo': 'Book a Demo',
  'product.unlockPopup.cancelAnytime': 'Cancel anytime.',
  'product.unlockPopup.title': 'Stop Losing Doors & Grow',
  'product.unlockPopup.subtitle.start': 'With Blanket’s New Property',
  'product.unlockPopup.subtitle.mid': 'Retention & Growth',
  'product.unlockPopup.subtitle.end': 'Platform',
  'product.unlockPopup.item1.title': 'Keep Your Doors at Home',
  'product.unlockPopup.item1.subtitle': 'Launch a branded Investor Dashboard and become a trusted advisor to your clients with an Asset Management Tool while keeping track of all owners at risk of leaving.',
  'product.unlockPopup.item2.title': 'Add More Doors & Grow Your Revenue',
  'product.unlockPopup.item2.subtitle': 'Launch a branded Investor Marketplace and turn your business into a deal-making machine by keeping your sales team busy with new owner, buyer, and seller leads.',
  'product.unlockPopup.discount.item1.title': '15% - 25% Discount on All Plans',
  'product.unlockPopup.discount.item1.subtitle': 'Enjoy a 15% discount on one Plan (Retain/Grow), or bundle up and secure a 25% discount for 6 months!',
  'product.unlockPopup.discount.item2.title': 'NO Implementation Fees ($1,500)',
  'product.unlockPopup.discount.item2.subtitle': 'Enjoy a seamless implementation process for FREE.',
  'product.unlockPopup.discount.action': 'Book a Demo to Learn More',
  'owner.LeadershipSummaryTitle': 'Add leadership summary',
  'owner.LeadershipSummaryTitleEdit': 'Edit leadership summary',

  'annualReport.title': 'Annual Report 2024',
  'annualReport.marketAnalysis.title': 'Market Update',
  'annualReport.marketAnalysis.rent': 'Single Family Rental Rent',
  'annualReport.marketAnalysis.homeValue': 'Single Family Rental Property Values',
  'annualReport.portfolioPerformance.title': 'Portfolio Performance',
  'annualReport.portfolioPerformance.totalValue': 'Total Value',
  'annualReport.portfolioPerformance.totalEquity': 'Total Equity',
  'annualReport.portfolioPerformance.totalAppreciation': 'Total Appreciation',
  'annualReport.portfolioPerformance.totalCashflow': 'Total Cashflow',
  'annualReport.portfolioPerformance.missingDataTooltip': 'By providing missing data you enhance the clarity of your report!',
  'annualReport.portfolioPerformance.valuationUnavailable': 'No valuation data for this property',
  'annualReport.missingDataDisable': 'This feature is disabled in preview mode. To add or modify data, click the "View" button for the selected owner.',
  'annualReport.legislationOverview.title': 'Legislation Overview',
  'annualReport.legislationOverview.description': 'Review the legal summary we’ve prepared and decideif it’s valuable for your clients. Toggle ON to include key legal updates in the annual report.',
  'annualReport.portfolioProjections.title': 'Projections',
  'annualReport.portfolioProjections.equityBuildUp': 'Equity Build-up',
  'annualReport.portfolioProjections.estInvestmentOverTime': 'Est. Investment Value Over Time',
  'annualReport.disclaimerText': 'Due to various factors, including but not limited to data transmission errors or changes in source data, some data may have incomplete or outdated information. We recommend that all property owners conduct their due diligence and verify all details to their satisfaction before making any decisions based on the information provided. Blanket is not responsible for any errors, omissions, or inaccuracies in the provided information. By accessing and using this information, you acknowledge and agree to these terms and accept that Blanket shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from reliance on this information.',
  'annualReport.marketAnalysis.singleFamily': 'Single-Family',
  'annualReport.leadershipSummary.title': 'Leadership Summary',
  'annualReport.leadershipSummary.personalizeTheMessage': 'Personalize the message',
  'annualReport.export': 'Export',
  'annualReport.header.title': '2024 Annual Investor Report',
  'annualReport.owner.title': 'Introducing Your 2024 Annual Owner Report!',
  'annualReport.owner.tablet': 'Your 2024 Annual Owner Report!',
  'annualReport.marketAnalysis.rentTooltip': 'Year-over-Year (YoY) rent growth shows how rental prices in your county have changed compared to the same time last year.',
  'annualReport.marketAnalysis.valueTooltip': 'Year-over-Year (YoY) property value growth shows how the market value of properties in your county has shifted compared to last year.',
  'annualReport.noDataForThisSection': 'No data for this section',
  'annualReport.noDataAvailable': 'No data available',
  'annualReport.didYouKnow': 'Did you know? A recent Institute of Real Estate Management report found that professionally managed properties have fewer maintenance issues, lower costs, and longer lifespans—reducing owner stress.',
  lease: 'Lease',
  leaseExpiration: 'Lease Expiration',
  tenantsTurnover: 'Tenants Turnover',
  maintenanceEvents: 'Maintenance Events',
  maintenanceCosts: 'Maintenance Costs',
  riskLastUpdate: 'Last Risk Update',

  'pmSettings.metrics.confirm.title': 'Confirm Changes to Assumptions',
  'pmSettings.metrics.confirm.descriptionTitle': 'Updating an assumption will change how related expenses are calculated for all owners. This means that:',
  'pmSettings.metrics.confirm.description.item1': 'The new percentage will apply to the corresponding expense for all properties, using the latest rent values.',
  'pmSettings.metrics.confirm.description.item2': 'Owners will see the updated value in their Proforma unless they have made their own adjustments.',
  'pmSettings.metrics.confirm.description.example': 'For example, if you set the Maintenance Reserve to 8%, it will automatically calculate as 8% of the current rent for all properties—unless the owner has made their own adjustments.',
  'pmSettings.metrics.confirm.description.confirmation': 'Are you sure you want to proceed?',
  dynamic: {
    'referAnOwner.referAndEarn.dialog.getSubtitle': getReferAndEarnSubtitle,
    'product.unlockPopup.discount.title': (features: NoLoadingFeatures) => `Exclusive Discounts for ${getCraneName(features)} Members - Don’t Miss Out!`,
    craneMembers: (features: NoLoadingFeatures) => `${getCraneName(features)} Members`,
    craneCommunity: (features: NoLoadingFeatures) => `${getCraneName(features)} Community`,
    'owner.successMessage': (ownersCount:number) => `Success! The annual report has been sent to ${ownersCount} ${ownersCount === 1 ? 'owner' : 'owners'}.`,
    'partners.wizard.question.enablePMReferralProgram.title': (features: NoLoadingFeatures) => {
      if (features.isFreemiumPM) {
        return 'How much are you willing to pay for a referral lead?';
      }

      return 'Do you want to activate a property manager referral program?';
    },
    'marketplace.crime.county.description': getCrimeTextCounty,
    'marketplace.crime.national.description': getCrimeTextNational,
    'pm-dashboard.inviteUser.success': (invitationType: 'EMAIL' | 'SMS') => {
      if (invitationType === 'EMAIL') {
        return 'Your email invitation has been successfully sent. The property owner will receive it shortly.';
      }

      return 'Your SMS invitation has been successfully sent. The property owner will receive it shortly.';
    },
    'pm-dashboard.inviteUser.smsConfirm.title': (email: string) => `The following message will be sent to ${email}:`,
    'retention.insight': (insight: string) => {
      switch (insight.toLowerCase()) {
        case 'managementfee':
          return labels.totalFees;
        case 'vacancy':
          return labels.vacancy;
        case 'noi':
          return labels.noi;
        case 'cashflow':
          return labels.cashFlow;
        case 'maintenance':
          return labels.maintenanceCosts;
        case 'appreciation':
          return labels.appreciation;
        case 'turnovertenants':
          return labels.tenantsTurnover;
        case 'maintenanceevents':
          return labels.maintenanceEvents;
        case 'monthstoendlease':
          return labels.lease;
        default:
          return insight;
      }
    },
    'retention.setupRetentionPolicy.riskDetectionSensitivity.options': getRiskDetectionSensitivity,
    'retention.insight.lease.title': (monthsToEndLease: number) => {
      if (monthsToEndLease === 0) {
        return 'Lease expired';
      }

      if (monthsToEndLease === 1) {
        return '1 month away from lease renewal';
      }

      return `${monthsToEndLease} months away from lease renewal`;
    },
    'retention.insight.lease.description': (monthsToEndLease: number) => {
      if (monthsToEndLease === 0) {
        return 'Based on the data we have, this lease appears to have expired. Owners may be evaluating their next steps, especially if the property underperforms.';
      }

      if (monthsToEndLease > 5) {
        return ` The lease renewal is still ${monthsToEndLease} months away, and the owner has time before making a decision. Keep engaging at a normal pace.`;
      }

      return `With ${monthsToEndLease} month${monthsToEndLease === 1 ? '' : 's'} until lease renewal, owners may be more likely to sell, especially if the property underperforms.`;
    },
    'retention.resolutionDate': (resolvedDate: string) => `Resolution date: ${dayjs(resolvedDate).format('DD MMMM YYYY')}`,
    'retention.ownerSince': (ownerSince: string) => `Client since: ${dayjs(ownerSince).format('MMM, YYYY')}`,
    'retention.insight.vacancy.wellDone': (months: number) => `Well done! The property had no vacancies in the last ${months} months, ensuring stable rental income.`,
  },
} as const;

// eslint-disable-next-line import/no-default-export
export default labels;
